import React from "react";
import StarRating from "./StarRating";

export default function FacilityCard({ data, primaryPhoto }) {
  return (
    <div className="bg-white rounded-xl shadow-md relative overflow-hidden mb-2">
      <div className="font-semibold text-gray-300 absolute top-5 left-5">
        {data.name}
      </div>
      <div className="absolute top-12 left-5 text-gray-300 text-[10px] pr-4">
        {data.description}
      </div>
      <div
        className="bg-cover bg-center h-60"
        style={{ backgroundImage: `url(${primaryPhoto || data.primaryPhoto})` }}
      >
        <div className="bg-black h-full w-full bg-opacity-50" />
      </div>
      <div className="absolute bottom-5 left-5">
        <StarRating rating={data.rating} />
      </div>
    </div>
  );
}
