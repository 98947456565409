import React, { useEffect, useRef } from "react";
import UserScreenWindow from "./UserScreenWindow";
import {
  getDocDataSnap,
  addNewDoc,
  setDocData,
} from "../../utils/firebase/Firebase.utils";
import MessageCard from "./MessageCard";
import MessageSenderCard from "./MessageSenderCard";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { v4 } from "uuid";
import Modal from "../Dashboard/Components/Modal";
import AddParticpantForm from "./AddParticpantForm";
import { FiSettings, FiSend, FiLoader } from "react-icons/fi";

function GroupChat() {
  const { id } = useParams();
  const [messages, setMessages] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const currentUser = useSelector((state) => state.userObject);
  const [formData, setFormData] = React.useState({});
  const messagesEndRef = useRef(null);
  const [addMembers, setAddMembers] = React.useState(false);
  const [groupChat, setGroupChat] = React.useState({});

  useEffect(() => {
    const fetchGroupChats = async () => {
      await getDocDataSnap("groupChats", id, (data) => {
        setGroupChat(data);
        setMessages(data.messages);
        setLoading(false);
      });
    };

    fetchGroupChats();
  }, []);

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  if (loading) {
    return (
      <UserScreenWindow label={"Group Chat"}>
        <div className="flex items-center justify-center h-full">
          <div className="flex flex-col items-center gap-4">
            <FiLoader className="w-8 h-8 animate-spin text-blue-500" />
            <p className="text-gray-600">Loading messages...</p>
          </div>
        </div>
      </UserScreenWindow>
    );
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!formData.message?.trim()) return;

    const docObject = {
      message: formData.message,
      messageStatus: "unread",
      displayName: currentUser.displayName,
      user: currentUser.email,
      dateTime: new Date(),
      id: v4(),
    };
    setFormData({});
    await setDocData("groupChats", id, { messages: [...messages, docObject] });
    scrollToBottom();
  };

  const handleAddParticipants = async () => {
    setAddMembers(!addMembers);
  };

  return (
    <div className="h-full flex flex-col relative max-w-[450px] mx-auto">
      <UserScreenWindow label={groupChat.name}>
        <div className="flex flex-col h-[calc(100vh-180px)]">
          {/* Header */}
        
          <button
            className="z-50 absolute top-3 right-4 p-1.5 w-fit text-white hover:bg-gray-400 rounded-full transition-colors"
            onClick={handleAddParticipants}
          >
            <FiSettings className="w-4 h-4" />
          </button>

          {/* Messages Container */}
          <div className="flex-1 overflow-y-auto space-y-4 w-full">
            {messages.map((message, index) => (
              <div key={index} className="flex flex-col">
                {message.user === currentUser.email ? (
                  <MessageSenderCard message={message} />
                ) : (
                  <MessageCard message={message} />
                )}
              </div>
            ))}
            <div ref={messagesEndRef} />
          </div>

          {/* Input Area */}
          <div className="bg-white border-t">
            <form onSubmit={handleSubmit} className="p-4">
              <div className="flex items-center gap-3">
                <input
                  className="flex-1 p-3 border border-gray-200 rounded-full focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                  type="text"
                  name="message"
                  onChange={handleChange}
                  value={formData.message || ""}
                  placeholder="Type your message..."
                />
                <button
                  type="submit"
                  className="p-3 bg-blue-500 text-white rounded-full hover:bg-blue-600 transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
                  disabled={!formData.message?.trim()}
                >
                  <FiSend className="w-5 h-5" />
                </button>
              </div>
            </form>
          </div>
        </div>

        {addMembers && (
          <Modal>
            <AddParticpantForm
              groupChat={groupChat}
              id={id}
              handleAddParticipants={handleAddParticipants}
            />
          </Modal>
        )}
      </UserScreenWindow>
    </div>
  );
}

export default GroupChat;
