import React, { useEffect } from "react";
import {
  getAllDocsSnap,
  geminiModel as model,
} from "../../../utils/firebase/Firebase.utils";

export default function AiDevTool() {
  const [data, setData] = React.useState([]);
  const [prompt, setPrompt] = React.useState("");
  const [response, setResponse] = React.useState("");
  const [dataLoaded, setDataLoaded] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  useEffect(() => {
    const fetchDocs = async () => {
      await getAllDocsSnap("residents", (docs) => {
        //flatten and stringify the docs
        const textRes = JSON.stringify(docs);
        setData(textRes);
        setDataLoaded(true);
      });
    };
    fetchDocs();
  }, []);

  const handleAsk = async () => {
    setLoading(true);
    const res = await model.generateContent(
      ` using the following data - ${data} - and the prompt - ${prompt} generate response`
    );
    setResponse(res.response.text());
    setLoading(false);
  };

  return (
    <div>
      <textarea
        rows={6}
        className="w-full p-8 rounded-xl"
        type="text"
        onChange={(e) => setPrompt(e.target.value)}
        value={prompt}
      />

      <button className="blue-button" onClick={handleAsk}>
        Ask
      </button>

      <div className="header">Response</div>

      <div className="text-left">{response}</div>
    </div>
  );
}
