import React, { useEffect } from "react";
import UserScreenWindow from "./UserScreenWindow";
import { getAllDocs } from "../../utils/firebase/Firebase.utils";
import RequestsCard from "./RequestsCard";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import GatePassRequestCard from "./GatePassRequestCard";
import ConcernsRequestCard from "./ConcernsRequestCard";
import { FiFileText, FiShield, FiHome, FiAlertCircle } from "react-icons/fi";
import { motion } from "framer-motion";
import BookingRequestCard from "./Facilities/BookingRequestCard";

function RequestSummary() {
  const [requests, setRequests] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(true);
  const currentUser = useSelector((state) => state.userObject);
  const [activeLink, setActiveLink] = React.useState("workPermits");
  const [gatePasses, setGatePasses] = React.useState([]);
  const [concerns, setConcerns] = React.useState([]);
  const [bookings, setBookings] = React.useState([]);

  const links = [
    {
      name: "Work Permits",
      link: "workPermits",
      icon: <FiFileText className="w-4 h-4" />,
    },
    {
      name: "Gate Pass",
      link: "gatePass",
      icon: <FiShield className="w-4 h-4" />,
    },
    {
      name: "Facilities",
      link: "facilities",
      icon: <FiHome className="w-4 h-4" />,
    },
    {
      name: "Concerns",
      link: "concerns",
      icon: <FiAlertCircle className="w-4 h-4" />,
    },
  ];

  useEffect(() => {
    const fetchRequests = async () => {
      const data = await getAllDocs("concerns");
      //filter active requests by email
      const userRequests = data.filter(
        (request) => request.user === currentUser.email
      );
      setConcerns(userRequests);
    };
    fetchRequests();
  }, []);

  useEffect(() => {
    const fetchRequests = async () => {
      const data = await getAllDocs("workPermits");
      //filter active requests by email
      const userRequests = data.filter(
        (request) => request.createdBy === currentUser.email
      );
      setRequests(userRequests);
    };
    fetchRequests();
  }, []);

  useEffect(() => {
    const fetchGatePasses = async () => {
      const data = await getAllDocs("gatePass");
      //
      const userGatePasses = data.filter(
        (request) => request.user === currentUser.email
      );
      setGatePasses(userGatePasses);
      setIsLoading(false);
    };
    fetchGatePasses();
  }, []);

  useEffect(() => {
    const fetchBookings = async () => {
      const data = await getAllDocs("bookings");
      //
      const bookingArray = data.filter(
        (request) => request.user === currentUser.email
      );
      setBookings(bookingArray);
      setIsLoading(false);
    };
    fetchBookings();
  }, []);

  if (isLoading) {
    return (
      <UserScreenWindow label="Requests Summary">
        <div className="flex items-center justify-center h-64">
          <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-eleghoBlue"></div>
        </div>
      </UserScreenWindow>
    );
  }

  return (
    <UserScreenWindow label={"Requests Summary"}>
      <div className="max-w-4xl mx-auto">
        <div className="text-center mb-8">
          <h1 className="text-2xl font-bold text-eleghoBlue mb-2">
            Requests Summary
          </h1>
          <p className="text-gray-600 text-sm">
            View and manage all your requests in one place
          </p>
        </div>

        <div className="bg-white rounded-xl shadow-sm border border-gray-100 p-4 mb-6">
          <div className="flex items-center justify-start space-x-2 flex-wrap gap-2">
            {links.map((link, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: index * 0.1 }}
                onClick={() => setActiveLink(link.link)}
                className={`flex items-center gap-2 px-4 py-2 rounded-lg cursor-pointer transition-all duration-200 ${
                  activeLink === link.link
                    ? "bg-eleghoBlue text-white"
                    : "bg-gray-50 text-gray-600 hover:bg-gray-100"
                }`}
              >
                {link.icon}
                <span className="text-sm font-medium">{link.name}</span>
              </motion.div>
            ))}
          </div>
        </div>

        <div className="space-y-4">
          {activeLink === "workPermits" && (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              className="space-y-4"
            >
              {requests.map((request, index) => (
                <motion.div
                  key={index}
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: index * 0.1 }}
                >
                  <Link to={`/request-details/${request.id}`} className="block">
                    <RequestsCard request={request} />
                  </Link>
                </motion.div>
              ))}
            </motion.div>
          )}

          {activeLink === "gatePass" && (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              className="space-y-4"
            >
              {gatePasses.map((request, index) => (
                <motion.div
                  key={index}
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: index * 0.1 }}
                >
                  <Link
                    to={`/gatepass-details/${request.id}`}
                    className="block"
                  >
                    <GatePassRequestCard gatepass={request} />
                  </Link>
                </motion.div>
              ))}
            </motion.div>
          )}

          {activeLink === "facilities" && (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              className="space-y-4"
            >
              {bookings.map((booking, index) => (
                <motion.div
                  key={index}
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: index * 0.1 }}
                >
                  <Link to={`/booking/${booking.id}`} className="block">
                    <BookingRequestCard booking={booking} />
                  </Link>
                </motion.div>
              ))}
            </motion.div>
          )}

          {activeLink === "concerns" && (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              className="space-y-4"
            >
              {concerns.map((request, index) => (
                <motion.div
                  key={index}
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: index * 0.1 }}
                >
                  <Link to={`/concern/${request.id}`} className="block">
                    <ConcernsRequestCard concern={request} />
                  </Link>
                </motion.div>
              ))}
            </motion.div>
          )}
        </div>
      </div>
    </UserScreenWindow>
  );
}

export default RequestSummary;
