/**
 * Utility functions for handling company-specific terminology
 */

// Map of terms that should be replaced based on company type
export const companyTermMap = {
  condo: {
    unit: "unit",
    units: "units",
    owner: "unit owner",
    structure: "tower",
    location: "floor",
    identifier: "unit number",
  },
  hoa: {
    unit: "resident",
    units: "residents",
    owner: "lot owner",
    structure: "phase",
    location: "block",
    identifier: "lot number",
  },
};

/**
 * Returns the appropriate term based on company type
 *
 * @param {string} term - The term to translate
 * @param {string} companyType - The company type (condo or hoa)
 * @returns {string} The translated term
 */
export const getCompanyTerm = (term, companyType = "condo") => {
  if (!companyTermMap[companyType] || !companyTermMap[companyType][term]) {
    return term;
  }

  return companyTermMap[companyType][term];
};

/**
 * Replaces all terminology in a text string based on company type
 *
 * @param {string} text - The text to process
 * @param {string} companyType - The company type (condo or hoa)
 * @returns {string} The processed text
 */
export const replaceCompanyTerms = (text, companyType = "condo") => {
  if (!text) return "";

  let result = text;
  const terms = companyTermMap[companyType] || companyTermMap.condo;

  Object.entries(terms).forEach(([key, value]) => {
    // Replace all occurrences of the term (case insensitive)
    const regex = new RegExp(`\\b${key}\\b`, "gi");
    result = result.replace(regex, value);

    // Also replace capitalized versions
    const capitalizedKey = key.charAt(0).toUpperCase() + key.slice(1);
    const capitalizedValue = value.charAt(0).toUpperCase() + value.slice(1);
    const regexCapitalized = new RegExp(`\\b${capitalizedKey}\\b`, "g");
    result = result.replace(regexCapitalized, capitalizedValue);
  });

  return result;
};
