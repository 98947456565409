import React from "react";
import { FiBell } from "react-icons/fi";

function NotificationCard({ notification }) {
  // Convert Firebase timestamp to date
  const date = notification.dateTime?.toDate().toLocaleDateString("en-US", {
    year: "numeric",
    month: "short",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
  });

  return (
    <div className="bg-white rounded-xl shadow-sm hover:shadow-md transition-all duration-200 overflow-hidden">
      <div className="p-4">
        <div className="flex items-start gap-3">
          {/* Icon */}
          <div className="flex-shrink-0">
            <div
              className={`w-10 h-10 rounded-full flex items-center justify-center ${
                notification.read ? "bg-gray-100" : "bg-blue-50"
              }`}
            >
              <FiBell
                className={`w-5 h-5 ${
                  notification.read ? "text-gray-400" : "text-blue-500"
                }`}
              />
            </div>
          </div>

          {/* Content */}
          <div className="flex-1 min-w-0">
            <div className="flex items-center justify-between mb-1">
              <h3 className="text-sm font-semibold text-gray-900 truncate">
                {notification.subject}
              </h3>
              {!notification.read && (
                <span className="ml-2 px-2 py-0.5 text-xs font-medium text-blue-600 bg-blue-50 rounded-full">
                  New
                </span>
              )}
            </div>

            <p className="text-sm text-gray-600 line-clamp-2 mb-2">
              {notification.message}
            </p>

            <div className="flex items-center justify-between">
              <span className="text-xs text-gray-400">{date}</span>
              <span className="text-xs text-blue-500 font-medium">
                View details →
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NotificationCard;
