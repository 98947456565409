import React from "react";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import DisplayAvatar from "./DisplayAvatar";

export default function CommentBlock({
  currentUser,
  comment,
  handleDeleteComment,
  withoutDelete,
}) {
  return (
    <div className="pl-4 relative">
      <div className="border-l border-b rounded-bl-lg mt-2 pb-4 shadow-sm">
        <div className="flex items-center gap-2 relative -left-4">
          <DisplayAvatar
            email={comment.email}
            displayName={comment.createdBy}
          />
          <div className="font-bold text-xs text-black capitalize">
            {comment.createdBy?.toLowerCase()}
          </div>
          <div className="text-gray-400">{comment.createdAt}</div>
        </div>
        <div className="relative left-10">{comment.message}</div>
      </div>

      {!withoutDelete && currentUser?.email === comment?.email && (
        <div
          className="absolute bottom-2 text-red-400 right-0 cursor-pointer"
          onClick={() => handleDeleteComment(comment)}
        >
          <DeleteForeverIcon />
        </div>
      )}
    </div>
  );
}
