import React from "react";

export default function BookingRequestCard({ booking }) {
  console.log(booking);

  const sampleBooking = {
    status: "Pending payment",
    user: "atmagat@gmail.com",
    createdBy: "lN4Xuy0y4OhLkiusZDnYdfOwhps1",
    date: {
      seconds: 1742832000,
      nanoseconds: 0,
    },
    bookingType: "Function Room A",
    createdEmail: "atmagat@gmail.com",
    createdName: "Alvin Magat",
    startTime: "9:00",
    createdAt: {
      seconds: 1742890936,
      nanoseconds: 305000000,
    },
    endTime: "12:00",
    id: "2mN7IbGj5qGgZsKcHqyx",
  };

  return (
    <div className="bg-white rounded-lg shadow-sm p-4 mb-3">
      <div className="flex flex-col space-y-2">
        <div className="flex items-center justify-between">
          <h3 className="text-base font-semibold text-gray-800">
            {booking.bookingType}
          </h3>
          <span className="text-xs px-2 py-1 rounded-full bg-blue-100 text-blue-800">
            {booking.status}
          </span>
        </div>

        <div className="flex flex-col space-y-1">
          <div className="flex items-center text-sm text-gray-600">
            <svg
              className="w-4 h-4 mr-1"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
            {booking.startTime} - {booking.endTime}
          </div>
          <div className="flex items-center text-sm text-gray-600">
            <svg
              className="w-4 h-4 mr-1"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
              />
            </svg>
            {booking.user}
          </div>
        </div>
      </div>
    </div>
  );
}
