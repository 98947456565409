import React, { useEffect } from "react";
import { getAllDocsSnap } from "../../../utils/firebase/Firebase.utils";
import AllResidentsTable from "../TenantManagement/AllResidentsTable";
import Modal from "../Components/Modal";
import ResidentsDetails from "../TenantManagement/ResidentsDetails";
import AddResidentForm from "./AddResidentForm";
import ResidentDetails from "../TenantManagement/ResidentDetails";
import ResidentsCard from "../TenantManagement/ResidentsCard";
import { Link } from "react-router-dom";
import BentoTable from "../../../components/BentoTable";
import { slicedData } from "../../../utils/EleghoUtils";
import ResidentTableHeaders from "./ResidentTableHeaders";
import { FiHome, FiUsers, FiUserCheck, FiUserX } from "react-icons/fi";
import { useCompany } from "../../../context/CompanyContext";

function AllResidents({ searchTerm }) {
  const [residentSummary, setResidentSummary] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(true);
  const [viewMode, setViewMode] = React.useState(false);
  const [selectedResident, setSelectedResident] = React.useState({});
  const [activeLink, setActiveLink] = React.useState("Resident Information");
  const [residents, setResidents] = React.useState([]);
  const [numberOfItemPerPage, setNumberOfItemPerPage] = React.useState(5);
  const [currentPage, setCurrentPage] = React.useState(1);
  const { getCapitalizedTerm } = useCompany();

  useEffect(() => {
    const fetchResidents = async () => {
      getAllDocsSnap("residents", (residents) => {
        //arrange by owner1
        residents.sort((a, b) => {
          if (a.owner1 < b.owner1) {
            return -1;
          }
          if (a.owner1 > b.owner1) {
            return 1;
          }
          return 0;
        });

        setResidents(residents);
        setIsLoading(false);
      });
    };

    fetchResidents();
  }, []);

  if (isLoading) {
    return <div>Loading</div>;
  }

  const stats = {
    totalUnits: residents.length,
    occupiedUnits: residents.filter((r) => r.status === "occupied").length,
    vacantUnits: residents.filter((r) => r.status === "vacant").length,
    totalOccupants: residents.reduce(
      (sum, r) => sum + (parseInt(r.numberOfOccupants) || 0),
      0
    ),
  };

  const tableHeaders = [
    "Lot Owner",
    "Unit Number",
    "Unit Size",
    "Occupants",
    "Email Address",
    "Phone Number",
    "Tenanted",
    "More Information",
  ];

  const handleViewDetails = () => {
    setViewMode(true);
  };

  const handleViewItem = (resident) => {
    setSelectedResident(resident.id);
    setViewMode(true);
  };

  const filteredResidents = residents.filter(
    (resident) =>
      resident.owner1?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      resident.owner2?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      resident.identifier
        ?.toString()
        .toLowerCase()
        .includes(searchTerm.toLowerCase()) ||
      resident.address
        ?.toString()
        .toLowerCase()
        .includes(searchTerm.toLowerCase()) ||
      resident.email?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      resident.block?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      resident.phase?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      resident.lot?.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const currentItems = filteredResidents.slice(
    (currentPage - 1) * numberOfItemPerPage,
    currentPage * numberOfItemPerPage
  );

  return (
    <div className="text-xs">
      <div>
        <div className="mt-4 font-bold text-blue-900 uppercase text-left text-sm">
          All {getCapitalizedTerm("owners")}
        </div>

        {/* Stats Cards */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 my-6">
          <div className="bg-white p-6 rounded-xl border border-gray-100 shadow-sm hover:shadow-md transition-shadow">
            <div className="flex items-center gap-3">
              <div className="p-2 bg-gray-50 rounded-lg">
                <FiHome className="w-5 h-5 text-gray-600" />
              </div>
              <div>
                <div className="text-sm text-gray-600">
                  Total {getCapitalizedTerm("units")}
                </div>
                <div className="text-2xl font-semibold text-gray-900">
                  {stats.totalUnits}
                </div>
              </div>
            </div>
          </div>
          <div className="bg-white p-6 rounded-xl border border-gray-100 shadow-sm hover:shadow-md transition-shadow">
            <div className="flex items-center gap-3">
              <div className="p-2 bg-eleghoGreen/10 rounded-lg">
                <FiUserCheck className="w-5 h-5 text-eleghoGreen" />
              </div>
              <div>
                <div className="text-sm text-gray-600">
                  Occupied {getCapitalizedTerm("units")}
                </div>
                <div className="text-2xl font-semibold text-eleghoGreen">
                  {stats.occupiedUnits}
                </div>
              </div>
            </div>
          </div>
          <div className="bg-white p-6 rounded-xl border border-gray-100 shadow-sm hover:shadow-md transition-shadow">
            <div className="flex items-center gap-3">
              <div className="p-2 bg-gray-50 rounded-lg">
                <FiUserX className="w-5 h-5 text-gray-600" />
              </div>
              <div>
                <div className="text-sm text-gray-600">
                  Vacant {getCapitalizedTerm("units")}
                </div>
                <div className="text-2xl font-semibold text-gray-600">
                  {stats.vacantUnits}
                </div>
              </div>
            </div>
          </div>
          <div className="bg-white p-6 rounded-xl border border-gray-100 shadow-sm hover:shadow-md transition-shadow">
            <div className="flex items-center gap-3">
              <div className="p-2 bg-eleghoBlue/10 rounded-lg">
                <FiUsers className="w-5 h-5 text-eleghoBlue" />
              </div>
              <div>
                <div className="text-sm text-gray-600">Total Occupants</div>
                <div className="text-2xl font-semibold text-eleghoBlue">
                  {stats.totalOccupants}
                </div>
              </div>
            </div>
          </div>
        </div>

        <BentoTable
          label="All Unit Owners"
          data={filteredResidents}
          numberOfItemPerPage={numberOfItemPerPage}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        >
          <div className="divide-y divide-gray-100">
            <ResidentTableHeaders />
            {currentItems.map((resident, index) => (
              <div
                className=""
                key={index}
                // onClick={() => handleViewItem(resident)}
              >
                <Link to={`/resident/${resident.id}`}>
                  <ResidentsCard resident={resident} />
                </Link>
              </div>
            ))}
          </div>
        </BentoTable>
      </div>
    </div>
  );
}

export default AllResidents;
