import React, { useEffect, useState } from "react";
import SelectInput from "../Components/SelectInput";
import {
  setDocData,
  getDocDataSnap,
} from "../../../utils/firebase/Firebase.utils";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import CloseButton from "../../../components/CloseButton";
import TaskAssignmentModule from "../../../components/TaskAssignmentModule";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import HistoryModule from "../../../components/HistoryModule";
import CommentBlock from "../../../components/CommentBlock";

export default function ConcernsPageDetails({ concern, setViewMode }) {
  const [formData, setFormData] = useState(concern);
  const [comments, setComments] = useState(concern.comments || []);
  const [newComment, setNewComment] = useState("");
  const [dispatchee, setDispatchee] = useState(concern?.dispatchee || "");
  const [newStatus, setNewStatus] = useState(concern.status);
  const [isProcessing, setIsProcessing] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [department, setDepartment] = useState("");
  const [resolution, setResolution] = useState(concern.resolution || "");
  const [editMode, setEditMode] = useState(false);
  const [assignedToArray, setAssignedToArray] = useState(
    concern.assignedTo || []
  );

  const currentUser = useSelector((state) => state.userObject);

  useEffect(() => {
    const fetchConcern = async () => {
      await getDocDataSnap("concerns", concern.id, (data) => {
        setFormData(data);
        setComments(data.comments || []);
        setDispatchee(data?.dispatchee || "");
        setIsLoading(false);
      });
    };
    fetchConcern();
  }, []);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  const dispatchOptions = [
    {
      label: "Engineering",
      value: "Engineering",
    },
    {
      label: "Security",
      value: "Security",
    },
    {
      label: "Admin",
      value: "Admin",
    },
    {
      label: "Finance",
      value: "Finance",
    },
    {
      label: "Facilities",
      value: "Facilities",
    },
  ];

  const statusList = ["Pending", "Processing", "Dispatched", "Resolved"];

  const statusOptions = statusList.map((item) => {
    return {
      label: item,
      value: item,
    };
  });

  const handleDispatch = async () => {
    setIsProcessing(true);
    const docObject = {
      ...formData,
      status: "Dispatched",
      dispatchee: department,
      updatedAt: new Date().toLocaleString(),
      updatedBy: currentUser.uid,
      updatedName: currentUser.displayName,
      updatedEmail: currentUser.email,
      updateType: `Dispatched to ${department}`,
    };

    await setDocData("concerns", concern.id, docObject);
    setIsProcessing(false);
    toast.success(`Dispatched to ${department}`);
  };

  const handleSelectDepartment = async (selected) => {
    setDepartment(selected);
  };

  const handleSelectStatus = (selected) => {
    setFormData({ ...formData, status: selected });
    setNewStatus(selected);
  };

  const handleUpdateStatus = async () => {
    let docObject = {};
    if (newStatus === "Resolved") {
      docObject = {
        ...formData,
        status: newStatus,
        updatedBy: currentUser.uid,
        updatedName: currentUser.displayName,
        updatedAt: new Date().toLocaleString(),
        resolvedAt: new Date().toLocaleString(),
        history: [
          ...(formData?.history || ""),
          {
            action: "Status is Updated to ",
            at: new Date().toLocaleString(),
            by: currentUser.displayName,
            to: newStatus,
          },
        ],
      };
    } else {
      docObject = {
        ...formData,
        status: newStatus,
        updatedBy: currentUser.uid,
        updatedName: currentUser.displayName,
        updatedAt: new Date().toLocaleString(),
        history: [
          ...(formData?.history || ""),
          {
            action: "Status is Updated to ",
            at: new Date().toLocaleString(),
            by: currentUser.displayName,
            to: newStatus,
          },
        ],
        resolvedAt: "Not yet resolved",
      };
    }

    await setDocData("concerns", concern.id, docObject);
    toast.success("Status Successfully Updated");
    setEditMode(false);
  };

  const handleNewComment = (e) => {
    setNewComment(e.target.value);
  };

  const handleAddComment = async () => {
    const docObject = {
      message: newComment,
      createdBy: currentUser.displayName,
      createdAt: new Date().toLocaleString(),
      email: currentUser.email,
    };

    const newHistoryItem = {
      action: "Comment Added",
      at: new Date().toLocaleString(),
      by: currentUser.displayName,
      to: newComment,
    };

    await setDocData("concerns", concern.id, {
      comments: [...comments, docObject],
      history: [...(formData?.history || ""), newHistoryItem],
    });
    toast.success("Comment Succesfully Added");
    setNewComment("");
  };

  const handleChangeResolution = (e) => {
    const { value } = e.target;
    setFormData({ ...formData, resolution: value });
  };

  const handleResolutionUpdate = async () => {
    setEditMode(!editMode);

    if (editMode) {
      const docObject = {
        ...formData,
        updatedAt: new Date().toLocaleString(),
        updatedBy: currentUser.uid,
        updatedName: currentUser.displayName,
        updatedEmail: currentUser.email,
        updateType: "Resolution is Updated",
        history: [
          ...(formData?.history || ""),
          {
            action: "Resolution report is updated ",
            at: new Date().toLocaleString(),
            by: currentUser.displayName,
            to: formData.resolution || "No Resolution Report",
          },
        ],
      };

      await setDocData("concerns", concern.id, docObject);
      toast.success("Resolution is Updated");
      setEditMode(!editMode);
    }
  };

  const handleDeleteComment = async (index) => {
    if (window.confirm("Are you sure you want to delete this comment?")) {
      const newComments = comments.filter((item, i) => i !== index);
      await setDocData("concerns", concern.id, { comments: newComments });
      toast.success("Comment Successfully Deleted");
    }
  };

  const handleAssignment = () => {
    if (editMode) {
      const docObject = {
        ...formData,
        updatedAt: new Date().toLocaleString(),
        updatedBy: currentUser.uid,
        updatedName: currentUser.displayName,
        updatedEmail: currentUser.email,
        updateType: "Concern Details is Updated",
        assignedTo: assignedToArray,
        history: [
          ...(formData?.history || ""),
          {
            action:
              assignedToArray.length === 0
                ? "Unassigned"
                : "Concern is Assigned to",
            at: new Date().toLocaleString(),
            by: currentUser.displayName,
            to: assignedToArray.map((item) => item.label).join(", "),
          },
        ],
      };

      setDocData("concerns", concern.id, docObject);
      toast.success("Concern Details is Updated");
      setEditMode(false);
    }
  };

  const handleCancelEdit = () => {
    setEditMode(false);
    setFormData(concern);
    setAssignedToArray(concern.assignedTo || []);
    toast.error("Edit Cancelled");
  };

  return (
    <div className="page relative text-gray-600">
      <button className="close-button" onClick={() => setViewMode(false)}>
        <CloseButton />
      </button>

      <div className="bg-eleghoBlue p-4 text-lg rounded-lg text-white font-semibold flex items-center justify-between">
        <div>Concern Details</div>
        <div className="flex items-center gap-4 text-xs">
          {!editMode && (
            <button
              className="green-button"
              onClick={() => setEditMode(!editMode)}
            >
              {editMode ? "Cancel" : "Edit"}
            </button>
          )}
          {editMode && <button onClick={handleCancelEdit}>Cancel</button>}
        </div>
      </div>

      <div className="flex flex-col lg:flex-row items-start lg:gap-4 text-xs">
        {/* left side */}
        <div className="">
          {/* Concern Details */}
          <div className="section-box mt-4 ">
            <div className="section-header">Concern Details</div>
            <div className="space-y-4">
              <div className="flex items-center justify-between w-96">
                <div className="font-bold text-gray-600">Reference Number</div>
                <div>{concern.id.slice(-8)}</div>
              </div>
              <div className="flex items-center justify-between w-96">
                <div className="font-bold text-gray-600">Type of Concern</div>
                <div>{formData.concern}</div>
              </div>
              <div className="flex flex-col items-start mb-4 mt-2 w-96">
                <div className="font-bold text-gray-600">Description</div>
                <div>{formData.description}</div>
              </div>
              <div className="flex items-center justify-between w-96">
                <div className="font-bold text-gray-600">Date Filed</div>
                <div>{formData.createdAt}</div>
              </div>
              <div className="flex items-center justify-between w-96">
                <div className="font-bold text-gray-600">Assigned to</div>
                <div>{formData?.dispatchee || "Not Dispatched"}</div>
              </div>

              <div className="flex items-center justify-between">
                <div className="font-bold text-gray-600">Status</div>
                <div className={`capitalize ${formData.status.toLowerCase()}`}>
                  {formData.status}
                </div>
              </div>
            </div>
          </div>

          {/* images and videos */}
          <div className="section-box mt-4">
            <div className="section-header">Image or Video</div>
            <div className="flex items-center justify-between w-96 mt-4">
              <div>Image</div>
              {formData.image ? (
                <a
                  href={concern?.image}
                  target="_blank"
                  className={` p-2 rounded-lg ${
                    formData.image
                      ? "bg-green-400"
                      : "bg-gray-200 cursor-not-allowed"
                  }`}
                >
                  {formData.image ? "View Image" : "No Image Uploaded"}
                </a>
              ) : (
                <div
                  className={` p-2 rounded-lg ${
                    formData.image
                      ? "bg-green-400"
                      : "bg-gray-200 cursor-not-allowed"
                  }`}
                >
                  No Image Uploaded
                </div>
              )}
            </div>
            <div className="flex items-center justify-between w-96 mt-4">
              <div>Video</div>
              {formData.video ? (
                <a
                  href={formData?.video}
                  target="_blank"
                  className={` p-2 rounded-lg ${
                    formData.video ? "bg-green-400" : "bg-gray-200"
                  }`}
                >
                  View Video
                </a>
              ) : (
                <div
                  className={` p-2 rounded-lg ${
                    formData.video
                      ? "bg-green-400"
                      : "bg-gray-200 cursor-not-allowed"
                  }`}
                >
                  No Video Uploaded
                </div>
              )}
            </div>
          </div>

          {/* Actions */}
          <div className="section-box mt-4">
            <div className="section-header mt-4">Update Status</div>
            <div className="max-w-96">
              <SelectInput
                options={statusOptions}
                onChange={(selected) => handleSelectStatus(selected.value)}
                disabled={!editMode}
                value={statusOptions.find(
                  (item) => item.value === formData.status
                )}
                required
              />
              {editMode && (
                <button
                  className="mt-2 blue-button "
                  onClick={handleUpdateStatus}
                >
                  Update
                </button>
              )}
            </div>
          </div>

          {/* Assignment of Task */}
          <div className="section-box mt-4">
            <div className="section-header">Assign Task</div>
            <div>
              <TaskAssignmentModule
                assignedToArray={assignedToArray}
                setAssignedToArray={setAssignedToArray}
                editMode={editMode}
              />
            </div>
            {editMode && (
              <div className="mt-4 flex items-center gap-4 justify-end">
                <button className="blue-button" onClick={handleAssignment}>
                  Save
                </button>
                <button onClick={handleCancelEdit}>Cancel</button>
              </div>
            )}
          </div>
        </div>

        {/* right side */}
        <div className="w-full">
          {/* Comments */}
          <div className="section-box mt-4 w-full">
            <div className="section-header">Comments</div>
            <div>
              <textarea
                name="comment"
                value={newComment}
                placeholder="Add comment here"
                rows={4}
                onChange={handleNewComment}
                className="border w-full rounded-lg p-2 bg-gray-100"
                id=""
              ></textarea>
              <div className="text-right mt-2">
                <button className="blue-button" onClick={handleAddComment}>
                  Add Comment
                </button>
              </div>
            </div>
            <div className="font-bold">
              Comments{" "}
              <span className="text-xs rounded-full bg-orange-500 text-white px-2 p">
                {comments.length}
              </span>
            </div>
            {comments.map((comment, index) => (
              <CommentBlock
                key={index}
                comment={comment}
                currentUser={currentUser}
                handleDeleteComment={() => handleDeleteComment(index)}
              />
            ))}
          </div>

          {/* Resolution Report */}
          <div className="mt-4 section-box w-full">
            <div className="section-header">Resolution Report</div>
            <div className="mt-4">
              <textarea
                name="resolution"
                rows={6}
                value={formData?.resolution}
                id=""
                disabled={!editMode}
                onChange={handleChangeResolution}
                className="w-full border p-2 rounded-lg"
                placeholder="Add resolution report here"
              ></textarea>
              {editMode && (
                <button
                  className="blue-button mt-4"
                  onClick={handleResolutionUpdate}
                >
                  {editMode ? "Update Resolution" : "Edit Resolution Report"}
                </button>
              )}
            </div>
          </div>

          {/* History */}
          <HistoryModule data={formData?.history || []} />
        </div>
      </div>
    </div>
  );
}
