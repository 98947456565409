import React, { useEffect, useState } from "react";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import ProfileSettings from "../Settings/ProfileSettings";
import { IoSettingsOutline } from "react-icons/io5";
import { IoMenuOutline, IoCloseOutline } from "react-icons/io5";
import { useSearchParams } from "react-router-dom";
import UserAccess from "../Settings/UserManagement";
import GeneralSetup from "../Settings/GeneralSetup";

function Settings() {
  const [searchParams, setSearchParams] = useSearchParams();
  const activeParams = searchParams.get("link");
  const [activeLink, setActiveLink] = React.useState(activeParams || "setup");
  const [notificationCount, setNotificationCount] = React.useState(0);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  useEffect(() => {
    setActiveLink(activeParams);
  }, [activeParams]);

  const settingsMenuItems = [
    {
      name: "General Setup",
      icon: <IoSettingsOutline className="text-lg" />,
      link: "setup",
    },
    {
      name: "Profile",
      icon: <AccountCircleIcon className="text-lg" />,
      link: "profile",
    },
    {
      name: "User Management",
      icon: <AccountCircleIcon className="text-lg" />,
      link: "usermanagement",
    },
  ];

  const handleMenuClick = (link) => {
    setActiveLink(link);
    setSearchParams({ link });
    setIsMobileMenuOpen(false); // Close mobile menu after selection
  };

  return (
    <div className="max-w-7xl mx-auto py-4 sm:py-8 px-4 sm:px-6 lg:px-8">
      <div className="flex items-center justify-between mb-6 sm:mb-8">
        <h1 className="text-xl sm:text-2xl font-semibold text-gray-900">
          Settings
        </h1>

        {/* Mobile Menu Button */}
        <button
          onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
          className="lg:hidden p-2 rounded-md text-gray-600 hover:bg-gray-100 focus:outline-none"
        >
          {isMobileMenuOpen ? (
            <IoCloseOutline className="h-6 w-6" />
          ) : (
            <IoMenuOutline className="h-6 w-6" />
          )}
        </button>
      </div>

      <div className="bg-white rounded-lg shadow">
        <div className="flex flex-col lg:flex-row min-h-[500px] sm:min-h-[600px]">
          {/* Sidebar - Mobile Dropdown / Desktop Sidebar */}
          <div
            className={`
            lg:w-64 lg:border-r lg:border-gray-200 lg:bg-gray-50 lg:rounded-l-lg
            ${isMobileMenuOpen ? "block" : "hidden lg:block"}
          `}
          >
            <nav className="p-2 lg:p-4 space-y-1">
              {settingsMenuItems.map((item, index) => (
                <button
                  key={index}
                  onClick={() => handleMenuClick(item.link)}
                  className={`w-full flex items-center gap-3 px-4 py-3 text-sm font-medium rounded-md transition-all duration-200 
                    ${
                      activeLink === item.link
                        ? "bg-blue-50 text-blue-700"
                        : "text-gray-600 hover:bg-gray-100"
                    }
                  `}
                >
                  <span className="flex-shrink-0">{item.icon}</span>
                  <span>{item.name}</span>
                </button>
              ))}
            </nav>
          </div>

          {/* Content Area */}
          <div className="flex-1 p-4 sm:p-6 overflow-auto">
            <div className="max-w-3xl mx-auto">
              {activeLink === "setup" && <GeneralSetup />}
              {activeLink === "profile" && <ProfileSettings />}
              {activeLink === "usermanagement" && (
                <UserAccess
                  notificationCount={notificationCount}
                  setNotificationCount={setNotificationCount}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Settings;
