import React, { useState, useEffect, useCallback, useRef } from "react";
import {
  GanttComponent,
  Inject,
  Selection,
  Toolbar,
  Edit,
  DayMarkers,
  Sort,
  Filter,
  ColumnMenu,
  Resize,
} from "@syncfusion/ej2-react-gantt";
import BentoContainer from "../../../components/BentoContainer";
import {
  addNewDoc,
  db,
  setDocData,
} from "../../../utils/firebase/Firebase.utils";
import {
  collection,
  getDocs,
  addDoc,
  updateDoc,
  deleteDoc,
  doc,
  onSnapshot,
  query,
  orderBy,
  where,
  setDoc,
  serverTimestamp,
  writeBatch,
} from "firebase/firestore";
import toast from "react-hot-toast";
import { FiSave, FiCopy, FiCalendar, FiList } from "react-icons/fi";

const MAINTENANCE_TEMPLATES = {
  "Building Systems": [
    {
      TaskName: "HVAC System Maintenance",
      duration: 7,
      frequency: "monthly",
      Progress: 0,
    },
    {
      TaskName: "Elevator Maintenance",
      duration: 3,
      frequency: "monthly",
      Progress: 0,
    },
    {
      TaskName: "Fire Safety Systems Check",
      duration: 2,
      frequency: "quarterly",
      Progress: 0,
    },
    {
      TaskName: "Emergency Lighting Test",
      duration: 1,
      frequency: "monthly",
      Progress: 0,
    },
  ],
  "Common Areas": [
    {
      TaskName: "Swimming Pool Maintenance",
      duration: 2,
      frequency: "weekly",
      Progress: 0,
    },
    {
      TaskName: "Gym Equipment Inspection",
      duration: 1,
      frequency: "weekly",
      Progress: 0,
    },
    {
      TaskName: "Landscaping and Gardens",
      duration: 3,
      frequency: "weekly",
      Progress: 0,
    },
  ],
  "Security Systems": [
    {
      TaskName: "CCTV System Check",
      duration: 1,
      frequency: "monthly",
      Progress: 0,
    },
    {
      TaskName: "Access Control System Maintenance",
      duration: 2,
      frequency: "quarterly",
      Progress: 0,
    },
  ],
  "Water Systems": [
    {
      TaskName: "Water Tank Cleaning",
      duration: 3,
      frequency: "quarterly",
      Progress: 0,
    },
    {
      TaskName: "Pump Room Inspection",
      duration: 1,
      frequency: "monthly",
      Progress: 0,
    },
  ],
};

export default function WorkWeekSchedule() {
  const [tasks, setTasks] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [showTemplateModal, setShowTemplateModal] = useState(false);
  const [showSaveTemplateModal, setShowSaveTemplateModal] = useState(false);
  const [templateName, setTemplateName] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("all");
  const [newCategory, setNewCategory] = useState("");
  const [selectedTasks, setSelectedTasks] = useState([]);
  const [saveScope, setSaveScope] = useState("all"); // "all", "category", "selected"
  const unsubscribeRef = useRef(null);
  const mountedRef = useRef(true);

  // Function to safely convert Firestore timestamp to Date with validation
  const convertTimestamps = useCallback((task) => {
    try {
      if (!task) return null;

      const convertDate = (date) => {
        if (!date) return new Date();
        if (date.toDate) return date.toDate();
        if (date instanceof Date) return date;
        return new Date(date);
      };

      const convertedTask = {
        ...task,
        StartDate: convertDate(task.StartDate),
        EndDate: convertDate(task.EndDate),
      };

      if (task.subtasks && Array.isArray(task.subtasks)) {
        convertedTask.subtasks = task.subtasks.map((subtask) => ({
          ...subtask,
          StartDate: convertDate(subtask.StartDate),
          EndDate: convertDate(subtask.EndDate),
        }));
      }

      return convertedTask;
    } catch (error) {
      console.error("Error converting timestamps:", error);
      return null;
    }
  }, []);

  // Setup Firestore listener
  useEffect(() => {
    mountedRef.current = true;

    const setupListener = () => {
      try {
        const tasksQuery = query(
          collection(db, "workSchedule"),
          where("year", "==", selectedYear),
          orderBy("TaskID")
        );

        // Clean up any existing subscription
        if (unsubscribeRef.current) {
          unsubscribeRef.current();
        }

        unsubscribeRef.current = onSnapshot(
          tasksQuery,
          (snapshot) => {
            if (!mountedRef.current) return;

            const tasksData = snapshot.docs
              .map((doc) => {
                const convertedTask = convertTimestamps(doc.data());
                return convertedTask ? { id: doc.id, ...convertedTask } : null;
              })
              .filter(Boolean);

            setTasks(tasksData);
            setIsLoading(false);
          },
          (error) => {
            console.error("Firestore subscription error:", error);
            if (mountedRef.current) {
              // toast.error(
              //   "Error loading schedule. Data will update when connection is restored."
              // );
              setIsLoading(false);
            }
          }
        );
      } catch (error) {
        console.error("Error setting up Firestore listener:", error);
        if (mountedRef.current) {
          setIsLoading(false);
        }
      }
    };

    setupListener();

    return () => {
      mountedRef.current = false;
      if (unsubscribeRef.current) {
        unsubscribeRef.current();
        unsubscribeRef.current = null;
      }
    };
  }, [selectedYear, convertTimestamps]);

  // Batch operations helper
  const performBatchOperation = async (operations) => {
    const batch = writeBatch(db);

    try {
      operations.forEach((op) => {
        if (op.type === "add") {
          const docRef = doc(collection(db, "workSchedule"));
          batch.set(docRef, op.data);
        } else if (op.type === "update") {
          const docRef = doc(db, "workSchedule", op.id);
          batch.update(docRef, op.data);
        } else if (op.type === "delete") {
          const docRef = doc(db, "workSchedule", op.id);
          batch.delete(docRef);
        }
      });

      await batch.commit();
      return true;
    } catch (error) {
      console.error("Batch operation failed:", error);
      throw error;
    }
  };

  // Modified handleActionComplete to handle task editing properly
  const handleActionComplete = async (args) => {
    if (args.requestType === "add") {
      try {
        const taskData = {
          ...args.data,
          StartDate: new Date(args.data.StartDate),
          EndDate: new Date(args.data.EndDate),
          category: args.data.category || "Uncategorized",
          createdAt: serverTimestamp(),
          year: selectedYear,
          TaskID: String(Date.now()),
          subtasks: args.data.subtasks
            ? args.data.subtasks.map((subtask) => ({
                ...subtask,
                StartDate: new Date(subtask.StartDate),
                EndDate: new Date(subtask.EndDate),
              }))
            : [],
        };

        await addNewDoc("workSchedule", taskData);
        toast.success("Task added successfully");
      } catch (error) {
        console.error("Error adding task:", error);
        toast.error(
          "Failed to add task. Changes will sync when connection is restored."
        );
      }
    } else if (args.requestType === "save") {
      try {
        const taskData = {
          ...args.data,
          StartDate: new Date(args.data.StartDate),
          EndDate: new Date(args.data.EndDate),
          category: args.data.category || "Uncategorized",
          updatedAt: serverTimestamp(),
          subtasks: args.data.subtasks
            ? args.data.subtasks.map((subtask) => ({
                ...subtask,
                StartDate: new Date(subtask.StartDate),
                EndDate: new Date(subtask.EndDate),
              }))
            : [],
        };

        console.log(args.data);
        const documentId = args.data?.taskData.id;

        // Use the Firestore document ID (id) instead of TaskID
        await setDocData("workSchedule", documentId, taskData);

        toast.success("Task updated successfully");
      } catch (error) {
        console.error("Error updating task:", error);
        toast.error(
          "Failed to update task. Changes will sync when connection is restored."
        );
      }
    } else if (args.requestType === "delete") {
      const documentId = args.data?.taskData.id;
      try {
        // Use the Firestore document ID (id) instead of TaskID
        const docRef = doc(db, "workSchedule", documentId);
        await deleteDoc(docRef);
        toast.success("Task deleted successfully");
      } catch (error) {
        console.error("Error deleting task:", error);
        toast.error(
          "Failed to delete task. Changes will sync when connection is restored."
        );
      }
    }
  };

  // Generate tasks from template for the entire year
  const generateYearlySchedule = async (templateName) => {
    try {
      const template = MAINTENANCE_TEMPLATES[templateName];
      if (!template) return;

      const startDate = new Date(selectedYear, 0, 1); // January 1st of selected year
      const operations = [];
      let taskId = Date.now(); // Use timestamp as base for unique IDs

      // Create parent category task
      const parentTask = {
        TaskID: String(taskId++),
        TaskName: templateName,
        StartDate: startDate,
        EndDate: new Date(selectedYear, 11, 31), // End of year
        Progress: 0,
        year: selectedYear,
        category: templateName,
        isParent: true,
        subtasks: [],
      };

      // Create subtasks for each template item
      template.forEach((task) => {
        const intervals = getIntervalsByFrequency(task.frequency);
        const taskSubtasks = intervals.map((interval) => {
          const taskStartDate = new Date(startDate);
          taskStartDate.setDate(taskStartDate.getDate() + interval * 7); // Convert weeks to days
          const taskEndDate = new Date(taskStartDate);
          taskEndDate.setDate(taskEndDate.getDate() + task.duration);

          return {
            TaskID: String(taskId++),
            TaskName: `${task.TaskName} (${getFrequencyLabel(
              interval,
              task.frequency
            )})`,
            StartDate: taskStartDate,
            EndDate: taskEndDate,
            Progress: 0,
            year: selectedYear,
            category: templateName,
            frequency: task.frequency,
            parentID: parentTask.TaskID,
          };
        });

        // Add all subtasks to operations
        operations.push(
          ...taskSubtasks.map((subtask) => ({
            type: "add",
            data: {
              ...subtask,
              createdAt: serverTimestamp(),
            },
          }))
        );

        // Update parent's subtasks array
        parentTask.subtasks.push(...taskSubtasks);
      });

      // Add parent task first
      operations.unshift({
        type: "add",
        data: {
          ...parentTask,
          createdAt: serverTimestamp(),
        },
      });

      console.log("Generated tasks:", operations);
      await performBatchOperation(operations);
      toast.success(`Generated ${templateName} schedule for ${selectedYear}`);
    } catch (error) {
      console.error("Error generating schedule:", error);
      toast.error("Failed to generate schedule");
    }
  };

  // Helper function to get a readable label for the frequency
  const getFrequencyLabel = (interval, frequency) => {
    switch (frequency) {
      case "weekly":
        return `Week ${interval + 1}`;
      case "monthly":
        return `Month ${Math.floor(interval / 4) + 1}`;
      case "quarterly":
        return `Q${Math.floor(interval / 13) + 1}`;
      default:
        return "";
    }
  };

  // Helper function to get week intervals based on frequency
  const getIntervalsByFrequency = (frequency) => {
    const weeks = 52;
    switch (frequency) {
      case "weekly":
        return Array.from({ length: weeks }, (_, i) => i);
      case "monthly":
        return Array.from({ length: 12 }, (_, i) => i * 4);
      case "quarterly":
        return Array.from({ length: 4 }, (_, i) => i * 13);
      default:
        return [];
    }
  };

  // Carry over schedule to next year
  const carryOverToNextYear = async () => {
    try {
      const nextYear = selectedYear + 1;
      const tasksToCarryOver = tasks.map((task) => ({
        ...task,
        year: nextYear,
        StartDate: new Date(task.StartDate.setFullYear(nextYear)),
        EndDate: new Date(task.EndDate.setFullYear(nextYear)),
        Progress: 0, // Reset progress for new year
      }));

      // Batch add tasks to Firestore
      const batch = tasksToCarryOver.map((task) =>
        addDoc(collection(db, "workSchedule"), task)
      );
      await Promise.all(batch);
      toast.success(`Schedule carried over to ${nextYear}`);
    } catch (error) {
      console.error("Error carrying over schedule:", error);
      toast.error("Failed to carry over schedule");
    }
  };

  // Modified save template function
  const saveAsTemplate = async () => {
    try {
      if (!templateName.trim()) {
        toast.error("Please enter a template name");
        return;
      }

      let tasksToSave = tasks;

      if (saveScope === "category") {
        if (selectedCategory === "new" && !newCategory.trim()) {
          toast.error("Please enter a new category name");
          return;
        }
        const categoryToUse =
          selectedCategory === "new" ? newCategory : selectedCategory;
        tasksToSave = tasks.filter((task) => task.category === categoryToUse);
      } else if (saveScope === "selected") {
        if (selectedTasks.length === 0) {
          toast.error("Please select at least one task");
          return;
        }
        tasksToSave = tasks.filter((task) =>
          selectedTasks.includes(task.TaskID)
        );
      }

      const templateTasks = tasksToSave.map((task) => ({
        TaskName: task.TaskName,
        duration: Math.ceil(
          (task.EndDate - task.StartDate) / (1000 * 60 * 60 * 24)
        ),
        frequency: task.frequency || "monthly",
        category: task.category || templateName,
        isParent: task.isParent || false,
        parentID: task.parentID || null,
      }));

      await setDoc(doc(db, "maintenanceTemplates", templateName), {
        tasks: templateTasks,
        createdAt: new Date(),
        category:
          saveScope === "category"
            ? selectedCategory === "new"
              ? newCategory
              : selectedCategory
            : "all",
      });

      toast.success("Template saved successfully");
      setShowSaveTemplateModal(false);
      setTemplateName("");
      setSelectedCategory("all");
      setNewCategory("");
      setSelectedTasks([]);
      setSaveScope("all");
    } catch (error) {
      console.error("Error saving template:", error);
      toast.error("Failed to save template");
    }
  };

  // Get unique categories from tasks
  const categories = [...new Set(tasks.map((task) => task.category))].filter(
    Boolean
  );

  const ganttSettings = {
    taskFields: {
      id: "TaskID",
      isPrimaryKey: true,
      name: "TaskName",
      startDate: "StartDate",
      endDate: "EndDate",
      duration: "Duration",
      progress: "Progress",
      dependency: "Predecessor",
      child: "subtasks",
      parentID: "parentID",
    },
    columns: [
      { field: "TaskID", visible: false },
      { field: "TaskName", headerText: "Task Name", width: "250" },
      { field: "category", headerText: "Category", width: "150" },
      {
        field: "StartDate",
        headerText: "Start Date",
        width: "150",
        format: { type: "date", format: "dd/MM/yyyy" },
      },
      {
        field: "EndDate",
        headerText: "End Date",
        width: "150",
        format: { type: "date", format: "dd/MM/yyyy" },
      },
      { field: "Progress", headerText: "Progress", width: "150" },
    ],
    toolbar: ["Add", "Edit", "Delete", "Search", "ExpandAll", "CollapseAll"],
    editSettings: {
      allowAdding: true,
      allowEditing: true,
      allowDeleting: true,
      allowTaskbarEditing: true,
      showDeleteConfirmDialog: true,
      mode: "Dialog",
      fields: [
        {
          text: "Task Name",
          field: "TaskName",
          type: "string",
          editType: "stringedit",
        },
        {
          text: "Category",
          field: "category",
          type: "string",
          editType: "stringedit",
        },
        {
          text: "Start Date",
          field: "StartDate",
          type: "date",
          format: "dd/MM/yyyy",
          editType: "datepicker",
        },
        {
          text: "End Date",
          field: "EndDate",
          type: "date",
          format: "dd/MM/yyyy",
          editType: "datepicker",
        },
        {
          text: "Progress",
          field: "Progress",
          type: "number",
          min: 0,
          max: 100,
          editType: "numericedit",
        },
      ],
      newRowPosition: "Below",
    },
    timelineSettings: {
      timelineViewMode: "Week",
    },
    labelSettings: {
      leftLabel: "TaskName",
    },
    splitterSettings: {
      position: "35%",
    },
    projectStartDate: new Date(selectedYear, 0, 1),
    projectEndDate: new Date(selectedYear, 11, 31),
    allowSelection: true,
    allowSorting: true,
    allowResizing: true,
    highlightWeekends: true,
    treeColumnIndex: 1,
    rowHeight: 40,
  };

  if (isLoading) {
    return (
      <div className="text-left text-gray-600 max-w-full mx-auto p-4">
        <BentoContainer
          label="52 Week Work Schedule"
          subtitle="Preventive Maintenance Schedule for Admin and PMO Team"
        >
          <div className="p-4 flex items-center justify-center">
            Loading schedule...
          </div>
        </BentoContainer>
      </div>
    );
  }

  return (
    <div className="text-left text-gray-600 max-w-full mx-auto p-4">
      <BentoContainer
        label="52 Week Work Schedule"
        subtitle="Preventive Maintenance Schedule for Admin and PMO Team"
      >
        <div className="p-4">
          {/* Year and Template Controls */}
          <div className="flex items-center justify-between mb-4 gap-4">
            <div className="flex items-center gap-2">
              <FiCalendar className="text-eleghoBlue" />
              <select
                value={selectedYear}
                onChange={(e) => setSelectedYear(Number(e.target.value))}
                className="px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-eleghoBlue/20"
              >
                {Array.from(
                  { length: 5 },
                  (_, i) => new Date().getFullYear() - 2 + i
                ).map((year) => (
                  <option key={year} value={year}>
                    {year}
                  </option>
                ))}
              </select>
            </div>

            <div className="flex items-center gap-2">
              <button
                onClick={() => setShowTemplateModal(true)}
                className="flex items-center gap-2 px-4 py-2 text-sm font-medium text-white bg-eleghoBlue rounded-lg hover:bg-eleghoBlue/90 focus:outline-none focus:ring-2 focus:ring-eleghoBlue/20 transition-colors"
              >
                <FiList />
                Apply Template
              </button>
              <button
                onClick={carryOverToNextYear}
                className="flex items-center gap-2 px-4 py-2 text-sm font-medium text-eleghoBlue bg-white border border-eleghoBlue rounded-lg hover:bg-eleghoBlue/5 focus:outline-none focus:ring-2 focus:ring-eleghoBlue/20 transition-colors"
              >
                <FiCopy />
                Carry Over to {selectedYear + 1}
              </button>
              <button
                onClick={() => setShowSaveTemplateModal(true)}
                className="flex items-center gap-2 px-4 py-2 text-sm font-medium text-eleghoBlue bg-white border border-eleghoBlue rounded-lg hover:bg-eleghoBlue/5 focus:outline-none focus:ring-2 focus:ring-eleghoBlue/20 transition-colors"
              >
                <FiSave />
                Save as Template
              </button>
            </div>
          </div>

          {/* Gantt Chart */}
          <GanttComponent
            id="property-maintenance-gantt"
            dataSource={tasks}
            height="550px"
            allowSelection={true}
            highlightWeekends={true}
            showColumnMenu={true}
            actionComplete={handleActionComplete}
            {...ganttSettings}
          >
            <Inject
              services={[
                Selection,
                Toolbar,
                Edit,
                DayMarkers,
                Sort,
                Filter,
                ColumnMenu,
              ]}
            />
          </GanttComponent>
        </div>
      </BentoContainer>

      {/* Template Modal */}
      {showTemplateModal && (
        <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50">
          <div className="bg-white rounded-lg p-6 max-w-md w-full">
            <h3 className="text-lg font-semibold mb-4">
              Apply Maintenance Template
            </h3>
            <div className="space-y-2">
              {Object.keys(MAINTENANCE_TEMPLATES).map((templateName) => (
                <button
                  key={templateName}
                  onClick={() => {
                    generateYearlySchedule(templateName);
                    setShowTemplateModal(false);
                  }}
                  className="w-full text-left px-4 py-3 text-sm font-medium text-gray-700 hover:bg-eleghoBlue/5 rounded-lg transition-colors"
                >
                  {templateName}
                </button>
              ))}
            </div>
            <button
              onClick={() => setShowTemplateModal(false)}
              className="mt-4 w-full px-4 py-2 text-sm font-medium text-gray-500 hover:text-gray-700"
            >
              Cancel
            </button>
          </div>
        </div>
      )}

      {/* Save Template Modal */}
      {showSaveTemplateModal && (
        <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50">
          <div className="bg-white rounded-lg p-6 max-w-2xl w-full">
            <h3 className="text-lg font-semibold mb-4">Save as Template</h3>
            <div className="space-y-4">
              <div>
                <label
                  htmlFor="templateName"
                  className="block text-sm font-medium text-gray-700 mb-1"
                >
                  Template Name
                </label>
                <input
                  type="text"
                  id="templateName"
                  value={templateName}
                  onChange={(e) => setTemplateName(e.target.value)}
                  placeholder="Enter template name"
                  className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-eleghoBlue/20"
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Save Scope
                </label>
                <div className="space-y-2">
                  <div className="flex items-center gap-2">
                    <input
                      type="radio"
                      id="scopeAll"
                      name="saveScope"
                      value="all"
                      checked={saveScope === "all"}
                      onChange={(e) => setSaveScope(e.target.value)}
                      className="text-eleghoBlue focus:ring-eleghoBlue"
                    />
                    <label htmlFor="scopeAll">Entire Schedule</label>
                  </div>
                  <div className="flex items-center gap-2">
                    <input
                      type="radio"
                      id="scopeCategory"
                      name="saveScope"
                      value="category"
                      checked={saveScope === "category"}
                      onChange={(e) => setSaveScope(e.target.value)}
                      className="text-eleghoBlue focus:ring-eleghoBlue"
                    />
                    <label htmlFor="scopeCategory">By Category</label>
                  </div>
                  <div className="flex items-center gap-2">
                    <input
                      type="radio"
                      id="scopeSelected"
                      name="saveScope"
                      value="selected"
                      checked={saveScope === "selected"}
                      onChange={(e) => setSaveScope(e.target.value)}
                      className="text-eleghoBlue focus:ring-eleghoBlue"
                    />
                    <label htmlFor="scopeSelected">Selected Tasks</label>
                  </div>
                </div>
              </div>

              {saveScope === "category" && (
                <div>
                  <label
                    htmlFor="category"
                    className="block text-sm font-medium text-gray-700 mb-1"
                  >
                    Category
                  </label>
                  <div className="space-y-2">
                    <select
                      id="category"
                      value={selectedCategory}
                      onChange={(e) => setSelectedCategory(e.target.value)}
                      className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-eleghoBlue/20"
                    >
                      <option value="new">Create New Category</option>
                      {categories.map((category) => (
                        <option key={category} value={category}>
                          {category}
                        </option>
                      ))}
                    </select>
                    {selectedCategory === "new" && (
                      <input
                        type="text"
                        value={newCategory}
                        onChange={(e) => setNewCategory(e.target.value)}
                        placeholder="Enter new category name"
                        className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-eleghoBlue/20"
                      />
                    )}
                  </div>
                </div>
              )}

              {saveScope === "selected" && (
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Select Tasks
                  </label>
                  <div className="max-h-60 overflow-y-auto border border-gray-300 rounded-lg p-2">
                    {tasks.map((task) => (
                      <div
                        key={task.TaskID}
                        className="flex items-center gap-2 py-1"
                      >
                        <input
                          type="checkbox"
                          id={`task-${task.TaskID}`}
                          checked={selectedTasks.includes(task.TaskID)}
                          onChange={(e) => {
                            if (e.target.checked) {
                              setSelectedTasks([...selectedTasks, task.TaskID]);
                            } else {
                              setSelectedTasks(
                                selectedTasks.filter((id) => id !== task.TaskID)
                              );
                            }
                          }}
                          className="text-eleghoBlue focus:ring-eleghoBlue"
                        />
                        <label
                          htmlFor={`task-${task.TaskID}`}
                          className="text-sm"
                        >
                          {task.TaskName} ({task.category || "Uncategorized"})
                        </label>
                      </div>
                    ))}
                  </div>
                </div>
              )}

              <div className="flex gap-2 mt-6">
                <button
                  onClick={saveAsTemplate}
                  className="flex-1 px-4 py-2 text-sm font-medium text-white bg-eleghoBlue rounded-lg hover:bg-eleghoBlue/90 focus:outline-none focus:ring-2 focus:ring-eleghoBlue/20"
                >
                  Save Template
                </button>
                <button
                  onClick={() => {
                    setShowSaveTemplateModal(false);
                    setTemplateName("");
                    setSelectedCategory("all");
                    setNewCategory("");
                    setSelectedTasks([]);
                    setSaveScope("all");
                  }}
                  className="flex-1 px-4 py-2 text-sm font-medium text-gray-500 bg-gray-100 rounded-lg hover:bg-gray-200"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
