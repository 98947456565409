import React, { useEffect, useState } from "react";
import AnnouncementForm from "./AnnouncementForm";
import {
  getAllDocsSnap,
  setDocData,
} from "../../../utils/firebase/Firebase.utils";
import toast from "react-hot-toast";
import { deleteDocById } from "../../../utils/firebase/Firebase.utils";
import AnnouncementCard from "./AnnouncementCard";
import PageModal from "../Components/PageModal";
import { motion } from "framer-motion";
import { FiPlusCircle, FiEdit2, FiSearch } from "react-icons/fi";

function Announcements() {
  const links = ["All", "Announcements", "Circulars", "Advisory"];
  const [activeLink, setActiveLink] = React.useState("All");
  const [addMode, setAddMode] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [announcementArray, setAnnouncementArray] = useState([]);
  const [loading, setLoading] = useState(true);
  const [editMode, setEditMode] = useState(false);
  const [viewMode, setViewMode] = useState(false);
  const [selectedAnnouncement, setSelectedAnnouncement] = useState(null);

  useEffect(() => {
    const fetchAnnouncements = async () => {
      await getAllDocsSnap("announcements", (data) => {
        data.sort = (a, b) => {
          return a.createdAt > b.createdAt ? -1 : 1;
        };

        setAnnouncementArray(data);
        setLoading(false);
      });
    };
    fetchAnnouncements();
  }, []);

  if (loading) {
    return (
      <div className="flex items-center justify-center h-screen">
        <div className="animate-pulse flex flex-col items-center">
          <div className="w-12 h-12 rounded-full border-4 border-[#363062] border-t-transparent animate-spin mb-4"></div>
          <p className="text-[#363062] text-base">Loading announcements...</p>
        </div>
      </div>
    );
  }

  const currentItems =
    activeLink === "All"
      ? announcementArray
      : announcementArray.filter(
          (announcement) => announcement.type === activeLink.toLowerCase()
        );

  const filteredAnnouncements = currentItems.filter((announcement) =>
    announcement.title.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleCreate = () => {
    setAddMode(true);
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleToggleStatus = async (announcement) => {
    const newStatus =
      announcement.status === "published" ? "unpublished" : "published";

    await setDocData("announcements", announcement.id, { status: newStatus });
    toast.success("Status updated successfully");
  };

  const handleDelete = async (announcement) => {
    if (window.confirm("Are you sure you want to delete this announcement?")) {
      await deleteDocById("announcements", announcement.id);
      toast.success("Announcement deleted successfully");
    }
  };

  const handleViewMode = (announcement) => {
    setSelectedAnnouncement(announcement);
    setViewMode(true);
  };

  const setAsAdvisory = async (announcement) => {
    await setDocData("announcements", announcement.id, {
      type: "advisory",
    });
    toast.success("Announcement set as advisory");
    setEditMode(false);
  };

  // Animation variants
  const container = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1,
      },
    },
  };

  const item = {
    hidden: { y: 20, opacity: 0 },
    show: { y: 0, opacity: 1 },
  };

  return (
    <div className="bg-gray-50 min-h-screen">
      <div className="max-w-7xl mx-auto px-4 py-8 sm:px-6 lg:px-8">
        {/* Header */}
        <div className="flex flex-col sm:flex-row sm:items-center sm:justify-between mb-8 space-y-4 sm:space-y-0">
          <motion.h1
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            className="text-lg font-medium text-[#363062] tracking-tight"
          >
            Announcements & Advisory
          </motion.h1>
          <div className="flex items-center gap-3">
            <motion.button
              initial={{ opacity: 0, scale: 0.9 }}
              animate={{ opacity: 1, scale: 1 }}
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              onClick={handleCreate}
              className="px-4 py-2 bg-[#363062] text-white rounded-lg flex items-center gap-2 hover:bg-opacity-90 transition-colors shadow-md"
            >
              <FiPlusCircle className="h-5 w-5" />
              <span>Create</span>
            </motion.button>
            <motion.button
              initial={{ opacity: 0, scale: 0.9 }}
              animate={{ opacity: 1, scale: 1 }}
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              onClick={() => setEditMode(!editMode)}
              className={`px-4 py-2 flex items-center gap-2 rounded-lg transition-colors shadow-md ${
                editMode
                  ? "bg-gray-200 text-gray-800 hover:bg-gray-300"
                  : "bg-opacity-10 bg-[#363062] text-[#363062] hover:bg-opacity-20"
              }`}
            >
              <FiEdit2 className="h-5 w-5" />
              <span>{editMode ? "Exit Edit Mode" : "Edit"}</span>
            </motion.button>
          </div>
        </div>

        {/* Filter Section */}
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="bg-white rounded-xl shadow-md overflow-hidden"
        >
          <div className="flex flex-col md:flex-row md:items-center md:justify-between p-4 lg:p-6 gap-4">
            <div className="flex flex-wrap gap-2">
              {links.map((link, index) => (
                <motion.button
                  key={index}
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  onClick={() => setActiveLink(link)}
                  className={`px-4 py-2 rounded-lg font-medium transition-all duration-200 ${
                    activeLink === link
                      ? "bg-[#8FCD00] text-white shadow-md"
                      : "bg-gray-100 text-gray-600 hover:bg-gray-200"
                  }`}
                >
                  {link}
                </motion.button>
              ))}
            </div>
            <div className="relative w-full md:w-auto md:min-w-[320px]">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <FiSearch className="h-5 w-5 text-gray-400" />
              </div>
              <input
                type="search"
                placeholder="Search announcements"
                className="block w-full pl-10 pr-3 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-[#363062] focus:border-[#363062] transition-colors"
                onChange={handleSearch}
              />
            </div>
          </div>
        </motion.div>

        {/* Announcements Section */}
        <div className="mt-8">
          {announcementArray.length === 0 ? (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              className="bg-white rounded-xl shadow-md p-12 text-center"
            >
              <svg
                className="mx-auto h-16 w-16 text-[#363062]"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={1}
                  d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2"
                />
              </svg>
              <h3 className="mt-4 text-lg font-medium text-[#363062]">
                No announcements
              </h3>
              <p className="mt-2 text-gray-500">
                Get started by creating a new announcement.
              </p>
              <button
                onClick={handleCreate}
                className="mt-6 px-4 py-2 bg-[#363062] text-white rounded-lg flex items-center gap-2 mx-auto hover:bg-opacity-90 transition-colors"
              >
                <FiPlusCircle className="h-5 w-5" />
                <span>Create announcement</span>
              </button>
            </motion.div>
          ) : (
            <motion.div
              variants={container}
              initial="hidden"
              animate="show"
              className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6"
            >
              {filteredAnnouncements.map((announcement, index) => (
                <motion.div key={index} variants={item}>
                  <AnnouncementCard
                    announcement={announcement}
                    handleToggleStatus={handleToggleStatus}
                    handleViewMode={handleViewMode}
                    setAsAdvisory={setAsAdvisory}
                    handleDelete={handleDelete}
                    editMode={editMode}
                  />
                </motion.div>
              ))}
            </motion.div>
          )}
        </div>
      </div>

      {addMode && (
        <PageModal>
          <AnnouncementForm setAddMode={setAddMode} />
        </PageModal>
      )}

      {viewMode && (
        <PageModal>
          <AnnouncementForm
            setAddMode={setViewMode}
            data={selectedAnnouncement}
          />
        </PageModal>
      )}
    </div>
  );
}

export default Announcements;
