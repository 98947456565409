import React, { useEffect, useState } from "react";
import Modal from "../Components/Modal";
import NewInventoryForm from "./NewInventoryForm";
import { getAllDocsSnap } from "../../../utils/firebase/Firebase.utils";
import ItemCard from "./ItemCard";
import PageModal from "../Components/PageModal";
import ItemDetailsPage from "./ItemDetailsPage";
import BentoTable from "../../../components/BentoTable";
import { slicedData } from "../../../utils/EleghoUtils";
import {
  FiBox,
  FiMap,
  FiUser,
  FiPackage,
  FiSearch,
  FiPlus,
} from "react-icons/fi";

export default function Inventoryv2() {
  const [addMode, setAddMode] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [items, setItems] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [viewMode, setViewMode] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [numberOfItemPerPage, setNumberOfItemPerPage] = useState(5);

  useEffect(() => {
    const fetchItems = async () => {
      await getAllDocsSnap("inventory", (data) => {
        // Sort items by name
        data.sort((a, b) => {
          if (a.itemName < b.itemName) {
            return -1;
          }
          if (a.itemName > b.itemName) {
            return 1;
          }
          return 0;
        });

        setItems(data || []);
        setIsLoading(false);
      });
    };
    fetchItems();
  }, []);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  const handleAddMode = () => {
    setAddMode(!addMode);
  };

  const handleSelect = (selected) => {
    setSelectedItem(selected);
    setViewMode(!viewMode);
  };

  // Filter items based on search term
  const filteredItems = items.filter(
    (item) =>
      item.itemName?.toLowerCase().includes(searchTerm?.toLowerCase()) ||
      item.location?.toLowerCase().includes(searchTerm?.toLowerCase()) ||
      item.custodian?.toLowerCase().includes(searchTerm?.toLowerCase())
  );

  // Get current items for pagination
  const currentItems = filteredItems.slice(
    (currentPage - 1) * numberOfItemPerPage,
    currentPage * numberOfItemPerPage
  );

  // Calculate inventory statistics
  const stats = {
    totalItems: items.length,
    uniqueLocations: [...new Set(items.map((item) => item.location))].length,
    uniqueCustodians: [...new Set(items.map((item) => item.custodian))].length,
    totalQuantity: items.reduce(
      (sum, item) => sum + (parseInt(item.quantity) || 0),
      0
    ),
  };

  return (
    <div className="text-gray-600 text-xs text-left max-w-7xl mx-auto mt-8">
      <div className="page-header">Inventory Management</div>

      {/* Stats Cards */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 my-6">
        <div className="bg-white p-6 rounded-xl border border-gray-100 shadow-sm hover:shadow-md transition-shadow">
          <div className="flex items-center gap-3">
            <div className="p-2 bg-gray-50 rounded-lg">
              <FiBox className="w-5 h-5 text-gray-600" />
            </div>
            <div>
              <div className="text-sm text-gray-600">Total Items</div>
              <div className="text-2xl font-semibold text-gray-900">
                {stats.totalItems}
              </div>
            </div>
          </div>
        </div>
        <div className="bg-white p-6 rounded-xl border border-gray-100 shadow-sm hover:shadow-md transition-shadow">
          <div className="flex items-center gap-3">
            <div className="p-2 bg-eleghoGreen/10 rounded-lg">
              <FiPackage className="w-5 h-5 text-eleghoGreen" />
            </div>
            <div>
              <div className="text-sm text-gray-600">Total Quantity</div>
              <div className="text-2xl font-semibold text-eleghoGreen">
                {stats.totalQuantity}
              </div>
            </div>
          </div>
        </div>
        <div className="bg-white p-6 rounded-xl border border-gray-100 shadow-sm hover:shadow-md transition-shadow">
          <div className="flex items-center gap-3">
            <div className="p-2 bg-gray-50 rounded-lg">
              <FiMap className="w-5 h-5 text-gray-600" />
            </div>
            <div>
              <div className="text-sm text-gray-600">Locations</div>
              <div className="text-2xl font-semibold text-gray-600">
                {stats.uniqueLocations}
              </div>
            </div>
          </div>
        </div>
        <div className="bg-white p-6 rounded-xl border border-gray-100 shadow-sm hover:shadow-md transition-shadow">
          <div className="flex items-center gap-3">
            <div className="p-2 bg-eleghoBlue/10 rounded-lg">
              <FiUser className="w-5 h-5 text-eleghoBlue" />
            </div>
            <div>
              <div className="text-sm text-gray-600">Custodians</div>
              <div className="text-2xl font-semibold text-eleghoBlue">
                {stats.uniqueCustodians}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="flex justify-between items-center mb-4">
        <button
          onClick={handleAddMode}
          className="flex items-center gap-2 bg-eleghoBlue text-white px-4 py-2 rounded-lg hover:bg-eleghoBlue/90 transition-colors"
        >
          <FiPlus className="w-4 h-4" />
          <span>Add New Item</span>
        </button>
        <div className="relative">
          <input
            type="text"
            placeholder="Search items..."
            className="border border-gray-300 rounded-md px-4 py-2 pl-9 focus:outline-none focus:ring-2 focus:ring-eleghoBlue/50"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <FiSearch className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
        </div>
      </div>

      <BentoTable
        label="All Inventory Items"
        data={filteredItems}
        numberOfItemPerPage={numberOfItemPerPage}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
      >
        <div className="divide-y divide-gray-100">
          {/* Table Header */}
          <div className="flex items-center gap-4 p-4 font-medium bg-gray-50 text-gray-700 text-xs rounded-t-lg">
            <div className="w-20">Status</div>
            <div className="w-1/6">Item Name</div>
            <div className="w-1/6">Location</div>
            <div className="w-1/6">Custodian</div>
            <div className="w-1/6">Quantity</div>
            <div className="w-1/6 text-right">Actions</div>
          </div>

          {currentItems.map((item, index) => (
            <div
              key={index}
              className="cursor-pointer"
              onClick={() => handleSelect(item)}
            >
              <ItemCard data={item} />
            </div>
          ))}
        </div>
      </BentoTable>

      {addMode && (
        <Modal>
          <NewInventoryForm setAddMode={setAddMode} />
        </Modal>
      )}

      {viewMode && (
        <PageModal>
          <ItemDetailsPage data={selectedItem} setViewMode={setViewMode} />
        </PageModal>
      )}
    </div>
  );
}
