import React, { useEffect } from "react";
import UserScreenWindow from "./UserScreenWindow";
import { queryAllDocsByFieldSnap } from "../../utils/firebase/Firebase.utils";
import toast from "react-hot-toast";
import GroupChatCard from "./GroupChatCard";
import Modal from "../Dashboard/Components/Modal";
import CreateGroupChatForm from "./CreateGroupChatForm";
import { useSelector } from "react-redux";
import {
  FiPlus,
  FiSearch,
  FiUsers,
  FiMessageSquare,
  FiChevronRight,
} from "react-icons/fi";
import { Link } from "react-router-dom";

export default function GroupChatHome() {
  const currentUser = useSelector((state) => state.userObject);
  const [groupChats, setGroupChats] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(true);
  const [createGroup, setCreateGroup] = React.useState(false);
  const [searchQuery, setSearchQuery] = React.useState("");

  useEffect(() => {
    const fetchGroupChats = async () => {
      await queryAllDocsByFieldSnap(
        "groupChats",
        "members",
        "array-contains",
        currentUser.uid,
        (data) => {
          data.sort((a, b) => {
            if (a.name < b.name) return -1;
            if (a.name > b.name) return 1;
            return 0;
          });
          setGroupChats(data);
          setIsLoading(false);
        }
      );
    };

    fetchGroupChats();
  }, []);

  const filteredGroupChats = groupChats.filter((chat) =>
    chat.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const createGroupChat = () => {
    setCreateGroup(!createGroup);
  };

  if (isLoading) {
    return (
      <UserScreenWindow label="Group Chats">
        <div className="flex items-center justify-center h-[calc(100vh-4rem)]">
          <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-eleghoBlue"></div>
        </div>
      </UserScreenWindow>
    );
  }

  return (
    <div className="relative h-full bg-white">
      <UserScreenWindow label="Group Chats">
        <div className="flex flex-col h-full">
          {/* Header with Search */}
          <div className="sticky top-0 z-10 bg-white border-b border-gray-100">
            <div className="px-4 py-3">
              <div className="relative">
                <FiSearch className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
                <input
                  type="text"
                  placeholder="Search groups"
                  className="w-full pl-10 pr-4 py-2.5 bg-gray-50 rounded-xl focus:outline-none focus:ring-2 focus:ring-eleghoBlue/20 text-[15px] placeholder-gray-400"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                />
              </div>
            </div>
          </div>

          {/* Main Content */}
          <div className="flex-1 overflow-y-auto">
            {filteredGroupChats.length === 0 ? (
              <div className="flex flex-col items-center justify-center h-full px-4">
                <div className="w-16 h-16 rounded-full bg-eleghoBlue/10 flex items-center justify-center mb-4">
                  <FiUsers className="text-2xl text-eleghoBlue" />
                </div>
                <p className="text-[17px] font-medium text-gray-900 mb-2">
                  No groups yet
                </p>
                <p className="text-[15px] text-center max-w-sm text-gray-500 mb-6">
                  Create your first group to start collaborating with your team
                </p>
                <button
                  className="px-6 py-2.5 bg-eleghoGreen text-white rounded-xl text-[15px] font-medium hover:bg-eleghoGreen/90 transition-all duration-200 flex items-center"
                  onClick={createGroupChat}
                >
                  <FiPlus className="mr-2" />
                  Create New Group
                </button>
              </div>
            ) : (
              <div className="px-4 py-2">
                <div className="flex items-center justify-between mb-3">
                  <div className="text-[13px] font-medium text-gray-500 uppercase tracking-wider">
                    Your Groups
                  </div>
                  <button
                    className="text-[13px] text-eleghoBlue font-medium hover:text-eleghoBlue/80 transition-colors duration-200"
                    onClick={createGroupChat}
                  >
                    New Group
                  </button>
                </div>
                <div className="space-y-1">
                  {filteredGroupChats.map((groupChat, index) => (
                    <Link key={index} to={`/group-chats/${groupChat.id}`}>
                      <div
                        key={index}
                        className="group bg-white rounded-xl p-2 hover:bg-gray-50 transition-colors duration-200 cursor-pointer flex items-center justify-between border border-gray-100"
                      >
                        <GroupChatCard groupChat={groupChat} />
                        <FiChevronRight className="text-gray-400 group-hover:text-gray-600 transition-colors duration-200" />
                      </div>
                    </Link>
                  ))}
                </div>
              </div>
            )}
          </div>

          {/* FAB Button */}
          <button
            className="fixed bottom-6 right-6 w-14 h-14 bg-eleghoGreen rounded-full flex items-center justify-center text-white shadow-lg hover:bg-eleghoGreen/90 transition-all duration-200 hover:scale-105"
            onClick={createGroupChat}
          >
            <FiPlus className="text-2xl" />
          </button>

          {/* Create Group Modal */}
          {createGroup && (
            <Modal>
              <CreateGroupChatForm createGroupChat={createGroupChat} />
            </Modal>
          )}
        </div>
      </UserScreenWindow>
    </div>
  );
}
