import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import {
  getAllDocsSnap,
  getDocDataSnap,
  setDocData,
} from "../../../utils/firebase/Firebase.utils";
import FacilityRequestCard from "./FacilityRequestCard";
import FacilitySetup from "./FacilitySetup";
import FacilityCalendar from "./FacilityCalendar";
import toast from "react-hot-toast";
import PageModal from "../Components/PageModal";
import FacilityReqPage from "./FacilityReqPage";

function Facilities() {
  const [activeLink, setActiveLink] = React.useState("");
  const [activeFilter, setActiveFilter] = React.useState("All");
  const [paymentFilter, setPaymentFilter] = React.useState("All");
  const [, setSearchParams] = useSearchParams();
  const [requests, setRequests] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [viewMode, setViewMode] = useState(false);
  const [selectedRequest, setSelectedRequest] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [facilityArray, setFacilityArray] = useState([]);
  const [facilities, setFacilities] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedFacility, setSelectedFacility] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const requestsPerPage = 10;

  const fetchRequests = async () => {
    await getAllDocsSnap("bookings", (data) => {
      //sort by date
      data.sort((a, b) => b.dateSubmitted - a.dateSubmitted);
      setRequests(data);
      setIsLoading(false);
    });
  };

  useEffect(() => {
    const fetchFacilities = async () => {
      await getAllDocsSnap("facilities", (data) => {
        //sort data by name
        data.sort((a, b) => a.name.localeCompare(b.name));
        const newArray = [...data, { name: "Setup" }];
        setFacilities(data);
        setFacilityArray(newArray);
        setLoading(false);
      });
    };
    fetchFacilities();
  }, []);

  useEffect(() => {
    setActiveLink(facilityArray[0]?.name || "");
  }, [facilityArray]);

  useEffect(() => {
    fetchRequests();
  }, []);

  useEffect(() => {
    setSearchParams({ facility: activeLink, filter: activeFilter });
  }, [activeLink, activeFilter]);

  useEffect(() => {
    const fetchSelectedFacility = async () => {
      if (activeLink && activeLink !== "Setup") {
        const facility = facilities.find((f) => f.name === activeLink);
        if (facility) {
          await getDocDataSnap("facilities", facility.id, (data) => {
            if (data) {
              setSelectedFacility(data);
            }
          });
        }
      } else {
        setSelectedFacility(null);
      }
    };
    fetchSelectedFacility();
  }, [activeLink, facilities]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  const statusFilters = ["Pending", "Approved", "Rejected", "All"];
  const paymentFilters = ["Paid", "Unpaid", "All"];

  const currentItems = requests.filter(
    (item) => item.bookingType === activeLink
  );

  const filteredItems = currentItems.filter((item) => {
    const matchesStatus =
      activeFilter === "All" ||
      item.status?.toLowerCase().includes(activeFilter?.toLowerCase());

    const matchesPayment =
      paymentFilter === "All" ||
      (paymentFilter === "Paid" && item.paymentStatus === "paid") ||
      (paymentFilter === "Unpaid" && item.paymentStatus !== "paid");

    const matchesSearch =
      item.id?.toLowerCase().includes(searchTerm?.toLowerCase()) ||
      item.user?.toLowerCase().includes(searchTerm?.toLowerCase());

    return matchesStatus && matchesPayment && matchesSearch;
  });

  // Pagination logic
  const indexOfLastRequest = currentPage * requestsPerPage;
  const indexOfFirstRequest = indexOfLastRequest - requestsPerPage;
  const currentRequests = filteredItems.slice(
    indexOfFirstRequest,
    indexOfLastRequest
  );
  const totalPages = Math.ceil(filteredItems.length / requestsPerPage);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleViewMode = (selected) => {
    setSelectedRequest(selected);
    setViewMode(true);
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleFacilityClick = (facility) => {
    setActiveLink(facility.name);
  };

  const handleStatusUpdate = async (requestId, newStatus) => {
    try {
      await setDocData("bookings", requestId, {
        status: newStatus,
        dateUpdated: new Date().getTime(),
      });
      toast.success(`Request ${newStatus.toLowerCase()}`);
      // Refresh requests after status update
      fetchRequests();
    } catch (error) {
      toast.error("Failed to update request status");
    }
  };

  return (
    <div className="p-2 text-blue-900 space-y-4 text-xs max-w-7xl mx-auto">
      <div className="flex items-center justify-between">
        <div className="page-header">Facilities</div>
      </div>
      <div className="border-2 border-gray-300 p-2 flex items-center justify-between flex-wrap">
        <div className="links-container flex-wrap">
          {facilityArray.map((facility, index) => (
            <div
              key={index}
              className={`
                ${
                  activeLink === facility.name
                    ? "activeLinkStyle"
                    : "inactiveLinkStyle"
                }
                `}
              onClick={() => handleFacilityClick(facility)}
            >
              {facility.name}
            </div>
          ))}
        </div>
        <div className="space-x-4 w-full lg:w-96 mt-4 lg:mt-0">
          <input
            type="text"
            placeholder="Search by Name, Request No."
            className="p-2 py-1 lg:w-96 w-full rounded-lg border-2 border-gray-300"
            onChange={handleSearch}
          />
        </div>
      </div>

      {activeLink === "Setup" ? (
        <FacilitySetup facilities={facilities} />
      ) : selectedFacility ? (
        <>
          {/* Facility Details */}
          <div className="bg-white rounded-lg shadow-sm p-4 text-left">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div>
                <img
                  src={selectedFacility?.primaryPhoto}
                  alt={selectedFacility?.name}
                  className="w-full h-64 object-cover rounded-lg"
                />
              </div>
              <div>
                <h3 className="font-bold text-lg mb-2">Description</h3>
                <p className="text-gray-600">{selectedFacility?.description}</p>

                <h3 className="font-bold text-lg mt-4 mb-2">Features</h3>
                <ul className="list-disc list-inside text-gray-600">
                  {selectedFacility?.roomFeatures?.map((feature, index) => (
                    <li key={index}>{feature}</li>
                  ))}
                </ul>

                <h3 className="font-bold text-lg mt-4 mb-2">
                  Additional Details
                </h3>
                <div className="grid grid-cols-2 gap-2 text-gray-600">
                  {Object.entries(selectedFacility?.Features || {}).map(
                    ([key, value]) => (
                      <div key={key}>
                        <span className="font-semibold">{key}:</span> {value}
                      </div>
                    )
                  )}
                </div>
              </div>
            </div>
          </div>

          {/* Calendar View */}
          <FacilityCalendar bookings={currentItems} />

          {/* Requests Section */}
          <div className="mt-8">
            <div className="flex items-center justify-between mb-4">
              <div className="font-bold text-lg">Booking Requests</div>
              <div className="flex items-center space-x-4">
                <select
                  value={activeFilter}
                  onChange={(e) => {
                    setActiveFilter(e.target.value);
                    setCurrentPage(1); // Reset to first page when filter changes
                  }}
                  className="p-2 rounded-lg border-2 border-gray-300"
                >
                  {statusFilters.map((filter) => (
                    <option key={filter} value={filter}>
                      {filter}
                    </option>
                  ))}
                </select>
                <select
                  value={paymentFilter}
                  onChange={(e) => {
                    setPaymentFilter(e.target.value);
                    setCurrentPage(1); // Reset to first page when filter changes
                  }}
                  className="p-2 rounded-lg border-2 border-gray-300"
                >
                  {paymentFilters.map((filter) => (
                    <option key={filter} value={filter}>
                      {filter}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            <div className="space-y-2">
              {currentRequests.map((item, index) => (
                <div key={index} onClick={() => handleViewMode(item)}>
                  <FacilityRequestCard data={item} />
                </div>
              ))}
            </div>

            {/* Pagination */}
            {totalPages > 1 && (
              <div className="flex justify-center items-center space-x-2 mt-4">
                <button
                  onClick={() => handlePageChange(currentPage - 1)}
                  disabled={currentPage === 1}
                  className="px-3 py-1 rounded-lg border-2 border-gray-300 disabled:opacity-50"
                >
                  Previous
                </button>
                {[...Array(totalPages)].map((_, index) => (
                  <button
                    key={index}
                    onClick={() => handlePageChange(index + 1)}
                    className={`px-3 py-1 rounded-lg border-2 ${
                      currentPage === index + 1
                        ? "border-eleghoBlue bg-eleghoBlue text-white"
                        : "border-gray-300"
                    }`}
                  >
                    {index + 1}
                  </button>
                ))}
                <button
                  onClick={() => handlePageChange(currentPage + 1)}
                  disabled={currentPage === totalPages}
                  className="px-3 py-1 rounded-lg border-2 border-gray-300 disabled:opacity-50"
                >
                  Next
                </button>
              </div>
            )}
          </div>

          {viewMode && (
            <PageModal>
              <FacilityReqPage
                data={selectedRequest}
                setViewMOde={setViewMode}
                onStatusUpdate={handleStatusUpdate}
              />
            </PageModal>
          )}
        </>
      ) : null}
    </div>
  );
}

export default Facilities;
