import React, { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import AddTenantForm from "../Forms/AddTenantForm";
import AddResidentForm from "./AddResidentForm";
import AllResidents from "./AllResidents";
import LongTermLeases from "../TenantManagement/LongTermLeases";
import AnimatedModal from "../Components/AnimatedModal";
import StackingPlan from "../TenantManagement/StackingPlan";
import { IoMdAdd } from "react-icons/io";

function TenantManagement() {
  const [searchParams, setSearchParams] = useSearchParams();
  const link = searchParams.get("link");
  const [activeLink, setActiveLink] = React.useState(link || "Owners");
  const [addMode, setAddMode] = React.useState(false);
  const [searchTerm, setSearchTerm] = React.useState("");

  useEffect(() => {
    setSearchParams({ link: activeLink });
  }, [activeLink]);

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const links = ["Owners", "Long Term Leases", "Map"];

  return (
    <div className="text-xs max-w-7xl mx-auto">
      <div className="text-gray-900 text-left font-medium mt-4 text-lg">
        Resident Management
      </div>

      {/* All links */}
      <div className="flex items-center justify-between gap-2 p-2 flex-wrap">
        <div className="links-container">
          {links.map((link, index) => (
            <div
              key={index}
              className={`${
                activeLink === link ? "activeLinkStyle" : "inactiveLinkStyle"
              } cursor-pointer`}
              onClick={() => setActiveLink(link)}
            >
              {link}
            </div>
          ))}
        </div>

        {/* buttons  */}
        <div className="flex gap-2">
          <button
            className="inline-flex items-center gap-2 px-4 py-2 bg-eleghoBlue text-white text-sm font-medium rounded-lg hover:bg-eleghoBlue/90 transition-colors duration-200 shadow-sm hover:shadow-md"
            onClick={() => setAddMode(true)}
          >
            <IoMdAdd className="text-lg" />
            <div>Add</div>
          </button>
        </div>
      </div>

      {/* filtering and search */}
      <div className="flex items-center justify-between p-2 border-2 border-gray-300 rounded-lg mt-2">
        <input
          className="border-2 px-4 py-2 rounded-lg border-gray-100 w-96"
          type="search"
          placeholder="Search by name, lot owner, or address"
          onChange={handleSearch}
        />
      </div>

      {/* rendering which active link is active */}
      {activeLink === "Owners" && <AllResidents searchTerm={searchTerm} />}

      {activeLink === "Long Term Leases" && (
        <LongTermLeases searchTerm={searchTerm} />
      )}

      {activeLink === "Stacking Plan" && <StackingPlan />}

      {/* //add tenant modal  */}
      <AnimatedModal
        isOpen={addMode && activeLink === "Long Term Leases"}
        onClose={() => setAddMode(false)}
      >
        <AddTenantForm setAddMode={setAddMode} addMode={addMode} />
      </AnimatedModal>

      <AnimatedModal
        isOpen={addMode && activeLink === "Owners"}
        onClose={() => setAddMode(false)}
      >
        <AddResidentForm setAddMode={setAddMode} addMode={addMode} />
      </AnimatedModal>
    </div>
  );
}

export default TenantManagement;
