import React, { useEffect, useState } from "react";
import {
  getAllDocsSnap,
  setDocData,
} from "../../../utils/firebase/Firebase.utils";
import { FiBell, FiClock } from "react-icons/fi";
import { useSelector } from "react-redux";

export default function NotificationWeb() {
  const currentUser = useSelector((state) => state.userObject);
  const [notifications, setNotifications] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedNotification, setSelectedNotification] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    const fetchNotifications = async () => {
      await getAllDocsSnap("notifications", (docs) => {
        const adminNotifications = docs.filter(
          (doc) => doc.to === currentUser.email
        );
        setNotifications(adminNotifications);
        setLoading(false);
      });
    };
    fetchNotifications();
  }, []);

  const handleNotificationClick = (notification) => {
    setSelectedNotification(notification);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedNotification(null);
  };

  useEffect(() => {
    const updateReadStatus = async () => {
      if (isModalOpen && selectedNotification && !selectedNotification.read) {
        // Update the notification as read in Firestore
        await setDocData("notifications", selectedNotification.id, {
          ...selectedNotification,
          read: true,
        });
        // Update local state
        setNotifications(
          notifications.map((n) =>
            n.id === selectedNotification.id ? { ...n, read: true } : n
          )
        );
      }
    };
    updateReadStatus();
  }, [isModalOpen, selectedNotification]);

  if (loading) {
    return (
      <div className="text-gray-600 text-xs text-left max-w-7xl mx-auto mt-8">
        <div className="header">Your Notifications</div>
        <div>Loading notifications...</div>
      </div>
    );
  }

  return (
    <div className="text-gray-600 text-xs text-left max-w-7xl mx-auto mt-8">
      <div className="header text-lg font-semibold mb-4">
        Your Notifications
      </div>
      {notifications.length === 0 ? (
        <div>You have no new notifications</div>
      ) : (
        <div className="space-y-4">
          {notifications.map((notification) => (
            <div
              key={notification.id}
              onClick={() => handleNotificationClick(notification)}
              className={`p-6 bg-white rounded-xl shadow-sm hover:shadow-lg transition-all duration-200 border cursor-pointer
                ${
                  notification.read
                    ? "border-gray-100 hover:bg-gray-50"
                    : "border-blue-100 hover:bg-blue-50/50"
                }`}
            >
              <div className="flex items-start gap-4">
                {/* Notification Icon */}
                <div
                  className={`flex-shrink-0 w-10 h-10 rounded-full flex items-center justify-center
                  ${notification.read ? "bg-gray-100" : "bg-blue-50"}`}
                >
                  <FiBell
                    className={`w-5 h-5 ${
                      notification.read ? "text-gray-400" : "text-blue-500"
                    }`}
                  />
                </div>

                {/* Content */}
                <div className="flex-1 min-w-0">
                  <div className="flex items-center justify-between mb-2">
                    <h3
                      className={`font-semibold text-sm ${
                        notification.read ? "text-gray-800" : "text-blue-900"
                      }`}
                    >
                      {notification.subject}
                    </h3>
                    {!notification.read && (
                      <span className="ml-2 px-2 py-0.5 text-xs font-medium text-blue-600 bg-blue-50 rounded-full">
                        New
                      </span>
                    )}
                  </div>
                  <div className="text-gray-600 text-sm leading-relaxed line-clamp-2">
                    {notification.message}
                  </div>
                  <div className="text-xs text-gray-400 mt-2 flex items-center gap-1">
                    <FiClock className="w-4 h-4" />
                    {notification.dateTime?.toDate().toLocaleString()}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}

      {/* Modal */}
      {isModalOpen && selectedNotification && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white rounded-xl p-6 max-w-2xl w-full mx-4 relative">
            <button
              onClick={closeModal}
              className="absolute top-4 right-4 text-gray-400 hover:text-gray-600"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
            <div className="space-y-4">
              <h2 className="text-xl font-semibold text-gray-800">
                {selectedNotification.subject}
              </h2>
              <div className="text-gray-600 text-sm leading-relaxed">
                {selectedNotification.message}
              </div>
              <div className="text-xs text-gray-400 flex items-center gap-1">
                <FiClock className="w-4 h-4" />
                {selectedNotification.dateTime?.toDate().toLocaleString()}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
