import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Legend,
} from "recharts";
import { motion } from "framer-motion";

const data = [
  { month: "Jan", collected: 4000, expected: 5000, collectionRate: 80 },
  { month: "Feb", collected: 4500, expected: 5000, collectionRate: 90 },
  { month: "Mar", collected: 4800, expected: 5000, collectionRate: 96 },
  { month: "Apr", collected: 3800, expected: 5000, collectionRate: 76 },
  { month: "May", collected: 4300, expected: 5000, collectionRate: 86 },
  { month: "Jun", collected: 5000, expected: 5000, collectionRate: 100 },
];

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    const collectionRate = (
      (payload[0].value / payload[1].value) *
      100
    ).toFixed(1);
    return (
      <div className="bg-white p-4 border border-gray-100 rounded-lg shadow-lg">
        <p className="font-medium text-gray-800 mb-2">{label}</p>
        <div className="space-y-1">
          <p className="text-sm text-teal-700">
            Collected: ₱{payload[0].value.toLocaleString()}
          </p>
          <p className="text-sm text-slate-600">
            Expected: ₱{payload[1].value.toLocaleString()}
          </p>
          <p
            className={`text-sm font-medium ${
              collectionRate >= 90
                ? "text-teal-700"
                : collectionRate >= 75
                ? "text-amber-700"
                : "text-rose-700"
            }`}
          >
            Collection Rate: {collectionRate}%
          </p>
        </div>
      </div>
    );
  }
  return null;
};

const CustomLegend = ({ payload }) => {
  return (
    <div className="flex items-center justify-center gap-6 text-sm">
      {payload.map((entry, index) => (
        <div key={index} className="flex items-center gap-2">
          <div
            className="w-3 h-3 rounded-sm"
            style={{ backgroundColor: entry.color }}
          />
          <span className="text-gray-600">{entry.value}</span>
        </div>
      ))}
    </div>
  );
};

function SimpleBarChartDashboard() {
  // Calculate total collection rate
  const totalCollected = data.reduce((sum, item) => sum + item.collected, 0);
  const totalExpected = data.reduce((sum, item) => sum + item.expected, 0);
  const overallRate = ((totalCollected / totalExpected) * 100).toFixed(1);

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      className="w-full h-full bg-white p-6 rounded-xl shadow-sm"
    >
      <div className="flex items-center justify-between mb-6">
        <div>
          <h3 className="text-lg font-semibold text-gray-800">
            Collection Rate
          </h3>
          <p className="text-sm text-gray-500">Last 6 months overview</p>
        </div>
        <div
          className={`px-3 py-1.5 rounded-lg text-sm font-medium ${
            overallRate >= 90
              ? "bg-teal-50 text-teal-700"
              : overallRate >= 75
              ? "bg-amber-50 text-amber-700"
              : "bg-rose-50 text-rose-700"
          }`}
        >
          {overallRate}% Overall
        </div>
      </div>

      <div className="h-[300px]">
        <ResponsiveContainer width="100%" height="100%">
          <BarChart
            data={data}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" stroke="#F1F5F9" />
            <XAxis
              dataKey="month"
              tick={{ fill: "#64748B" }}
              axisLine={{ stroke: "#E2E8F0" }}
            />
            <YAxis
              tick={{ fill: "#64748B" }}
              axisLine={{ stroke: "#E2E8F0" }}
              tickFormatter={(value) => `₱${value / 1000}k`}
            />
            <Tooltip content={<CustomTooltip />} />
            <Legend content={<CustomLegend />} />
            <Bar
              dataKey="collected"
              fill="#14B8A6"
              radius={[4, 4, 0, 0]}
              maxBarSize={50}
              animationDuration={1000}
              name="Collected"
            />
            <Bar
              dataKey="expected"
              fill="#94A3B8"
              radius={[4, 4, 0, 0]}
              maxBarSize={50}
              animationDuration={1000}
              name="Expected"
            />
          </BarChart>
        </ResponsiveContainer>
      </div>

      {/* Summary Cards */}
      <div className="grid grid-cols-3 gap-4 mt-6">
        <div className="bg-slate-50 p-4 rounded-lg border border-slate-100">
          <p className="text-sm text-slate-600 font-medium">Total Expected</p>
          <p className="text-xl font-semibold text-slate-700">
            ₱{totalExpected.toLocaleString()}
          </p>
        </div>
        <div className="bg-teal-50 p-4 rounded-lg border border-teal-100">
          <p className="text-sm text-teal-600 font-medium">Total Collected</p>
          <p className="text-xl font-semibold text-teal-700">
            ₱{totalCollected.toLocaleString()}
          </p>
        </div>
        <div
          className={`p-4 rounded-lg border ${
            overallRate >= 90
              ? "bg-teal-50 text-teal-700 border-teal-100"
              : overallRate >= 75
              ? "bg-amber-50 text-amber-700 border-amber-100"
              : "bg-rose-50 text-rose-700 border-rose-100"
          }`}
        >
          <p className="text-sm font-medium">Collection Rate</p>
          <p className="text-xl font-semibold">{overallRate}%</p>
        </div>
      </div>
    </motion.div>
  );
}

export default SimpleBarChartDashboard;
