import React, { useEffect, useState } from "react";
import PageModal from "../Components/PageModal";
import BillingForm from "./BillingForm";
import { getAllDocsSnap } from "../../../utils/firebase/Firebase.utils";
import BillingCard from "./BillingCard";
import { slicedData } from "../../../utils/EleghoUtils";
import { DataGrid } from "@mui/x-data-grid";
import Paper from "@mui/material/Paper";
import BillingPageDetails from "./BillingPageDetails";
import {
  FiDollarSign,
  FiClock,
  FiCheckCircle,
  FiAlertCircle,
  FiPlusCircle,
  FiCalendar,
  FiDroplet,
  FiBell,
  FiXCircle,
  FiUpload,
  FiFileText,
  FiDownload,
} from "react-icons/fi";

export default function Billing() {
  const [addMode, setAddMode] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [billings, setBillings] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [numberOfItemsPerPage, setNumberOfItemsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [viewMode, setViewMode] = useState(false);
  const [selectedForView, setSelectedForView] = useState(null);

  const columns = [
    { field: "identifier", headerName: "Identifier", width: 150 },
    { field: "name", headerName: "Name", width: 200 },
    { field: "billingType", headerName: "Billing Type", width: 150 },
    {
      field: "amount",
      headerName: "Amount",
      width: 130,
      renderCell: (params) => {
        const amount = parseFloat(params.value) || 0;
        return `₱${amount.toLocaleString()}`;
      },
    },
    { field: "description", headerName: "Description", width: 200 },
    {
      field: "dueDate",
      headerName: "Due Date",
      width: 130,
    },
    {
      field: "status",
      headerName: "Status",
      width: 200,
      renderCell: (params) => {
        const statusConfig = {
          paid: {
            text: "text-green-600",
            icon: <FiCheckCircle className="w-4 h-4 text-green-600" />,
          },
          pending: {
            text: "text-blue-600",
            icon: <FiClock className="w-4 h-4 text-blue-600" />,
          },
          overdue: {
            text: "text-red-600",
            icon: <FiAlertCircle className="w-4 h-4 text-red-600" />,
          },
          unpaid: {
            text: "text-orange-600",
            icon: <FiXCircle className="w-4 h-4 text-orange-600" />,
          },
          "deposit slip uploaded": {
            text: "text-purple-600",
            icon: <FiFileText className="w-4 h-4 text-purple-600" />,
          },
        };

        const config = statusConfig[params.value] || {
          text: "text-gray-600",
          icon: null,
        };

        return (
          <div
            className={`px-3 py-1.5 capitalize ${config.text} flex items-center gap-2 font-medium`}
          >
            {config.icon}
            <span>{params.value}</span>
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    const fetchBillings = async () => {
      await getAllDocsSnap("billings", (docs) => {
        setBillings(docs);
        console.log(docs[0]);
        setIsLoading(false);
      });
    };
    fetchBillings();
  }, []);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  const handleViewMode = (params) => {
    setViewMode(true);
    setSelectedForView(params.row);
  };

  const stats = {
    totalBillings: billings.length,
    pendingBillings: billings.filter((b) => b.status === "pending").length,
    paidBillings: billings.filter((b) => b.status === "paid").length,
    overdueBillings: billings.filter((b) => b.status === "overdue").length,
  };

  const handleExcelDownload = () => {
    // Convert billings data to CSV format
    const headers = [
      "Identifier",
      "Name",
      "Billing Type",
      "Amount",
      "Description",
      "Due Date",
      "Status",
    ];
    const csvData = billings.map((billing) => [
      billing.identifier,
      billing.name,
      billing.billingType,
      billing.amount,
      billing.description,
      new Date(billing.dueDate).toLocaleDateString(),
      billing.status,
    ]);

    // Create CSV content
    const csvContent = [
      headers.join(","),
      ...csvData.map((row) => row.join(",")),
    ].join("\n");

    // Create blob and download
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    const url = URL.createObjectURL(blob);
    link.setAttribute("href", url);
    link.setAttribute(
      "download",
      `billings_${new Date().toLocaleDateString()}.csv`
    );
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div className="text-xs max-w-7xl mx-auto mt-8">
      <div className="title-header">Billing and Collection</div>

      {/* Stats Cards */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 my-6">
        <div className="bg-white p-6 rounded-xl border border-gray-100 shadow-sm hover:shadow-md transition-shadow">
          <div className="flex items-center gap-3">
            <div className="p-2 bg-gray-50 rounded-lg">
              <FiDollarSign className="w-5 h-5 text-gray-600" />
            </div>
            <div>
              <div className="text-sm text-gray-600">Total Billings</div>
              <div className="text-2xl font-semibold text-gray-900">
                {stats.totalBillings}
              </div>
            </div>
          </div>
        </div>
        <div className="bg-white p-6 rounded-xl border border-gray-100 shadow-sm hover:shadow-md transition-shadow">
          <div className="flex items-center gap-3">
            <div className="p-2 bg-eleghoGreen/10 rounded-lg">
              <FiCheckCircle className="w-5 h-5 text-eleghoGreen" />
            </div>
            <div>
              <div className="text-sm text-gray-600">Paid</div>
              <div className="text-2xl font-semibold text-eleghoGreen">
                {stats.paidBillings}
              </div>
            </div>
          </div>
        </div>
        <div className="bg-white p-6 rounded-xl border border-gray-100 shadow-sm hover:shadow-md transition-shadow">
          <div className="flex items-center gap-3">
            <div className="p-2 bg-eleghoBlue/10 rounded-lg">
              <FiClock className="w-5 h-5 text-eleghoBlue" />
            </div>
            <div>
              <div className="text-sm text-gray-600">Pending</div>
              <div className="text-2xl font-semibold text-eleghoBlue">
                {stats.pendingBillings}
              </div>
            </div>
          </div>
        </div>
        <div className="bg-white p-6 rounded-xl border border-gray-100 shadow-sm hover:shadow-md transition-shadow">
          <div className="flex items-center gap-3">
            <div className="p-2 bg-red-50 rounded-lg">
              <FiAlertCircle className="w-5 h-5 text-red-500" />
            </div>
            <div>
              <div className="text-sm text-gray-600">Overdue</div>
              <div className="text-2xl font-semibold text-red-500">
                {stats.overdueBillings}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="flex items-center gap-4 flex-wrap">
        <button
          className="px-4 py-2 bg-eleghoBlue text-white rounded-lg hover:bg-eleghoBlue/90 transition-colors text-xs inline-flex items-center gap-2"
          onClick={() => setAddMode(true)}
        >
          <FiPlusCircle className="w-4 h-4" />
          <span>Create new</span>
        </button>

        <button className="px-4 py-2 bg-eleghoBlue text-white rounded-lg hover:bg-eleghoBlue/90 transition-colors text-xs inline-flex items-center gap-2">
          <FiCalendar className="w-4 h-4" />
          <span>Generate Annual Dues</span>
        </button>

        <button className="px-4 py-2 bg-eleghoBlue text-white rounded-lg hover:bg-eleghoBlue/90 transition-colors text-xs inline-flex items-center gap-2">
          <FiDroplet className="w-4 h-4" />
          <span>Generate Water Bills</span>
        </button>

        <button className="px-4 py-2 bg-eleghoBlue text-white rounded-lg hover:bg-eleghoBlue/90 transition-colors text-xs inline-flex items-center gap-2">
          <FiBell className="w-4 h-4" />
          <span>Send Reminder</span>
        </button>

        <button
          className="px-4 py-2 bg-green-600 text-white rounded-lg hover:bg-green-700 transition-colors text-xs inline-flex items-center gap-2"
          onClick={handleExcelDownload}
        >
          <FiDownload className="w-4 h-4" />
          <span>Download Excel</span>
        </button>
      </div>

      {/* DataGrid Table */}
      <Paper sx={{ height: "70vh", width: "100%", marginTop: "1rem" }}>
        <DataGrid
          rows={billings}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 10 },
            },
            sorting: {
              sortModel: [{ field: "dueDate", sort: "desc" }],
            },
          }}
          pageSizeOptions={[5, 10, 25]}
          sx={{
            border: 0,
            fontSize: "0.8rem",
            fontFamily: "inherit",
            "& .MuiDataGrid-cell:focus": {
              outline: "none",
            },
          }}
          onRowClick={handleViewMode}
        />
      </Paper>

      {addMode && (
        <PageModal>
          <BillingForm setAddMode={setAddMode} />
        </PageModal>
      )}

      {viewMode && (
        <PageModal>
          <BillingPageDetails
            data={selectedForView}
            setViewMode={setViewMode}
          />
        </PageModal>
      )}
    </div>
  );
}
