import React from "react";
import { setDocData } from "../../../utils/firebase/Firebase.utils";
import toast from "react-hot-toast";

function ResidentsCard({ resident }) {
  const handleStatusToggle = async (e) => {
    e.preventDefault(); // Prevent link navigation
    e.stopPropagation(); // Stop event bubbling
    try {
      const newStatus = resident.status === "occupied" ? "vacant" : "occupied";
      await setDocData("residents", resident.id, { status: newStatus });
      toast.success(`Status updated to ${newStatus}`);
    } catch (error) {
      toast.error("Failed to update status");
      console.error("Error updating status:", error);
    }
  };

  return (
    <div className="bg-white flex items-center gap-4 p-4 rounded-xl border border-gray-100 hover:border-eleghoBlue/20 hover:shadow-sm text-left transition-all duration-200">
      <div className="w-24">
        <div
          onClick={handleStatusToggle}
          className={`capitalize text-xs font-medium px-3 py-1.5 rounded-full cursor-pointer transition-colors duration-200 ${
            resident.status === "occupied"
              ? "bg-eleghoGreen/10 text-eleghoGreen hover:bg-eleghoGreen/20"
              : "bg-gray-50 text-gray-600 hover:bg-gray-100"
          }`}
        >
          {resident.status || "vacant"}
        </div>
      </div>
      <div className="w-28">
        <div className="font-semibold text-gray-900 text-sm">
          {resident.owner1}
        </div>
      </div>
      <div className="w-24">
        <div className="text-gray-600 text-sm">{resident.nationality}</div>
      </div>
      <div className="w-36">
        <div className="text-gray-600 text-sm">{resident.identifier}</div>
      </div>
      <div className="w-60">
        <div className="text-gray-600 text-sm">{resident.address}</div>
      </div>
      <div className="w-28">
        <div className="text-gray-600 text-sm">
          {resident.currentTenant || "Not Leased"}
        </div>
      </div>
      <div className="w-24">
        <div className="text-gray-600 text-sm">
          {resident.numberOfOccupants}
        </div>
      </div>
    </div>
  );
}

export default ResidentsCard;
