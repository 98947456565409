import React, { useState, useRef, useEffect, useCallback } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  queryAllDocsByField,
  addNewDoc,
  storage,
} from "../../utils/firebase/Firebase.utils";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import toast from "react-hot-toast";
import Webcam from "react-webcam";

export default function Parcels() {
  const currentUser = useSelector((state) => state.userObject);
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    unitNumber: "",
    dateTime: "",
    deliveryCompany: "",
    storageLocation: "",
    image: null,
  });
  const [showCamera, setShowCamera] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [facingMode, setFacingMode] = useState("environment");
  const [cameraError, setCameraError] = useState("");
  const webcamRef = useRef(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [residents, setResidents] = useState([]);
  const [filteredResidents, setFilteredResidents] = useState([]);

  // Set current date and time as default
  useEffect(() => {
    const now = new Date();
    const localDateTime = new Date(now - now.getTimezoneOffset() * 60000)
      .toISOString()
      .slice(0, 16);

    setFormData((prevData) => ({
      ...prevData,
      dateTime: localDateTime,
    }));
  }, []);

  // Fetch residents data for autocomplete
  useEffect(() => {
    const fetchResidents = async () => {
      try {
        const residentsData = await queryAllDocsByField(
          "residents",
          "status",
          "==",
          "occupied"
        );
        setResidents(residentsData || []);
      } catch (error) {
        console.error("Error fetching residents:", error);
        toast.error("Failed to load resident data");
      }
    };

    fetchResidents();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });

    // If changing unit number, handle search
    if (name === "unitNumber") {
      setSearchTerm(value);

      if (value.trim() !== "") {
        const filtered = residents.filter(
          (resident) =>
            resident.identifier?.toLowerCase().includes(value.toLowerCase()) ||
            resident.lot?.toLowerCase().includes(value.toLowerCase())
        );
        setFilteredResidents(filtered);
      } else {
        setFilteredResidents([]);
      }
    }
  };

  const handleResidentSelect = (resident) => {
    setFormData({
      ...formData,
      unitNumber:
        resident.identifier || `Unit ${resident.lot} - Tower ${resident.phase}`,
    });
    setFilteredResidents([]);
    setSearchTerm("");
  };

  const startCamera = () => {
    setCameraError("");
    setShowCamera(true);
  };

  const stopCamera = () => {
    setShowCamera(false);
  };

  const switchCamera = () => {
    setFacingMode((prevMode) =>
      prevMode === "environment" ? "user" : "environment"
    );
  };

  const capturePhoto = useCallback(() => {
    if (!webcamRef.current) {
      toast.error("Camera not initialized properly");
      return;
    }

    try {
      const imageSrc = webcamRef.current.getScreenshot();

      if (!imageSrc) {
        toast.error("Failed to capture image");
        return;
      }

      // Convert base64 to blob
      fetch(imageSrc)
        .then((res) => res.blob())
        .then((blob) => {
          setFormData((prev) => ({
            ...prev,
            image: blob,
          }));
          stopCamera();
        })
        .catch((error) => {
          console.error("Error converting image:", error);
          toast.error("Failed to process captured image");
        });
    } catch (error) {
      console.error("Error capturing photo:", error);
      toast.error("Failed to capture photo");
    }
  }, [webcamRef]);

  const handleCameraError = useCallback((error) => {
    console.error("Camera error:", error);
    setCameraError(
      "Failed to access camera. Please check permissions and try again."
    );
    toast.error("Camera access error");
  }, []);

  const handleImageCapture = (e) => {
    if (e.target.files && e.target.files[0]) {
      setFormData({
        ...formData,
        image: e.target.files[0],
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!formData.unitNumber) {
      toast.error("Please enter a unit number");
      return;
    }

    if (!formData.image) {
      toast.error("Please capture a photo of the parcel");
      return;
    }

    try {
      setIsSubmitting(true);
      setUploadProgress(0);

      // Create parcel data object
      const parcelData = {
        unitNumber: formData.unitNumber,
        dateTime: formData.dateTime,
        deliveryCompany: formData.deliveryCompany || "Not specified",
        storageLocation: formData.storageLocation,
        status: "Delivered", // Change to "Delivered" to match the ParcelLogs component expectation
        createdAt: new Date().toISOString(),
        createdBy: currentUser?.email || "Unknown",
      };

      // If image is a blob (from camera), we need to create a file
      let imageFile = formData.image;
      if (formData.image instanceof Blob && !(formData.image instanceof File)) {
        imageFile = new File([formData.image], `parcel_${Date.now()}.jpg`, {
          type: "image/jpeg",
        });
      }

      // Upload the image to Firebase Storage
      const storageRef = ref(
        storage,
        `parcels/${Date.now()}_${imageFile.name.replace(/[^a-zA-Z0-9.]/g, "_")}`
      );

      const uploadTask = uploadBytesResumable(storageRef, imageFile);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          // Track upload progress
          const progress = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );
          setUploadProgress(progress);
        },
        (error) => {
          setIsSubmitting(false);
          console.error("Upload error:", error);

          // Handle specific error types
          if (error.code === "storage/unauthorized") {
            toast.error(
              "Permission denied. You don't have access to upload files."
            );
          } else if (error.code === "storage/canceled") {
            toast.error("Upload was canceled");
          } else if (error.code === "storage/unknown") {
            toast.error("Unknown error occurred during upload");
          } else {
            toast.error(`Failed to upload image: ${error.message}`);
          }
        },
        async () => {
          try {
            // Get the download URL after upload completes
            const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);

            // Add the image URL to the parcel data
            parcelData.imageUrl = downloadURL;

            // Save to Firestore
            const docRef = await addNewDoc("parcels", parcelData);

            if (docRef) {
              toast.success("Parcel logged successfully");
              // Reset form
              setFormData({
                unitNumber: "",
                dateTime: "",
                deliveryCompany: "",
                storageLocation: "",
                image: null,
              });
              // Redirect to parcel logs page
              navigate("/parcel-logs");
            } else {
              throw new Error("Failed to create document");
            }
          } catch (firestoreError) {
            setIsSubmitting(false);
            console.error("Firestore error:", firestoreError);
            toast.error(
              `Failed to save parcel data: ${firestoreError.message}`
            );
          }
        }
      );
    } catch (error) {
      setIsSubmitting(false);
      console.error("Parcel logging error:", error);
      toast.error(`An error occurred: ${error.message}`);
    }
  };

  const videoConstraints = {
    facingMode: facingMode,
    width: { ideal: 1280 },
    height: { ideal: 720 },
  };

  return (
    <div className="text-left p-4">
      <div className="flex justify-between items-center mb-4">
        <div className="text-lg font-medium text-eleghoBlue">Parcels</div>
        <Link
          to="/parcel-logs"
          className="text-sm text-eleghoBlue flex items-center"
        >
          <span>View Logs</span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-4 w-4 ml-1"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M9 5l7 7-7 7"
            />
          </svg>
        </Link>
      </div>

      {showCamera ? (
        <div className="fixed inset-0 z-50 bg-black flex flex-col">
          <div className="relative flex-1 w-full">
            {cameraError ? (
              <div className="absolute inset-0 flex items-center justify-center">
                <div className="bg-red-500 text-white p-4 rounded-lg max-w-xs text-center">
                  <p className="mb-2 font-semibold">Camera Error</p>
                  <p>{cameraError}</p>
                  <button
                    onClick={stopCamera}
                    className="mt-3 bg-white text-red-500 px-4 py-2 rounded-lg font-medium"
                  >
                    Close Camera
                  </button>
                </div>
              </div>
            ) : (
              <Webcam
                audio={false}
                ref={webcamRef}
                screenshotFormat="image/jpeg"
                videoConstraints={videoConstraints}
                onUserMediaError={handleCameraError}
                className="w-full h-full object-cover"
              />
            )}

            <div className="absolute bottom-6 inset-x-0 flex justify-center space-x-6">
              <button
                type="button"
                onClick={stopCamera}
                className="p-3 bg-white bg-opacity-20 rounded-full shadow-lg"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-8 w-8 text-white"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>

              <button
                type="button"
                onClick={capturePhoto}
                className="p-4 bg-white rounded-full shadow-lg"
              >
                <div className="h-12 w-12 rounded-full border-4 border-eleghoGreen"></div>
              </button>

              <button
                type="button"
                onClick={switchCamera}
                className="p-3 bg-white bg-opacity-20 rounded-full shadow-lg"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-8 w-8 text-white"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>
      ) : (
        <form onSubmit={handleSubmit} className="space-y-4">
          <div className="mb-3">
            <label className="block text-eleghoBlue font-medium mb-1">
              Unit Number *
            </label>
            <div className="relative">
              <input
                type="text"
                name="unitNumber"
                value={formData.unitNumber}
                onChange={handleChange}
                placeholder="Enter unit number (e.g. #01-123)"
                required
                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-eleghoGreen"
              />
              {/* Unit number suggestions */}
              {filteredResidents.length > 0 && (
                <ul className="absolute z-10 w-full bg-white mt-1 border border-gray-300 rounded-md shadow-lg max-h-60 overflow-auto">
                  {filteredResidents.map((resident) => (
                    <li
                      key={resident.id}
                      onClick={() => handleResidentSelect(resident)}
                      className="px-3 py-2 hover:bg-gray-100 cursor-pointer text-sm"
                    >
                      {resident.identifier ||
                        `Unit ${resident.lot} - Tower ${resident.phase}`}
                    </li>
                  ))}
                </ul>
              )}
            </div>
          </div>

          <div className="mb-3">
            <label className="block text-eleghoBlue font-medium mb-1">
              Date and Time of Delivery *
            </label>
            <input
              type="datetime-local"
              name="dateTime"
              value={formData.dateTime}
              onChange={handleChange}
              placeholder="Select date and time"
              required
              className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-eleghoGreen"
            />
          </div>

          <div className="mb-3">
            <label className="block text-eleghoBlue font-medium mb-1">
              Delivery Company
            </label>
            <input
              type="text"
              name="deliveryCompany"
              value={formData.deliveryCompany}
              onChange={handleChange}
              placeholder="Enter delivery company name (optional)"
              className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-eleghoGreen"
            />
          </div>

          <div className="mb-3">
            <label className="block text-eleghoBlue font-medium mb-1">
              Storage Location *
            </label>
            <input
              type="text"
              name="storageLocation"
              value={formData.storageLocation}
              onChange={handleChange}
              placeholder="Where the parcel is stored (e.g. Security Room)"
              required
              className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-eleghoGreen"
            />
          </div>

          <div className="mb-3">
            <label className="block text-eleghoBlue font-medium mb-1">
              Parcel Photo *
            </label>
            <div className="flex flex-col items-center">
              {formData.image ? (
                <div className="mb-3 w-full relative">
                  <img
                    src={
                      formData.image instanceof Blob
                        ? URL.createObjectURL(formData.image)
                        : formData.image
                    }
                    alt="Parcel"
                    className="h-64 w-full object-cover rounded-md shadow-md"
                  />
                  <button
                    type="button"
                    onClick={() => setFormData({ ...formData, image: null })}
                    className="absolute top-2 right-2 p-1 bg-red-500 text-white rounded-full shadow-md"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-5 w-5"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  </button>
                </div>
              ) : (
                <div
                  className="mb-3 w-full h-64 border-2 border-dashed border-gray-300 rounded-md flex items-center justify-center bg-gray-50 cursor-pointer"
                  onClick={startCamera}
                >
                  <div className="text-center p-4">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-16 w-16 mx-auto text-eleghoBlue opacity-60"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={1.5}
                        d="M3 9a2 2 0 012-2h.93a2 2 0 001.664-.89l.812-1.22A2 2 0 0110.07 4h3.86a2 2 0 011.664.89l.812 1.22A2 2 0 0018.07 7H19a2 2 0 012 2v9a2 2 0 01-2 2H5a2 2 0 01-2-2V9z"
                      />
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={1.5}
                        d="M15 13a3 3 0 11-6 0 3 3 0 016 0z"
                      />
                    </svg>
                    <p className="mt-3 text-eleghoBlue">
                      Tap to capture a photo
                    </p>
                  </div>
                </div>
              )}
              <div className="w-full">
                <button
                  type="button"
                  onClick={startCamera}
                  className="w-full py-2.5 px-4 bg-eleghoBlue text-white rounded-md hover:bg-opacity-90 focus:outline-none focus:ring-2 focus:ring-eleghoGreen focus:ring-opacity-50 transition-all duration-200 flex items-center justify-center shadow-sm mb-2"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-5 w-5 mr-2"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M3 9a2 2 0 012-2h.93a2 2 0 001.664-.89l.812-1.22A2 2 0 0110.07 4h3.86a2 2 0 011.664.89l.812 1.22A2 2 0 0018.07 7H19a2 2 0 012 2v9a2 2 0 01-2 2H5a2 2 0 01-2-2V9z"
                    />
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M15 13a3 3 0 11-6 0 3 3 0 016 0z"
                    />
                  </svg>
                  {formData.image ? "Retake Photo" : "Take Photo"}
                </button>

                {/* Add direct file upload option */}
                <label
                  htmlFor="parcelPhoto"
                  className="w-full py-2.5 px-4 bg-gray-200 text-gray-700 rounded-md hover:bg-gray-300 focus:outline-none transition-all duration-200 flex items-center justify-center shadow-sm cursor-pointer"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-5 w-5 mr-2"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-8l-4-4m0 0l-4 4m4-4v12"
                    />
                  </svg>
                  Upload from Gallery
                </label>

                <input
                  id="parcelPhoto"
                  type="file"
                  accept="image/*"
                  onChange={handleImageCapture}
                  className="hidden"
                  required={!formData.image}
                />
              </div>
            </div>
          </div>

          <div className="pt-2">
            <button
              type="submit"
              disabled={isSubmitting}
              className={`w-full py-2 bg-eleghoGreen text-white font-medium rounded-md hover:bg-opacity-90 focus:outline-none ${
                isSubmitting ? "opacity-70 cursor-not-allowed" : ""
              }`}
            >
              {isSubmitting ? (
                <div className="flex items-center justify-center">
                  <div className="w-5 h-5 border-t-2 border-r-2 border-white rounded-full animate-spin mr-2"></div>
                  <span>
                    {uploadProgress > 0
                      ? `Uploading... ${uploadProgress}%`
                      : "Logging Parcel..."}
                  </span>
                </div>
              ) : (
                "Log Parcel"
              )}
            </button>
          </div>
        </form>
      )}
    </div>
  );
}
