import React from "react";

export default function NewWorkPermit({ setAddMode }) {
  return (
    <div className="relative">
      <button className="close-button" onClick={() => setAddMode(false)}>
        Close
      </button>
      <div className="header">New Work Permit</div>
    </div>
  );
}
