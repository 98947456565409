import React from "react";
import { motion } from "framer-motion";

export default function SideModal({ children }) {
  return (
    <motion.div
      initial={{ opacity: 0, x: 100 }}
      animate={{ opacity: 1, x: 0 }}
      exit={{ opacity: 0, x: 100 }}
      transition={{ duration: 0.3 }}
      className="absolute top-0 right-0 w-screen lg:max-w-[450px] bg-black bg-opacity-50 z-20 flex items-start justify-center p-2 lg:p-8"
    >
      <motion.div className="flex items-start justify-center lg:max-w-[450px] w-screen min-h-screen">
        {children}
      </motion.div>
    </motion.div>
  );
}
