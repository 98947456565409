import React from "react";
import { FiArrowRight } from "react-icons/fi";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "../../../components/card";

function AnnouncementCard({
  announcement,
  onCardClick,
  onPhotoClick,
  formatDate,
}) {
  return (
    <Card
      className="h-full border rounded-lg overflow-hidden bg-white cursor-pointer"
      onClick={() => onCardClick(announcement)}
    >
      {announcement.isNew && (
        <div className="bg-[#8FCD00] text-white px-3 py-1 absolute top-3 left-3 rounded-md text-xs font-bold z-10">
          NEW
        </div>
      )}

      <CardHeader className="pb-2">
        <CardTitle className="text-base font-bold text-[#363062]">
          {announcement.title}
        </CardTitle>
        <CardDescription className="text-sm text-gray-600">
          {announcement.description}
        </CardDescription>
      </CardHeader>

      {announcement.imageUrl && (
        <div className="px-4">
          <img
            src={announcement.imageUrl}
            alt={announcement.title}
            className="w-full h-36 object-cover rounded-md cursor-pointer"
            onClick={(e) => onPhotoClick(e, announcement.imageUrl)}
          />
        </div>
      )}

      <CardContent className="pt-3 text-sm">
        <p className="text-gray-700 line-clamp-3">{announcement.content}</p>
      </CardContent>

      <CardFooter className="flex justify-between items-center">
        <p className="text-xs text-gray-500">
          {formatDate(announcement.createdAt)}
        </p>

        {announcement.link && (
          <a
            href={announcement.link}
            onClick={(e) => e.stopPropagation()}
            className="flex items-center text-xs text-[#363062] hover:text-[#8FCD00] transition"
          >
            Learn More <FiArrowRight className="ml-1" />
          </a>
        )}
      </CardFooter>
    </Card>
  );
}

export default AnnouncementCard;
