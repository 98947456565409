import React, { useEffect } from "react";
import { useCompany } from "../context/CompanyContext";
import ChatBox from "./ChatBox";

const AppWrapper = ({ children }) => {
  const { companyType } = useCompany();

  useEffect(() => {
    // Set a data attribute on the document body to enable global CSS styling based on company type
    document.body.setAttribute("data-company-type", companyType);

    return () => {
      document.body.removeAttribute("data-company-type");
    };
  }, [companyType]);

  return (
    <>
      {children}
      {/* <ChatBox /> */}
    </>
  );
};

export default AppWrapper;
