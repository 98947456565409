import React from "react";

export default function FacilityReqPage({ data, setViewMOde }) {
  const formatDate = (timestamp) => {
    if (!timestamp) return "";
    // Handle both Firestore Timestamp objects and string dates
    if (timestamp.seconds) {
      const date = new Date(timestamp.seconds * 1000);
      return date.toLocaleString("en-US", {
        year: "numeric",
        month: "long",
        day: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
        timeZone: "UTC",
      });
    }
    return timestamp; // If it's already a string, return as is
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4">
      <div className="bg-white rounded-lg shadow-xl w-full max-w-2xl p-6 relative">
        <button
          className="absolute top-4 right-4 text-gray-500 hover:text-gray-700"
          onClick={() => setViewMOde(false)}
        >
          <svg
            className="w-6 h-6"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>

        <h2 className="text-2xl font-semibold text-gray-800 mb-6">
          Facility Request Details
        </h2>

        <div className="grid grid-cols-2 gap-6">
          <div className="space-y-4">
            <div>
              <label className="text-sm font-medium text-gray-500">
                Booking Type
              </label>
              <p className="text-base text-gray-800">{data?.bookingType}</p>
            </div>

            <div>
              <label className="text-sm font-medium text-gray-500">Date</label>
              <p className="text-base text-gray-800">
                {formatDate(data?.date)}
              </p>
            </div>

            <div>
              <label className="text-sm font-medium text-gray-500">Time</label>
              <p className="text-base text-gray-800">
                {data?.startTime} - {data?.endTime}
              </p>
            </div>

            <div>
              <label className="text-sm font-medium text-gray-500">
                Status
              </label>
              <span
                className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${
                  data?.status === "Pending payment"
                    ? "bg-yellow-100 text-yellow-800"
                    : "bg-green-100 text-green-800"
                }`}
              >
                {data?.status}
              </span>
            </div>
          </div>

          <div className="space-y-4">
            <div>
              <label className="text-sm font-medium text-gray-500">
                Requestor Name
              </label>
              <p className="text-base text-gray-800">{data?.createdName}</p>
            </div>

            <div>
              <label className="text-sm font-medium text-gray-500">Email</label>
              <p className="text-base text-gray-800">{data?.createdEmail}</p>
            </div>

            <div>
              <label className="text-sm font-medium text-gray-500">
                Created At
              </label>
              <p className="text-base text-gray-800">
                {formatDate(data?.createdAt)}
              </p>
            </div>

            <div>
              <label className="text-sm font-medium text-gray-500">
                Reference ID
              </label>
              <p className="text-base text-gray-800 font-mono">
                {data?.createdBy}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
