import React, { useEffect, useState } from "react";
import {
  getDocDataSnap,
  queryAllDocsByFieldSnap,
} from "../../../utils/firebase/Firebase.utils";
import WorkPermitCards from "../WorkPermits/WorkPermitCards";
import { slicedData } from "../../../utils/EleghoUtils";
import BentoTable from "../../../components/BentoTable";
import PageModal from "../Components/PageModal";
import GuestPassCard from "../../Security/GuestPassCard";
import GuestPassDetailsPage from "../../Security/GuestPassDetailsPage";

export default function ResidentGuestPass({ identifier }) {
  const [concerns, setConcerns] = useState([]);
  const [loading, setLoading] = useState(true);
  const [numberOfItemPerPage, setNumberOfItemPerPage] = useState(5);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedForView, setSelectedForView] = useState(null);
  const [viewMode, setViewMode] = useState(false);

  useEffect(() => {
    const fetchConcerns = async () => {
      await queryAllDocsByFieldSnap(
        "guests",
        "linkedIdentifiers",
        "array-contains",
        identifier,
        (data) => {
          setConcerns(data);
          setLoading(false);
        }
      );
    };
    fetchConcerns();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  const dataSliced = slicedData(concerns, numberOfItemPerPage, currentPage);

  const handleViewMode = (concern) => {
    setViewMode(true);
    setSelectedForView(concern);
  };

  return (
    <div>
      <BentoTable
        label="Guest Pass"
        data={dataSliced}
        numberOfItemPerPage={numberOfItemPerPage}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
      >
        {concerns.map((concern, index) => (
          <div onClick={() => handleViewMode(concern)} key={index}>
            <GuestPassCard data={concern} />
          </div>
        ))}
      </BentoTable>

      {viewMode && (
        <PageModal>
          <GuestPassDetailsPage
            data={selectedForView}
            setViewMode={() => setViewMode(false)}
          />
        </PageModal>
      )}
    </div>
  );
}
