import React, { useEffect, useState } from "react";
import UserScreenWindow from "../UserScreenWindow";
import { useParams } from "react-router-dom";
import { getDocData } from "../../../utils/firebase/Firebase.utils";

export default function ConcernConfirmation() {
  const { id } = useParams();
  const [concern, setConcern] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchConcern = async () => {
      const data = await getDocData("concerns", id);
      setConcern(data);
      console.log(data);
      setIsLoading(false);
    };
    fetchConcern();
  }, []);

  const concernObject = concern;

  if (isLoading) {
    return (
      <UserScreenWindow label="Concern Confirmation">
        <div className="flex justify-center items-center h-64">
          <div className="animate-spin rounded-full h-10 w-10 border-t-2 border-b-2 border-[#8FCD00]"></div>
        </div>
      </UserScreenWindow>
    );
  }

  return (
    <UserScreenWindow label="Concern Confirmation">
      <div className="p-4 max-w-3xl mx-auto">
        <h1 className="text-lg font-semibold text-[#363062] mb-4 border-b border-gray-200 pb-2">
          Concern Confirmation
        </h1>

        <div className="bg-white rounded-lg shadow-sm p-5 mb-6">
          <div className="grid md:grid-cols-2 gap-4">
            <div>
              <div className="mb-4">
                <p className="text-sm text-gray-500">Concern Type</p>
                <p className="font-medium text-[#363062]">
                  {concern?.concern || "Water Supply"}
                </p>
              </div>

              <div className="mb-4">
                <p className="text-sm text-gray-500">Location</p>
                <p className="font-medium text-[#363062]">
                  {concern?.identifier || "Unit 6e - Tower 2"}
                </p>
              </div>

              <div className="mb-4">
                <p className="text-sm text-gray-500">Reported By</p>
                <p className="font-medium text-[#363062]">
                  {concern?.createdBy || "Alvin Magat"}
                </p>
              </div>

              <div className="mb-4">
                <p className="text-sm text-gray-500">Date Submitted</p>
                <p className="font-medium text-[#363062]">
                  {concern?.createdAt || "3/25/2025, 5:43:07 PM"}
                </p>
              </div>
            </div>

            <div>
              <div className="mb-4">
                <p className="text-sm text-gray-500">Status</p>
                <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-yellow-100 text-yellow-800">
                  {concern?.status || "Pending"}
                </span>
              </div>

              <div className="mb-4">
                <p className="text-sm text-gray-500">Description</p>
                <p className="font-medium text-[#363062]">
                  {concern?.description || "Pressure too much now"}
                </p>
              </div>
            </div>
          </div>
        </div>

        {(concern?.image || concernObject.image) && (
          <div className="mb-6">
            <p className="text-sm text-gray-500 mb-2">Attached Image</p>
            <div className="bg-gray-100 rounded-lg overflow-hidden">
              <img
                src={concern?.image || concernObject.image}
                alt="Concern attachment"
                className="w-full object-contain max-h-64"
              />
            </div>
          </div>
        )}

        <div className="flex justify-end w-full">
          <button
            className="px-4 py-2 bg-[#8FCD00] w-full text-white rounded hover:bg-opacity-90 transition-colors"
            onClick={() => window.history.back()}
          >
            Back to Concerns
          </button>
        </div>
      </div>
    </UserScreenWindow>
  );
}
