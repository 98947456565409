import React, { useEffect, useState, useRef } from "react";
import BackButton from "../../components/BackButton";
import { getAllDocsSnap } from "../../utils/firebase/Firebase.utils";
import {
  FaPlus,
  FaSearch,
  FaClipboardList,
  FaSortAmountDown,
  FaSortAmountUp,
  FaFilter,
  FaTimes,
  FaExclamationCircle,
} from "react-icons/fa";
import { useNavigate } from "react-router-dom";

export default function AdminMobileConcerns() {
  const [concerns, setConcerns] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("all");
  const [sortOrder, setSortOrder] = useState("newest");
  const [unitIdentifiers, setUnitIdentifiers] = useState([]);
  const [showUnitDropdown, setShowUnitDropdown] = useState(false);
  const [filterText, setFilterText] = useState("");
  const [selectedUnit, setSelectedUnit] = useState("");
  const dropdownRef = useRef(null);
  const navigate = useNavigate();

  // Close dropdown when clicking outside
  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowUnitDropdown(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const sampleObject = {
    image: "",
    identifier: "Unit 6e - Tower 2",
    concern: "Water Leaks",
    uid: "PfcH65WfW6bHRnKBliDRUL6Gp232",
    status: "Pending",
    user: "cring.sayson@infinity8data.com",
    description: "Leaky faucet",
    createdAt: "3/27/2025, 2:36:12 PM",
    video: "",
    createdBy: "Ma Christina Sayson",
    id: "02c9e11d-c3fb-493f-9025-9e99dd3edfc0",
  };

  useEffect(() => {
    const fetchConcerns = async () => {
      await getAllDocsSnap("concerns", (docs) => {
        setConcerns(docs);
        // Extract unique unit identifiers
        const uniqueUnits = [
          ...new Set(docs.map((doc) => doc.identifier)),
        ].sort();
        setUnitIdentifiers(uniqueUnits);
        setIsLoading(false);
      });
    };
    fetchConcerns();
  }, []);

  // Sort concerns by creation date
  const sortConcernsByDate = (concerns) => {
    return [...concerns].sort((a, b) => {
      const dateA = new Date(a.createdAt);
      const dateB = new Date(b.createdAt);
      return sortOrder === "newest" ? dateB - dateA : dateA - dateB;
    });
  };

  // Validate that a selected unit exists in the unitIdentifiers list
  const isValidUnit = (unit) => {
    return !unit || unitIdentifiers.includes(unit);
  };

  const filteredConcerns = concerns.filter((concern) => {
    const searchLower = searchQuery.toLowerCase();
    const matchesSearch =
      !searchQuery ||
      concern.concern.toLowerCase().includes(searchLower) ||
      concern.description.toLowerCase().includes(searchLower) ||
      concern.createdBy.toLowerCase().includes(searchLower);

    const matchesStatus =
      selectedStatus === "all" || concern.status === selectedStatus;

    // Only filter by unit if a valid unit has been selected
    const matchesUnit =
      !selectedUnit ||
      (isValidUnit(selectedUnit) && concern.identifier === selectedUnit);

    return matchesSearch && matchesStatus && matchesUnit;
  });

  // Filter unit identifiers based on search text
  const filteredUnitIdentifiers = unitIdentifiers.filter((unit) =>
    unit.toLowerCase().includes(filterText.toLowerCase())
  );

  // Apply sorting to filtered concerns
  const sortedConcerns = sortConcernsByDate(filteredConcerns);

  const toggleSortOrder = () => {
    setSortOrder(sortOrder === "newest" ? "oldest" : "newest");
  };

  const handleUnitSelect = (unit) => {
    // Only set the selected unit if it exists in the unitIdentifiers list
    if (unitIdentifiers.includes(unit)) {
      setSelectedUnit(unit);
      setShowUnitDropdown(false);
      setFilterText("");
    }
  };

  const clearUnitFilter = () => {
    setSelectedUnit("");
  };

  // Function to handle filter text changes
  const handleFilterTextChange = (e) => {
    setFilterText(e.target.value);
    // Don't auto-select when typing - force dropdown selection
  };

  // Handle when Enter key is pressed in the filter text input
  const handleFilterKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      // If there's exactly one match and it's valid, select it
      if (filteredUnitIdentifiers.length === 1) {
        handleUnitSelect(filteredUnitIdentifiers[0]);
      }
    }
  };

  const statusButtons = [
    { label: "All", value: "all", color: "bg-gray-100 text-gray-800" },
    {
      label: "Pending",
      value: "Pending",
      color: "bg-yellow-100 text-yellow-800",
    },
    {
      label: "Dispatched",
      value: "Dispatched",
      color: "bg-blue-100 text-blue-800",
    },
    {
      label: "Processing",
      value: "Processing",
      color: "bg-purple-100 text-purple-800",
    },
    {
      label: "Resolved",
      value: "Resolved",
      color: "bg-green-100 text-green-800",
    },
  ];

  if (isLoading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-[#363062]"></div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-50">
      {/* Header */}
      <div className="sticky top-0 bg-white shadow-sm z-10">
        <div className="flex items-center justify-between p-4">
          <div className="flex items-center gap-2">
            <BackButton />
            <h1 className="text-lg font-semibold text-[#363062]">Concerns</h1>
          </div>
          <button
            onClick={() => {
              navigate(
                "/admin/mobile/add-concern"
              ); /* TODO: Add navigation to register concern form */
            }}
            className="flex items-center gap-2 px-4 py-2 bg-[#363062] text-white rounded-xl text-sm font-medium hover:bg-[#2a264d] transition-colors"
          >
            <FaClipboardList className="w-4 h-4" />
            <span>Add</span>
          </button>
        </div>
      </div>

      {/* Search and Filters */}
      <div className="p-4 space-y-4">
        <div className="flex gap-2">
          <div className="relative flex-1">
            <FaSearch className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
            <input
              type="text"
              placeholder="Search concerns..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              className="w-full pl-10 pr-4 py-2.5 rounded-xl border border-gray-200 focus:outline-none focus:ring-2 focus:ring-[#363062] focus:border-transparent text-[#363062] placeholder-gray-400 bg-white"
            />
          </div>
          <button
            onClick={toggleSortOrder}
            className="px-3 py-2.5 rounded-xl border border-gray-200 bg-white text-[#363062] hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-[#363062]"
            title={sortOrder === "newest" ? "Newest first" : "Oldest first"}
          >
            {sortOrder === "newest" ? (
              <FaSortAmountDown className="w-5 h-5" />
            ) : (
              <FaSortAmountUp className="w-5 h-5" />
            )}
          </button>
          <div className="relative" ref={dropdownRef}>
            <button
              onClick={() => setShowUnitDropdown(!showUnitDropdown)}
              className={`px-3 py-2.5 rounded-xl border ${
                selectedUnit
                  ? "border-[#363062] bg-[#363062] bg-opacity-5"
                  : "border-gray-200 bg-white"
              } text-[#363062] hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-[#363062]`}
              title="Filter by unit"
            >
              <FaFilter className="w-5 h-5" />
            </button>
            {showUnitDropdown && (
              <div className="absolute right-0 mt-2 w-64 bg-white rounded-xl shadow-lg border border-gray-200 z-20">
                <div className="p-3 border-b border-gray-100">
                  <div className="relative">
                    <FaSearch className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
                    <input
                      type="text"
                      placeholder="Search units..."
                      value={filterText}
                      onChange={handleFilterTextChange}
                      onKeyDown={handleFilterKeyDown}
                      className="w-full pl-10 pr-4 py-2 rounded-lg border border-gray-200 focus:outline-none focus:ring-2 focus:ring-[#363062] focus:border-transparent text-[#363062] placeholder-gray-400 bg-white text-sm"
                      aria-label="Search units - selection required"
                    />
                  </div>
                  <div className="mt-2 text-xs text-[#363062]">
                    <span className="flex items-center gap-1">
                      <FaExclamationCircle className="w-3 h-3" />
                      Select a unit from the list below
                    </span>
                  </div>
                </div>
                <div className="max-h-64 overflow-y-auto py-2">
                  {filteredUnitIdentifiers.length > 0 ? (
                    filteredUnitIdentifiers.map((unit) => (
                      <div
                        key={unit}
                        onClick={() => handleUnitSelect(unit)}
                        className={`px-4 py-2 cursor-pointer hover:bg-gray-50 text-sm ${
                          selectedUnit === unit
                            ? "bg-[#363062] bg-opacity-5 font-medium text-[#363062]"
                            : ""
                        }`}
                      >
                        {unit}
                      </div>
                    ))
                  ) : (
                    <div className="px-4 py-2 text-sm text-gray-500">
                      No units found
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>

        {/* Selected Unit Tag */}
        {selectedUnit && isValidUnit(selectedUnit) && (
          <div className="flex items-center gap-2">
            <div className="inline-flex items-center gap-1.5 bg-[#363062] bg-opacity-10 text-[#363062] px-3 py-1.5 rounded-lg text-sm">
              <span className="font-medium">Unit:</span> {selectedUnit}
              <button
                onClick={clearUnitFilter}
                className="ml-1 text-[#363062] hover:text-[#2a264d]"
              >
                <FaTimes className="w-3.5 h-3.5" />
              </button>
            </div>
          </div>
        )}

        {/* Status Filters */}
        <div className="flex overflow-x-auto pb-2 gap-2 -mx-4 px-4 py-2">
          {statusButtons.map((button) => (
            <button
              key={button.value}
              onClick={() => setSelectedStatus(button.value)}
              className={`px-4 py-2 rounded-xl text-sm font-medium whitespace-nowrap transition-colors ${
                selectedStatus === button.value
                  ? `${button.color} ring-2 ring-[#363062]`
                  : "bg-white border border-gray-200 text-gray-700 hover:bg-gray-50"
              }`}
            >
              {button.label}
            </button>
          ))}
        </div>
      </div>

      {/* Concerns List */}
      <div className="px-4 pb-20">
        {sortedConcerns.length > 0 ? (
          sortedConcerns.map((concern) => (
            <div
              key={concern.id}
              className="bg-white rounded-xl shadow-sm p-4 mb-4 border border-gray-100"
            >
              <div className="flex justify-between items-start mb-3">
                <h2 className="text-base font-medium text-[#363062]">
                  {concern.identifier}
                </h2>
                <span
                  className={`px-3 py-1 rounded-full text-xs font-medium ${
                    concern.status === "Pending"
                      ? "bg-yellow-100 text-yellow-800"
                      : concern.status === "Resolved"
                      ? "bg-[#8FCD00] bg-opacity-10 text-[#8FCD00]"
                      : "bg-blue-100 text-blue-800"
                  }`}
                >
                  {concern.status}
                </span>
              </div>
              <div className="space-y-2">
                <p className="text-sm text-gray-700">
                  <span className="font-medium text-[#363062]">Concern:</span>{" "}
                  {concern.concern}
                </p>
                <p className="text-sm text-gray-700">
                  <span className="font-medium text-[#363062]">
                    Description:
                  </span>{" "}
                  {concern.description}
                </p>
                <div className="flex items-center justify-between text-xs text-gray-500">
                  <span>{concern.createdBy}</span>
                  <span>{concern.createdAt}</span>
                </div>
              </div>
              {(concern.image || concern.video) && (
                <div className="mt-3 rounded-lg overflow-hidden">
                  {concern.image && (
                    <img
                      src={concern.image}
                      alt="Concern"
                      className="w-full h-40 object-cover"
                    />
                  )}
                  {concern.video && (
                    <video
                      src={concern.video}
                      controls
                      className="w-full h-40 object-cover"
                    />
                  )}
                </div>
              )}
            </div>
          ))
        ) : (
          <div className="text-center py-10">
            <p className="text-gray-500">No concerns match your filters</p>
          </div>
        )}
      </div>
    </div>
  );
}
