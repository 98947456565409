import React from "react";
import CancelPresentationIcon from "@mui/icons-material/CancelPresentation";

export default function CloseButton() {
  return (
    <>
      <CancelPresentationIcon />
    </>
  );
}
