import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  getAllDocsSnap,
  getDocDataSnap,
  setDocData,
} from "../../../utils/firebase/Firebase.utils";
import SelectInput from "../Components/SelectInput";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import Page from "../../../components/Page";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CloseButton from "../../../components/CloseButton";
import CommentBlock from "../../../components/CommentBlock";
import { useCompany } from "../../../context/CompanyContext";
import CompanyTerms from "../../../components/CompanyTerms";
import CompanyTermsWrapper from "../../../components/CompanyTermsWrapper";
import {
  sendNotification,
  sendBulkNotifications,
} from "../../../utils/notifications";
import { storeNotification } from "../../../services/notificationService";
import {
  createWorkPermitStatusNotification,
  createWorkPermitCommentNotification,
  createWorkPermitAssignmentNotification,
} from "../../../utils/workPermitNotifications";

export default function WorkPermitDetails({ application, setViewMode }) {
  const { id } = useParams();
  const currentUser = useSelector((state) => state.userObject);
  const { getTerm, getCapitalizedTerm } = useCompany();

  const [isLoading, setIsLoading] = React.useState(true);
  const [editMode, setEditMode] = React.useState(false);
  const [isProcessing, setIsProcessing] = React.useState(false);
  const [formData, setFormData] = React.useState(application);
  const [status, setStatus] = React.useState(application.status);
  const [comments, setComments] = React.useState([]);
  const [newComment, setNewComment] = React.useState("");
  const [files, setFiles] = React.useState({});
  const [adminUsers, setAdminUsers] = React.useState([]);

  const [assignedToArray, setAssignedToArray] = React.useState(
    application.assignedTo || []
  );

  useEffect(() => {
    const fetchAdminUsers = async () => {
      await getAllDocsSnap("users", (users) => {
        const adminUsers = users.filter((user) => !user.roles.includes("user"));
        setAdminUsers(adminUsers);
      });
    };
    fetchAdminUsers();
  }, []);

  useEffect(() => {
    const fetchApplication = async () => {
      getDocDataSnap("workPermits", application.id, (data) => {
        setFormData(data);
        setFiles(data.files);
        setStatus(data.status);
        setComments(data.comments || []);
        setIsLoading(false);
      });
    };
    fetchApplication();
  }, []);

  if (isLoading) {
    return (
      <div>
        <CompanyTermsWrapper>Loading...</CompanyTermsWrapper>
      </div>
    );
  }

  const userOptions = adminUsers.map((user) => ({
    value: user.email,
    label: user.displayName,
  }));

  const handleUpdateStatus = (e) => {
    setIsProcessing(true);
    setStatus(e.value);
  };

  const handleEditMode = async () => {
    setEditMode(!editMode);

    if (editMode) {
      try {
        await setDocData("workPermits", application.id, {
          ...formData,
          status,
          assignedTo: assignedToArray,
          approvedAt: status === "Approved" ? new Date().toLocaleString() : "",
          approvedBy: status === "Approved" ? currentUser.displayName : "",
          history: [
            ...(formData?.history || ""),
            {
              action: "Status Updated",
              by: currentUser.displayName,
              at: new Date().toLocaleString(),
              to: status,
            },
          ],
        });

        // Send notification to the requestor
        if (
          formData.requestedBy &&
          formData.requestedBy !== currentUser.email
        ) {
          const notification = createWorkPermitStatusNotification(
            application.id,
            status,
            formData.workPermitType,
            currentUser.displayName
          );

          // Store notification in Firestore
          await storeNotification(formData.requestedBy, notification);

          // Try sending FCM notification
          await sendNotification(formData.requestedBy, notification);
        }

        // Send notifications to assigned users
        if (assignedToArray && assignedToArray.length > 0) {
          const assignedEmails = assignedToArray
            .map((user) => user.value)
            .filter(
              (email) =>
                email !== formData.requestedBy && email !== currentUser.email
            );

          if (assignedEmails.length > 0) {
            const notification = createWorkPermitStatusNotification(
              application.id,
              status,
              formData.workPermitType,
              currentUser.displayName
            );

            // Store notification for each assigned user
            for (const email of assignedEmails) {
              await storeNotification(email, notification);
            }

            // Try sending FCM notifications
            await sendBulkNotifications(assignedEmails, notification);
          }
        }

        toast.success("Status updated successfully");
        setViewMode(false);
      } catch (error) {
        toast.error("Failed to update status");
      }
    }
  };

  const options = [
    { label: "Pending", value: "Pending" },
    { label: "Approved", value: "Approved" },
    { label: "Returned", value: "Returned" },
  ];

  const handleCancel = () => {
    setEditMode(false);
  };

  const handleChange = (e) => {
    setNewComment(e.target.value);
  };

  const handleAddComment = async () => {
    if (!newComment || newComment.trim() === "") {
      toast.error("Comment cannot be empty");
      return;
    }

    const comment = {
      message: newComment,
      createdAt: new Date().toLocaleString(),
      createdBy: currentUser.displayName,
      email: currentUser.email,
    };

    const docObject = {
      ...formData,
      comments: [...comments, comment],
      updatedBy: currentUser.email,
      updatedByName: currentUser.displayName,
      updatedAt: new Date().toLocaleString(),
      lastUpdateType: "Comment Added",
    };

    try {
      await setDocData("workPermits", application.id, docObject);

      // Send notification to the requestor if the current user is not the requestor
      if (formData.requestedBy && formData.requestedBy !== currentUser.email) {
        const notification = createWorkPermitCommentNotification(
          application.id,
          formData.workPermitType,
          currentUser.displayName,
          newComment
        );

        // Store notification in Firestore
        await storeNotification(formData.requestedBy, notification);

        // Try sending FCM notification
        await sendNotification(formData.requestedBy, notification);
      }

      // Send notifications to assigned users
      if (assignedToArray && assignedToArray.length > 0) {
        const assignedEmails = assignedToArray
          .map((user) => user.value)
          .filter(
            (email) =>
              email !== formData.requestedBy && email !== currentUser.email
          );

        if (assignedEmails.length > 0) {
          const notification = createWorkPermitCommentNotification(
            application.id,
            formData.workPermitType,
            currentUser.displayName,
            newComment
          );

          // Store notification for each assigned user
          for (const email of assignedEmails) {
            await storeNotification(email, notification);
          }

          // Try sending FCM notifications
          await sendBulkNotifications(assignedEmails, notification);
        }
      }

      toast.success("Comment added successfully");
      setComments([...comments, comment]);
      setNewComment("");
    } catch (error) {
      toast.error("Failed to add comment");
    }
  };

  const handleDeleteComment = async (index) => {
    if (window.confirm("Are you sure you want to delete this comment?")) {
      const newComments = comments.filter((comment, i) => i !== index);

      const docObject = {
        ...formData,
        comments: newComments,
        updatedBy: currentUser.email,
        updatedByName: currentUser.displayName,
        updatedAt: new Date().toLocaleString(),
        lastUpdateType: "Comment Deleted",
      };

      try {
        await setDocData("workPermits", application.id, docObject);
        toast.success("Comment deleted successfully");
        setComments(newComments);
      } catch (error) {
        toast.error("Failed to delete comment");
      }
    }
  };

  const filesArray =
    files !== undefined
      ? Object.keys(files).map((key) => {
          return {
            name: key,
            url: files[key],
          };
        })
      : [];

  const handleSaveAssignedTo = async () => {
    setIsProcessing(true);
    const docObject = {
      ...formData,
      assignedTo: assignedToArray,
      updatedBy: currentUser.email,
      updatedByName: currentUser.displayName,
      updatedAt: new Date().toLocaleString(),
      history: [
        ...(formData?.history || ""),
        {
          action: "Assigned to",
          by: currentUser.displayName,
          at: new Date().toLocaleString(),
          to: assignedToArray.map((item) => item.label).join(", "),
        },
      ],
    };

    try {
      await setDocData("workPermits", application.id, docObject);

      // Send notifications to newly assigned users
      const previouslyAssigned = new Set(
        (formData.assignedTo || []).map((user) => user.value)
      );
      const newlyAssigned = assignedToArray
        .filter((user) => !previouslyAssigned.has(user.value))
        .map((user) => user.value);

      if (newlyAssigned.length > 0) {
        const notification = createWorkPermitAssignmentNotification(
          application.id,
          formData.workPermitType,
          assignedToArray
        );

        // Store notification for each newly assigned user
        for (const email of newlyAssigned) {
          await storeNotification(email, notification);
        }

        // Try sending FCM notifications
        await sendBulkNotifications(newlyAssigned, notification);
      }

      // Notify the requestor about assignment changes
      if (formData.requestedBy && formData.requestedBy !== currentUser.email) {
        const notification = createWorkPermitAssignmentNotification(
          application.id,
          formData.workPermitType,
          assignedToArray
        );

        // Store notification in Firestore
        await storeNotification(formData.requestedBy, notification);

        // Try sending FCM notification
        await sendNotification(formData.requestedBy, notification);
      }

      toast.success("Assigned successfully");
      setIsProcessing(false);
    } catch (error) {
      toast.error("Failed to assign");
      setIsProcessing(false);
    }
  };

  return (
    <div className="page relative text-gray-600 text-xs !bg-gray-100">
      <button className="close-button" onClick={() => setViewMode(false)}>
        <CloseButton />
      </button>
      <div className="text-white  text-lg font-semibold bg-eleghoBlue p-2 rounded-lg flex items-center justify-between">
        <div>
          <CompanyTermsWrapper>Work Permit Request Details</CompanyTermsWrapper>
        </div>
        <div className="flex items-center gap-4">
          <button className="green-button" onClick={handleEditMode}>
            {editMode ? (
              <CompanyTermsWrapper>Save</CompanyTermsWrapper>
            ) : (
              <CompanyTermsWrapper>Edit</CompanyTermsWrapper>
            )}
          </button>
          {editMode && (
            <button className="underline text-xs" onClick={handleCancel}>
              <CompanyTermsWrapper>Cancel</CompanyTermsWrapper>
            </button>
          )}
        </div>
      </div>
      <div className="flex items-start justify-normal gap-4 flex-col lg:flex-row mt-4">
        <div className="w-96">
          <div className=" bg-white p-4 rounded-lg shadow-sm">
            <div className="text-lg font-semibold text-eleghoBlue border-b border-gray-400 mb-2">
              <CompanyTermsWrapper>Request Details</CompanyTermsWrapper>
            </div>
            <div className="">
              <div className="flex items-center justify-between gap-8 max-w-[550px] p-1">
                <div className="w-60 font-bold">
                  <CompanyTerms term="identifier" capitalize={true} />
                </div>
                <div className="w-60 text-right">{formData?.identifier}</div>
              </div>
              <div className="flex items-center justify-between gap-8 max-w-[550px] p-1">
                <div className="w-60 font-bold">
                  <CompanyTermsWrapper>Request Type</CompanyTermsWrapper>
                </div>
                <div className="w-60 text-right">
                  {formData?.workPermitType}
                </div>
              </div>
              <div className="flex items-center justify-between gap-8 max-w-[550px] p-1">
                <div className="w-60 font-bold">
                  <CompanyTermsWrapper>
                    Duration of Activities
                  </CompanyTermsWrapper>
                </div>
                <div className="w-60 text-right">{formData?.duration}</div>
              </div>
              <div className="flex items-start justify-between gap-8 max-w-[550px]  p-1">
                <div className="w-60 font-bold">
                  <CompanyTermsWrapper>Description of Work</CompanyTermsWrapper>
                </div>
                <div className="w-60 text-right">{formData?.description}</div>
              </div>
              <div className="flex items-center justify-between gap-8 max-w-[550px]  p-1">
                <div className="w-60 font-bold">
                  <CompanyTermsWrapper>Vehicle Information</CompanyTermsWrapper>
                </div>
                <div className="w-60 text-right">{formData?.vehicle}</div>
              </div>
              <div className="flex items-center justify-between gap-8 max-w-[550px]  p-1">
                <div className="w-60 font-bold">
                  <CompanyTermsWrapper>Workers List</CompanyTermsWrapper>
                </div>
                <div className="w-60 text-right">{formData?.workers}</div>
              </div>
              <div className="flex items-center justify-between gap-8 max-w-[550px]  p-1">
                <div className="w-60 font-bold">
                  <CompanyTermsWrapper>Requested By</CompanyTermsWrapper>
                </div>
                <div className="w-60 text-right">{formData?.name}</div>
              </div>
              <div className="flex items-center justify-between gap-8 max-w-[550px] p-1">
                <div className="w-60 font-bold">
                  <CompanyTermsWrapper>Status</CompanyTermsWrapper>
                </div>
                <div className="w-60 text-right">{formData?.status}</div>
              </div>
              {status === "Approved" && (
                <div className="flex items-center justify-between gap-8 max-w-[550px] p-1">
                  <div className="w-60 font-bold">
                    <CompanyTermsWrapper>Approved By</CompanyTermsWrapper>
                  </div>
                  <div className="w-60 text-right">{formData?.approvedBy}</div>
                </div>
              )}
            </div>
          </div>

          {/* Documents here */}
          <div className="mt-4 bg-white p-4 rounded-lg shadow-sm">
            {filesArray && (
              <div className="">
                <div className="text-eleghoBlue font-bold flex items-center gap-2">
                  <div>
                    <AttachFileIcon />
                  </div>
                  <div>
                    <CompanyTermsWrapper>Documents</CompanyTermsWrapper>
                  </div>
                </div>
                {filesArray.map((file, index) => (
                  <div
                    key={index}
                    className="flex items-center justify-between gap-8 max-w-[550px] p-2 bg-gray-100 min-w-16 rounded-lg mt-2"
                  >
                    <div className="w-60 font-bold">{file.name}</div>
                    <div className="bg-green-400 px-2 py-1 rounded-lg w-fit text-center font-semibold">
                      <a href={file.url} target="_blank" rel="noreferrer">
                        <CompanyTermsWrapper>View Document</CompanyTermsWrapper>
                      </a>
                    </div>
                  </div>
                ))}
                {filesArray.length === 0 && (
                  <div className="text-center mt-4">
                    <CompanyTermsWrapper>
                      No documents uploaded
                    </CompanyTermsWrapper>
                  </div>
                )}
              </div>
            )}
          </div>

          {/* Update Status here */}
          <div className="">
            {editMode && (
              <div>
                <div className="w-96 mt-4">
                  <SelectInput
                    name="status"
                    label={
                      <CompanyTermsWrapper>Update Status</CompanyTermsWrapper>
                    }
                    options={options}
                    value={options.find((option) => option.value === status)}
                    onChange={handleUpdateStatus}
                    required
                  />
                </div>

                <div className="flex items-center justify-end gap-4 mt-4 ">
                  <button className="blue-button" onClick={handleEditMode}>
                    {editMode ? (
                      <CompanyTermsWrapper>Save</CompanyTermsWrapper>
                    ) : (
                      <CompanyTermsWrapper>Edit</CompanyTermsWrapper>
                    )}
                  </button>
                  {editMode && (
                    <button
                      className="underline text-xs"
                      onClick={handleCancel}
                    >
                      <CompanyTermsWrapper>Cancel</CompanyTermsWrapper>
                    </button>
                  )}
                </div>
              </div>
            )}
          </div>

          <div className="bg-white p-4 rounded-lg shadow-sm w-96 mt-4">
            <div className="text-lg font-semibold text-eleghoBlue border-b border-gray-400">
              <CompanyTermsWrapper>Assign this task</CompanyTermsWrapper>
            </div>
            <div className="mt-4">
              <SelectInput
                label={<CompanyTermsWrapper>Assign To</CompanyTermsWrapper>}
                name="assignedTo"
                options={userOptions}
                isMulti
                onChange={(selected) => setAssignedToArray(selected)}
                value={assignedToArray}
                disabled={!editMode}
                required
              />
              <div className="text-right mt-4">
                <button
                  className="blue-button"
                  disabled={!editMode}
                  onClick={handleSaveAssignedTo}
                >
                  {isProcessing ? (
                    <CompanyTermsWrapper>Processing...</CompanyTermsWrapper>
                  ) : (
                    <CompanyTermsWrapper>Assign</CompanyTermsWrapper>
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>

        {/* Update Comments here */}
        <div className="w-full max-w-[1080px]">
          <div className="section-box">
            <div className="header">
              <CompanyTermsWrapper>Comments</CompanyTermsWrapper>
            </div>
            <div className="w-full">
              <textarea
                name="comment"
                rows={3}
                className="border rounded-lg w-full mt-4 p-2 bg-gray-100"
                id=""
                placeholder={getTerm("Enter your comments here")}
                onChange={handleChange}
                value={newComment}
              />
            </div>
            <div className="text-right mt-2">
              <button className="blue-button" onClick={handleAddComment}>
                <CompanyTermsWrapper>Add Comment</CompanyTermsWrapper>
              </button>
            </div>
            <div className="mt-8">
              <div className="font-bold">
                <CompanyTermsWrapper>Comments</CompanyTermsWrapper>{" "}
                <span className="text-xs rounded-full bg-orange-500 text-white px-2 p">
                  {comments.length}
                </span>
              </div>
              <div className="mt-4 max-w-[1440px]">
                {comments.map((comment, index) => (
                  <CommentBlock
                    key={index}
                    comment={comment}
                    currentUser={currentUser}
                    handleDeleteComment={() => handleDeleteComment(index)}
                  />
                ))}
              </div>
            </div>
          </div>

          <div className="section-box mt-4">
            <div className="font-semibold text-eleghoBlue text-lg border-b border-gray-400">
              <CompanyTermsWrapper>History</CompanyTermsWrapper>
            </div>
            <div className="mt-4 flex flex-col-reverse w-full">
              {formData?.history?.map((history, index) => (
                <div key={index} className="relative border-l border-gray-300">
                  <div className="flex items-center relative -left-4">
                    <div className=" bg-white p-1 rounded-full text-green-500">
                      <CheckCircleOutlineIcon />
                    </div>
                    <div className="text-xs">
                      {history.action} {history.to}
                    </div>
                  </div>
                  <div className="pl-4 mb-4">
                    <div className="text-xs text-gray-400">{history.at}</div>
                    <div className="text-xs font-semibold text-gray-400">
                      {history.by}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
