import React from "react";
import WorkWeekSchedule from "../WorkWeek/WorkWeekSchedule";

export default function FiftyTwoWorkWeekSchedulePage() {
  return (
    <div>
      <div className="font-medium text-lg text-gray-900 text-left">
        52 Work Week Schedule
      </div>
      <WorkWeekSchedule />
    </div>
  );
}
