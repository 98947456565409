import React, { useEffect, useState } from "react";
import { getAllDocsSnap } from "../../../utils/firebase/Firebase.utils";
import PropertyCard from "./PropertyCard";

export default function Properties({ searchTerm = "" }) {
  const [properties, setProperties] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchProperties = async () => {
      await getAllDocsSnap("properties", (data) => {
        setProperties(data);
        setLoading(false);
      });
    };
    fetchProperties();
  }, []);

  const filteredProperties =
    searchTerm.trim() === ""
      ? properties
      : properties.filter((property) => {
          const searchLower = searchTerm.toLowerCase();
          return (
            property.title?.toLowerCase().includes(searchLower) ||
            property.description?.toLowerCase().includes(searchLower) ||
            property.type?.toLowerCase().includes(searchLower) ||
            property.location?.toLowerCase().includes(searchLower) ||
            property.price?.toString().includes(searchLower)
          );
        });

  if (loading) {
    return (
      <div className="flex justify-center items-center h-40">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-gray-900"></div>
      </div>
    );
  }

  return (
    <div>
      {filteredProperties.length === 0 ? (
        <div className="text-center py-10">
          <p className="text-gray-600">
            {searchTerm.trim() !== ""
              ? `No properties found matching "${searchTerm}"`
              : "No properties available at the moment."}
          </p>
        </div>
      ) : (
        <div>
          {filteredProperties.map((property, index) => (
            <PropertyCard data={property} key={index} />
          ))}
        </div>
      )}
    </div>
  );
}
