import React, { useEffect, useState } from "react";
import { getAllDocsSnap } from "../../../utils/firebase/Firebase.utils";
import ProductCard from "./ProductCard";

export default function Products({ searchTerm = "" }) {
  const [products, setProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchProducts = async () => {
      await getAllDocsSnap("products", (data) => {
        setProducts(data);
        console.log(data);
        setIsLoading(false);
      });
    };
    fetchProducts();
  }, []);

  const filteredProducts =
    searchTerm.trim() === ""
      ? products
      : products.filter((product) => {
          const searchLower = searchTerm.toLowerCase();
          return (
            product.productTitle?.toLowerCase().includes(searchLower) ||
            product.description?.toLowerCase().includes(searchLower) ||
            product.categories?.some((cat) =>
              cat.toLowerCase().includes(searchLower)
            ) ||
            product.productCategories?.some((cat) =>
              cat.toLowerCase().includes(searchLower)
            )
          );
        });

  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-gray-900"></div>
      </div>
    );
  }

  return (
    <div className="container mx-auto px-2 sm:px-4">
      <h1 className="header mb-4">Products and Services</h1>

      <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-2 sm:gap-4">
        {filteredProducts.map((product) => (
          <ProductCard key={product.id} product={product} />
        ))}
      </div>

      {filteredProducts.length === 0 && !isLoading && (
        <div className="text-center py-10">
          <p className="text-gray-600">
            {searchTerm.trim() !== ""
              ? `No products found matching "${searchTerm}"`
              : "No products available at the moment."}
          </p>
        </div>
      )}
    </div>
  );
}
