import React, { useState } from "react";
import { useSelector } from "react-redux";
import toast from "react-hot-toast";
import { addNewDoc, setDocData } from "../../../utils/firebase/Firebase.utils";
import Label from "../Components/Label";
import SelectInput from "../Components/SelectInput";
import InputBox from "../Components/InputBox";

export default function AddContactForm({
  data,
  setAddMode,
  setEditMode,
  editMode,
}) {
  const currentUser = useSelector((state) => state.userObject);
  const [formData, setFormData] = useState(editMode ? data : {});
  const [submitting, setSubmitting] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitting(true);

    try {
      if (editMode) {
        const docObject = {
          ...formData,
          updatedBy: currentUser.email,
          updatedAt: new Date().toLocaleString(),
        };
        await setDocData("contacts", data.id, docObject);
        toast.success("Contact Updated Successfully");
        setEditMode(false);
      } else {
        const docObject = {
          ...formData,
          createdBy: currentUser.email,
          user: currentUser.uid,
          createdAt: new Date().toLocaleString(),
        };
        await addNewDoc("contacts", docObject);
        toast.success("Contact Added Successfully");
        setAddMode(false);
      }
    } catch (error) {
      toast.error("Something went wrong. Please try again.");
    } finally {
      setSubmitting(false);
    }
  };

  const categoryOptions = [
    {
      label: "Condo Corp Directory",
      value: "Condo Corp Directory",
    },
    {
      label: "Emergency Hotlines",
      value: "Emergency Hotlines",
    },
  ];

  return (
    <div className="bg-white rounded-lg shadow-xl w-full max-w-2xl relative">
      <button
        className="absolute top-4 right-4 text-gray-500 hover:text-gray-700"
        onClick={() => {
          setAddMode(false);
          setEditMode(false);
        }}
      >
        <svg
          className="w-6 h-6"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M6 18L18 6M6 6l12 12"
          />
        </svg>
      </button>

      <div className="p-6">
        <h2 className="text-2xl font-semibold text-gray-800 mb-6">
          {editMode ? "Edit Contact" : "Add New Contact"}
        </h2>

        <form onSubmit={handleSubmit} className="space-y-6">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div>
              <Label text="Category" />
              <SelectInput
                name="category"
                label="Category"
                value={categoryOptions.find(
                  (option) => option.value === formData.category
                )}
                options={categoryOptions}
                onChange={(value) => {
                  setFormData({ ...formData, category: value.value });
                }}
                required
              />
            </div>

            <div>
              <Label text="Name" />
              <InputBox
                name="name"
                type="text"
                onChange={handleChange}
                placeholder="Enter name of Contact"
                value={formData.name}
                disabled={submitting}
                required
              />
            </div>

            <div>
              <Label text="Title / Position" />
              <InputBox
                name="title"
                type="text"
                onChange={handleChange}
                placeholder="Enter Title"
                required
                disabled={submitting}
                value={formData.title}
              />
            </div>

            <div>
              <Label text="Department" />
              <InputBox
                name="department"
                type="text"
                onChange={handleChange}
                placeholder="Enter Department"
                required
                disabled={submitting}
                value={formData.department}
              />
            </div>

            <div>
              <Label text="Contact Number" />
              <InputBox
                name="contactNumber"
                type="tel"
                onChange={handleChange}
                placeholder="Enter Contact Number"
                required
                disabled={submitting}
                value={formData.contactNumber}
              />
            </div>

            <div>
              <Label text="Landline Number" />
              <InputBox
                name="landline"
                type="tel"
                onChange={handleChange}
                disabled={submitting}
                placeholder="Enter Landline Number"
                value={formData.landline}
              />
            </div>

            <div className="md:col-span-2">
              <Label text="Email Address" />
              <InputBox
                name="email"
                type="email"
                onChange={handleChange}
                disabled={submitting}
                placeholder="Enter Email Address"
                value={formData.email}
              />
            </div>
          </div>

          <div className="flex justify-end space-x-4 mt-8">
            <button
              type="button"
              onClick={() => {
                setAddMode(false);
                setEditMode(false);
              }}
              className="px-4 py-2 text-gray-700 bg-gray-100 rounded-md hover:bg-gray-200 transition-colors"
              disabled={submitting}
            >
              Cancel
            </button>
            <button
              type="submit"
              disabled={submitting}
              className="px-4 py-2 text-white bg-blue-600 rounded-md hover:bg-blue-700 transition-colors flex items-center"
            >
              {submitting ? (
                <>
                  <svg
                    className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                  Processing...
                </>
              ) : (
                "Submit"
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
