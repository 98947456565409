import React, { useState } from "react";
import GroupChatHome from "../User/GroupChatHome";
import { useSelector } from "react-redux";
import MobileWrapper from "../../components/MobileWrapper";
import DirectMessaging from "./DirectMessaging";

export default function UserChat() {
  const currentUser = useSelector((user) => user.userObject);
  console.log(currentUser);

  const [activeLink, setActiveLink] = useState("Direct Messages");

  const links = [
    { name: "Direct Messages", access: "user" },
    {
      name: "Group Chat",
      access: "user-admin",
    },
  ];

  const accessibleLink = links.filter((link) => {
    if (currentUser.roles.includes(link.access)) {
      return link;
    }
  });

  console.log(accessibleLink);

  return (
    <MobileWrapper label={"Chats"}>
      <div className="header">User Chats</div>

      <div className="category-container">
        {accessibleLink.map((link, index) => (
          <div
            className={
              activeLink === link.name ? "active-category" : "inactive-category"
            }
            onClick={() => setActiveLink(link.name)}
          >
            {link.name}
          </div>
        ))}
      </div>

      {activeLink === "Group Chat" && <GroupChatHome />}
      {activeLink === "Direct Messages" && <DirectMessaging />}
    </MobileWrapper>
  );
}
