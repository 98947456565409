import React from "react";
import { FaArrowLeft } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

export default function BackButton() {
  const navigate = useNavigate();
  return (
    <button
      onClick={() => navigate(-1)}
      className="mr-2 p-2 rounded-full hover:bg-gray-200 transition-colors"
      aria-label="Go back"
    >
      <FaArrowLeft className="text-[#363062]" />
    </button>
  );
}
