import React from "react";
import {
  Day,
  Inject,
  Month,
  ScheduleComponent,
  ViewDirective,
  ViewsDirective,
  Week,
  WorkWeek,
} from "@syncfusion/ej2-react-schedule";
// import { registerLicense } from "@syncfusion/ej2-base";
import { convertTimeStamptoDate } from "../../../utils/EleghoUtils";

// registerLicense(
//   "ORg4AjUWIQA/Gnt2XVhhQlJHfV5AQmBIYVp/TGpJfl96cVxMZVVBJAtUQF1hTH5RdkdjX35ddHNSRWNY"
// );

export default function FacilityCalendar({ bookings }) {
  const categoryColors = [
    {
      type: "Pending",
      color: "#FFA500",
    },
    {
      type: "Approved",
      color: "#008000",
    },
    {
      type: "Rejected",
      color: "#FF0000",
    },
  ];

  console.log("bookings", bookings);

  const createDateTime = (date, time) => {
    // Extract hours and minutes from startTime
    const [hours, minutes] = time.split(":").map(Number);

    // Create a new Date object for dateTime
    const dateTime = new Date(convertTimeStamptoDate(date));
    dateTime.setHours(hours, minutes, 0, 0); // Set hours and minutes

    return dateTime;
  };

  const data = bookings.map((item) => {
    return {
      id: item.id,
      Description: `${item.createdName} - ${item.status}`,
      isReadOnly: true,
      Subject: item.createdName,
      StartTime: createDateTime(item.date, item.startTime),
      EndTime: createDateTime(item.date, item.endTime),
      IsAllDay: false,
      CategoryColor:
        categoryColors.find((color) => color.type === item.status)?.color ||
        "#808080",
    };
  });

  const onEventRendered = (args) => {
    const categoryColor = args.data.CategoryColor;
    args.element.style.backgroundColor = categoryColor;
  };

  const onPopupOpen = (args) => {
    args.cancel = true;
  };

  const onCellClick = (args) => {
    args.cancel = true;
  };

  const onCellDoubleClick = (args) => {
    args.cancel = true;
  };

  return (
    <div className="bg-white rounded-lg shadow-sm p-4">
      <div className="font-bold text-lg mb-4">Calendar View</div>
      <div className="flex items-center justify-center">
        <ScheduleComponent
          eventSettings={{ dataSource: data }}
          eventRendered={onEventRendered}
          popupOpen={onPopupOpen}
          width="100%"
          height={500}
          currentView="Week"
          timeScale={{ interval: 60, slotCount: 1 }}
          rowAutoHeight={true}
          readOnly={true}
          cellClick={onCellClick}
          cellDoubleClick={onCellDoubleClick}
        >
          <ViewsDirective>
            <ViewDirective option="Day" />
            <ViewDirective option="Week" />
            <ViewDirective option="Month" />
          </ViewsDirective>
          <Inject services={[Day, Week, Month]} />
        </ScheduleComponent>
      </div>
    </div>
  );
}
