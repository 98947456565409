import React, { useState, useRef } from "react";
import UserScreenWindow from "../UserScreenWindow";
import InputBoxVar2 from "./InputBoxVar2";
import { FaSquareParking, FaCloudArrowUp } from "react-icons/fa6";
import { CiSquarePlus } from "react-icons/ci";
import toast from "react-hot-toast";
import { addNewDoc } from "../../../utils/firebase/Firebase.utils";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import CharacterCounter from "./CharacterCounter";
import { handleUploadMultiple } from "../../../utils/EleghoUtils";

export default function Newparkingspace() {
  const currentUser = useSelector((state) => state.userObject);
  const navigate = useNavigate();
  const [characterCount, setCharacterCount] = useState(0);
  const [formData, setFormData] = useState({});
  const [isProcessing, setIsProcessing] = useState(false);
  const [rentalType, setRentalType] = useState("Hourly");
  const photoRef = useRef();

  const rentalTypes = ["Hourly", "Daily", "Weekly", "Monthly"];

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleTitleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setCharacterCount(e.target.value.length);
  };

  const formatNumber = (num) => {
    return num.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const handleNumberChange = (e) => {
    const { name, value } = e.target;
    const numericValue = e.target.value.replace(/[^0-9.]/g, "");
    setFormData({ ...formData, [name]: formatNumber(numericValue) });
  };

  const handleUpload = async (e) => {
    await handleUploadMultiple(
      e,
      formData,
      setFormData,
      "parkingImages",
      "images"
    );
    toast.success("Images Uploaded Successfully");
  };

  const handleRemove = (index) => {
    const newPhotos = formData.images.filter((photo, i) => i !== index);
    setFormData({ ...formData, images: newPhotos });
  };

  const checkIfAllFieldsAreFilled = () => {
    const { title, price, location, size, description } = formData;
    if (!title || !price || !location || !size || !description) {
      return false;
    }
    return true;
  };

  const checkIfImageIsUploaded = () => {
    if (formData.images && formData.images.length > 0) {
      return true;
    }
    return false;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsProcessing(true);

    if (!checkIfAllFieldsAreFilled()) {
      toast.error("Please fill in all required fields");
      setIsProcessing(false);
      return;
    }

    if (!checkIfImageIsUploaded()) {
      toast.error("Please upload at least one image");
      setIsProcessing(false);
      return;
    }

    const docObject = {
      ...formData,
      rentalType,
      category: "Parking",
      createdAt: new Date().toLocaleString(),
      createdBy: currentUser.email,
      createdName: currentUser.displayName,
    };

    await addNewDoc("parkingSpaces", docObject);
    toast.success("Parking Space Added Successfully");
    setIsProcessing(false);
    navigate("/marketplace");
  };

  return (
    <UserScreenWindow label="List New Parking Space">
      <div className="flex items-center gap-4 text-eleghoBlue text-sm font-semibold">
        <div>
          <FaSquareParking />
        </div>
        <div>Parking Space Details</div>
      </div>

      <div className="category-container text-center mt-4">
        {rentalTypes.map((type) => (
          <div
            key={type}
            className={
              rentalType === type
                ? "active-category flex items-center gap-1"
                : "inactive-category flex items-center gap-1"
            }
            onClick={() => setRentalType(type)}
          >
            {type}
          </div>
        ))}
      </div>

      <div className="relative mt-4">
        <InputBoxVar2
          label="Parking Space Title"
          name="title"
          placeholder="E.g., Covered Parking Near Building 3"
          onChange={handleTitleChange}
          maxLength="40"
        />
        <div className="absolute right-2 bottom-2 text-xs text-gray-400">
          <CharacterCounter count={characterCount} limit={"40"} />
        </div>
      </div>

      <div className="mt-2">
        <InputBoxVar2
          label={`${rentalType} Rate (PHP)`}
          placeholder={`${rentalType} Rate`}
          onChange={handleNumberChange}
          name="price"
          value={formData.price}
        />
      </div>

      <div className="mt-2">
        <InputBoxVar2
          label="Location"
          placeholder="E.g., Basement Level 1, Slot B24"
          onChange={handleChange}
          name="location"
          value={formData.location}
        />
      </div>

      <div className="mt-2">
        <InputBoxVar2
          label="Size"
          placeholder="E.g., Standard Car, SUV, etc."
          onChange={handleChange}
          name="size"
          value={formData.size}
        />
      </div>

      <div className="mt-2">
        <div className="flex flex-col gap-2 text-eleghoBlue">
          <label className="font-semibold text-sm">Description</label>
          <textarea
            className="p-2 text-sm border border-gray-200 rounded-md h-24"
            placeholder="Describe your parking space, access details, special features, etc."
            name="description"
            onChange={handleChange}
            value={formData.description}
          />
        </div>
      </div>

      <div className="mt-2">
        <InputBoxVar2
          label="Contact Number (Optional)"
          placeholder="Phone number"
          onChange={handleChange}
          name="contactNumber"
          value={formData.contactNumber}
        />
      </div>

      <input
        type="file"
        multiple
        onChange={handleUpload}
        ref={photoRef}
        className="hidden"
      />

      <div className="border-2 border-dashed border-gray-400 p-8 rounded-lg bg-white flex items-center justify-center gap-4 mt-4">
        <div className="flex flex-col items-center">
          <div className="flex items-center gap-4 flex-wrap mb-4">
            {formData.images && formData.images.length > 0
              ? formData.images.map((image, index) => (
                  <div key={index}>
                    <img
                      src={image}
                      alt="parking"
                      className="w-20 h-20 object-cover"
                    />
                    <button
                      className="underline mt-2 text-gray-400 text-xs text-center"
                      onClick={() => handleRemove(index)}
                    >
                      Remove
                    </button>
                  </div>
                ))
              : null}
          </div>
          <FaCloudArrowUp />
          <div className="text-sm font-semibold text-eleghoBlue">
            Upload Photos of Your Parking Space
          </div>
          <div
            className="text-2xl cursor-pointer hover:bg-gray-400 p-2 rounded-lg"
            onClick={() => photoRef.current.click()}
          >
            <CiSquarePlus />
          </div>
        </div>
      </div>

      <button
        className="small-form-button mt-4"
        onClick={handleSubmit}
        disabled={isProcessing}
      >
        {isProcessing ? "Processing..." : "Submit Listing"}
      </button>
    </UserScreenWindow>
  );
}
