import React, { useEffect } from "react";
import { IoIosArrowBack } from "react-icons/io";
import { FiClock, FiUser, FiCalendar } from "react-icons/fi";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import UserScreenWindow from "./UserScreenWindow";
import { queryAllDocsByFieldSnap } from "../../utils/firebase/Firebase.utils";

function GuestHistory() {
  const [guestHistory, setGuestHistory] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const currentUser = useSelector((state) => state.userObject);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchGuestHistory = async () => {
      const history = await queryAllDocsByFieldSnap(
        "guests",
        "requestedBy",
        "==",
        currentUser.email,
        (data) => {
          // Arrange by date in descending order
          const sortedData = data.sort((a, b) => {
            return new Date(b.date) - new Date(a.date);
          });
          setGuestHistory(sortedData);
          setLoading(false);
        }
      );
    };
    fetchGuestHistory();
  }, []);

  return (
    <UserScreenWindow label="Guest History">
      <div className="min-h-screen bg-gray-50 p-4">
        <div className="max-w-3xl mx-auto">
          {/* Header */}

          {/* Quick Stats */}
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-3 mb-6">
            <motion.div
              whileHover={{ scale: 1.01 }}
              whileTap={{ scale: 0.99 }}
              className="bg-white rounded-lg shadow-sm p-4"
            >
              <div className="flex items-center gap-3">
                <div className="p-2.5 bg-eleghoBlue/10 rounded-lg">
                  <FiUser className="w-5 h-5 text-eleghoBlue" />
                </div>
                <div>
                  <p className="text-sm text-gray-600">Total Guests</p>
                  <p className="text-lg font-semibold text-eleghoBlue">
                    {guestHistory.reduce(
                      (acc, curr) => acc + curr.guests.length,
                      0
                    )}
                  </p>
                </div>
              </div>
            </motion.div>

            <motion.div
              whileHover={{ scale: 1.01 }}
              whileTap={{ scale: 0.99 }}
              className="bg-white rounded-lg shadow-sm p-4"
            >
              <div className="flex items-center gap-3">
                <div className="p-2.5 bg-eleghoGreen/10 rounded-lg">
                  <FiCalendar className="w-5 h-5 text-eleghoGreen" />
                </div>
                <div>
                  <p className="text-sm text-gray-600">Total Visits</p>
                  <p className="text-lg font-semibold text-eleghoGreen">
                    {guestHistory.length}
                  </p>
                </div>
              </div>
            </motion.div>

            <motion.div
              whileHover={{ scale: 1.01 }}
              whileTap={{ scale: 0.99 }}
              className="bg-white rounded-lg shadow-sm p-4 sm:col-span-2 lg:col-span-1"
            >
              <div className="flex items-center gap-3">
                <div className="p-2.5 bg-eleghoBlue/10 rounded-lg">
                  <FiClock className="w-5 h-5 text-eleghoBlue" />
                </div>
                <div>
                  <p className="text-sm text-gray-600">Latest Visit</p>
                  <p className="text-base font-medium text-gray-900 truncate">
                    {guestHistory.length > 0
                      ? new Date(guestHistory[0].date).toLocaleDateString()
                      : "No visits yet"}
                  </p>
                </div>
              </div>
            </motion.div>
          </div>

          {/* Guest History List */}
          <div className="bg-white rounded-lg shadow-sm border border-gray-100 overflow-hidden">
            <div className="px-4 py-3 border-b border-gray-100">
              <h2 className="text-base font-semibold text-eleghoBlue">
                Visit History
              </h2>
            </div>
            <div className="divide-y divide-gray-100">
              {loading ? (
                <div className="p-4 text-center">
                  <div className="w-8 h-8 border-2 border-eleghoBlue border-t-transparent rounded-full animate-spin mx-auto" />
                </div>
              ) : guestHistory.length === 0 ? (
                <div className="p-4 text-center text-gray-500">
                  No guest history found
                </div>
              ) : (
                guestHistory.map((visit, index) => (
                  <motion.div
                    key={index}
                    initial={{ opacity: 0, y: 10 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ delay: index * 0.05 }}
                    className="p-4 hover:bg-gray-50 transition-colors cursor-pointer"
                    onClick={() => navigate(`/guest-card-details/${visit.id}`)}
                  >
                    <div className="flex flex-col space-y-2">
                      <div className="flex items-center justify-between">
                        <div className="flex items-center gap-2">
                          <FiUser className="w-4 h-4 text-eleghoBlue" />
                          <span className="font-medium text-gray-900">
                            {visit.guests.map((guest) => guest.name).join(", ")}
                          </span>
                        </div>
                        <div className="flex items-center gap-2 text-sm text-gray-500">
                          <FiCalendar className="w-4 h-4" />
                          <span>
                            {new Date(visit.date).toLocaleDateString()}
                          </span>
                        </div>
                      </div>
                      <div className="flex items-center gap-2 text-sm text-gray-500 ml-6">
                        <FiClock className="w-4 h-4" />
                        <span>{visit.time}</span>
                        <span className="mx-2">•</span>
                        <span>{visit.linkedIdentifiers.join(", ")}</span>
                      </div>
                    </div>
                  </motion.div>
                ))
              )}
            </div>
          </div>
        </div>
      </div>
    </UserScreenWindow>
  );
}

export default GuestHistory;
