import React from "react";
import {
  deleteDocById,
  getAllDocsSnap,
  setDocData,
} from "../../../utils/firebase/Firebase.utils";
import { useSelector } from "react-redux";
import toast from "react-hot-toast";
import Modal from "../Components/Modal";
import TodosForm from "../../Todos/TodosForm";
import { FaPlus, FaTrash, FaCheck, FaClock } from "react-icons/fa";
import { MdPriorityHigh } from "react-icons/md";
import { BsFillGridFill, BsListUl } from "react-icons/bs";

export default function Todos() {
  const currentUser = useSelector((state) => state.userObject);
  const [todos, setTodos] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [activeFilter, setActiveFilter] = React.useState("pending");
  const [addMode, setAddMode] = React.useState(false);
  const [viewMode, setViewMode] = React.useState("grid"); // 'grid' or 'list'

  const filters = ["all", "pending", "completed"];

  React.useEffect(() => {
    const fetchTodos = async () => {
      await getAllDocsSnap("todos", (data) => {
        const filteredData = data.filter(
          (todo) => todo.user === currentUser.uid && todo.status !== "deleted"
        );

        filteredData.sort((a, b) => {
          return a.priority === "High" ? -1 : 1;
        });

        setTodos(data);
        setLoading(false);
      });
    };
    fetchTodos();
  }, []);

  const currentItems = todos.filter((todo) => {
    if (activeFilter === "all") return true;
    return todo.status === activeFilter;
  });

  const handleAdd = () => {
    setAddMode(!addMode);
  };

  return (
    <div className="max-w-7xl mx-auto p-6">
      {addMode && (
        <Modal>
          <TodosForm setAddTodo={setAddMode} />
        </Modal>
      )}

      <div className="bg-white rounded-lg shadow-lg p-6">
        <div className="flex justify-between items-center mb-6">
          <div>
            <h1 className="text-lg font-semibold" style={{ color: "#363062" }}>
              Task Management
            </h1>
            <p className="text-sm text-gray-600">{currentUser.displayName}</p>
          </div>
          <div className="flex items-center gap-4">
            <div className="flex bg-gray-100 rounded-lg p-1">
              <button
                className={`p-2 rounded-lg transition-all ${
                  viewMode === "grid"
                    ? "bg-white shadow-sm"
                    : "text-gray-600 hover:text-gray-800"
                }`}
                onClick={() => setViewMode("grid")}
                title="Grid View"
              >
                <BsFillGridFill className="w-4 h-4" />
              </button>
              <button
                className={`p-2 rounded-lg transition-all ${
                  viewMode === "list"
                    ? "bg-white shadow-sm"
                    : "text-gray-600 hover:text-gray-800"
                }`}
                onClick={() => setViewMode("list")}
                title="List View"
              >
                <BsListUl className="w-4 h-4" />
              </button>
            </div>
            <button
              className="flex items-center gap-2 px-4 py-2 rounded-lg text-white transition-all hover:opacity-90"
              style={{ backgroundColor: "#8FCD00" }}
              onClick={handleAdd}
            >
              <FaPlus /> Add Todo
            </button>
          </div>
        </div>

        <div className="flex items-center gap-4 mb-6">
          {filters.map((filter, index) => (
            <div
              key={index}
              className={`capitalize px-4 py-2 rounded-lg cursor-pointer transition-all ${
                activeFilter === filter
                  ? "text-white shadow-md"
                  : "text-gray-600 hover:bg-gray-100"
              }`}
              style={{
                backgroundColor:
                  activeFilter === filter ? "#363062" : "transparent",
              }}
              onClick={() => setActiveFilter(filter)}
            >
              {filter}
            </div>
          ))}
        </div>

        <div className="mt-4">
          {loading ? (
            <div className="text-center py-8 text-gray-600">Loading...</div>
          ) : (
            <div
              className={
                viewMode === "grid"
                  ? "grid gap-4 md:grid-cols-2 lg:grid-cols-3"
                  : "flex flex-col gap-3"
              }
            >
              {currentItems.map((todo, index) => (
                <div key={index}>
                  <TodoCard todo={todo} viewMode={viewMode} />
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

const TodoCard = ({ todo, viewMode }) => {
  const toggleStatus = async (id, status) => {
    const newStatus = status === "completed" ? "pending" : "completed";
    await setDocData("todos", id, { status: newStatus });
    toast.success("Status updated successfully");
  };

  const handleDelete = async (id) => {
    if (window.confirm("Are you sure you want to delete this todo?")) {
      await deleteDocById("todos", id);
      toast.success("Todo deleted successfully");
    }
  };

  return (
    <div
      className={`bg-white border border-gray-200 rounded-lg p-4 shadow-sm hover:shadow-md transition-all ${
        viewMode === "list" ? "flex items-center justify-between" : ""
      }`}
    >
      {viewMode === "list" ? (
        <>
          <div className="flex items-center gap-4 flex-1">
            <h3 className="font-semibold" style={{ color: "#363062" }}>
              {todo.name}
            </h3>
            <p className="text-gray-600 text-sm flex-1">{todo.description}</p>
          </div>
          <div className="flex items-center gap-4">
            <div className="flex items-center gap-1">
              <MdPriorityHigh
                className={`${
                  todo.priority === "High" ? "text-red-500" : "text-yellow-500"
                }`}
              />
              <span
                className={`text-xs capitalize ${
                  todo.priority === "High" ? "text-red-500" : "text-yellow-500"
                }`}
              >
                {todo.priority}
              </span>
            </div>
            <div
              className={`flex items-center gap-1 px-2 py-1 rounded-full text-xs cursor-pointer ${
                todo.status === "completed"
                  ? "bg-green-100 text-green-700"
                  : "bg-yellow-100 text-yellow-700"
              }`}
              onClick={() => toggleStatus(todo.id, todo.status)}
            >
              {todo.status === "completed" ? (
                <FaCheck className="w-3 h-3" />
              ) : (
                <FaClock className="w-3 h-3" />
              )}
              <span className="capitalize">{todo.status}</span>
            </div>
            <button
              className="flex items-center gap-1 text-xs text-red-500 hover:text-red-700 transition-colors"
              onClick={() => handleDelete(todo.id)}
            >
              <FaTrash className="w-3 h-3" />
              Delete
            </button>
          </div>
        </>
      ) : (
        <>
          <div className="flex justify-between items-start mb-3">
            <h3 className="font-semibold" style={{ color: "#363062" }}>
              {todo.name}
            </h3>
            <div
              className={`flex items-center gap-1 px-2 py-1 rounded-full text-xs cursor-pointer ${
                todo.status === "completed"
                  ? "bg-green-100 text-green-700"
                  : "bg-yellow-100 text-yellow-700"
              }`}
              onClick={() => toggleStatus(todo.id, todo.status)}
            >
              {todo.status === "completed" ? (
                <FaCheck className="w-3 h-3" />
              ) : (
                <FaClock className="w-3 h-3" />
              )}
              <span className="capitalize">{todo.status}</span>
            </div>
          </div>

          <p className="text-gray-600 text-sm mb-4">{todo.description}</p>

          <div className="flex justify-between items-center">
            <div className="flex items-center gap-1">
              <MdPriorityHigh
                className={`${
                  todo.priority === "High" ? "text-red-500" : "text-yellow-500"
                }`}
              />
              <span
                className={`text-xs capitalize ${
                  todo.priority === "High" ? "text-red-500" : "text-yellow-500"
                }`}
              >
                {todo.priority}
              </span>
            </div>

            <button
              className="flex items-center gap-1 text-xs text-red-500 hover:text-red-700 transition-colors"
              onClick={() => handleDelete(todo.id)}
            >
              <FaTrash className="w-3 h-3" />
              Delete
            </button>
          </div>
        </>
      )}
    </div>
  );
};
