import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { loadUser } from "../../store/userSlice";
import { FiChevronLeft } from "react-icons/fi";
import { BsTelephone } from "react-icons/bs";
import { AnimatePresence, motion } from "framer-motion";
import { CiUser } from "react-icons/ci";
import { IoLockClosedOutline } from "react-icons/io5";
import { MdOutlineArrowForwardIos } from "react-icons/md";
import { MdOutlineMail } from "react-icons/md";
import { FiLogOut } from "react-icons/fi";
import { IoCopyOutline } from "react-icons/io5";
import UserScreenWindow from "./UserScreenWindow";

import { getDocDataSnap } from "../../utils/firebase/Firebase.utils";
import { Link, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";

function Profile() {
  const currentUser = useSelector((state) => state.userObject);
  const dispatch = useDispatch();
  const [userData, setUserData] = React.useState({});

  const navigate = useNavigate();

  const handleLogout = () => {
    dispatch(loadUser({}));
  };

  useEffect(() => {
    const fetchUserData = async () => {
      const user = await getDocDataSnap("users", currentUser.uid, (data) => {
        setUserData(data);
      });
    };
    fetchUserData();
  }, []);

  const invitationLink = `https://tali.elegho.app/invite/${currentUser.uid}`;

  const handleCopy = () => {
    navigator.clipboard.writeText(invitationLink);
    toast.success("Link copied to clipboard");
  };

  const getInitials = (name) => {
    if (!name) return "JD";
    return name
      .split(" ")
      .map((word) => word[0])
      .join("")
      .toUpperCase()
      .slice(0, 2);
  };

  return (
    <UserScreenWindow label="Profile">
      <div className="max-w-4xl mx-auto px-4 py-6 space-y-6">
        {/* Profile Card */}
        <div className="bg-white rounded-2xl shadow-lg p-6 transform hover:scale-[1.02] transition-transform">
          <div className="flex flex-col items-center space-y-4">
            <div className="relative">
              <img
                src={
                  userData.photoUrl ||
                  `https://api.dicebear.com/7.x/initials/svg?seed=${getInitials(
                    currentUser.displayName
                  )}&backgroundColor=0f4c81`
                }
                alt="profile"
                className="rounded-full h-24 w-24 object-cover object-center ring-4 ring-eleghoBlue/20"
              />
            </div>
            <div className="text-center">
              <h2 className="text-2xl font-bold text-gray-800">
                {currentUser.displayName || "John Doe"}
              </h2>
              {userData.linkedIdentifiers?.map((identifier) => (
                <div key={identifier} className="text-sm text-eleghoBlue">
                  {identifier}
                </div>
              ))}
            </div>
            <div className="flex flex-col w-full max-w-md gap-3">
              <div className="flex items-center gap-2 bg-eleghoBlue/5 px-4 py-3 rounded-lg">
                <MdOutlineMail className="text-eleghoBlue text-xl" />
                <span className="text-gray-700">{userData.email}</span>
              </div>
              <div className="flex items-center gap-2 bg-eleghoBlue/5 px-4 py-3 rounded-lg">
                <BsTelephone className="text-eleghoBlue text-xl" />
                <span className="text-gray-700">
                  {userData.telephone || "No Phone Number"}
                </span>
              </div>
            </div>
          </div>
        </div>

        {/* Navigation Menu */}
        <div className="bg-white rounded-2xl shadow-lg overflow-hidden divide-y divide-gray-100">
          <Link to="/userprofile" className="block group">
            <div className="flex items-center justify-between p-5 hover:bg-eleghoBlue/5 transition-all duration-200 transform hover:translate-x-2">
              <div className="flex items-center gap-4">
                <div className="p-3 bg-eleghoBlue/10 rounded-xl group-hover:bg-eleghoBlue/20 transition-colors">
                  <CiUser className="text-eleghoBlue text-2xl" />
                </div>
                <div>
                  <span className="font-medium text-gray-700 block">
                    Profile Settings
                  </span>
                  <span className="text-sm text-gray-500">
                    Update your personal information
                  </span>
                </div>
              </div>
              <MdOutlineArrowForwardIos className="text-eleghoBlue/60 group-hover:text-eleghoBlue transition-colors" />
            </div>
          </Link>
          <div
            className="block group cursor-pointer"
            onClick={() => {
              navigate("/password");
            }}
          >
            <div className="flex items-center justify-between p-5 hover:bg-eleghoBlue/5 transition-all duration-200 transform hover:translate-x-2">
              <div className="flex items-center gap-4">
                <div className="p-3 bg-eleghoBlue/10 rounded-xl group-hover:bg-eleghoBlue/20 transition-colors">
                  <IoLockClosedOutline className="text-eleghoBlue text-2xl" />
                </div>
                <div>
                  <span className="font-medium text-gray-700 block">
                    Password & Security
                  </span>
                  <span className="text-sm text-gray-500">
                    Manage your account security
                  </span>
                </div>
              </div>
              <MdOutlineArrowForwardIos className="text-eleghoBlue/60 group-hover:text-eleghoBlue transition-colors" />
            </div>
          </div>
        </div>

        {/* Invitation Section */}
        {!currentUser.principalUser && (
          <div className="bg-white rounded-2xl shadow-lg p-6">
            <h3 className="text-lg font-semibold text-gray-700 mb-4">
              Family Invitation
            </h3>
            <p className="text-sm text-gray-600 mb-4">
              Share this link with your family members to invite them
            </p>
            <div className="flex items-center gap-2">
              <input
                type="text"
                value={invitationLink}
                className="flex-1 bg-eleghoBlue/5 border border-eleghoBlue/10 rounded-lg px-4 py-2 text-sm text-gray-600"
                readOnly
              />
              <button
                onClick={handleCopy}
                className="flex items-center gap-2 bg-eleghoGreen text-white px-4 py-2 rounded-lg hover:bg-opacity-90 transition-colors"
              >
                <IoCopyOutline />
                Copy
              </button>
            </div>
          </div>
        )}

        {/* Logout Button */}
        <button
          onClick={handleLogout}
          className="w-full bg-white border border-red-200 text-red-600 rounded-xl p-4 flex items-center justify-center gap-2 hover:bg-red-50 transition-colors"
        >
          <FiLogOut />
          <span>Log Out</span>
        </button>
      </div>
    </UserScreenWindow>
  );
}

export default Profile;
