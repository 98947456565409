import React from "react";
import {
  FiMap,
  FiClock,
  FiAlertTriangle,
  FiTag,
  FiEye,
  FiUser,
} from "react-icons/fi";

export default function IncidentReportsCard({ data }) {
  // Function to determine status color
  const getStatusColor = (status) => {
    switch (status?.toLowerCase()) {
      case "resolved":
        return "bg-green-100 text-green-800";
      case "pending":
        return "bg-yellow-100 text-yellow-800";
      case "investigating":
        return "bg-blue-100 text-blue-800";
      default:
        return "bg-gray-100 text-gray-800";
    }
  };

  return (
    <div className="px-4 py-3 hover:bg-gray-50 transition-colors flex items-center gap-4">
      {/* Incident Type - w-36 */}
      <div className="w-36 flex items-center">
        <FiAlertTriangle
          className={`h-4 w-4 mr-2 ${
            data.status?.toLowerCase() === "resolved"
              ? "text-green-500"
              : "text-orange-500"
          }`}
        />
        <span className="font-medium text-gray-900 truncate">
          {data.incidentType}
        </span>
      </div>

      {/* Location - w-36 */}
      <div className="w-36 flex items-center">
        <FiMap className="mr-1 h-3 w-3 text-gray-400" />
        <span className="text-gray-600 truncate">{data.location}</span>
      </div>

      {/* Date/Time - w-36 */}
      <div className="w-36 flex items-center">
        <FiClock className="mr-1 h-3 w-3 text-gray-400" />
        <span className="text-gray-600 truncate">
          {data.date} {data.time}
        </span>
      </div>

      {/* Status - w-24 */}
      <div className="w-24 flex items-center">
        <span
          className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${getStatusColor(
            data.status
          )}`}
        >
          {data.status}
        </span>
      </div>

      {/* Reported By - w-36 */}
      <div className="w-36 flex items-center">
        <FiUser className="mr-1 h-3 w-3 text-gray-400" />
        <span className="text-gray-600 truncate">
          {data.reportedBy || "Not specified"}
        </span>
      </div>

      {/* Actions - w-24 */}
      <div className="w-24 text-right">
        <button className="text-eleghoBlue hover:text-eleghoBlue/80 flex items-center text-xs justify-end ml-auto">
          <FiEye className="mr-1 h-3 w-3" />
          View details
        </button>
      </div>
    </div>
  );
}
