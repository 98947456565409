import React, { useEffect, useState } from "react";
import InputBox from "../Components/InputBox";
import SelectInput from "../Components/SelectInput";
import PageModal from "../Components/PageModal";
import Modal from "../Components/Modal";
import {
  addNewDoc,
  getAllDocsSnap,
  getDocDataSnap,
} from "../../../utils/firebase/Firebase.utils";
import { useSelector } from "react-redux";
import toast from "react-hot-toast";
import MaintenanceItemsCards from "./MaintenanceItemsCards";
import ItemDetails from "./ItemDetails";
import AddItemForm from "./AddItemForm";
import {
  FiTool,
  FiClock,
  FiAlertCircle,
  FiCheckCircle,
  FiSearch,
  FiFilter,
} from "react-icons/fi";
import BentoTable from "../../../components/BentoTable";
import { slicedData } from "../../../utils/EleghoUtils";

// Create a table header component
function MaintenanceTableHeaders() {
  return (
    <div className="bg-gray-50 flex items-center gap-4 p-4 rounded-xl border-b border-gray-100 text-left">
      <div className="w-24">
        <div className="text-xs font-medium text-gray-500 uppercase tracking-wider">
          Status
        </div>
      </div>
      <div className="w-28">
        <div className="text-xs font-medium text-gray-500 uppercase tracking-wider">
          Name
        </div>
      </div>
      <div className="w-24">
        <div className="text-xs font-medium text-gray-500 uppercase tracking-wider">
          Type
        </div>
      </div>
      <div className="w-36">
        <div className="text-xs font-medium text-gray-500 uppercase tracking-wider">
          Priority
        </div>
      </div>
      <div className="w-48">
        <div className="text-xs font-medium text-gray-500 uppercase tracking-wider">
          Assigned To
        </div>
      </div>
      <div className="w-36">
        <div className="text-xs font-medium text-gray-500 uppercase tracking-wider">
          Created At
        </div>
      </div>
    </div>
  );
}

// Create a card component to display each maintenance item
function MaintenanceItemCard({ item }) {
  return (
    <div className="bg-white flex items-center gap-4 p-4 rounded-xl border border-gray-100 hover:border-eleghoBlue/20 hover:shadow-sm text-left transition-all duration-200">
      <div className="w-24">
        <div
          className={`capitalize text-xs font-medium px-3 py-1.5 rounded-full ${
            item.status === "completed"
              ? "bg-eleghoGreen/10 text-eleghoGreen"
              : item.status === "in-progress"
              ? "bg-yellow-50 text-yellow-500"
              : "bg-gray-50 text-gray-600"
          }`}
        >
          {item.status || "pending"}
        </div>
      </div>
      <div className="w-28">
        <div className="font-semibold text-gray-900 text-sm">{item.name}</div>
      </div>
      <div className="w-24">
        <div className="text-gray-600 text-sm">{item.type}</div>
      </div>
      <div className="w-36">
        <div
          className={`text-xs font-medium px-3 py-1.5 rounded-full ${
            item.priority === "high"
              ? "bg-red-50 text-red-500"
              : item.priority === "medium"
              ? "bg-yellow-50 text-yellow-500"
              : "bg-gray-50 text-gray-600"
          }`}
        >
          {item.priority || "low"}
        </div>
      </div>
      <div className="w-48">
        <div className="text-gray-600 text-sm">
          {item.assignedTo || "Unassigned"}
        </div>
      </div>
      <div className="w-36">
        <div className="text-gray-600 text-sm">{item.createdAt}</div>
      </div>
    </div>
  );
}

export default function MaintenanceNewPage() {
  const currentUser = useSelector((state) => state.userObject);
  const [formData, setFormData] = useState({});
  const [addMode, setAddMode] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [items, setItems] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [viewMode, setViewMode] = useState(false);
  const [selectedItem, setSelectedItem] = useState({});
  const [numberOfItemPerPage, setNumberOfItemPerPage] = useState(5);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [activeFilter, setActiveFilter] = useState("all");

  useEffect(() => {
    const fetchItems = async () => {
      await getAllDocsSnap("maintenanceItems", (data) => {
        setItems(data);
        setIsLoading(false);
      });
    };
    fetchItems();
  }, []);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  const itemTypes = ["Equipment", "Vehicle", "Facility"];
  const itemTypesOptions = itemTypes.map((item) => {
    return {
      value: item,
      label: item,
    };
  });

  const handleAddMode = () => {
    setAddMode(!addMode);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSelectType = (selected) => {
    setFormData({
      type: selected.value,
    });
  };

  const handleAddItem = async (e) => {
    e.preventDefault();
    setIsProcessing(true);
    const docObject = {
      ...formData,
      createdAt: new Date().toLocaleString(),
      createdBy: currentUser.uid,
      createdName: currentUser.displayName,
      createdEmail: currentUser.email,
    };

    await addNewDoc("maintenanceItems", docObject);
    toast.success("Item Successfully Added");
    setIsProcessing(false);
    setAddMode(false);
    setFormData("");
  };

  const handleViewMode = (item) => {
    setViewMode(true);
    setSelectedItem(item);
  };

  // Calculate stats for the maintenance items
  const stats = {
    totalItems: items.length,
    pendingItems: items.filter((item) => item.status === "pending").length || 0,
    completedItems:
      items.filter((item) => item.status === "completed").length || 0,
    urgentItems: items.filter((item) => item.priority === "high").length || 0,
  };

  const filteredItems = items.filter((item) => {
    const matchesSearch =
      item.name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      item.description?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      item.type?.toLowerCase().includes(searchTerm.toLowerCase());

    const matchesFilter =
      activeFilter === "all" ||
      (activeFilter === "pending" && item.status === "pending") ||
      (activeFilter === "completed" && item.status === "completed") ||
      (activeFilter === "urgent" && item.priority === "high");

    return matchesSearch && matchesFilter;
  });

  const currentItems = filteredItems.slice(
    (currentPage - 1) * numberOfItemPerPage,
    currentPage * numberOfItemPerPage
  );

  return (
    <div className="text-xs">
      <div>
        <div className="mt-4 font-bold text-blue-900 uppercase text-left text-sm">
          Maintenance Items
        </div>

        {/* Stats Cards */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 my-6">
          <div className="bg-white p-6 rounded-xl border border-gray-100 shadow-sm hover:shadow-md transition-shadow">
            <div className="flex items-center gap-3">
              <div className="p-2 bg-gray-50 rounded-lg">
                <FiTool className="w-5 h-5 text-gray-600" />
              </div>
              <div>
                <div className="text-sm text-gray-600">Total Items</div>
                <div className="text-2xl font-semibold text-gray-900">
                  {stats.totalItems}
                </div>
              </div>
            </div>
          </div>
          <div className="bg-white p-6 rounded-xl border border-gray-100 shadow-sm hover:shadow-md transition-shadow">
            <div className="flex items-center gap-3">
              <div className="p-2 bg-yellow-50 rounded-lg">
                <FiClock className="w-5 h-5 text-yellow-500" />
              </div>
              <div>
                <div className="text-sm text-gray-600">Pending Items</div>
                <div className="text-2xl font-semibold text-yellow-500">
                  {stats.pendingItems}
                </div>
              </div>
            </div>
          </div>
          <div className="bg-white p-6 rounded-xl border border-gray-100 shadow-sm hover:shadow-md transition-shadow">
            <div className="flex items-center gap-3">
              <div className="p-2 bg-eleghoGreen/10 rounded-lg">
                <FiCheckCircle className="w-5 h-5 text-eleghoGreen" />
              </div>
              <div>
                <div className="text-sm text-gray-600">Completed Items</div>
                <div className="text-2xl font-semibold text-eleghoGreen">
                  {stats.completedItems}
                </div>
              </div>
            </div>
          </div>
          <div className="bg-white p-6 rounded-xl border border-gray-100 shadow-sm hover:shadow-md transition-shadow">
            <div className="flex items-center gap-3">
              <div className="p-2 bg-red-50 rounded-lg">
                <FiAlertCircle className="w-5 h-5 text-red-500" />
              </div>
              <div>
                <div className="text-sm text-gray-600">Urgent Items</div>
                <div className="text-2xl font-semibold text-red-500">
                  {stats.urgentItems}
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Search and filters */}
        <div className="flex items-center justify-between mb-6">
          <button
            className="bg-eleghoBlue hover:bg-blue-700 text-white font-bold py-2 px-4 rounded transition-colors"
            onClick={handleAddMode}
          >
            Create a maintenance item
          </button>

          <div className="flex items-center gap-4">
            <div className="relative">
              <FiSearch className="absolute left-3 top-1/2 -translate-y-1/2 text-gray-400" />
              <input
                type="text"
                placeholder="Search items..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="pl-10 pr-4 py-2 border border-gray-200 rounded-lg focus:outline-none focus:ring-2 focus:ring-eleghoBlue/20 focus:border-eleghoBlue"
              />
            </div>
            <div className="flex items-center gap-2">
              <FiFilter className="text-gray-400" />
              <select
                value={activeFilter}
                onChange={(e) => setActiveFilter(e.target.value)}
                className="border border-gray-200 rounded-lg px-4 py-2 focus:outline-none focus:ring-2 focus:ring-eleghoBlue/20 focus:border-eleghoBlue"
              >
                <option value="all">All Status</option>
                <option value="pending">Pending</option>
                <option value="completed">Completed</option>
                <option value="urgent">Urgent</option>
              </select>
            </div>
          </div>
        </div>

        {addMode && (
          <Modal>
            <AddItemForm setAddMode={setAddMode} />
          </Modal>
        )}

        <BentoTable
          label="All Maintenance Items"
          data={filteredItems}
          numberOfItemPerPage={numberOfItemPerPage}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        >
          <div className="divide-y divide-gray-100">
            <MaintenanceTableHeaders />
            {currentItems.map((item, index) => (
              <div
                className="cursor-pointer"
                key={index}
                onClick={() => handleViewMode(item)}
              >
                <MaintenanceItemCard item={item} />
              </div>
            ))}
          </div>
        </BentoTable>
      </div>

      {viewMode && (
        <PageModal>
          <ItemDetails item={selectedItem} setViewMode={setViewMode} />
        </PageModal>
      )}
    </div>
  );
}
