import React, { useState, useRef } from "react";
import UserScreenWindow from "../UserScreenWindow";
import InputBoxVar2 from "./InputBoxVar2";
import { LuShoppingBasket } from "react-icons/lu";
import CharacterCounter from "./CharacterCounter";
import { handleUploadMultiple } from "../../../utils/EleghoUtils";
import { addNewDoc } from "../../../utils/firebase/Firebase.utils";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { FaCloudArrowUp } from "react-icons/fa6";
import { CiSquarePlus } from "react-icons/ci";
import toast from "react-hot-toast";

export default function NewProduct() {
  const currentUser = useSelector((state) => state.userObject);
  const navigate = useNavigate();
  const [formData, setFormData] = useState({});
  const [isProcessing, setIsProcessing] = useState(false);
  const [characterCount, setCharacterCount] = useState(0);
  const [priceCharacterCount, setPriceCharacterCount] = useState(0);
  const photoRef = useRef();

  // Predefined categories similar to Facebook Marketplace
  const categories = [
    "Vehicles",
    "Electronics",
    "Furniture",
    "Home Appliances",
    "Clothing & Accessories",
    "Books & Hobbies",
    "Sports & Fitness",
    "Toys & Games",
    "Baby & Kids",
    "Pet Supplies",
    "Food & Grocery",
    "Health & Beauty",
    "Services",
    "Musical Instruments",
    "Art & Collectibles",
    "Other",
  ];

  // Using an array to track selected categories instead of a single string
  const [selectedCategories, setSelectedCategories] = useState([]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleProductTitle = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setCharacterCount(e.target.value.length);
  };

  const handlePriceChange = (e) => {
    const { name, value } = e.target;
    const numericValue = e.target.value.replace(/[^0-9.]/g, "");
    setFormData({ ...formData, [name]: formatNumber(numericValue) });
    setPriceCharacterCount(numericValue.length);
  };

  // Modified to handle multiple category selection
  const handleCategorySelect = (category) => {
    let updatedCategories;

    if (selectedCategories.includes(category)) {
      // If category is already selected, remove it
      updatedCategories = selectedCategories.filter((cat) => cat !== category);
    } else {
      // If category is not selected, add it
      updatedCategories = [...selectedCategories, category];
    }

    setSelectedCategories(updatedCategories);
    setFormData({ ...formData, categories: updatedCategories });
  };

  const formatNumber = (num) => {
    return num.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const countCharacters = (value) => {
    return value ? value.length : 0;
  };

  const handleUpload = async (e) => {
    await handleUploadMultiple(
      e,
      formData,
      setFormData,
      "productImages",
      "images"
    );
    toast.success("Images Uploaded Successfully");
  };

  const handleRemove = (index) => {
    const newPhotos = formData.images.filter((photo, i) => i !== index);
    setFormData({ ...formData, images: newPhotos });
  };

  const checkIfAllFieldsAreFilled = () => {
    const { productTitle, price, description } = formData;
    // Check if at least one category is selected
    if (!productTitle || !price || !selectedCategories.length || !description) {
      return false;
    }
    return true;
  };

  const checkIfImageIsUploaded = () => {
    if (formData.images && formData.images.length > 0) {
      return true;
    }
    return false;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsProcessing(true);

    if (!checkIfAllFieldsAreFilled()) {
      toast.error(
        "Please fill in all required fields and select at least one category"
      );
      setIsProcessing(false);
      return;
    }

    if (!checkIfImageIsUploaded()) {
      toast.error("Please upload at least one image");
      setIsProcessing(false);
      return;
    }

    const docObject = {
      ...formData,
      category: "Product",
      productCategories: selectedCategories,
      createdAt: new Date().toLocaleString(),
      createdBy: currentUser.email,
      createdName: currentUser.displayName,
    };

    await addNewDoc("products", docObject);
    toast.success("Product Added Successfully");
    setIsProcessing(false);
    navigate("/marketplace");
  };

  return (
    <UserScreenWindow label="New Product Listing">
      <div className="flex items-center gap-4 text-eleghoBlue text-sm font-semibold">
        <div>
          <LuShoppingBasket />
        </div>
        <div>Product Details</div>
      </div>

      <div className="relative mt-4">
        <InputBoxVar2
          label="Product Title"
          name="productTitle"
          placeholder="Product Title"
          onChange={handleProductTitle}
          maxLength="30"
        />
        <div className="absolute right-2 bottom-2 text-xs text-gray-400">
          <CharacterCounter count={characterCount} limit={"30"} />
        </div>
      </div>

      <div className="mt-2">
        <InputBoxVar2
          label="Price"
          placeholder="Price"
          onChange={handlePriceChange}
          name="price"
          value={formData.price}
        />
      </div>

      <div className="mt-2">
        <label className="text-sm font-semibold text-eleghoBlue mb-2 block">
          Categories (Select one or more)
        </label>
        <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-2 max-h-48 overflow-y-auto border border-gray-200 rounded-md p-2">
          {categories.map((category, index) => (
            <div
              key={index}
              onClick={() => handleCategorySelect(category)}
              className={`p-2 text-sm border rounded-md cursor-pointer transition-colors ${
                selectedCategories.includes(category)
                  ? "bg-eleghoBlue text-white border-eleghoBlue"
                  : "bg-white text-gray-700 border-gray-200 hover:bg-gray-50"
              }`}
            >
              {category}
            </div>
          ))}
        </div>
        {selectedCategories.length > 0 && (
          <div className="mt-2 text-xs text-gray-600">
            Selected: {selectedCategories.join(", ")}
          </div>
        )}
      </div>

      <div className="mt-2">
        <InputBoxVar2
          label="Contact Number"
          placeholder="Contact Number"
          onChange={handleChange}
          name="contactNumber"
          value={formData.contactNumber}
        />
      </div>

      <div className="flex items-center gap-4 text-eleghoBlue text-sm font-semibold mt-4">
        Description
      </div>

      <textarea
        name="description"
        onChange={handleChange}
        placeholder="Enter Description of the Product"
        value={formData.description}
        className="w-full p-2 border border-gray-200 rounded-md"
        rows={6}
        maxLength={999}
      ></textarea>
      <div className="text-gray-400 text-xs">
        {countCharacters(formData.description)} / 999 characters
      </div>

      <div className="flex items-center gap-4 text-eleghoBlue text-sm font-semibold mt-4">
        Upload Sample Photos
      </div>

      <input
        type="file"
        ref={photoRef}
        className="hidden"
        accept="image/*"
        multiple
        onChange={handleUpload}
      />

      <div className="border-2 border-dashed border-gray-400 p-8 rounded-lg bg-white flex items-center justify-center gap-4 mt-4">
        <div className="flex flex-col items-center">
          <div className="flex items-center gap-4 flex-wrap mb-4">
            {formData.images && formData.images.length > 0
              ? formData.images.map((image, index) => (
                  <div key={index}>
                    <img
                      src={image}
                      alt="product"
                      className="w-20 h-20 object-cover"
                    />
                    <button
                      className="underline mt-2 text-gray-400 text-xs text-center"
                      onClick={() => handleRemove(index)}
                    >
                      Remove
                    </button>
                  </div>
                ))
              : null}
          </div>
          <FaCloudArrowUp />
          <div className="text-sm font-semibold text-eleghoBlue">
            Upload Sample Photos
          </div>
          <div
            className="text-2xl cursor-pointer hover:bg-gray-400 p-2 rounded-lg"
            onClick={() => photoRef.current.click()}
          >
            <CiSquarePlus />
          </div>
        </div>
      </div>

      <button
        className="small-form-button mt-2"
        onClick={handleSubmit}
        disabled={isProcessing}
      >
        {isProcessing ? "Processing..." : "Submit"}
      </button>
    </UserScreenWindow>
  );
}
