import React from "react";
import HomeIcon from "@mui/icons-material/Home";
import QrCodeScannerIcon from "@mui/icons-material/QrCodeScanner";
import ChatBubbleIcon from "@mui/icons-material/ChatBubble";
import PersonIcon from "@mui/icons-material/Person";
import { Link } from "react-router-dom";

export default function SecurityNavbar() {
  const navItems = [
    {
      label: "Home",
      icon: <HomeIcon />,
      link: "/",
    },
    {
      label: "Scan QR Code",
      icon: <QrCodeScannerIcon />,
      link: "/qr",
    },
    {
      label: "Chat",
      icon: <ChatBubbleIcon />,
      link: "/chat",
    },
    {
      label: "Profile",
      icon: <PersonIcon />,
      link: "/profile",
    },
  ];

  return (
    <div className="flex justify-around text-center bg-gray-200 text-gray-600 pb-8 pt-2 rounded-t-xl">
      {navItems.map((item, index) => (
        <div
          key={index}
          className="flex flex-col items-center justify-start p-2 cursor-pointer hover:bg-green-400 rounded-lg
          w-20 h-20
          "
        >
          <Link to={item.link} className="text-center">
            {item.icon}
            <div className="text-xs text-center">{item.label}</div>
          </Link>
        </div>
      ))}
    </div>
  );
}
