import React from "react";
import { CiSearch } from "react-icons/ci";

export default function SearchBar({ placeholder, searchTerm, setSearchTerm }) {
  return (
    <div>
      <div className="bg-white rounded-xl overflow-hidden flex items-center justify-between p-2">
        <input
          type="text"
          placeholder={`Search ${placeholder}`}
          className="grow outline-none w-full"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <CiSearch className="text-gray-500 mr-2 text-lg" />
      </div>
    </div>
  );
}
