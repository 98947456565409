import React, { useEffect } from "react";
import { getAllDocsSnap } from "../../../utils/firebase/Firebase.utils";
import TenantDetails from "./TenantDetails";
import Modal from "../Components/Modal";
import TenantCard from "./TenantCard";
import { Link } from "react-router-dom";
import BentoTable from "../../../components/BentoTable";
import { slicedData } from "../../../utils/EleghoUtils";
import {
  FiHome,
  FiUsers,
  FiUserCheck,
  FiUserX,
  FiFileText,
} from "react-icons/fi";
import { useCompany } from "../../../context/CompanyContext";
import CompanyTerms from "../../../components/CompanyTerms";

export default function LongTermLeases({ searchTerm }) {
  const [tenants, setTenants] = React.useState([]);
  const [tenant, setTenant] = React.useState({});
  const [viewMode, setViewMode] = React.useState(false);
  const [residents, setResidents] = React.useState([]);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [numberOfItemPerPage, setNumberOfItemPerPage] = React.useState(5);
  const { terms, getCapitalizedTerm } = useCompany();

  const tableHeaders = [
    getCapitalizedTerm("owner"),
    getCapitalizedTerm("identifier"),
    "Tenant Name",
    "Occupants",
    "Address",
    "Lease Start",
    "Lease End",
    "Documents",
  ];

  useEffect(() => {
    const fetchResidents = async () => {
      getAllDocsSnap("residents", (residents) => {
        setResidents(residents);
      });
    };

    fetchResidents();
  }, []);

  useEffect(() => {
    const fetchTenants = async () => {
      getAllDocsSnap("tenants", (tenants) => {
        const mappedTenants = tenants.map((tenant) => {
          const unitOwner = residents.find(
            (resident) => resident.identifier === tenant.lot
          );
          return {
            ...tenant,
            unitOwner: unitOwner?.owner1 || "N/A",
            tenantName: `${tenant.firstName} ${tenant.lastName}`,
            documents: "View",
          };
        });
        setTenants(mappedTenants);
      });
    };

    fetchTenants();
  }, [residents]);

  const handleViewMode = (tenant) => {
    setTenant(tenant);
    setViewMode(true);
  };

  const filteredTenants = tenants.filter(
    (tenant) =>
      tenant.tenantName.toLowerCase().includes(searchTerm.toLowerCase()) ||
      tenant.unitOwner.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const dataSliced = slicedData(filteredTenants, 5, 1);

  // Calculate stats
  const stats = {
    totalLeases: tenants.length,
    activeLeases: tenants.filter((t) => new Date(t.leaseEnd) > new Date())
      .length,
    expiringLeases: tenants.filter((t) => {
      const leaseEnd = new Date(t.leaseEnd);
      const now = new Date();
      const thirtyDaysFromNow = new Date(now.setDate(now.getDate() + 30));
      return leaseEnd <= thirtyDaysFromNow && leaseEnd > new Date();
    }).length,
    totalTenants: tenants.reduce(
      (sum, t) => sum + (parseInt(t.occupants) || 0),
      0
    ),
  };

  return (
    <div className="text-xs">
      <div>
        <div className="mt-4 font-bold text-blue-900 uppercase text-left text-sm">
          All Lease Tenants
        </div>

        {/* Stats Cards */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 my-6">
          <div className="bg-white p-6 rounded-xl border border-gray-100 shadow-sm hover:shadow-md transition-shadow">
            <div className="flex items-center gap-3">
              <div className="p-2 bg-gray-50 rounded-lg">
                <FiFileText className="w-5 h-5 text-gray-600" />
              </div>
              <div>
                <div className="text-sm text-gray-600">Total Leases</div>
                <div className="text-2xl font-semibold text-gray-900">
                  {stats.totalLeases}
                </div>
              </div>
            </div>
          </div>
          <div className="bg-white p-6 rounded-xl border border-gray-100 shadow-sm hover:shadow-md transition-shadow">
            <div className="flex items-center gap-3">
              <div className="p-2 bg-eleghoGreen/10 rounded-lg">
                <FiUserCheck className="w-5 h-5 text-eleghoGreen" />
              </div>
              <div>
                <div className="text-sm text-gray-600">Active Leases</div>
                <div className="text-2xl font-semibold text-eleghoGreen">
                  {stats.activeLeases}
                </div>
              </div>
            </div>
          </div>
          <div className="bg-white p-6 rounded-xl border border-gray-100 shadow-sm hover:shadow-md transition-shadow">
            <div className="flex items-center gap-3">
              <div className="p-2 bg-orange-50 rounded-lg">
                <FiUserX className="w-5 h-5 text-orange-500" />
              </div>
              <div>
                <div className="text-sm text-gray-600">Expiring Soon</div>
                <div className="text-2xl font-semibold text-orange-500">
                  {stats.expiringLeases}
                </div>
              </div>
            </div>
          </div>
          <div className="bg-white p-6 rounded-xl border border-gray-100 shadow-sm hover:shadow-md transition-shadow">
            <div className="flex items-center gap-3">
              <div className="p-2 bg-eleghoBlue/10 rounded-lg">
                <FiUsers className="w-5 h-5 text-eleghoBlue" />
              </div>
              <div>
                <div className="text-sm text-gray-600">Total Occupants</div>
                <div className="text-2xl font-semibold text-eleghoBlue">
                  {stats.totalTenants}
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Table Section */}
        <BentoTable
          label="All Lease Tenants"
          data={dataSliced}
          numberOfItemPerPage={5}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        >
          {/* Table Header */}
          <div className="grid grid-cols-12 gap-4 p-4 border-b border-gray-100 bg-gray-50 rounded-t-xl">
            <div className="col-span-1">
              <div className="text-xs font-medium text-gray-500 uppercase tracking-wider">
                <CompanyTerms term="unit" capitalize={true} />
              </div>
            </div>
            <div className="col-span-1">
              <div className="text-xs font-medium text-gray-500 uppercase tracking-wider">
                <CompanyTerms term="owner" capitalize={true} />
              </div>
            </div>
            <div className="col-span-2">
              <div className="text-xs font-medium text-gray-500 uppercase tracking-wider">
                Tenant Name
              </div>
            </div>
            <div className="col-span-1">
              <div className="text-xs font-medium text-gray-500 uppercase tracking-wider">
                Occupants
              </div>
            </div>
            <div className="col-span-2">
              <div className="text-xs font-medium text-gray-500 uppercase tracking-wider">
                Address
              </div>
            </div>
            <div className="col-span-2">
              <div className="text-xs font-medium text-gray-500 uppercase tracking-wider">
                Lease Start
              </div>
            </div>
            <div className="col-span-2">
              <div className="text-xs font-medium text-gray-500 uppercase tracking-wider">
                Lease End
              </div>
            </div>
            <div className="col-span-1">
              <div className="text-xs font-medium text-gray-500 uppercase tracking-wider">
                Docs
              </div>
            </div>
          </div>

          {/* Table Body */}
          <div className="space-y-2">
            {filteredTenants.map((tenant, index) => (
              <div
                key={index}
                className="cursor-pointer hover:bg-gray-50 transition-colors duration-200"
                onClick={() => handleViewMode(tenant)}
              >
                <Link to={`/tenant/${tenant.id}?detail=Basic+Information`}>
                  <TenantCard tenant={tenant} />
                </Link>
              </div>
            ))}
          </div>
        </BentoTable>
      </div>
    </div>
  );
}
