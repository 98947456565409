import React from "react";
import InputBox from "./InputBox";

export default function NumberInputText({
  name,
  label,
  placeholder,
  formData,
  setFormData,
}) {
  const formatNumber = (num) => {
    // Convert the number to a string
    let strNum = num.toString();

    // Use the regex only on the integer part of the number
    const parts = strNum.split(".");
    const integerPart = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");

    // Recombine the integer part with the decimal part, if it exists
    return parts.length > 1 ? `${integerPart}.${parts[1]}` : integerPart;
  };

  const handleNumberChange = (e) => {
    const { name, value } = e.target;
    const numericValue = e.target.value.replace(/[^0-9.]/g, "");
    setFormData({ ...formData, [name]: formatNumber(numericValue) });
  };

  return (
    <div>
      <InputBox
        type="text"
        label={label}
        name={name}
        placeholder={placeholder}
        onChange={handleNumberChange}
        value={formData[name]}
      />
    </div>
  );
}
