import React, { useEffect, useState } from "react";
import { getAllDocsSnap } from "../../../utils/firebase/Firebase.utils";

export default function AdvisoryComponent() {
  const [advisory, setAdvisory] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchAdvisory = async () => {
      await getAllDocsSnap("announcements", (data) => {
        const advisoryItem = data.find(
          (announcement) => announcement.type === "advisory"
        );
        setAdvisory(advisoryItem);
        setIsLoading(false);
      });
    };
    fetchAdvisory();
  }, []);

  if (isLoading) {
    return (
      <div className="text-xs border border-red-500 p-2 rounded-md w-full ">
        <div className="font-bold text-red-500">Advisory</div>
        <div>Loading...</div>
      </div>
    );
  }

  if (!advisory) {
    return (
     <div></div>
    );
  }

  return (
    <div className="text-xs border border-red-500 p-2 rounded-md shadow-md">
      <div className="font-bold text-red-500">{advisory.title}</div>
      <div>{advisory.Details}</div>
    </div>
  );
}
