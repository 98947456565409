import React from "react";

function InputBox({
  label,
  type,
  placeholder,
  value,
  onChange,
  className = "",
  error,
  ...otherProps
}) {
  return (
    <div className="flex flex-col gap-1.5 ">
      <label className="text-xs font-medium text-gray-700  text-left">{label}</label>
      <div className="relative">
        <input
          className={`
            w-full px-3 py-2 text-sm
            bg-white border rounded-lg
            text-gray-900 placeholder-gray-400
            focus:outline-none focus:ring-2 focus:ring-eleghoBlue/20 focus:border-eleghoBlue
            transition-all duration-200
            ${
              error
                ? "border-red-300 focus:border-red-500 focus:ring-red-200"
                : "border-gray-200 hover:border-gray-300"
            }
            ${className}
          `}
          type={type}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          {...otherProps}
        />
        {error && (
          <div className="absolute right-3 top-1/2 -translate-y-1/2">
            <svg
              className="w-4 h-4 text-red-500"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
          </div>
        )}
      </div>
      {error && <p className="text-xs text-red-500 mt-0.5">{error}</p>}
    </div>
  );
}

export default InputBox;
