import React from "react";
import { NavLink, useLocation } from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion";
import {
  FiGrid,
  FiUsers,
  FiTool,
  FiShield,
  FiHome,
  FiBox,
  FiBarChart2,
  FiVolume2,
  FiFileText,
  FiTruck,
  FiShoppingBag,
  FiBriefcase,
  FiMenu,
  FiX,
} from "react-icons/fi";

function Navbar() {
  const [showMobileMenu, setShowMobileMenu] = React.useState(false);
  const location = useLocation();

  // Prevent background scrolling when mobile menu is open
  React.useEffect(() => {
    if (showMobileMenu) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
    return () => {
      document.body.style.overflow = "unset";
    };
  }, [showMobileMenu]);

  const navbaritems = [
    {
      name: "Overview",
      icon: <FiGrid className="w-5 h-5" />,
      link: "/overview",
      exact: true,
    },
    {
      name: "Resident Management",
      icon: <FiUsers className="w-5 h-5" />,
      link: "/resident-management",
      exact: false,
    },
    {
      name: "Property Management",
      icon: <FiTool className="w-5 h-5" />,
      link: "/property-management",
      exact: false,
    },
    {
      name: "Security",
      icon: <FiShield className="w-5 h-5" />,
      link: "/security",
      exact: true,
    },
    {
      name: "Facilities",
      icon: <FiHome className="w-5 h-5" />,
      link: "/facilities",
      exact: true,
    },
    {
      name: "Inventory",
      icon: <FiBox className="w-5 h-5" />,
      link: "/inventory",
      exact: true,
    },
    {
      name: "Analytics",
      icon: <FiBarChart2 className="w-5 h-5" />,
      link: "/analytics",
      exact: true,
    },
    {
      name: "Announcements",
      icon: <FiVolume2 className="w-5 h-5" />,
      link: "/announcements",
      exact: true,
    },
    {
      name: "Billing",
      icon: <FiFileText className="w-5 h-5" />,
      link: "/billing",
      exact: true,
    },
    {
      name: "Suppliers",
      icon: <FiTruck className="w-5 h-5" />,
      link: "/services",
      exact: true,
    },
    {
      name: "Marketplace",
      icon: <FiShoppingBag className="w-5 h-5" />,
      link: "/marketplace",
      exact: true,
    },
    {
      name: "HOA Directory",
      icon: <FiBriefcase className="w-5 h-5" />,
      link: "/contacts",
      exact: true,
    },
  ];

  const isLinkActive = (item) => {
    const currentPath = location.pathname;
    if (item.exact) {
      return currentPath === item.link;
    }
    return currentPath.startsWith(item.link);
  };

  return (
    <nav className="bg-eleghoBlue shadow-lg relative z-10 main-navbar">
      {/* Desktop Navigation */}
      <div className="hidden lg:block py-1">
        <div className="flex items-center justify-center gap-1">
          {navbaritems.map((item, index) => (
            <NavLink
              key={item.name}
              to={item.link}
              end={item.exact}
              className={({ isActive }) =>
                `flex items-center px-3 py-2 text-xs font-medium transition-all duration-200 rounded-md mx-0.5 ${
                  isActive
                    ? "text-gray-800 bg-eleghoGreen"
                    : "text-white hover:bg-eleghoGreen hover:text-gray-800"
                }`
              }
            >
              <motion.div
                initial={{ scale: 0.9 }}
                animate={{ scale: 1 }}
                transition={{ delay: index * 0.1 }}
                className="flex flex-col items-center gap-1.5"
              >
                {item.icon}
                <span className="text-[11px] font-medium">{item.name}</span>
              </motion.div>
            </NavLink>
          ))}
        </div>
      </div>

      {/* Mobile Navigation */}
      <div className="lg:hidden relative">
        {/* Mobile Header */}
        <div className="flex items-center justify-between px-4 h-16 border-b border-gray-100">
          <div className="text-lg font-semibold text-white">Tali Connect</div>
          <button
            onClick={() => setShowMobileMenu(!showMobileMenu)}
            className="p-2 rounded-lg text-white hover:text-eleghoGreen hover:bg-white/10 transition-colors"
          >
            <FiMenu className="w-6 h-6" />
          </button>
        </div>

        {/* Mobile Menu */}
        <AnimatePresence>
          {showMobileMenu && (
            <>
              {/* Backdrop */}
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.2 }}
                className="fixed inset-0 bg-black/50 z-40"
                onClick={() => setShowMobileMenu(false)}
              />
              {/* Menu */}
              <motion.div
                initial={{ x: "100%" }}
                animate={{ x: 0 }}
                exit={{ x: "100%" }}
                transition={{ type: "tween", duration: 0.3 }}
                className="fixed inset-y-0 right-0 w-full bg-white z-50 overflow-y-auto shadow-xl"
              >
                <div className="flex justify-between items-center px-4 py-4 border-b">
                  <div className="text-lg font-semibold text-gray-900">
                    Menu
                  </div>
                  <button
                    onClick={() => setShowMobileMenu(false)}
                    className="p-2 rounded-lg text-gray-600 hover:text-eleghoBlue hover:bg-gray-100 transition-colors"
                  >
                    <FiX className="w-6 h-6" />
                  </button>
                </div>
                <div className="px-2 py-3 space-y-1">
                  {navbaritems.map((item, index) => (
                    <motion.div
                      key={index}
                      initial={{ x: 20, opacity: 0 }}
                      animate={{ x: 0, opacity: 1 }}
                      transition={{ delay: index * 0.05 }}
                    >
                      <NavLink
                        to={item.link}
                        end={item.exact}
                        onClick={() => setShowMobileMenu(false)}
                        className={({ isActive }) =>
                          `flex items-center gap-3 px-4 py-3 rounded-lg text-sm font-medium transition-all ${
                            isActive
                              ? "text-white bg-eleghoBlue"
                              : "text-gray-600 hover:bg-gray-100 hover:text-eleghoBlue"
                          }`
                        }
                      >
                        {item.icon}
                        <span>{item.name}</span>
                      </NavLink>
                    </motion.div>
                  ))}
                </div>
              </motion.div>
            </>
          )}
        </AnimatePresence>
      </div>
    </nav>
  );
}

export default Navbar;
