import React, { useEffect } from "react";
import {
  addNewDoc,
  deleteDocById,
  setDocData,
} from "../../../utils/firebase/Firebase.utils";
import InputBox from "../Components/InputBox";
import { useSelector } from "react-redux";
import PhotosUploader from "./PhotosUploader";
import { handleUploadMultiple } from "../../../utils/EleghoUtils";
import toast from "react-hot-toast";
import FacilityCard from "../../User/Facilities/FacilityCard";
import FunctionRoomRequest from "../../User/Facilities/FunctionRoomRequest";

export default function NewFacilityForm({ data, setAddMode, setData }) {
  const currentUser = useSelector((state) => state.userObject);
  const [formData, setFormData] = React.useState(data || {});
  const [isProcessing, setIsProcessing] = React.useState(false);
  const [editMode, setEditMode] = React.useState(data ? false : true);

  const [keyValuePairArray, setKeyValuePairArray] = React.useState(
    data
      ? Object.entries(data.Features).map(([key, value]) => ({ key, value }))
      : [{ key: "", value: "" }] || [{ key: "", value: "" }]
  );
  const [featuresObject, setFeaturesObject] = React.useState(
    data.Features || {}
  );
  const [roomFeatures, setRoomFeatures] = React.useState(
    data?.roomFeatures || [""]
  );
  const [photos, setPhotos] = React.useState(data ? data.photos : []);
  const [primaryPhoto, setPrimaryPhoto] = React.useState(
    data?.primaryPhoto || ""
  );

  useEffect(() => {
    setFormData({ ...formData, photos });
  }, [photos]);

  useEffect(() => {
    const newObject = keyValuePairArray.reduce((acc, item) => {
      // remove empty key value pairs
      if (item.key && item.value) {
        acc[item.key] = item.value;
        return acc;
      } else {
        return acc;
      }
    }, {});

    setFeaturesObject(newObject);
  }, [keyValuePairArray]);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleKeyValuePairChange = (e, index, key) => {
    const newArray = [...keyValuePairArray];
    newArray[index][key] = e.target.value;
    setKeyValuePairArray(newArray);
  };

  const handleAddToObject = () => {
    setKeyValuePairArray([...keyValuePairArray, { key: "", value: "" }]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsProcessing(true);

    const data = {
      ...formData,
      photos: photos,
      primaryPhoto: primaryPhoto || photos[0] || "",
      Features: featuresObject,
      roomFeatures: roomFeatures,
      createdBy: currentUser.uid,
      dateCreated: new Date().getTime(),
    };

    if (formData.id) {
      await setDocData("facilities", formData.id, data);
      setIsProcessing(false);
      setAddMode(false);
      setData(null);
      toast.success("Facility Updated");
      return;
    }

    await addNewDoc("facilities", data);
    setIsProcessing(false);
    setAddMode(false);
    setData(null);
    toast.success("Facility Added");
  };

  const handleUpload = async (e) => {
    await handleUploadMultiple(
      e,
      formData,
      setFormData,
      `facilities`,
      "photos"
    );
    setPhotos(formData.photos);
  };

  const handleDelete = async () => {
    if (window.confirm("Are you sure you want to delete this facility?")) {
      await deleteDocById("facilities", formData.id);
      toast.success("Facility Deleted");
      setData(null);
      setAddMode(false);
    }
  };

  const handleEditMode = () => {
    setEditMode(!editMode);
  };

  return (
    <div className="page relative text-left">
      <button
        className="close-button"
        onClick={() => {
          setAddMode(false);
          setData(null);
        }}
      >
        Close
      </button>
      <div className="flex items-center gap-4">
        <button type="button" className="underline" onClick={handleEditMode}>
          {editMode ? "Cancel" : "Edit"}
        </button>
        <button type="button" className="underline" onClick={handleDelete}>
          Delete
        </button>
      </div>

      <div className="header mt-4">
        {data ? "Edit Facility" : "Create New Facility"}
      </div>

      <div className="flex items-start gap-8 flex-wrap">
        <div className="w-96">
          <div className="mt-4">
            <form onSubmit={handleSubmit} className="flex flex-col gap-4">
              <InputBox
                name="name"
                label="Facility Name"
                type="text"
                placeholder={"Enter Facility Name"}
                value={formData.name}
                onChange={handleChange}
                required
                disabled={!editMode}
              />
              <InputBox
                name="description"
                label="Description"
                type="text"
                placeholder={"Enter Description"}
                value={formData.description}
                onChange={handleChange}
                disabled={!editMode}
                required
              />
              <div className="font-bold">Features</div>
              <div>
                <button
                  onClick={handleAddToObject}
                  type="button"
                  className="blue-button mb-4"
                  disabled={!editMode}
                >
                  Add a Technical Feature
                </button>
                {keyValuePairArray.map((item, index) => (
                  <div key={index} className="flex gap-2">
                    <InputBox
                      label="Feature Name"
                      type="text"
                      placeholder="Feature Name"
                      disabled={!editMode}
                      value={item.key}
                      onChange={(e) =>
                        handleKeyValuePairChange(e, index, "key")
                      }
                    />
                    <InputBox
                      label="Value"
                      type="text"
                      placeholder="Value"
                      value={item.value}
                      disabled={!editMode}
                      onChange={(e) =>
                        handleKeyValuePairChange(e, index, "value")
                      }
                    />
                    {editMode && (
                      <button
                        className="underline"
                        type="button"
                        disabled={!editMode}
                        onClick={() => {
                          const newArray = [...keyValuePairArray];
                          newArray.splice(index, 1);
                          setKeyValuePairArray(newArray);
                        }}
                      >
                        Remove
                      </button>
                    )}
                  </div>
                ))}
                <div className="mt-4">
                  <button
                    className="blue-button"
                    type="button"
                    disabled={!editMode}
                    onClick={() => {
                      setRoomFeatures([...roomFeatures, ""]);
                    }}
                  >
                    Add Key Feature Bullet Points
                  </button>
                  {roomFeatures.map((item, index) => (
                    <div className="flex items-center gap-4" key={index}>
                      <div className="w-2 h-2 rounded-full bg-gray-600"></div>
                      <InputBox
                        type="text"
                        value={item}
                        disabled={!editMode}
                        placeholder="e.g. 24/7 Security"
                        onChange={(e) => {
                          const newArray = [...roomFeatures];
                          newArray[index] = e.target.value;
                          setRoomFeatures(newArray);
                        }}
                      />
                      {editMode && (
                        <button
                          className="underline"
                          type="button"
                          disabled={!editMode}
                          onClick={() => {
                            const newArray = [...roomFeatures];
                            newArray.splice(index, 1);
                            setRoomFeatures(newArray);
                          }}
                        >
                          Remove
                        </button>
                      )}
                    </div>
                  ))}
                </div>
              </div>
              <div>
                <div>Upload Photos</div>
                <PhotosUploader
                  data={photos}
                  setData={setPhotos}
                  primaryPhoto={primaryPhoto}
                  setPrimaryPhoto={setPrimaryPhoto}
                  uploader={handleUpload}
                  disabled={!editMode}
                />
              </div>
              <div className="mt-4">
                <button
                  className="small-form-button"
                  type="submit"
                  disabled={!editMode}
                >
                  {isProcessing ? "Processing.." : "Submit"}
                </button>
              </div>
            </form>
          </div>
        </div>
        <div className="w-96  bg-gray-100 rounded-lg p-4">
          <div className="font-bold mb-8">Preview</div>
          <FacilityCard data={formData} primaryPhoto={primaryPhoto} />

          {/* <div>
            <FunctionRoomRequest data={formData} />
          </div> */}
        </div>
      </div>
    </div>
  );
}
