import React from "react";
import { motion } from "framer-motion";

function Modal({ children }) {
  return (
    <div className="fixed inset-0 bg-black/50 backdrop-blur-sm z-50 flex items-center justify-center p-4">
      <motion.div
        initial={{ opacity: 0, scale: 0.95 }}
        animate={{ opacity: 1, scale: 1 }}
        exit={{ opacity: 0, scale: 0.95 }}
        transition={{ duration: 0.2 }}
        className="w-full flex items-center justify-center"
      >
        {children}
      </motion.div>
    </div>
  );
}

export default Modal;
