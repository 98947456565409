import React, { useEffect, useState } from "react";
import Header from "./Header";
import Navbar from "./Navbar";
import { Outlet, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import UserDashboard from "../User/UserDashboard/UserDashboard";
import UserNavBar from "../User/UserNavBar";
import { motion, AnimatePresence } from "framer-motion";

function Dashboard() {
  const currentUser = useSelector((state) => state.userObject);
  const navigate = useNavigate();
  const [isMobileView, setIsMobileView] = useState(false);

  // Check if we're in mobile view
  useEffect(() => {
    const checkMobileView = () => {
      setIsMobileView(document.body.className.includes("mobile-view"));
    };

    // Check initially
    checkMobileView();

    // Set up a MutationObserver to watch for class changes on the body
    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.attributeName === "class") {
          checkMobileView();
        }
      });
    });

    observer.observe(document.body, { attributes: true });

    return () => observer.disconnect();
  }, []);

  if (currentUser?.roles?.length < 1) {
    return (
      <div className=" flex items-center justify-center font-semibold text-lg flex-col h-screen">
        <div>
          Congratulations, your account has been created. However, please wait
          for the activation of your access rights.
        </div>
        <button
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mt-4"
          onClick={() => {
            navigate("/login");
          }}
        >
          Log Out
        </button>
      </div>
    );
  }

  if (currentUser?.roles?.includes("user")) {
    return (
      <AnimatePresence>
        <div className="max-w-[450px] w-screen m-auto bg-gray-100">
          <motion.div className="relative max-w-[450px] m-auto w-screen">
            <div className="pb-28">
              <Outlet />
            </div>
          </motion.div>
          {!isMobileView && (
            <div className="fixed left-0 bottom-0 mx-auto w-full">
              <UserNavBar />
            </div>
          )}
        </div>
      </AnimatePresence>
    );
  }

  return (
    <div className="min-h-screen h-screen bg-[#eef8fa] ">
      <div className="h-auto">
        {!isMobileView && (
          <>
            <Header />
            <Navbar />
          </>
        )}
        <div className="px-1 lg:px-2 py-2 bg-[#eef8fa] h-auto max-w-[1440p] m-auto">
          <Outlet />
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
