import React from "react";
import { motion } from "framer-motion";
import { FiClock, FiMapPin, FiArrowRight } from "react-icons/fi";

function WorkItemCard({ item, onClick, colors, onViewAll, index }) {
  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ delay: index * 0.1 }}
      className="relative flex flex-col border border-gray-100 rounded-lg p-4 bg-white hover:shadow-lg hover:border-gray-200 transition-all duration-200 group"
    >
      {/* Header with type badge */}
      <div className="flex items-center justify-between mb-3">
        <div
          className="px-2.5 py-1 rounded-md text-xs font-medium"
          style={{
            backgroundColor:
              colors.find((color) => color.name === item.type)?.color + "15",
            color: colors.find((color) => color.name === item.type)?.color,
          }}
        >
          {item.type}
        </div>
        <div className="flex items-center text-xs text-gray-400 gap-1.5">
          <FiClock className="w-3.5 h-3.5" />
          {item.timeAgo}
        </div>
      </div>

      {/* Content */}
      <div className="flex flex-col gap-2">
        <h3 className="font-medium text-gray-900 group-hover:text-blue-600 transition-colors">
          {item.title}
        </h3>
        <div className="flex items-center gap-1.5 text-xs text-gray-500">
          <FiMapPin className="w-3.5 h-3.5" />
          {item.location}
        </div>
      </div>

      {/* View All Button */}
      <button
        onClick={() => onViewAll(item.type)}
        className="flex items-center gap-1.5 text-xs text-blue-600 hover:text-blue-700 mt-4 group-hover:gap-2 transition-all duration-200"
      >
        View Details
        <FiArrowRight className="w-3.5 h-3.5" />
      </button>
    </motion.div>
  );
}

export default WorkItemCard;
