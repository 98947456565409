import React from "react";
import { FiMonitor, FiSmartphone } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import AdminHeaderMobile from "./AdminHeaderMobile";
import AdminAppsMobile from "./AdminAppsMobile";
import AdminQuickAccessMobile from "./AdminQuickAccessMobile";
import AdminTasksSummaryMobile from "./AdminTasksSummaryMobile";
import SecurityAppicons from "../SecurityMobile/SecurityAppicons";
import Announcements from "../User/Announcements";

export default function AdminHomeMobile() {
  const navigate = useNavigate();
  const [displayMode, setDisplayMode] = React.useState("mobile");
  const currentUser = useSelector((state) => state.userObject);

  // Handle display mode change
  React.useEffect(() => {
    if (displayMode === "desktop") {
      // Navigate to the desktop admin dashboard layout
      navigate("/overview");

      // Remove mobile view class
      document.body.className = "";
    } else {
      document.body.className = "mobile-view";
    }
  }, [displayMode, navigate]);

  return (
    <div className="pb-8">
      <AdminHeaderMobile />
      {/* put the toggle here */}
      {currentUser?.roles?.includes("admin") && (
        <div className="flex justify-end px-4 pt-4">
          <div className="bg-white rounded-lg shadow-sm flex items-center p-1">
            <button
              onClick={() => setDisplayMode("desktop")}
              className={`flex items-center gap-1.5 px-3 py-1.5 rounded-md transition-colors ${
                displayMode === "desktop"
                  ? "bg-blue-100 text-blue-600"
                  : "text-gray-600 hover:bg-gray-100"
              }`}
            >
              <FiMonitor className="w-4 h-4" />
              <span className="text-sm font-medium">Desktop</span>
            </button>
            <button
              onClick={() => setDisplayMode("mobile")}
              className={`flex items-center gap-1.5 px-3 py-1.5 rounded-md transition-colors ${
                displayMode === "mobile"
                  ? "bg-blue-100 text-blue-600"
                  : "text-gray-600 hover:bg-gray-100"
              }`}
            >
              <FiSmartphone className="w-4 h-4" />
              <span className="text-sm font-medium">Mobile</span>
            </button>
          </div>
        </div>
      )}

      <AdminAppsMobile />
      <AdminQuickAccessMobile />
      <AdminTasksSummaryMobile />
      <Announcements />
    </div>
  );
}
