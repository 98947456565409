import React, { useEffect } from "react";
import { getAllDocsSnap } from "../../utils/firebase/Firebase.utils";
import GatePassCard from "./GatePassCard";
import PageModal from "../Dashboard/Components/PageModal";
import GatePassDetailsPage from "./GatePassDetailsPage";
import BentoTable from "../../components/BentoTable";
import { slicedData } from "../../utils/EleghoUtils";
import LinksBar from "../../components/LinksBar";
import {
  FiFileText,
  FiCheckCircle,
  FiClock,
  FiXCircle,
  FiCheckSquare,
  FiHome,
  FiCalendar,
  FiPackage,
  FiTruck,
} from "react-icons/fi";

export default function GatePass() {
  const [gatePassRequests, setGatePassRequests] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [viewMode, setViewMode] = React.useState(false);
  const [selectedData, setSelectedData] = React.useState(null);
  const [currentPage, setCurrentPage] = React.useState(1);
  const links = ["All", "Approved", "Pending", "Rejected", "Completed"];
  const [numberOfItemPerPage, setNumberOfItemPerPage] = React.useState(5);
  const [activeLink, setActiveLink] = React.useState("Pending");

  useEffect(() => {
    const getGatePassRequests = async () => {
      await getAllDocsSnap("gatePass", (data) => {
        setGatePassRequests(data);
        setLoading(false);
      });
    };

    getGatePassRequests();
  }, []);

  if (loading) {
    return (
      <div className="flex items-center justify-center h-64">
        <div className="text-center">
          <div className="w-16 h-16 border-4 border-t-eleghoBlue border-gray-200 rounded-full animate-spin mx-auto"></div>
          <p className="mt-4 text-gray-600">Loading gate pass requests...</p>
        </div>
      </div>
    );
  }

  const handleViewMode = (item) => {
    setViewMode(true);
    setSelectedData(item);
  };

  const filteredItems = gatePassRequests.filter(
    (item) => activeLink === "All" || item.status === activeLink.toLowerCase()
  );

  const dataSliced = filteredItems.slice(
    (currentPage - 1) * numberOfItemPerPage,
    currentPage * numberOfItemPerPage
  );

  // Stats calculation
  const stats = {
    total: gatePassRequests.length,
    approved: gatePassRequests.filter((item) => item.status === "approved")
      .length,
    pending: gatePassRequests.filter((item) => item.status === "pending")
      .length,
    rejected: gatePassRequests.filter((item) => item.status === "rejected")
      .length,
    completed: gatePassRequests.filter((item) => item.status === "completed")
      .length,
  };

  // Table headers
  

  return (
    <div className="text-xs">
      <div className="mt-4 font-bold text-blue-900 uppercase text-left text-sm">
        Gate Pass Requests
      </div>

      {/* Stats Cards */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-5 gap-6 my-6">
        <div className="bg-white p-6 rounded-xl border border-gray-100 shadow-sm hover:shadow-md transition-shadow">
          <div className="flex items-center gap-3">
            <div className="p-2 bg-gray-50 rounded-lg">
              <FiFileText className="w-5 h-5 text-gray-600" />
            </div>
            <div>
              <div className="text-sm text-gray-600">Total Requests</div>
              <div className="text-2xl font-semibold text-gray-900">
                {stats.total}
              </div>
            </div>
          </div>
        </div>
        <div className="bg-white p-6 rounded-xl border border-gray-100 shadow-sm hover:shadow-md transition-shadow">
          <div className="flex items-center gap-3">
            <div className="p-2 bg-eleghoGreen/10 rounded-lg">
              <FiCheckCircle className="w-5 h-5 text-eleghoGreen" />
            </div>
            <div>
              <div className="text-sm text-gray-600">Approved</div>
              <div className="text-2xl font-semibold text-eleghoGreen">
                {stats.approved}
              </div>
            </div>
          </div>
        </div>
        <div className="bg-white p-6 rounded-xl border border-gray-100 shadow-sm hover:shadow-md transition-shadow">
          <div className="flex items-center gap-3">
            <div className="p-2 bg-yellow-50 rounded-lg">
              <FiClock className="w-5 h-5 text-yellow-500" />
            </div>
            <div>
              <div className="text-sm text-gray-600">Pending</div>
              <div className="text-2xl font-semibold text-yellow-500">
                {stats.pending}
              </div>
            </div>
          </div>
        </div>
        <div className="bg-white p-6 rounded-xl border border-gray-100 shadow-sm hover:shadow-md transition-shadow">
          <div className="flex items-center gap-3">
            <div className="p-2 bg-red-50 rounded-lg">
              <FiXCircle className="w-5 h-5 text-red-500" />
            </div>
            <div>
              <div className="text-sm text-gray-600">Rejected</div>
              <div className="text-2xl font-semibold text-red-500">
                {stats.rejected}
              </div>
            </div>
          </div>
        </div>
        <div className="bg-white p-6 rounded-xl border border-gray-100 shadow-sm hover:shadow-md transition-shadow">
          <div className="flex items-center gap-3">
            <div className="p-2 bg-eleghoBlue/10 rounded-lg">
              <FiCheckSquare className="w-5 h-5 text-eleghoBlue" />
            </div>
            <div>
              <div className="text-sm text-gray-600">Completed</div>
              <div className="text-2xl font-semibold text-eleghoBlue">
                {stats.completed}
              </div>
            </div>
          </div>
        </div>
      </div>

      <LinksBar
        links={links}
        activeLink={activeLink}
        setActiveLink={setActiveLink}
      />

      <BentoTable
        label="Gate Pass Requests"
        data={filteredItems}
        numberOfItemPerPage={numberOfItemPerPage}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
      >
        <div className="divide-y divide-gray-100">
          {dataSliced.map((item, index) => (
            <div
              key={index}
              onClick={() => handleViewMode(item)}
              className="cursor-pointer hover:bg-gray-50 transition-colors"
            >
              <GatePassCard key={index} data={item} />
            </div>
          ))}
        </div>
      </BentoTable>

      {viewMode && (
        <PageModal>
          <GatePassDetailsPage data={selectedData} setViewMode={setViewMode} />
        </PageModal>
      )}
    </div>
  );
}
