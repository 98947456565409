import React from "react";
import InputBox from "./InputBox";
import ButtonBlue from "./ButtonBlue";
import { useEffect, useState } from "react";
import {
  signInWithEmail,
  findUserDocbyId,
} from "../../utils/firebase/Firebase.utils";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { loadUser } from "../../store/userSlice";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import PageModal from "../Dashboard/Components/PageModal";
import PrivacyPage from "./PrivacyPage";
import toast from "react-hot-toast";
import {
  requestNotificationPermission,
  saveUserToken,
} from "../../services/notificationService";
import Logo from "../assets/TaliConnectLogo.jpeg";

function LoginForm() {
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = React.useState(false);
  const [viewMode, setViewMode] = React.useState(false);
  const [error, setError] = React.useState("");
  const [fieldErrors, setFieldErrors] = React.useState({
    email: "",
    password: "",
  });

  const handleMouseDownPassword = (e) => {
    e.preventDefault();
  };
  const [isLoading, setIsLoading] = React.useState(false);
  const [formData, setFormData] = React.useState({});
  const [isProcessing, setIsProcessing] = React.useState(false);
  const Navigate = useNavigate();
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  const dispatch = useDispatch();

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsProcessing(true);
    setError(""); // Clear any previous errors
    setFieldErrors({ email: "", password: "" }); // Clear field errors

    const signIn = async () => {
      try {
        setIsLoading(true);
        const res = await signInWithEmail(formData.email, formData.password);
        if (res) {
          const { email, uid } = res.user;

          const {
            displayName,
            roles,
            department,
            linkedIdentifiers,
            photoUrl,
            principalUser,
          } = await findUserDocbyId(res.user.uid);

          dispatch(
            loadUser({
              email,
              displayName,
              uid,
              roles,
              department,
              linkedIdentifiers,
              photoUrl,
              principalUser,
            })
          );

          // Request notification permission and save token
          const token = await requestNotificationPermission();
          if (token) {
            await saveUserToken(uid, token);
          }

          Navigate("/dashboard?splash=true");
        }
      } catch (error) {
        console.error("Login error:", error);

        // Handle specific Firebase auth errors
        if (error.code === 400) {
          switch (error.message) {
            case "TOO_MANY_ATTEMPTS_TRY_LATER":
              const tooManyAttemptsMsg =
                "Too many login attempts. Please try again later or reset your password.";
              setError(tooManyAttemptsMsg);
              toast.error(tooManyAttemptsMsg);
              break;
            case "INVALID_LOGIN_CREDENTIALS":
              const invalidCredentialsMsg =
                "Invalid email or password. Please check your credentials.";
              setError(invalidCredentialsMsg);
              setFieldErrors({
                email: "Please verify your email",
                password: "Please verify your password",
              });
              toast.error(invalidCredentialsMsg);
              break;
            default:
              const defaultMsg =
                "Login failed. Please check your credentials and try again.";
              setError(defaultMsg);
              toast.error(defaultMsg);
          }
        } else {
          switch (error.code) {
            case "auth/too-many-requests":
              const tooManyRequestsMsg =
                "Too many login attempts. Please try again later or reset your password.";
              setError(tooManyRequestsMsg);
              toast.error(tooManyRequestsMsg);
              break;
            case "auth/wrong-password":
              const wrongPasswordMsg = "Incorrect password. Please try again.";
              setFieldErrors({ ...fieldErrors, password: wrongPasswordMsg });
              toast.error(wrongPasswordMsg);
              break;
            case "auth/user-not-found":
              const userNotFoundMsg =
                "No account found with this email. Please check your email or sign up.";
              setFieldErrors({ ...fieldErrors, email: userNotFoundMsg });
              toast.error(userNotFoundMsg);
              break;
            case "auth/invalid-email":
              const invalidEmailMsg =
                "Invalid email address. Please enter a valid email.";
              setFieldErrors({ ...fieldErrors, email: invalidEmailMsg });
              toast.error(invalidEmailMsg);
              break;
            case "auth/user-disabled":
              const userDisabledMsg =
                "This account has been disabled. Please contact support.";
              setError(userDisabledMsg);
              toast.error(userDisabledMsg);
              break;
            case "auth/network-request-failed":
              const networkErrorMsg =
                "Network error. Please check your internet connection and try again.";
              setError(networkErrorMsg);
              toast.error(networkErrorMsg);
              break;
            default:
              const defaultErrorMsg =
                "Login failed. Please check your credentials and try again.";
              setError(defaultErrorMsg);
              toast.error(defaultErrorMsg);
          }
        }
      } finally {
        setIsLoading(false);
        setIsProcessing(false);
      }
    };
    signIn();
  };

  const handleClickShowPassword = () => {
    setShowPassword((show) => !show);
  };

  const handleViewPrivacyPolicy = () => {
    setViewMode(!viewMode);
  };

  return (
    <div className="bg-white rounded-xl shadow-lg p-4 w-[350px]">
      <div className="flex flex-col items-start justify-around gap-4">
        <div className="text-lg font-bold uppercase w-full text-center">
          <img src={Logo} alt="" className="w-20 h-20 m-auto" />
          <div className="text-gray-600 font-bold text-xs capitalize mt-4">
            Powered by
          </div>
          <div>
            <img
              src="https://www.elegho.com/_next/image?url=https%3A%2F%2Fres.cloudinary.com%2Fduk7mmsc6%2Fimage%2Fupload%2Fv1741437371%2FElegho%2FElegho-Logo_qdoodo.png&w=640&q=75"
              alt=""
              className="w-16 m-auto"
            />
          </div>
        </div>
        <div className="text-center w-full">
          Enter Your Log in Credentials Below
        </div>
        <div className="w-full ">
          <form className="w-full" onSubmit={handleSubmit}>
            <InputBox
              label="Email Address"
              type="email"
              placeholder="Enter your email address"
              onChange={handleChange}
              name={"email"}
            />
            <div className="relative mt-4">
              <InputBox
                label="Password"
                type={showPassword ? "text" : "password"}
                placeholder="Enter your password"
                onChange={handleChange}
                name={"password"}
              />
              <button
                type="button"
                className="absolute top-1/2 pt-1 right-2"
                onClick={handleClickShowPassword}
              >
                {showPassword ? <FaEyeSlash /> : <FaEye />}
              </button>
            </div>
            <div className="mt-8">
              <ButtonBlue label={isProcessing ? "Logging in..." : "Login"} />
            </div>
            <div>
              <Link to="/resetpassword" className="text-blue-600">
                Forgot Password?
              </Link>
            </div>
            <div>
              Don't have an account?{" "}
              <Link to="/signup" className="text-blue-600">
                Sign up
              </Link>
            </div>

            <div
              className="underline text-gray-600 cursor-pointer"
              onClick={handleViewPrivacyPolicy}
            >
              Privacy Policy and Information
            </div>
          </form>

          {viewMode && (
            <PageModal>
              <PrivacyPage setViewMode={setViewMode} />
            </PageModal>
          )}
        </div>
      </div>
    </div>
  );
}

export default LoginForm;
