import React from "react";

export default function ConcernsCard({ concern }) {
  // Define status color based on concern status
  const getStatusColor = (status) => {
    switch (status.toLowerCase()) {
      case "resolved":
        return "bg-green-100 text-green-800";
      case "pending":
        return "bg-amber-100 text-amber-800";
      case "dispatched":
        return "bg-blue-100 text-blue-800";
      case "processing":
        return "bg-purple-100 text-purple-800";
      default:
        return "bg-gray-100 text-gray-800";
    }
  };

  return (
    <div className="grid grid-cols-5 gap-2 p-3 text-sm text-gray-600 items-center hover:bg-gray-50">
      <div className="font-medium text-gray-900">{concern.identifier}</div>

      <div className="text-gray-700 truncate">{concern.concern}</div>

      <div className="text-gray-700 truncate">{concern.description}</div>

      <div className="text-gray-500 text-xs">{concern.createdAt}</div>

      <div>
        <span
          className={`px-2 py-1 rounded-full text-xs font-medium ${getStatusColor(
            concern.status
          )}`}
        >
          {concern.status}
        </span>
      </div>
    </div>
  );
}
