import React from "react";
import { useSelector } from "react-redux";
import { FiBell, FiSettings } from "react-icons/fi";
import { Link } from "react-router-dom";

function AdminHeaderMobile() {
  const currentUser = useSelector((state) => state.userObject);

  return (
    <div className="bg-white px-4 py-3 shadow-sm">
      <div className="flex items-center justify-between">
        <div className="flex items-center">
          <div className="font-bold text-blue-900 text-xl">Tali</div>
        </div>

        <div className="flex items-center gap-3">
          <Link to="/admin/notifications" className="relative">
            <FiBell className="w-6 h-6 text-gray-600" />
            {currentUser?.notifications > 0 && (
              <span className="absolute -top-1 -right-1 bg-red-500 text-white rounded-full text-xs w-4 h-4 flex items-center justify-center">
                {currentUser.notifications}
              </span>
            )}
          </Link>
          <Link to="/admin/settings">
            <FiSettings className="w-6 h-6 text-gray-600" />
          </Link>
        </div>
      </div>

      <div className="mt-2 text-sm text-gray-600">
        Welcome back, {currentUser?.displayName || "User"}
      </div>
    </div>
  );
}

export default AdminHeaderMobile;
