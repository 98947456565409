import React from "react";
import InputBox from "../Components/InputBox";
import SelectInput from "../Components/SelectInput";
import { useEffect } from "react";
import toast from "react-hot-toast";
import {
  getDocDataSnap,
  setDocData,
} from "../../../utils/firebase/Firebase.utils";
import { useParams } from "react-router-dom";
import Modal from "../Components/Modal";
import { useSelector } from "react-redux";
import { MdModeEdit, MdSave, MdCancel } from "react-icons/md";
import {
  FaUser,
  FaBuilding,
  FaHome,
  FaPhone,
  FaEnvelope,
  FaGlobe,
} from "react-icons/fa";
import { useCompany } from "../../../context/CompanyContext";
import CompanyTerms from "../../../components/CompanyTerms";

export default function ResidentInformationPage({}) {
  const { id } = useParams();
  const [formData, setFormData] = React.useState({});
  const [editMode, setEditMode] = React.useState(false);
  const [isProcessing, setIsProcessing] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(true);
  const [resident, setResident] = React.useState({});

  const currentUser = useSelector((state) => state.userObject);

  const allowableAccess = ["admin", "maker", "approver"];
  const userRoles = currentUser.roles;

  const { terms } = useCompany();

  useEffect(() => {
    const fetchResidents = async () => {
      await getDocDataSnap("residents", id, (data) => {
        setResident(data);
        setFormData(data);
        setIsLoading(false);
      });
    };
    fetchResidents();
  }, []);

  if (isLoading) {
    return (
      <div className="flex items-center justify-center p-8">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-eleghoBlue"></div>
      </div>
    );
  }

  const propertyTypes = [
    { label: "Residential", value: "Residential" },
    { label: "Commercial", value: "Commercial" },
  ];

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleEditMode = () => {
    setEditMode(!editMode);
    if (editMode) {
      setFormData(resident);
      toast.error("Changes have not been saved");
    }
  };

  const handleSave = async (e) => {
    if (window.confirm("Are you sure you want to save the changes?")) {
      e.preventDefault();
      setIsProcessing(true);
      const docObject = {
        ...formData,
        identifier: `Unit ${formData.lot} - Tower ${formData.phase}`,
      };
      await setDocData("residents", id, docObject);
      toast.success("Changes saved successfully");
      setEditMode(false);
      setIsProcessing(false);
    }
  };

  const InfoCard = ({ title, icon: Icon, children }) => (
    <div className="bg-white rounded-lg border border-gray-100 p-4">
      <div className="flex items-center gap-2 mb-3">
        <Icon className="w-4 h-4 text-gray-400" />
        <h3 className="text-sm font-medium text-gray-500">{title}</h3>
      </div>
      <div className="space-y-2">{children}</div>
    </div>
  );

  const InfoItem = ({ label, value, editValue, name, type = "text" }) => (
    <div className="flex items-start justify-between group">
      <span className="text-xs text-gray-500">{label}</span>
      {editMode ? (
        <InputBox
          value={editValue}
          onChange={handleChange}
          name={name}
          type={type}
          className="w-48 text-right bg-blue-50 border-blue-200 focus:border-eleghoBlue focus:ring-1 focus:ring-eleghoBlue transition-colors duration-200 px-3 py-1.5"
        />
      ) : (
        <span className="text-sm font-medium text-gray-900">{value}</span>
      )}
    </div>
  );

  return (
    <div className="space-y-6">
      {/* Header */}
      <div className="flex items-center justify-between">
        <div>
          <h2 className="text-lg font-medium text-gray-900">
            {resident.identifier}
          </h2>
          <p className="text-sm text-gray-500">Resident Information</p>
        </div>
        {allowableAccess.some((role) => userRoles.includes(role)) && (
          <div className="flex items-center gap-2">
            {editMode ? (
              <>
                <button
                  onClick={handleEditMode}
                  className="inline-flex items-center px-3 py-1.5 text-xs font-medium text-gray-700 bg-gray-100 rounded-md hover:bg-gray-200 transition-colors duration-200"
                >
                  <MdCancel className="w-4 h-4 mr-1.5" />
                  Cancel
                </button>
                <button
                  onClick={handleSave}
                  className="inline-flex items-center px-3 py-1.5 text-xs font-medium text-white bg-eleghoBlue rounded-md hover:bg-eleghoBlue/90 transition-colors duration-200"
                >
                  <MdSave className="w-4 h-4 mr-1.5" />
                  Save Changes
                </button>
              </>
            ) : (
              <button
                onClick={handleEditMode}
                className="inline-flex items-center px-3 py-1.5 text-xs font-medium text-eleghoBlue bg-blue-50 rounded-md hover:bg-blue-100 transition-colors duration-200"
              >
                <MdModeEdit className="w-4 h-4 mr-1.5" />
                Edit Information
              </button>
            )}
          </div>
        )}
      </div>

      {/* Information Cards */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        {/* Basic Information */}
        <InfoCard title="Basic Information" icon={FaUser}>
          <InfoItem
            label="Primary Owner"
            value={resident.owner1}
            editValue={formData.owner1}
            name="owner1"
          />
          <InfoItem
            label="Secondary Owner"
            value={resident.owner2}
            editValue={formData.owner2}
            name="owner2"
          />
          <InfoItem
            label="Nationality"
            value={resident.nationality}
            editValue={formData.nationality}
            name="nationality"
          />
          <InfoItem
            label="Address"
            value={resident.address}
            editValue={formData.address}
            name="address"
          />
        </InfoCard>

        {/* Contact Information */}
        <InfoCard title="Contact Information" icon={FaPhone}>
          <InfoItem
            label="Email"
            value={resident.email}
            editValue={formData.email}
            name="email"
          />
          <InfoItem
            label="Phone Number"
            value={resident.phoneNumber}
            editValue={formData.phoneNumber}
            name="phoneNumber"
          />
        </InfoCard>

        {/* Unit Information */}
        <InfoCard
          title={
            <>
              <CompanyTerms term="unit" capitalize={true} /> Information
            </>
          }
          icon={FaBuilding}
        >
          <InfoItem
            label={
              <>
                <CompanyTerms term="structure" capitalize={true} /> Number
              </>
            }
            value={resident.phase}
            editValue={formData.phase}
            name="phase"
          />
          <InfoItem
            label={
              <>
                <CompanyTerms term="location" capitalize={true} /> Number
              </>
            }
            value={resident.block}
            editValue={formData.block}
            name="block"
          />
          <InfoItem
            label={<CompanyTerms term="identifier" capitalize={true} />}
            value={resident.lot}
            editValue={formData.lot}
            name="lot"
          />
          <InfoItem
            label="Property Type"
            value={resident.unitType}
            editValue={formData.unitType}
            name="unitType"
          />
        </InfoCard>

        {/* Additional Details */}
        <InfoCard title="Additional Details" icon={FaHome}>
          <InfoItem
            label={
              <>
                <CompanyTerms term="unit" capitalize={true} /> Size
              </>
            }
            value={`${resident.lotSize} sqm`}
            editValue={formData.lotSize}
            name="lotSize"
          />
          <InfoItem
            label="Number of Occupants"
            value={resident.numberOfOccupants}
            editValue={formData.numberOfOccupants}
            name="numberOfOccupants"
            type="number"
          />
        </InfoCard>
      </div>

      {/* Processing Modal */}
      {isProcessing && (
        <Modal>
          <div className="flex items-center gap-2">
            <div className="animate-spin rounded-full h-5 w-5 border-b-2 border-eleghoBlue"></div>
            <span className="text-sm font-medium text-gray-900">
              Saving changes...
            </span>
          </div>
        </Modal>
      )}
    </div>
  );
}
