import React from "react";

export default function BentoContainer({ subtitle, label, children }) {
  return (
    <div className="border border-gray-200 rounded-lg mt-4 overflow-hidden w-full bg-white shadow-md">
      <div className="bg-gradient-to-r from-eleghoBlue to-eleghoBlue/80 px-4 py-3">
        <div className="text-left uppercase font-semibold text-white">
          {label}
        </div>
        {subtitle && (
          <div className="text-xs text-left font-normal text-blue-100 mt-0.5">
            {subtitle}
          </div>
        )}
      </div>
      {children}
    </div>
  );
}
