import React from "react";
import { useSelector } from "react-redux";
import SecurityAppicons from "./SecurityAppicons";
import Alerts from "./Alerts";
import AdvisoryComponent from "../User/UserDashboard/AdvisoryComponent";
import Announcements from "../User/Announcements";
import AdminQuickAccessMobile from "../AdminMobileMode/AdminQuickAccessMobile";
import AdminHeaderMobile from "../AdminMobileMode/AdminHeaderMobile";

export default function Home() {
  const currentUser = useSelector((state) => state.userObject);

  return (
    <div className="py-2 px-1">
      <AdminHeaderMobile />

      <div className="mt-4 space-y-4">
        <SecurityAppicons />
        <AdminQuickAccessMobile />
        {/* <Alerts />
        <AdvisoryComponent /> */}
        <Announcements />
      </div>
    </div>
  );
}
