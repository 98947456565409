import React, { useEffect } from "react";
import UserScreenWindow from "./UserScreenWindow";
import { getAllDocsSnap } from "../../utils/firebase/Firebase.utils";
import ContactCard from "./ContactCard";

function SOS() {
  const [emergencyContacts, setEmergencyContacts] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(true);
  const [searchTerm, setSearchTerm] = React.useState("");

  useEffect(() => {
    const fetchContacts = async () => {
      await getAllDocsSnap("contacts", (data) => {
        const filteredData = data.filter(
          (contact) => contact.category === "Emergency Hotlines"
        );
        //sort by name
        filteredData.sort((a, b) => {
          if (a.name < b.name) {
            return -1;
          }
          if (a.name > b.name) {
            return 1;
          }
          return 0;
        });
        setEmergencyContacts(filteredData);
        setIsLoading(false);
      });
    };
    fetchContacts();
  }, []);

  const filteredItems = emergencyContacts.filter((contact) => {
    return (
      contact?.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      contact?.title.toLowerCase().includes(searchTerm.toLowerCase())
    );
  });

  return (
    <UserScreenWindow label="Emergency Information">
      <div className="flex flex-col h-full">
        {/* Header */}
        <div className="bg-[#363062] rounded-lg p-3 shadow-md">
          <div className="text-center text-sm font-bold text-white">
            Emergency Hotlines
          </div>
        </div>

        {/* Emergency Call Section */}
        <div className="my-6 bg-white rounded-lg shadow-md p-4">
          <div className="font-bold text-[#363062] text-base mb-2">
            For Emergency Assistance
          </div>
          <div className="flex flex-col">
            <div className="text-sm mb-2">Security Office</div>
            <a href="tel:+639053387440" className="w-full">
              <div className="bg-[#363062] hover:bg-opacity-90 text-white p-2.5 rounded-lg text-center w-full flex items-center justify-center shadow-sm transition-all duration-200 transform hover:scale-[1.02]">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-4 w-4 mr-2"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"
                  />
                </svg>
                CALL NOW
              </div>
            </a>
          </div>
        </div>

        {/* Contact List */}
        <div className="flex-1">
          {isLoading ? (
            <div className="flex justify-center items-center h-32">
              <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-[#8FCD00]"></div>
            </div>
          ) : (
            <div className="flex flex-col h-full">
              <div className="mb-4">
                <div className="p-2 bg-white rounded-lg shadow-sm">
                  <div className="relative">
                    <input
                      type="search"
                      className="w-full p-2 pl-8 border border-gray-200 rounded-lg focus:outline-none focus:ring-1 focus:ring-[#8FCD00] focus:border-[#8FCD00]"
                      placeholder="Search contacts..."
                      onChange={(e) => setSearchTerm(e.target.value)}
                    />
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-4 w-4 text-gray-400 absolute left-2.5 top-3"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                      />
                    </svg>
                  </div>
                </div>
              </div>

              <div className="space-y-3 overflow-y-auto">
                {filteredItems.length > 0 ? (
                  filteredItems.map((contact, index) => (
                    <ContactCard key={index} contact={contact} />
                  ))
                ) : (
                  <div className="text-center text-sm text-gray-500 py-6">
                    No contacts found matching your search
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </UserScreenWindow>
  );
}

export default SOS;
