import React, { useState } from "react";
import { FaRegHeart } from "react-icons/fa";
import { FaHeart } from "react-icons/fa6";
import PhotoCarousel from "./PhotoCarousel";

export default function PropertyCard({ data }) {
  const [liked, setLiked] = useState(false);

  return (
    <div className="relative p-2 bg-white rounded-lg shadow-md mt-2">
      <div className="overflow-hidden rounded-lg">
        <PhotoCarousel images={data.images} />
      </div>
      <div className="bg-yellow-500 text-sm font-bold rounded-lg w-fit px-2 py-1 absolute top-4 right-4">
        {data.type}
      </div>
      <div className="font-bold text-eleghoBlue "> Php {data.monthlyRent}</div>
      <div className="flex items-center justify-between">
        <div className="text-eleghoBlue text-xs">{data.unitNumber}</div>
        <button onClick={() => setLiked(!liked)}>
          <div className="text-2xl">
            {liked ? <FaHeart className="text-red-500" /> : <FaRegHeart />}
          </div>
        </button>
      </div>

      <div className="underline cursor-pointer text-xs text-gray-400">
        View Details
      </div>
    </div>
  );
}


