import React, { useEffect, useState } from "react";
import {
  Day,
  Inject,
  Month,
  ScheduleComponent,
  ViewDirective,
  ViewsDirective,
  Week,
  WorkWeek,
} from "@syncfusion/ej2-react-schedule";
import { registerLicense } from "@syncfusion/ej2-base";
import { convertTimeStamptoDate } from "../../../utils/EleghoUtils";
import { getAllDocsSnap } from "../../../utils/firebase/Firebase.utils";

registerLicense(
  "ORg4AjUWIQA/Gnt2XVhhQlJHfV5AQmBIYVp/TGpJfl96cVxMZVVBJAtUQF1hTH5RdkdjX35ddHNSRWNY"
);

export default function DayViewCalendar({ date, setDateSelected, bookings }) {
  const [categoryColors, setCategoryColors] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchFacilities = async () => {
      await getAllDocsSnap("facilities", (facilities) => {
        // Generate a unique color for each facility
        const colors = facilities.map((facility, index) => {
          // Generate a unique color using HSL
          const hue = (index * 137.5) % 360; // Golden ratio to spread colors
          return {
            bookingType: facility.name,
            color: `hsl(${hue}, 70%, 50%)`, // Using HSL for better color distribution
          };
        });
        setCategoryColors(colors);
        setIsLoading(false);
      });
    };

    fetchFacilities();
  }, []);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  const createDateTime = (date, time) => {
    // Extract hours and minutes from startTime
    const [hours, minutes] = time.split(":").map(Number);

    // Create a new Date object for dateTime
    const dateTime = new Date(convertTimeStamptoDate(date));
    dateTime.setHours(hours, minutes, 0, 0); // Set hours and minutes

    return dateTime;
  };

  const data = bookings.map((item) => {
    const facilityColor =
      categoryColors.find((color) => color.bookingType === item.bookingType)
        ?.color || "#808080"; // Default gray color if facility not found

    return {
      id: item.id,
      Description: "Booked",
      isReadOnly: true,
      Subject: "Booked",
      StartTime: createDateTime(item.date, item.startTime),
      EndTime: createDateTime(item.date, item.endTime),
      IsAllDay: false,
      CategoryColor: facilityColor,
    };
  });

  const onEventRendered = (args) => {
    const categoryColor = args.data.CategoryColor;
    args.element.style.backgroundColor = categoryColor;
  };

  const onPopupOpen = (args) => {
    args.cancel = true;
  };

  const onCellClick = (args) => {
    args.cancel = true;
  };

  const onCellDoubleClick = (args) => {
    args.cancel = true;
  };

  console.log(data);

  return (
    <div>
      <div className="flex items-center gap-2 bg-white rounded-lg p-2 justify-between">
        <div className="text-gray-600 font-bold text-sm ">
          Date Selected: {date.toLocaleDateString()}
        </div>
        <button
          className="underline text-xs"
          onClick={() => setDateSelected(null)}
        >
          Reset
        </button>
      </div>

      <div>
        <div>
          <div className="mt-4 flex items-center justify-center">
            <ScheduleComponent
              eventSettings={{ dataSource: data }}
              eventRendered={onEventRendered}
              popupOpen={onPopupOpen}
              width={400}
              height={350}
              currentView="Week"
              timeScale={{ interval: 60, slotCount: 1 }}
              rowAutoHeight={true}
              readOnly={true}
              cellClick={onCellClick}
              cellDoubleClick={onCellDoubleClick}
              selectedDate={date}
            >
              <ViewsDirective>
                <ViewDirective option="Day" />
                <ViewDirective option="Week" />
                <ViewDirective option="Month" />
              </ViewsDirective>
              <Inject services={[Day, Week, Month]} />
            </ScheduleComponent>
          </div>
        </div>
      </div>
    </div>
  );
}
