import React from "react";
import { motion, AnimatePresence } from "framer-motion";

export default function SupplierInformationPage({ data, setViewMode }) {
  const feedbacks = data.feedbacks || [];

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      className=" rounded-2xl  w-full min-h-screen p-6 relative overflow-hidden bg-gray-100"
    >
      <motion.button
        whileHover={{ scale: 1.1 }}
        whileTap={{ scale: 0.95 }}
        onClick={() => setViewMode(false)}
        className="absolute top-4 right-4 text-gray-400 hover:text-eleghoBlue transition-colors"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-5 w-5"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M6 18L18 6M6 6l12 12"
          />
        </svg>
      </motion.button>

      <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
        {/* Left Column - Supplier Info */}
        <motion.div
          initial={{ opacity: 0, x: -20 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ delay: 0.2 }}
          className="space-y-6"
        >
          <motion.div
            whileHover={{ scale: 1.02 }}
            className="flex items-center gap-4 bg-gradient-to-br from-eleghoBlue/5 to-eleghoGreen/5 rounded-xl p-4"
          >
            <motion.img
              whileHover={{ scale: 1.05 }}
              src={data.logo}
              className="w-20 h-20 rounded-lg object-contain bg-white p-2 shadow-md"
              alt={`${data.name} logo`}
            />
            <div>
              <h1 className="text-xl font-bold text-eleghoBlue">{data.name}</h1>
              <p className="text-xs text-gray-500 mt-0.5">Premium Supplier</p>
            </div>
          </motion.div>

          <motion.div
            whileHover={{ scale: 1.01 }}
            className="bg-white rounded-xl p-4 shadow-lg border border-gray-100"
          >
            <h2 className="text-sm font-semibold text-eleghoBlue mb-3">
              Contact Information
            </h2>
            <div className="grid grid-cols-2 gap-4">
              <div>
                <p className="text-xs font-medium text-gray-500">Address</p>
                <p className="text-sm text-gray-900 mt-0.5">{data.address}</p>
              </div>
              <div>
                <p className="text-xs font-medium text-gray-500">
                  Contact Number
                </p>
                <p className="text-sm text-gray-900 mt-0.5">
                  {data?.phone || "Not Set"}
                </p>
              </div>
              <div>
                <p className="text-xs font-medium text-gray-500">
                  Email Address
                </p>
                <p className="text-sm text-gray-900 mt-0.5">
                  {data?.email || "Not Set"}
                </p>
              </div>
            </div>
          </motion.div>

          <motion.div
            whileHover={{ scale: 1.01 }}
            className="bg-white rounded-xl p-4 shadow-lg border border-gray-100"
          >
            <h2 className="text-sm font-semibold text-eleghoBlue mb-3">
              Services Offered
            </h2>
            <div className="grid grid-cols-2 gap-2">
              {data.services.map((service, index) => (
                <motion.div
                  key={index}
                  whileHover={{ scale: 1.02 }}
                  className="flex items-center gap-2 p-2 rounded-lg bg-gray-50 hover:bg-eleghoBlue/5 transition-colors"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4 text-eleghoGreen"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M5 13l4 4L19 7"
                    />
                  </svg>
                  <span className="text-xs text-gray-700 capitalize font-medium">
                    {service}
                  </span>
                </motion.div>
              ))}
            </div>
          </motion.div>
        </motion.div>

        {/* Right Column - Feedbacks */}
        <motion.div
          initial={{ opacity: 0, x: 20 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ delay: 0.4 }}
          className="space-y-6"
        >
          <div>
            <h2 className="text-base font-bold text-eleghoBlue">
              Customer Feedbacks
            </h2>
            <p className="text-xs text-gray-500 mt-0.5">
              What customers say about {data.name}
            </p>
          </div>

          <div className="space-y-3">
            <AnimatePresence>
              {feedbacks.length === 0 ? (
                <motion.div
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  className="bg-white rounded-xl p-6 text-center shadow-lg border border-gray-100"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-12 w-12 mx-auto text-eleghoBlue/40"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={1.5}
                      d="M8 10h.01M12 10h.01M16 10h.01M9 16H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-5l-5 5v-5z"
                    />
                  </svg>
                  <p className="mt-3 text-sm text-gray-500">No feedbacks yet</p>
                </motion.div>
              ) : (
                feedbacks.map((feedback, index) => (
                  <motion.div
                    key={index}
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ delay: index * 0.1 }}
                    whileHover={{ scale: 1.01 }}
                    className="bg-white rounded-xl p-4 shadow-lg border border-gray-100"
                  >
                    <div className="flex items-center gap-2 mb-2">
                      <div className="w-8 h-8 rounded-full bg-eleghoBlue/10 flex items-center justify-center">
                        <span className="text-eleghoBlue font-semibold text-sm">
                          {feedback.name[0]}
                        </span>
                      </div>
                      <div>
                        <p className="text-sm font-semibold text-gray-900">
                          {feedback.name}
                        </p>
                        <p className="text-xs text-gray-500">
                          Verified Customer
                        </p>
                      </div>
                    </div>
                    <p className="text-sm text-gray-700 leading-relaxed">
                      {feedback.comment}
                    </p>
                  </motion.div>
                ))
              )}
            </AnimatePresence>
          </div>
        </motion.div>
      </div>
    </motion.div>
  );
}
