import React from "react";
import { FaHouse } from "react-icons/fa6";
import { FaCalendarAlt } from "react-icons/fa";
import { useCompany } from "../../../context/CompanyContext";
import CompanyTerms from "../../../components/CompanyTerms";

export default function TenantCard({ tenant }) {
  const { terms, getCapitalizedTerm, getTerm } = useCompany();

  return (
    <div className="bg-white text-gray-600 p-4 flex items-center gap-4 rounded-xl border border-gray-100 hover:border-eleghoBlue/20 hover:shadow-sm text-left transition-all duration-200">
      <div className="col-span-1 w-24">
        <div className="flex items-center gap-2">
          <FaHouse className="text-lg text-green-600" />
          <div className="font-medium text-gray-900 text-sm tracking-wide">
            {tenant.lot}
          </div>
        </div>
      </div>

      <div className="col-span-1 w-24">
        <div className="font-medium text-gray-900 text-sm tracking-wide">
          {tenant.unitOwner}
        </div>
      </div>

      <div className="col-span-2 w-48">
        <div className="font-medium text-gray-900 text-sm tracking-wide">
          {tenant.firstName} {tenant.lastName}
        </div>
      </div>

      <div className="col-span-1 w-24">
        <div className="text-gray-600 text-sm tracking-wide">
          {tenant.occupants}
        </div>
      </div>

      <div className="col-span-2 w-48">
        <div className="text-gray-600 text-sm tracking-wide">
          {tenant.address}
        </div>
      </div>

      <div className="col-span-2 w-48">
        <div className="text-gray-600 text-sm tracking-wide">
          {tenant.leaseStart}
        </div>
      </div>

      <div className="col-span-2 w-48">
        <div className="text-gray-600 text-sm tracking-wide">
          {tenant.leaseEnd}
        </div>
      </div>

      <div className="col-span-1 w-24">
        <div className="text-eleghoBlue text-sm font-medium tracking-wide cursor-pointer hover:underline">
          {tenant.documents}
        </div>
      </div>
    </div>
  );
}
