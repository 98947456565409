import React, { useEffect, useState } from "react";
import UserScreenWindow from "../UserScreenWindow";
import { useParams } from "react-router-dom";
import { getDocData } from "../../../utils/firebase/Firebase.utils";

export default function CarStickerApplicationDetails() {
  const { id } = useParams();
  const [application, setApplication] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchApplication = async () => {
      const data = await getDocData("carStickers", id);
      setApplication(data);
      setLoading(false);
    };
    fetchApplication();
  }, []);

  if (loading) {
    return (
      <UserScreenWindow label="Car Sticker Application">
        <div className="flex justify-center items-center h-48">
          <div className="animate-spin rounded-full h-10 w-10 border-t-2 border-b-2 border-[#363062]"></div>
        </div>
      </UserScreenWindow>
    );
  }

  if (!application) {
    return (
      <UserScreenWindow label="Car Sticker Application">
        <div className="text-center py-6 text-[#363062]">
          <h2 className="font-semibold mb-2">Application Not Found</h2>
          <p className="text-sm">
            The car sticker application you're looking for doesn't exist.
          </p>
        </div>
      </UserScreenWindow>
    );
  }

  return (
    <UserScreenWindow label="Car Sticker Application Details">
      <div className="max-w-full mx-auto p-3 md:p-4 lg:max-w-3xl">
        <div className="mb-4">
          <div className="flex flex-wrap items-center gap-2 mb-3">
            <span
              className={`px-2 py-0.5 rounded-full text-xs font-medium ${
                application.status === "Approved"
                  ? "bg-[#8FCD00]/20 text-[#8FCD00]"
                  : application.status === "Rejected"
                  ? "bg-red-100 text-red-600"
                  : "bg-yellow-100 text-yellow-600"
              }`}
            >
              {application.status}
            </span>
            <span className="text-xs text-gray-500">
              Requested on {application.requestedAt}
            </span>
          </div>
        </div>

        <div className="space-y-4 md:space-y-5">
          {/* Vehicle Information Card */}
          <div className="bg-white rounded-lg p-4 shadow-sm border border-gray-100">
            <h2 className="font-medium text-sm text-[#363062] mb-3 pb-2 border-b border-gray-100">
              Vehicle Information
            </h2>

            <div className="grid grid-cols-2 gap-3">
              <div className="col-span-2 md:col-span-1">
                <span className="text-xs text-gray-500 block">
                  Plate Number
                </span>
                <span className="font-medium text-sm">
                  {application.plateNumber}
                </span>
              </div>

              <div className="col-span-2 md:col-span-1">
                <span className="text-xs text-gray-500 block">Car Model</span>
                <span className="font-medium text-sm">
                  {application.carModel}
                </span>
              </div>

              <div className="col-span-2 md:col-span-1">
                <span className="text-xs text-gray-500 block">Car Color</span>
                <span className="font-medium text-sm">
                  {application.carColor}
                </span>
              </div>

              <div className="col-span-2 md:col-span-1">
                <span className="text-xs text-gray-500 block">Car Owner</span>
                <span className="font-medium text-sm">
                  {application.carOwner}
                </span>
              </div>
            </div>
          </div>

          {/* Resident Information Card */}
          <div className="bg-white rounded-lg p-4 shadow-sm border border-gray-100">
            <h2 className="font-medium text-sm text-[#363062] mb-3 pb-2 border-b border-gray-100">
              Resident Information
            </h2>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
              <div>
                <span className="text-xs text-gray-500 block">
                  Requested By
                </span>
                <span className="font-medium text-sm break-words">
                  {application.requestedBy}
                </span>
              </div>

              <div>
                <span className="text-xs text-gray-500 block">
                  Unit Identifier
                </span>
                <span className="font-medium text-sm">
                  {application.identifier}
                </span>
              </div>
            </div>
          </div>

          {/* Documents Card */}
          <div className="bg-white rounded-lg p-4 shadow-sm border border-gray-100">
            <h2 className="font-medium text-sm text-[#363062] mb-3 pb-2 border-b border-gray-100">
              Submitted Documents
            </h2>

            <div className="space-y-4">
              <div>
                <p className="text-xs text-gray-500 mb-1">ORCR Document</p>
                <div className="aspect-video bg-gray-100 rounded-md overflow-hidden">
                  <img
                    src={application.ORCR}
                    alt="ORCR Document"
                    className="w-full h-full object-contain"
                    onClick={() => window.open(application.ORCR, "_blank")}
                    style={{ cursor: "pointer" }}
                  />
                </div>
                <p className="text-[10px] text-gray-400 mt-1 text-center">
                  Tap to view full image
                </p>
              </div>

              <div>
                <p className="text-xs text-gray-500 mb-1">Vehicle Photo</p>
                <div className="aspect-video bg-gray-100 rounded-md overflow-hidden">
                  <img
                    src={application.Photo}
                    alt="Vehicle Photo"
                    className="w-full h-full object-contain"
                    onClick={() => window.open(application.Photo, "_blank")}
                    style={{ cursor: "pointer" }}
                  />
                </div>
                <p className="text-[10px] text-gray-400 mt-1 text-center">
                  Tap to view full image
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </UserScreenWindow>
  );
}
