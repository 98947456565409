import SelectInput from "../Dashboard/Components/SelectInput";
import React, { useEffect } from "react";
import {
  getDocDataSnap,
  setDocData,
  getAllDocs,
} from "../../utils/firebase/Firebase.utils";
import { useSelector } from "react-redux";
import { setDoc } from "firebase/firestore";
import toast from "react-hot-toast";
import DetailsPageHead from "../../components/DetailsPageHead";
import HistoryModule from "../../components/HistoryModule";
import TaskAssignmentModule from "../../components/TaskAssignmentModule";
import CommentBlock from "../../components/CommentBlock";
import CloseButton from "../../components/CloseButton";
import {
  sendNotification,
  sendBulkNotifications,
} from "../../utils/notifications";
import {
  createGuestPassStatusNotification,
  createGuestPassAssignmentNotification,
  createGuestPassCommentNotification,
  createStatusSpecificNotification,
  storeNotification,
  sendTestNotification,
  debugNotificationStatus,
} from "../../services/notificationService";

export default function GuestPassDetailsPage({ data, setViewMode }) {
  const [newStatus, setNewStatus] = React.useState(data.status || "");
  const [formData, setFormData] = React.useState(data);
  const [updateHappened, setUpdateHappened] = React.useState(false);
  const [addMode, setAddMode] = React.useState(false);
  const [comments, setComments] = React.useState([]);
  const [newComment, setNewComment] = React.useState("");
  const [editMode, setEditMode] = React.useState(false);
  const [assignedToArray, setAssignedToArray] = React.useState(
    data.assignedTo || []
  );
  const [showIdModal, setShowIdModal] = React.useState(false);
  const [selectedIdentification, setSelectedIdentification] =
    React.useState("");
  const [selectedGuestName, setSelectedGuestName] = React.useState("");

  const currentUser = useSelector((state) => state.userObject);

  const [showDevTools, setShowDevTools] = React.useState(false);

  useEffect(() => {
    const fetchData = async () => {
      await getDocDataSnap("guests", data.id, (data) => {
        setFormData(data);
        setComments(data.comments || []);
      });
    };
    fetchData();
  }, []);

  const status = [
    "pending",
    "approved",
    "rejected",
    "admitted",
    "completed",
    "returned",
  ];
  const statusOptions = status.map((item) => ({ value: item, label: item }));

  const handleSelect = (selected) => {
    setUpdateHappened(true);

    setFormData({ ...formData, status: selected.value });
  };

  const handleUpdateStatus = async () => {
    const updatedStatus = formData.status;
    setNewStatus(updatedStatus);
    const docObject = {
      ...formData,
      updatedBy: currentUser.uid,
      updatedName: currentUser.displayName,
      updatedEmail: currentUser.email,
      updateType: "Status is updated to " + updatedStatus,
    };

    await setDocData("guests", data.id, docObject);
    toast.success("Status Updated Successfully");
    setUpdateHappened(false);

    // Send notification to the requestor with resident-focused message
    if (data.requestedBy) {
      const notification = createStatusSpecificNotification(
        data.id,
        updatedStatus,
        data.linkedIdentifiers[0],
        "resident"
      );

      // Store notification in Firestore even if FCM fails
      await storeNotification(data.requestedBy, notification);
      console.log("notification 1")

      // Try sending FCM notification
      await sendNotification(data.requestedBy, notification);
    }

    // If assigned to someone, send them staff-focused notifications
    if (formData.assignedTo && formData.assignedTo.length > 0) {
      const assignedEmails = formData.assignedTo.map((user) => user.email);
      const notification = createStatusSpecificNotification(
        data.id,
        updatedStatus,
        data.linkedIdentifiers[0],
        "staff"
      );

      // Store notification in Firestore for each user
      for (const email of assignedEmails) {
        if (email !== data.requestedBy) {
          // Avoid duplicate notification
          await storeNotification(email, notification);
        }
      }

      // Try sending FCM notifications
      await sendBulkNotifications(assignedEmails, notification);
    }

    // Send notification to security staff for all status changes with security focus
    try {
      // Get all security staff users
      const securityStaff = await getAllDocs("users");
      const securityEmails = securityStaff
        .filter(
          (user) => user.role === "security" && user.email !== currentUser.email
        )
        .map((user) => user.email);

      if (securityEmails.length > 0) {
        const securityNotification = createStatusSpecificNotification(
          data.id,
          updatedStatus,
          data.linkedIdentifiers[0],
          "security"
        );

        // Store notification for each security staff
        for (const email of securityEmails) {
          if (
            email !== data.requestedBy &&
            !formData.assignedTo?.some((user) => user.email === email)
          ) {
            // Avoid duplicate notification for requestor or assigned staff
            await storeNotification(email, securityNotification);
          }
        }

        // Send FCM notifications
        await sendBulkNotifications(securityEmails, securityNotification);
      }
    } catch (error) {
      console.error("Error sending notifications to security staff:", error);
    }
  };

  const handleAddMode = () => {
    setAddMode(!addMode);
  };

  const handleChange = (e) => {
    setNewComment(e.target.value);
  };

  const handleSubmit = async () => {
    const docObject = {
      comments: [
        ...comments,
        {
          message: newComment,
          createdAt: new Date().toLocaleString(),
          createdBy: currentUser.displayName,
          email: currentUser.email,
        },
      ],
    };
    await setDocData("guests", data.id, docObject);
    toast.success("Comment Added Successfully");
    setNewComment("");

    // Send notification to the requestor if the current user is not the requestor
    if (data.requestedBy && data.requestedBy !== currentUser.email) {
      const notification = createGuestPassCommentNotification(
        data.id,
        data.linkedIdentifiers[0],
        currentUser.displayName,
        newComment
      );

      // Store notification in Firestore
      await storeNotification(data.requestedBy, notification);

      // Try sending FCM notification
      await sendNotification(data.requestedBy, notification);
    }

    // If assigned to others, notify them about the comment
    if (formData.assignedTo && formData.assignedTo.length > 0) {
      const assignedEmails = formData.assignedTo
        .map((user) => user.email)
        .filter(
          (email) => email !== currentUser.email && email !== data.requestedBy
        ); // Don't notify the current user or requestor twice

      if (assignedEmails.length > 0) {
        const notification = createGuestPassCommentNotification(
          data.id,
          data.linkedIdentifiers[0],
          currentUser.displayName,
          newComment
        );

        // Store notification in Firestore for each user
        for (const email of assignedEmails) {
          await storeNotification(email, notification);
        }

        // Try sending FCM notifications
        await sendBulkNotifications(assignedEmails, notification);
      }
    }
  };

  const handleCancelEdit = () => {
    setEditMode(false);
    setFormData(data);
    setAssignedToArray(data.assignedTo || []);
    toast.error("Edit Cancelled");
  };

  const handleAssignment = async () => {
    if (editMode) {
      const docObject = {
        ...formData,
        updatedAt: new Date().toLocaleString(),
        updatedBy: currentUser.uid,
        updatedName: currentUser.displayName,
        updatedEmail: currentUser.email,
        updateType: "Guess Pass is assigned",
        assignedTo: assignedToArray,
        history: [
          ...(formData?.history || ""),
          {
            action:
              assignedToArray.length === 0
                ? "Unassigned"
                : "Gate Pass is Assigned to",
            at: new Date().toLocaleString(),
            by: currentUser.displayName,
            to: assignedToArray.map((item) => item.label).join(", "),
          },
        ],
      };

      await setDocData("guests", data.id, docObject);
      toast.success("Guest Pass is Updated");
      setEditMode(false);

      // Send notifications to newly assigned users
      if (assignedToArray.length > 0) {
        const previouslyAssigned = new Set(
          (formData.assignedTo || []).map((user) => user.email)
        );
        const newlyAssigned = assignedToArray
          .filter((user) => !previouslyAssigned.has(user.email))
          .map((user) => user.email);

        if (newlyAssigned.length > 0) {
          const notification = createGuestPassAssignmentNotification(
            data.id,
            data.linkedIdentifiers[0],
            assignedToArray
          );

          // Store notification in Firestore for each user
          for (const email of newlyAssigned) {
            await storeNotification(email, notification);
          }

          // Try sending FCM notifications
          await sendBulkNotifications(newlyAssigned, notification);
        }
      }

      // Notify the requestor about assignment changes
      if (data.requestedBy && data.requestedBy !== currentUser.email) {
        const notification = createGuestPassAssignmentNotification(
          data.id,
          data.linkedIdentifiers[0],
          assignedToArray
        );

        // Store notification in Firestore
        await storeNotification(data.requestedBy, notification);

        // Try sending FCM notification
        await sendNotification(data.requestedBy, notification);
      }
    }
  };

  // Test notification functionality
  const handleTestNotification = async () => {
    try {
      // First debug the notification status
      const statusInfo = await debugNotificationStatus();
      console.log("Notification status:", statusInfo);

      if (statusInfo.permissionStatus !== "granted") {
        toast.error(
          "Notification permission not granted. Check console for details."
        );
        return;
      }

      // Try sending a test notification
      const success = await sendTestNotification(currentUser);

      if (success) {
        toast.success(
          "Test notification sent successfully. Check your notifications!"
        );
      } else {
        toast.error(
          "Failed to send test notification. Check console for details."
        );
      }
    } catch (error) {
      console.error("Error testing notification:", error);
      toast.error("Error testing notification: " + error.message);
    }
  };

  // Toggle developer tools visibility with Ctrl+Shift+D
  React.useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.ctrlKey && e.shiftKey && e.key === "D") {
        e.preventDefault();
        setShowDevTools((prev) => !prev);
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    return () => window.removeEventListener("keydown", handleKeyDown);
  }, []);

  // Function to handle opening the ID modal
  const handleViewId = (identification, guestName) => {
    setSelectedIdentification(identification);
    setSelectedGuestName(guestName || "Guest");
    setShowIdModal(true);
  };

  // Function to close the ID modal
  const handleCloseIdModal = () => {
    setShowIdModal(false);
    setSelectedIdentification("");
    setSelectedGuestName("");
  };

  const getStatusColor = (status) => {
    switch (status) {
      case "approved":
        return "bg-green-100 text-green-800 border-green-200";
      case "rejected":
        return "bg-red-100 text-red-800 border-red-200";
      case "pending":
        return "bg-amber-100 text-amber-800 border-amber-200";
      case "admitted":
        return "bg-blue-100 text-blue-800 border-blue-200";
      case "completed":
        return "bg-purple-100 text-purple-800 border-purple-200";
      case "returned":
        return "bg-gray-100 text-gray-800 border-gray-200";
      default:
        return "bg-gray-100 text-gray-800 border-gray-200";
    }
  };

  return (
    <div className=" min-h-screen p-6 relative">
      <div className="max-w-7xl mx-auto">
        {/* Header Section */}
        <div className="bg-white rounded-lg shadow-sm p-6 mb-6">
          <div className="flex items-center justify-between mb-6">
            <div className="title-header">Guest Pass Details Page</div>
            <div className="flex items-center gap-4">
              <button
                className={`px-6 py-3 rounded-lg font-medium transition-all ${
                  editMode
                    ? "bg-red-500 text-white hover:bg-red-600 shadow-sm"
                    : "bg-[#363062] text-white hover:bg-opacity-90 shadow-sm"
                }`}
                onClick={() => {
                  if (editMode) {
                    handleCancelEdit();
                  } else {
                    setEditMode(true);
                  }
                }}
              >
                {editMode ? "Cancel Edit" : "Edit Guest Pass"}
              </button>

              <button
                className="p-3 rounded-lg hover:bg-gray-100 transition-colors"
                onClick={() => setViewMode(false)}
              >
                <svg
                  className="w-6 h-6 text-gray-600"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>
          </div>

          {/* Status Badge */}
          <div className="flex items-center gap-4 mb-6">
            <div
              className={`px-4 py-2 rounded-full border ${getStatusColor(
                newStatus
              )} font-medium capitalize`}
            >
              {newStatus}
            </div>
            {editMode && (
              <div className="flex items-center gap-4">
                <SelectInput
                  name={"status"}
                  placeholder={"Select Status"}
                  options={statusOptions}
                  onChange={(selected) => handleSelect(selected)}
                  value={statusOptions.find(
                    (item) => item.value === formData.status
                  )}
                  className="w-48"
                />
                <button
                  className="px-5 py-2 bg-[#363062] text-white rounded-lg hover:bg-opacity-90 disabled:opacity-50 disabled:cursor-not-allowed font-medium shadow-sm"
                  disabled={!updateHappened}
                  onClick={handleUpdateStatus}
                >
                  Update Status
                </button>
              </div>
            )}
          </div>

          {/* Main Content Grid */}
          <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
            {/* Left Column - Guest Details */}
            <div className="lg:col-span-2 space-y-6">
              {/* Guest Information Card */}
              <div className="bg-white rounded-lg shadow-sm border border-gray-100 overflow-hidden">
                <div className="border-b border-gray-100 p-4">
                  <h3 className="font-semibold text-lg text-[#363062]">
                    Guest Information
                  </h3>
                </div>
                <div className="p-6">
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
                    <div>
                      <div className="text-sm text-gray-500 mb-1">
                        Unit Number
                      </div>
                      <div className="font-medium">
                        {data.linkedIdentifiers && data.linkedIdentifiers[0]
                          ? data.linkedIdentifiers[0]
                          : "N/A"}
                      </div>
                    </div>
                    <div>
                      <div className="text-sm text-gray-500 mb-1">
                        Number of Guests
                      </div>
                      <div className="font-medium">
                        {Array.isArray(data.guests) ? data.guests.length : 0}
                      </div>
                    </div>
                    <div>
                      <div className="text-sm text-gray-500 mb-1">
                        Date of Arrival
                      </div>
                      <div className="font-medium">{data.date}</div>
                    </div>
                    <div>
                      <div className="text-sm text-gray-500 mb-1">
                        Requestor Email
                      </div>
                      <div className="font-medium">{data.requestedBy}</div>
                    </div>
                  </div>

                  {/* Guest List */}
                  <div className="space-y-4">
                    <h4 className="font-medium text-[#363062]">
                      Guest Details
                    </h4>
                    {Array.isArray(data.guests) && data.guests.length > 0 ? (
                      data.guests.map((guest, index) => (
                        <div
                          key={index}
                          className="bg-gray-50 rounded-lg p-4 border border-gray-100"
                        >
                          <div className="flex flex-col space-y-3">
                            <div className="grid grid-cols-1 sm:grid-cols-3 gap-4">
                              <div>
                                <div className="text-sm text-gray-500 mb-1">
                                  Name
                                </div>
                                <div className="font-medium">
                                  {guest.name || "N/A"}
                                </div>
                              </div>
                              <div>
                                <div className="text-sm text-gray-500 mb-1">
                                  Email
                                </div>
                                <div className="font-medium">
                                  {guest.email || "N/A"}
                                </div>
                              </div>
                              {guest.plateNumber && (
                                <div>
                                  <div className="text-sm text-gray-500 mb-1">
                                    Vehicle
                                  </div>
                                  <div className="font-medium">
                                    {guest.plateNumber}
                                  </div>
                                </div>
                              )}
                            </div>
                            <div className="pt-3 border-t border-gray-200">
                              {guest.identification ? (
                                <button
                                  className="w-full px-4 py-2.5 bg-[#363062] text-white rounded-lg hover:bg-opacity-90 font-medium flex items-center justify-center gap-2"
                                  onClick={() =>
                                    handleViewId(
                                      guest.identification,
                                      guest.name
                                    )
                                  }
                                >
                                  <svg
                                    className="w-5 h-5"
                                    fill="none"
                                    stroke="currentColor"
                                    viewBox="0 0 24 24"
                                  >
                                    <path
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      strokeWidth={2}
                                      d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                                    />
                                    <path
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      strokeWidth={2}
                                      d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
                                    />
                                  </svg>
                                  View Identification
                                </button>
                              ) : (
                                <div className="w-full px-4 py-2.5 bg-gray-100 text-gray-600 rounded-lg text-center font-medium">
                                  No ID provided
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      ))
                    ) : (
                      <div className="text-gray-500 italic p-4 bg-gray-50 rounded-lg text-center">
                        No guest information available
                      </div>
                    )}
                  </div>
                </div>
              </div>

              {/* Comments Section */}
              <div className="bg-white rounded-lg shadow-sm border border-gray-100 overflow-hidden">
                <div className="border-b border-gray-100 p-4">
                  <h3 className="font-semibold text-lg text-[#363062]">
                    Comments
                  </h3>
                </div>
                <div className="p-6">
                  <div className="mb-6">
                    <textarea
                      name="newComment"
                      className="w-full rounded-lg p-4 border border-gray-200 focus:outline-none focus:ring-2 focus:ring-[#363062] focus:border-transparent resize-none"
                      rows="3"
                      onChange={handleChange}
                      placeholder="Write your comment here..."
                      value={newComment}
                    ></textarea>
                    <div className="flex justify-end mt-3">
                      <button
                        className="px-6 py-2.5 bg-[#8FCD00] text-white rounded-lg hover:bg-opacity-90 disabled:opacity-50 disabled:cursor-not-allowed font-medium shadow-sm"
                        onClick={handleSubmit}
                        disabled={!newComment.trim()}
                      >
                        Submit Comment
                      </button>
                    </div>
                  </div>

                  <div className="space-y-4">
                    {comments.map((comment, index) => (
                      <CommentBlock key={index} comment={comment} />
                    ))}
                  </div>
                </div>
              </div>
            </div>

            {/* Right Column - Assignment & History */}
            <div className="space-y-6">
              {/* Assignment Card */}
              <div className="bg-white rounded-lg shadow-sm border border-gray-100 ">
                <div className="border-b border-gray-100 p-4">
                  <h3 className="font-semibold text-lg text-[#363062]">
                    Task Assignment
                  </h3>
                </div>
                <div className="p-6">
                  <TaskAssignmentModule
                    assignedToArray={assignedToArray}
                    setAssignedToArray={setAssignedToArray}
                    editMode={editMode}
                  />
                  {editMode && (
                    <div className="mt-6 flex items-center gap-4 justify-end">
                      <button
                        className="px-6 py-2.5 bg-[#363062] text-white rounded-lg hover:bg-opacity-90 font-medium shadow-sm"
                        onClick={handleAssignment}
                      >
                        Save Assignment
                      </button>
                      <button
                        className="px-6 py-2.5 border border-gray-300 rounded-lg hover:bg-gray-50 font-medium"
                        onClick={handleCancelEdit}
                      >
                        Cancel
                      </button>
                    </div>
                  )}
                </div>
              </div>

              {/* History Card */}
              <div className="bg-white rounded-lg shadow-sm border border-gray-100 overflow-hidden">
                <div className="border-b border-gray-100 p-4">
                  <h3 className="font-semibold text-lg text-[#363062]">
                    History
                  </h3>
                </div>
                <div className="p-6">
                  <HistoryModule data={formData.history || []} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* ID Modal */}
      {showIdModal && (
        <div className="fixed inset-0 bg-black bg-opacity-60 flex items-center justify-center z-50 p-4">
          <div className="bg-white rounded-lg max-w-3xl w-full shadow-xl">
            <div className="p-4 border-b border-gray-200 flex items-center justify-between">
              <h3 className="font-bold text-xl text-[#363062]">
                {selectedGuestName}'s Identification
              </h3>
              <button
                className="p-2 hover:bg-gray-100 rounded-full transition-colors"
                onClick={handleCloseIdModal}
              >
                <svg
                  className="w-6 h-6 text-gray-600"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>
            <div className="p-6 bg-gray-50">
              <div className="bg-white p-4 rounded-lg shadow-sm">
                <img
                  src={selectedIdentification}
                  alt="Guest Identification"
                  className="max-h-96 object-contain mx-auto"
                  onError={(e) => {
                    e.target.onerror = null;
                    e.target.src =
                      "https://via.placeholder.com/400x300?text=Image+Not+Available";
                  }}
                />
              </div>
            </div>
            <div className="p-4 flex justify-between border-t border-gray-200">
              <button
                className="px-6 py-2.5 bg-gray-100 text-gray-800 rounded-lg hover:bg-gray-200 transition-colors font-medium"
                onClick={handleCloseIdModal}
              >
                Close
              </button>
              <a
                href={selectedIdentification}
                download="guest-identification"
                className="px-6 py-2.5 bg-[#8FCD00] text-white rounded-lg hover:bg-opacity-90 transition-colors font-medium flex items-center gap-2"
                target="_blank"
                rel="noopener noreferrer"
              >
                <svg
                  className="w-5 h-5"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"
                  />
                </svg>
                Download
              </a>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
