import React from "react";
import {
  FiAlertCircle,
  FiCalendar,
  FiHome,
  FiMessageSquare,
} from "react-icons/fi";

function ConcernsRequestCard({ concern }) {
  const getStatusColor = (status) => {
    switch (status.toLowerCase()) {
      case "pending":
        return "bg-yellow-100 text-yellow-800";
      case "in progress":
        return "bg-blue-100 text-blue-800";
      case "resolved":
        return "bg-green-100 text-green-800";
      case "rejected":
        return "bg-red-100 text-red-800";
      default:
        return "bg-gray-100 text-gray-800";
    }
  };

  return (
    <div className="bg-white rounded-xl shadow-sm border border-gray-100 p-4 hover:shadow-md transition-all duration-200">
      <div className="flex items-start justify-between">
        <div className="space-y-3">
          <div className="flex items-center gap-2">
            <div className="p-2 bg-eleghoBlue/10 rounded-lg">
              <FiAlertCircle className="w-4 h-4 text-eleghoBlue" />
            </div>
            <div>
              <div className="text-sm font-medium text-gray-900">
                {concern.concern}
              </div>
              <div className="text-xs text-gray-500">Concern Request</div>
            </div>
          </div>

          <div className="grid grid-cols-2 gap-4">
            <div className="flex items-center gap-2">
              <FiHome className="w-4 h-4 text-gray-400" />
              <div>
                <div className="text-xs text-gray-500">Unit</div>
                <div className="text-sm font-medium text-gray-900">
                  {concern.identifier}
                </div>
              </div>
            </div>

            <div className="flex items-center gap-2">
              <FiMessageSquare className="w-4 h-4 text-gray-400" />
              <div>
                <div className="text-xs text-gray-500">Description</div>
                <div className="text-sm font-medium text-gray-900 line-clamp-2">
                  {concern.description}
                </div>
              </div>
            </div>
          </div>

          <div className="flex items-center gap-2">
            <FiCalendar className="w-4 h-4 text-gray-400" />
            <div>
              <div className="text-xs text-gray-500">Filed On</div>
              <div className="text-sm font-medium text-gray-900">
                {concern.createdAt}
              </div>
            </div>
          </div>
        </div>

        <div
          className={`px-3 py-1 rounded-full text-xs font-medium ${getStatusColor(
            concern.status
          )}`}
        >
          {concern.status}
        </div>
      </div>
    </div>
  );
}

export default ConcernsRequestCard;
