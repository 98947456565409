import React, { useState, useEffect } from "react";
import UserScreenWindow from "../UserScreenWindow";
import { useParams, useNavigate } from "react-router-dom";
import { format } from "date-fns";
import {
  CalendarMonth,
  AccessTime,
  Business,
  Person,
  Email,
  CheckCircle,
  Cancel,
  Warning,
} from "@mui/icons-material";
import {
  getDocData,
  setDocData,
  updateDoc,
} from "../../../utils/firebase/Firebase.utils";
import toast from "react-hot-toast";

export default function BookingRequestDetails() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [booking, setBooking] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const [showCancelModal, setShowCancelModal] = useState(false);

  useEffect(() => {
    const fetchBooking = async () => {
      const booking = await getDocData("bookings", id);
      setBooking(booking);
    };
    fetchBooking();
  }, [id]);

  const formatDate = (timestamp) => {
    return format(new Date(timestamp.seconds * 1000), "MMMM d, yyyy");
  };

  const formatTime = (time) => {
    try {
      // Handle 24-hour format (HH:mm)
      if (time.includes(":")) {
        const [hours, minutes] = time.split(":");
        const date = new Date();
        date.setHours(parseInt(hours, 10));
        date.setMinutes(parseInt(minutes, 10));
        return format(date, "h:mm a");
      }
      // Handle 12-hour format (HH:mm AM/PM)
      return format(new Date(`2000-01-01T${time}`), "h:mm a");
    } catch (error) {
      console.error("Error formatting time:", error);
      return time; // Return original time if formatting fails
    }
  };

  const handleCancelRequest = async () => {
    try {
      setIsProcessing(true);
      await setDocData("bookings", id, {
        status: "Cancelled",
        cancelledAt: new Date(),
      });
      toast.success("Booking request cancelled successfully");
      navigate("/facility-usage");
    } catch (error) {
      toast.error("Failed to cancel booking request");
      console.error("Error cancelling booking:", error);
    } finally {
      setIsProcessing(false);
      setShowCancelModal(false);
    }
  };

  if (!booking) {
    return (
      <UserScreenWindow label="My Booking Request">
        <div className="flex items-center justify-center h-64">
          <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-eleghoBlue"></div>
        </div>
      </UserScreenWindow>
    );
  }

  return (
    <UserScreenWindow label="My Booking Request">
      <div className="p-4 space-y-4">
        {/* Status Badge */}
        <div className="flex justify-center">
          <span
            className={`px-3 py-1.5 rounded-full text-xs font-medium flex items-center gap-1.5 ${
              booking.status === "Pending payment"
                ? "bg-yellow-50 text-yellow-700 border border-yellow-200"
                : booking.status === "Cancelled"
                ? "bg-red-50 text-red-700 border border-red-200"
                : "bg-eleghoGreen/10 text-eleghoGreen border border-eleghoGreen/20"
            }`}
          >
            {booking.status === "Pending payment" ? (
              <AccessTime className="w-3.5 h-3.5" />
            ) : booking.status === "Cancelled" ? (
              <Cancel className="w-3.5 h-3.5" />
            ) : (
              <CheckCircle className="w-3.5 h-3.5" />
            )}
            {booking.status}
          </span>
        </div>

        {/* Facility Details */}
        <div className="bg-white rounded-lg border border-gray-100 p-3">
          <h2 className="text-sm font-medium text-gray-700 flex items-center gap-1.5 mb-3">
            <Business className="w-4 h-4 text-eleghoBlue" />
            Facility Details
          </h2>
          <div className="space-y-2 text-sm">
            <p className="text-gray-600 flex items-center gap-1.5">
              <Business className="w-3.5 h-3.5 text-eleghoBlue" />
              <span className="font-medium">Facility:</span>{" "}
              {booking.bookingType}
            </p>
            <p className="text-gray-600 flex items-center gap-1.5">
              <CalendarMonth className="w-3.5 h-3.5 text-eleghoBlue" />
              <span className="font-medium">Date:</span>{" "}
              {formatDate(booking.date)}
            </p>
            <p className="text-gray-600 flex items-center gap-1.5">
              <AccessTime className="w-3.5 h-3.5 text-eleghoBlue" />
              <span className="font-medium">Time:</span>{" "}
              {formatTime(booking.startTime)} - {formatTime(booking.endTime)}
            </p>
          </div>
        </div>

        {/* User Details */}
        <div className="bg-white rounded-lg border border-gray-100 p-3">
          <h2 className="text-sm font-medium text-gray-700 flex items-center gap-1.5 mb-3">
            <Person className="w-4 h-4 text-eleghoBlue" />
            My Information
          </h2>
          <div className="space-y-2 text-sm">
            <p className="text-gray-600 flex items-center gap-1.5">
              <Person className="w-3.5 h-3.5 text-eleghoBlue" />
              <span className="font-medium">Name:</span> {booking.createdName}
            </p>
            <p className="text-gray-600 flex items-center gap-1.5">
              <Email className="w-3.5 h-3.5 text-eleghoBlue" />
              <span className="font-medium">Email:</span> {booking.createdEmail}
            </p>
            <p className="text-gray-600 flex items-center gap-1.5">
              <CalendarMonth className="w-3.5 h-3.5 text-eleghoBlue" />
              <span className="font-medium">Requested On:</span>{" "}
              {formatDate(booking.createdAt)}
            </p>
          </div>
        </div>

        {/* Action Buttons */}
        {booking.status === "Pending payment" && (
          <div className="flex flex-col gap-2 mt-4">
            <button
              onClick={() => setShowCancelModal(true)}
              className="w-full py-2 bg-red-50 text-red-600 rounded-lg hover:bg-red-100 transition-colors flex items-center justify-center gap-1.5 text-sm border border-red-200"
            >
              <Cancel className="w-4 h-4" />
              Cancel Request
            </button>
          </div>
        )}

        {/* Cancel Confirmation Modal */}
        {showCancelModal && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
            <div className="bg-white rounded-lg p-4 w-full max-w-sm">
              <div className="flex items-center gap-2 mb-4">
                <Warning className="w-5 h-5 text-red-600" />
                <h3 className="text-sm font-medium text-gray-900">
                  Confirm Cancellation
                </h3>
              </div>
              <p className="text-sm text-gray-600 mb-4">
                Are you sure you want to cancel this booking? This action cannot
                be undone.
              </p>
              <div className="flex gap-2">
                <button
                  onClick={() => setShowCancelModal(false)}
                  className="flex-1 py-2 text-sm text-gray-600 hover:bg-gray-50 rounded-lg transition-colors"
                >
                  No, Keep Booking
                </button>
                <button
                  onClick={handleCancelRequest}
                  disabled={isProcessing}
                  className="flex-1 py-2 text-sm bg-red-50 text-red-600 rounded-lg hover:bg-red-100 transition-colors disabled:opacity-50 disabled:cursor-not-allowed flex items-center justify-center gap-1.5"
                >
                  <Cancel className="w-4 h-4" />
                  {isProcessing ? "Cancelling..." : "Yes, Cancel"}
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </UserScreenWindow>
  );
}
