import React from "react";
import { useCompany } from "../context/CompanyContext";

// Handles dynamic terminology based on company type
const CompanyTerms = ({ term, capitalize = false, children }) => {
  const { getTerm, getCapitalizedTerm } = useCompany();

  if (!term) {
    return children || null;
  }

  if (capitalize) {
    return getCapitalizedTerm(term);
  }

  return getTerm(term);
};

export default CompanyTerms;
