import React from "react";
import SecurityNavbar from "./SecurityNavbar";
import Header from "./Header";
import { Outlet } from "react-router-dom";
import AdminHeaderMobile from "../AdminMobileMode/AdminHeaderMobile";

export default function SecurityDashboard() {
  return (
    <div className="max-w-[450px] mx-auto bg-gray-100 min-h-screen flex flex-col">
      <div className="min-h-screen">
        <Outlet />
      </div>
      <div className="sticky bottom-0">
        <SecurityNavbar />
      </div>
    </div>
  );
}
