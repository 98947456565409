import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { v4 } from "uuid";
import toast from "react-hot-toast";
import {
  getDocDataSnap,
  queryDocByFieldSnap,
  setDocData,
} from "../../utils/firebase/Firebase.utils";

// Form Components
const FormInput = ({ label, error, ...props }) => (
  <div className="space-y-1">
    <label className="block text-sm font-medium text-eleghoBlue">{label}</label>
    <input
      {...props}
      className={`w-full px-3 py-2 border rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-eleghoBlue/20 focus:border-eleghoBlue transition-all duration-200 ${
        error ? "border-red-500" : "border-gray-200 hover:border-eleghoBlue/50"
      }`}
    />
    {error && <p className="text-xs text-red-500">{error}</p>}
  </div>
);

const FormSelect = ({ label, options, error, ...props }) => (
  <div className="space-y-1">
    <label className="block text-sm font-medium text-eleghoBlue">{label}</label>
    <select
      {...props}
      className={`w-full px-3 py-2 border rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-eleghoBlue/20 focus:border-eleghoBlue transition-all duration-200 ${
        error ? "border-red-500" : "border-gray-200 hover:border-eleghoBlue/50"
      }`}
    >
      <option value="">Select {label}</option>
      {options.map((option) => (
        <option key={option.value} value={option.value}>
          {option.label}
        </option>
      ))}
    </select>
    {error && <p className="text-xs text-red-500">{error}</p>}
  </div>
);

export default function AddOccupantFormUser({ setAddMode }) {
  const [formData, setFormData] = React.useState({
    firstName: "",
    middleName: "",
    lastName: "",
    birthday: "",
    email: "",
    relationship: "",
  });
  const [errors, setErrors] = React.useState({});
  const [isProcessing, setIsProcessing] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(true);
  const currentUser = useSelector((state) => state.userObject);
  const [selectedIdentifier, setSelectedIdentifier] = React.useState("");
  const [selectedResident, setSelectedResident] = React.useState({});
  const [currentOccupants, setCurrentOccupants] = React.useState([]);
  const [assignedId] = React.useState(v4());

  useEffect(() => {
    const fetchResidentData = async () => {
      if (!selectedIdentifier) return;

      setIsLoading(true);
      try {
        await queryDocByFieldSnap(
          "residents",
          "identifier",
          "==",
          selectedIdentifier,
          (data) => {
            setSelectedResident(data[0]?.id);
            setCurrentOccupants(data[0]?.occupantsName || []);
          }
        );
      } catch (error) {
        toast.error("Failed to fetch resident data");
      } finally {
        setIsLoading(false);
      }
    };
    fetchResidentData();
  }, [selectedIdentifier]);

  const validateForm = () => {
    const newErrors = {};
    if (!selectedIdentifier)
      newErrors.identifier = "Please select a unit number";
    if (!formData.firstName) newErrors.firstName = "First name is required";
    if (!formData.lastName) newErrors.lastName = "Last name is required";
    if (!formData.birthday) newErrors.birthday = "Birthday is required";
    if (!formData.relationship)
      newErrors.relationship = "Relationship is required";
    if (formData.email && !/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = "Please enter a valid email";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
    if (errors[name]) {
      setErrors((prev) => ({ ...prev, [name]: "" }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) return;

    setIsProcessing(true);
    try {
      const docObject = {
        ...formData,
        id: assignedId,
        createdAt: new Date().toISOString(),
      };

      await setDocData("residents", selectedResident, {
        occupantsName: [...currentOccupants, docObject],
      });

      toast.success("Occupant added successfully");
      setAddMode(false);
    } catch (error) {
      toast.error("Failed to add occupant");
    } finally {
      setIsProcessing(false);
    }
  };

  const identifierOptions = currentUser.linkedIdentifiers.map((id) => ({
    value: id,
    label: id,
  }));

  return (
    <div className="fixed inset-0 bg-eleghoBlue/50 backdrop-blur-sm overflow-y-auto h-full w-full z-50">
      <div className="relative top-20 mx-auto p-6 border w-full max-w-md shadow-lg rounded-xl bg-white">
        <div className="flex items-center justify-between mb-8">
          <div>
            <h2 className="text-xl font-semibold text-eleghoBlue">
              Add New Occupant
            </h2>
            <p className="text-sm text-gray-500 mt-1">
              Fill in the occupant details below
            </p>
          </div>
          <button
            onClick={() => setAddMode(false)}
            className="text-gray-400 hover:text-eleghoBlue transition-colors"
          >
            <svg
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
        </div>

        <form onSubmit={handleSubmit} className="space-y-6">
          <FormSelect
            label="Unit Number"
            options={identifierOptions}
            value={selectedIdentifier}
            onChange={(e) => setSelectedIdentifier(e.target.value)}
            error={errors.identifier}
          />

          <div className="grid grid-cols-2 gap-4">
            <FormInput
              label="First Name"
              name="firstName"
              value={formData.firstName}
              onChange={handleChange}
              error={errors.firstName}
            />
            <FormInput
              label="Last Name"
              name="lastName"
              value={formData.lastName}
              onChange={handleChange}
              error={errors.lastName}
            />
          </div>

          <FormInput
            label="Middle Name"
            name="middleName"
            value={formData.middleName}
            onChange={handleChange}
          />

          <FormInput
            label="Birthday"
            type="date"
            name="birthday"
            value={formData.birthday}
            onChange={handleChange}
            error={errors.birthday}
          />

          <FormInput
            label="Email Address"
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            error={errors.email}
          />

          <FormInput
            label="Relationship"
            name="relationship"
            placeholder="e.g. Wife, Daughter, Son"
            value={formData.relationship}
            onChange={handleChange}
            error={errors.relationship}
          />

          <button
            type="submit"
            disabled={isProcessing || isLoading}
            className={`w-full py-2.5 px-4 rounded-lg text-sm font-medium transition-all duration-200
              ${
                isProcessing || isLoading
                  ? "bg-gray-100 text-gray-400 cursor-not-allowed"
                  : "bg-eleghoGreen text-eleghoBlue hover:bg-eleghoGreen/90 focus:outline-none focus:ring-2 focus:ring-eleghoGreen focus:ring-offset-2"
              }`}
          >
            {isProcessing ? "Adding..." : "Add Occupant"}
          </button>
        </form>
      </div>
    </div>
  );
}
