// Helper functions for work permit notifications

/**
 * Creates a notification object for work permit status updates
 * @param {string} workPermitId - The ID of the work permit
 * @param {string} newStatus - The new status
 * @param {string} workPermitType - The type of work permit
 * @param {string} updatedBy - Name of the person who updated the status
 * @returns {Object} Notification object
 */
export const createWorkPermitStatusNotification = (
  workPermitId,
  newStatus,
  workPermitType,
  updatedBy
) => {
  return {
    title: "Work Permit Status Update",
    body: `Your ${workPermitType} work permit request has been ${newStatus.toLowerCase()} by ${updatedBy}`,
    data: {
      type: "work_permit_status",
      id: workPermitId,
      status: newStatus,
      url: `/work-permits/${workPermitId}`,
    },
  };
};

/**
 * Creates a notification object for new comments on work permits
 * @param {string} workPermitId - The ID of the work permit
 * @param {string} workPermitType - The type of work permit
 * @param {string} commenterName - Name of the person who added the comment
 * @param {string} comment - The comment text (truncated if too long)
 * @returns {Object} Notification object
 */
export const createWorkPermitCommentNotification = (
  workPermitId,
  workPermitType,
  commenterName,
  comment
) => {
  // Truncate comment if it's too long
  const truncatedComment =
    comment.length > 100 ? comment.substring(0, 97) + "..." : comment;

  return {
    title: "New Comment on Work Permit",
    body: `${commenterName} commented on your ${workPermitType} work permit: "${truncatedComment}"`,
    data: {
      type: "work_permit_comment",
      id: workPermitId,
      url: `/work-permits/${workPermitId}`,
    },
  };
};

/**
 * Creates a notification object for work permit assignments
 * @param {string} workPermitId - The ID of the work permit
 * @param {string} workPermitType - The type of work permit
 * @param {Array} assignedTo - Array of users assigned to the work permit
 * @returns {Object} Notification object
 */
export const createWorkPermitAssignmentNotification = (
  workPermitId,
  workPermitType,
  assignedTo
) => {
  const assignedNames = assignedTo.map((user) => user.label).join(", ");

  return {
    title: "Work Permit Assignment",
    body: `You have been assigned to a ${workPermitType} work permit request`,
    data: {
      type: "work_permit_assignment",
      id: workPermitId,
      url: `/work-permits/${workPermitId}`,
    },
  };
};
