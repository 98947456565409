import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { useSearchParams } from "react-router-dom";
import { useCompany } from "../../../context/CompanyContext";
import { motion } from "framer-motion";
import {
  FiHome,
  FiUsers,
  FiClipboard,
  FiCheckSquare,
  FiGrid,
  FiBell,
  FiSettings,
  FiMonitor,
  FiSmartphone,
} from "react-icons/fi";
import {
  getAllDocsSnap,
  setDocData,
} from "../../../utils/firebase/Firebase.utils";
import Modal from "../Components/Modal";
import SplashScreen from "../SplashScreen";
import StatCard from "../../../components/dashboard/StatCard";
import ActivityTimeline from "../../../components/dashboard/ActivityTimeline";
import TodoList from "../../../components/dashboard/TodoList";
import { PieChartV1 } from "../../../components/PieChartV1";
import { convertTimeStamptoDate } from "../../../utils/EleghoUtils";
import toast from "react-hot-toast";
import TodosForm from "../../Todos/TodosForm";
import Calendar from "react-calendar/dist/cjs/Calendar.js";
import StatsCard from "../../../components/dashboard/StatsCard";
import WorkItemCard from "../../../components/dashboard/WorkItemCard";
import SimpleBarChartDashboard from "../../../components/dashboard/SimpleBarChartDashboard";

function AdminDashboard() {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const splash = searchParams.get("splash");
  const [showModal, setShowModal] = React.useState(false);
  const currentUser = useSelector((state) => state.userObject);
  const [selectedJobOrderLink, setSelectedJobOrderLink] =
    React.useState("newJobOrder");
  const firstName = currentUser.displayName;
  const [numberOfUnits, setNumberOfUnits] = React.useState(0);
  const [occupiedUnits, setOccupiedUnits] = React.useState(0);
  const [gatepass, setGatepass] = React.useState(0);
  const [visitorPass, setVisitorPass] = React.useState(0);
  const [activeReport, setActiveReport] = useState("propertyOverview");
  const [viewMode, setViewMode] = useState(false);
  const [workPermits, setWorkPermits] = useState(0);
  const [concerns, setConcerns] = useState(0);
  const [pendingGatePass, setPendingGatePass] = useState([]);
  const [pendingVisitorPass, setPendingVisitorPass] = useState([]);
  const [pendingWorkPermits, setPendingWorkPermits] = useState([]);
  const [pendingConcerns, setPendingConcerns] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [addTodo, setAddTodo] = useState(false);
  const [formData, setFormData] = useState({});
  const [pendingTodos, setPendingTodos] = useState([]);
  const [displayMode, setDisplayMode] = useState("desktop");
  const { company, getCapitalizedTerm } = useCompany();

  useEffect(() => {
    if (splash === "true") {
      setShowModal(true);
    }
  }, [splash]);

  useEffect(() => {
    const fetchTodos = async () => {
      await getAllDocsSnap("todos", (todos) => {
        const pending = todos.filter(
          (todo) => todo.status === "pending" && todo.user === currentUser.uid
        );
        setPendingTodos(pending);
      });
    };
    fetchTodos();
  }, []);

  useEffect(() => {
    const fetchgatepass = async () => {
      const gatepass = await getAllDocsSnap("gatePass", (gatepass) => {
        const pendingGatePass = gatepass.filter(
          (item) => item.status === "pending"
        );
        if (
          currentUser.roles.includes("admin") ||
          currentUser.roles.includes("security")
        ) {
          setPendingGatePass(pendingGatePass);
          setGatepass(pendingGatePass.length);
        } else {
          const assignedGatePass = pendingGatePass.filter((item) =>
            item.assignedTo?.some((user) => user.value === currentUser.email)
          );
          setPendingGatePass(assignedGatePass);
          setGatepass(assignedGatePass.length);
        }
      });
    };

    const fetchVisitorPass = async () => {
      const visitorPass = await getAllDocsSnap("guests", (visitorPass) => {
        const pendingVisitorPass = visitorPass.filter(
          (item) => item.status === "pending"
        );

        if (
          currentUser.roles.includes("admin") ||
          currentUser.roles.includes("security")
        ) {
          setPendingVisitorPass(pendingVisitorPass);
          setVisitorPass(pendingVisitorPass.length);
        } else {
          const assignedVisitorPass = pendingVisitorPass.filter((item) =>
            item.assignedTo?.some((user) => user.value === currentUser.email)
          );
          setPendingVisitorPass(assignedVisitorPass);
          setVisitorPass(assignedVisitorPass.length);
        }
      });
    };

    const fetchWorkPermits = async () => {
      const workPermits = await getAllDocsSnap("workPermits", (workPermits) => {
        const pendingWorkPermits = workPermits.filter(
          (item) => item.status === "Pending"
        );

        if (
          currentUser.roles.includes("admin") ||
          currentUser.roles.includes("security")
        ) {
          setPendingWorkPermits(pendingWorkPermits);
          setWorkPermits(pendingWorkPermits.length);
        } else {
          const assignedWorkPermits = pendingWorkPermits.filter((item) =>
            item.assignedTo?.some((user) => user.value === currentUser.email)
          );
          setPendingWorkPermits(assignedWorkPermits);
          setWorkPermits(assignedWorkPermits.length);
        }
      });
    };

    const fetchConcerns = async () => {
      const concerns = await getAllDocsSnap("concerns", (concerns) => {
        const pendingConcerns = concerns.filter(
          (item) => item.status === "Pending"
        );

        if (
          currentUser.roles.includes("admin") ||
          currentUser.roles.includes("security")
        ) {
          setPendingConcerns(pendingConcerns);
          setConcerns(pendingConcerns.length);
        } else {
          const assignedConcerns = pendingConcerns.filter((item) =>
            item.assignedTo?.some((user) => user.value === currentUser.email)
          );
          setPendingConcerns(assignedConcerns);
          setConcerns(assignedConcerns.length);
        }
      });
    };
    fetchgatepass();
    fetchWorkPermits();
    fetchConcerns();
    fetchVisitorPass();
  }, []);

  useEffect(() => {
    const fetchResidents = async () => {
      const residentList = getAllDocsSnap("residents", (residents) => {
        setNumberOfUnits(residents.length);
        const occupiedUnits = residents.filter(
          (resident) => resident.numberOfOccupants > 0
        );
        setOccupiedUnits(occupiedUnits.length);
        setIsLoading(false);
      });
    };
    fetchResidents();
  }, []);

  // Handle display mode change
  useEffect(() => {
    if (displayMode === "mobile") {
      // Navigate to the mobile admin dashboard layout
      navigate("/admin/mobile");

      // Add a small delay to ensure the component unmounts and remounts
      document.body.className = "mobile-view";
    } else {
      document.body.className = "";
    }
  }, [displayMode, navigate]);

  if (isLoading) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-gray-50">
        <div className="space-y-4 text-center">
          <div className="animate-spin h-8 w-8 border-4 border-blue-600 border-t-transparent rounded-full mx-auto" />
          <p className="text-gray-600">Loading dashboard data...</p>
        </div>
      </div>
    );
  }

  const currentDateTime = new Date();

  const propertyOverview = {
    totalUnits: numberOfUnits,
    totalOccupied: occupiedUnits,
    totalVacant: numberOfUnits - occupiedUnits,
  };

  const todoList = [
    { id: 1, title: "Check your approval list and request", priority: "High" },
    {
      id: 2,
      title: "Check your approval list and request",
      priority: "High",
    },
    {
      id: 3,
      title: "Check your approval list and request",
      priority: "High",
    },
  ];

  const activeLinkStyle = "text-green-600 border-b-2 border-green-600 p-b-2";

  const handleViewReport = () => {
    setActiveReport("propertyOverview");
    setViewMode(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSearchParams({ splash: false });
  };

  const data = {
    title: "Occupancy Overview",
    cardDescription: "As of " + currentDateTime.toLocaleString(),
    chartData: [
      {
        label: "Vacant",
        data: numberOfUnits - occupiedUnits,
        fill: "#ffab00",
      },
      {
        label: "Occupied",
        data: occupiedUnits,
        fill: "#4285f4",
      },
    ],
    total: numberOfUnits,
    chartConfig: {
      visitors: {
        label: "Occupancy",
      },
      chrome: {
        label: "Occupied",
        color: "#4285f4",
      },
      safari: {
        label: "Vacant",
        color: "#ffab00",
      },
    },
  };

  const numberOfWorkItems = gatepass + visitorPass + workPermits + concerns;

  const mappedGatePass = pendingGatePass.map((item) => {
    return {
      id: item.id,
      title: `Gate Pass for ${item.deliveryCompany}`,
      location: item.identifier,
      type: "Gate Pass",
      dateTime: convertTimeStamptoDate(item.requestDateTime).toLocaleString(),
    };
  });

  const mappedWorkPermits = pendingWorkPermits.map((item) => {
    return {
      id: item.id,
      title: item.workPermitType,
      location: item.identifier,
      type: "Work Permit",
      dateTime: item.createdAt,
    };
  });

  const mappedVisitorPass = pendingVisitorPass.map((item) => {
    return {
      id: item.id,
      title: `Visitor Pass`,
      location: item.linkedIdentifiers[0],
      type: "Visitor Pass",
      dateTime: new Date(item.date).toLocaleString(),
    };
  });

  const mappedConcerns = pendingConcerns.map((item) => {
    return {
      id: item.id,
      title: item.concern,
      location: item.identifier,
      type: "Concerns",
      dateTime: item.createdAt,
    };
  });

  const workItems = [
    ...mappedGatePass,
    ...mappedVisitorPass,
    ...mappedWorkPermits,
    ...mappedConcerns,
  ].slice(0, 10);

  //sort workitems by dateTime
  workItems.sort((a, b) => {
    return new Date(b.dateTime) - new Date(a.dateTime);
  });

  const handleLapse = (dateTime) => {
    if (!dateTime) return "Just now";

    const now = new Date();
    const then = new Date(dateTime);
    const totalMilliseconds = now - then;

    // Convert to minutes, hours, and days
    const minutes = Math.floor(totalMilliseconds / (1000 * 60));
    const hours = Math.floor(totalMilliseconds / (1000 * 60 * 60));
    const days = Math.floor(totalMilliseconds / (1000 * 60 * 60 * 24));

    if (minutes < 1) return "Just now";
    if (minutes < 60) return `${minutes} min ago`;
    if (hours < 24) return `${hours} ${hours === 1 ? "hour" : "hours"} ago`;
    return `${days} ${days === 1 ? "day" : "days"} ago`;
  };

  const handleNavigate = (type) => {
    if (type === "Gate Pass") {
      navigate("/security?type=Gate+Pass");
    }

    if (type === "Visitor Pass") {
      navigate("/security?type=Guest+Pass");
    }

    if (type === "Work Permit") {
      navigate("/property-management?type=Work+Permits");
    }

    if (type === "Concerns") {
      navigate("/property-management?type=Concerns");
    }
  };

  const handleAddtodo = () => {
    setAddTodo(!addTodo);
  };

  const handleComplete = async (id) => {
    await setDocData("todos", id, { status: "completed" });
    toast.success("Todo marked as completed");
  };

  const Tickets = () => {
    return (
      <Link
        to="/tickets"
        className="inline-flex items-center gap-1.5 px-3 py-1.5 text-sm font-medium text-gray-700 hover:text-blue-600 bg-gray-50 hover:bg-blue-50 rounded-lg transition-all duration-200"
      >
        <FiBell className="w-4 h-4" />
        <span>View Tickets</span>
      </Link>
    );
  };

  const jobOrderColors = [
    {
      name: "Work Permits",
      color: "#ffab00",
      textColor: "black",
    },
    {
      name: "Gate Pass",
      color: "#4285f4",
      textColor: "white",
    },
    {
      name: "Visitor Pass",
      color: "#34a853",
      textColor: "white",
    },
    {
      name: "Concerns",
      color: "#ea4335",
      textColor: "white",
    },
  ];

  const priorityColors = [
    {
      name: "high",
      color: "#ffab00",
      textColor: "black",
    },
    {
      name: "medium",
      color: "#4285f4",
      textColor: "white",
    },
    {
      name: "low",
      color: "#34a853",
      textColor: "white",
    },
  ];

  return (
    <div className="min-h-screen bg-gray-50 pb-12">
      {showModal && (
        <Modal>
          <SplashScreen
            setShowModal={setShowModal}
            setClose={handleCloseModal}
          />
        </Modal>
      )}

      {addTodo && (
        <Modal>
          <TodosForm setAddTodo={setAddTodo} />
        </Modal>
      )}

      {/* Display Mode Toggle */}
      <div className="flex justify-end px-4 lg:px-6 pt-4 mx-4 lg:max-w-7xl lg:mx-auto">
        <div className="bg-white rounded-lg shadow-sm flex items-center p-1">
          <button
            onClick={() => setDisplayMode("desktop")}
            className={`flex items-center gap-1.5 px-3 py-1.5 rounded-md transition-colors ${
              displayMode === "desktop"
                ? "bg-blue-100 text-blue-600"
                : "text-gray-600 hover:bg-gray-100"
            }`}
          >
            <FiMonitor className="w-4 h-4" />
            <span className="text-sm font-medium">Desktop</span>
          </button>
          <button
            onClick={() => setDisplayMode("mobile")}
            className={`flex items-center gap-1.5 px-3 py-1.5 rounded-md transition-colors ${
              displayMode === "mobile"
                ? "bg-blue-100 text-blue-600"
                : "text-gray-600 hover:bg-gray-100"
            }`}
          >
            <FiSmartphone className="w-4 h-4" />
            <span className="text-sm font-medium">Mobile</span>
          </button>
        </div>
      </div>

      {/* Only render desktop dashboard content when in desktop mode */}
      {displayMode === "desktop" && (
        <>
          {/* Welcome Band */}
          <div className="flex flex-col lg:flex-row items-center justify-between px-4 lg:px-6 py-3 border-b border-gray-200 mx-4 lg:max-w-7xl lg:mx-auto bg-white mt-4 rounded-xl shadow-sm">
            <div className="text-left">
              <motion.div
                initial={{ opacity: 0, y: -20 }}
                animate={{ opacity: 1, y: 0 }}
                className="text-xl font-medium text-blue-900"
              >
                Welcome Back {firstName}!
              </motion.div>
              <motion.div
                initial={{ opacity: 0, y: -20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.1 }}
                className="text-sm text-gray-500"
              >
                You have{" "}
                <span className="font-medium text-red-600">
                  {currentUser.notifications}
                </span>
                {" unread "}
                notifications and{" "}
                <span className="font-medium text-red-600">
                  {numberOfWorkItems}
                </span>{" "}
                work items pending.
              </motion.div>
            </div>

            <motion.div
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.2 }}
              className="flex flex-col items-start justify-start p-3 gap-1.5 text-xs text-gray-600 bg-blue-50/80 rounded-lg max-w-md mt-3 lg:mt-0"
            >
              <div className="font-medium">Trial Period Active</div>
              <div className="text-left">
                Due to limited resident information in the system, you might
                experience limited functionalities. It is advisable to put in
                test data to fully experience the system.
              </div>
            </motion.div>
          </div>

          {/* Main Grid Container */}
          <div className="flex flex-col lg:flex-row items-start justify-center gap-4 w-full px-4 mt-6">
            {/* First Column - Approvals and Job Orders */}
            <div className="w-full lg:w-[380px] flex flex-col gap-6">
              {/* For Approvals */}
              <div className="bg-white rounded-xl shadow-sm p-4 lg:p-6">
                <div className="flex items-center justify-between mb-4">
                  <h2 className="text-lg font-semibold text-gray-900">
                    For Approval
                  </h2>
                  <span className="text-xs lg:text-sm text-gray-500">
                    {currentDateTime.toLocaleString()}
                  </span>
                </div>
                <div className="grid grid-cols-2 gap-3 lg:gap-4">
                  <StatsCard
                    title="Gate Pass"
                    count={gatepass}
                    link="/security?type=Gate+Pass"
                    color="blue"
                    delay={0.1}
                  />
                  <StatsCard
                    title="Visitor Pass"
                    count={visitorPass}
                    link="/security?type=Guest+Pass"
                    color="green"
                    delay={0.2}
                  />
                  <StatsCard
                    title="Work Permits"
                    count={workPermits}
                    link="/property-management?type=Work+Permits"
                    color="yellow"
                    delay={0.3}
                  />
                  <StatsCard
                    title="Concerns"
                    count={concerns}
                    link="/property-management?type=Concerns"
                    color="red"
                    delay={0.4}
                  />
                </div>
              </div>

              {/* Job Orders */}
              <div className="bg-white rounded-xl shadow-sm p-4 lg:p-6">
                {/* Header Section */}
                <div className="space-y-4 mb-8">
                  {/* Title and Actions Row */}
                  <div className="flex items-center justify-between">
                    <h2 className="text-lg font-semibold text-gray-900">
                      Job Orders
                    </h2>
                    <Tickets />
                  </div>

                  {/* Info Row */}
                  <div className="flex items-center justify-between text-sm text-gray-500">
                    <div className="flex items-center gap-2">
                      <span>{currentDateTime.toLocaleString()}</span>
                      <span>•</span>
                      <span>{workItems.length} pending items</span>
                    </div>
                  </div>

                  {/* Tabs Row */}
                  <div className="flex items-center gap-8 border-b border-gray-100">
                    <button
                      className={`pb-3 text-sm font-medium border-b-2 transition-colors ${
                        selectedJobOrderLink === "newJobOrder"
                          ? "text-blue-600 border-blue-600"
                          : "text-gray-500 border-transparent hover:text-gray-700 hover:border-gray-200"
                      }`}
                      onClick={() => setSelectedJobOrderLink("newJobOrder")}
                    >
                      New Job Order
                    </button>
                    <button
                      className={`pb-3 text-sm font-medium border-b-2 transition-colors ${
                        selectedJobOrderLink === "newPendingOrder"
                          ? "text-blue-600 border-blue-600"
                          : "text-gray-500 border-transparent hover:text-gray-700 hover:border-gray-200"
                      }`}
                      onClick={() => setSelectedJobOrderLink("newPendingOrder")}
                    >
                      New Pending Order
                    </button>
                  </div>
                </div>

                {/* Cards Grid */}
                <div className="grid grid-cols-1 gap-4">
                  {selectedJobOrderLink === "newJobOrder" &&
                    workItems.slice(0, 5).map((item, index) => (
                      <WorkItemCard
                        key={item.id}
                        item={{
                          ...item,
                          timeAgo: handleLapse(item.dateTime),
                        }}
                        colors={jobOrderColors}
                        onViewAll={handleNavigate}
                        index={index}
                      />
                    ))}
                  {workItems.length > 5 && (
                    <div className="text-center pt-2">
                      <button
                        onClick={() => navigate("/tickets")}
                        className="text-sm text-blue-600 hover:text-blue-700 hover:underline"
                      >
                        View {workItems.length - 5} more items
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </div>

            {/* Second Column - Property Overview and Collections */}
            <div className="w-full lg:w-[520px] flex flex-col gap-6">
              {/* Property Overview */}
              <div className="bg-white rounded-xl shadow-sm p-4 lg:p-6">
                <div className="flex flex-col sm:flex-row items-start sm:items-center justify-between mb-4 gap-2">
                  <h2 className="text-lg font-semibold text-gray-900">
                    Property Overview
                  </h2>
                  <button
                    onClick={handleViewReport}
                    className="text-sm text-blue-600 hover:text-blue-700 hover:underline"
                  >
                    View Detailed Report
                  </button>
                </div>
                <div className="grid grid-cols-2 gap-4 mb-4">
                  <div className="bg-blue-50 p-3 rounded-lg">
                    <div className="text-sm text-gray-500">
                      Total {getCapitalizedTerm("units")}
                    </div>
                    <div className="text-xl font-bold text-gray-900">
                      {propertyOverview.totalUnits}
                    </div>
                  </div>
                  <div className="bg-green-50 p-3 rounded-lg">
                    <div className="text-sm text-gray-500">
                      Occupied {getCapitalizedTerm("units")}
                    </div>
                    <div className="text-xl font-bold text-gray-900">
                      {propertyOverview.totalOccupied}
                    </div>
                  </div>
                  <div className="bg-amber-50 p-3 rounded-lg">
                    <div className="text-sm text-gray-500">
                      Vacant {getCapitalizedTerm("units")}
                    </div>
                    <div className="text-xl font-bold text-gray-900">
                      {propertyOverview.totalVacant}
                    </div>
                  </div>
                  <div className="bg-purple-50 p-3 rounded-lg">
                    <div className="text-sm text-gray-500">Occupancy Rate</div>
                    <div className="text-xl font-bold text-gray-900">
                      {propertyOverview.totalUnits > 0
                        ? Math.round(
                            (propertyOverview.totalOccupied /
                              propertyOverview.totalUnits) *
                              100
                          )
                        : 0}
                      %
                    </div>
                  </div>
                </div>
                <div className="h-[300px]">
                  <PieChartV1 data={data} />
                </div>
              </div>

              {/* Dues Collection */}
              <div className="bg-white rounded-xl shadow-sm p-4 lg:p-6">
                <div className="flex items-center justify-between mb-4">
                  <h2 className="text-lg font-semibold text-gray-900">
                    Dues Collection Overview
                  </h2>
                </div>
                <div className="h-96">
                  <SimpleBarChartDashboard />
                </div>
              </div>
            </div>

            {/* Third Column - Calendar and Todos */}
            <div className="w-full lg:w-[380px] flex flex-col gap-6">
              {/* Calendar */}
              <div className="bg-white rounded-xl shadow-sm p-4 lg:p-6">
                <div className="flex items-center justify-between mb-4">
                  <h2 className="text-lg font-semibold text-gray-900">
                    Calendar
                  </h2>
                  <span className="text-xs lg:text-sm text-gray-500">
                    {currentDateTime.toLocaleString()}
                  </span>
                </div>
                <div className="max-w-[400px] mx-auto lg:max-w-none">
                  <Calendar className="w-full" />
                </div>
              </div>

              {/* Todos */}
              <div className="bg-white rounded-xl shadow-sm p-4 lg:p-6">
                {/* Header Section */}
                <div className="space-y-4 mb-6">
                  {/* Title and Actions Row */}
                  <div className="flex items-center justify-between">
                    <div>
                      <h2 className="text-lg font-semibold text-gray-900">
                        To Do List
                      </h2>
                      <p className="text-sm text-gray-500 mt-1">
                        {pendingTodos.length} tasks pending
                      </p>
                    </div>
                    <div className="flex items-center gap-2">
                      <button
                        onClick={handleAddtodo}
                        className="inline-flex items-center gap-1.5 px-3 py-1.5 bg-blue-600 text-white text-sm font-medium rounded-lg hover:bg-blue-700 transition-all duration-200 hover:gap-2"
                      >
                        <span>Add Task</span>
                        <FiClipboard className="w-4 h-4" />
                      </button>
                      <button
                        onClick={() => navigate("/todos")}
                        className="p-1.5 text-gray-500 hover:text-blue-600 rounded-lg hover:bg-blue-50 transition-all"
                      >
                        <FiGrid className="w-4 h-4" />
                      </button>
                    </div>
                  </div>
                </div>

                {/* Todo List */}
                <div className="space-y-3">
                  {pendingTodos.slice(0, 5).map((todo, index) => (
                    <motion.div
                      initial={{ opacity: 0, y: 10 }}
                      animate={{ opacity: 1, y: 0 }}
                      transition={{ delay: index * 0.1 }}
                      key={todo.id}
                      className="group flex items-center gap-3 border border-gray-100 rounded-xl p-3 bg-white hover:border-gray-200 hover:shadow-sm transition-all duration-200"
                    >
                      {/* Checkbox */}
                      <button
                        onClick={() => handleComplete(todo.id)}
                        className="flex-shrink-0 w-5 h-5 rounded-md border-2 border-gray-300 hover:border-blue-500 flex items-center justify-center group-hover:border-blue-500 transition-colors"
                      >
                        <FiCheckSquare className="w-4 h-4 text-transparent group-hover:text-blue-500 transition-colors" />
                      </button>

                      {/* Content */}
                      <div className="flex-grow min-w-0">
                        <h3 className="text-sm font-medium text-gray-900 truncate text-left">
                          {todo.name}
                        </h3>
                        <div className="flex items-center gap-2 mt-1">
                          <div
                            className="px-2 py-0.5 rounded-md text-xs font-medium"
                            style={{
                              backgroundColor:
                                priorityColors.find(
                                  (color) =>
                                    color.name === todo.priority?.toLowerCase()
                                )?.color + "15",
                              color: priorityColors.find(
                                (color) =>
                                  color.name === todo.priority?.toLowerCase()
                              )?.color,
                            }}
                          >
                            {todo.priority}
                          </div>
                          <span className="text-xs text-gray-400">
                            {handleLapse(todo.createdAt)}
                          </span>
                        </div>
                      </div>

                      {/* Actions */}
                      <div className="flex-shrink-0 opacity-0 group-hover:opacity-100 transition-opacity">
                        <button
                          onClick={() => handleComplete(todo.id)}
                          className="p-1 text-gray-400 hover:text-blue-600 rounded transition-colors"
                        >
                          <FiCheckSquare className="w-4 h-4" />
                        </button>
                      </div>
                    </motion.div>
                  ))}

                  {/* Show More Link */}
                  {pendingTodos.length > 5 && (
                    <motion.div
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      transition={{ delay: 0.5 }}
                      className="text-center pt-2"
                    >
                      <button
                        onClick={() => navigate("/todos")}
                        className="text-sm text-blue-600 hover:text-blue-700 hover:underline"
                      >
                        View {pendingTodos.length - 5} more tasks
                      </button>
                    </motion.div>
                  )}

                  {/* Empty State */}
                  {pendingTodos.length === 0 && (
                    <motion.div
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      className="text-center py-6"
                    >
                      <div className="text-gray-400 mb-2">
                        <FiCheckSquare className="w-8 h-8 mx-auto" />
                      </div>
                      <h3 className="text-sm font-medium text-gray-900">
                        No pending tasks
                      </h3>
                      <p className="text-xs text-gray-500 mt-1">
                        Add a new task to get started
                      </p>
                    </motion.div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default AdminDashboard;
