import React from "react";
import UserScreenWindow from "./UserScreenWindow";
import { useParams, useNavigate } from "react-router-dom";
import { FaCheckCircle, FaClock, FaFileAlt, FaHandshake } from "react-icons/fa";

function WorkPermitConfirmation() {
  const { id } = useParams();
  const navigate = useNavigate();
  const dateToday = new Date().toLocaleDateString();

  return (
    <UserScreenWindow label={"Work Permit Confirmation"}>
      <div className="max-w-2xl mx-auto px-4">
        <div className="text-center mb-8">
          <FaCheckCircle className="mx-auto text-eleghoGreen text-5xl mb-4" />
          <h1 className="text-2xl font-bold text-eleghoBlue">
            Work Permit Confirmation
          </h1>
        </div>

        <div className="bg-white rounded-2xl shadow-lg p-6 mb-6">
          <div className="space-y-6">
            <div className="flex items-start space-x-4">
              <FaClock className="text-eleghoBlue text-xl mt-1" />
              <div>
                <p className="text-gray-700">
                  Your application for permit is being reviewed
                </p>
                <p className="text-gray-600 text-sm mt-1">
                  You will receive a confirmation on the status of your
                  application within 2 working days
                </p>
              </div>
            </div>

            <div className="flex items-start space-x-4">
              <FaFileAlt className="text-eleghoBlue text-xl mt-1" />
              <div>
                <p className="text-gray-700">Required Documents</p>
                <p className="text-gray-600 text-sm mt-1">
                  If you haven't uploaded required documents, please submit via
                  email or hardcopy at the HOA admin office
                </p>
              </div>
            </div>

            <div className="flex items-start space-x-4">
              <FaHandshake className="text-eleghoBlue text-xl mt-1" />
              <div>
                <p className="text-gray-700">Thank you for your cooperation</p>
              </div>
            </div>
          </div>

          <div className="mt-8 p-4 bg-gray-50 rounded-xl">
            <div className="text-center">
              <p className="text-gray-600 font-semibold">
                Your Transaction Reference Number
              </p>
              <p className="text-eleghoBlue text-xl font-bold mt-2">{id}</p>
              <p className="text-gray-600 text-sm mt-2">
                Transaction Date: {dateToday}
              </p>
            </div>
          </div>
        </div>

        <button
          className="w-full bg-eleghoBlue hover:bg-eleghoGreen text-white py-3 px-6 rounded-xl font-semibold transition-colors duration-200 flex items-center justify-center space-x-2"
          onClick={() => navigate(`/requests`)}
        >
          <span>Go to Requests Summary</span>
        </button>
      </div>
    </UserScreenWindow>
  );
}

export default WorkPermitConfirmation;
