import React from "react";
import InputBox from "../Dashboard/Components/InputBox";
import { useNavigate } from "react-router-dom";
import { setDocData } from "../../utils/firebase/Firebase.utils";
import toast from "react-hot-toast";
import { v4 } from "uuid";
import { useSelector } from "react-redux";

function CreateGroupChatForm({ createGroupChat }) {
  const currentUser = useSelector((state) => state.userObject);
  const [formData, setFormData] = React.useState({});
  const [assignedId, setAssignedId] = React.useState(v4());

  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const groupChatData = {
      name: formData.name,
      description: formData.description,
      members: [currentUser.uid],
      admins: [currentUser.uid],
      image: "",
      messages: [],
    };
    await setDocData("groupChats", assignedId, groupChatData);
    toast.success("Group chat created successfully");
    navigate("/group-chats/" + assignedId);
    createGroupChat(false);
  };
  return (
    <div className="relative bg-white p-2 rounded-lg">
      <button onClick={createGroupChat} className="close-button">
        Cancel
      </button>
      <div className="pt-8">
        <form className="space-y-8" onSubmit={handleSubmit}>
          <InputBox
            name="name"
            type="text"
            label="Group Chat Name"
            placeholder="Enter group chat name"
            onChange={handleChange}
          />
          <InputBox
            name="description"
            type="text"
            label="Description"
            placeholder="Enter group chat description"
            onChange={handleChange}
          />
          <div className="text-center">
            <button type="submit" className="blue-button mt-8">
              Create Group Chat
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default CreateGroupChatForm;
