import React, { useEffect, useState } from "react";
import { getDocDataSnap } from "../../../utils/firebase/Firebase.utils";
import ResidentWorkPermits from "./ResidentWorkPermits";
import ResidentConcerns from "./ResidentConcerns";
import ResidentGatePass from "./ResidentGatePass";
import ResidentGuestPass from "./ResidentGuestPass";
import { useSelector } from "react-redux";
import PageModal from "../Components/PageModal";
import WorkPermitUser from "../../User/WorkPermit/WorkPermitUser";
import MobileModal from "../Components/MobileModal";
import NewWorkPermit from "./NewWorkPermit";
import SideModal from "../Components/SideModal";
import { AnimatePresence } from "framer-motion";

export default function ResidentConcernsAndRequests({ data }) {
  const currentUser = useSelector((state) => state.userObject);
  const [activeLink, setActiveLink] = useState("Work Permits");
  const links = ["Work Permits", "Concerns", "Gate Pass", "Guest Pass"];
  const [addMode, setAddMode] = useState(false);

  const allowableAccess = ["super-admin", "admin", "pm-maker", "pm-checker"];

  const handleAddMode = () => {
    setAddMode(!addMode);
  };

  return (
    <div>
      <div className="flex items-center gap-4 border-b border-gray-200">
        {links.map((link, index) => (
          <div
            key={index}
            className={`cursor-pointer ${
              activeLink === link
                ? "text-primary font-bold border-b-2 border-green-500"
                : "text-gray-500"
            }`}
            onClick={() => setActiveLink(link)}
          >
            {link}
          </div>
        ))}
      </div>

      {currentUser.roles.some((role) => allowableAccess.includes(role)) && (
        <div className="mt-4">
          <button className="blue-button" onClick={handleAddMode}>
            Add
          </button>
        </div>
      )}

      {
        {
          "Work Permits": <ResidentWorkPermits identifier={data.identifier} />,
          Concerns: <ResidentConcerns identifier={data.identifier} />,
          "Gate Pass": <ResidentGatePass identifier={data.identifier} />,
          "Guest Pass": <ResidentGuestPass identifier={data.identifier} />,
        }[activeLink]
      }

      <AnimatePresence>
        {addMode && (
          <SideModal>
            <div className="page">
              {
                {
                  "Work Permits": (
                    <NewWorkPermit
                      setAddMode={setAddMode}
                      identifier={data.identifier}
                    />
                  ),
                  // Concerns: <ResidentConcerns identifier={data.identifier} />,
                  // "Gate Pass": <ResidentGatePass identifier={data.identifier} />,
                  // "Guest Pass": (
                  //   <ResidentGuestPass identifier={data.identifier} />
                  // ),
                }[activeLink]
              }
            </div>
          </SideModal>
        )}
      </AnimatePresence>
    </div>
  );
}
