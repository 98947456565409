import React, { useEffect, useState } from "react";
import { motion, useMotionValue } from "framer-motion";
import { useRef } from "react";

export default function PhotoCarousel({ images }) {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [direction, setDirection] = useState(0); // 1: next, -1: previous
  const dragX = useMotionValue(0);
  const [dragging, setDragging] = React.useState(false);
  const [mobileWidth, setMobileWidth] = useState(0);

  const containerRef = useRef(null);

  useEffect(() => {
    // get width of the container
    if (containerRef.current) {
      setMobileWidth(containerRef.current.offsetWidth);
    }

    const handleResize = () => {
      if (containerRef.current) {
        setMobileWidth(containerRef.current.offsetWidth);
      }
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [containerRef.current]);

  const nextImage = () => {
    setDirection(1);
    setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const prevImage = () => {
    setDirection(-1);
    setCurrentIndex(
      (prevIndex) => (prevIndex - 1 + images.length) % images.length
    );
  };
  const onDragStart = () => {
    setDragging(true);
  };

  const onDragEnd = () => {
    setDragging(false);

    if (dragX.get() < -10) {
      if (currentIndex === images.length - 1) {
        return;
      } else {
        setCurrentIndex((prev) => prev + 1);
      }
    } else if (dragX.get() > 10) {
      if (currentIndex === 0) {
        return;
      } else {
        setCurrentIndex((prev) => prev - 1);
      }
    }
  };

  return (
    <div className=" relative">
      <motion.div
        className="h-60 aspect-video object-cover rounded-xl flex items-center overflow-hidden relative"
        ref={containerRef}
      >
        <motion.div
          className="flex items-center cursor-grab active:cursor-grabbing "
          drag="x"
          dragConstraints={{ left: 0, right: 0 }}
          onDragStart={onDragStart}
          onDragEnd={onDragEnd}
          style={{
            x: dragX,
          }}
        >
          {images.map((photo, index) => (
            <div
              key={index}
              className={`h-60 object-cover rounded-xl shrink-0 bg-cover bg-center bg-no-repeat `}
              style={{
                transform: `translateX(-${currentIndex * 100}%)`,
                transition: "transform 0.5s ease",
                width: `${mobileWidth}px`,
              }}
            >
              <img
                src={photo}
                alt="property"
                className="h-60 w-full object-cover rounded-xl"
                // stop the drag event from bubbling up to the parent
                onDragStart={(e) => e.preventDefault()}
                onTouchStart={(e) => e.preventDefault()}
              />
            </div>
          ))}
        </motion.div>
      </motion.div>

      {/* create dot indicators */}
      {images.length > 1 && (
        <div className="flex items-center justify-center gap-2 absolute bottom-5 p-2 rounded-xl  left-1/2 -translate-x-1/2 w-fit">
          {images.map((photo, index) => (
            <div
              key={index}
              className={`h-2 w-2 rounded-full ${
                index === currentIndex ? "bg-eleghoGreen" : "bg-white"
              }`}
            ></div>
          ))}
        </div>
      )}

      <div className="flex justify-between mt-2 text-xs mb-4">
        <button onClick={prevImage}>Previous</button>
        <button onClick={nextImage}>Next</button>
      </div>
    </div>
  );
}
