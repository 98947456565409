import React from "react";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";

export default function HistoryModule({ data }) {
  return (
    <div className="section-box mt-4">
      <div className="font-semibold text-eleghoBlue text-lg border-b border-gray-400">
        History
      </div>
      <div className="mt-4 flex flex-col-reverse w-full">
        {data?.map((history, index) => (
          <div key={index} className="relative border-l border-gray-300">
            <div className="flex items-center relative -left-4">
              <div className=" bg-white p-1 rounded-full text-green-500">
                <CheckCircleOutlineIcon />
              </div>
              <div className="text-xs">
                {history.action} {history.to}
              </div>
            </div>
            <div className="pl-4 mb-4">
              <div className="text-xs text-gray-400">{history.at}</div>
              <div className="text-xs font-semibold text-gray-400">
                {history.by}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
