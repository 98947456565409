import React, { useEffect } from "react";
import { createNewDocument } from "../../../utils/firebase/Firebase.utils";
import InputBox from "../Components/InputBox";
import {
  getAllDocs,
  getDocDataSnap,
  setDocData,
  deleteDocById,
} from "../../../utils/firebase/Firebase.utils";
import toast from "react-hot-toast";
import SelectInput from "../Components/SelectInput";
import {
  FiUser,
  FiHome,
  FiPhone,
  FiMail,
  FiMapPin,
  FiUsers,
  FiCalendar,
  FiDollarSign,
  FiX,
} from "react-icons/fi";
import { useCompany } from "../../../context/CompanyContext";
import CompanyTerms from "../../../components/CompanyTerms";

function AddTenantForm({ setAddMode, addMode, viewMode, setViewMode, tenant }) {
  const [formData, setFormData] = React.useState(tenant ? tenant : {});
  const [isProcesssing, setIsProcessing] = React.useState(false);
  const [residents, setResidents] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(true);
  const [searchTerm, setSearchTerm] = React.useState("");
  const [filteredResidents, setFilteredResidents] = React.useState([]);
  const [disabled, setDisabled] = React.useState(false);
  const [editMode, setEditMode] = React.useState(false);
  const { terms, getCapitalizedTerm, getTerm } = useCompany();

  useEffect(() => {
    if (viewMode) {
      setDisabled(true);
    }

    if (addMode) {
      setDisabled(false);
    }
  }, []);

  useEffect(() => {
    const fetchResidents = async () => {
      const residents = await getAllDocs("residents");

      const unitNumbers = residents.map((resident) => {
        return {
          identifier: resident.identifier,
          value: resident.identifier,
          label: resident.owner1 + " - " + resident.identifier,
        };
      });

      //sort by identifier name
      unitNumbers.sort((a, b) => {
        if (a.identifier < b.identifier) {
          return -1;
        }
        if (a.identifier > b.identifier) {
          return 1;
        }
        return 0;
      });

      setResidents(unitNumbers);
      setIsLoading(false);
    };

    fetchResidents();
  }, []);

  useEffect(() => {
    setFilteredResidents(
      residents.filter(
        (resident) =>
          resident.label?.toLowerCase().includes(searchTerm?.toLowerCase()) ||
          resident.identifier?.toLowerCase().includes(searchTerm.toLowerCase())
      )
    );
  }, [searchTerm]);

  if (isLoading) {
    return <div>Loading</div>;
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsProcessing(true);
    const res = await createNewDocument("tenants", formData);
    if (res) {
      setIsProcessing(false);
      setAddMode(false);
      setFormData({});
    }
  };

  const options = [
    {
      label: "Select Unit Number",
      value: "",
    },
    ...residents,
  ];

  const leaseTypeOptions = [
    { label: "Personal Lease", value: "Personal Lease" },
    { label: "Corporate Lease", value: "Corporate Lease" },
  ];

  const handleSearchChange = (e) => {
    const { value } = e.target;
    setFormData((prev) => ({ ...prev, lot: value }));
    setSearchTerm(value);
  };

  const handleResidentSelect = (e, resident) => {
    setFormData((prev) => ({ ...prev, lot: resident.value }));
    setSearchTerm("");
  };

  const handleEdit = () => {
    setEditMode(!editMode);
    setDisabled(!disabled);
  };

  const handleSaveEdits = async () => {
    setIsProcessing(true);
    const res = await setDocData("tenants", tenant.id, formData);
    if (res) {
      setIsProcessing(false);
      setEditMode(false);
      setDisabled(true);
      setViewMode(false);
    }
    toast.success("Tenant Details Updated Successfully");
  };

  const handleDelete = async () => {
    if (window.confirm("Are you sure you want to delete this tenant?")) {
      setIsProcessing(true);
      const res = await deleteDocById("tenants", tenant.id);
      if (res) {
        setIsProcessing(false);
        setViewMode(false);
        toast.success("Tenant Deleted Successfully");
      }
    } else {
      toast.error("Deletion Cancelled");
      return;
    }
  };

  const handleSelectLeaseType = (e) => {
    setFormData((prev) => ({ ...prev, leaseType: e.value }));
  };

  return (
    <div className="p-4">
      <div className="flex items-center justify-between mb-4">
        <h2 className="text-lg font-semibold text-gray-800">
          {addMode ? "Add Tenant" : "Tenant Details"}
        </h2>
        <div className="flex items-center gap-2">
          <button
            onClick={() => setAddMode(false)}
            className="p-2 text-gray-500 hover:text-gray-700 hover:bg-gray-100 rounded-lg transition-colors duration-200"
            title="Close"
          >
            <FiX className="w-5 h-5" />
          </button>
          {!addMode && (
            <>
              <button
                onClick={handleEdit}
                className="text-sm text-eleghoBlue hover:underline"
              >
                {editMode ? "Cancel Edit" : "Edit"}
              </button>
              <button
                onClick={handleDelete}
                className="text-sm text-red-600 hover:underline"
              >
                Delete
              </button>
            </>
          )}
        </div>
      </div>

      <form onSubmit={handleSubmit} className="space-y-4">
        {/* Basic Information Section */}
        <div className="bg-white rounded-lg p-3 sm:p-4 border border-gray-100">
          <h3 className="text-sm font-medium text-gray-700 mb-3">
            Basic Information
          </h3>
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-3 sm:gap-4">
            <div className="space-y-1">
              <label className="text-sm font-medium text-gray-600 text-left block">
                First Name
              </label>
              <div className="relative">
                <FiUser className="absolute left-2 top-1/2 transform -translate-y-1/2 text-gray-400 text-sm" />
                <input
                  type="text"
                  name="firstName"
                  value={formData.firstName}
                  onChange={handleChange}
                  className="w-full pl-8 pr-3 py-1.5 text-sm border border-gray-200 rounded-md focus:ring-1 focus:ring-eleghoBlue/20 focus:border-eleghoBlue outline-none"
                  placeholder="Enter first name"
                  required
                  disabled={disabled}
                />
              </div>
            </div>
            <div className="space-y-1">
              <label className="text-sm font-medium text-gray-600 text-left block">
                Last Name
              </label>
              <div className="relative">
                <FiUser className="absolute left-2 top-1/2 transform -translate-y-1/2 text-gray-400 text-sm" />
                <input
                  type="text"
                  name="lastName"
                  value={formData.lastName}
                  onChange={handleChange}
                  className="w-full pl-8 pr-3 py-1.5 text-sm border border-gray-200 rounded-md focus:ring-1 focus:ring-eleghoBlue/20 focus:border-eleghoBlue outline-none"
                  placeholder="Enter last name"
                  required
                  disabled={disabled}
                />
              </div>
            </div>
            <div className="space-y-1">
              <label className="text-sm font-medium text-gray-600 text-left block">
                Address
              </label>
              <div className="relative">
                <FiMapPin className="absolute left-2 top-1/2 transform -translate-y-1/2 text-gray-400 text-sm" />
                <input
                  type="text"
                  name="address"
                  value={formData.address}
                  onChange={handleChange}
                  className="w-full pl-8 pr-3 py-1.5 text-sm border border-gray-200 rounded-md focus:ring-1 focus:ring-eleghoBlue/20 focus:border-eleghoBlue outline-none"
                  placeholder="Enter address"
                  required
                  disabled={disabled}
                />
              </div>
            </div>
            <div className="space-y-1">
              <label className="text-sm font-medium text-gray-600 text-left block">
                Email
              </label>
              <div className="relative">
                <FiMail className="absolute left-2 top-1/2 transform -translate-y-1/2 text-gray-400 text-sm" />
                <input
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  className="w-full pl-8 pr-3 py-1.5 text-sm border border-gray-200 rounded-md focus:ring-1 focus:ring-eleghoBlue/20 focus:border-eleghoBlue outline-none"
                  placeholder="Enter email"
                  required
                  disabled={disabled}
                />
              </div>
            </div>
            <div className="space-y-1">
              <label className="text-sm font-medium text-gray-600 text-left block">
                Phone Number
              </label>
              <div className="relative">
                <FiPhone className="absolute left-2 top-1/2 transform -translate-y-1/2 text-gray-400 text-sm" />
                <input
                  type="tel"
                  name="phoneNumber"
                  value={formData.phoneNumber}
                  onChange={handleChange}
                  className="w-full pl-8 pr-3 py-1.5 text-sm border border-gray-200 rounded-md focus:ring-1 focus:ring-eleghoBlue/20 focus:border-eleghoBlue outline-none"
                  placeholder="Enter phone number"
                  required
                  disabled={disabled}
                />
              </div>
            </div>
          </div>
        </div>

        {/* Rent Information Section */}
        <div className="bg-white rounded-lg p-3 sm:p-4 border border-gray-100">
          <h3 className="text-sm font-medium text-gray-700 mb-3">
            Rent Information
          </h3>
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-3 sm:gap-4">
            <div className="space-y-1">
              <label className="text-sm font-medium text-gray-600 text-left block">
                {getCapitalizedTerm("identifier")}
              </label>
              <div className="relative">
                <FiHome className="absolute left-2 top-1/2 transform -translate-y-1/2 text-gray-400 text-sm" />
                <input
                  type="text"
                  name="lot"
                  value={formData.lot || ""}
                  onChange={handleSearchChange}
                  className="w-full pl-8 pr-3 py-1.5 text-sm border border-gray-200 rounded-md focus:ring-1 focus:ring-eleghoBlue/20 focus:border-eleghoBlue outline-none"
                  placeholder={`Enter ${getTerm("identifier")}`}
                  required
                  disabled={disabled}
                />
              </div>
              {searchTerm && filteredResidents.length > 0 && (
                <ul className="bg-white p-2 rounded-xl border border-gray-200 shadow-lg absolute z-20">
                  {filteredResidents.map((resident, index) => (
                    <li
                      key={index}
                      onClick={(e) => handleResidentSelect(e, resident)}
                      className="cursor-pointer hover:bg-gray-200"
                    >
                      {resident.label}
                    </li>
                  ))}
                </ul>
              )}
            </div>
            <div className="space-y-1">
              <label className="text-sm font-medium text-gray-600 text-left block">
                Monthly Lease Rate
              </label>
              <div className="relative">
                <FiDollarSign className="absolute left-2 top-1/2 transform -translate-y-1/2 text-gray-400 text-sm" />
                <input
                  type="number"
                  name="monthlyLeaseRate"
                  value={formData.monthlyLeaseRate}
                  onChange={handleChange}
                  className="w-full pl-8 pr-3 py-1.5 text-sm border border-gray-200 rounded-md focus:ring-1 focus:ring-eleghoBlue/20 focus:border-eleghoBlue outline-none"
                  placeholder="Enter monthly lease rate"
                  required
                  disabled={disabled}
                />
              </div>
            </div>
            <div className="space-y-1">
              <label className="text-sm font-medium text-gray-600 text-left block">
                Lease Start
              </label>
              <div className="relative">
                <FiCalendar className="absolute left-2 top-1/2 transform -translate-y-1/2 text-gray-400 text-sm" />
                <input
                  type="date"
                  name="leaseStart"
                  value={formData.leaseStart}
                  onChange={handleChange}
                  className="w-full pl-8 pr-3 py-1.5 text-sm border border-gray-200 rounded-md focus:ring-1 focus:ring-eleghoBlue/20 focus:border-eleghoBlue outline-none"
                  required
                  disabled={disabled}
                />
              </div>
            </div>
            <div className="space-y-1">
              <label className="text-sm font-medium text-gray-600 text-left block">
                Lease End
              </label>
              <div className="relative">
                <FiCalendar className="absolute left-2 top-1/2 transform -translate-y-1/2 text-gray-400 text-sm" />
                <input
                  type="date"
                  name="leaseEnd"
                  value={formData.leaseEnd}
                  onChange={handleChange}
                  className="w-full pl-8 pr-3 py-1.5 text-sm border border-gray-200 rounded-md focus:ring-1 focus:ring-eleghoBlue/20 focus:border-eleghoBlue outline-none"
                  required
                  disabled={disabled}
                />
              </div>
            </div>
            <div className="space-y-1">
              <label className="text-sm font-medium text-gray-600 text-left block">
                Number of Occupants
              </label>
              <div className="relative">
                <FiUsers className="absolute left-2 top-1/2 transform -translate-y-1/2 text-gray-400 text-sm" />
                <input
                  type="number"
                  name="occupants"
                  value={formData.occupants}
                  onChange={handleChange}
                  className="w-full pl-8 pr-3 py-1.5 text-sm border border-gray-200 rounded-md focus:ring-1 focus:ring-eleghoBlue/20 focus:border-eleghoBlue outline-none"
                  placeholder="Enter number of occupants"
                  required
                  disabled={disabled}
                />
              </div>
            </div>
            <div className="space-y-1">
              <label className="text-sm font-medium text-gray-600 text-left block">
                Type of Lease
              </label>
              <SelectInput
                options={leaseTypeOptions}
                name="leaseType"
                onChange={handleSelectLeaseType}
                disabled={disabled}
                value={leaseTypeOptions.find(
                  (option) =>
                    option.value?.toLowerCase() ===
                    formData.leaseType?.toLowerCase()
                )}
              />
            </div>
          </div>
        </div>

        {/* Form Actions */}
        <div className="flex flex-col sm:flex-row justify-end gap-2 sm:gap-3">
          <button
            type="button"
            onClick={() => setAddMode(false)}
            className="w-full sm:w-auto px-4 py-1.5 text-sm font-medium text-gray-600 bg-gray-100 rounded-md hover:bg-gray-200 transition-colors duration-200"
          >
            Cancel
          </button>
          {addMode && (
            <button
              type="submit"
              className="w-full sm:w-auto px-4 py-1.5 text-sm font-medium text-white bg-eleghoBlue rounded-md hover:bg-eleghoBlue/90 transition-colors duration-200"
            >
              {isProcesssing ? "Processing..." : "Add Tenant"}
            </button>
          )}
          {editMode && (
            <button
              type="button"
              onClick={handleSaveEdits}
              className="w-full sm:w-auto px-4 py-1.5 text-sm font-medium text-white bg-eleghoBlue rounded-md hover:bg-eleghoBlue/90 transition-colors duration-200"
            >
              {isProcesssing ? "Processing..." : "Save Changes"}
            </button>
          )}
        </div>
      </form>
    </div>
  );
}

export default AddTenantForm;
