import React from "react";

import { FiFileText } from "react-icons/fi";
import { BsFillPeopleFill } from "react-icons/bs";
import { GrUserWorker } from "react-icons/gr";
import { MdOutlinePayments } from "react-icons/md";
import { MdOutlineSos } from "react-icons/md";
import { HiOutlineDotsHorizontal } from "react-icons/hi";
import { useNavigate } from "react-router-dom";
import { MdOutlineConstruction } from "react-icons/md";
import { TbBuildingCottage } from "react-icons/tb";
import { CiDeliveryTruck } from "react-icons/ci";
import { TbAlertSquareFilled } from "react-icons/tb";
import { TbReport } from "react-icons/tb";
import { FaPeoplePulling } from "react-icons/fa6";
import { TbTruckDelivery } from "react-icons/tb";
import { useSelector } from "react-redux";

export default function SecurityAppicons() {
  const currentUser = useSelector((state) => state.userObject);
  const navigate = useNavigate();

  const requestTypes = [
    {
      label: "Guests",
      icon: <BsFillPeopleFill />,
      link: "/guests",
      roles: ["admin", "security", "reception"],
    },
    {
      label: "Parcels",
      icon: <TbTruckDelivery />,
      link: "/parcels",
      roles: ["admin", "security", "housekeeping", "reception"],
    },
    {
      label: "Gate Pass",
      icon: <CiDeliveryTruck />,
      link: "/gate-pass",
      roles: ["admin", "security", "reception"],
    },

    {
      label: "Permits",
      icon: <MdOutlineConstruction />,
      link: "/work-permits",
      roles: ["admin", "security"],
    },

    {
      label: "Facilities",
      icon: <TbBuildingCottage />,
      link: "/facility-usage",
      roles: ["admin", "security"],
    },

    {
      label: "Concerns",
      icon: <GrUserWorker />,
      link: "/concerns",
      roles: ["admin", "security", "reception"],
    },
    {
      label: "Incidents",
      icon: <FaPeoplePulling />,
      link: "/incidents",
      roles: ["admin", "security"],
    },
    {
      label: "Reporting",
      icon: <TbReport />,
      link: "/reporting",
      roles: ["admin", "security"],
    },
    {
      label: "Alerts",
      icon: <TbAlertSquareFilled />,
      link: "/alerts",
      roles: ["admin", "security", "reception"],
    },
  ];

  const filteredRequestTypes = requestTypes.filter((request) =>
    currentUser.roles?.some((role) => request.roles?.includes(role))
  );

  return (
    <div className="bg-white p-2 rounded-lg shadow-md mt-2">
      <div className="header">Resident Requests and Concerns</div>
      <div className="text-neutral-700">
        <div className="flex items-center justify-around gap-2 flex-wrap p-2 rounded-xl ">
          {filteredRequestTypes.map((request, index) => (
            <div
              className="text-xs flex flex-col items-center justify-center w-20 h-20 bg-green-100 rounded-lg hover:bg-green-200 cursor-pointer"
              key={index}
              onClick={() => navigate(`${request.link}`)}
            >
              <div className="text-4xl">{request.icon}</div>
              <div className="text-xs font-semibold">{request.label}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
