import React, { useEffect } from "react";
import UserScreenWindow from "./UserScreenWindow";
import { useParams } from "react-router-dom";
import { getDocData, setDocData } from "../../utils/firebase/Firebase.utils";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import InputBox from "../Dashboard/Components/InputBox";
import { storage } from "../../utils/firebase/Firebase.utils";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import {
  FiDollarSign,
  FiCalendar,
  FiFileText,
  FiUpload,
  FiMessageSquare,
  FiCheck,
} from "react-icons/fi";
import { v4 } from "uuid";

function BillingDetails() {
  const [billDetails, setBillDetails] = React.useState(null);
  const { id } = useParams();
  const [isLoading, setIsLoading] = React.useState(true);
  const navigate = useNavigate();
  const [remarks, setRemarks] = React.useState("");
  const [depositSlip, setDepositSlip] = React.useState(null);
  const [isProcessing, setIsProcessing] = React.useState(false);
  const [progress, setProgress] = React.useState(0);

  useEffect(() => {
    const fetchBillDetails = async () => {
      const data = await getDocData("billings", id);
      setBillDetails(data);
      setIsLoading(false);
    };
    fetchBillDetails();
  }, []);

  if (isLoading) {
    return (
      <UserScreenWindow label="Billing Details">
        <div className="flex items-center justify-center h-64">
          <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-eleghoBlue"></div>
        </div>
      </UserScreenWindow>
    );
  }

  const handleUpload = (e) => {
    const file = e.target.files[0];
    const storageRef = ref(storage, `depositSlips/${file.name}`);
    const uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        switch (snapshot.state) {
          case "paused":
            break;
          case "running":
            break;
        }
        setProgress(progress);
      },
      (error) => {
        toast.error("Error uploading file");
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          setDepositSlip(downloadURL);
          toast.success("File uploaded successfully");
        });
      }
    );
  };

  const handleChange = (e) => {
    const { value, name } = e.target;
    setRemarks(value);
  };

  const handleSubmit = async () => {
    if (!depositSlip) {
      toast.error("Please upload a deposit slip");
      return;
    }

    setIsProcessing(true);
    const data = {
      status: "deposit slip uploaded",
      paymentDate: new Date().toLocaleDateString(),
      paymentId: v4(),
      depositSlip: depositSlip,
      remarks: remarks,
    };
    await setDocData("billings", id, data);
    setIsProcessing(false);
    toast.success("Payment Uploaded!");
    navigate(`/payment-confirmed/${id}`);
  };

  return (
    <UserScreenWindow label="Billing Details">
      <div className="max-w-2xl mx-auto p-6">
        {/* Header */}
        <div className="text-center mb-8">
          <h1 className="text-2xl font-bold text-eleghoBlue mb-2">
            Billing Details
          </h1>
          <p className="text-gray-600">Review and process your payment</p>
        </div>

        {/* Billing Information Card */}
        <div className="bg-white rounded-xl shadow-sm border border-gray-100 p-6 mb-6">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div className="space-y-4">
              <div className="flex items-center gap-3">
                <div className="p-2 bg-eleghoBlue/10 rounded-lg">
                  <FiFileText className="w-5 h-5 text-eleghoBlue" />
                </div>
                <div>
                  <div className="text-sm text-gray-600">Billing For</div>
                  <div className="font-medium text-eleghoBlue">
                    {billDetails.identifier}
                  </div>
                </div>
              </div>
              <div className="flex items-center gap-3">
                <div className="p-2 bg-eleghoGreen/10 rounded-lg">
                  <FiDollarSign className="w-5 h-5 text-eleghoGreen" />
                </div>
                <div>
                  <div className="text-sm text-gray-600">Total Amount</div>
                  <div className="font-medium text-eleghoGreen">
                    Php {billDetails.amount}
                  </div>
                </div>
              </div>
            </div>
            <div className="space-y-4">
              <div className="flex items-center gap-3">
                <div className="p-2 bg-eleghoBlue/10 rounded-lg">
                  <FiCalendar className="w-5 h-5 text-eleghoBlue" />
                </div>
                <div>
                  <div className="text-sm text-gray-600">Due Date</div>
                  <div className="font-medium text-eleghoBlue">
                    {billDetails.dueDate}
                  </div>
                </div>
              </div>
              <div className="flex items-center gap-3">
                <div className="p-2 bg-eleghoBlue/10 rounded-lg">
                  <FiCheck className="w-5 h-5 text-eleghoBlue" />
                </div>
                <div>
                  <div className="text-sm text-gray-600">Status</div>
                  <div className="font-medium text-eleghoBlue capitalize">
                    {billDetails.status}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-4 p-4 bg-gray-50 rounded-lg">
            <div className="text-sm text-gray-600">Description</div>
            <div className="text-gray-800">{billDetails.description}</div>
          </div>
        </div>

        {/* Payment Options */}
        <div className="space-y-6">
          {/* Pay Now Button */}
          <button
            className="w-full bg-eleghoGreen text-white py-3 px-4 rounded-lg font-medium hover:bg-eleghoGreen/90 transition-colors flex items-center justify-center gap-2"
            onClick={() => navigate(`/payment-confirmed/${id}`)}
          >
            <FiDollarSign className="w-5 h-5" />
            Pay Now
          </button>

          {/* Divider */}
          <div className="relative">
            <div className="absolute inset-0 flex items-center">
              <div className="w-full border-t border-gray-200"></div>
            </div>
            <div className="relative flex justify-center text-sm">
              <span className="px-2 bg-white text-gray-500">or</span>
            </div>
          </div>

          {/* Upload Section */}
          <div className="bg-white rounded-xl shadow-sm border border-gray-100 p-6">
            <div className="space-y-4">
              <div className="flex items-center gap-3">
                <div className="p-2 bg-eleghoBlue/10 rounded-lg">
                  <FiUpload className="w-5 h-5 text-eleghoBlue" />
                </div>
                <div>
                  <div className="text-sm font-medium text-gray-700">
                    Upload Deposit Slip
                  </div>
                  <div className="text-xs text-gray-500">
                    Upload your deposit slip image
                  </div>
                </div>
              </div>

              <div className="border-2 border-dashed border-gray-200 rounded-lg p-4 text-center">
                <input
                  type="file"
                  onChange={handleUpload}
                  className="hidden"
                  id="deposit-slip"
                  accept="image/*"
                />
                <label htmlFor="deposit-slip" className="cursor-pointer block">
                  <FiUpload className="w-8 h-8 text-gray-400 mx-auto mb-2" />
                  <div className="text-sm text-gray-600">
                    Click to upload or drag and drop
                  </div>
                </label>
              </div>

              {progress > 0 && (
                <div className="space-y-2">
                  <div className="h-2 bg-gray-100 rounded-full overflow-hidden">
                    <div
                      className="h-full bg-eleghoBlue transition-all duration-300"
                      style={{ width: `${progress}%` }}
                    ></div>
                  </div>
                  <div className="text-xs text-gray-500 text-center">
                    Uploading... {Math.round(progress)}%
                  </div>
                </div>
              )}
            </div>
          </div>

          {/* Remarks Section */}
          <div className="bg-white rounded-xl shadow-sm border border-gray-100 p-6">
            <div className="flex items-center gap-3 mb-4">
              <div className="p-2 bg-eleghoBlue/10 rounded-lg">
                <FiMessageSquare className="w-5 h-5 text-eleghoBlue" />
              </div>
              <div>
                <div className="text-sm font-medium text-gray-700">
                  Add Remarks
                </div>
                <div className="text-xs text-gray-500">
                  Enter any additional information
                </div>
              </div>
            </div>

            <InputBox
              name="remarks"
              onChange={handleChange}
              placeholder="Enter your remarks here"
              value={remarks}
              required
              className="w-full"
            />
          </div>

          {/* Submit Button */}
          <button
            className="w-full bg-eleghoBlue text-white py-3 px-4 rounded-lg font-medium hover:bg-eleghoBlue/90 transition-colors flex items-center justify-center gap-2 disabled:opacity-50 disabled:cursor-not-allowed"
            onClick={handleSubmit}
            disabled={isProcessing}
          >
            {isProcessing ? (
              <>
                <div className="animate-spin rounded-full h-5 w-5 border-b-2 border-white"></div>
                Processing...
              </>
            ) : (
              <>
                <FiCheck className="w-5 h-5" />
                Submit Payment
              </>
            )}
          </button>
        </div>
      </div>
    </UserScreenWindow>
  );
}

export default BillingDetails;
