import { getMessaging, getToken, onMessage } from "firebase/messaging";
import app from "../utils/firebase/Firebase.utils";
import {
  doc,
  updateDoc,
  collection,
  query,
  where,
  getDocs,
  addDoc,
} from "firebase/firestore";
import { db } from "../utils/firebase/Firebase.utils";
import { sendNotification } from "../utils/notifications";

const messaging = getMessaging(app);

export const requestNotificationPermission = async () => {
  try {
    const permission = await Notification.requestPermission();
    if (permission === "granted") {
      const token = await getFCMToken();
      return token;
    }
    return null;
  } catch (error) {
    console.error("Error requesting notification permission:", error);
    return null;
  }
};

export const getFCMToken = async () => {
  try {
    const currentToken = await getToken(messaging, {
      vapidKey:
        "BGi8X7349xlPDXxlG5wEuRCLQNSm2dzD6_CuxcJK5qTVjX75Z2JJB2Si9HVduxuN73wcyE-grg2oLjMvjZe9LG4", // You'll need to replace this with your actual VAPID key
    });
    return currentToken;
  } catch (error) {
    console.error("Error getting FCM token:", error);
    return null;
  }
};

export const onMessageListener = () => {
  return new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      console.log("Received foreground message:", payload);
      // Display notification using the Notification API for foreground messages
      if (payload.notification) {
        const notificationTitle = payload.notification.title;
        const notificationOptions = {
          body: payload.notification.body,
          icon: "/logo192.png",
          data: payload.data,
        };

        // Create and show the notification
        try {
          const notification = new Notification(
            notificationTitle,
            notificationOptions
          );

          // Handle notification click
          notification.onclick = () => {
            if (payload.data && payload.data.url) {
              window.location.href = payload.data.url;
            }
            notification.close();
          };
        } catch (error) {
          console.error("Error showing notification:", error);
        }
      }
      resolve(payload);
    });
  });
};

export const saveUserToken = async (userId, token) => {
  try {
    const userRef = doc(db, "users", userId);
    await updateDoc(userRef, {
      fcmToken: token,
      notificationEnabled: true,
    });
    return true;
  } catch (error) {
    console.error("Error saving user token:", error);
    return false;
  }
};

export const removeUserToken = async (userId) => {
  try {
    const userRef = doc(db, "users", userId);
    await updateDoc(userRef, {
      fcmToken: null,
      notificationEnabled: false,
    });
    return true;
  } catch (error) {
    console.error("Error removing user token:", error);
    return false;
  }
};

// Utility functions for different notification types
export const createGuestPassStatusNotification = (
  passId,
  status,
  unitNumber
) => {
  return {
    title: "Guest Pass Status Update",
    body: `Guest pass for  ${unitNumber} is now ${status}`,
    data: {
      url: `/guests/${passId}`,
      type: "guest_pass_status",
      passId: passId,
      status: status,
    },
  };
};

// Helper for creating status-specific notifications with appropriate styling and messages
export const createStatusSpecificNotification = (
  passId,
  status,
  unitNumber,
  audience = "resident"
) => {
  let title,
    body,
    priority = "normal",
    url = `/guests/${passId}`; // Initialize url with default value

  // Different message based on audience (resident, staff, security)
  switch (audience) {
    case "security":
      // Security-focused messages
      if (status === "admitted") {
        title = "Guest Admission Alert";
        body = `Guests for  ${unitNumber} have been admitted to the property. Please verify their credentials.`;
        priority = "high";
        url = `/guests-card-details/${passId}`;
      } else if (status === "completed") {
        title = "Guest Departure Alert";
        body = `Guests for  ${unitNumber} have completed their visit. Please confirm departure.`;
        priority = "high";
      } else if (status === "approved") {
        title = "New Approved Guest Pass";
        body = `Guest pass for  ${unitNumber} has been approved. Expect guest arrival soon.`;
      } else {
        title = "Guest Pass Status Change";
        body = `Guest pass for  ${unitNumber} is now ${status}.`;
      }
      break;

    case "staff":
      // Staff-focused messages
      if (status === "pending") {
        title = "New Guest Pass Request";
        body = `A new guest pass request has been submitted for Unit ${unitNumber}. Please review.`;
        priority = "high";
      } else {
        title = "Guest Pass Status Change";
        body = `Guest pass for  ${unitNumber} is now ${status}.`;
      }
      break;

    case "resident":
    default:
      // Resident-focused messages
      switch (status) {
        case "approved":
          title = "Guest Pass Approved";
          body = `Your guest pass request for  ${unitNumber} has been approved.`;
          break;
        case "rejected":
          title = "Guest Pass Rejected";
          body = `Your guest pass request for  ${unitNumber} has been rejected.`;
          priority = "high";
          break;
        case "admitted":
          title = "Guests Admitted";
          body = `Your guests have been admitted to the property.`;
          break;
        case "completed":
          title = "Guest Visit Completed";
          body = `Your guest pass for  ${unitNumber} has been marked as completed.`;
          break;
        case "returned":
          title = "Guest Pass Returned";
          body = `Your guest pass for  ${unitNumber} has been returned.`;
          break;
        default:
          title = "Guest Pass Status Update";
          body = `Your guest pass for  ${unitNumber} is now ${status}.`;
      }
  }

  return {
    title,
    body,
    data: {
      url,
      type: "guest_pass_status",
      passId,
      status,
      priority,
    },
  };
};

export const createGuestPassAssignmentNotification = (
  passId,
  unitNumber,
  assignedTo = []
) => {
  const assignedNames = assignedTo.map((user) => user.label).join(", ");
  return {
    title: "Guest Pass Assignment",
    body:
      assignedTo.length > 0
        ? `Guest pass for  ${unitNumber} assigned to ${assignedNames}`
        : `Guest pass for  ${unitNumber} has been unassigned`,
    data: {
      url: `/guests/${passId}`,
      type: "guest_pass_assignment",
      passId: passId,
    },
  };
};

export const createGuestPassCommentNotification = (
  passId,
  unitNumber,
  commenter,
  commentText
) => {
  const shortComment =
    commentText.length > 50
      ? `${commentText.substring(0, 50)}...`
      : commentText;

  return {
    title: "New Comment on Guest Pass",
    body: `${commenter} commented on  ${unitNumber} guest pass: ${shortComment}`,
    data: {
      url: `/guests/${passId}`,
      type: "guest_pass_comment",
      passId: passId,
    },
  };
};

// Function to store a notification in Firestore regardless of FCM success
export const storeNotification = async (userEmail, notification) => {
  try {
    // Find user by email
    const usersRef = collection(db, "users");
    const q = query(usersRef, where("email", "==", userEmail));
    const querySnapshot = await getDocs(q);

    if (querySnapshot.empty) {
      console.log("User not found with email:", userEmail);
      return false;
    }

    const userDoc = querySnapshot.docs[0];

    // Store notification in Firestore
    const notificationsRef = collection(db, "notifications");
    await addDoc(notificationsRef, {
      ...notification,
      timestamp: new Date(),
      read: false,
      userId: userDoc.id,
      userEmail: userEmail,
    });

    return true;
  } catch (error) {
    console.error("Error storing notification:", error);
    return false;
  }
};

// Test function to send a notification to the current user for debugging
export const sendTestNotification = async (currentUser) => {
  if (!currentUser || !currentUser.email) {
    console.error("No current user available for test notification");
    return false;
  }

  try {
    const testNotification = {
      title: "Test Notification",
      body: "This is a test notification to verify that notifications are working correctly",
      data: {
        url: "/notifications",
        type: "test",
        timestamp: new Date().toISOString(),
      },
    };

    // Store in Firestore
    const success = await storeNotification(
      currentUser.email,
      testNotification
    );

    if (success) {
      console.log("Test notification stored successfully");

      // Try sending via FCM
      const fcmResult = await sendNotification(
        currentUser.email,
        testNotification
      );
      console.log("FCM result:", fcmResult);

      return true;
    } else {
      console.error("Failed to store test notification");
      return false;
    }
  } catch (error) {
    console.error("Error sending test notification:", error);
    return false;
  }
};

// Debug function to log notification permission status and token
export const debugNotificationStatus = async () => {
  try {
    const permissionStatus = Notification.permission;
    console.log("Notification permission status:", permissionStatus);

    if (permissionStatus === "granted") {
      const token = await getFCMToken();
      console.log("FCM Token:", token);
      return { permissionStatus, token };
    }

    return { permissionStatus, token: null };
  } catch (error) {
    console.error("Error checking notification status:", error);
    return { error: error.message };
  }
};
