import { addNewDoc } from "./firebase/Firebase.utils";
import { v4 } from "uuid";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { storage } from "./firebase/Firebase.utils";

export const addNewNotification = async (notification) => {
  const newNotification = {
    ...notification,
    read: false,
  };
  await addNewDoc("notifications", newNotification);
};

export const convertTimeStamptoDate = (timestamp) => {
  const date = timestamp.toDate().toLocaleDateString("en-US", {
    year: "numeric",
    month: "short",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  });
  return date;
};

export const convertFirebaseDate = (timestamp) => {
  const date = timestamp.toDate().toLocaleDateString("en-US", {
    year: "numeric",
    month: "short",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  });
  return date;
};

export const convertToDateOnly = (timestamp) => {
  const date = timestamp.toDate().toLocaleDateString("en-US", {
    year: "numeric",
    month: "short",
    day: "numeric",
  });
  return date;
};

export const uploadFile = async (
  e,
  folder,
  setUploadingInProgress,
  setProgress,
  setFormData,
  name
) => {
  const file = e.target.files[0];
  const storageRef = ref(storage, `${folder}/${file.name} + ${v4()}`);
  const uploadTask = uploadBytesResumable(storageRef, file);
  setUploadingInProgress(true);

  uploadTask.on(
    "state_changed",
    (snapshot) => {
      const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
      setProgress(progress);
    },
    (error) => {},
    () => {
      getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
        setFormData((prev) => ({ ...prev, [name]: downloadURL }));
      });

      setUploadingInProgress(false);
    }
  );
};

// remove non numeric characters from a string
export const removeNonNumeric = (str) => {
  return str.replace(/\D/g, "");
};

// format a phone number
export function formatPhoneNumber(input) {
  // Remove any non-digit characters
  let digits = input.replace(/\D/g, "");

  // Check if the number has the correct length
  if (digits.length < 11) {
    return "";
  }

  // Check if the first two digits are '63' and replace them with '0'
  if (digits.startsWith("+63")) {
    digits = "0" + digits.substring(3);
  }

  // Check if the first two digits are '63' and replace them with '0'
  if (digits.startsWith("63")) {
    digits = "0" + digits.substring(2);
  }

  // Format the number
  const formatted = `(${digits.substring(0, 4)}) ${digits.substring(
    4,
    7
  )} ${digits.substring(7)}`;
  return formatted;
}

export const handleUploadMultiple = async (
  e,
  formData,
  setFormData,
  folder,
  name
) => {
  const files = e.target.files;
  const promises = [];

  for (let i = 0; i < files.length; i++) {
    const file = files[i];
    const storageRef = ref(storage, `${folder}/${file.name} + ${v4()}`);
    const uploadTask = uploadBytesResumable(storageRef, file);
    promises.push(uploadTask);
  }

  Promise.all(promises).then(async (snapshot) => {
    const downloadURLs = formData[name] || [];

    // Gather all download URLs with Promise.all
    await Promise.all(
      snapshot.map(async (snap) => {
        const downloadURL = await getDownloadURL(snap.ref);
        downloadURLs.push(downloadURL);
      })
    );

    // Update the formData only after all download URLs are fetched
    setFormData({ ...formData, [name]: downloadURLs });
  });

  //reset the file input
  e.target.value = null;
};

export const slicedData = (data, numberOfItemPerPage, currentPage) => {
  data.slice(
    (currentPage - 1) * numberOfItemPerPage,
    currentPage * numberOfItemPerPage
  );

  return data;
};

export const sendEmailNotification = async (
  name,
  email,
  subject,
  message,
  link,
  setEmailingUser,
  setEmailInProgress
) => {
  setEmailInProgress(true);
  setEmailingUser(email);
  const fetchReq = async () => {
    await fetch("https://talieleghoemailsender.vercel.app//api/general", {
      method: "POST",
      body: JSON.stringify({
        name,
        email,
        subject,
        message,
        link,
      }),
    });
  };
  fetchReq();
  setEmailInProgress(false);
  setEmailingUser(null);
};

export const userRoles = [
  { value: "super-admin", label: "Super Admin" },
  { value: "admin", label: "Admin" },
  { value: "user", label: "User" },
  { value: "security", label: "Security" },
  { value: "maintenance", label: "Maintenance" },
  { value: "reception", label: "Reception" },
  { value: "housekeeping", label: "House Keeping" },
  { value: "pmo-team", label: "PMO-team member" },
  { value: "approver", label: "Approver" },
  { value: "reviewer", label: "Reviewer" },
  { value: "maker", label: "Maker" },
  { value: "bod", label: "BOD" },
  { value: "finance", label: "Finance" },
  { value: "broker", label: "Broker" },
  { value: "restricted", label: "Restricted" },
  { value: "guest", label: "Guest" },
];

export const departmentOptions = [
  { value: "admin", label: "Admin" },
  { value: "security", label: "Security" },
  { value: "finance", label: "Finance" },
  { value: "engineering", label: "Engineering" },
  { value: "maintenance", label: "Maintenance" },
  { value: "housekeeping", label: "House-Keeping" },
  { value: "warranty", label: "Warranty" },
  { value: "board", label: "Board of Directors" },
];

export class Condo {
  constructor(name, id) {
    this.name = name;
    this.id = id;
    this.resident = "resident";
    this.unit = "unit";
    this.tenant = "tenant";
    this.unitNo = "unit number";
    this.owner = "unit owner";
  }
}

export class HOA {
  constructor(name, id) {
    this.name = name;
    this.id = id;
    this.resident = "resident";
    this.unit = "lot";
    this.tenant = "tenant";
    this.unitNo = "lot number";
    this.owner = "lot owner";
  }
}
