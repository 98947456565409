import React from "react";
import { motion } from "framer-motion";
import { FiEye, FiEdit2, FiTrash2, FiTag } from "react-icons/fi";

export default function AnnouncementCard({
  announcement,
  handleToggleStatus,
  handleViewMode,
  setAsAdvisory,
  handleDelete,
  editMode,
}) {
  const getStatusColor = (status) => {
    return status === "published"
      ? "bg-green-100 text-[#3CB371]"
      : "bg-yellow-100 text-yellow-800";
  };

  return (
    <motion.div
      whileHover={{ y: -5 }}
      className="bg-white rounded-xl overflow-hidden transition-all duration-300 shadow-md hover:shadow-lg relative h-full flex flex-col"
    >
      {/* Type Tag */}
      {announcement.type === "advisory" && (
        <div className="absolute top-3 left-3 z-10 bg-[#00578e] text-white px-3 py-1 rounded-full text-xs font-medium shadow-md flex items-center gap-1">
          <FiTag className="h-3 w-3" />
          <span>{announcement.type}</span>
        </div>
      )}

      {/* Status Badge */}
      <div
        className={`absolute top-3 right-3 z-10 px-3 py-1 rounded-full text-xs font-medium shadow-sm cursor-pointer transition-colors ${getStatusColor(
          announcement.status
        )}`}
        onClick={() => handleToggleStatus(announcement)}
      >
        {announcement.status}
      </div>

      {/* Image Section */}
      <div className="h-52 overflow-hidden">
        <img
          src={
            announcement.poster ||
            "https://via.placeholder.com/400x200?text=No+Image"
          }
          alt={announcement.title}
          className="w-full h-full object-cover transform transition-transform duration-500 hover:scale-110"
        />
      </div>

      {/* Content Section */}
      <div className="p-5 flex-grow">
        <h3 className="font-bold text-xl text-[#00578e] mb-2 line-clamp-2">
          {announcement.title}
        </h3>
        <p className="text-gray-600 line-clamp-3 text-sm">
          {announcement.description}
        </p>
      </div>

      {/* Action Buttons */}
      <div className="border-t border-gray-100 p-4 flex items-center justify-between gap-2">
        <button
          onClick={() => handleViewMode(announcement)}
          className="flex items-center gap-1 text-[#00578e] hover:text-[#004570] transition-colors text-sm font-medium"
        >
          <FiEye className="h-4 w-4" />
          <span>View Details</span>
        </button>

        {editMode && (
          <div className="flex items-center gap-3">
            <button
              className="flex items-center gap-1 text-[#3CB371] hover:text-[#2E8B57] transition-colors text-sm font-medium"
              onClick={() => setAsAdvisory(announcement)}
            >
              <FiTag className="h-4 w-4" />
              <span>Set Advisory</span>
            </button>
            <button
              className="flex items-center gap-1 text-red-600 hover:text-red-800 transition-colors text-sm font-medium"
              onClick={() => handleDelete(announcement)}
            >
              <FiTrash2 className="h-4 w-4" />
              <span>Delete</span>
            </button>
          </div>
        )}
      </div>
    </motion.div>
  );
}
