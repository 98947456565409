import React from "react";
import { motion } from "framer-motion";
import { FiX } from "react-icons/fi";
import { useSelector } from "react-redux";

function SplashScreen({ setShowModal, setClose }) {
  const currentUser = useSelector((state) => state.userObject);
  const firstName = currentUser?.displayName?.split(" ")[0] || "User";

  const handleModal = () => {
    setShowModal(false);
    if (setClose) setClose();
  };

  return (
    <motion.div
      initial={{ opacity: 0, scale: 0.95 }}
      animate={{ opacity: 1, scale: 1 }}
      exit={{ opacity: 0, scale: 0.95 }}
      transition={{ duration: 0.2 }}
      className="bg-white rounded-2xl shadow-xl overflow-hidden max-w-[400px] w-full mx-auto"
    >
      {/* Close Button */}
      <button
        className="absolute right-4 top-4 p-2 rounded-full hover:bg-gray-100 transition-colors z-10"
        onClick={handleModal}
      >
        <FiX className="w-5 h-5 text-gray-500" />
      </button>

      {/* Header Section */}
      <div className="bg-gradient-to-br from-[#1e3a8a] to-[#4f46e5] p-8 text-white">
        <motion.div
          initial={{ y: 20, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ delay: 0.1 }}
          className="space-y-2"
        >
          <h1 className="text-2xl font-semibold">
            Welcome back, {firstName}! 👋
          </h1>
          <p className="text-blue-100 text-sm">
            You're now connected to The Tali Connect Portal
          </p>
        </motion.div>
      </div>

      {/* Content Section */}
      <div className="p-8 space-y-6">
        {/* Logo Section */}
        <motion.div
          initial={{ scale: 0.8, opacity: 0 }}
          animate={{ scale: 1, opacity: 1 }}
          transition={{ delay: 0.2 }}
          className="flex flex-col items-center space-y-3"
        >
          <img
            src="https://www.elegho.com/_next/image?url=https%3A%2F%2Fres.cloudinary.com%2Fduk7mmsc6%2Fimage%2Fupload%2Fv1741437371%2FElegho%2FElegho-Logo_qdoodo.png&w=640&q=75"
            alt="Elegho Logo"
            className="h-10"
          />
          <p className="text-sm text-gray-600 font-medium">Powered by Elegho</p>
        </motion.div>

        {/* Trial Info */}
        <motion.div
          initial={{ y: 20, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ delay: 0.3 }}
          className="space-y-4 text-sm"
        >
          <div className="bg-blue-50 p-4 rounded-lg">
            <p className="text-blue-900 font-medium mb-2">
              🎉 Trial Period Active
            </p>
            <p className="text-gray-600 leading-relaxed">
              During the trial period, you may experience limited functionality
              due to minimal resident data. We recommend adding test data to
              fully explore the system's capabilities.
            </p>
          </div>

          {/* Support Info */}
          <div className="bg-gray-50 p-4 rounded-lg">
            <p className="text-gray-900 font-medium mb-2">Need Help? 💁‍♂️</p>
            <p className="text-gray-600">
              Contact our support team at{" "}
              <a
                href="mailto:sales@infinity8data.com"
                className="text-blue-600 hover:text-blue-700 font-medium"
              >
                sales@infinity8data.com
              </a>
            </p>
          </div>
        </motion.div>

        {/* Get Started Button */}
        <motion.button
          initial={{ y: 20, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ delay: 0.4 }}
          onClick={handleModal}
          className="w-full py-3 bg-[#4f46e5] text-white font-medium rounded-lg
            hover:bg-[#4338ca] transition-colors
            focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#4f46e5]"
        >
          Get Started
        </motion.button>
      </div>
    </motion.div>
  );
}

export default SplashScreen;
