import React, { useEffect } from "react";
import NoPhoto from "../../assets/blankphoto.webp";
import {
  setDocData,
  storage,
  getDocDataSnap,
} from "../../../utils/firebase/Firebase.utils";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import toast from "react-hot-toast";
import InputBox from "../Components/InputBox";
import OccupantFormData from "./OccupantFormData";
import OccupantDocuments from "./OccupantDocuments";
import { IoClose } from "react-icons/io5";

export default function TenantOccupantDetails({
  tenantId,
  occupantId,
  tenant,
  handleViewMode,
  isAccordion = false,
}) {
  const [formData, setFormData] = React.useState({});
  const [isLoading, setIsLoading] = React.useState(true);
  const [progress, setProgress] = React.useState(0);
  const [occupant, setOccupant] = React.useState({});
  const [editMode, setEditMode] = React.useState(false);
  const [activeLink, setActiveLink] = React.useState("Information");

  useEffect(() => {
    const fetchOccupant = async () => {
      getDocDataSnap("tenants", tenantId, (tenant) => {
        const occupant = tenant.occupantsName.find(
          (item) => item.id === occupantId
        );
        setOccupant(occupant);
        setFormData(occupant);
        setIsLoading(false);
      });
    };
    fetchOccupant();
  }, []);

  const handleUpload = (e) => {
    e.preventDefault();
    const file = e.target.files[0];

    if (!file) {
      toast.error("No file selected");
      return;
    }

    const storageRef = ref(storage, `tenants/${tenant.id}/${occupant.id}`);
    const uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setProgress(progress);
      },
      (error) => {},
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          setFormData((prev) => ({ ...prev, primaryPhoto: downloadURL }));
          setDocData("tenants", tenantId, {
            occupantsName: tenant.occupantsName.map((item) =>
              item.id === occupant.id
                ? { ...item, primaryPhoto: downloadURL }
                : item
            ),
          });
        });
      }
    );
  };

  if (isLoading) {
    return (
      <div className="flex items-center justify-center p-8">
        <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-eleghoBlue"></div>
      </div>
    );
  }

  const handleEditMode = () => {
    setEditMode(!editMode);

    if (editMode) {
      setFormData(occupant);
    }
  };

  const links = ["Information", "Documents"];

  const handleSubmit = () => {};

  return (
    <div
      className={`${
        isAccordion
          ? "bg-white"
          : "relative pt-8 m-2 bg-gray-100 w-full min-h-screen rounded-lg"
      }`}
    >
      {!isAccordion && (
        <button className="close-button font-bold" onClick={handleViewMode}>
          Close
        </button>
      )}

      <div
        className={`${
          isAccordion
            ? "p-4"
            : "bg-blue-900 p-8 flex items-center gap-8 flex-wrap"
        }`}
      >
        <div className="flex items-center gap-4">
          <img
            src={occupant.primaryPhoto || NoPhoto}
            alt=""
            className={`${
              isAccordion ? "w-16 h-16" : "w-60"
            } aspect-square object-cover object-top rounded-full`}
          />
          <div
            className={`${
              isAccordion ? "text-gray-900" : "text-white"
            } font-bold ${isAccordion ? "text-base" : "text-lg uppercase"}`}
          >
            {occupant.firstName} {occupant.middleName} {occupant.lastName}
          </div>
        </div>
      </div>

      <div className={`${isAccordion ? "p-4" : "p-8"}`}>
        {editMode && (
          <>
            <div className="mt-4">
              <input type="file" onChange={handleUpload} />
              <progress value={progress} max="100" />
            </div>
          </>
        )}
        <div className="mt-2">
          <button className="blue-button mb-4" onClick={handleEditMode}>
            {editMode ? "Cancel" : "Edit Photo"}
          </button>
        </div>

        <div className="flex gap-2 mb-4">
          {links.map((link) => (
            <button
              key={link}
              onClick={() => setActiveLink(link)}
              className={`px-4 py-2 rounded-lg text-sm font-medium transition-colors duration-200 ${
                activeLink === link
                  ? "bg-eleghoBlue text-white"
                  : "bg-gray-100 text-gray-600 hover:bg-gray-200"
              }`}
            >
              {link}
            </button>
          ))}
        </div>

        <div className="bg-white rounded-lg shadow-sm border border-gray-100 p-4">
          {activeLink === "Information" && (
            <OccupantFormData occupantId={occupantId} />
          )}

          {activeLink === "Documents" && (
            <OccupantDocuments
              formData={formData}
              tenantId={tenantId}
              tenant={tenant}
              occupantId={occupantId}
            />
          )}
        </div>
      </div>
    </div>
  );
}
