import React, { useState, useEffect } from "react";
import {
  getAllDocsSnap,
  setDocData,
  getEmailsOfAllFirebaseUsers,
} from "../../utils/firebase/Firebase.utils";
import Select from "react-select";
import InputBox from "../Dashboard/Components/InputBox";
import toast from "react-hot-toast";
import Modal from "../Dashboard/Components/Modal";
import UserDetails from "./UserDetails";
import { useSelector } from "react-redux";
import {
  convertFirebaseDate,
  convertTimeStamptoDate,
  departmentOptions,
  userRoles,
} from "../../utils/EleghoUtils";
import { FaSearch, FaEnvelope, FaEdit, FaEye, FaViber } from "react-icons/fa";

// Navigation Tab Component
const NavigationTab = ({ label, active, count, onClick }) => (
  <button
    onClick={onClick}
    className={`px-4 py-2 rounded-lg text-sm font-medium transition-all relative text-left
      ${
        active
          ? `bg-[#363062]/10 text-[#363062]`
          : "text-gray-600 hover:bg-gray-50"
      }`}
  >
    {label}
    {count > 0 && (
      <span className="absolute -top-1 -right-1 bg-[#8FCD00] text-white text-xs rounded-full w-5 h-5 flex items-center justify-center">
        {count}
      </span>
    )}
  </button>
);

// User Table Row Component
const UserTableRow = ({
  user,
  selectedUserForEdit,
  onEdit,
  onView,
  onEmailConfirmation,
  emailingUser,
  emailInProgress,
  onSelectDepartment,
  onSelectRoles,
}) => (
  <tr className="border-b border-gray-100 hover:bg-gray-50 transition-colors">
    <td className="p-4 text-left">
      <div className="font-medium text-gray-900">{user.displayName}</div>
      <div className="text-sm text-gray-500">{user.email}</div>
    </td>
    <td className="p-4 text-left">
      {selectedUserForEdit === user.id ? (
        <Select
          className="w-48"
          onChange={(selectedOption) =>
            onSelectDepartment(user.id, selectedOption)
          }
          options={departmentOptions}
          value={departmentOptions.find(
            (item) => user.department === item.value
          )}
        />
      ) : (
        <span className="text-sm text-gray-600">
          {user.department || "Not assigned"}
        </span>
      )}
    </td>
    <td className="p-4 text-left">
      {selectedUserForEdit === user.id ? (
        <Select
          isMulti
          className="w-full"
          onChange={(selectedOption) => onSelectRoles(user.id, selectedOption)}
          options={userRoles}
          value={userRoles.filter((item) => user.roles?.includes(item.value))}
        />
      ) : (
        <div className="flex flex-wrap gap-2">
          {user.roles?.map((role) => (
            <span
              key={role}
              className={`px-2 py-1 text-xs font-medium rounded-full ${
                role === "admin"
                  ? "bg-[#363062]/10 text-[#363062]"
                  : role === "user"
                  ? "bg-[#8FCD00]/10 text-[#8FCD00]"
                  : "bg-gray-100 text-gray-700"
              }`}
            >
              {role}
            </span>
          ))}
        </div>
      )}
    </td>
    <td className="p-4 text-left">
      <div className="flex items-center gap-2">
        <button
          onClick={() => onView(user)}
          className="p-2 text-[#363062] hover:bg-[#363062]/10 rounded-lg transition-colors"
          title="View Details"
        >
          <FaEye className="w-4 h-4" />
        </button>
        {/* <button
          onClick={() => onEdit(user.id)}
          className="p-2 text-[#363062] hover:bg-[#363062]/10 rounded-lg transition-colors"
          title={selectedUserForEdit === user.id ? "Update" : "Edit"}
        >
          <FaEdit className="w-4 h-4" />
        </button> */}
        <button
          onClick={() => onEmailConfirmation(user.email, user.displayName)}
          disabled={emailingUser === user.email}
          className={`p-2 rounded-lg transition-colors ${
            emailingUser === user.email
              ? "text-gray-400 bg-gray-50"
              : "text-[#363062] hover:bg-[#363062]/10"
          }`}
          title="Send Email"
        >
          <FaEnvelope className="w-4 h-4" />
        </button>
        <button
          className="p-2 text-[#363062] hover:bg-[#363062]/10 rounded-lg transition-colors"
          title="Send Viber"
        >
          <FaViber className="w-4 h-4" />
        </button>
      </div>
    </td>
  </tr>
);

function UserAccess({ notificationCount, setNotificationCount }) {
  const currentUser = useSelector((state) => state.userObject);
  const [users, setUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedUserForEdit, setSelectedUserForEdit] = useState(null);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [emailInProgress, setEmailInProgress] = useState(false);
  const [emailingUser, setEmailingUser] = useState(null);
  const [viewMode, setViewMode] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [activeLink, setActiveLink] = useState("All Home Owners");

  const links = [
    "All Home Owners",
    "New Users",
    "Admins",
    "Roles Not Assigned",
    "Unit Information Not Assigned",
  ];

  useEffect(() => {
    const getUsers = async () => {
      getAllDocsSnap("users", (users) => {
        const sortedUsers = users.sort((a, b) =>
          a.displayName?.localeCompare(b.displayName)
        );
        setUsers(sortedUsers);
        setFilteredUsers(sortedUsers);
        setIsLoading(false);
      });
    };
    getUsers();
  }, []);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  const newUsers = users.filter((user) => user.roles === undefined);
  const newUserCount = newUsers.length;
  setNotificationCount(newUserCount);

  const selectUserForEditing = (id) => {
    setSelectedUserForEdit(id);

    if (selectedUserForEdit === id) {
      setSelectedUserForEdit(null);
    }
  };

  const handleSelect = (userid, selectedOption) => {
    let roles = [];
    selectedOption.map((option) => {
      roles.push(option.value);
    });
    setDocData("users", userid, { roles: roles });
  };

  const handleSelectDepartment = (userid, selectedOption) => {
    setDocData("users", userid, { department: selectedOption.value });
  };

  const handleSearch = (e) => {
    const search = e.target.value.toLowerCase();
    const filtered = users.filter((user) =>
      user.displayName?.toLowerCase().includes(search)
    );
    setFilteredUsers(filtered);
  };

  const handleEmailConfirmation = async (email, name) => {
    setEmailInProgress(true);
    setEmailingUser(email);
    try {
      await fetch("https://talieleghoemailsender.vercel.app/api/subscribe", {
        method: "POST",
        body: JSON.stringify({ name, email }),
      });
      await setDocData("users", email, { emailSent: true });
      toast.success("Email sent successfully");
    } catch (error) {
      toast.error("Failed to send email");
    } finally {
      setEmailInProgress(false);
      setEmailingUser(null);
    }
  };

  const handleViewMode = (user) => {
    setSelectedUser(user);
    setViewMode(true);
  };

  const currentItems = filteredUsers.filter((user) => {
    if (activeLink === "All Home Owners") {
      return user.roles?.includes("user");
    } else if (activeLink === "New Users") {
      if (!user.createdAt) return false;
      const today = new Date();
      const createdAt = new Date(
        user.createdAt.seconds * 1000 + user.createdAt.nanoseconds / 1000000
      );
      const diffDays = Math.ceil(
        Math.abs(today - createdAt) / (1000 * 60 * 60 * 24)
      );
      return diffDays < 15;
    } else if (activeLink === "Roles Not Assigned") {
      return !user.roles || user.roles.length === 0;
    } else if (activeLink === "Unit Information Not Assigned") {
      return user.roles?.includes("user") && !user.linkedIdentifiers?.length;
    } else if (activeLink === "Admins") {
      return user.roles?.length > 0 && !user.roles.includes("user");
    }
    return false;
  });

  if (!currentUser.roles?.includes("super-admin")) {
    return (
      <div className="flex items-start justify-start h-[calc(100vh-4rem)] p-6">
        <div className="text-left">
          <h2 className="text-base font-bold text-[#363062] mb-2">
            Access Denied
          </h2>
          <p className="text-gray-600">
            Only Super Admins can access this page
          </p>
        </div>
      </div>
    );
  }

  if (isLoading) {
    return (
      <div className="flex items-start justify-start h-[calc(100vh-4rem)] p-6">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-[#363062]"></div>
      </div>
    );
  }

  return (
    <div className="p-6 mx-auto">
      <div className="mb-6">
        <h1 className="text-base font-bold text-[#363062] mb-4 text-left">
          User Management
        </h1>
        <div className="flex items-center gap-2 overflow-x-auto pb-2">
          {links.map((link) => (
            <NavigationTab
              key={link}
              label={link}
              active={activeLink === link}
              count={link === "New Users" ? notificationCount : 0}
              onClick={() => setActiveLink(link)}
            />
          ))}
        </div>
      </div>

      <div className="bg-white rounded-xl shadow-sm border border-gray-200">
        <div className="p-4 border-b border-gray-200">
          <div className="relative">
            <FaSearch className="absolute left-3 top-1/2 -translate-y-1/2 text-gray-400" />
            <input
              type="search"
              placeholder="Search users..."
              onChange={handleSearch}
              className="w-full pl-10 pr-4 py-2 rounded-lg border border-gray-200 focus:outline-none focus:ring-2 focus:ring-[#363062]/20 focus:border-[#363062]"
            />
          </div>
        </div>

        <div className="overflow-x-auto">
          <table className="w-full">
            <thead>
              <tr className="bg-gray-50">
                <th className="p-4 font-medium text-gray-600 text-left">
                  User
                </th>
                <th className="p-4 font-medium text-gray-600 text-left">
                  Department
                </th>
                <th className="p-4 font-medium text-gray-600 text-left">
                  Roles
                </th>
                <th className="p-4 font-medium text-gray-600 text-left">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody>
              {currentItems.map((user) => (
                <UserTableRow
                  key={user.id}
                  user={user}
                  selectedUserForEdit={selectedUserForEdit}
                  onEdit={(id) => selectUserForEditing(id)}
                  onView={(user) => handleViewMode(user)}
                  onEmailConfirmation={handleEmailConfirmation}
                  emailingUser={emailingUser}
                  emailInProgress={emailInProgress}
                  onSelectDepartment={handleSelectDepartment}
                  onSelectRoles={handleSelect}
                />
              ))}
            </tbody>
          </table>
        </div>
      </div>

      {viewMode && (
        <Modal>
          <div className="flex justify-end mb-4">
            <button
              onClick={() => setViewMode(false)}
              className="text-gray-500 hover:text-gray-700 text-left"
            >
              Close
            </button>
          </div>
          <UserDetails id={selectedUser.id} setViewMode={setViewMode} />
        </Modal>
      )}
    </div>
  );
}

export default UserAccess;
