import React, { useEffect } from "react";
import {
  addNewDoc,
  setDocData,
  deleteDocById,
} from "../../../utils/firebase/Firebase.utils";
import FacilityCard from "../../User/Facilities/FacilityCard";
import PageModal from "../Components/PageModal";
import NewFacilityForm from "./NewFacilityForm";

export default function FacilitySetup({ facilities }) {
  const [addMode, setAddMode] = React.useState(false);
  const [isProcessing, setIsProcessing] = React.useState(false);
  const [selectedFacility, setSelectedFacility] = React.useState(null);

  const handleViewMode = (facility) => {
    setSelectedFacility(facility);
    setAddMode(true);
  };

  return (
    <div className="text-left">
      <div className="header text-left">Facilities Setup</div>
      <button className="blue-button" onClick={() => setAddMode(true)}>
        Create New
      </button>

      <div className="flex items-center gap-4 flex-wrap">
        {facilities.map((facility, index) => (
          <div
            key={index}
            className="facility mt-4 w-64 cursor-pointer"
            onClick={() => handleViewMode(facility)}
          >
            <FacilityCard data={facility} />
          </div>
        ))}
      </div>

      {addMode && (
        <PageModal>
          <NewFacilityForm
            setAddMode={setAddMode}
            data={selectedFacility}
            setData={setSelectedFacility}
          />
        </PageModal>
      )}
    </div>
  );
}
