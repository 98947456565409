import React from "react";
import { IoMdCreate } from "react-icons/io";

export default function DirectMessaging() {
  return (
    <div className="relative min-h-[100vh]">
      <button className="blue-button flex items-center gap-2 mt-4">
        <IoMdCreate /> Create
      </button>
    </div>
  );
}
