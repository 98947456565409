import React, { useState, useRef } from "react";
import InputBox from "../Components/InputBox";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import {
  addNewDoc,
  setDocData,
  storage,
} from "../../../utils/firebase/Firebase.utils";
import { useSelector } from "react-redux";
import toast from "react-hot-toast";
import SelectInput from "../Components/SelectInput";
import { IoCloudUploadOutline } from "react-icons/io5";
import { motion } from "framer-motion";
import { FiSave, FiX, FiUpload, FiImage } from "react-icons/fi";

export default function AnnouncementForm({ data, setAddMode }) {
  const uploadingRef = useRef(null);
  const currentUser = useSelector((state) => state.userObject);
  const [formData, setFormData] = useState(data || {});
  const [file, setFile] = useState(null);
  const [progress, setProgress] = useState(0);
  const [isProcessing, setIsProcessing] = useState(false);

  const handleUpload = async (e) => {
    const file = e.target.files[0];
    if (!file) return;

    const storageRef = ref(storage, `announcements/${file.name}`);
    const uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setProgress(progress);
      },
      (error) => {
        toast.error("Upload failed. Please try again.");
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          setFormData((prev) => ({ ...prev, poster: downloadURL }));
          toast.success("Image uploaded successfully!");
        });
      }
    );
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSave = async (e) => {
    e.preventDefault();
    setIsProcessing(true);

    try {
      if (data) {
        await setDocData("announcements", data.id, formData);
        toast.success("Announcement updated successfully");
        setIsProcessing(false);
        setAddMode(false);
        return;
      }

      const docObject = {
        ...formData,
        createdAt: new Date().toLocaleString(),
        createdBy: currentUser.email,
        user: currentUser.uid,
        status: "unpublished",
      };

      await addNewDoc("announcements", docObject);
      toast.success("Announcement added successfully");
      setAddMode(false);
    } catch (error) {
      toast.error("An error occurred. Please try again.");
      setIsProcessing(false);
    }
  };

  const typeOptions = [
    { value: "announcements", label: "Announcements" },
    { value: "newsletters", label: "Newsletters" },
    { value: "events", label: "Events" },
    { value: "circulars", label: "Circulars" },
    { value: "advisory", label: "Advisory" },
    { value: "others", label: "Others" },
  ];

  return (
    <motion.div
      className="bg-white rounded-xl overflow-hidden w-full max-w-3xl"
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -20 }}
    >
      <div className="bg-[#00578e] p-6 text-white relative">
        <button
          className="absolute top-5 right-5 text-white hover:text-blue-200 transition-colors"
          onClick={() => setAddMode(false)}
          aria-label="Close form"
        >
          <FiX className="w-6 h-6" />
        </button>
        <h2 className="text-2xl font-bold">
          {data ? "Edit Announcement" : "Create Announcement"}
        </h2>
        <p className="text-blue-100 mt-1">
          {data
            ? "Update the announcement details below"
            : "Fill in the details to create a new announcement"}
        </p>
      </div>

      <div className="p-6 text-left">
        <form onSubmit={handleSave} className="space-y-6">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Type
              </label>
              <SelectInput
                name="type"
                label=""
                options={typeOptions}
                required
                onChange={(selected) =>
                  setFormData({ ...formData, type: selected.value })
                }
                value={typeOptions.find((item) => item.value === formData.type)}
                className="w-full"
              />
            </div>

            <div className="md:col-span-2">
              <InputBox
                name="title"
                label="Title"
                type="text"
                placeholder="Enter a descriptive title"
                required
                onChange={handleChange}
                value={formData.title || ""}
              />
            </div>

            <div className="md:col-span-2">
              <InputBox
                name="description"
                label="Short Description"
                type="text"
                placeholder="Enter a brief description"
                required
                onChange={handleChange}
                value={formData.description || ""}
              />
            </div>

            <div className="md:col-span-2">
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Detailed Content
              </label>
              <textarea
                className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-[#00578e] focus:border-[#00578e] transition-colors min-h-[150px]"
                name="Details"
                placeholder="Enter the full content of your announcement"
                onChange={handleChange}
                required
                value={formData.Details || ""}
              ></textarea>
            </div>

            <div className="md:col-span-2">
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Featured Image
              </label>
              <div
                className={`border-2 border-dashed rounded-lg transition-all cursor-pointer hover:border-[#00578e] focus:outline-none focus:ring-2 focus:ring-[#00578e] ${
                  formData?.poster ? "border-[#3CB371]" : "border-gray-300"
                }`}
                onClick={() => uploadingRef.current.click()}
              >
                {formData?.poster ? (
                  <div className="relative group">
                    <img
                      src={formData.poster}
                      alt="Preview"
                      className="w-full h-56 object-cover rounded-lg"
                    />
                    <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity rounded-lg">
                      <FiUpload className="text-white w-8 h-8" />
                    </div>
                  </div>
                ) : (
                  <div className="h-56 flex flex-col items-center justify-center p-6 text-center">
                    <FiImage className="w-12 h-12 text-gray-400 mb-2" />
                    <div className="text-gray-700 font-medium mb-1">
                      Drop your image here, or click to browse
                    </div>
                    <p className="text-gray-500 text-sm">
                      Supports JPG, PNG, GIF up to 10MB
                    </p>
                  </div>
                )}
              </div>

              <input
                type="file"
                ref={uploadingRef}
                onChange={handleUpload}
                name="poster"
                className="hidden"
                accept="image/*"
              />

              {progress > 0 && progress < 100 && (
                <div className="mt-2">
                  <div className="w-full bg-gray-200 rounded-full h-2.5">
                    <div
                      className="bg-[#00578e] h-2.5 rounded-full transition-all duration-300"
                      style={{ width: `${progress}%` }}
                    ></div>
                  </div>
                  <p className="text-xs text-gray-500 mt-1">{`Uploading: ${Math.round(
                    progress
                  )}%`}</p>
                </div>
              )}
            </div>
          </div>

          <div className="flex justify-end space-x-4 pt-4 border-t">
            <button
              type="button"
              onClick={() => setAddMode(false)}
              className="px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-[#00578e]"
            >
              Cancel
            </button>
            <button
              className="px-4 py-2 bg-[#00578e] text-white rounded-md shadow-sm hover:bg-[#004570] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#00578e] disabled:opacity-50 disabled:cursor-not-allowed flex items-center gap-2"
              type="submit"
              disabled={isProcessing}
            >
              <FiSave className="w-4 h-4" />
              <span>
                {isProcessing ? "Processing..." : data ? "Update" : "Create"}
              </span>
            </button>
          </div>
        </form>
      </div>
    </motion.div>
  );
}
