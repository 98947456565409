import React from "react";
import BlueNavHeader from "../pages/User/BlueNavHeader";

function MobileWrapper({ children, label }) {
  return (
    <div className="flex flex-col min-h-screen">
      <header>
        <BlueNavHeader label={label} />
      </header>
      <main className="flex-1 p-2 pt-4">{children}</main>
    </div>
  );
}

export default MobileWrapper;
