import React from "react";

export default function BillingPageDetails({ data, setViewMode }) {
  const sampleObject = {
    soaNumber: "2025-007",
    billingDate: "2025-01-01",
    description: "Outstanding balance from 2024",
    name: "Head, Bryan",
    billingType: "Dues",
    dueDate: "2025-01-31",
    amount: "21276",
    identifier: "Phase 2- Block 3- Lot 6",
    status: "unpaid",
    id: "1drgn1EA2QoEleFHADfb",
  };

  // Use data from props or fallback to sample data
  const billData = data || sampleObject;

  // Format amount with commas
  const formatAmount = (amount) => {
    return parseFloat(amount).toLocaleString("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };

  return (
    <div className="page relative text-gray-600 text-xs bg-white rounded-lg shadow-lg p-6 max-w-2xl mx-auto">
      <button
        className="absolute top-4 right-4 flex items-center justify-center bg-gray-100 hover:bg-gray-200 rounded-full w-8 h-8 transition-colors"
        onClick={() => setViewMode(false)}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-4 w-4"
          viewBox="0 0 20 20"
          fill="#363062"
        >
          <path
            fillRule="evenodd"
            d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
            clipRule="evenodd"
          />
        </svg>
      </button>

      <h1
        className="text-lg font-semibold text-center mb-6"
        style={{ color: "#363062" }}
      >
        Statement of Account
      </h1>

      <div className="grid grid-cols-2 gap-4 mb-6">
        <div className="flex flex-col">
          <span className="text-gray-400">SOA Number</span>
          <span className="font-medium">{billData.soaNumber}</span>
        </div>
        <div className="flex flex-col">
          <span className="text-gray-400">Billing Date</span>
          <span className="font-medium">{billData.billingDate}</span>
        </div>
        <div className="flex flex-col">
          <span className="text-gray-400">Name</span>
          <span className="font-medium">{billData.name}</span>
        </div>
        <div className="flex flex-col">
          <span className="text-gray-400">Property</span>
          <span className="font-medium">{billData.identifier}</span>
        </div>
      </div>

      <div
        className="p-4 rounded-md mb-6"
        style={{ backgroundColor: "rgba(54, 48, 98, 0.05)" }}
      >
        <div className="flex justify-between mb-2">
          <span className="text-gray-500">Description</span>
          <span className="font-medium">{billData.description}</span>
        </div>
        <div className="flex justify-between mb-2">
          <span className="text-gray-500">Billing Type</span>
          <span className="font-medium">{billData.billingType}</span>
        </div>
        <div className="flex justify-between mb-2">
          <span className="text-gray-500">Due Date</span>
          <span className="font-medium">{billData.dueDate}</span>
        </div>
      </div>

      <div className="flex flex-col items-end mb-6">
        <span className="text-gray-400 mb-1">Total Amount</span>
        <span className="text-base font-bold" style={{ color: "#363062" }}>
          ₱ {formatAmount(billData.amount)}
        </span>
      </div>

      <div className="flex justify-center">
        <div
          className={`px-4 py-1 rounded-full text-xs font-medium uppercase ${
            billData.status === "paid"
              ? "bg-green-100 text-green-800"
              : "bg-red-100 text-red-800"
          }`}
        >
          {billData.status}
        </div>
      </div>
    </div>
  );
}
