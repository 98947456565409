import React, { useEffect, useState } from "react";
import UserScreenWindow from "../UserScreenWindow";
import { getAllDocsSnap } from "../../../utils/firebase/Firebase.utils";
import StarRating from "./StarRating";
import { AnimatePresence, motion } from "framer-motion";
import FunctionRoomForm from "./FunctionRoomForm";
import { useParams } from "react-router-dom";

export default function FunctionRoomRequest({ data }) {
  const { id } = useParams();
  const [room, setRoom] = useState(data || {});
  const [loading, setLoading] = useState(true);
  const [viewMode, setViewMode] = useState(false);

  useEffect(() => {
    const fetchRoom = async () => {
      await getAllDocsSnap("facilities", (data) => {
        const filteredData = data.find((data) => data.id === id);
        setRoom(filteredData);
        setLoading(false);
      });
    };

    if (!data) {
      fetchRoom();
    } else {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    window.scrollTo(400, 400);
  }, [viewMode]);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <UserScreenWindow label={room.name}>
      <div className="relative">
        <div className=" -top-5 m-0">
          <img
            src={room.primaryPhoto}
            className="h-96 w-full object-cover"
            alt=""
          />
        </div>

        <div className=" bg-white rounded-3xl w-full relative bottom-10">
          <div className="p-4">
            <div className="font-bold text-eleghoBlue">
              Booking Request for {room.name}
            </div>

            <StarRating rating={room.rating || 5} />
            <div className="mt-4 space-y-2 text-sm text-gray-600">
              {Object.keys(room.Features).map((key, index) => (
                <div className="flex items-center justify-between" key={index}>
                  <div className="font-bold text-eleghoBlue">{key}:</div>
                  <div>{room.Features[key]}</div>
                </div>
              ))}
              <div>
                <div className="font-bold text-eleghoBlue">Features</div>
                {room.roomFeatures.map((feature, index) => (
                  <li className="list-disc" key={index}>
                    {feature}
                  </li>
                ))}
              </div>
              <div className="mt-4">
                <button
                  className="p-4 text-sm w-full bg-eleghoGreen rounded-2xl font-bold text-white"
                  onClick={() => setViewMode(true)}
                >
                  Check Availability
                </button>
              </div>
            </div>
          </div>
        </div>

        <AnimatePresence>
          {viewMode && (
            <motion.div
              initial={{ y: 500 }}
              animate={{ y: 0 }}
              exit={{ y: 500 }}
              transition={{ duration: 0.2 }}
              className="pb-8 rounded-2xl bg-gray-100 relative border border-gray-100 bottom-72 shadow-lg"
            >
              <button
                className="close-button"
                onClick={() => setViewMode(false)}
              >
                Close
              </button>
              <FunctionRoomForm facility={room.name} />
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </UserScreenWindow>
  );
}
