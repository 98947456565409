import React, { useEffect } from "react";
import UserScreenWindow from "./UserScreenWindow";
import { useParams } from "react-router-dom";
import { getDocData } from "../../utils/firebase/Firebase.utils";
import { useNavigate } from "react-router-dom";

function GatepassConfirmation() {
  const { id } = useParams();
  const [gatepass, setGatepass] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchGatepass = async () => {
      const data = await getDocData("gatePass", id);
      setGatepass(data);
      setIsLoading(false);
    };
    fetchGatepass();
  }, []);

  if (isLoading) {
    return (
      <UserScreenWindow label="Gate Pass Confirmation">
        <div className="flex justify-center items-center h-64">
          <div className="animate-pulse flex flex-col items-center">
            <div className="h-12 w-12 rounded-full bg-gray-200 mb-4"></div>
            <div className="text-sm text-[#363062]">
              Loading gate pass details...
            </div>
          </div>
        </div>
      </UserScreenWindow>
    );
  }

  const date = gatepass.requestDateTime.toDate().toLocaleDateString("en-US", {
    year: "numeric",
    month: "short",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  });

  return (
    <UserScreenWindow label="Gate Pass Confirmation">
      <div className="p-6 max-w-md mx-auto">
        <div className="bg-white rounded-lg shadow-lg overflow-hidden">
          <div className="bg-[#363062] text-white py-4 px-6">
            <h2 className="text-base font-medium text-center">
              Request submitted successfully
            </h2>
          </div>

          <div className="p-6 space-y-4">
            <div className="flex items-center justify-center h-16 w-16 mx-auto rounded-full bg-[#8FCD00]/20 border-4 border-[#8FCD00]">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-8 w-8 text-[#8FCD00]"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M5 13l4 4L19 7"
                />
              </svg>
            </div>

            <div className="text-center">
              <div className="text-sm text-gray-500 mb-1">Request Number</div>
              <div className="text-[#363062] font-medium tracking-wide">
                {id}
              </div>
            </div>

            <div className="text-center">
              <div className="text-sm text-gray-500 mb-1">Submission Time</div>
              <div className="text-[#363062] text-sm">{date}</div>
            </div>

            <div className="border-t border-gray-200 my-4 pt-4">
              <div className="text-xs text-gray-500 text-center mb-4">
                Your gate pass request has been successfully submitted and is
                being processed.
              </div>

              <button
                className="w-full bg-[#363062] hover:bg-[#363062]/90 transition-colors text-white rounded-md py-2.5 text-sm font-medium flex items-center justify-center gap-2"
                onClick={() => navigate("/dashboard")}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-4 w-4"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M15 19l-7-7 7-7"
                  />
                </svg>
                Back to Dashboard
              </button>
            </div>
          </div>
        </div>
      </div>
    </UserScreenWindow>
  );
}

export default GatepassConfirmation;
