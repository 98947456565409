import React, { useState, useEffect } from "react";
import MobileWrapper from "../../components/MobileWrapper";
import {
  addNewDoc,
  getAllDocsSnap,
  storage,
} from "../../utils/firebase/Firebase.utils";
import { useNavigate } from "react-router-dom";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { useSelector } from "react-redux";

export default function GuestForm() {
  const currentUser = useSelector((state) => state.userObject);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [residents, setResidents] = useState([]);
  const [unitSearchTerm, setUnitSearchTerm] = useState("");
  const [filteredUnits, setFilteredUnits] = useState([]);
  const [showUnitDropdown, setShowUnitDropdown] = useState(false);

 

  // Form state
  const [formData, setFormData] = useState({
    requestorName: "",
    date: "",
    time: "",
    linkedIdentifiers: [],
    purpose: "",
    status: "pending",
    requestDate: new Date().toISOString(),
    requestedBy: "",
    guests: [
      {
        name: "",
        plateNumber: "",
        email: "",
        identification: "",
        isUploading: false,
      },
    ],
    history: [
      {
        by: currentUser.email,
        at: new Date().toLocaleString(),
        action: "Created",
        to: "",
      },
    ],
    updatedAt: new Date().toLocaleString(),
  });

  // Color scheme
  const eleghoBlue = "#363062";
  const eleghoGreen = "#8FCD00";

  // Fetch residents data from Firestore
  useEffect(() => {
    const fetchResidents = async () => {
      await getAllDocsSnap("residents", (snap) => {
        setResidents(snap);
        console.log("Residents data:", snap);
      });
    };
    fetchResidents();
  }, []);

  // Filter units based on search term
  useEffect(() => {
    if (unitSearchTerm.trim() === "") {
      setFilteredUnits([]);
      return;
    }

    const term = unitSearchTerm.toLowerCase().trim();
    const filtered = residents.filter((resident) => {
      // Check if resident has identifier property
      if (!resident.identifier) return false;

      // If identifier is a string, search in it
      if (typeof resident.identifier === "string") {
        return resident.identifier.toLowerCase().includes(term);
      }

      // If identifier is an array, search in each element
      if (Array.isArray(resident.identifier)) {
        return resident.identifier.some((id) =>
          id.toLowerCase().includes(term)
        );
      }

      return false;
    });

    setFilteredUnits(filtered);
  }, [unitSearchTerm, residents]);

  // Handle basic form field changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // Handle unit search input
  const handleUnitSearch = (e) => {
    setUnitSearchTerm(e.target.value);
    setShowUnitDropdown(true);
  };

  // Handle unit selection from dropdown
  const handleUnitSelect = (identifier) => {
    if (identifier && !formData.linkedIdentifiers.includes(identifier)) {
      setFormData({
        ...formData,
        linkedIdentifiers: [...formData.linkedIdentifiers, identifier],
      });
    }
    setUnitSearchTerm("");
    setShowUnitDropdown(false);
  };

  // Remove a unit from the linkedIdentifiers array
  const handleRemoveUnit = (unit) => {
    setFormData({
      ...formData,
      linkedIdentifiers: formData.linkedIdentifiers.filter((u) => u !== unit),
    });
  };

  // Handle guest information changes
  const handleGuestChange = (index, field, value) => {
    const updatedGuests = [...formData.guests];
    updatedGuests[index] = {
      ...updatedGuests[index],
      [field]: value,
    };
    setFormData({
      ...formData,
      guests: updatedGuests,
    });
  };

  // Function to handle ID image upload
  const handleIdImageUpload = async (index, file) => {
    if (!file) return;

    try {
      // Show loading state for this specific guest
      const updatedGuests = [...formData.guests];
      updatedGuests[index] = {
        ...updatedGuests[index],
        isUploading: true,
      };
      setFormData({
        ...formData,
        guests: updatedGuests,
      });

      // Create a unique filename using timestamp and random string
      const timestamp = new Date().getTime();
      const randomString = Math.random().toString(36).substring(2, 8);
      const filename = `guests/${timestamp}_${randomString}_${file.name}`;

      // Create a reference to the file location in Firebase Storage
      const storageRef = ref(storage, filename);

      // Upload file
      await uploadBytes(storageRef, file);

      // Get download URL
      const downloadURL = await getDownloadURL(storageRef);

      // Update the guest with the identification URL
      const updatedGuestsWithUrl = [...formData.guests];
      updatedGuestsWithUrl[index] = {
        ...updatedGuestsWithUrl[index],
        identification: downloadURL,
        isUploading: false,
      };

      setFormData({
        ...formData,
        guests: updatedGuestsWithUrl,
      });
    } catch (error) {
      console.error("Error uploading ID image:", error);
      alert("Error uploading ID image. Please try again.");

      // Clear loading state
      const updatedGuests = [...formData.guests];
      updatedGuests[index] = {
        ...updatedGuests[index],
        isUploading: false,
      };
      setFormData({
        ...formData,
        guests: updatedGuests,
      });
    }
  };

  // Update handleAddGuest to include isUploading state
  const handleAddGuest = () => {
    setFormData({
      ...formData,
      guests: [
        ...formData.guests,
        {
          name: "",
          plateNumber: "",
          email: "",
          identification: "",
          isUploading: false,
        },
      ],
    });
  };

  // Remove a guest field
  const handleRemoveGuest = (index) => {
    if (formData.guests.length > 1) {
      const updatedGuests = formData.guests.filter((_, i) => i !== index);
      setFormData({
        ...formData,
        guests: updatedGuests,
      });
    }
  };

  // Submit form
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      // Validate form data
      if (
        !formData.requestorName ||
        !formData.date ||
        !formData.time ||
        formData.linkedIdentifiers.length === 0
      ) {
        alert("Please fill in all required fields");
        setIsLoading(false);
        return;
      }

      // Check if all guests have names
      const validGuests = formData.guests.filter(
        (guest) => guest.name.trim() !== ""
      );
      if (validGuests.length === 0) {
        alert("Please add at least one guest");
        setIsLoading(false);
        return;
      }

      // Only include guests with names
      const submitData = {
        ...formData,
        guests: validGuests,
        requestDate: new Date().toISOString(),
        updatedAt: new Date().toLocaleString(),
        history: [
          ...formData.history,
          {
            by: "System",
            at: new Date().toLocaleString(),
            action: "Submitted",
            to: "",
          },
        ],
      };

      // Submit to Firebase
      await addNewDoc("guests", submitData);
      alert("Guest registration request submitted successfully");
      navigate("/guests");
    } catch (error) {
      console.error("Error submitting guest request:", error);
      alert("Error submitting request. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  // Close dropdown when clicking outside
  const handleClickOutside = () => {
    setShowUnitDropdown(false);
  };

  return (
    <MobileWrapper label="New Guest Registration">
      <div className="pb-4">
        <form onSubmit={handleSubmit}>
          {/* Requestor Information */}
          <div className="mb-4">
            <div
              className="text-base font-semibold mb-2"
              style={{ color: eleghoBlue }}
            >
              Requestor Information
            </div>

            <div className="mb-3">
              <label className="block text-sm font-medium mb-1">
                Your Name *
              </label>
              <input
                type="text"
                name="requestorName"
                value={formData.requestorName}
                onChange={handleInputChange}
                className="w-full p-2 border rounded-md text-sm"
                style={{ borderColor: "#ddd" }}
                placeholder="Enter your full name"
                required
              />
            </div>

            <div className="mb-3">
              <label className="block text-sm font-medium mb-1">
                Email Address *
              </label>
              <input
                type="email"
                name="requestedBy"
                value={formData.requestedBy}
                onChange={handleInputChange}
                className="w-full p-2 border rounded-md text-sm"
                style={{ borderColor: "#ddd" }}
                placeholder="Enter your email address"
                required
              />
            </div>

            <div className="grid grid-cols-2 gap-3">
              <div>
                <label className="block text-sm font-medium mb-1">Date *</label>
                <input
                  type="date"
                  name="date"
                  value={formData.date}
                  onChange={handleInputChange}
                  className="w-full p-2 border rounded-md text-sm"
                  style={{ borderColor: "#ddd" }}
                  placeholder="Select date"
                  required
                />
              </div>
              <div>
                <label className="block text-sm font-medium mb-1">Time *</label>
                <input
                  type="time"
                  name="time"
                  value={formData.time}
                  onChange={handleInputChange}
                  className="w-full p-2 border rounded-md text-sm"
                  style={{ borderColor: "#ddd" }}
                  placeholder="Select time"
                  required
                />
              </div>
            </div>
          </div>

          {/* Unit Selection */}
          <div className="mb-4">
            <div
              className="text-base font-semibold mb-2"
              style={{ color: eleghoBlue }}
            >
              Unit Information
            </div>

            <div className="mb-3 relative">
              <label className="block text-sm font-medium mb-1">
                Search Unit *
              </label>
              <input
                type="text"
                value={unitSearchTerm}
                onChange={handleUnitSearch}
                onFocus={() => setShowUnitDropdown(true)}
                onBlur={() => setTimeout(handleClickOutside, 200)}
                className="w-full p-2 border rounded-md text-sm"
                style={{ borderColor: "#ddd" }}
                placeholder="Search by unit number or name"
              />

              {/* Dropdown for unit search results */}
              {showUnitDropdown && filteredUnits.length > 0 && (
                <div className="absolute z-10 w-full mt-1 max-h-48 overflow-auto bg-white border border-gray-300 rounded-md shadow-lg">
                  {filteredUnits.map((resident, index) => {
                    // Handle different identifier formats
                    const identifier =
                      typeof resident.identifier === "string"
                        ? resident.identifier
                        : Array.isArray(resident.identifier)
                        ? resident.identifier[0]
                        : "";

                    if (!identifier) return null;

                    return (
                      <div
                        key={index}
                        className="p-2 text-sm hover:bg-gray-100 cursor-pointer"
                        onClick={() => handleUnitSelect(identifier)}
                      >
                        {identifier}
                        {resident.name && ` - ${resident.name}`}
                      </div>
                    );
                  })}
                </div>
              )}

              {showUnitDropdown &&
                unitSearchTerm &&
                filteredUnits.length === 0 && (
                  <div className="absolute z-10 w-full mt-1 p-2 bg-white border border-gray-300 rounded-md shadow-lg text-sm">
                    No units found matching "{unitSearchTerm}"
                  </div>
                )}
            </div>

            {/* Display selected units */}
            {formData.linkedIdentifiers.length > 0 && (
              <div className="mb-3">
                <label className="block text-sm font-medium mb-1">
                  Selected Units:
                </label>
                <div className="flex flex-wrap gap-2">
                  {formData.linkedIdentifiers.map((unit) => (
                    <div
                      key={unit}
                      className="px-2 py-1 rounded-md text-white text-xs flex items-center gap-1"
                      style={{ backgroundColor: eleghoBlue }}
                    >
                      {unit}
                      <button
                        type="button"
                        onClick={() => handleRemoveUnit(unit)}
                        className="w-4 h-4 flex items-center justify-center rounded-full bg-white text-xs"
                        style={{ color: eleghoBlue }}
                      >
                        ×
                      </button>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>

          {/* Purpose of Visit */}
          <div className="mb-4">
            <label className="block text-sm font-medium mb-1">
              Purpose of Visit
            </label>
            <textarea
              name="purpose"
              value={formData.purpose}
              onChange={handleInputChange}
              className="w-full p-2 border rounded-md text-sm"
              style={{ borderColor: "#ddd" }}
              rows="2"
              placeholder="Please state the purpose of the visit"
            />
          </div>

          {/* Guest Information */}
          <div className="mb-4">
            <div
              className="text-base font-semibold mb-2"
              style={{ color: eleghoBlue }}
            >
              Guest Information
            </div>

            {formData.guests.map((guest, index) => (
              <div
                key={index}
                className="mb-3 p-3 border rounded-md"
                style={{ borderColor: "#eee" }}
              >
                <div className="flex justify-between items-center mb-2">
                  <div className="text-sm font-medium">
                    Visitor #{index + 1}
                  </div>
                  {formData.guests.length > 1 && (
                    <button
                      type="button"
                      onClick={() => handleRemoveGuest(index)}
                      className="text-xs text-red-500"
                    >
                      Remove
                    </button>
                  )}
                </div>

                <div className="mb-2">
                  <label className="block text-sm font-medium mb-1">
                    Guest Name *
                  </label>
                  <input
                    type="text"
                    value={guest.name}
                    onChange={(e) =>
                      handleGuestChange(index, "name", e.target.value)
                    }
                    className="w-full p-2 border rounded-md text-sm"
                    style={{ borderColor: "#ddd" }}
                    placeholder="Enter guest's full name"
                    required
                  />
                </div>

                <div className="mb-2">
                  <label className="block text-sm font-medium mb-1">
                    Email Address
                  </label>
                  <input
                    type="email"
                    value={guest.email}
                    onChange={(e) =>
                      handleGuestChange(index, "email", e.target.value)
                    }
                    className="w-full p-2 border rounded-md text-sm"
                    style={{ borderColor: "#ddd" }}
                    placeholder="Enter guest's email address (optional)"
                  />
                </div>

                <div className="mb-2">
                  <label className="block text-sm font-medium mb-1">
                    Vehicle Plate Number (if any)
                  </label>
                  <input
                    type="text"
                    value={guest.plateNumber}
                    onChange={(e) =>
                      handleGuestChange(index, "plateNumber", e.target.value)
                    }
                    className="w-full p-2 border rounded-md text-sm"
                    style={{ borderColor: "#ddd" }}
                    placeholder="Enter vehicle plate number (if applicable)"
                  />
                </div>

                <div>
                  <label className="block text-sm font-medium mb-1">
                    ID Upload
                  </label>
                  <div className="mt-1 flex items-center">
                    <input
                      type="file"
                      accept="image/*"
                      onChange={(e) =>
                        handleIdImageUpload(index, e.target.files[0])
                      }
                      className="hidden"
                      id={`idUpload-${index}`}
                      disabled={guest.isUploading}
                    />
                    <label
                      htmlFor={`idUpload-${index}`}
                      className="cursor-pointer px-3 py-2 border border-gray-300 rounded-md text-sm text-gray-700 hover:bg-gray-50"
                    >
                      {guest.isUploading ? "Uploading..." : "Choose file"}
                    </label>
                    {guest.identification && (
                      <span className="ml-3 text-xs text-green-600">
                        ID uploaded successfully
                      </span>
                    )}
                  </div>
                  {guest.identification && (
                    <div className="mt-2">
                      <img
                        src={guest.identification}
                        alt="ID Preview"
                        className="h-16 rounded border border-gray-300"
                      />
                    </div>
                  )}
                </div>
              </div>
            ))}

            <button
              type="button"
              onClick={handleAddGuest}
              className="w-full py-2 border border-dashed rounded-md text-sm"
              style={{ borderColor: eleghoGreen, color: eleghoGreen }}
            >
              + Add Another Guest
            </button>
          </div>

          {/* Submit Button */}
          <div className="mt-6">
            <button
              type="submit"
              className="w-full py-2 rounded-md text-white text-sm font-medium"
              style={{ backgroundColor: eleghoGreen }}
              disabled={isLoading}
            >
              {isLoading ? "Submitting..." : "Submit Guest Request"}
            </button>
          </div>

          <div className="mt-3">
            <button
              type="button"
              onClick={() => navigate("/guests")}
              className="w-full py-2 border rounded-md text-sm"
              style={{ borderColor: "#ddd", color: "#666" }}
              disabled={isLoading}
            >
              Cancel
            </button>
          </div>
        </form>
      </div>
    </MobileWrapper>
  );
}
