import React from "react";
import Select from "react-select";

function SelectInput({
  disabled,
  options,
  label,
  name,
  onChange,
  value,

  ...otherProps
}) {
  return (
    <div className="text-xs text-left">
      <label className="text-blue-900 text-xs capitalize font-medium text-left">
        {label}
      </label>
      <Select
        {...otherProps}
        options={options}
        name={name}
        onChange={onChange}
        value={value}
        editable={disabled}
        isDisabled={disabled}
      />
    </div>
  );
}

export default SelectInput;
