import React, { useEffect, useState } from "react";
import InputBox from "../Components/InputBox";
import {
  createNewDocument,
  getDocDataSnap,
  setDocData,
  deleteDocById,
} from "../../../utils/firebase/Firebase.utils";
import SelectInput from "../Components/SelectInput";
import toast from "react-hot-toast";
import {
  FiUser,
  FiHome,
  FiPhone,
  FiMail,
  FiMapPin,
  FiUsers,
  FiX,
} from "react-icons/fi";

function AddResidentForm({
  addMode,
  setAddMode,
  setViewMode,
  viewMode,
  resident,
}) {
  const [formData, setFormData] = useState({
    owner1: "",
    owner2: "",
    nationality: "",
    address: "",
    email: "",
    phoneNumber: "",
    phase: "",
    block: "",
    lot: "",
    unitType: "",
    lotSize: "",
    numberOfOccupants: "",
    status: "occupied",
  });
  const [isProcessing, setIsProcessing] = React.useState(false);
  const [editMode, setEditMode] = React.useState(false);
  const [disabled, setDisabled] = React.useState(false);

  useEffect(() => {
    const fetchResidentDetails = async () => {
      getDocDataSnap("residents", resident, (data) => {
        setFormData(data);
      });
    };

    if (viewMode) {
      fetchResidentDetails();
      setDisabled(true);
    }

    if (addMode) {
      setDisabled(false);
    }
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSelect = (e, name) => {
    setFormData((prev) => ({ ...prev, [name]: e.value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsProcessing(true);
    const dataObject = {
      ...formData,
      status: "vacant",
      identifier: `Unit ${formData.lot} - Tower ${formData.phase}`,
    };

    await createNewDocument("residents", dataObject);
    setFormData({});
    setIsProcessing(false);
    setAddMode(false);
    toast.success("Resident Added Successfully");
  };

  const options = [
    { value: "Residential", label: "Residential" },
    { value: "Commercial", label: "Commercial" },
  ];

  const handleEdit = () => {
    setEditMode(!editMode);
    setDisabled(!disabled);
  };

  const handleSubmitEdit = async () => {
    setIsProcessing(true);
    const docObject = {
      ...formData,
      identifier: `Unit ${formData.lot} - Tower ${formData.phase}`,
    };
    await setDocData("residents", resident, docObject);
    setIsProcessing(false);
    setEditMode(false);
    setDisabled(true);
    toast.success("Changes Saved Successfully");
  };

  const handleDelete = async () => {
    if (window.confirm("Are you sure you want to delete this resident?")) {
      setIsProcessing(true);
      await deleteDocById("residents", resident);
      setIsProcessing(false);
      setViewMode(false);
      toast.success("Resident Deleted Successfully");
    } else {
      toast.error("Deletion Cancelled");
      return;
    }
  };

  return (
    <div className="p-4">
      <div className="flex items-center justify-between mb-4">
        <h2 className="text-lg font-semibold text-gray-800">
          {addMode ? "Add Resident" : "Resident Details"}
        </h2>
        <div className="flex items-center gap-2">
          <button
            onClick={() => setAddMode(false)}
            className="p-2 text-gray-500 hover:text-gray-700 hover:bg-gray-100 rounded-lg transition-colors duration-200"
            title="Close"
          >
            <FiX className="w-5 h-5" />
          </button>
          {!addMode && (
            <>
              <button
                onClick={handleEdit}
                className="text-sm text-eleghoBlue hover:underline"
              >
                {editMode ? "Cancel Edit" : "Edit"}
              </button>
              <button
                onClick={handleDelete}
                className="text-sm text-red-600 hover:underline"
              >
                Delete
              </button>
            </>
          )}
        </div>
      </div>

      <form onSubmit={handleSubmit} className="space-y-4">
        {/* Basic Information Section */}
        <div className="bg-white rounded-lg p-3 sm:p-4 border border-gray-100">
          <h3 className="text-sm font-medium text-gray-700 mb-3">
            Basic Information
          </h3>
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-3 sm:gap-4">
            <div className="space-y-1">
              <label className="text-sm font-medium text-gray-600 text-left block">
                Owner 1
              </label>
              <div className="relative">
                <FiUser className="absolute left-2 top-1/2 transform -translate-y-1/2 text-gray-400 text-sm" />
                <input
                  type="text"
                  name="owner1"
                  value={formData.owner1}
                  onChange={handleChange}
                  className="w-full pl-8 pr-3 py-1.5 text-sm border border-gray-200 rounded-md focus:ring-1 focus:ring-eleghoBlue/20 focus:border-eleghoBlue outline-none"
                  placeholder="Enter owner 1 name"
                  required
                  disabled={disabled}
                />
              </div>
            </div>
            <div className="space-y-1">
              <label className="text-sm font-medium text-gray-600 text-left block">
                Nationality
              </label>
              <div className="relative">
                <FiUser className="absolute left-2 top-1/2 transform -translate-y-1/2 text-gray-400 text-sm" />
                <input
                  type="text"
                  name="nationality"
                  value={formData.nationality}
                  onChange={handleChange}
                  className="w-full pl-8 pr-3 py-1.5 text-sm border border-gray-200 rounded-md focus:ring-1 focus:ring-eleghoBlue/20 focus:border-eleghoBlue outline-none"
                  placeholder="Enter nationality"
                  required
                  disabled={disabled}
                />
              </div>
            </div>
            <div className="space-y-1">
              <label className="text-sm font-medium text-gray-600 text-left block">
                Owner 2
              </label>
              <div className="relative">
                <FiUser className="absolute left-2 top-1/2 transform -translate-y-1/2 text-gray-400 text-sm" />
                <input
                  type="text"
                  name="owner2"
                  value={formData.owner2}
                  onChange={handleChange}
                  className="w-full pl-8 pr-3 py-1.5 text-sm border border-gray-200 rounded-md focus:ring-1 focus:ring-eleghoBlue/20 focus:border-eleghoBlue outline-none"
                  placeholder="Enter owner 2 name"
                  disabled={disabled}
                />
              </div>
            </div>
            <div className="space-y-1">
              <label className="text-sm font-medium text-gray-600 text-left block">
                Address
              </label>
              <div className="relative">
                <FiMapPin className="absolute left-2 top-1/2 transform -translate-y-1/2 text-gray-400 text-sm" />
                <input
                  type="text"
                  name="address"
                  value={formData.address}
                  onChange={handleChange}
                  className="w-full pl-8 pr-3 py-1.5 text-sm border border-gray-200 rounded-md focus:ring-1 focus:ring-eleghoBlue/20 focus:border-eleghoBlue outline-none"
                  placeholder="Enter address"
                  required
                  disabled={disabled}
                />
              </div>
            </div>
            <div className="space-y-1">
              <label className="text-sm font-medium text-gray-600 text-left block">
                Email
              </label>
              <div className="relative">
                <FiMail className="absolute left-2 top-1/2 transform -translate-y-1/2 text-gray-400 text-sm" />
                <input
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  className="w-full pl-8 pr-3 py-1.5 text-sm border border-gray-200 rounded-md focus:ring-1 focus:ring-eleghoBlue/20 focus:border-eleghoBlue outline-none"
                  placeholder="Enter email"
                  required
                  disabled={disabled}
                />
              </div>
            </div>
            <div className="space-y-1">
              <label className="text-sm font-medium text-gray-600 text-left block">
                Phone Number
              </label>
              <div className="relative">
                <FiPhone className="absolute left-2 top-1/2 transform -translate-y-1/2 text-gray-400 text-sm" />
                <input
                  type="tel"
                  name="phoneNumber"
                  value={formData.phoneNumber}
                  onChange={handleChange}
                  className="w-full pl-8 pr-3 py-1.5 text-sm border border-gray-200 rounded-md focus:ring-1 focus:ring-eleghoBlue/20 focus:border-eleghoBlue outline-none"
                  placeholder="Enter phone number"
                  required
                  disabled={disabled}
                />
              </div>
            </div>
          </div>
        </div>

        {/* Ownership Information Section */}
        <div className="bg-white rounded-lg p-3 sm:p-4 border border-gray-100">
          <h3 className="text-sm font-medium text-gray-700 mb-3">
            Ownership Information
          </h3>
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-3 sm:gap-4">
            <div className="space-y-1">
              <label className="text-sm font-medium text-gray-600 text-left block">
                Tower Number
              </label>
              <div className="relative">
                <FiHome className="absolute left-2 top-1/2 transform -translate-y-1/2 text-gray-400 text-sm" />
                <input
                  type="text"
                  name="phase"
                  value={formData.phase}
                  onChange={handleChange}
                  className="w-full pl-8 pr-3 py-1.5 text-sm border border-gray-200 rounded-md focus:ring-1 focus:ring-eleghoBlue/20 focus:border-eleghoBlue outline-none"
                  placeholder="Enter tower number"
                  required
                  disabled={disabled}
                />
              </div>
            </div>
            <div className="space-y-1">
              <label className="text-sm font-medium text-gray-600 text-left block">
                Floor Number
              </label>
              <div className="relative">
                <FiHome className="absolute left-2 top-1/2 transform -translate-y-1/2 text-gray-400 text-sm" />
                <input
                  type="text"
                  name="block"
                  value={formData.block}
                  onChange={handleChange}
                  className="w-full pl-8 pr-3 py-1.5 text-sm border border-gray-200 rounded-md focus:ring-1 focus:ring-eleghoBlue/20 focus:border-eleghoBlue outline-none"
                  placeholder="Enter floor number"
                  required
                  disabled={disabled}
                />
              </div>
            </div>
            <div className="space-y-1">
              <label className="text-sm font-medium text-gray-600 text-left block">
                Unit Number
              </label>
              <div className="relative">
                <FiHome className="absolute left-2 top-1/2 transform -translate-y-1/2 text-gray-400 text-sm" />
                <input
                  type="text"
                  name="lot"
                  value={formData.lot}
                  onChange={handleChange}
                  className="w-full pl-8 pr-3 py-1.5 text-sm border border-gray-200 rounded-md focus:ring-1 focus:ring-eleghoBlue/20 focus:border-eleghoBlue outline-none"
                  placeholder="Enter unit number"
                  required
                  disabled={disabled}
                />
              </div>
            </div>
            <div className="space-y-1">
              <label className="text-sm font-medium text-gray-600 text-left block">
                Property Type
              </label>
              <SelectInput
                options={options}
                name="unitType"
                onChange={(e) => handleSelect(e, "unitType")}
                disabled={disabled}
                value={options.find(
                  (option) =>
                    option.value?.toLowerCase() ===
                    formData.unitType?.toLowerCase()
                )}
              />
            </div>
            <div className="space-y-1">
              <label className="text-sm font-medium text-gray-600 text-left block">
                Unit Size (sqm)
              </label>
              <div className="relative">
                <FiHome className="absolute left-2 top-1/2 transform -translate-y-1/2 text-gray-400 text-sm" />
                <input
                  type="number"
                  name="lotSize"
                  value={formData.lotSize}
                  onChange={handleChange}
                  className="w-full pl-8 pr-3 py-1.5 text-sm border border-gray-200 rounded-md focus:ring-1 focus:ring-eleghoBlue/20 focus:border-eleghoBlue outline-none"
                  placeholder="Enter unit size"
                  required
                  disabled={disabled}
                />
              </div>
            </div>
            <div className="space-y-1">
              <label className="text-sm font-medium text-gray-600 text-left block">
                Number of Occupants
              </label>
              <div className="relative">
                <FiUsers className="absolute left-2 top-1/2 transform -translate-y-1/2 text-gray-400 text-sm" />
                <input
                  type="number"
                  name="numberOfOccupants"
                  value={formData.numberOfOccupants}
                  onChange={handleChange}
                  className="w-full pl-8 pr-3 py-1.5 text-sm border border-gray-200 rounded-md focus:ring-1 focus:ring-eleghoBlue/20 focus:border-eleghoBlue outline-none"
                  placeholder="Enter number of occupants"
                  required
                  disabled={disabled}
                />
              </div>
            </div>
          </div>
        </div>

        {/* Form Actions */}
        <div className="flex flex-col sm:flex-row justify-end gap-2 sm:gap-3">
          <button
            type="button"
            onClick={() => setAddMode(false)}
            className="w-full sm:w-auto px-4 py-1.5 text-sm font-medium text-gray-600 bg-gray-100 rounded-md hover:bg-gray-200 transition-colors duration-200"
          >
            Cancel
          </button>
          {addMode && (
            <button
              type="submit"
              className="w-full sm:w-auto px-4 py-1.5 text-sm font-medium text-white bg-eleghoBlue rounded-md hover:bg-eleghoBlue/90 transition-colors duration-200"
            >
              {isProcessing ? "Processing..." : "Add Resident"}
            </button>
          )}
          {editMode && (
            <button
              type="button"
              onClick={handleSubmitEdit}
              className="w-full sm:w-auto px-4 py-1.5 text-sm font-medium text-white bg-eleghoBlue rounded-md hover:bg-eleghoBlue/90 transition-colors duration-200"
            >
              {isProcessing ? "Processing..." : "Save Changes"}
            </button>
          )}
        </div>
      </form>
    </div>
  );
}

export default AddResidentForm;
