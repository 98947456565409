import React from "react";

export default function CharacterCounter({ limit, count }) {
  return (
    <div>
      <div>
        {count} / {limit}
      </div>
    </div>
  );
}
