import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  getAllDocsSnap,
  getDocDataSnap,
  setDocData,
} from "../../../utils/firebase/Firebase.utils";
import FacilityRequestCard from "./FacilityRequestCard";
import PageModal from "../Components/PageModal";
import FacilityReqPage from "./FacilityReqPage";
import { convertToDateOnly } from "../../../utils/EleghoUtils";
import toast from "react-hot-toast";

export default function FacilityDetailPage() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [facility, setFacility] = useState(null);
  const [requests, setRequests] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [viewMode, setViewMode] = useState(false);
  const [selectedRequest, setSelectedRequest] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [activeFilter, setActiveFilter] = useState("All");

  const fetchRequests = async () => {
    await getAllDocsSnap("bookings", (data) => {
      // Filter requests for this facility and sort by date
      const facilityRequests = data
        .filter((request) => request.bookingType === facility?.name)
        .sort((a, b) => b.dateSubmitted - a.dateSubmitted);
      setRequests(facilityRequests);
    });
  };

  useEffect(() => {
    const fetchFacility = async () => {
      await getDocDataSnap("facilities", id, (data) => {
        if (!data) {
          toast.error("Facility not found");
          navigate("/facilities");
          return;
        }
        setFacility(data);
        setIsLoading(false);
      });
    };

    fetchFacility();
  }, [id]);

  useEffect(() => {
    if (facility) {
      fetchRequests();
    }
  }, [facility]);

  const filters = ["Pending", "Approved", "Rejected", "All"];

  const filteredRequests = requests.filter(
    (request) =>
      request.status?.toLowerCase().includes(activeFilter?.toLowerCase()) ||
      request.id?.toLowerCase().includes(searchTerm?.toLowerCase()) ||
      request.user?.toLowerCase().includes(searchTerm?.toLowerCase())
  );

  const handleViewMode = (selected) => {
    setSelectedRequest(selected);
    setViewMode(true);
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleStatusUpdate = async (requestId, newStatus) => {
    try {
      await setDocData("bookings", requestId, {
        status: newStatus,
        dateUpdated: new Date().getTime(),
      });
      toast.success(`Request ${newStatus.toLowerCase()}`);
    } catch (error) {
      toast.error("Failed to update request status");
    }
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="p-2 text-blue-900 space-y-4 text-xs max-w-7xl mx-auto">
      <div className="flex items-center justify-between">
        <div className="font-bold text-xl">{facility?.name}</div>
        <button
          onClick={() => navigate("/facilities")}
          className="text-eleghoBlue hover:text-eleghoGreen"
        >
          Back to Facilities
        </button>
      </div>

      {/* Facility Details */}
      <div className="bg-white rounded-lg shadow-sm p-4">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div>
            <img
              src={facility?.primaryPhoto}
              alt={facility?.name}
              className="w-full h-64 object-cover rounded-lg"
            />
          </div>
          <div>
            <h3 className="font-bold text-lg mb-2">Description</h3>
            <p className="text-gray-600">{facility?.description}</p>

            <h3 className="font-bold text-lg mt-4 mb-2">Features</h3>
            <ul className="list-disc list-inside text-gray-600">
              {facility?.roomFeatures?.map((feature, index) => (
                <li key={index}>{feature}</li>
              ))}
            </ul>

            <h3 className="font-bold text-lg mt-4 mb-2">Additional Details</h3>
            <div className="grid grid-cols-2 gap-2 text-gray-600">
              {Object.entries(facility?.Features || {}).map(([key, value]) => (
                <div key={key}>
                  <span className="font-semibold">{key}:</span> {value}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      {/* Requests Section */}
      <div className="mt-8">
        <div className="flex items-center justify-between mb-4">
          <div className="font-bold text-lg">Booking Requests</div>
          <div className="flex items-center space-x-4">
            <select
              value={activeFilter}
              onChange={(e) => setActiveFilter(e.target.value)}
              className="p-2 rounded-lg border-2 border-gray-300"
            >
              {filters.map((filter) => (
                <option key={filter} value={filter}>
                  {filter}
                </option>
              ))}
            </select>
            <input
              type="text"
              placeholder="Search by Name, Request No."
              className="p-2 rounded-lg border-2 border-gray-300"
              onChange={handleSearch}
            />
          </div>
        </div>

        <div className="space-y-2">
          {filteredRequests.map((request, index) => (
            <div key={index} onClick={() => handleViewMode(request)}>
              <FacilityRequestCard data={request} />
            </div>
          ))}
        </div>
      </div>

      {viewMode && (
        <PageModal>
          <FacilityReqPage
            data={selectedRequest}
            setViewMOde={setViewMode}
            onStatusUpdate={handleStatusUpdate}
          />
        </PageModal>
      )}
    </div>
  );
}
