import React from "react";
import { motion } from "framer-motion";

function ModalVariant2({ children }) {
  return (
    <div className="absolute top-0 left-0 w-full h-[200vh] bg-black bg-opacity-50 z-50 flex items-start justify-center p-4">
      <motion.div
        initial={{ opacity: 0, y: 100 }}
        animate={{ opacity: 1, y: 0 }}
        className="bg-white rounded-xl overflow-hidden"
      >
        {children}
      </motion.div>
    </div>
  );
}

export default ModalVariant2;