import React from "react";

export default function WorkPermitCards({ application }) {
  const { identifier, name, id, status, createdAt, duration, workPermitType } =
    application;

  const getStatusColor = (status) => {
    switch (status.toLowerCase()) {
      case "approved":
        return "bg-green-100 text-green-800";
      case "pending":
        return "bg-yellow-100 text-yellow-800";
      case "returned":
        return "bg-red-100 text-red-800";
      default:
        return "bg-gray-100 text-gray-800";
    }
  };

  return (
    <div className="hover:bg-gray-50 transition-colors duration-150 ease-in-out cursor-pointer">
      <div className="grid grid-cols-1 md:grid-cols-6 gap-2 p-4 text-sm">
        <div className="font-medium text-eleghoBlue">{identifier}</div>
        <div className="capitalize text-gray-700">{workPermitType}</div>
        <div className="text-gray-700">{name}</div>
        <div className="text-gray-600">{createdAt}</div>
        <div className="text-gray-700">{duration} days</div>
        <div className="flex justify-center">
          <span
            className={`px-2 py-1 rounded-full text-xs font-medium ${getStatusColor(
              status
            )}`}
          >
            {status}
          </span>
        </div>
      </div>
    </div>
  );
}
