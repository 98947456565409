import { useState, useEffect } from "react";
import { getAllDocsSnap } from "../../utils/firebase/Firebase.utils";
import WorkPermitCards from "./WorkPermits/WorkPermitCards";
import PageModal from "./Components/PageModal";
import WorkPermitDetails from "./WorkPermits/WorkPermitDetails";
import BentoTable from "../../components/BentoTable";
import { slicedData } from "../../utils/EleghoUtils";
import { useCompany } from "../../context/CompanyContext";
import CompanyTerms from "../../components/CompanyTerms";
import {
  FiFileText,
  FiClock,
  FiCheckCircle,
  FiAlertTriangle,
} from "react-icons/fi";

function WorkPermits() {
  const [activeFilter, setActiveFilter] = useState("Pending");
  const [workPermits, setWorkPermits] = useState([]);
  const [filteredWorkPermitApplications, setFilteredWorkPermitApplications] =
    useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedApplication, setSelectedApplication] = useState(null);
  const [viewMode, setViewMode] = useState(false);
  const [numberOfItemPerPage, setNumberOfItemPerPage] = useState(5);
  const [currentPage, setCurrentPage] = useState(1);
  const { terms } = useCompany();

  useEffect(() => {
    const fetchWorkPermits = async () => {
      await getAllDocsSnap("workPermits", (data) => {
        // arrange by createdAt
        data.sort((a, b) => {
          return new Date(b.createdAt) - new Date(a.createdAt);
        });

        setWorkPermits(data);
        setFilteredWorkPermitApplications(data);
        setIsLoading(false);
      });
    };

    fetchWorkPermits();
  }, []);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  const filters = ["All", "Pending", "Approved", "Returned"];

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const newFilteredWorkPermitApplications =
    filteredWorkPermitApplications.filter(
      (application) =>
        application.identifier
          .toLowerCase()
          .includes(searchTerm.toLowerCase()) ||
        application.name.toLowerCase().includes(searchTerm.toLowerCase())
    );

  const handleViewMode = (application) => {
    setViewMode(!viewMode);
    setSelectedApplication(application);
  };

  const dataSliced = slicedData(
    newFilteredWorkPermitApplications,
    numberOfItemPerPage,
    currentPage
  );

  const filteredItems = dataSliced.filter(
    (item) =>
      activeFilter === "All" ||
      item.status.toLowerCase() === activeFilter.toLowerCase()
  );

  // Calculate stats
  const stats = {
    totalPermits: workPermits.length,
    pendingPermits: workPermits.filter(
      (p) => p.status.toLowerCase() === "pending"
    ).length,
    approvedPermits: workPermits.filter(
      (p) => p.status.toLowerCase() === "approved"
    ).length,
    returnedPermits: workPermits.filter(
      (p) => p.status.toLowerCase() === "returned"
    ).length,
  };

  return (
    <div className="text-xs">
      <div className="text-sm font-bold text-blue-900 uppercase text-left">
        Request for Approvals and Work Permits
      </div>

      {/* Stats Cards */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 my-6">
        <div className="bg-white p-6 rounded-xl border border-gray-100 shadow-sm hover:shadow-md transition-shadow">
          <div className="flex items-center gap-3">
            <div className="p-2 bg-gray-50 rounded-lg">
              <FiFileText className="w-5 h-5 text-gray-600" />
            </div>
            <div>
              <div className="text-sm text-gray-600">Total Permits</div>
              <div className="text-2xl font-semibold text-gray-900">
                {stats.totalPermits}
              </div>
            </div>
          </div>
        </div>
        <div className="bg-white p-6 rounded-xl border border-gray-100 shadow-sm hover:shadow-md transition-shadow">
          <div className="flex items-center gap-3">
            <div className="p-2 bg-yellow-50 rounded-lg">
              <FiClock className="w-5 h-5 text-yellow-500" />
            </div>
            <div>
              <div className="text-sm text-gray-600">Pending Permits</div>
              <div className="text-2xl font-semibold text-yellow-500">
                {stats.pendingPermits}
              </div>
            </div>
          </div>
        </div>
        <div className="bg-white p-6 rounded-xl border border-gray-100 shadow-sm hover:shadow-md transition-shadow">
          <div className="flex items-center gap-3">
            <div className="p-2 bg-green-50 rounded-lg">
              <FiCheckCircle className="w-5 h-5 text-green-500" />
            </div>
            <div>
              <div className="text-sm text-gray-600">Approved Permits</div>
              <div className="text-2xl font-semibold text-green-500">
                {stats.approvedPermits}
              </div>
            </div>
          </div>
        </div>
        <div className="bg-white p-6 rounded-xl border border-gray-100 shadow-sm hover:shadow-md transition-shadow">
          <div className="flex items-center gap-3">
            <div className="p-2 bg-red-50 rounded-lg">
              <FiAlertTriangle className="w-5 h-5 text-red-500" />
            </div>
            <div>
              <div className="text-sm text-gray-600">Returned Permits</div>
              <div className="text-2xl font-semibold text-red-500">
                {stats.returnedPermits}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="flex items-center justify-between mb-4">
        <div className="flex items-center justify-start gap-4">
          {filters.map((filter, index) => (
            <div
              key={index}
              className={`cursor-pointer rounded-full px-4 py-1 text-sm border border-gray-300 ${
                activeFilter === filter
                  ? "bg-eleghoBlue text-white border-eleghoBlue"
                  : "bg-white text-gray-700 hover:bg-gray-50"
              }`}
              onClick={() => setActiveFilter(filter)}
            >
              {filter}
            </div>
          ))}
        </div>
        <div>
          <input
            type="search"
            name="search"
            className="border border-gray-300 px-4 py-2 w-60 rounded-lg text-sm"
            placeholder={`Search ${terms.identifier || "Lot No."} or Name`}
            onChange={handleSearch}
          />
        </div>
      </div>

      <BentoTable
        label="Work Permits"
        data={newFilteredWorkPermitApplications}
        numberOfItemPerPage={numberOfItemPerPage}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
      >
        <div className="divide-y divide-gray-100">
          {/* Table Header */}
          <div className="grid grid-cols-1 md:grid-cols-6 gap-2 font-semibold py-3 px-4 bg-gray-50 text-gray-700 text-xs rounded-t-lg">
            <div className="font-medium text-eleghoBlue">
              <CompanyTerms term="identifier" capitalize={true} />
            </div>
            <div className="capitalize text-gray-700">Type of Work</div>
            <div className="text-gray-700">
              <CompanyTerms term="owner" capitalize={true} /> Name
            </div>
            <div className="text-gray-600">Date Created</div>
            <div className="text-gray-700">Duration</div>
            <div className="flex justify-center">Status</div>
          </div>

          {filteredItems.map((application, index) => (
            <div
              className="cursor-pointer"
              key={index}
              onClick={() => handleViewMode(application)}
            >
              <WorkPermitCards application={application} />
            </div>
          ))}
        </div>
      </BentoTable>

      {viewMode && (
        <PageModal>
          <WorkPermitDetails
            application={selectedApplication}
            setViewMode={setViewMode}
          />
        </PageModal>
      )}
    </div>
  );
}

export default WorkPermits;
