import React, { useEffect } from "react";
import UserBar from "./UserBar";
import { getDocDataSnap } from "../../utils/firebase/Firebase.utils";
import Logo from "../assets/Elegho-Logo-general.png";
import { useDispatch } from "react-redux";
import { motion } from "framer-motion";
import { FiSearch } from "react-icons/fi";
import NotificationBadge from "../../components/NotificationBadge";

function Header() {
  const id = "7Dw6sBMQrHLSiHoirHdN";
  const [company, setCompany] = React.useState({});
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchCompanyData = async () => {
      await getDocDataSnap("company", id, (data) => {
        setCompany(data);
      });
    };
    fetchCompanyData();
  }, []);

  return (
    <motion.div
      initial={{ y: -10, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      className="bg-white border-b border-gray-200 w-full shadow-sm"
    >
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between h-20">
          {/* Logo Section */}
          <div className="flex items-center gap-6">
            <motion.div
              initial={{ scale: 0.9, opacity: 0 }}
              animate={{ scale: 1, opacity: 1 }}
              transition={{ delay: 0.1 }}
              className="flex items-center"
            >
              <img
                className="h-12 w-auto object-contain"
                src={company.companyLogo || Logo}
                alt="Company Logo"
              />
            </motion.div>

            {company.companyLogo && (
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ delay: 0.2 }}
                className="hidden lg:flex items-center gap-2"
              >
                <span className="text-xs text-gray-500">powered by</span>
                <img
                  className="h-8 w-auto object-contain"
                  src={Logo}
                  alt="Elegho Logo"
                />
              </motion.div>
            )}
          </div>

          {/* Search and Actions */}
          <div className="flex items-center gap-6">
            {/* Search Bar */}
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 0.3 }}
              className="hidden lg:flex items-center relative"
            >
              <input
                type="text"
                placeholder="Search..."
                className="w-[300px] pl-10 pr-4 py-2 rounded-lg bg-gray-50 border border-gray-200 focus:bg-white focus:border-blue-500 focus:ring-1 focus:ring-blue-500 transition-colors"
              />
              <FiSearch className="absolute left-3 top-1/2 -translate-y-1/2 text-gray-400" />
            </motion.div>

            {/* User Bar */}
            <motion.div
              initial={{ scale: 0.9, opacity: 0 }}
              animate={{ scale: 1, opacity: 1 }}
              transition={{ delay: 0.5 }}
            >
              <UserBar />
            </motion.div>
          </div>
        </div>
      </div>
    </motion.div>
  );
}

export default Header;
