import { QrCode } from "@mui/icons-material";
import React, { useEffect } from "react";
import { IoIosArrowBack } from "react-icons/io";
import { IoMdPersonAdd } from "react-icons/io";
import { RiFileHistoryLine } from "react-icons/ri";
import GuestCard from "./GuestCard";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { FiUsers, FiUserPlus, FiClock } from "react-icons/fi";
import { motion } from "framer-motion";
import UserScreenWindow from "./UserScreenWindow";

import {
  getDocDataSnap,
  getAllDocsSnap,
  setDocData,
  queryAllDocsByFieldSnap,
} from "../../utils/firebase/Firebase.utils";

function GuestRegistration() {
  const [guestRegistrations, setGuestRegistrations] = React.useState([]);
  const currentUser = useSelector((state) => state.userObject);

  useEffect(() => {
    const fetchGuestRegistrations = async () => {
      const guestRegistrations = await queryAllDocsByFieldSnap(
        "guests",
        "requestedBy",
        "==",
        currentUser.email,
        (data) => {
          //arrange by date
          const sortedData = data.sort((a, b) => {
            return new Date(b.date) - new Date(a.date);
          });
          setGuestRegistrations(sortedData);
        }
      );
    };
    fetchGuestRegistrations();
  }, []);

  const navigate = useNavigate();

  return (
    <UserScreenWindow label="Guest Registration">
      {/* Quick Actions */}
      <div className="space-y-3">
        <motion.div
          whileHover={{ scale: 1.01 }}
          whileTap={{ scale: 0.99 }}
          className="bg-white rounded-lg shadow-sm border border-gray-100 p-4 cursor-pointer active:bg-gray-50 transition-colors"
          onClick={() => navigate("/add-guest")}
        >
          <div className="flex items-center gap-3">
            <div className="p-2.5 bg-eleghoGreen/10 rounded-lg">
              <FiUserPlus className="w-5 h-5 text-eleghoGreen" />
            </div>
            <div>
              <h3 className="font-medium text-gray-900">Add New Guest</h3>
              <p className="text-xs text-gray-500">Register a new visitor</p>
            </div>
          </div>
        </motion.div>

        <motion.div
          whileHover={{ scale: 1.01 }}
          whileTap={{ scale: 0.99 }}
          className="bg-white rounded-lg shadow-sm border border-gray-100 p-4 cursor-pointer active:bg-gray-50 transition-colors"
          onClick={() => navigate("/guest-history")}
        >
          <div className="flex items-center gap-3">
            <div className="p-2.5 bg-eleghoBlue/10 rounded-lg">
              <FiClock className="w-5 h-5 text-eleghoBlue" />
            </div>
            <div>
              <h3 className="font-medium text-gray-900">Guest History</h3>
              <p className="text-xs text-gray-500">View past registrations</p>
            </div>
          </div>
        </motion.div>

        <motion.div
          whileHover={{ scale: 1.01 }}
          whileTap={{ scale: 0.99 }}
          className="bg-white rounded-lg shadow-sm border border-gray-100 p-4 cursor-pointer active:bg-gray-50 transition-colors"
        >
          <div className="flex items-center gap-3">
            <div className="p-2.5 bg-eleghoBlue/10 rounded-lg">
              <FiUsers className="w-5 h-5 text-eleghoBlue" />
            </div>
            <div>
              <h3 className="font-medium text-gray-900">Active Guests</h3>
              <p className="text-xs text-gray-500">
                {guestRegistrations.length} registered
              </p>
            </div>
          </div>
        </motion.div>
      </div>

      {/* Guest List */}
      <div className="mt-6">
        <div className="bg-white rounded-lg shadow-sm border border-gray-100 overflow-hidden">
          <div className="px-4 py-3 border-b border-gray-100">
            <h2 className="text-base font-semibold text-eleghoBlue">
              Guest Registration History
            </h2>
          </div>
          <div className="divide-y divide-gray-100">
            {guestRegistrations.map((guest, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, y: 10 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: index * 0.05 }}
                className="active:bg-gray-50 cursor-pointer transition-colors"
                onClick={() => navigate(`/guest-card-details/${guest.id}`)}
              >
                <GuestCard guestDetails={guest} />
              </motion.div>
            ))}
          </div>
        </div>
      </div>
    </UserScreenWindow>
  );
}

export default GuestRegistration;
