import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getDocDataSnap } from "../../../utils/firebase/Firebase.utils";
import UserScreenWindow from "../UserScreenWindow";
import {
  FiPackage,
  FiCalendar,
  FiClock,
  FiUser,
  FiTruck,
  FiHome,
  FiCheckCircle,
  FiXCircle,
  FiClock as FiClockIcon,
  FiChevronDown,
  FiChevronUp,
} from "react-icons/fi";
import { format } from "date-fns";

export default function Gatepassdetails() {
  const { id } = useParams();
  const [gatepass, setGatepass] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(true);
  const [displayCount, setDisplayCount] = useState(5);

  useEffect(() => {
    const fetchGatepass = async () => {
      await getDocDataSnap("gatePass", id, (data) => {
        setGatepass(data);
        setIsLoading(false);
      });
    };
    fetchGatepass();
  }, []);

  const handleShowMore = () => {
    setDisplayCount((prev) => prev + 5);
  };

  const handleShowLess = () => {
    setDisplayCount(5);
  };

  if (isLoading) {
    return (
      <UserScreenWindow label="Gatepass Details">
        <div className="flex items-center justify-center h-64">
          <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-eleghoBlue"></div>
        </div>
      </UserScreenWindow>
    );
  }

  if (!gatepass) {
    return (
      <UserScreenWindow label="Gatepass Details">
        <div className="flex flex-col items-center justify-center h-64">
          <FiXCircle className="text-4xl text-red-500 mb-4" />
          <p className="text-gray-600">Gatepass not found</p>
        </div>
      </UserScreenWindow>
    );
  }

  const getStatusColor = (status) => {
    switch (status) {
      case "approved":
        return "bg-eleghoGreen/10 text-eleghoGreen";
      case "pending":
        return "bg-yellow-100 text-yellow-600";
      case "rejected":
        return "bg-red-100 text-red-600";
      default:
        return "bg-gray-100 text-gray-600";
    }
  };

  const getStatusIcon = (status) => {
    switch (status) {
      case "approved":
        return <FiCheckCircle className="w-5 h-5" />;
      case "pending":
        return <FiClockIcon className="w-5 h-5" />;
      case "rejected":
        return <FiXCircle className="w-5 h-5" />;
      default:
        return null;
    }
  };

  const displayedHistory = gatepass.history.slice(0, displayCount);
  const hasMore = displayCount < gatepass.history.length;

  return (
    <UserScreenWindow label="Gatepass Details">
      <div className="max-w-2xl mx-auto space-y-6">
        {/* Header Section */}
        <div className="bg-white rounded-2xl shadow-sm border border-gray-100 p-5">
          <div className="flex items-center justify-between mb-4">
            <div>
              <h1 className="header">Gate Pass Request</h1>
              <div className="text-xs text-gray-400">{id}</div>
              <p className="text-xs text-gray-500 mt-0.5">Request Details</p>
            </div>
            <div
              className={`px-3 py-1.5 rounded-full flex items-center space-x-1.5 ${getStatusColor(
                gatepass.status
              )}`}
            >
              {getStatusIcon(gatepass.status)}
              <span className="font-medium capitalize text-xs">
                {gatepass.status}
              </span>
            </div>
          </div>

          <div className="grid grid-cols-2 gap-3">
            <div className="flex items-center space-x-2 p-2.5 bg-gray-50 rounded-lg">
              <FiCalendar className="w-4 h-4 text-eleghoBlue" />
              <div>
                <p className="text-xs text-gray-500">Delivery Date</p>
                <p className="text-sm font-medium text-gray-900">
                  {format(new Date(gatepass.deliveryDate), "MMMM d, yyyy")}
                </p>
              </div>
            </div>
            <div className="flex items-center space-x-2 p-2.5 bg-gray-50 rounded-lg">
              <FiClock className="w-4 h-4 text-eleghoBlue" />
              <div>
                <p className="text-xs text-gray-500">Delivery Time</p>
                <p className="text-sm font-medium text-gray-900">
                  {gatepass.deliveryTime}
                </p>
              </div>
            </div>
          </div>
        </div>

        {/* Details Section */}
        <div className="bg-white rounded-2xl shadow-sm border border-gray-100 p-5">
          <h2 className="text-base font-semibold text-gray-900 mb-3">
            Request Information
          </h2>
          <div className="space-y-3">
            <div className="flex items-center space-x-2 p-2.5 bg-gray-50 rounded-lg">
              <FiUser className="w-4 h-4 text-eleghoBlue" />
              <div>
                <p className="text-xs text-gray-500">Requested By</p>
                <p className="text-sm font-medium text-gray-900">
                  {gatepass.requestedBy}
                </p>
              </div>
            </div>

            <div className="flex items-center space-x-2 p-2.5 bg-gray-50 rounded-lg">
              <FiHome className="w-4 h-4 text-eleghoBlue" />
              <div>
                <p className="text-xs text-gray-500">Unit</p>
                <p className="text-sm font-medium text-gray-900">
                  {gatepass.identifier}
                </p>
              </div>
            </div>

            <div className="flex items-center space-x-2 p-2.5 bg-gray-50 rounded-lg">
              <FiTruck className="w-4 h-4 text-eleghoBlue" />
              <div>
                <p className="text-xs text-gray-500">Delivery Company</p>
                <p className="text-sm font-medium text-gray-900">
                  {gatepass.deliveryCompany}
                </p>
              </div>
            </div>

            <div className="flex items-center space-x-2 p-2.5 bg-gray-50 rounded-lg">
              <FiPackage className="w-4 h-4 text-eleghoBlue" />
              <div>
                <p className="text-xs text-gray-500">Items</p>
                <p className="text-sm font-medium text-gray-900">
                  {gatepass.items}
                </p>
              </div>
            </div>
          </div>
        </div>

        {/* History Timeline */}
        <div className="bg-white rounded-2xl shadow-sm border border-gray-100 p-5">
          <div className="flex items-center justify-between mb-3">
            <h2 className="text-base font-semibold text-gray-900">
              Status History
            </h2>
            <span className="text-xs text-gray-500">
              {gatepass.history.length} updates
            </span>
          </div>

          <div className="space-y-3">
            {displayedHistory.map((entry, index) => (
              <div key={index} className="flex items-start space-x-3">
                <div className="flex-shrink-0">
                  <div className="w-7 h-7 rounded-full bg-gray-100 flex items-center justify-center">
                    <FiUser className="w-3.5 h-3.5 text-gray-600" />
                  </div>
                </div>
                <div className="flex-1">
                  <div className="flex items-center justify-between mb-0.5">
                    <p className="text-xs font-medium text-gray-900">
                      {entry.action}{" "}
                      {entry.to && (
                        <span className="capitalize">{entry.to}</span>
                      )}
                    </p>
                    <span className="text-[10px] text-gray-500">
                      {entry.at}
                    </span>
                  </div>
                  <p className="text-[10px] text-gray-500">by {entry.by}</p>
                </div>
              </div>
            ))}
          </div>

          {hasMore && (
            <button
              onClick={handleShowMore}
              className="mt-3 w-full flex items-center justify-center space-x-1.5 text-eleghoBlue hover:text-eleghoBlue/80 transition-colors py-1.5"
            >
              <FiChevronDown className="w-3.5 h-3.5" />
              <span className="text-xs">Show More</span>
            </button>
          )}

          {displayCount > 5 && (
            <button
              onClick={handleShowLess}
              className="mt-2 w-full flex items-center justify-center space-x-1.5 text-eleghoBlue hover:text-eleghoBlue/80 transition-colors py-1.5"
            >
              <FiChevronUp className="w-3.5 h-3.5" />
              <span className="text-xs">Show Less</span>
            </button>
          )}
        </div>
      </div>
    </UserScreenWindow>
  );
}
