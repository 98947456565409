import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import BlankPhoto from "../assets/blankphoto.webp";
import {
  getDocDataSnap,
  setDocData,
  storage,
} from "../../utils/firebase/Firebase.utils";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";

function ProfileSettings() {
  const currentUser = useSelector((state) => state.userObject);
  const [formData, setFormData] = useState({});
  const [isProcessing, setIsProcessing] = useState(false);
  const [progress, setProgress] = useState(0);
  const [userData, setUserData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [isDragActive, setIsDragActive] = useState(false);

  useEffect(() => {
    const fetchUserData = async () => {
      await getDocDataSnap("users", currentUser.uid, (data) => {
        setUserData(data);
        setIsLoading(false);
      });
    };
    fetchUserData();
  }, []);

  const handleUpload = async (e) => {
    if (!e.target.files[0]) return;

    const file = e.target.files[0];
    await processUpload(file);
  };

  const processUpload = async (file) => {
    setIsProcessing(true);
    const storageRef = ref(storage, `userPhotos/${file.name}`);
    const uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setProgress(progress);
      },
      (error) => {
        console.error("Upload error:", error);
        setIsProcessing(false);
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then(async (downloadURL) => {
          await setDocData("users", currentUser.uid, {
            photoUrl: downloadURL,
          });
          setProgress(0);
          setIsProcessing(false);
        });
      }
    );
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    setIsDragActive(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    setIsDragActive(false);
  };

  const handleDrop = async (e) => {
    e.preventDefault();
    setIsDragActive(false);

    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      await processUpload(e.dataTransfer.files[0]);
    }
  };

  if (isLoading) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  return (
    <div className="max-w-4xl mx-auto px-4 py-8">
      <div className="bg-white rounded-2xl shadow-sm p-8">
        <h1 className="text-2xl font-bold text-gray-900 mb-8">
          Profile Settings
        </h1>

        <div className="grid md:grid-cols-[300px_1fr] gap-12">
          {/* Left Column - Photo Upload */}
          <div className="space-y-6">
            <div className="text-center">
              <div className="relative group mx-auto">
                <img
                  src={userData.photoUrl || BlankPhoto}
                  className="rounded-full w-48 h-48 object-cover mx-auto ring-4 ring-gray-50 shadow-lg"
                  alt={currentUser.displayName || "Profile photo"}
                />
                <div className="absolute inset-0 rounded-full bg-black bg-opacity-40 opacity-0 group-hover:opacity-100 transition-opacity flex items-center justify-center">
                  <span className="text-white text-sm font-medium">
                    Change Photo
                  </span>
                </div>
              </div>

              <div
                className={`mt-6 p-4 border-2 border-dashed rounded-lg transition-colors ${
                  isDragActive
                    ? "border-blue-500 bg-blue-50"
                    : "border-gray-300"
                }`}
                onDragOver={handleDragOver}
                onDragLeave={handleDragLeave}
                onDrop={handleDrop}
              >
                <input
                  type="file"
                  onChange={handleUpload}
                  className="hidden"
                  id="photo-upload"
                  accept="image/*"
                />
                <label
                  htmlFor="photo-upload"
                  className="cursor-pointer flex flex-col items-center space-y-2"
                >
                  <svg
                    className="w-8 h-8 text-gray-400"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z"
                    />
                  </svg>
                  <span className="text-sm text-gray-500">
                    Drag & drop or click to upload
                  </span>
                </label>
              </div>

              {progress > 0 && (
                <div className="mt-4">
                  <div className="w-full bg-gray-200 rounded-full h-2.5">
                    <div
                      className="bg-blue-500 h-2.5 rounded-full transition-all duration-300"
                      style={{ width: `${progress}%` }}
                    ></div>
                  </div>
                  <span className="text-sm text-gray-500 mt-2">
                    {Math.round(progress)}% uploaded
                  </span>
                </div>
              )}
            </div>
          </div>

          {/* Right Column - User Info */}
          <div className="space-y-6">
            <div className="border rounded-lg p-6 space-y-4 bg-gray-50">
              <div className="grid grid-cols-[120px_1fr] gap-4 items-center">
                <span className="text-sm font-medium text-gray-500">
                  Display Name
                </span>
                <span className="text-sm font-semibold text-gray-900">
                  {currentUser.displayName}
                </span>
              </div>
              <div className="grid grid-cols-[120px_1fr] gap-4 items-center">
                <span className="text-sm font-medium text-gray-500">Email</span>
                <span className="text-sm font-semibold text-gray-900">
                  {currentUser.email}
                </span>
              </div>
              <div className="grid grid-cols-[120px_1fr] gap-4 items-center">
                <span className="text-sm font-medium text-gray-500">Roles</span>
                <div className="flex gap-2 flex-wrap">
                  {currentUser.roles.map((role, index) => (
                    <span
                      key={index}
                      className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-blue-100 text-blue-800"
                    >
                      {role}
                    </span>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProfileSettings;
