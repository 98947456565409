import React, { useEffect, useState } from "react";
import {
  addNewDoc,
  getDocData,
  setDocData,
  storage,
} from "../../utils/firebase/Firebase.utils";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import NoImage from "../assets/noimageuploaded.png";

function GeneralSetup() {
  const [formData, setFormData] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [editMode, setEditMode] = useState(false);
  const [progress, setProgress] = useState(0);
  const [activeUpload, setActiveUpload] = useState("");

  const currentUser = useSelector((state) => state.userObject);
  const id = "7Dw6sBMQrHLSiHoirHdN";

  useEffect(() => {
    const fetchCompanyData = async () => {
      const data = await getDocData("company", id);
      setFormData(data);
      setIsLoading(false);
    };
    fetchCompanyData();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleUpload = async (e, fileName) => {
    e.preventDefault();
    const file = e.target.files[0];

    // Validate file type and size
    const validTypes = ["image/jpeg", "image/png", "image/gif"];
    if (!validTypes.includes(file.type)) {
      toast.error("Please upload an image file (JPEG, PNG, or GIF)");
      return;
    }

    if (file.size > 5 * 1024 * 1024) {
      toast.error("File size should be less than 5MB");
      return;
    }

    setIsSubmitting(true);
    const storageRef = ref(storage, `company/${id}/${fileName}`);
    const uploadTask = uploadBytesResumable(storageRef, file);
    setActiveUpload(fileName);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setProgress(progress);
      },
      (error) => {
        toast.error("Error uploading file");
        setIsSubmitting(false);
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          setFormData((prev) => ({ ...prev, [fileName]: downloadURL }));
          toast.success(`Logo uploaded successfully`);
          setIsSubmitting(false);
        });
      }
    );
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    try {
      const docObject = {
        ...formData,
        lastUpdated: new Date(),
        user: currentUser.email,
      };

      await setDocData("company", id, docObject);
      setEditMode(false);
      toast.success("Company information updated successfully");
    } catch (error) {
      toast.error("Failed to update company information");
    } finally {
      setIsSubmitting(false);
    }
  };

  if (isLoading) {
    return (
      <div className="min-h-[400px] flex items-center justify-center">
        <div className="animate-pulse text-gray-600">
          Loading company information...
        </div>
      </div>
    );
  }

  return (
    <div className="max-w-4xl mx-auto px-4 py-8">
      <div className="bg-white rounded-xl shadow-sm border border-gray-100 p-6">
        <div className="flex items-center justify-between mb-8">
          <h1 className="text-2xl font-semibold text-gray-800">
            Company Settings
          </h1>
          <button
            onClick={() => setEditMode(!editMode)}
            className={`px-4 py-2 rounded-lg text-sm font-medium transition-colors
              ${
                editMode
                  ? "bg-gray-100 text-gray-600 hover:bg-gray-200"
                  : "bg-blue-50 text-blue-600 hover:bg-blue-100"
              }`}
          >
            {editMode ? "Cancel" : "Edit Information"}
          </button>
        </div>

        <form onSubmit={handleSubmit} className="space-y-6">
          {/* Company Logo Section */}
          <div className="mb-8">
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Company Logo
            </label>
            <div className="flex items-start space-x-6">
              <div className="flex-shrink-0">
                <div className="h-32 w-32 rounded-lg border-2 border-gray-200 flex items-center justify-center overflow-hidden">
                  <img
                    src={formData.companyLogo || NoImage}
                    alt="Company logo"
                    className="object-cover h-full w-full"
                  />
                </div>
              </div>

              {editMode && (
                <div className="flex flex-col space-y-3">
                  <input
                    type="file"
                    onChange={(e) => handleUpload(e, "companyLogo")}
                    accept="image/*"
                    className="hidden"
                    id="logo-upload"
                  />
                  <label
                    htmlFor="logo-upload"
                    className="inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 cursor-pointer"
                  >
                    Upload new logo
                  </label>

                  {formData.companyLogo && (
                    <button
                      type="button"
                      onClick={() =>
                        setFormData((prev) => ({ ...prev, companyLogo: "" }))
                      }
                      className="text-sm text-red-600 hover:text-red-700"
                    >
                      Remove logo
                    </button>
                  )}

                  {activeUpload === "companyLogo" &&
                    progress > 0 &&
                    progress < 100 && (
                      <div className="w-full">
                        <div className="bg-gray-200 rounded-full h-2.5 w-44">
                          <div
                            className="bg-blue-600 h-2.5 rounded-full transition-all duration-300"
                            style={{ width: `${progress}%` }}
                          ></div>
                        </div>
                      </div>
                    )}
                </div>
              )}
            </div>
          </div>

          {/* Company Information Fields */}
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Company Name
              </label>
              <input
                type="text"
                name="companyName"
                value={formData.companyName || ""}
                onChange={handleChange}
                disabled={!editMode}
                className={`w-full rounded-lg border ${
                  editMode ? "border-gray-300" : "border-gray-200 bg-gray-50"
                } px-4 py-2.5 text-gray-700 focus:border-blue-500 focus:ring-blue-500`}
                placeholder="Enter company name"
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Email Address
              </label>
              <input
                type="email"
                name="companyEmail"
                value={formData.companyEmail || ""}
                onChange={handleChange}
                disabled={!editMode}
                className={`w-full rounded-lg border ${
                  editMode ? "border-gray-300" : "border-gray-200 bg-gray-50"
                } px-4 py-2.5 text-gray-700 focus:border-blue-500 focus:ring-blue-500`}
                placeholder="Enter company email"
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Phone Number
              </label>
              <input
                type="tel"
                name="companyPhone"
                value={formData.companyPhone || ""}
                onChange={handleChange}
                disabled={!editMode}
                className={`w-full rounded-lg border ${
                  editMode ? "border-gray-300" : "border-gray-200 bg-gray-50"
                } px-4 py-2.5 text-gray-700 focus:border-blue-500 focus:ring-blue-500`}
                placeholder="Enter phone number"
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Website
              </label>
              <input
                type="url"
                name="companyWebsite"
                value={formData.companyWebsite || ""}
                onChange={handleChange}
                disabled={!editMode}
                className={`w-full rounded-lg border ${
                  editMode ? "border-gray-300" : "border-gray-200 bg-gray-50"
                } px-4 py-2.5 text-gray-700 focus:border-blue-500 focus:ring-blue-500`}
                placeholder="Enter website URL"
              />
            </div>

            <div className="md:col-span-2">
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Address
              </label>
              <input
                type="text"
                name="companyAddress"
                value={formData.companyAddress || ""}
                onChange={handleChange}
                disabled={!editMode}
                className={`w-full rounded-lg border ${
                  editMode ? "border-gray-300" : "border-gray-200 bg-gray-50"
                } px-4 py-2.5 text-gray-700 focus:border-blue-500 focus:ring-blue-500`}
                placeholder="Enter company address"
              />
            </div>

            <div className="md:col-span-2">
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Description
              </label>
              <textarea
                name="companyDescription"
                value={formData.companyDescription || ""}
                onChange={handleChange}
                disabled={!editMode}
                rows={4}
                className={`w-full rounded-lg border ${
                  editMode ? "border-gray-300" : "border-gray-200 bg-gray-50"
                } px-4 py-2.5 text-gray-700 focus:border-blue-500 focus:ring-blue-500`}
                placeholder="Enter company description"
              />
            </div>
          </div>

          {editMode && (
            <div className="flex justify-end pt-6">
              <button
                type="submit"
                disabled={isSubmitting}
                className={`px-6 py-2.5 rounded-lg text-white font-medium transition-colors
                  ${
                    isSubmitting
                      ? "bg-blue-400 cursor-not-allowed"
                      : "bg-blue-600 hover:bg-blue-700"
                  }`}
              >
                {isSubmitting ? (
                  <span className="flex items-center space-x-2">
                    <svg
                      className="animate-spin h-5 w-5 text-white"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                      ></circle>
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      ></path>
                    </svg>
                    <span>Saving...</span>
                  </span>
                ) : (
                  "Save Changes"
                )}
              </button>
            </div>
          )}
        </form>
      </div>
    </div>
  );
}

export default GeneralSetup;
