import React from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { loadUser } from "../../store/userSlice";
import MobileWrapper from "../../components/MobileWrapper";

export default function ProfileSecurity() {
  const currentUser = useSelector((state) => state.userObject);
  const dispatch = useDispatch();
  const [userData, setUserData] = React.useState({});

  // Format roles from array to display string
  const getRolesDisplay = () => {
    if (!currentUser?.roles || !currentUser.roles.length)
      return "No roles assigned";
    return currentUser.roles.join(", ");
  };

  const handleLogout = () => {
    dispatch(loadUser({}));
  };

  return (
    <MobileWrapper className="bg-gray-50" label="User Profile">
      <div className="px-4 py-6">
        <div className="flex items-center mb-6">
          <div className="h-20 w-20 rounded-full bg-blue-500 flex items-center justify-center text-white text-2xl font-bold">
            {currentUser?.firstName?.[0] || currentUser?.email?.[0] || "U"}
          </div>
          <div className="ml-4">
            <h2 className="text-xl font-semibold">
              {currentUser?.firstName} {currentUser?.lastName}
            </h2>
            <p className="text-gray-600">{currentUser?.email}</p>
          </div>
        </div>

        <div className="bg-white rounded-lg shadow-sm p-4 mb-4">
          <h3 className="text-lg font-medium mb-3">Account Information</h3>
          <div className="space-y-3">
            <div className="flex justify-between border-b pb-2">
              <span className="text-gray-500">Email</span>
              <span>{currentUser?.email || "Not provided"}</span>
            </div>
            <div className="flex justify-between border-b pb-2">
              <span className="text-gray-500">Phone</span>
              <span>{currentUser?.phone || "Not provided"}</span>
            </div>
            <div className="flex justify-between">
              <span className="text-gray-500">Role</span>
              <span>{getRolesDisplay()}</span>
            </div>
          </div>
        </div>

        <div className="bg-white rounded-lg shadow-sm p-4 mb-6">
          <h3 className="text-lg font-medium mb-3">Security</h3>
          <button className="w-full py-2 text-blue-600 border border-blue-600 rounded-md mb-2">
            Change Password
          </button>
          <button className="w-full py-2 text-blue-600 border border-blue-600 rounded-md">
            Two-Factor Authentication
          </button>
        </div>

        <button
          className="w-full py-3 bg-red-500 text-white font-medium rounded-md hover:bg-red-600 transition-colors"
          onClick={handleLogout}
        >
          Log Out
        </button>
      </div>
    </MobileWrapper>
  );
}
