import React from "react";
import { useParams } from "react-router-dom";
import InputBox from "../Components/InputBox";
import SelectInput from "../Components/SelectInput";
import {
  getAllDocs,
  setDocData,
  storage,
} from "../../../utils/firebase/Firebase.utils";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { useEffect } from "react";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import Modal from "../Components/Modal";
import NoPhoto from "../../assets/blankphoto.webp";
import { useCompany } from "../../../context/CompanyContext";
import CompanyTerms from "../../../components/CompanyTerms";

export default function BasicInformation({ tenant }) {
  const { id } = useParams();
  const [formData, setFormData] = React.useState(tenant);
  const [disabled, setDisabled] = React.useState(true);
  const [searchTerm, setSearchTerm] = React.useState("");
  const [residents, setResidents] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(true);
  const [editMode, setEditMode] = React.useState(false);
  const [isProcesssing, setIsProcessing] = React.useState(false);
  const [progress, setProgress] = React.useState(0);

  const currentUser = useSelector((state) => state.userObject);
  const { terms, getCapitalizedTerm } = useCompany();

  useEffect(() => {
    const fetchResidents = async () => {
      const residents = await getAllDocs("residents");

      const unitNumbers = residents.map((resident) => {
        return {
          identifier: resident.identifier,
          value: resident.identifier,
          label: resident.owner1 + " - " + resident.identifier,
        };
      });

      //sort by identifier name
      unitNumbers.sort((a, b) => {
        if (a.identifier < b.identifier) {
          return -1;
        }
        if (a.identifier > b.identifier) {
          return 1;
        }
        return 0;
      });

      setResidents(unitNumbers);
      setIsLoading(false);
    };

    fetchResidents();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSearchChange = (e) => {
    const { value } = e.target;
    setFormData((prev) => ({ ...prev, lot: value }));
    setSearchTerm(value);
  };

  const handleResidentSelect = (e, resident) => {
    setFormData((prev) => ({ ...prev, lot: resident.value }));
    setSearchTerm("");
  };

  const filteredResidents = residents.filter(
    (resident) =>
      resident.label.toLowerCase().includes(searchTerm.toLowerCase()) ||
      resident.identifier?.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const leaseTypeOptions = [
    { label: "Personal Lease", value: "Personal Lease" },
    { label: "Corporate Lease", value: "Corporate Lease" },
  ];

  const handleSelectLeaseType = (e) => {
    setFormData((prev) => ({ ...prev, leaseType: e.value }));
  };

  const handleEditMode = () => {
    setEditMode(!editMode);
    setDisabled(!disabled);

    if (editMode) {
      if (
        window.confirm(
          "Are you sure you want to cancel? Changes will not be saved"
        )
      ) {
        setFormData(tenant);
        toast.success("Changes have been cancelled");
      }
    }
  };

  const handleSave = async (e) => {
    e.preventDefault();
    if (window.confirm("Are you sure you want to save changes?")) {
      setIsProcessing(true);
      const docObject = {
        ...formData,
        updatedAt: new Date().toLocaleString(),
        updatedBy: currentUser.email,
      };

      const res = await setDocData("tenants", id, docObject);

      if (res) {
        setIsProcessing(false);
        setDisabled(true);
        setEditMode(false);
        toast.success("Changes have been saved");
      }
    }
  };

  const allowableAccess = ["admin", "manager", "maker", "super-admin"];
  const userRoles = currentUser.roles;

  const handleUpload = (e) => {
    e.preventDefault();

    const file = e.target.files[0];

    if (!file) {
      toast.error("No file selected");
      return;
    }

    const storageRef = ref(storage, `tenants/${id}/primaryPhoto`);
    const uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setProgress(progress);
      },
      (error) => {},
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          setFormData((prev) => ({ ...prev, primaryPhoto: downloadURL }));
          setDocData("tenants", id, { primaryPhoto: downloadURL });
          toast.success("Photo Uploaded Successfully");
          setProgress(0);
        });
      }
    );
  };

  return (
    <div className="text-xs">
      {allowableAccess.some((role) => userRoles.includes(role)) ? (
        <button
          onClick={handleEditMode}
          className={editMode ? "underline text-xs mt-4" : "blue-button mt-4"}
        >
          {editMode ? "Cancel" : "Edit"}
        </button>
      ) : null}

      <div className="mt-4">
        <img
          src={tenant.primaryPhoto || NoPhoto}
          className="w-60 aspect-square object-cover object-top "
          alt=""
        />

        {editMode && (
          <div className="flex flex-col gap-2">
            <input type="file" onChange={handleUpload} />
            <div>
              <progress value={progress} max="100" />
            </div>
          </div>
        )}
      </div>
      <form action="" onSubmit={handleSave}>
        <div className="flex items-start gap-4 flex-wrap max-w-[1440px] bubble mt-4 px-4 py-8">
          <div className="w-[500px] space-y-4">
            <div className="font-bold mb-4">Basic Information</div>
            <InputBox
              label={"First Name"}
              type={"text"}
              placeholder={"First Name"}
              name="firstName"
              required
              onChange={handleChange}
              value={formData.firstName}
              disabled={disabled}
            />
            <InputBox
              label={"Last Name"}
              type={"text"}
              placeholder={"Last Name"}
              name="lastName"
              required
              onChange={handleChange}
              value={formData.lastName}
              disabled={disabled}
            />
            <InputBox
              label={"Address"}
              type={"text"}
              placeholder={"Address"}
              name="address"
              required
              onChange={handleChange}
              value={formData.address}
              disabled={disabled}
            />
            <InputBox
              label={"Email"}
              type={"email"}
              placeholder={"Email"}
              name="email"
              required
              onChange={handleChange}
              value={formData.email}
              disabled={disabled}
            />
            <InputBox
              label={"Phone Number"}
              type={"tel"}
              placeholder={"Phone Number"}
              name="phoneNumber"
              required
              onChange={handleChange}
              value={formData.phoneNumber}
              disabled={disabled}
            />
          </div>
          <div className="w-[500px] space-y-4">
            <div className="font-bold mb-4">Rent Information</div>
            <div className="relative">
              <InputBox
                name="lot"
                placeholder={getCapitalizedTerm("identifier")}
                label={getCapitalizedTerm("identifier")}
                type={"text"}
                required
                onChange={handleSearchChange}
                value={formData.lot}
                disabled={disabled}
              />
              {searchTerm && filteredResidents.length > 0 && (
                <ul className="bg-white p-2 rounded-xl border border-gray-200 shadow-lg absolute z-20">
                  {filteredResidents.map((resident, index) => (
                    <li
                      key={index}
                      onClick={(e) => handleResidentSelect(e, resident)}
                      className="cursor-pointer hover:bg-gray-200"
                    >
                      {resident.label}
                    </li>
                  ))}
                </ul>
              )}
            </div>
            <InputBox
              label={"Lease Start"}
              type={"date"}
              placeholder={"Lease Start"}
              name="leaseStart"
              required
              onChange={handleChange}
              value={formData.leaseStart}
              disabled={disabled}
            />
            <InputBox
              label={"Lease End"}
              type={"date"}
              placeholder={"Lease End"}
              name="leaseEnd"
              required
              onChange={handleChange}
              value={formData.leaseEnd}
              disabled={disabled}
            />
            <InputBox
              label={"Number of Occupants"}
              type={"number"}
              placeholder={"No. of Occupants"}
              name="occupants"
              required
              onChange={handleChange}
              value={formData.occupants}
              disabled={disabled}
            />
            <SelectInput
              label="Type of Lease"
              name="leaseType"
              options={leaseTypeOptions}
              onChange={handleSelectLeaseType}
              value={leaseTypeOptions.find(
                (option) =>
                  option.value?.toLowerCase() ===
                  formData.leaseType?.toLowerCase()
              )}
              disabled={disabled}
            />
          </div>
        </div>

        {editMode && (
          <div className="text-left mt-8">
            <button type="submit" className="w-full lg:w-fit blue-button">
              Save
            </button>
          </div>
        )}
      </form>

      {isProcesssing && (
        <Modal>
          <div className="header">Saving Changes...</div>
        </Modal>
      )}
    </div>
  );
}
