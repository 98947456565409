import React from "react";

export default function InputBoxVar2({ label, placeholder, ...otherProps }) {
  return (
    <div className="flex flex-col gap-2 text-eleghoBlue">
      <label className="font-semibold text-sm">{label}</label>
      <input
        type="text"
        placeholder={placeholder}
        className="p-2 text-sm border border-gray-200 rounded-md"
        {...otherProps}
      />
    </div>
  );
}
