import React, { useEffect } from "react";
import {
  addNewDoc,
  getAllDocsSnap,
  setDocData,
} from "../../utils/firebase/Firebase.utils";
import toast from "react-hot-toast";
import { parse } from "uuid";

function WaterMeterReading() {
  const [addMode, setAddMode] = React.useState(false);
  const [inProcess, setInProcess] = React.useState(false);
  const [billDate, setBillDate] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [lots, setLots] = React.useState([]);
  const [billRuns, setBillRuns] = React.useState([]);
  const [billRunSelected, setBillRunSelected] = React.useState({});

  useEffect(() => {
    const fetchBillRuns = async () => {
      await getAllDocsSnap("waterBills", (data) => {
        //filter status !== Deleted
        const filteredData = data.filter((run) => run.status !== "Deleted");
        setBillRuns(filteredData);
      });
    };
    fetchBillRuns();
  }, []);

  useEffect(() => {
    const fetchLots = async () => {
      await getAllDocsSnap("residents", (data) => {
        setLots(data);
        setLoading(false);
      });
    };
    fetchLots();
  }, []);

  const handleGenerateBills = () => {
    setAddMode(!addMode);
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  const lotMap = lots.map((lot) => {
    return {
      identifier: lot.identifier,
      owner: lot.owner1,
      lotSize: lot.lotSize,
      waterReading: "",
      billAmount: "",
    };
  });

  const handleCreateBillRun = async () => {
    setInProcess(true);
    const docObject = {
      billDate,
      lots: lotMap,
      status: "Draft",
      createdAt: new Date().toLocaleString(),
    };

    await addNewDoc("waterBills", docObject);
    toast.success("Bill Run Created Successfully");
    setInProcess(false);
  };

  const handleEditBillRun = (selected) => {
    setBillRunSelected(selected);
  };

  const handleComputeBill = (reading) => {
    const waterReading = parseFloat(reading);
    let billAmount = 0;

    if (waterReading <= 30) {
      billAmount = waterReading * 20;
    } else if (waterReading <= 40) {
      billAmount = waterReading * 22;
    } else if (waterReading <= 50) {
      billAmount = waterReading * 24;
    } else if (waterReading <= 60) {
      billAmount = waterReading * 26;
    } else if (waterReading <= 70) {
      billAmount = waterReading * 28;
    } else if (waterReading <= 80) {
      billAmount = waterReading * 30;
    } else if (waterReading <= 90) {
      billAmount = waterReading * 32;
    } else if (waterReading <= 100) {
      billAmount = waterReading * 34;
    } else if (waterReading > 100) {
      billAmount = waterReading * 36;
    } else {
      // Default case, which usually should not occur since reading is a number
      billAmount = 0;
    }

    return billAmount;
  };

  const handleWaterReadingChange = (lot, value) => {
    lot.waterReading = value;
    lot.billAmount = handleComputeBill(value);
    setLots([...lots]);
  };

  const handleSaveBillRun = async () => {
    setInProcess(true);
    const docObject = {
      ...billRunSelected,
      status: "Saved",
      savedAt: new Date().toLocaleString(),
      createdBy: "Admin",
    };

    await setDocData("waterBills", billRunSelected.id, docObject);
    setInProcess(false);
    toast.success("Bill Run Updated Successfully");
  };

  const handleSendBillings = async () => {
    setInProcess(true);
    for (let i = 0; i < billRunSelected.lots.length; i++) {
      const lot = billRunSelected.lots[i];
      const docObject = {
        identifier: lot.identifier,
        billingType: "Water",
        description: "Water Bill",
        //due date is 15 days after the run date
        dueDate: new Date(
          new Date(billRunSelected.billDate).getTime() +
            15 * 24 * 60 * 60 * 1000
        ).toLocaleString(),
        owner: lot.owner,
        billDate: billRunSelected.billDate,
        amount: parseFloat(lot.billAmount).toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }),
        status: "Unpaid",
        createdAt: new Date().toLocaleString(),
      };

      await addNewDoc("billings", docObject);
    }
    setInProcess(false);
    toast.success("Billings Sent Successfully");
    setBillRunSelected({});
  };

  const handleDelete = async (billRun) => {
    if (window.confirm("Are you sure you want to delete this bill run?")) {
      setInProcess(true);
      await setDocData("waterBills", billRun.id, { status: "Deleted" });
      setInProcess(false);
      toast.success("Bill Run Deleted Successfully");
    }
  };

  const handleReminder = async () => {
    toast.success("Reminders Sent Successfully");
  };

  return (
    <div className="p-2 text-blue-900">
      <div className="flex items-center justify-between">
        <div className="font-medium text-gray-900 lg:text-lg text-sm ">
          Water Meter Reading
        </div>
        <div className="flex items-center justify-normal gap-2">
          <button className="blue-button" onClick={handleGenerateBills}>
            Generate Water Bills
          </button>
          <button className="blue-button" onClick={handleReminder}>
            Send General Reminders
          </button>
        </div>
      </div>

      {addMode && (
        <div>
          <div>Select Bill Period</div>
          <input
            type="date"
            className="basic-input"
            name={"billDate"}
            onChange={(e) => setBillDate(e.target.value)}
          />
          <button className="blue-button" onClick={handleCreateBillRun}>
            {inProcess ? "Processing..." : "Create Bill Run"}
          </button>
        </div>
      )}

      <div>
        {billRuns.map((billRun, index) => (
          <div key={index} className="border-2 border-gray-300 p-2 mt-4">
            <div className="font-bold">Bill Run for {billRun.billDate}</div>
            <div className="flex items-center justify-between">
              <div>Bill Run Status: {billRun.status}</div>
              <div>Created At: {billRun.createdAt}</div>
            </div>
            <div className="flex items-center justify-between">
              <div>Bill Run for {billRun.billDate}</div>
              <div className="flex items-center justify-between gap-2">
                <button
                  className="blue-button"
                  onClick={() => handleEditBillRun(billRun)}
                >
                  Edit
                </button>
                <button
                  className="blue-button"
                  onClick={() => handleDelete(billRun)}
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>

      <div>
        {billRunSelected.lots && (
          <div>
            <div className="font-bold">
              Bill Run for {billRunSelected.billDate}
            </div>
            <table className="w-full mt-4">
              <thead>
                <tr>
                  <th>Identifier</th>
                  <th>Owner</th>
                  <th>Unit Size</th>
                  <th>Water Reading</th>
                  <th>Bill Amount</th>
                </tr>
              </thead>
              <tbody>
                {billRunSelected.lots.map((lot, index) => (
                  <tr key={index}>
                    <td>{lot.identifier}</td>
                    <td>{lot.owner}</td>
                    <td>{lot.lotSize}</td>
                    <td>
                      <input
                        type="number"
                        className="basic-input"
                        name={"waterReading"}
                        onChange={(e) =>
                          handleWaterReadingChange(lot, e.target.value)
                        }
                        value={lot.waterReading}
                      />
                    </td>
                    <td>
                      <input
                        type="number"
                        className="basic-input"
                        name={"billAmount"}
                        value={lot.billAmount}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>

            <div className="flex items-center gap-2">
              <button className="blue-button" onClick={handleSaveBillRun}>
                {inProcess ? "Processing..." : "Save Bill Run"}
              </button>
              <button className="blue-button" onClick={handleSendBillings}>
                {inProcess ? "Processing..." : "Send Billings"}
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default WaterMeterReading;
