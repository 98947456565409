import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  getDocDataSnap,
  setDocData,
} from "../../../utils/firebase/Firebase.utils";
import Modal from "../Components/Modal";
import AddResidentOccupantForm from "./AddResidentOccupantForm";
import { motion, AnimatePresence } from "framer-motion";
import {
  IoChevronDownOutline,
  IoPersonAddOutline,
  IoSearchOutline,
  IoClose,
} from "react-icons/io5";
import { FaRegUser } from "react-icons/fa";
import ResidentOccupantDetails from "./ResidentOccupantDetails";
import EmptyPage from "../Components/EmptyPage";

export default function OccupantsInformationPage() {
  const { id } = useParams();
  const [occupants, setOccupants] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [addMode, setAddMode] = useState(false);
  const [expandedId, setExpandedId] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    const fetchOccupants = async () => {
      await getDocDataSnap("residents", id, (data) => {
        setOccupants(data.occupantsName || []);
        setIsLoading(false);
      });
    };
    fetchOccupants();
  }, [id]);

  const handleAddMode = () => {
    setAddMode(!addMode);
  };

  const toggleAccordion = (occupantId) => {
    setExpandedId(expandedId === occupantId ? null : occupantId);
  };

  const filteredOccupants = occupants.filter((occupant) => {
    const fullName =
      `${occupant.firstName} ${occupant.middleName} ${occupant.lastName}`.toLowerCase();
    return (
      fullName.includes(searchTerm.toLowerCase()) ||
      (occupant.relationship &&
        occupant.relationship.toLowerCase().includes(searchTerm.toLowerCase()))
    );
  });

  if (isLoading) {
    return (
      <div className="flex items-center justify-center min-h-[200px]">
        <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-eleghoBlue"></div>
      </div>
    );
  }

  return (
    <div className="text-gray-600 text-left">
      {/* Header Section */}
      <div className="bg-white p-6 rounded-lg shadow-sm mb-6">
        <div className="flex flex-col md:flex-row md:items-center justify-between gap-4">
          <div>
            <h2 className="text-xl font-semibold text-gray-900">
              Occupants Information
            </h2>
            <p className="text-sm text-gray-500 mt-1">
              Manage and view all occupants details
            </p>
          </div>
          <button
            onClick={handleAddMode}
            className="inline-flex items-center px-4 py-2.5 text-sm font-medium text-white bg-eleghoBlue rounded-lg hover:bg-eleghoBlue/90 transition-colors duration-200 shadow-sm"
          >
            <IoPersonAddOutline className="w-5 h-5 mr-2" />
            Add New Occupant
          </button>
        </div>

        {/* Search Bar */}
        <div className="mt-6 relative">
          <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
            <IoSearchOutline className="h-5 w-5 text-gray-400" />
          </div>
          <input
            type="text"
            className="block w-full pl-10 pr-3 py-2.5 border border-gray-200 rounded-lg focus:ring-2 focus:ring-eleghoBlue/20 focus:border-eleghoBlue placeholder-gray-400 text-sm"
            placeholder="Search occupants by name or relationship..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>
      </div>

      {/* Occupants Accordion List */}
      <div className="space-y-4">
        {filteredOccupants.map((occupant, index) => (
          <div
            key={index}
            className="bg-white rounded-lg shadow-sm overflow-hidden border border-gray-100"
          >
            <button
              onClick={() => toggleAccordion(occupant.id)}
              className="w-full px-6 py-4 flex items-center justify-between hover:bg-gray-50 transition-colors duration-200"
            >
              <div className="flex items-center gap-4">
                <div className="w-12 h-12 rounded-full overflow-hidden bg-eleghoBlue/10 flex items-center justify-center">
                  {occupant.primaryPhoto ? (
                    <img
                      src={occupant.primaryPhoto}
                      alt={occupant.firstName}
                      className="w-full h-full object-cover"
                    />
                  ) : (
                    <FaRegUser className="w-5 h-5 text-eleghoBlue" />
                  )}
                </div>
                <div className="text-left">
                  <h3 className="text-base font-medium text-gray-900">
                    {occupant.firstName} {occupant.middleName}{" "}
                    {occupant.lastName}
                  </h3>
                  <p className="text-sm text-gray-500 mt-0.5">
                    {occupant.relationship || "Relationship not specified"}
                  </p>
                </div>
              </div>
              <motion.div
                animate={{ rotate: expandedId === occupant.id ? 180 : 0 }}
                transition={{ duration: 0.2 }}
              >
                <IoChevronDownOutline className="w-6 h-6 text-gray-400" />
              </motion.div>
            </button>

            <AnimatePresence>
              {expandedId === occupant.id && (
                <motion.div
                  initial={{ height: 0, opacity: 0 }}
                  animate={{ height: "auto", opacity: 1 }}
                  exit={{ height: 0, opacity: 0 }}
                  transition={{ duration: 0.2 }}
                  className="overflow-hidden"
                >
                  <div className="border-t border-gray-100">
                    <ResidentOccupantDetails
                      occupantId={occupant.id}
                      setViewMode={() => toggleAccordion(occupant.id)}
                      occupant={occupant}
                      occupants={occupants}
                      isAccordion={true}
                      isExpanded={expandedId === occupant.id}
                    />
                  </div>
                </motion.div>
              )}
            </AnimatePresence>
          </div>
        ))}

        {filteredOccupants.length === 0 && (
          <div className="text-center py-12 bg-white rounded-lg shadow-sm border border-gray-100">
            {searchTerm ? (
              <>
                <FaRegUser className="w-12 h-12 text-gray-400 mx-auto mb-4" />
                <h3 className="text-lg font-medium text-gray-900 mb-2">
                  No matching occupants found
                </h3>
                <p className="text-gray-500 mb-4">
                  Try adjusting your search terms
                </p>
              </>
            ) : (
              <>
                <FaRegUser className="w-12 h-12 text-gray-400 mx-auto mb-4" />
                <h3 className="text-lg font-medium text-gray-900 mb-2">
                  No Occupants Added
                </h3>
                <p className="text-gray-500 mb-4">
                  Start by adding occupants to this resident
                </p>
                <button
                  onClick={handleAddMode}
                  className="inline-flex items-center px-4 py-2.5 text-sm font-medium text-white bg-eleghoBlue rounded-lg hover:bg-eleghoBlue/90 transition-colors duration-200"
                >
                  <IoPersonAddOutline className="w-5 h-5 mr-2" />
                  Add First Occupant
                </button>
              </>
            )}
          </div>
        )}
      </div>

      {addMode && (
        <Modal>
          <AddResidentOccupantForm
            handleAddMode={handleAddMode}
            occupants={occupants}
          />
        </Modal>
      )}
    </div>
  );
}
