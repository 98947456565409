import React, { useEffect, useState } from "react";
import { getAllDocsSnap } from "../../../utils/firebase/Firebase.utils";

export default function Parking({ searchTerm = "" }) {
  const [parkingSpaces, setParkingSpaces] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchParkingSpaces = async () => {
      await getAllDocsSnap("parkingSpaces", (data) => {
        setParkingSpaces(data);
        setLoading(false);
        console.log(data);
      });
    };
    fetchParkingSpaces();
  }, []);

  

  const filteredParkingSpaces =
    searchTerm.trim() === ""
      ? parkingSpaces
      : parkingSpaces.filter((parking) => {
          const searchLower = searchTerm.toLowerCase();
          return (
            parking.title?.toLowerCase().includes(searchLower) ||
            parking.description?.toLowerCase().includes(searchLower) ||
            parking.location?.toLowerCase().includes(searchLower) ||
            parking.price?.toString().includes(searchLower)
          );
        });

  if (loading) {
    return (
      <div className="flex justify-center items-center h-40">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-gray-900"></div>
      </div>
    );
  }

  return (
    <div className="container mx-auto px-2 sm:px-4">
      <h1 className="text-xl sm:text-2xl font-bold my-4 sm:mb-6">
        Parking Spaces
      </h1>

      {filteredParkingSpaces.length === 0 ? (
        <div className="text-center py-10">
          <p className="text-gray-600">
            {searchTerm.trim() !== ""
              ? `No parking spaces found matching "${searchTerm}"`
              : "No parking spaces available at the moment."}
          </p>
        </div>
      ) : (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
          {filteredParkingSpaces.map((parking, index) => (
            <div
              key={parking.id || index}
              className="bg-white rounded-lg shadow-md overflow-hidden flex flex-col h-full"
            >
              <div className="relative h-48">
                {parking.images && parking.images.length > 0 ? (
                  <img
                    src={parking.images[0]}
                    alt={parking.title}
                    className="w-full h-full object-cover"
                  />
                ) : (
                  <div className="w-full h-full bg-gray-200 flex items-center justify-center">
                    <span className="text-gray-400">No image available</span>
                  </div>
                )}
                <div className="absolute top-0 right-0 bg-blue-500 text-white px-2 py-1 m-2 rounded-md text-sm">
                  {parking.rentalType || "For Rent"}
                </div>
              </div>

              <div className="p-4 flex-grow">
                <div className="flex justify-between items-start">
                  <h2 className="text-lg font-semibold">{parking.title}</h2>
                  <p className="text-green-600 font-bold">₱{parking.price}</p>
                </div>
                <div className="mt-2 flex items-center text-gray-600">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4 mr-1"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
                    />
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
                    />
                  </svg>
                  <p>{parking.location}</p>
                </div>

                {parking.size && (
                  <div className="mt-1 text-sm text-gray-600">
                    <span className="font-medium">Size:</span> {parking.size}
                  </div>
                )}

                <p className="mt-2 text-sm text-gray-700 line-clamp-2">
                  {parking.description}
                </p>

                {parking.createdName && (
                  <div className="mt-3 flex items-center text-xs text-gray-500">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-4 w-4 mr-1"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
                      />
                    </svg>
                    {parking.createdName}
                  </div>
                )}
              </div>

              <div className="p-4 pt-0 mt-auto">
                <button className="w-full bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 transition">
                  Contact Owner
                </button>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}
