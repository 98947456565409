import React, { useState, useEffect, useRef } from "react";
import { FaUpload, FaVideo, FaImage, FaSearch } from "react-icons/fa";
import {
  getAllDocs,
  queryDocByField,
  createNewDocument,
  storage,
  auth,
  setDocData,
} from "../../utils/firebase/Firebase.utils";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { v4 as uuidv4 } from "uuid";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import BackButton from "../../components/BackButton";

export default function AddConcernMobileAdmin() {
  const [formData, setFormData] = useState({
    image: "",
    identifier: "",
    concern: "",
    otherConcern: "",
    description: "",
    video: "",
  });
  const [isProcessing, setIsProcessing] = useState(false);
  const [unitIdentifiers, setUnitIdentifiers] = useState([]);
  const [filteredUnits, setFilteredUnits] = useState([]);
  const [showDropdown, setShowDropdown] = useState(false);
  const [imageFile, setImageFile] = useState(null);
  const [videoFile, setVideoFile] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const [videoPreview, setVideoPreview] = useState(null);
  const [uploadProgress, setUploadProgress] = useState({ image: 0, video: 0 });
  const [error, setError] = useState("");

  const dropdownRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    // Fetch all residents from Firestore
    const fetchResidents = async () => {
      try {
        const residents = await getAllDocs("residents");
        if (residents && residents.length > 0) {
          // Extract unique identifiers
          const identifiers = residents
            .map((resident) => resident.identifier)
            .filter(Boolean);
          setUnitIdentifiers(identifiers);
        }
      } catch (error) {
        console.error("Error fetching residents:", error);
      }
    };

    fetchResidents();

    // Close dropdown when clicking outside
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowDropdown(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });

    if (name === "identifier") {
      // Filter unit identifiers based on input
      const filtered = unitIdentifiers.filter((unit) =>
        unit.toLowerCase().includes(value.toLowerCase())
      );
      setFilteredUnits(filtered);
      setShowDropdown(true);
    }
  };

  const handleFileChange = (e, type) => {
    const file = e.target.files[0];
    if (!file) return;

    // Reset error state
    setError("");

    // Preview file
    const reader = new FileReader();

    if (type === "image") {
      // Check file size (max 5MB)
      if (file.size > 5 * 1024 * 1024) {
        setError("Image size should not exceed 5MB");
        return;
      }

      setImageFile(file);
      reader.onloadend = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(file);
    } else if (type === "video") {
      // Check file size (max 20MB)
      if (file.size > 20 * 1024 * 1024) {
        setError("Video size should not exceed 20MB");
        return;
      }

      setVideoFile(file);
      reader.onloadend = () => {
        setVideoPreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleRemoveFile = (type) => {
    if (type === "image") {
      setImageFile(null);
      setImagePreview(null);
      setFormData({ ...formData, image: "" });
    } else if (type === "video") {
      setVideoFile(null);
      setVideoPreview(null);
      setFormData({ ...formData, video: "" });
    }
  };

  const handleSelectUnit = (unit) => {
    setFormData({ ...formData, identifier: unit });
    setShowDropdown(false);
  };

  const uploadFileToStorage = async (file, path) => {
    const fileExtension = file.name.split(".").pop();
    const fileName = `${uuidv4()}.${fileExtension}`;
    const storageRef = ref(storage, `${path}/${fileName}`);

    try {
      const snapshot = await uploadBytes(storageRef, file);
      const downloadURL = await getDownloadURL(snapshot.ref);
      return downloadURL;
    } catch (error) {
      console.error(`Error uploading ${path}:`, error);
      throw error;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (isProcessing) return;

    try {
      setIsProcessing(true);
      setError("");

      // Get current user info
      const currentUser = auth.currentUser;
      if (!currentUser) {
        setError("You must be logged in to submit a concern");
        setIsProcessing(false);
        return;
      }

      // Prepare data for submission
      const concernData = {
        identifier: formData.identifier,
        concern:
          formData.concern === "Other"
            ? formData.otherConcern
            : formData.concern,
        description: formData.description,
        status: "Pending",
        uid: currentUser.uid,
        user: currentUser.email,
        createdBy: currentUser.displayName || currentUser.email,
        createdAt: new Date().toLocaleString(),
        id: uuidv4(),
        image: "",
        video: "",
      };

      // Upload image if exists
      if (imageFile) {
        const imageURL = await uploadFileToStorage(
          imageFile,
          "concerns/images"
        );
        concernData.image = imageURL;
      }

      // Upload video if exists
      if (videoFile) {
        const videoURL = await uploadFileToStorage(
          videoFile,
          "concerns/videos"
        );
        concernData.video = videoURL;
      }

      // Save to Firestore
      await setDocData("concerns", concernData.id, concernData);

      // Success! Clear form or redirect
      toast.success("Concern submitted successfully!");
      setIsProcessing(false);

      // Navigate back or to concerns list
      navigate(-1);
    } catch (error) {
      console.error("Error submitting concern:", error);
      setError("Failed to submit concern. Please try again.");
      setIsProcessing(false);
    }
  };

  return (
    <div className="min-h-screen bg-gray-50 p-4">
      <div className="max-w-md mx-auto bg-white rounded-lg shadow-md p-6">
        <div className="flex items-center justify-normal mb-6">
          <BackButton />
          <h2 className="text-lg font-semibold text-[#363062]">
            Add New Concern
          </h2>
        </div>

        {error && (
          <div className="mb-4 p-3 bg-red-100 text-red-700 rounded-md text-sm">
            {error}
          </div>
        )}

        <form onSubmit={handleSubmit} className="space-y-4">
          <div className="relative" ref={dropdownRef}>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Unit Identifier
            </label>
            <div className="relative">
              <input
                type="text"
                name="identifier"
                value={formData.identifier}
                onChange={handleChange}
                onFocus={() => setShowDropdown(true)}
                className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-[#363062]"
                placeholder="Search unit identifier"
                required
              />
              <FaSearch className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
            </div>

            {showDropdown && formData.identifier !== "" && (
              <div className="absolute z-10 w-full mt-1 bg-white border border-gray-300 rounded-md shadow-lg max-h-48 overflow-y-auto">
                {filteredUnits.length > 0 ? (
                  filteredUnits.map((unit, index) => (
                    <div
                      key={index}
                      className="px-4 py-2 hover:bg-gray-100 cursor-pointer text-sm"
                      onClick={() => handleSelectUnit(unit)}
                    >
                      {unit}
                    </div>
                  ))
                ) : (
                  <div className="px-4 py-2 text-sm text-gray-500">
                    No matching units found
                  </div>
                )}
              </div>
            )}
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Concern Type
            </label>
            <select
              name="concern"
              value={formData.concern}
              onChange={handleChange}
              className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-[#363062]"
              required
            >
              <option value="">Select a concern</option>
              <option value="Water Leaks">Water Leaks</option>
              <option value="Electrical">Electrical</option>
              <option value="Plumbing">Plumbing</option>
              <option value="Structural">Structural</option>
              <option value="Other">Other</option>
            </select>
          </div>

          {formData.concern === "Other" && (
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Specify Concern Type
              </label>
              <input
                type="text"
                name="otherConcern"
                value={formData.otherConcern}
                onChange={handleChange}
                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-[#363062]"
                placeholder="Enter concern type"
                required
              />
            </div>
          )}

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Description
            </label>
            <textarea
              name="description"
              value={formData.description}
              onChange={handleChange}
              className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-[#363062]"
              rows="3"
              placeholder="Describe the concern in detail"
              required
            />
          </div>

          <div className="space-y-3">
            <label className="block text-sm font-medium text-gray-700">
              Attachments
            </label>

            {/* Image upload */}
            <div className="space-y-2">
              <div className="flex space-x-4">
                <label className="flex-1">
                  <input
                    type="file"
                    accept="image/*"
                    onChange={(e) => handleFileChange(e, "image")}
                    className="hidden"
                  />
                  <div className="flex items-center justify-center px-4 py-2 border border-gray-300 rounded-md cursor-pointer hover:bg-gray-50">
                    <FaImage className="text-[#363062] mr-2" />
                    <span className="text-sm">Add Image</span>
                  </div>
                </label>
                <label className="flex-1">
                  <input
                    type="file"
                    accept="video/*"
                    onChange={(e) => handleFileChange(e, "video")}
                    className="hidden"
                  />
                  <div className="flex items-center justify-center px-4 py-2 border border-gray-300 rounded-md cursor-pointer hover:bg-gray-50">
                    <FaVideo className="text-[#363062] mr-2" />
                    <span className="text-sm">Add Video</span>
                  </div>
                </label>
              </div>

              {/* Image preview */}
              {imagePreview && (
                <div className="relative mt-2 border rounded-md overflow-hidden">
                  <img
                    src={imagePreview}
                    alt="Preview"
                    className="w-full h-40 object-cover"
                  />
                  <button
                    type="button"
                    onClick={() => handleRemoveFile("image")}
                    className="absolute top-2 right-2 bg-red-500 text-white p-1 rounded-full w-6 h-6 flex items-center justify-center text-xs"
                  >
                    ×
                  </button>
                </div>
              )}

              {/* Video preview */}
              {videoPreview && (
                <div className="relative mt-2 border rounded-md overflow-hidden">
                  <video
                    src={videoPreview}
                    controls
                    className="w-full h-40 object-cover"
                  />
                  <button
                    type="button"
                    onClick={() => handleRemoveFile("video")}
                    className="absolute top-2 right-2 bg-red-500 text-white p-1 rounded-full w-6 h-6 flex items-center justify-center text-xs"
                  >
                    ×
                  </button>
                </div>
              )}
            </div>
          </div>

          <button
            type="submit"
            disabled={isProcessing}
            className="w-full bg-[#8FCD00] text-white py-2 px-4 rounded-md hover:bg-opacity-90 transition-colors disabled:opacity-50"
          >
            {isProcessing ? "Submitting..." : "Submit Concern"}
          </button>
        </form>
      </div>
    </div>
  );
}
