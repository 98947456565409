import React, { useEffect, useRef } from "react";
import UserScreenWindow from "./UserScreenWindow";
import { v4 } from "uuid";
import { getDocData } from "../../utils/firebase/Firebase.utils";
import { useParams } from "react-router-dom";
import {
  FaCheckCircle,
  FaCalendarAlt,
  FaMoneyBillWave,
  FaHashtag,
  FaDownload,
} from "react-icons/fa";
import domtoimage from "dom-to-image";

function PaymentConfirmed() {
  const [billDetails, setBillDetails] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(true);
  const { id } = useParams();
  const receiptRef = useRef(null);

  useEffect(() => {
    const fetchBillDetails = async () => {
      const data = await getDocData("billings", id);
      setBillDetails(data);
      setIsLoading(false);
    };
    fetchBillDetails();
  }, []);

  const newDateString = new Date().toLocaleDateString();

  const handleDownload = async () => {
    if (receiptRef.current) {
      try {
        const dataUrl = await domtoimage.toPng(receiptRef.current, {
          quality: 1.0,
          bgcolor: "#ffffff",
          style: {
            transform: "scale(1)",
            "transform-origin": "top left",
          },
        });

        const link = document.createElement("a");
        link.download = `payment-receipt-${v4().slice(0, 8)}.png`;
        link.href = dataUrl;
        link.click();
      } catch (error) {
        console.error("Error generating receipt:", error);
      }
    }
  };

  if (isLoading) {
    return (
      <UserScreenWindow label="Payment Confirmation">
        <div className="flex items-center justify-center h-64">
          <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-eleghoBlue"></div>
        </div>
      </UserScreenWindow>
    );
  }

  return (
    <UserScreenWindow label={"Payment Confirmation"}>
      <div className="max-w-2xl mx-auto p-6">
        <div className="flex flex-col items-center justify-center mb-12">
          <FaCheckCircle className="text-eleghoGreen text-6xl mb-4" />
          <h2 className="text-2xl font-bold text-gray-800 mb-2">
            Payment Successful!
          </h2>
          <p className="text-gray-600 text-center">
            Thank you for your payment. We shall validate your payment soon.
          </p>
        </div>

        <div
          ref={receiptRef}
          className="bg-white rounded-lg shadow-lg p-6 space-y-6"
        >
          <div className="space-y-4">
            <div className="flex items-center space-x-3">
              <FaHashtag className="text-eleghoBlue text-xl" />
              <div>
                <div className="text-sm text-gray-500">Transaction Number</div>
                <div className="font-semibold text-eleghoBlue">
                  {billDetails.paymentId}
                </div>
              </div>
            </div>

            <div className="flex items-center space-x-3">
              <FaCalendarAlt className="text-eleghoBlue text-xl" />
              <div>
                <div className="text-sm text-gray-500">Payment Date</div>
                <div className="font-semibold">{billDetails.paymentDate}</div>
              </div>
            </div>

            <div className="flex items-center space-x-3">
              <FaMoneyBillWave className="text-eleghoBlue text-xl" />
              <div>
                <div className="text-sm text-gray-500">Amount</div>
                <div className="font-semibold">Php {billDetails.amount}</div>
              </div>
            </div>
          </div>

          <div className="pt-6 border-t border-gray-200">
            <div className="flex flex-col items-center space-y-4">
              <div className="text-sm text-gray-500">Powered by</div>
              <div className="w-32">
                <img
                  src="https://upload.wikimedia.org/wikipedia/commons/thumb/3/34/PayMongo_Logo.svg/2560px-PayMongo_Logo.svg.png"
                  alt="PayMongo"
                  className="w-full"
                />
              </div>
            </div>
          </div>
        </div>

        {/* <div className="mt-6 flex justify-center">
          <button
            onClick={handleDownload}
            className="flex items-center space-x-2 bg-eleghoBlue text-white px-6 py-3 rounded-lg hover:bg-opacity-90 transition-all duration-200 shadow-md"
          >
            <FaDownload className="text-lg" />
            <span>Download Receipt</span>
          </button>
        </div> */}
      </div>
    </UserScreenWindow>
  );
}

export default PaymentConfirmed;
