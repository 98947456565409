import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  getDocDataSnap,
  setDocData,
} from "../../../utils/firebase/Firebase.utils";
import { uploadFile } from "../../../utils/EleghoUtils";
import toast from "react-hot-toast";
import { FaFileUpload, FaFileDownload, FaTrashAlt } from "react-icons/fa";

export default function ResidentVehicleRegistration() {
  const { id } = useParams();
  const [vehicles, setVehicles] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [uploadingInProgress, setUploadingInProgress] = useState(false);
  const [progress, setProgress] = useState(0);
  const fileInput = useRef(null);
  const [selectedVehicleId, setSelectedVehicleId] = useState(null);
  const [newVehicle, setNewVehicle] = useState({
    plateNumber: "",
    make: "",
    model: "",
    year: "",
    color: "",
    type: "car",
    status: "active",
    orcrDocument: null,
  });

  useEffect(() => {
    const fetchVehicles = async () => {
      await getDocDataSnap("residents", id, (data) => {
        setVehicles(data.vehicles || []);
        setIsLoading(false);
      });
    };
    fetchVehicles();
  }, [id]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewVehicle((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const updatedVehicles = [...vehicles, { ...newVehicle, id: Date.now() }];
      await setDocData("residents", id, { vehicles: updatedVehicles });
      setVehicles(updatedVehicles);
      setNewVehicle({
        plateNumber: "",
        make: "",
        model: "",
        year: "",
        color: "",
        type: "car",
        status: "active",
        orcrDocument: null,
      });
      toast.success("Vehicle added successfully");
    } catch (error) {
      toast.error("Failed to add vehicle");
      console.error("Error adding vehicle:", error);
    }
  };

  const handleDelete = async (vehicleId) => {
    if (window.confirm("Are you sure you want to delete this vehicle?")) {
      try {
        const updatedVehicles = vehicles.filter((v) => v.id !== vehicleId);
        await setDocData("residents", id, { vehicles: updatedVehicles });
        setVehicles(updatedVehicles);
        toast.success("Vehicle deleted successfully");
      } catch (error) {
        toast.error("Failed to delete vehicle");
        console.error("Error deleting vehicle:", error);
      }
    }
  };

  const handleFileUpload = async (e, vehicleId = null) => {
    try {
      const res = await uploadFile(
        e,
        `residents/${id}/vehicles`,
        setUploadingInProgress,
        setProgress,
        null,
        "orcr"
      );

      if (res) {
        let updatedVehicles;
        if (vehicleId) {
          // Update existing vehicle
          updatedVehicles = vehicles.map((vehicle) =>
            vehicle.id === vehicleId
              ? { ...vehicle, orcrDocument: res }
              : vehicle
          );
        } else {
          // For new vehicle
          setNewVehicle((prev) => ({
            ...prev,
            orcrDocument: res,
          }));
          return;
        }

        await setDocData("residents", id, { vehicles: updatedVehicles });
        setVehicles(updatedVehicles);
        toast.success("OR-CR document uploaded successfully");
      }
    } catch (error) {
      toast.error("Failed to upload OR-CR document");
      console.error("Error uploading document:", error);
    }
  };

  const handleDownloadDocument = (documentUrl) => {
    if (documentUrl) {
      window.open(documentUrl, "_blank");
    } else {
      toast.error("No document available");
    }
  };

  if (isLoading) {
    return (
      <div className="flex items-center justify-center min-h-[400px]">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-eleghoBlue"></div>
      </div>
    );
  }

  return (
    <div className="space-y-6">
      {/* Add New Vehicle Form */}
      <div className="bg-white p-6 rounded-lg shadow-sm">
        <h2 className="text-lg font-medium text-gray-900 mb-4">
          Add New Vehicle
        </h2>
        <form
          onSubmit={handleSubmit}
          className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4"
        >
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Plate Number
            </label>
            <input
              type="text"
              name="plateNumber"
              value={newVehicle.plateNumber}
              onChange={handleInputChange}
              required
              className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-eleghoBlue"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Make
            </label>
            <input
              type="text"
              name="make"
              value={newVehicle.make}
              onChange={handleInputChange}
              required
              className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-eleghoBlue"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Model
            </label>
            <input
              type="text"
              name="model"
              value={newVehicle.model}
              onChange={handleInputChange}
              required
              className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-eleghoBlue"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Year
            </label>
            <input
              type="number"
              name="year"
              value={newVehicle.year}
              onChange={handleInputChange}
              required
              className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-eleghoBlue"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Color
            </label>
            <input
              type="text"
              name="color"
              value={newVehicle.color}
              onChange={handleInputChange}
              required
              className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-eleghoBlue"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Type
            </label>
            <select
              name="type"
              value={newVehicle.type}
              onChange={handleInputChange}
              className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-eleghoBlue"
            >
              <option value="car">Car</option>
              <option value="motorcycle">Motorcycle</option>
              <option value="truck">Truck</option>
              <option value="suv">SUV</option>
              <option value="van">Van</option>
            </select>
          </div>

          <div className="md:col-span-2 lg:col-span-3">
            <label className="block text-sm font-medium text-gray-700 mb-1">
              OR-CR Document
            </label>
            <div className="flex items-center gap-2">
              <input
                type="file"
                accept=".pdf,.jpg,.jpeg,.png"
                onChange={(e) => handleFileUpload(e)}
                className="hidden"
                ref={fileInput}
              />
              <button
                type="button"
                onClick={() => fileInput.current.click()}
                className="inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-eleghoBlue"
              >
                <FaFileUpload className="mr-2 h-4 w-4" />
                Upload OR-CR
              </button>
              {newVehicle.orcrDocument && (
                <span className="text-sm text-green-600">
                  Document uploaded
                </span>
              )}
            </div>
          </div>

          <div className="md:col-span-2 lg:col-span-3">
            <button
              type="submit"
              className="w-full sm:w-auto px-4 py-2 bg-eleghoBlue text-white rounded-md hover:bg-eleghoBlue/90 transition-colors duration-200"
            >
              Add Vehicle
            </button>
          </div>
        </form>
      </div>

      {/* Registered Vehicles List */}
      <div className="bg-white p-6 rounded-lg shadow-sm">
        <h2 className="text-lg font-medium text-gray-900 mb-4">
          Registered Vehicles
        </h2>
        <div className="overflow-x-auto">
          <table className="min-w-full divide-y divide-gray-200">
            <thead>
              <tr>
                <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Plate Number
                </th>
                <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Make & Model
                </th>
                <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Year
                </th>
                <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Color
                </th>
                <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Type
                </th>
                <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Status
                </th>
                <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  OR-CR
                </th>
                <th className="px-6 py-3 bg-gray-50"></th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {vehicles.length === 0 ? (
                <tr>
                  <td
                    colSpan="8"
                    className="px-6 py-4 text-center text-sm text-gray-500"
                  >
                    No vehicles registered
                  </td>
                </tr>
              ) : (
                vehicles.map((vehicle) => (
                  <tr key={vehicle.id}>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                      {vehicle.plateNumber}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                      {vehicle.make} {vehicle.model}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                      {vehicle.year}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                      {vehicle.color}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                      {vehicle.type}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <span
                        className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${
                          vehicle.status === "active"
                            ? "bg-green-100 text-green-800"
                            : "bg-red-100 text-red-800"
                        }`}
                      >
                        {vehicle.status}
                      </span>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="flex items-center gap-2">
                        <input
                          type="file"
                          accept=".pdf,.jpg,.jpeg,.png"
                          onChange={(e) => handleFileUpload(e, vehicle.id)}
                          className="hidden"
                          id={`file-${vehicle.id}`}
                        />
                        <button
                          type="button"
                          onClick={() =>
                            document
                              .getElementById(`file-${vehicle.id}`)
                              .click()
                          }
                          className="text-eleghoBlue hover:text-eleghoBlue/80"
                          title="Upload OR-CR"
                        >
                          <FaFileUpload className="h-4 w-4" />
                        </button>
                        {vehicle.orcrDocument && (
                          <button
                            type="button"
                            onClick={() =>
                              handleDownloadDocument(vehicle.orcrDocument)
                            }
                            className="text-eleghoBlue hover:text-eleghoBlue/80"
                            title="Download OR-CR"
                          >
                            <FaFileDownload className="h-4 w-4" />
                          </button>
                        )}
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                      <button
                        onClick={() => handleDelete(vehicle.id)}
                        className="text-red-600 hover:text-red-900"
                      >
                        Delete
                      </button>
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
