import React, { useEffect } from "react";
import { useSelector } from "react-redux";

import {
  getDocDataSnap,
  setDocData,
  storage,
} from "../../../utils/firebase/Firebase.utils";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { v4 } from "uuid";

import { useState } from "react";
import toast from "react-hot-toast";
import { IoIosCheckmarkCircle } from "react-icons/io";
import { RiErrorWarningFill } from "react-icons/ri";
import InputBox from "../Components/InputBox";
import MobileModal from "../Components/MobileModal";

function TenantDocuments({ tenantId }) {
  const [url, setUrl] = useState("");
  const [progress, setProgress] = useState(0);
  const [tenant, setTenant] = useState({});
  const [activeUpload, setActiveUpload] = useState("");
  const [editMode, setEditMode] = useState(false);
  const currentUser = useSelector((state) => state.userObject);
  const [tenantFiles, setTenantFiles] = useState([]);
  const [addMode, setAddMode] = useState(false);
  const [fileName, setFileName] = useState("");

  const requiredFiles = ["Lease Contract", "Valid ID", "Move In Authorization"];
  const attachmentList = [...requiredFiles, ...Object.keys(tenantFiles)];
  const uniqueFiles = [...new Set(attachmentList)];

  useEffect(() => {
    const fetchTenant = async () => {
      getDocDataSnap("tenants", tenantId, (data) => {
        setTenant(data);
        setTenantFiles(data.documents || []);
      });
    };
    fetchTenant();
  }, []);

  const handleUpload = (e, fileName) => {
    setActiveUpload(fileName);
    e.preventDefault();
    const file = e.target.files[0];
    const storageRef = ref(
      storage,
      `tenants/${tenantId}/${fileName} + ${v4()}`
    );
    const uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setProgress(progress);
      },
      (error) => {},
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          setUrl(downloadURL);
          setDocData("tenants", tenantId, {
            documents: { ...tenant.documents, [fileName]: downloadURL },
          });
          toast.success(`${fileName} Uploaded Successfully`);
          setProgress(0);
        });
      }
    );
  };

  const allowableAccess = ["admin", "manager", "maker"];
  const userRoles = currentUser.roles;

  const handleEditMode = () => {
    setEditMode(!editMode);
  };

  const handleRemove = async (file) => {
    if (window.confirm(`Are you sure you want to remove ${fileName}`)) {
      const newFiles = { ...tenantFiles };
      delete newFiles[file];
      setTenantFiles(newFiles);
      setDocData("tenants", tenantId, { documents: newFiles });
      toast.success(`${file} Removed Successfully`);
    }
  };

  return (
    <div className="max-w-[1440px] mt-8">
      <div className="font-bold text-sm ">Upload Tenant Documents</div>

      {allowableAccess.some((role) => userRoles.includes(role)) ? (
        <div className="flex items-center gap-4 mt-4">
          <button
            onClick={handleEditMode}
            className={editMode ? "underline text-xs" : "blue-button"}
          >
            {editMode ? "Close" : "Edit"}
          </button>
          <button className="blue-button" onClick={() => setAddMode(true)}>
            Add Document
          </button>
        </div>
      ) : null}

      {uniqueFiles.map((file, index) => (
        <div
          key={index}
          className="flex items-center gap-4 mt-2 flex-wrap bubble p-2"
        >
          <div className="flex items-center gap-2 ">
            <div>
              {tenantFiles[file] ? (
                <IoIosCheckmarkCircle className="text-green-500" />
              ) : (
                <RiErrorWarningFill className="text-red-500" />
              )}
            </div>

            <label className="text-gray-600 font-bold w-60 text-left ">
              {file}
            </label>
          </div>

          <div className="flex items-center justify-start flex-wrap gap-8">
            {editMode && (
              <>
                <input
                  type="file"
                  name={file}
                  onChange={(e) => handleUpload(e, file)}
                />
                <div className="h-2">
                  {activeUpload === file && progress > 0 && (
                    <progress value={progress} max="100">
                      {progress}%
                    </progress>
                  )}
                </div>
              </>
            )}
            {tenantFiles[file] ? (
              <a
                href={tenantFiles[file]}
                target="_blank"
                className="underline font-bold"
              >
                View {file}
              </a>
            ) : (
              <div className="text-gray-500">No {file} Uploaded</div>
            )}

            {editMode && tenantFiles[file] && (
              <button
                className="blue-button "
                onClick={() => handleRemove(file)}
              >
                Remove
              </button>
            )}
          </div>
        </div>
      ))}

      {addMode && (
        <MobileModal>
          <div className="page relative">
            <button className="close-button" onClick={() => setAddMode(false)}>
              Close
            </button>
            <InputBox
              label="Enter File Name"
              type="text"
              onChange={(e) => setFileName(e.target.value)}
              value={fileName}
              placeholder={"Enter File Name"}
            />

            <input
              type="file"
              name={fileName}
              className="mt-4"
              onChange={(e) => handleUpload(e, fileName)}
            />

            <div className="h-2">
              {activeUpload === fileName && progress > 0 && (
                <progress value={progress} max="100">
                  {progress}%
                </progress>
              )}
            </div>

            <button
              className="blue-button small-form-button mt-4"
              disabled={!fileName}
              onClick={() => setAddMode(false)}
            >
              Close
            </button>
          </div>
        </MobileModal>
      )}
    </div>
  );
}

export default TenantDocuments;
