import React, { useEffect } from "react";
import Modal from "../Dashboard/Components/Modal";
import IncidentForm from "./IncidentForm";
import PageModal from "../Dashboard/Components/PageModal";
import { getAllDocsSnap } from "../../utils/firebase/Firebase.utils";
import IncidentReportsCard from "./IncidentReportsCard";
import IncidentReportDetails from "./IncidentReportDetails";
import { slicedData } from "../../utils/EleghoUtils";
import BentoTable from "../../components/BentoTable";
import IncidentReportTableHeaders from "./IncidentReportTableHeaders";
import {
  FiAlertTriangle,
  FiClipboard,
  FiCheckCircle,
  FiClock,
} from "react-icons/fi";

export default function IncidentReports({ searchTerm = "" }) {
  const [addMode, setAddMode] = React.useState(false);
  const [isProcessing, setIsProcessing] = React.useState(false);
  const [reports, setReports] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(true);
  const [selectedReport, setSelectedReport] = React.useState({});
  const [viewMode, setViewMode] = React.useState(false);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [numberOfItemsPerPage, setNumberOfItemsPerPage] = React.useState(5);

  useEffect(() => {
    const fetchReports = async () => {
      await getAllDocsSnap("incidentReports", (data) => {
        setReports(data);
        setIsLoading(false);
      });
    };
    fetchReports();
  }, []);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  const handleAddMode = () => {
    setAddMode(true);
  };

  const handleView = (report) => {
    setViewMode(true);
    setSelectedReport(report);
  };

  // Calculate statistics
  const stats = {
    totalIncidents: reports.length,
    resolvedIncidents: reports.filter((report) => report.status === "resolved")
      .length,
    pendingIncidents: reports.filter((report) => report.status === "pending")
      .length,
    recentIncidents: reports.filter((report) => {
      // Assuming there's a timestamp or date field to check for recent incidents (last 7 days)
      // Adjust according to your actual data structure
      const reportDate = new Date(report.timestamp || report.date);
      const sevenDaysAgo = new Date();
      sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 7);
      return reportDate >= sevenDaysAgo;
    }).length,
  };

  // Filter reports based on search term
  const filteredReports = searchTerm
    ? reports.filter(
        (report) =>
          report.incidentType
            ?.toLowerCase()
            .includes(searchTerm.toLowerCase()) ||
          report.location?.toLowerCase().includes(searchTerm.toLowerCase()) ||
          report.description
            ?.toLowerCase()
            .includes(searchTerm.toLowerCase()) ||
          report.reportedBy?.toLowerCase().includes(searchTerm.toLowerCase())
      )
    : reports;

  const dataSliced = slicedData(
    filteredReports,
    numberOfItemsPerPage,
    currentPage
  );

  return (
    <div className="text-xs">
      <div className="mt-4 font-bold text-blue-900 uppercase text-left text-sm">
        Incident Reports
      </div>

      {/* Stats Cards */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 my-6">
        <div className="bg-white p-6 rounded-xl border border-gray-100 shadow-sm hover:shadow-md transition-shadow">
          <div className="flex items-center gap-3">
            <div className="p-2 bg-gray-50 rounded-lg">
              <FiClipboard className="w-5 h-5 text-gray-600" />
            </div>
            <div>
              <div className="text-sm text-gray-600">Total Incidents</div>
              <div className="text-2xl font-semibold text-gray-900">
                {stats.totalIncidents}
              </div>
            </div>
          </div>
        </div>
        <div className="bg-white p-6 rounded-xl border border-gray-100 shadow-sm hover:shadow-md transition-shadow">
          <div className="flex items-center gap-3">
            <div className="p-2 bg-green-50 rounded-lg">
              <FiCheckCircle className="w-5 h-5 text-green-600" />
            </div>
            <div>
              <div className="text-sm text-gray-600">Resolved</div>
              <div className="text-2xl font-semibold text-green-600">
                {stats.resolvedIncidents}
              </div>
            </div>
          </div>
        </div>
        <div className="bg-white p-6 rounded-xl border border-gray-100 shadow-sm hover:shadow-md transition-shadow">
          <div className="flex items-center gap-3">
            <div className="p-2 bg-orange-50 rounded-lg">
              <FiClock className="w-5 h-5 text-orange-500" />
            </div>
            <div>
              <div className="text-sm text-gray-600">Pending</div>
              <div className="text-2xl font-semibold text-orange-500">
                {stats.pendingIncidents}
              </div>
            </div>
          </div>
        </div>
        <div className="bg-white p-6 rounded-xl border border-gray-100 shadow-sm hover:shadow-md transition-shadow">
          <div className="flex items-center gap-3">
            <div className="p-2 bg-red-50 rounded-lg">
              <FiAlertTriangle className="w-5 h-5 text-red-500" />
            </div>
            <div>
              <div className="text-sm text-gray-600">Recent (7d)</div>
              <div className="text-2xl font-semibold text-red-500">
                {stats.recentIncidents}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="flex justify-between items-center mb-4">
        <div className="text-sm text-gray-600">
          Showing {filteredReports.length} incident reports
        </div>
        <button
          className="px-4 py-2 text-sm font-medium text-white bg-eleghoBlue rounded-lg hover:bg-eleghoBlue/90 focus:outline-none focus:ring-2 focus:ring-eleghoBlue/50 transition-colors"
          onClick={handleAddMode}
        >
          Log an Incident
        </button>
      </div>

      <BentoTable
        label="All Incident Reports"
        data={filteredReports}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        numberOfItemPerPage={numberOfItemsPerPage}
      >
        <div className="divide-y divide-gray-100">
          <IncidentReportTableHeaders />
          {dataSliced.map((report, index) => (
            <div
              key={index}
              className="cursor-pointer"
              onClick={() => handleView(report)}
            >
              <IncidentReportsCard data={report} />
            </div>
          ))}
        </div>
      </BentoTable>

      {addMode && (
        <PageModal>
          <IncidentForm setAddMode={setAddMode} />
        </PageModal>
      )}

      {viewMode && (
        <PageModal>
          <IncidentReportDetails
            data={selectedReport}
            setViewMode={setViewMode}
          />
        </PageModal>
      )}
    </div>
  );
}
