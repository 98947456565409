import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { FaSearch, FaArrowLeft } from "react-icons/fa";
import {
  FiHome,
  FiUsers,
  FiClipboard,
  FiCheckSquare,
  FiGrid,
  FiBell,
  FiSettings,
  FiMonitor,
  FiSmartphone,
  FiPlus,
} from "react-icons/fi";
import {
  getAllDocsSnap,
  setDocData,
} from "../../utils/firebase/Firebase.utils";
import {
  PieChart,
  Pie,
  Cell,
  ResponsiveContainer,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
} from "recharts";
import { convertTimeStamptoDate } from "../../utils/EleghoUtils";
import toast from "react-hot-toast";
import TodosForm from "../Todos/TodosForm";
import Calendar from "react-calendar/dist/cjs/Calendar.js";

function AdminMobileOverview() {
  const navigate = useNavigate();
  const [showModal, setShowModal] = React.useState(false);
  const currentUser = useSelector((state) => state.userObject);
  const [selectedJobOrderLink, setSelectedJobOrderLink] =
    React.useState("newJobOrder");
  const firstName = currentUser.displayName;
  const [numberOfUnits, setNumberOfUnits] = React.useState(0);
  const [occupiedUnits, setOccupiedUnits] = React.useState(0);
  const [gatepass, setGatepass] = React.useState(0);
  const [visitorPass, setVisitorPass] = React.useState(0);
  const [workPermits, setWorkPermits] = useState(0);
  const [concerns, setConcerns] = useState(0);
  const [pendingGatePass, setPendingGatePass] = useState([]);
  const [pendingVisitorPass, setPendingVisitorPass] = useState([]);
  const [pendingWorkPermits, setPendingWorkPermits] = useState([]);
  const [pendingConcerns, setPendingConcerns] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [addTodo, setAddTodo] = useState(false);
  const [pendingTodos, setPendingTodos] = useState([]);

  useEffect(() => {
    const fetchTodos = async () => {
      await getAllDocsSnap("todos", (todos) => {
        const pending = todos.filter(
          (todo) => todo.status === "pending" && todo.user === currentUser.uid
        );
        setPendingTodos(pending);
      });
    };
    fetchTodos();
  }, []);

  useEffect(() => {
    const fetchgatepass = async () => {
      const gatepass = await getAllDocsSnap("gatePass", (gatepass) => {
        const pendingGatePass = gatepass.filter(
          (item) => item.status === "pending"
        );
        if (
          currentUser.roles.includes("admin") ||
          currentUser.roles.includes("security")
        ) {
          setPendingGatePass(pendingGatePass);
          setGatepass(pendingGatePass.length);
        } else {
          const assignedGatePass = pendingGatePass.filter((item) =>
            item.assignedTo?.some((user) => user.value === currentUser.email)
          );
          setPendingGatePass(assignedGatePass);
          setGatepass(assignedGatePass.length);
        }
      });
    };

    const fetchVisitorPass = async () => {
      const visitorPass = await getAllDocsSnap("guests", (visitorPass) => {
        const pendingVisitorPass = visitorPass.filter(
          (item) => item.status === "pending"
        );

        if (
          currentUser.roles.includes("admin") ||
          currentUser.roles.includes("security")
        ) {
          setPendingVisitorPass(pendingVisitorPass);
          setVisitorPass(pendingVisitorPass.length);
        } else {
          const assignedVisitorPass = pendingVisitorPass.filter((item) =>
            item.assignedTo?.some((user) => user.value === currentUser.email)
          );
          setPendingVisitorPass(assignedVisitorPass);
          setVisitorPass(assignedVisitorPass.length);
        }
      });
    };

    const fetchWorkPermits = async () => {
      const workPermits = await getAllDocsSnap("workPermits", (workPermits) => {
        const pendingWorkPermits = workPermits.filter(
          (item) => item.status === "Pending"
        );

        if (
          currentUser.roles.includes("admin") ||
          currentUser.roles.includes("security")
        ) {
          setPendingWorkPermits(pendingWorkPermits);
          setWorkPermits(pendingWorkPermits.length);
        } else {
          const assignedWorkPermits = pendingWorkPermits.filter((item) =>
            item.assignedTo?.some((user) => user.value === currentUser.email)
          );
          setPendingWorkPermits(assignedWorkPermits);
          setWorkPermits(assignedWorkPermits.length);
        }
      });
    };

    const fetchConcerns = async () => {
      const concerns = await getAllDocsSnap("concerns", (concerns) => {
        const pendingConcerns = concerns.filter(
          (item) => item.status === "Pending"
        );

        if (
          currentUser.roles.includes("admin") ||
          currentUser.roles.includes("security")
        ) {
          setPendingConcerns(pendingConcerns);
          setConcerns(pendingConcerns.length);
        } else {
          const assignedConcerns = pendingConcerns.filter((item) =>
            item.assignedTo?.some((user) => user.value === currentUser.email)
          );
          setPendingConcerns(assignedConcerns);
          setConcerns(assignedConcerns.length);
        }
      });
    };

    fetchgatepass();
    fetchWorkPermits();
    fetchConcerns();
    fetchVisitorPass();
  }, []);

  useEffect(() => {
    const fetchResidents = async () => {
      const residentList = getAllDocsSnap("residents", (residents) => {
        setNumberOfUnits(residents.length);
        const occupiedUnits = residents.filter(
          (resident) => resident.numberOfOccupants > 0
        );
        setOccupiedUnits(occupiedUnits.length);
        setIsLoading(false);
      });
    };
    fetchResidents();
  }, []);

  if (isLoading) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-gray-50">
        <div className="space-y-4 text-center">
          <div className="animate-spin h-8 w-8 border-4 border-blue-600 border-t-transparent rounded-full mx-auto" />
          <p className="text-gray-600">Loading dashboard data...</p>
        </div>
      </div>
    );
  }

  const currentDateTime = new Date();

  const propertyOverview = {
    totalUnits: numberOfUnits,
    totalOccupied: occupiedUnits,
    totalVacant: numberOfUnits - occupiedUnits,
  };

  const occupancyData = [
    { name: "Vacant", value: numberOfUnits - occupiedUnits, color: "#ffab00" },
    { name: "Occupied", value: occupiedUnits, color: "#4285f4" },
  ];

  const duesData = [
    { name: "Jan", value: 4000 },
    { name: "Feb", value: 3000 },
    { name: "Mar", value: 2000 },
    { name: "Apr", value: 2780 },
    { name: "May", value: 1890 },
    { name: "Jun", value: 2390 },
  ];

  const handleComplete = async (id) => {
    await setDocData("todos", id, { status: "completed" });
    toast.success("Todo marked as completed");
  };

  const handleLapse = (dateTime) => {
    if (!dateTime) return "Just now";

    const now = new Date();
    const then = new Date(dateTime);
    const totalMilliseconds = now - then;

    const minutes = Math.floor(totalMilliseconds / (1000 * 60));
    const hours = Math.floor(totalMilliseconds / (1000 * 60 * 60));
    const days = Math.floor(totalMilliseconds / (1000 * 60 * 60 * 24));

    if (minutes < 1) return "Just now";
    if (minutes < 60) return `${minutes} min ago`;
    if (hours < 24) return `${hours} ${hours === 1 ? "hour" : "hours"} ago`;
    return `${days} ${days === 1 ? "day" : "days"} ago`;
  };

  return (
    <div className="p-4 space-y-6">
      <button
        onClick={() => navigate(-1)}
        className="mr-2 p-2 rounded-full hover:bg-gray-200 transition-colors"
        aria-label="Go back"
      >
        <FaArrowLeft className="text-[#363062]" />
      </button>
      <div className="title-header">Property Management Overview</div>
      {/* Property Overview Card */}
      <div className="bg-white rounded-xl shadow-sm p-4">
        <h2 className="text-lg font-semibold text-gray-900 mb-4">
          Property Overview
        </h2>
        <div className="flex items-center justify-between mb-4">
          <div>
            <p className="text-sm text-gray-500">Total Units</p>
            <p className="text-2xl font-bold text-gray-900">
              {propertyOverview.totalUnits}
            </p>
          </div>
          <div>
            <p className="text-sm text-gray-500">Occupied</p>
            <p className="text-2xl font-bold text-blue-600">
              {propertyOverview.totalOccupied}
            </p>
          </div>
          <div>
            <p className="text-sm text-gray-500">Vacant</p>
            <p className="text-2xl font-bold text-yellow-600">
              {propertyOverview.totalVacant}
            </p>
          </div>
        </div>
        <div className="h-[200px]">
          <ResponsiveContainer width="100%" height="100%">
            <PieChart>
              <Pie
                data={occupancyData}
                cx="50%"
                cy="50%"
                innerRadius={60}
                outerRadius={80}
                paddingAngle={5}
                dataKey="value"
              >
                {occupancyData.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={entry.color} />
                ))}
              </Pie>
              <Tooltip />
            </PieChart>
          </ResponsiveContainer>
        </div>
      </div>

      {/* Dues Collection Card */}
      <div className="bg-white rounded-xl shadow-sm p-4">
        <h2 className="text-lg font-semibold text-gray-900 mb-4">
          Dues Collection
        </h2>
        <div className="h-[200px]">
          <ResponsiveContainer width="100%" height="100%">
            <BarChart data={duesData}>
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Bar dataKey="value" fill="#4285f4" />
            </BarChart>
          </ResponsiveContainer>
        </div>
      </div>

      {/* Pending Approvals Card */}
      <div className="bg-white rounded-xl shadow-sm p-4">
        <h2 className="text-lg font-semibold text-gray-900 mb-4">
          Pending Approvals
        </h2>
        <div className="grid grid-cols-2 gap-4">
          <div className="bg-blue-50 p-3 rounded-lg">
            <p className="text-sm text-gray-600">Gate Pass</p>
            <p className="text-2xl font-bold text-blue-600">{gatepass}</p>
          </div>
          <div className="bg-green-50 p-3 rounded-lg">
            <p className="text-sm text-gray-600">Visitor Pass</p>
            <p className="text-2xl font-bold text-green-600">{visitorPass}</p>
          </div>
          <div className="bg-yellow-50 p-3 rounded-lg">
            <p className="text-sm text-gray-600">Work Permits</p>
            <p className="text-2xl font-bold text-yellow-600">{workPermits}</p>
          </div>
          <div className="bg-red-50 p-3 rounded-lg">
            <p className="text-sm text-gray-600">Concerns</p>
            <p className="text-2xl font-bold text-red-600">{concerns}</p>
          </div>
        </div>
      </div>

      {/* Todo List Card */}
      <div className="bg-white rounded-xl shadow-sm p-4">
        <div className="flex items-center justify-between mb-4">
          <h2 className="text-lg font-semibold text-gray-900">Todo List</h2>
          <button
            onClick={() => setAddTodo(true)}
            className="text-blue-600 hover:text-blue-700"
          >
            <FiPlus className="w-5 h-5" />
          </button>
        </div>
        <div className="space-y-3">
          {pendingTodos.slice(0, 3).map((todo) => (
            <div
              key={todo.id}
              className="flex items-center gap-3 p-3 bg-gray-50 rounded-lg"
            >
              <button
                onClick={() => handleComplete(todo.id)}
                className="w-5 h-5 rounded border-2 border-gray-300 hover:border-blue-500"
              >
                <FiCheckSquare className="w-4 h-4 text-transparent hover:text-blue-500" />
              </button>
              <div>
                <p className="text-sm font-medium text-gray-900">{todo.name}</p>
                <p className="text-xs text-gray-500">
                  {handleLapse(todo.createdAt)}
                </p>
              </div>
            </div>
          ))}
          {pendingTodos.length > 3 && (
            <button
              onClick={() => navigate("/todos")}
              className="w-full text-sm text-blue-600 hover:text-blue-700"
            >
              View {pendingTodos.length - 3} more tasks
            </button>
          )}
        </div>
      </div>

      {/* Calendar Card */}
      <div className="bg-white rounded-xl shadow-sm p-4">
        <h2 className="text-lg font-semibold text-gray-900 mb-4">Calendar</h2>
        <div className="max-w-[300px] mx-auto">
          <Calendar className="w-full" />
        </div>
      </div>

      {addTodo && (
        <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center p-4">
          <div className="bg-white rounded-xl p-4 w-full max-w-md">
            <TodosForm setAddTodo={setAddTodo} />
          </div>
        </div>
      )}
    </div>
  );
}

export default AdminMobileOverview;
