import React, { useEffect } from "react";
import BentoContainer from "./BentoContainer";

export default function BentoTable({
  label,
  children,
  data,
  numberOfItemPerPage,
  currentPage,
  setCurrentPage,
  setSearchTerm,
}) {
  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const handlePagination = (page) => {
    setCurrentPage(page);
  };

  const numberOfPages = Math.ceil(data.length / numberOfItemPerPage);

  const handleNext = () => {
    if (currentPage < numberOfPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrevious = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  return (
    <div className="min-h-[700px]">
      <BentoContainer label={label}>{children}</BentoContainer>

      <div className="mt-4 flex items-center justify-between">
        <button
          className="px-4 py-2 text-sm font-medium text-eleghoBlue bg-white border border-eleghoBlue rounded-lg hover:bg-eleghoBlue/5 focus:outline-none focus:ring-2 focus:ring-eleghoBlue/20 disabled:opacity-50 disabled:cursor-not-allowed transition-colors"
          disabled={currentPage === 1}
          onClick={handlePrevious}
        >
          Previous
        </button>

        <div className="flex items-center gap-2">
          {numberOfPages <= 10
            ? Array(numberOfPages)
                .fill()
                .map((_, index) => (
                  <button
                    className={`w-8 h-8 text-sm font-medium rounded-lg transition-colors ${
                      currentPage === index + 1
                        ? "bg-eleghoBlue text-white"
                        : "text-eleghoBlue bg-white border border-eleghoBlue hover:bg-eleghoBlue/5"
                    }`}
                    key={index}
                    onClick={() => handlePagination(index + 1)}
                  >
                    {index + 1}
                  </button>
                ))
            : [
                // First page
                <button
                  key="first"
                  className={`w-8 h-8 text-sm font-medium rounded-lg transition-colors ${
                    currentPage === 1
                      ? "bg-eleghoBlue text-white"
                      : "text-eleghoBlue bg-white border border-eleghoBlue hover:bg-eleghoBlue/5"
                  }`}
                  onClick={() => handlePagination(1)}
                >
                  1
                </button>,

                // Show ellipsis if current page is > 3
                currentPage > 3 && (
                  <span key="ellipsis1" className="px-1">
                    ...
                  </span>
                ),

                // Pages around current page
                ...Array(numberOfPages)
                  .fill()
                  .map((_, index) => {
                    const pageNumber = index + 1;
                    // Show 1 page before and 1 page after current page
                    if (
                      pageNumber !== 1 &&
                      pageNumber !== numberOfPages &&
                      (pageNumber === currentPage - 1 ||
                        pageNumber === currentPage ||
                        pageNumber === currentPage + 1)
                    ) {
                      return (
                        <button
                          key={index}
                          className={`w-8 h-8 text-sm font-medium rounded-lg transition-colors ${
                            currentPage === pageNumber
                              ? "bg-eleghoBlue text-white"
                              : "text-eleghoBlue bg-white border border-eleghoBlue hover:bg-eleghoBlue/5"
                          }`}
                          onClick={() => handlePagination(pageNumber)}
                        >
                          {pageNumber}
                        </button>
                      );
                    }
                    return null;
                  })
                  .filter(Boolean),

                // Show ellipsis if current page < numberOfPages - 2
                currentPage < numberOfPages - 2 && (
                  <span key="ellipsis2" className="px-1">
                    ...
                  </span>
                ),

                // Last page
                <button
                  key="last"
                  className={`w-8 h-8 text-sm font-medium rounded-lg transition-colors ${
                    currentPage === numberOfPages
                      ? "bg-eleghoBlue text-white"
                      : "text-eleghoBlue bg-white border border-eleghoBlue hover:bg-eleghoBlue/5"
                  }`}
                  onClick={() => handlePagination(numberOfPages)}
                >
                  {numberOfPages}
                </button>,
              ].filter(Boolean)}
        </div>

        <button
          disabled={currentPage === numberOfPages}
          className="px-4 py-2 text-sm font-medium text-eleghoBlue bg-white border border-eleghoBlue rounded-lg hover:bg-eleghoBlue/5 focus:outline-none focus:ring-2 focus:ring-eleghoBlue/20 disabled:opacity-50 disabled:cursor-not-allowed transition-colors"
          onClick={handleNext}
        >
          Next
        </button>
      </div>
    </div>
  );
}
