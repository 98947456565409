import React from "react";

function MessageSenderCard({ message }) {
  //convert firebase timestamp to date
  const date = message.dateTime.toDate().toLocaleDateString("en-US", {
    year: "numeric",
    month: "short",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
  });

  return (
    <div className="flex flex-col items-end mb-4 group animate-fade-in w-full">
      <div className="flex items-center gap-2 mb-1.5">
        <div className="text-sm font-semibold text-gray-700 hover:text-emerald-600 transition-colors duration-200">
          {message.displayName}
        </div>
        <div className="text-xs text-gray-400 group-hover:text-gray-500 transition-colors duration-200">
          {date}
        </div>
      </div>
      <div className="flex justify-end w-full">
        <div className="relative max-w-[85%]">
          <div className="absolute -right-2 top-0 w-2 h-2 bg-emerald-500 rounded-full opacity-0 group-hover:opacity-100 transition-opacity duration-200"></div>
          <div className="bg-gradient-to-br from-emerald-50 to-emerald-100 rounded-2xl rounded-tr-sm px-4 py-3 shadow-md hover:shadow-lg transition-all duration-200 border border-emerald-100">
            <p className="text-gray-800 text-sm leading-relaxed break-words">
              {message.message}
            </p>
            <div className="absolute bottom-0 left-0 w-4 h-4 bg-gradient-to-br from-emerald-50 to-emerald-100 rounded-br-full"></div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MessageSenderCard;
