import React, { useEffect, useState } from "react";
import UserScreenWindow from "./UserScreenWindow";
import UserPhoto from "../assets/blankphoto.webp";
import { useSelector } from "react-redux";
import { useRef } from "react";
import {
  queryDocByField,
  queryDocByFieldSnap,
  setDocData,
  storage,
} from "../../utils/firebase/Firebase.utils";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { getDocDataSnap } from "../../utils/firebase/Firebase.utils";
import AddOccupantFormUser from "./AddOccupantFormUser";
import OccupantDetails from "./OccupantDetails";
import MobileModal from "../Dashboard/Components/MobileModal";
import { IoCamera, IoAdd, IoHome } from "react-icons/io5";
import { useNavigate } from "react-router-dom";

export default function UserProfile() {
  const currentUser = useSelector((state) => state.userObject);
  const inputRef = useRef(null);
  const [progress, setProgress] = useState(0);
  const [userData, setUserData] = useState({});
  const [addMode, setAddMode] = useState(false);
  const [occupantsArray, setOccupantsArray] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedOccupant, setSelectedOccupant] = useState({});
  const [viewMode, setViewMode] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserData = async () => {
      await getDocDataSnap("users", currentUser.uid, (data) => {
        setUserData(data);
      });
    };
    fetchUserData();
  }, []);

  useEffect(() => {
    const fetchOccupants = async () => {
      const occupantsPromises = await currentUser.linkedIdentifiers.map(
        async (identifier) => {
          const occupants = await queryDocByField(
            "residents",
            "identifier",
            "==",
            identifier
          );
          return {
            identifier: identifier,
            occupants: occupants[0]?.occupantsName?.map((item) => ({
              ...item,
              principalResident: occupants[0]?.id,
            })),
          };
        }
      );
      const occupants = await Promise.all(occupantsPromises);
      setOccupantsArray(occupants);
      setLoading(false);
    };
    fetchOccupants();
  }, [currentUser.linkedIdentifiers]);

  const handleUpload = async (e) => {
    if (!e.target.files[0]) return;

    const file = e.target.files[0];
    const storageRef = ref(storage, `userPhotos/${file.name}`);
    const uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setProgress(progress);
      },
      (error) => {},
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then(async (downloadURL) => {
          await setDocData("users", currentUser.uid, { photoUrl: downloadURL });
          setProgress(0);
        });
      }
    );
  };

  const handleViewMode = (occupant) => {
    setViewMode(true);
    setSelectedOccupant(occupant);
  };

  return (
    <UserScreenWindow label="User Profile">
      <div className="max-w-4xl mx-auto">
        {/* Profile Header Section */}
        <div className="bg-white rounded-xl shadow-sm p-6 mb-6">
          <div className="flex items-center gap-6">
            <div className="relative group">
              <div className="w-20 h-20 rounded-full overflow-hidden">
                <img
                  src={userData.photoUrl || UserPhoto}
                  alt="Profile"
                  className="w-full h-full object-cover"
                />
              </div>
              <button
                onClick={() => inputRef.current.click()}
                className="absolute bottom-0 right-0 bg-blue-900 text-white p-2 rounded-full hover:bg-blue-800 transition-colors"
              >
                <IoCamera className="w-4 h-4" />
              </button>
              <input
                type="file"
                className="hidden"
                ref={inputRef}
                onChange={handleUpload}
                accept="image/*"
              />
            </div>
            <div>
              <h2 className="text-xl font-semibold text-blue-900">
                {currentUser.displayName}
              </h2>
              <div className="text-sm text-gray-500 mt-1">
                {currentUser.linkedIdentifiers?.map((identifier) => (
                  <div key={identifier} className="flex items-center gap-2">
                    <IoHome className="w-4 h-4" />
                    <span>{identifier}</span>
                  </div>
                ))}
              </div>
            </div>
          </div>
          {progress > 0 && (
            <div className="mt-4">
              <div className="h-2 bg-gray-200 rounded-full overflow-hidden">
                <div
                  className="h-full bg-blue-900 transition-all duration-300"
                  style={{ width: `${progress}%` }}
                />
              </div>
            </div>
          )}
        </div>

        {/* Occupants Section */}
        <div className="bg-white rounded-xl shadow-sm p-6">
          <div className="flex items-center justify-between mb-6">
            <h3 className="text-lg font-semibold text-blue-900">
              Household Members
            </h3>
            <button
              onClick={() => setAddMode(true)}
              className="flex items-center gap-2 px-4 py-2 bg-blue-900 text-white rounded-lg hover:bg-blue-800 transition-colors text-sm"
            >
              <IoAdd className="w-4 h-4" />
              Register Member
            </button>
          </div>

          {loading ? (
            <div className="flex items-center justify-center py-8">
              <div className="animate-pulse text-gray-600">Loading...</div>
            </div>
          ) : occupantsArray.length === 0 ? (
            <div className="text-center py-8 text-gray-500">
              No household members registered yet
            </div>
          ) : (
            <div className="space-y-6">
              {occupantsArray.map((occupant, index) => (
                <div
                  key={index}
                  className="border-b border-gray-100 last:border-0 pb-4 last:pb-0"
                >
                  <div className="text-sm font-medium text-blue-900 mb-3">
                    {occupant.identifier}
                  </div>
                  <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-3">
                    {occupant?.occupants?.map((occ, idx) => (
                      <div
                        key={idx}
                        onClick={() => handleViewMode(occ)}
                        className="bg-gray-50 p-4 rounded-lg cursor-pointer hover:bg-gray-100 transition-colors"
                      >
                        <div className="font-medium text-gray-900">
                          {occ.firstName} {occ.lastName}
                        </div>
                        <div className="text-sm text-gray-500 mt-1">
                          {occ.relationship}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>

      {viewMode && (
        <MobileModal>
          <OccupantDetails
            occupant={selectedOccupant}
            setViewMode={setViewMode}
          />
        </MobileModal>
      )}

      {addMode && (
        <MobileModal>
          <AddOccupantFormUser setAddMode={setAddMode} />
        </MobileModal>
      )}
    </UserScreenWindow>
  );
}
