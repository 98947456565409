import React, { useEffect } from "react";
import UserScreenWindow from "./UserScreenWindow";
import { useParams } from "react-router-dom";
import { getDocData, setDocData } from "../../utils/firebase/Firebase.utils";
import { FiBell, FiClock, FiMessageSquare, FiCheck } from "react-icons/fi";

function NotificationDetails() {
  const { id } = useParams();
  const [notification, setNotification] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(true);

  useEffect(() => {
    const fetchNotification = async () => {
      const data = await getDocData("notifications", id);
      setNotification(data);
      setIsLoading(false);
    };
    fetchNotification();
  }, []);

  //update the notification to read when message is opened
  useEffect(() => {
    if (notification) {
      setDocData("notifications", id, { ...notification, read: true });
    }
  }, [notification, id]);

  if (isLoading) {
    return (
      <UserScreenWindow>
        <div className="flex items-center justify-center min-h-[400px]">
          <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-eleghoBlue"></div>
        </div>
      </UserScreenWindow>
    );
  }

  const date = notification.dateTime.toDate().toLocaleDateString("en-US", {
    year: "numeric",
    month: "short",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  });

  return (
    <UserScreenWindow label="Notifications">
      <div className="max-w-3xl mx-auto p-6">
        <div className="bg-white rounded-2xl shadow-sm border border-gray-100 overflow-hidden">
          {/* Header */}
          <div className="bg-eleghoBlue/5 p-6 border-b border-gray-100">
            <div className="flex items-center gap-3 mb-2">
              <div className="p-2 bg-eleghoBlue/10 rounded-lg">
                <FiBell className="w-5 h-5 text-eleghoBlue" />
              </div>
              <h1 className="text-xl font-semibold text-eleghoBlue">
                {notification.subject}
              </h1>
            </div>
            <div className="flex items-center gap-2 text-sm text-gray-600">
              <FiClock className="w-4 h-4" />
              <span>{date}</span>
            </div>
          </div>

          {/* Content */}
          <div className="p-6">
            <div className="flex items-start gap-3">
              <div className="p-2 bg-eleghoGreen/10 rounded-lg mt-1">
                <FiMessageSquare className="w-5 h-5 text-eleghoGreen" />
              </div>
              <div className="flex-1">
                <div className="text-gray-600 text-sm mb-4">
                  {notification.message}
                </div>
                <div className="flex items-center gap-2 text-sm text-eleghoGreen">
                  <FiCheck className="w-4 h-4" />
                  <span>Message read</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </UserScreenWindow>
  );
}

export default NotificationDetails;
