import React from "react";

const ProductCard = ({ product }) => {
  return (
    <div className="bg-white rounded-lg shadow-md overflow-hidden hover:shadow-lg transition-shadow duration-300 flex flex-col">
      <div className="h-32 sm:h-40 md:h-48 overflow-hidden">
        {product.images && product.images.length > 0 ? (
          <img
            src={product.images[0]}
            alt={product.productTitle}
            className="w-full h-full object-cover"
          />
        ) : (
          <div className="w-full h-full bg-gray-200 flex items-center justify-center">
            <span className="text-gray-400 text-xs sm:text-sm">No Image</span>
          </div>
        )}
      </div>

      <div className="p-2 sm:p-4 flex-grow">
        <h2 className="text-sm sm:text-lg font-semibold text-gray-800 mb-1 truncate">
          {product.productTitle}
        </h2>
        <p className="text-lg sm:text-xl font-bold text-green-600 mb-1 sm:mb-2">
          ₱{product.price}
        </p>

        <p
          className="text-xs sm:text-sm text-gray-600 mb-1 sm:mb-2 overflow-hidden text-ellipsis h-8 sm:h-10"
          style={{
            display: "-webkit-box",
            WebkitLineClamp: 2,
            WebkitBoxOrient: "vertical",
          }}
        >
          {product.description}
        </p>

        <div className="flex flex-wrap gap-1 mb-1 sm:mb-3">
          {product.productCategories &&
            product.productCategories.slice(0, 2).map((category, index) => (
              <span
                key={index}
                className="bg-blue-100 text-blue-800 text-xs px-1 py-0.5 sm:px-2 sm:py-1 rounded-full"
              >
                {category}
              </span>
            ))}
        </div>

        <div className="flex justify-between items-center text-xs text-gray-500">
          <span className="truncate max-w-[80px] sm:max-w-none">
            {product.createdName}
          </span>
          <span>{new Date(product.createdAt).toLocaleDateString()}</span>
        </div>
      </div>

      <div className="px-2 py-2 sm:px-4 sm:py-3 bg-gray-50 border-t mt-auto">
        <button className="w-full bg-blue-600 hover:bg-blue-700 text-white py-1.5 sm:py-2 px-2 sm:px-4 rounded-md transition-colors duration-300 text-xs sm:text-sm">
          Contact Seller
        </button>
      </div>
    </div>
  );
};

export default ProductCard;
