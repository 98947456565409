import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import {
  getDocDataSnap,
  setDocData,
  deleteDocById,
} from "../../../utils/firebase/Firebase.utils";
import { useSearchParams } from "react-router-dom";
import BasicInformation from "./BasicInformation";
import TenancyInformation from "./TenancyInformation";
import DocumentsTenants from "./DocumentsTenants";
import OccupantsInformationFullPage from "./OccupantsInformationFullPage";
import { IoArrowBack } from "react-icons/io5";

export default function TenantDetailsFullpage() {
  const { id } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const detail = searchParams.get("detail");
  const [activeLink, setActiveLink] = useState(detail);
  const [tenant, setTenant] = React.useState({});
  const [isLoading, setIsLoading] = React.useState(true);

  useEffect(() => {
    setSearchParams({ detail: activeLink });
  }, [activeLink]);

  useEffect(() => {
    const fetchTenant = async () => {
      getDocDataSnap("tenants", id, (tenant) => {
        setTenant(tenant);
        setIsLoading(false);
      });
    };
    fetchTenant();
  }, []);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  const activeLinkStyle =
    "bg-white text-blue-900 shadow-lg rounded-lg font-bold p-2";
  const inactiveLinkStyle =
    "text-gray-400 hover:font-bold hover:bg-white cursor-pointer p-2 rounded-lg hover:shadow-lg";

  const links = [
    "Basic Information",
    "Tenancy Information",
    "Occupants",
    "Others",
  ];

  return (
    <div className="text-left text-gray-600 max-w-7xl mx-auto mt-4">
      <div className="mb-4">
        <div className="flex items-center gap-2">
          <Link to="/resident-management?link=Long+Term+Leases">
            <div className="hover:bg-white px-4 py-2 rounded-full cursor-pointer text-lg bg-gray-300">
              <IoArrowBack className="inline" />
            </div>
          </Link>
          <div className="font-bold text-blue-900 capitalize text-xl">
            Tenant Details
          </div>
        </div>
      </div>

      <div className="rounded-lg flex items-center gap-4 text-xs bg-gray-200 p-1 w-fit text-gray-400 font-bold border border-gray-400">
        {links.map((link, index) => (
          <div
            key={index}
            className={`${
              activeLink === link ? activeLinkStyle : inactiveLinkStyle
            } cursor-pointer`}
            onClick={() => setActiveLink(link)}
          >
            {link}
          </div>
        ))}
      </div>

      {/* display the active link content */}

      {detail === "Basic Information" && (
        <div>
          <BasicInformation tenant={tenant} />
        </div>
      )}
      {detail === "Tenancy Information" && (
        <div>
          <TenancyInformation tenant={tenant} />
        </div>
      )}
      {detail === "Others" && (
        <div className="">
          <DocumentsTenants tenant={tenant} />
        </div>
      )}

      {detail === "Occupants" && (
        <div className="">
          <OccupantsInformationFullPage tenant={tenant} />
        </div>
      )}
    </div>
  );
}
