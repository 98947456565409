import React from "react";
import PageModal from "../../Dashboard/Components/PageModal";

function PhotoModal({ photoUrl, onClose }) {
  if (!photoUrl) return null;

  return (
    <PageModal>
      <div className="bg-white p-4 rounded-lg max-w-4xl relative">
        <button
          onClick={onClose}
          className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
        >
          ×
        </button>
        <img
          src={photoUrl}
          alt="Announcement"
          className="max-w-full max-h-[80vh]"
        />
      </div>
    </PageModal>
  );
}

export default PhotoModal;
