import React, { useState } from "react";
import {
  FaUser,
  FaIdCard,
  FaBirthdayCake,
  FaPhone,
  FaEnvelope,
  FaMapMarkerAlt,
  FaBriefcase,
  FaUserFriends,
  FaBuilding,
  FaPencilAlt,
  FaSave,
  FaTimes,
} from "react-icons/fa";

export default function OccupantDetails({ occupant, setViewMode, onUpdate }) {
  const [isEditing, setIsEditing] = useState(false);
  const [formData, setFormData] = useState(occupant);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (onUpdate) {
      await onUpdate(formData);
    }
    setIsEditing(false);
  };

  const genderOptions = ["Male", "Female", "Other"];
  const civilStatusOptions = ["Single", "Married", "Divorced", "Widowed"];

  const SectionHeader = ({ icon: Icon, title }) => (
    <div className="flex items-center gap-2 pb-3 mb-4 border-b border-gray-100">
      <div className="p-1.5 rounded-lg bg-eleghoBlue/10">
        <Icon className="w-4 h-4 text-eleghoBlue" />
      </div>
      <h3 className="text-base font-medium text-gray-900">{title}</h3>
    </div>
  );

  const InfoField = ({ icon: Icon, label, value }) => (
    <div className="bg-gray-50/50 p-3 rounded-lg space-y-1">
      <div className="flex items-center gap-2 text-eleghoGreen/80 text-sm font-medium">
        <Icon className="w-4 h-4 flex-shrink-0" />
        <span>{label}</span>
      </div>
      <p className="text-gray-600 pl-6 break-words">
        {value || "Not provided"}
      </p>
    </div>
  );

  const InputField = ({ icon: Icon, label, ...props }) => (
    <div className="bg-white p-3 rounded-lg space-y-2 border border-gray-100">
      <div className="flex items-center gap-2 text-eleghoGreen/80 text-sm font-medium">
        <Icon className="w-4 h-4 flex-shrink-0" />
        <span>{label}</span>
      </div>
      <input
        className="w-full pl-6 pr-3 py-2 text-sm border border-gray-200 rounded-lg focus:ring-2 focus:ring-eleghoBlue/20 focus:border-eleghoBlue"
        {...props}
      />
    </div>
  );

  const SelectField = ({ icon: Icon, label, options, ...props }) => (
    <div className="bg-white p-3 rounded-lg space-y-2 border border-gray-100">
      <div className="flex items-center gap-2 text-eleghoGreen/80 text-sm font-medium">
        <Icon className="w-4 h-4 flex-shrink-0" />
        <span>{label}</span>
      </div>
      <select
        className="w-full pl-6 pr-3 py-2 text-sm border border-gray-200 rounded-lg focus:ring-2 focus:ring-eleghoBlue/20 focus:border-eleghoBlue"
        {...props}
      >
        <option value="">Select {label}</option>
        {options.map((option) => (
          <option key={option} value={option}>
            {option}
          </option>
        ))}
      </select>
    </div>
  );

  return (
    <div className="bg-white min-h-screen text-sm relative">
      {/* Sticky Header */}
      <div className="sticky top-0 z-10 bg-white border-b border-gray-100 px-4 py-3 sm:px-6">
        <div className="flex flex-col sm:flex-row sm:items-center sm:justify-between gap-3">
          <div>
            <h2 className="text-eleghoBlue text-lg sm:text-xl font-semibold">
              {formData.firstName} {formData.lastName}
            </h2>
            <p className="text-gray-500 text-sm">{formData.relationship}</p>
          </div>
          <div className="flex gap-2">
            {!isEditing ? (
              <>
                <button
                  className="flex-1 sm:flex-none px-4 py-2 bg-eleghoBlue/10 text-eleghoBlue rounded-md hover:bg-eleghoBlue/20 transition-colors flex items-center justify-center gap-2"
                  onClick={() => setIsEditing(true)}
                >
                  <FaPencilAlt className="w-4 h-4" />
                  <span>Edit</span>
                </button>
                <button
                  className="flex-1 sm:flex-none px-4 py-2 bg-eleghoGreen/10 text-eleghoGreen rounded-md hover:bg-eleghoGreen/20 transition-colors flex items-center justify-center gap-2"
                  onClick={() => setViewMode(false)}
                >
                  <FaTimes className="w-4 h-4" />
                  <span>Close</span>
                </button>
              </>
            ) : (
              <>
                <button
                  className="flex-1 sm:flex-none px-4 py-2 bg-eleghoBlue/10 text-eleghoBlue rounded-md hover:bg-eleghoBlue/20 transition-colors flex items-center justify-center gap-2"
                  onClick={handleSubmit}
                >
                  <FaSave className="w-4 h-4" />
                  <span>Save</span>
                </button>
                <button
                  className="flex-1 sm:flex-none px-4 py-2 bg-red-100 text-red-600 rounded-md hover:bg-red-200 transition-colors flex items-center justify-center gap-2"
                  onClick={() => {
                    setFormData(occupant);
                    setIsEditing(false);
                  }}
                >
                  <FaTimes className="w-4 h-4" />
                  <span>Cancel</span>
                </button>
              </>
            )}
          </div>
        </div>
      </div>

      {/* Content */}
      <form onSubmit={handleSubmit} className="p-4 sm:p-6 space-y-6">
        {/* Personal Information */}
        <section className="bg-white rounded-lg">
          <SectionHeader icon={FaUser} title="Personal Information" />
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
            {isEditing ? (
              <>
                <InputField
                  icon={FaUser}
                  label="First Name"
                  name="firstName"
                  value={formData.firstName}
                  onChange={handleChange}
                  required
                />
                <InputField
                  icon={FaUser}
                  label="Middle Name"
                  name="middleName"
                  value={formData.middleName}
                  onChange={handleChange}
                />
                <InputField
                  icon={FaUser}
                  label="Last Name"
                  name="lastName"
                  value={formData.lastName}
                  onChange={handleChange}
                  required
                />
                <InputField
                  icon={FaUserFriends}
                  label="Relationship"
                  name="relationship"
                  value={formData.relationship}
                  onChange={handleChange}
                  required
                />
                <InputField
                  icon={FaBirthdayCake}
                  label="Date of Birth"
                  name="dateOfBirth"
                  type="date"
                  value={formData.dateOfBirth}
                  onChange={handleChange}
                  required
                />
                <SelectField
                  icon={FaUser}
                  label="Gender"
                  name="gender"
                  value={formData.gender}
                  options={genderOptions}
                  onChange={handleChange}
                  required
                />
                <SelectField
                  icon={FaIdCard}
                  label="Civil Status"
                  name="civilStatus"
                  value={formData.civilStatus}
                  options={civilStatusOptions}
                  onChange={handleChange}
                  required
                />
              </>
            ) : (
              <>
                <InfoField
                  icon={FaUser}
                  label="First Name"
                  value={formData.firstName}
                />
                <InfoField
                  icon={FaUser}
                  label="Middle Name"
                  value={formData.middleName}
                />
                <InfoField
                  icon={FaUser}
                  label="Last Name"
                  value={formData.lastName}
                />
                <InfoField
                  icon={FaUserFriends}
                  label="Relationship"
                  value={formData.relationship}
                />
                <InfoField
                  icon={FaBirthdayCake}
                  label="Date of Birth"
                  value={formData.dateOfBirth}
                />
                <InfoField
                  icon={FaUser}
                  label="Gender"
                  value={formData.gender}
                />
                <InfoField
                  icon={FaIdCard}
                  label="Civil Status"
                  value={formData.civilStatus}
                />
              </>
            )}
          </div>
        </section>

        {/* Contact Information */}
        <section className="bg-white rounded-lg">
          <SectionHeader icon={FaPhone} title="Contact Information" />
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
            {isEditing ? (
              <>
                <InputField
                  icon={FaPhone}
                  label="Contact Number"
                  name="contactNumber"
                  value={formData.contactNumber}
                  onChange={handleChange}
                  required
                />
                <InputField
                  icon={FaEnvelope}
                  label="Email"
                  name="email"
                  type="email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                />
                <InputField
                  icon={FaMapMarkerAlt}
                  label="Address"
                  name="address"
                  value={formData.address}
                  onChange={handleChange}
                  required
                />
              </>
            ) : (
              <>
                <InfoField
                  icon={FaPhone}
                  label="Contact Number"
                  value={formData.contactNumber}
                />
                <InfoField
                  icon={FaEnvelope}
                  label="Email"
                  value={formData.email}
                />
                <InfoField
                  icon={FaMapMarkerAlt}
                  label="Address"
                  value={formData.address}
                />
              </>
            )}
          </div>
        </section>

        {/* Employment Information */}
        <section className="bg-white rounded-lg">
          <SectionHeader icon={FaBriefcase} title="Employment Information" />
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
            {isEditing ? (
              <>
                <InputField
                  icon={FaBriefcase}
                  label="Occupation"
                  name="occupation"
                  value={formData.occupation}
                  onChange={handleChange}
                />
                <InputField
                  icon={FaBuilding}
                  label="Employer"
                  name="employer"
                  value={formData.employer}
                  onChange={handleChange}
                />
              </>
            ) : (
              <>
                <InfoField
                  icon={FaBriefcase}
                  label="Occupation"
                  value={formData.occupation}
                />
                <InfoField
                  icon={FaBuilding}
                  label="Employer"
                  value={formData.employer}
                />
              </>
            )}
          </div>
        </section>

        {/* Emergency Contact */}
        <section className="bg-white rounded-lg">
          <SectionHeader icon={FaPhone} title="Emergency Contact" />
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
            {isEditing ? (
              <>
                <InputField
                  icon={FaUser}
                  label="Emergency Contact"
                  name="emergencyContact"
                  value={formData.emergencyContact}
                  onChange={handleChange}
                  required
                />
                <InputField
                  icon={FaPhone}
                  label="Emergency Contact Number"
                  name="emergencyContactNumber"
                  value={formData.emergencyContactNumber}
                  onChange={handleChange}
                  required
                />
                <InputField
                  icon={FaUserFriends}
                  label="Emergency Contact Relationship"
                  name="emergencyContactRelationship"
                  value={formData.emergencyContactRelationship}
                  onChange={handleChange}
                  required
                />
              </>
            ) : (
              <>
                <InfoField
                  icon={FaUser}
                  label="Emergency Contact"
                  value={formData.emergencyContact}
                />
                <InfoField
                  icon={FaPhone}
                  label="Emergency Contact Number"
                  value={formData.emergencyContactNumber}
                />
                <InfoField
                  icon={FaUserFriends}
                  label="Emergency Contact Relationship"
                  value={formData.emergencyContactRelationship}
                />
              </>
            )}
          </div>
        </section>
      </form>
    </div>
  );
}
