import React from "react";
import AdminHeaderMobile from "./AdminHeaderMobile";
import { Outlet, Link, useLocation } from "react-router-dom";
import { FiHome, FiAlertCircle, FiMessageSquare, FiUser } from "react-icons/fi";

function AdminDashboardLayout() {
  const location = useLocation();

  const isActive = (path) => {
    return location.pathname === path;
  };

  return (
    <div className="max-w-[450px] mx-auto bg-gray-100 min-h-screen flex flex-col">
      <div className="flex-grow">
        <Outlet />
      </div>

      {/* Bottom Navigation */}
      <div className="fixed bottom-0 left-0 right-0 max-w-[450px] mx-auto bg-white shadow-lg border-t border-gray-200">
        <div className="flex items-center justify-around py-2">
          <Link
            to="/admin/mobile"
            className={`flex flex-col items-center p-2 ${
              isActive("/admin/mobile") ? "text-blue-600" : "text-gray-600"
            }`}
          >
            <FiHome className="w-6 h-6" />
            <span className="text-xs mt-1">Home</span>
          </Link>
          <Link
            to="/admin/mobile/tickets"
            className={`flex flex-col items-center p-2 ${
              isActive("/admin/mobile/tickets")
                ? "text-blue-600"
                : "text-gray-600"
            }`}
          >
            <FiAlertCircle className="w-6 h-6" />
            <span className="text-xs mt-1">Tickets</span>
          </Link>
          <Link
            to="/admin/mobile/chat"
            className={`flex flex-col items-center p-2 ${
              isActive("/admin/mobile/chat") ? "text-blue-600" : "text-gray-600"
            }`}
          >
            <FiMessageSquare className="w-6 h-6" />
            <span className="text-xs mt-1">Chat</span>
          </Link>
          <Link
            to="/admin/mobile/profile"
            className={`flex flex-col items-center p-2 ${
              isActive("/admin/mobile/profile")
                ? "text-blue-600"
                : "text-gray-600"
            }`}
          >
            <FiUser className="w-6 h-6" />
            <span className="text-xs mt-1">Profile</span>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default AdminDashboardLayout;
