import React, { useEffect } from "react";
import ResidentInformationPage from "../TenantManagement/ResidentInformationPage";
import OccupantsInformationPage from "../TenantManagement/OccupantsInformationPage";
import VehiclesInformationPage from "../TenantManagement/VehiclesInformationPage";
import HistoryInformationPage from "../TenantManagement/HistoryInformationPage";
import ResidentVehicleRegistration from "./ResidentVehicleRegistration";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import {
  deleteDocById,
  getAllDocsSnap,
  getDocById,
  getDocDataSnap,
  setDocData,
} from "../../../utils/firebase/Firebase.utils";
import toast from "react-hot-toast";
import { IoArrowBack } from "react-icons/io5";
import { FaTrashAlt } from "react-icons/fa";
import ProfilePicture from "./ProfilePicture";
import { FaMobileAlt } from "react-icons/fa";
import { MdOutlineMailOutline } from "react-icons/md";
import { MdOutlineMarkEmailUnread } from "react-icons/md";
import { BsThreeDotsVertical } from "react-icons/bs";
import { BsThreeDots } from "react-icons/bs";
import ErrorIcon from "@mui/icons-material/Error";
import { formatPhoneNumber, uploadFile } from "../../../utils/EleghoUtils";
import { motion } from "framer-motion";
import { useRef } from "react";
import ResidentConcernsAndRequests from "./ResidentConcernsAndRequests";

const calculateCompletionPercentage = (data) => {
  const requiredFields = [
    "owner1",
    "owner2",
    "nationality",
    "address",
    "email",
    "phoneNumber",
    "phase",
    "block",
    "lot",
    "unitType",
    "lotSize",
    "numberOfOccupants",
  ];

  const filledFields = requiredFields.filter(
    (field) => data[field]
    // && data[field].trim() !== ""
  );
  return Math.round((filledFields.length / requiredFields.length) * 100);
};

const CircularProgress = ({ percentage }) => {
  const radius = 16;
  const circumference = 2 * Math.PI * radius;
  const strokeDashoffset = circumference - (percentage / 100) * circumference;

  return (
    <div className="relative w-12 h-12">
      <svg className="w-full h-full transform -rotate-90" viewBox="0 0 40 40">
        {/* Background circle */}
        <circle
          className="text-gray-200"
          strokeWidth="4"
          stroke="currentColor"
          fill="transparent"
          r={radius}
          cx="20"
          cy="20"
        />
        {/* Progress circle */}
        <circle
          className={
            percentage >= 90
              ? "text-green-500"
              : percentage >= 70
              ? "text-eleghoBlue"
              : percentage >= 50
              ? "text-yellow-500"
              : "text-red-500"
          }
          strokeWidth="4"
          strokeDasharray={circumference}
          strokeDashoffset={strokeDashoffset}
          strokeLinecap="round"
          stroke="currentColor"
          fill="transparent"
          r={radius}
          cx="20"
          cy="20"
        />
      </svg>
      <div className="absolute inset-0 flex items-center justify-center">
        <span
          className={`text-sm font-medium ${
            percentage >= 90
              ? "text-green-500"
              : percentage >= 70
              ? "text-eleghoBlue"
              : percentage >= 50
              ? "text-yellow-500"
              : "text-red-500"
          }`}
        >
          {percentage}%
        </span>
      </div>
    </div>
  );
};

export default function ResidentDetailsFullpage() {
  const [activeLink, setActiveLink] = React.useState("Resident Information");
  const currentUser = useSelector((state) => state.userObject);
  const { id } = useParams();
  const navigate = useNavigate();
  const [data, setData] = React.useState({});
  const [isLoading, setIsLoading] = React.useState(true);
  const fileInput = useRef(null);
  const [uploadingInProgress, setUploadingInProgress] = React.useState(false);
  const [progress, setProgress] = React.useState(0);
  const [formData, setFormData] = React.useState({});

  useEffect(() => {
    const fetchResident = async () => {
      await getDocDataSnap("residents", id, (data) => {
        setData(data);
        setIsLoading(false);
      });
    };
    fetchResident();
  }, []);

  useEffect(() => {
    const updateData = async () => {
      await setDocData("residents", id, formData);
    };
    if (Object.keys(formData).length > 0) {
      updateData();
    }
  }, [formData]);

  if (isLoading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-eleghoBlue"></div>
      </div>
    );
  }

  const links = [
    "Resident Information",
    "Occupants Information",
    "Vehicles",
    "Concerns & Requests",
    "Billings",
    "Violations",
    "History",
  ];

  const handleDelete = async () => {
    if (window.confirm("Are you sure you want to delete this resident?")) {
      await deleteDocById("residents", id);
      navigate("/resident-management?link=Owners");
      toast.success("Resident deleted successfully");
    }
  };

  const allowedAccess = ["admin", "super-admin"];

  const handleOpenEmailApp = () => {
    window.open(`mailto:${data.email}`);
  };

  const handleUpload = async (e) => {
    const res = await uploadFile(
      e,
      "residents",
      setUploadingInProgress,
      setProgress,
      setFormData,
      "photo"
    );
  };

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-4">
      <input
        type="file"
        className="hidden"
        ref={fileInput}
        onChange={handleUpload}
      />

      {/* Header Section */}
      <div className="flex items-center justify-between mb-4">
        <div className="flex items-center gap-3">
          <Link
            to="/resident-management?link=Owners"
            className="p-1.5 hover:bg-gray-50 rounded-lg transition-colors duration-200"
          >
            <IoArrowBack className="w-4 h-4 text-gray-500" />
          </Link>
          <div className="flex items-start flex-col">
            <h1 className="text-lg font-medium text-gray-900">
              Resident Details
            </h1>
            <p className="text-xs text-gray-500">
              View and manage resident information
            </p>
          </div>
        </div>

        <div className="flex items-center gap-2">
          {currentUser.roles.some((role) => allowedAccess.includes(role)) && (
            <button
              onClick={handleDelete}
              className="inline-flex items-center px-3 py-1.5 text-xs font-medium text-red-600 bg-red-50 rounded-md hover:bg-red-100 transition-colors duration-200"
              title="Delete Resident"
            >
              <FaTrashAlt className="w-3.5 h-3.5 mr-1.5" />
              Delete
            </button>
          )}
        </div>
      </div>

      {/* Profile Card */}
      <div className="bg-white rounded-lg shadow-sm overflow-hidden mb-4">
        <div className="p-5">
          <div className="flex items-start gap-6">
            {/* Profile Picture Section */}
            <div
              className="shrink-0 cursor-pointer"
              onClick={() => fileInput.current.click()}
            >
              <ProfilePicture data={data} />
            </div>

            {/* Main Content Section */}
            <div className="flex-1 min-w-0">
              {/* Top Row: Name, Status, and Actions */}
              <div className="flex items-start justify-between gap-4">
                <div className="min-w-0">
                  <div className="flex items-center gap-2">
                    <h2 className="text-base font-medium text-gray-900 truncate">
                      {data.owner1}
                    </h2>
                    {data.notMigs ? (
                      <div className="inline-flex items-center gap-1 px-2 py-0.5 bg-red-50 rounded-full">
                        <ErrorIcon className="w-3.5 h-3.5 text-red-500" />
                        <span className="text-xs font-medium text-red-600">
                          Not In Good Standing
                        </span>
                      </div>
                    ) : (
                      <div className="inline-flex items-center gap-1 px-2 py-0.5 bg-green-50 rounded-full">
                        <CheckCircleIcon className="w-3.5 h-3.5 text-green-500" />
                        <span className="text-xs font-medium text-green-600">
                          Good Standing
                        </span>
                      </div>
                    )}
                  </div>
                </div>

                <div className="flex items-center gap-2 shrink-0">
                  <button
                    onClick={handleOpenEmailApp}
                    className="inline-flex items-center px-3 py-1.5 text-xs font-medium text-white bg-eleghoBlue rounded-md hover:bg-eleghoBlue/90 transition-colors duration-200"
                  >
                    <MdOutlineMarkEmailUnread className="w-3.5 h-3.5 mr-1.5" />
                    Send Email
                  </button>
                </div>
              </div>

              {/* Contact Information */}
              <div className="mt-4 flex items-center justify-between">
                <div className="flex flex-wrap gap-4">
                  <div className="flex items-center gap-2 text-gray-600 bg-gray-50 px-3 py-1.5 rounded-md">
                    <FaMobileAlt className="w-4 h-4 text-gray-400" />
                    <span className="text-xs font-medium">
                      {formatPhoneNumber(data.phoneNumber)}
                    </span>
                  </div>
                  <div className="flex items-center gap-2 text-gray-600 bg-gray-50 px-3 py-1.5 rounded-md">
                    <MdOutlineMailOutline className="w-4 h-4 text-gray-400" />
                    <span className="text-xs font-medium">{data.email}</span>
                  </div>
                </div>

                {/* Data Completion Status */}
                <div className="flex items-center gap-3 bg-gray-50 px-4 py-2 rounded-lg">
                  <CircularProgress
                    percentage={calculateCompletionPercentage(data)}
                  />
                  <div className="flex flex-col">
                    <span className="text-xs font-medium text-gray-900">
                      Profile Complete
                    </span>
                    <span className="text-xs text-gray-500">
                      {calculateCompletionPercentage(data)}% of fields filled
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Navigation Tabs */}
      <div className="border-b border-gray-200 mb-4">
        <nav className="-mb-px flex space-x-6 overflow-x-auto">
          {links.map((link, index) => (
            <button
              key={index}
              onClick={() => setActiveLink(link)}
              className={`
                whitespace-nowrap py-2.5 px-1 border-b-2 font-medium text-xs
                ${
                  activeLink === link
                    ? "border-eleghoBlue text-eleghoBlue"
                    : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300"
                }
              `}
            >
              {link}
            </button>
          ))}
        </nav>
      </div>

      {/* Content Section */}
      <div className="bg-white rounded-lg shadow-sm p-4">
        {activeLink === "Resident Information" && <ResidentInformationPage />}
        {activeLink === "Occupants Information" && <OccupantsInformationPage />}
        {activeLink === "Vehicles" && <ResidentVehicleRegistration />}
        {activeLink === "Concerns & Requests" && (
          <ResidentConcernsAndRequests data={data} />
        )}
        {activeLink === "History" && <HistoryInformationPage />}
      </div>
    </div>
  );
}
