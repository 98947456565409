import React from "react";
import {
  FiHome,
  FiCalendar,
  FiPackage,
  FiTruck,
  FiCheck,
} from "react-icons/fi";
import { useCompany } from "../../context/CompanyContext";

export default function GatePassCard({ data }) {
  const getStatusColor = (status) => {
    switch (status.toLowerCase()) {
      case "pending":
        return "bg-yellow-100 text-yellow-600";
      case "approved":
        return "bg-eleghoGreen/10 text-eleghoGreen";
      case "rejected":
        return "bg-red-100 text-red-600";
      case "completed":
        return "bg-blue-100 text-blue-600";
      default:
        return "bg-gray-100 text-gray-600";
    }
  };

  const { getCapitalizedTerm } = useCompany();

  return (
    <div className="bg-white flex items-center gap-4 p-4 rounded-xl border border-gray-100 hover:border-eleghoBlue/20 hover:shadow-sm text-left transition-all duration-200">
      {/* Unit Information */}
      <div className="w-28">
        <div className="flex items-center gap-2">
          <FiHome className="w-4 h-4 text-eleghoBlue" />
          <div>
            <div className="text-xs text-gray-500">
              {getCapitalizedTerm("identifier")}
            </div>
            <div className="font-medium text-sm text-gray-900">
              {data.identifier}
            </div>
          </div>
        </div>
      </div>

      {/* Request Type */}
      <div className="w-32">
        <div className="flex items-center gap-2">
          <FiPackage className="w-4 h-4 text-eleghoBlue" />
          <div>
            <div className="text-xs text-gray-500">Request Type</div>
            <div className="font-medium text-sm text-gray-900 capitalize">
              {data.requestType || "Delivery"}
            </div>
          </div>
        </div>
      </div>

      {/* Delivery Date */}
      <div className="w-36">
        <div className="flex items-center gap-2">
          <FiCalendar className="w-4 h-4 text-eleghoBlue" />
          <div>
            <div className="text-xs text-gray-500">Delivery Date</div>
            <div className="font-medium text-sm text-gray-900">
              {data.deliveryDate}
            </div>
          </div>
        </div>
      </div>

      {/* Provider/Company */}
      <div className="w-48">
        <div className="flex items-center gap-2">
          <FiTruck className="w-4 h-4 text-eleghoBlue" />
          <div>
            <div className="text-xs text-gray-500">Provider</div>
            <div className="font-medium text-sm text-gray-900 capitalize truncate">
              {data.deliveryCompany}
            </div>
          </div>
        </div>
      </div>

      {/* Status */}
      <div className="w-24">
        <div
          className={`capitalize text-xs font-medium px-3 py-1.5 rounded-full ${getStatusColor(
            data.status
          )}`}
        >
          {data.status}
        </div>
      </div>

      {/* Action Button */}
      <div className="w-20 ml-auto">
        <button className="bg-eleghoBlue/10 text-eleghoBlue px-3 py-1.5 rounded-lg text-xs font-medium flex items-center gap-1 hover:bg-eleghoBlue/20 transition-colors">
          <FiCheck className="w-3 h-3" />
          <span>View</span>
        </button>
      </div>
    </div>
  );
}
