import React from "react";
import { useState, useEffect } from "react";
import { getAllDocsSnap } from "../../utils/firebase/Firebase.utils";
import { useNavigate } from "react-router-dom";

export default function AdminResidentListMobile() {
  const [residents, setResidents] = useState([]);
  const [filteredResidents, setFilteredResidents] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const fetchResidents = async () => {
      await getAllDocsSnap("residents", (docs) => {
        setResidents(docs);
        setFilteredResidents(docs);
        setIsLoading(false);
      });
    };
    fetchResidents();
  }, []);

  useEffect(() => {
    if (searchTerm.trim() === "") {
      setFilteredResidents(residents);
    } else {
      const lowercasedSearch = searchTerm.toLowerCase();
      const filtered = residents.filter(
        (resident) =>
          (resident.identifier &&
            resident.identifier.toLowerCase().includes(lowercasedSearch)) ||
          (resident.owner1 &&
            resident.owner1.toLowerCase().includes(lowercasedSearch)) ||
          (resident.owner2 &&
            resident.owner2.toLowerCase().includes(lowercasedSearch))
      );
      setFilteredResidents(filtered);
    }
  }, [searchTerm, residents]);

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleBack = () => {
    navigate(-1);
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="p-4">
      <div className="flex items-center mb-4">
        <button
          onClick={handleBack}
          className="mr-2 p-2 rounded-md"
          style={{ backgroundColor: "#363062", color: "white" }}
        >
          ← Back
        </button>
        <h1 className="text-lg font-bold" style={{ color: "#363062" }}>
          Residents
        </h1>
      </div>
      <div className="mb-4">
        <input
          type="text"
          placeholder="Search residents..."
          value={searchTerm}
          onChange={handleSearch}
          className="w-full p-2 border rounded-md"
          style={{ borderColor: "#8FCD00" }}
        />
      </div>
      <div className="grid gap-4">
        {filteredResidents.map((resident) => (
          <div
            key={resident.id}
            className="p-4 rounded-lg shadow-md border"
            style={{ borderColor: "#8FCD00" }}
          >
            <div className="font-medium" style={{ color: "#363062" }}>
              {resident.identifier}
            </div>
            <div className="mt-2">
              <p>{resident.owner1}</p>
              {resident.owner2 && <p>{resident.owner2}</p>}
            </div>
          </div>
        ))}
      </div>
      {filteredResidents.length === 0 && (
        <div className="text-center py-4">No residents found</div>
      )}
    </div>
  );
}
