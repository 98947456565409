import React from "react";
import UserScreenWindow from "../UserScreenWindow";
import { LuShoppingBasket } from "react-icons/lu";
import { LuDoorOpen } from "react-icons/lu";
import { FaSquareParking } from "react-icons/fa6";
import SearchBar from "./SearchBar";
import IconButton from "./IconButton";
import { IoPencil } from "react-icons/io5";
import { FaList } from "react-icons/fa";
import NewListingProperty from "./NewListingProperty";
import PageModal from "../../Dashboard/Components/PageModal";
import { useNavigate } from "react-router-dom";
import Properties from "./Properties";
import PropertiesCard from "./Properties";
import Products from "./Products";
import Parking from "./Parking";

export default function Marketplace() {
  const navigate = useNavigate();
  const [activeCategory, setActiveCategory] = React.useState(1);
  const [searchTerm, setSearchTerm] = React.useState("");
  const [addMode, setAddMode] = React.useState(false);

  const categories = [
    {
      id: 1,
      name: "Products/Services",
      icon: <LuShoppingBasket />,
    },
    {
      id: 2,
      name: "Units for Sale/Lease",
      icon: <LuDoorOpen />,
    },
    {
      id: 3,
      name: "Parking Space",
      icon: <FaSquareParking />,
    },
  ];

  const handleAddMode = () => {
    if (activeCategory === 2) {
      navigate("/new-listing-property");
    }
    if (activeCategory === 1) {
      navigate("/new-product-service");
    }
    if (activeCategory === 3) {
      navigate("/new-parking-space");
    }
  };
  return (
    <UserScreenWindow label="Marketplace">
      <div>
        <div className="category-container">
          {categories.map((category, index) => (
            <div
              key={category.id}
              className={
                activeCategory === category.id
                  ? "active-category flex items-center gap-1"
                  : "inactive-category flex items-center gap-1"
              }
              onClick={() => setActiveCategory(category.id)}
            >
              <div>{category.icon}</div>
              <div>{category.name}</div>
            </div>
          ))}
        </div>

        <div className="mt-4">
          <SearchBar
            placeholder={categories[activeCategory - 1].name}
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
          />
        </div>

        <div className="mt-4 flex items-center justify-center gap-8">
          <IconButton
            icon={<IoPencil />}
            label="Create Listing"
            onClick={handleAddMode}
          />
        </div>
      </div>

      <div className="mt-4">
        {activeCategory === 2 && <Properties searchTerm={searchTerm} />}
      </div>
      <div className="mt-4">
        {activeCategory === 1 && <Products searchTerm={searchTerm} />}
      </div>
      <div className="mt-4">
        {activeCategory === 3 && <Parking searchTerm={searchTerm} />}
      </div>
    </UserScreenWindow>
  );
}
