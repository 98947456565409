import React, { useEffect, useState } from "react";
import AddContactForm from "./AddContactForm";
import Modal from "../Components/Modal";
import {
  deleteDocById,
  getAllDocsSnap,
} from "../../../utils/firebase/Firebase.utils";
import toast from "react-hot-toast";
import BentoTable from "../../../components/BentoTable";
import PageModal from "../Components/PageModal";
import {
  FiPhone,
  FiMail,
  FiSearch,
  FiPlus,
  FiEdit,
  FiTrash2,
} from "react-icons/fi";
import { useCompany } from "../../../context/CompanyContext";

export default function ContactsAdmin() {
  const { getCapitalizedTerm } = useCompany();
  const [addMode, setAddMode] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [contacts, setContacts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedContact, setSelectedContact] = useState({});

  const links = [getCapitalizedTerm("directory"), "Emergency Hotlines"];

  const [activeLink, setActiveLink] = useState(links[0]);
  const [searchTerm, setSearchTerm] = useState("");

  const [numberOfItemPerPage, setNumberOfItemPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    const fetchContacts = async () => {
      await getAllDocsSnap("contacts", (data) => {
        setContacts(data);
        setLoading(false);
      });
    };
    fetchContacts();
  }, []);

  if (loading) {
    return <div>Loading</div>;
  }

  const handleAddMode = () => {
    setAddMode(!addMode);
  };

  const handleEdit = (contact) => {
    setEditMode(true);
    setSelectedContact(contact);
  };

  const handleDelete = async (contact) => {
    if (window.confirm("Are you sure you want to delete this contact?")) {
      await deleteDocById("contacts", contact.id);
      toast.success("Contact Deleted Successfully");
    }
  };

  const currentList = contacts.filter((contact) => {
    return contact.category === activeLink;
  });

  const filteredList = currentList.filter((contact) => {
    return (
      contact.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      contact.title.toLowerCase().includes(searchTerm.toLowerCase())
    );
  });

  const slicedItems = filteredList.slice(
    (currentPage - 1) * numberOfItemPerPage,
    currentPage * numberOfItemPerPage
  );

  const stats = {
    totalContacts: currentList.length,
    emergencyContacts: contacts.filter(
      (c) => c.category === "Emergency Hotlines"
    ).length,
    directoryContacts: contacts.filter(
      (c) => c.category === "Condo Corp Directory"
    ).length,
  };

  return (
    <div className="text-xs max-w-7xl mx-auto px-4 sm:px-6">
      <div className="title-header mt-4 text-lg sm:text-xl font-semibold">
        Contact Management
      </div>

      {/* Stats Cards */}
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 sm:gap-6 my-4 sm:my-6">
        <div className="bg-white p-4 sm:p-6 rounded-xl border border-gray-100 shadow-sm hover:shadow-md transition-shadow">
          <div className="flex items-center gap-3">
            <div className="p-2 bg-eleghoBlue/10 rounded-lg">
              <FiPhone className="w-4 h-4 sm:w-5 sm:h-5 text-eleghoBlue" />
            </div>
            <div>
              <div className="text-xs sm:text-sm text-gray-600">
                Total Contacts
              </div>
              <div className="text-xl sm:text-2xl font-semibold text-eleghoBlue">
                {stats.totalContacts}
              </div>
            </div>
          </div>
        </div>
        <div className="bg-white p-4 sm:p-6 rounded-xl border border-gray-100 shadow-sm hover:shadow-md transition-shadow">
          <div className="flex items-center gap-3">
            <div className="p-2 bg-eleghoGreen/10 rounded-lg">
              <FiPhone className="w-4 h-4 sm:w-5 sm:h-5 text-eleghoGreen" />
            </div>
            <div>
              <div className="text-xs sm:text-sm text-gray-600">
                Emergency Contacts
              </div>
              <div className="text-xl sm:text-2xl font-semibold text-eleghoGreen">
                {stats.emergencyContacts}
              </div>
            </div>
          </div>
        </div>
        <div className="bg-white p-4 sm:p-6 rounded-xl border border-gray-100 shadow-sm hover:shadow-md transition-shadow">
          <div className="flex items-center gap-3">
            <div className="p-2 bg-gray-50 rounded-lg">
              <FiMail className="w-4 h-4 sm:w-5 sm:h-5 text-gray-600" />
            </div>
            <div>
              <div className="text-xs sm:text-sm text-gray-600">
                Directory Contacts
              </div>
              <div className="text-xl sm:text-2xl font-semibold text-gray-600">
                {stats.directoryContacts}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="flex flex-col sm:flex-row items-start sm:items-center justify-between gap-4 mb-6">
        <div className="flex items-center gap-2 bg-white border rounded-lg border-gray-300 w-full sm:w-96 px-3 py-2">
          <FiSearch className="text-gray-400 w-4 h-4" />
          <input
            className="w-full border-none outline-none text-xs"
            placeholder="Search name or title"
            type="search"
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>
        <button
          onClick={handleAddMode}
          className="flex items-center justify-center w-full sm:w-auto gap-2 px-4 py-2 bg-eleghoBlue text-white rounded-lg hover:bg-eleghoBlue/90 transition-colors text-xs"
        >
          <FiPlus className="w-4 h-4" />
          Add New Contact
        </button>
      </div>

      <div className="flex flex-wrap gap-2 sm:gap-4 mb-6">
        {links.map((link, index) => (
          <button
            key={index}
            className={`px-3 sm:px-4 py-2 rounded-lg text-xs transition-colors ${
              activeLink === link
                ? "bg-eleghoBlue text-white"
                : "bg-white text-gray-600 hover:bg-gray-50"
            }`}
            onClick={() => setActiveLink(link)}
          >
            {link}
          </button>
        ))}
      </div>

      <div className="overflow-x-auto pb-6">
        <BentoTable
          label={activeLink}
          data={filteredList}
          numberOfItemPerPage={numberOfItemPerPage}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        >
          <div className="divide-y divide-gray-100 min-w-[900px]">
            <div className="grid grid-cols-6 gap-4 py-3 px-4 bg-gray-50 rounded-t-lg font-medium text-gray-600">
              <div>Name</div>
              <div>Title</div>
              <div>Email</div>
              <div>Contact Number</div>
              <div>Landline</div>
              <div>Actions</div>
            </div>
            {slicedItems.map((contact, index) => (
              <div
                key={index}
                className="grid grid-cols-6 gap-4 py-3 sm:py-4 px-4 bg-white hover:bg-gray-50 transition-colors"
              >
                <div className="font-medium text-eleghoBlue">
                  {contact.name}
                </div>
                <div className="text-gray-600">{contact.title}</div>
                <div className="text-gray-600 truncate">{contact.email}</div>
                <div className="text-gray-600">{contact.contactNumber}</div>
                <div className="text-gray-600">{contact.landline}</div>
                <div className="flex items-center gap-2 sm:gap-4">
                  <button
                    className="flex items-center gap-1.5 px-2 py-1.5 rounded-md text-xs text-eleghoBlue hover:text-eleghoBlue/80 hover:bg-eleghoBlue/5 transition-colors"
                    onClick={() => handleEdit(contact)}
                  >
                    <FiEdit className="w-3.5 h-3.5" />
                    <span className="hidden sm:inline">Edit</span>
                  </button>
                  <button
                    className="flex items-center gap-1.5 px-2 py-1.5 rounded-md text-xs text-red-500 hover:text-red-600 hover:bg-red-50 transition-colors"
                    onClick={() => handleDelete(contact)}
                  >
                    <FiTrash2 className="w-3.5 h-3.5" />
                    <span className="hidden sm:inline">Delete</span>
                  </button>
                </div>
              </div>
            ))}
          </div>
        </BentoTable>
      </div>

      {addMode && (
        <PageModal>
          <AddContactForm setAddMode={setAddMode} />
        </PageModal>
      )}

      {editMode && (
        <Modal>
          <AddContactForm
            data={selectedContact}
            setEditMode={setEditMode}
            setAddMode={setAddMode}
            editMode={editMode}
          />
        </Modal>
      )}
    </div>
  );
}
