import React, { useEffect } from "react";
import { IoIosArrowBack } from "react-icons/io";
import CheckBox from "./CheckBox";
import { motion } from "framer-motion";
import ButtonBlue from "../Login/ButtonBlue";
import { useState } from "react";
import {
  addNewDoc,
  setDocData,
  getDocData,
} from "../../utils/firebase/Firebase.utils";
import toast from "react-hot-toast";
import { v4 } from "uuid";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { storage } from "../../utils/firebase/Firebase.utils";
import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";

function MaintenanceRequest() {
  const [formData, setFormData] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [assignedId, setAssignedId] = useState(v4());
  const [file, setFile] = useState(null);
  const [progress, setProgress] = useState(0);
  const [url, setUrl] = useState("");
  const [activeUpload, setActiveUpload] = useState("");

  const navigate = useNavigate();
  const currentUser = useSelector((state) => state.userObject);

  const { linkedIdentifiers } = currentUser;

  const requestTypes = [
    "plumbing",
    "electrical",
    "carpentry",
    "airconditioning",
    "painting",
  ];

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleCheckBox = (e) => {
    const { name, checked } = e.target;
    setFormData((prev) => ({ ...prev, [name]: checked }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    if (!url) {
      toast.error("Please upload photo");
      setIsSubmitting(false);
      return;
    }

    //check if one of checkbox is checked
    if (
      !formData.plumbing &&
      !formData.electrical &&
      !formData.carpentry &&
      !formData.airconditioning &&
      !formData.painting
    ) {
      toast.error("Please select at least one request type");
      setIsSubmitting(false);
      return;
    }

    //check if identifier is not blank
    if (!formData.identifier) {
      toast.error("Please select unit number");
      setIsSubmitting(false);
      return;
    }

    const docObject = {
      ...formData,
      requestTypes: requestTypes.filter((request) => formData[request]),
      photo: url,
      status: "pending",
      id: assignedId,
      createdAt: new Date(),
      user: currentUser.email,
      uid: currentUser.uid,
    };

    await setDocData("maintenance", assignedId, docObject);
    setFormData({});
    setIsSubmitting(false);
    toast.success("Request submitted successfully");
    navigate("/maintenance-request-confirmation/" + assignedId);
  };

  const handleSetFile = (e) => {
    e.preventDefault();
    setFile(e.target.files[0]);
  };

  const handleUpload = async (e, fileName) => {
    e.preventDefault();
    const file = e.target.files[0];
    setIsSubmitting(true);

    const storageRef = ref(storage, `maintenance/${assignedId}/${fileName}`);
    const uploadTask = uploadBytesResumable(storageRef, file);
    setActiveUpload(fileName);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setProgress(progress);
      },
      (error) => {
        toast.error("Error uploading file");
        setIsSubmitting(false);
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          setUrl(downloadURL);
          toast.success(`${fileName} Uploaded Successfully`);
          setIsSubmitting(false);
        });
      }
    );
    setIsSubmitting(false);
  };

  return (
    <motion.div
      initial={{ x: "-100%" }}
      animate={{ x: 0 }}
      exit={{ x: "-100%" }}
      transition={{ duration: 0.3, type: "tween" }}
    >
      <form onSubmit={handleSubmit}>
        <div className="bg-blue-900 h-12 text-white flex items-center justify-normal gap-4 p-2">
          <button onClick={() => window.history.back()}>
            <IoIosArrowBack className="" />
          </button>
          Maintenance Request
        </div>
        <div className="text-blue-900 font-bold text-left p-2">
          Select your request
        </div>
        <div className="grid grid-cols-2 p-2 text-blue-900">
          {requestTypes.map((request, index) => (
            <CheckBox
              key={index}
              name={request}
              label={request}
              onChange={handleCheckBox}

              // checked={formData[request]}
            />
          ))}
        </div>
        <div>
          <div className="text-left text-blue-900 p-2">
            <div>
              <div>Unit Number</div>
              <select
                name="identifier"
                className="border border-blue-900 rounded-lg p-2 w-full"
                onChange={handleChange}
                required
              >
                <option value={""}>Select Unit number</option>
                {linkedIdentifiers.map((identifier, index) => (
                  <option key={index} value={identifier}>
                    {identifier}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
        <div className="text-left p-2 text-blue-900">
          <div>
            <div>Date</div>
            <input
              className="border border-blue-900 rounded-lg p-2 w-32"
              type="date"
              name="preferredDate"
              onChange={handleChange}
              required
            />
          </div>
          <div>
            <div>Preferred Time</div>
            <input
              className="border border-blue-900 rounded-lg p-2 w-32"
              type="time"
              name="preferredTime"
              onChange={handleChange}
              required
            />
          </div>
        </div>
        <div className="text-left p-2 text-blue-900">
          <div>Description</div>
          <div>
            <textarea
              className="border border-blue-900 rounded-lg p-2 w-full"
              placeholder="Description"
              name="description"
              onChange={handleChange}
              required
            />
          </div>
        </div>
        <div className="text-left text-blue-900 p-2">
          <div>Upload Photo</div>
          <div className="border border-blue-900 rounded-lg p-2 w-full h-32">
            <input
              type="file"
              name="photo"
              required
              onChange={(e) => {
                handleSetFile(e);
                handleUpload(e, "photo");
              }}
            />
            <progress value={progress} max="100" />
          </div>
        </div>
        <div>
          <button
            className="bg-blue-600 text-white p-2 rounded-md w-full focus:outline-none focus:ring-2 focus:ring-blue-600 focus:border-transparent disabled:bg-gray-200"
            type="submit"
            disabled={isSubmitting}
          >
            Submit
          </button>
        </div>
      </form>
    </motion.div>
  );
}

export default MaintenanceRequest;
