import React from "react";
import { useCompany } from "../../context/CompanyContext";

export default function IncidentReportTableHeaders() {
  const { getCapitalizedTerm } = useCompany();

  return (
    <div className="bg-gray-50 flex items-center gap-4 p-4 rounded-xl border-b border-gray-100 text-left">
      <div className="w-36">
        <div className="text-xs font-medium text-gray-500 uppercase tracking-wider">
          Incident Type
        </div>
      </div>
      <div className="w-36">
        <div className="text-xs font-medium text-gray-500 uppercase tracking-wider">
          Location
        </div>
      </div>
      <div className="w-36">
        <div className="text-xs font-medium text-gray-500 uppercase tracking-wider">
          Date/Time
        </div>
      </div>
      <div className="w-24">
        <div className="text-xs font-medium text-gray-500 uppercase tracking-wider">
          Status
        </div>
      </div>
      <div className="w-36">
        <div className="text-xs font-medium text-gray-500 uppercase tracking-wider">
          Reported By
        </div>
      </div>
      <div className="w-24 text-right">
        <div className="text-xs font-medium text-gray-500 uppercase tracking-wider">
          Actions
        </div>
      </div>
    </div>
  );
}
