import React from "react";

export default function LinksBar({ links, activeLink, setActiveLink }) {
  return (
    <div>
      <div className="flex items-center gap-2">
        {links.map((link, index) => (
          <div
            key={index}
            className={`capitalize ${
              activeLink === link ? "active-pill" : "inactive-pill"
            }`}
            onClick={() => setActiveLink(link)}
          >
            {link}
          </div>
        ))}
      </div>
    </div>
  );
}
