import React from "react";
import SelectInput from "../Dashboard/Components/SelectInput";
import { useSelector } from "react-redux";
import Label from "../Dashboard/Components/Label";
import InputBox from "../Dashboard/Components/InputBox";
import { storage } from "../../utils/firebase/Firebase.utils";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { v4 } from "uuid";
import { setDocData } from "../../utils/firebase/Firebase.utils";
import toast from "react-hot-toast";

export default function CarStickerApplication({ setAddMode }) {
  const currentUser = useSelector((state) => state.userObject);
  const [currentUpload, setCurrentUpload] = React.useState(null);
  const [progress, setProgress] = React.useState(0);
  const [formData, setFormData] = React.useState({});
  const [isProcessing, setIsProcessing] = React.useState(false);
  const [assignedId, setAssignedId] = React.useState(v4());

  const identifierOptions = currentUser.linkedIdentifiers.map((identifier) => {
    return {
      value: identifier,
      label: identifier,
    };
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSelect = (selected) => {
    setFormData({ ...formData, identifier: selected.value });
  };

  const handleUpload = async (e, type) => {
    //check if file is uploaded
    if (!e.target.files[0]) return;
    setCurrentUpload(type);

    const file = e.target.files[0];
    const storageRef = ref(
      storage,
      `carStickers/${assignedId}/${type}/${file.name}`
    );
    const uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setProgress(progress);
      },
      (error) => {},
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          setFormData((prev) => ({ ...prev, [type]: downloadURL }));
          setProgress(0);
        });
      }
    );
  };

  const handleSubmit = async (e) => {
    setIsProcessing(true);
    e.preventDefault();
    const docObject = {
      ...formData,
      status: "Pending",
      id: assignedId,
      userId: currentUser.uid,
      requestedAt: new Date().toLocaleString(),
      requestedBy: currentUser.email,
    };

    await setDocData("carStickers", assignedId, docObject);
    toast.success("Application submitted successfully");

    setAddMode(false);
    setIsProcessing(false);
  };

  return (
    <div className="page relative bg-white rounded-lg shadow-lg p-6 max-w-2xl mx-auto">
      <div className="flex justify-between items-center mb-6 border-b border-gray-100 pb-4">
        <h2 className="text-lg font-semibold text-[#363062]">
          Car Sticker Application
        </h2>
        <button
          onClick={() => setAddMode(false)}
          className="text-gray-500 hover:text-[#363062] transition-colors"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-5 w-5"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>
      </div>

      <div className="mt-4">
        <form onSubmit={handleSubmit} className="space-y-4">
          <div className="space-y-3">
            <div className="mb-3">
              <Label
                label="Select Unit Number"
                className="text-sm font-medium text-[#363062]"
              />
              <SelectInput
                options={identifierOptions}
                onChange={(selected) => handleSelect(selected)}
                value={identifierOptions.find(
                  (option) => option.value === formData.identifier
                )}
                className="mt-1 w-full rounded-md border-gray-200 focus:border-[#8FCD00] focus:ring focus:ring-[#8FCD00] focus:ring-opacity-20"
              />
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div>
                <InputBox
                  label="Plate Number"
                  placeholder="Plate Number"
                  name="plateNumber"
                  onChange={handleChange}
                  value={formData.plateNumber}
                  className="w-full rounded-md border-gray-200 focus:border-[#8FCD00] focus:ring focus:ring-[#8FCD00] focus:ring-opacity-20"
                />
              </div>
              <div>
                <InputBox
                  label="Car Model"
                  placeholder="e.g. Mazda 3"
                  name="carModel"
                  onChange={handleChange}
                  value={formData.carModel}
                  className="w-full rounded-md border-gray-200 focus:border-[#8FCD00] focus:ring focus:ring-[#8FCD00] focus:ring-opacity-20"
                />
              </div>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div>
                <InputBox
                  label="Car Color"
                  placeholder="e.g. Red"
                  name="carColor"
                  onChange={handleChange}
                  value={formData.carColor}
                  className="w-full rounded-md border-gray-200 focus:border-[#8FCD00] focus:ring focus:ring-[#8FCD00] focus:ring-opacity-20"
                />
              </div>
              <div>
                <InputBox
                  label="Car Owner"
                  placeholder="e.g. John Doe"
                  name="carOwner"
                  onChange={handleChange}
                  value={formData.carOwner}
                  className="w-full rounded-md border-gray-200 focus:border-[#8FCD00] focus:ring focus:ring-[#8FCD00] focus:ring-opacity-20"
                />
              </div>
            </div>

            <div className="mt-4 border border-gray-100 rounded-md p-4 bg-gray-50">
              <Label
                label="Upload OR/CR"
                className="text-sm font-medium text-[#363062]"
              />
              <div className="mt-2 flex items-center space-x-2">
                <label className="cursor-pointer bg-white px-4 py-2 rounded border border-gray-200 hover:bg-gray-50 transition-colors text-sm flex items-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4 mr-2 text-[#363062]"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                    />
                  </svg>
                  Browse Files
                  <input
                    type="file"
                    className="hidden"
                    onChange={(e) => handleUpload(e, "ORCR")}
                  />
                </label>
                <span className="text-xs text-gray-500">
                  {formData.ORCR ? "File uploaded" : "No file chosen"}
                </span>
              </div>
              {currentUpload === "ORCR" && progress > 0 && (
                <div className="w-full mt-2">
                  <div className="w-full bg-gray-200 rounded-full h-2">
                    <div
                      className="bg-[#8FCD00] h-2 rounded-full"
                      style={{ width: `${progress}%` }}
                    ></div>
                  </div>
                </div>
              )}
            </div>

            <div className="mt-4 border border-gray-100 rounded-md p-4 bg-gray-50">
              <Label
                label="Upload Photo of the Car"
                className="text-sm font-medium text-[#363062]"
              />
              <div className="mt-2 flex items-center space-x-2">
                <label className="cursor-pointer bg-white px-4 py-2 rounded border border-gray-200 hover:bg-gray-50 transition-colors text-sm flex items-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4 mr-2 text-[#363062]"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                    />
                  </svg>
                  Browse Files
                  <input
                    type="file"
                    className="hidden"
                    onChange={(e) => handleUpload(e, "Photo")}
                  />
                </label>
                <span className="text-xs text-gray-500">
                  {formData.Photo ? "File uploaded" : "No file chosen"}
                </span>
              </div>
              {currentUpload === "Photo" && progress > 0 && (
                <div className="w-full mt-2">
                  <div className="w-full bg-gray-200 rounded-full h-2">
                    <div
                      className="bg-[#8FCD00] h-2 rounded-full"
                      style={{ width: `${progress}%` }}
                    ></div>
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className="pt-4 mt-4 border-t border-gray-100">
            <button
              className="w-full py-2.5 px-4 bg-[#363062] hover:bg-opacity-90 text-white rounded-md 
                         font-medium focus:outline-none focus:ring-2 focus:ring-[#8FCD00] focus:ring-opacity-50 
                         transition-colors flex items-center justify-center"
              type="submit"
              disabled={isProcessing}
            >
              {isProcessing ? (
                <svg
                  className="animate-spin -ml-1 mr-2 h-4 w-4 text-white"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  ></path>
                </svg>
              ) : null}
              {isProcessing ? "Processing..." : "Submit Application"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
