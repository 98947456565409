import React from "react";
import { MdOutlineSos } from "react-icons/md";
import { RiHomeOfficeFill } from "react-icons/ri";
import { HiOutlineDotsHorizontal } from "react-icons/hi";
import { useNavigate } from "react-router-dom";
import { useCompany } from "../../context/CompanyContext";

import { FaHandshake } from "react-icons/fa";
function QuickContactsSection() {
  const { getCapitalizedTerm } = useCompany();
  const requestTypes = [
    {
      label: "Emergency",
      icon: <MdOutlineSos />,
      link: "/sos",
    },
    {
      label: "HOA Directory",
      icon: <RiHomeOfficeFill />,
      link: "/condo-corp",
    },
    {
      label: "Suppliers",
      icon: <FaHandshake />,
      link: "/suppliers",
    },
    {
      label: "Others",
      icon: <HiOutlineDotsHorizontal />,
      link: "/other-services",
    },
  ];

  const navigate = useNavigate();

  return (
    <div className="text-blue-900 w-full">
      <div className="text-center font-bold text-xs text-blue-900">
        CONTACTS
      </div>
      <div className="flex items-center justify-around gap-2 flex-wrap p-2 rounded-xl">
        {requestTypes.map((request, index) => (
          <div
            className="text-xs flex flex-col items-center justify-center w-20 h-20  rounded-lg cursor-pointer"
            key={index}
            onClick={() => navigate(`${request.link}`)}
          >
            <div className="text-4xl">{request.icon}</div>
            <div className="text-xs font-semibold">{request.label}</div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default QuickContactsSection;
