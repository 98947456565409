import React, { useState } from "react";
import { setDocData } from "../../../utils/firebase/Firebase.utils";
import { useParams } from "react-router-dom";
import { v4 } from "uuid";
import toast from "react-hot-toast";
import {
  FaUser,
  FaIdCard,
  FaBirthdayCake,
  FaPhone,
  FaEnvelope,
  FaMapMarkerAlt,
  FaBriefcase,
  FaUserFriends,
  FaBuilding,
} from "react-icons/fa";
import { IoClose } from "react-icons/io5";

export default function AddResidentOccupantForm({ handleAddMode, occupants }) {
  const { id } = useParams();
  const [formData, setFormData] = useState({
    firstName: "",
    middleName: "",
    lastName: "",
    relationship: "",
    dateOfBirth: "",
    gender: "",
    civilStatus: "",
    contactNumber: "",
    email: "",
    address: "",
    occupation: "",
    employer: "",
    emergencyContact: "",
    emergencyContactNumber: "",
    emergencyContactRelationship: "",
  });
  const [isProcessing, setIsProcessing] = useState(false);
  const [assignedId, setAssignedId] = useState(v4());

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsProcessing(true);
    const docObject = {
      ...formData,
      id: assignedId,
    };
    const newOccupants = [...occupants, docObject];
    try {
      await setDocData("residents", id, { occupantsName: newOccupants });
      toast.success("Occupant added successfully");
      handleAddMode();
    } catch (error) {
      toast.error("Error adding occupant");
      console.error("Error:", error);
    }
    setIsProcessing(false);
  };

  const genderOptions = ["Male", "Female", "Other"];
  const civilStatusOptions = ["Single", "Married", "Divorced", "Widowed"];

  const InputField = ({ icon: Icon, label, ...props }) => (
    <div className="space-y-1">
      <label className="text-xs font-medium text-gray-700 block">{label}</label>
      <div className="relative">
        <div className="absolute inset-y-0 left-0 pl-2.5 flex items-center pointer-events-none">
          <Icon className="w-4 h-4 text-gray-400" />
        </div>
        <input
          className="w-full pl-9 pr-3 py-2 text-sm border border-gray-200 rounded-lg focus:ring-2 focus:ring-eleghoBlue/20 focus:border-eleghoBlue placeholder-gray-400"
          {...props}
        />
      </div>
    </div>
  );

  const SelectField = ({ icon: Icon, label, options, ...props }) => (
    <div className="space-y-1">
      <label className="text-xs font-medium text-gray-700 block">{label}</label>
      <div className="relative">
        <div className="absolute inset-y-0 left-0 pl-2.5 flex items-center pointer-events-none">
          <Icon className="w-4 h-4 text-gray-400" />
        </div>
        <select
          className="w-full pl-9 pr-3 py-2 text-sm border border-gray-200 rounded-lg focus:ring-2 focus:ring-eleghoBlue/20 focus:border-eleghoBlue appearance-none bg-white"
          {...props}
        >
          <option value="">Select {label}</option>
          {options.map((option) => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
        </select>
      </div>
    </div>
  );

  return (
    <div className="relative bg-white rounded-xl shadow-lg max-w-7xl w-full mx-auto overflow-y-auto max-h-[90vh]">
      {/* Header */}
      <div className="sticky top-0 z-10 bg-white flex items-center justify-between p-4 md:p-6 border-b border-gray-100">
        <div>
          <h2 className="text-xl font-semibold text-gray-900">
            Register an Occupant
          </h2>
          <p className="mt-1 text-sm text-gray-500">
            Add a new occupant to the residence
          </p>
        </div>
        <button
          onClick={handleAddMode}
          className="p-2 text-gray-400 hover:text-gray-600 rounded-lg hover:bg-gray-100 transition-colors"
        >
          <IoClose className="w-5 h-5" />
        </button>
      </div>

      {/* Form */}
      <form onSubmit={handleSubmit} className="p-4 md:p-8">
        <div className="space-y-8">
          {/* Personal Information */}
          <div className="space-y-4">
            <div className="flex items-center gap-2 pb-2 border-b border-gray-100">
              <div className="p-1.5 rounded-lg bg-eleghoBlue/10">
                <FaUser className="w-4 h-4 text-eleghoBlue" />
              </div>
              <h3 className="text-base font-medium text-gray-900">
                Personal Information
              </h3>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-4 gap-6">
              <InputField
                icon={FaUser}
                label="First Name"
                name="firstName"
                placeholder="Enter first name"
                onChange={handleChange}
                required
              />
              <InputField
                icon={FaUser}
                label="Middle Name"
                name="middleName"
                placeholder="Enter middle name"
                onChange={handleChange}
              />
              <InputField
                icon={FaUser}
                label="Last Name"
                name="lastName"
                placeholder="Enter last name"
                onChange={handleChange}
                required
              />
              <InputField
                icon={FaUserFriends}
                label="Relationship to Owner"
                name="relationship"
                placeholder="e.g. Son, Daughter, Spouse"
                onChange={handleChange}
                required
              />
              <InputField
                icon={FaBirthdayCake}
                label="Date of Birth"
                name="dateOfBirth"
                type="date"
                onChange={handleChange}
                required
              />
              <SelectField
                icon={FaUser}
                label="Gender"
                name="gender"
                options={genderOptions}
                onChange={handleChange}
                required
              />
              <SelectField
                icon={FaIdCard}
                label="Civil Status"
                name="civilStatus"
                options={civilStatusOptions}
                onChange={handleChange}
                required
              />
            </div>
          </div>

          {/* Contact Information */}
          <div className="space-y-4">
            <div className="flex items-center gap-2 pb-2 border-b border-gray-100">
              <div className="p-1.5 rounded-lg bg-eleghoBlue/10">
                <FaPhone className="w-4 h-4 text-eleghoBlue" />
              </div>
              <h3 className="text-base font-medium text-gray-900">
                Contact Information
              </h3>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
              <InputField
                icon={FaPhone}
                label="Contact Number"
                name="contactNumber"
                placeholder="Enter contact number"
                onChange={handleChange}
                required
              />
              <InputField
                icon={FaEnvelope}
                label="Email Address"
                name="email"
                type="email"
                placeholder="Enter email address"
                onChange={handleChange}
                required
              />
              <InputField
                icon={FaMapMarkerAlt}
                label="Address"
                name="address"
                placeholder="Enter complete address"
                onChange={handleChange}
                required
              />
            </div>
          </div>

          {/* Employment Information */}
          <div className="space-y-4">
            <div className="flex items-center gap-2 pb-2 border-b border-gray-100">
              <div className="p-1.5 rounded-lg bg-eleghoBlue/10">
                <FaBriefcase className="w-4 h-4 text-eleghoBlue" />
              </div>
              <h3 className="text-base font-medium text-gray-900">
                Employment Information
              </h3>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
              <InputField
                icon={FaBriefcase}
                label="Occupation"
                name="occupation"
                placeholder="Enter occupation"
                onChange={handleChange}
                required
              />
              <InputField
                icon={FaBuilding}
                label="Employer"
                name="employer"
                placeholder="Enter employer name"
                onChange={handleChange}
                required
              />
            </div>
          </div>

          {/* Emergency Contact */}
          <div className="space-y-4">
            <div className="flex items-center gap-2 pb-2 border-b border-gray-100">
              <div className="p-1.5 rounded-lg bg-eleghoBlue/10">
                <FaPhone className="w-4 h-4 text-eleghoBlue" />
              </div>
              <h3 className="text-base font-medium text-gray-900">
                Emergency Contact
              </h3>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
              <InputField
                icon={FaUser}
                label="Emergency Contact Name"
                name="emergencyContact"
                placeholder="Enter contact name"
                onChange={handleChange}
                required
              />
              <InputField
                icon={FaPhone}
                label="Emergency Contact Number"
                name="emergencyContactNumber"
                placeholder="Enter contact number"
                onChange={handleChange}
                required
              />
              <InputField
                icon={FaUserFriends}
                label="Relationship to Occupant"
                name="emergencyContactRelationship"
                placeholder="e.g. Parent, Sibling, Spouse"
                onChange={handleChange}
                required
              />
            </div>
          </div>
        </div>

        {/* Form Actions */}
        <div className="mt-8 flex items-center justify-end gap-3 pt-6 border-t border-gray-100">
          <button
            type="button"
            onClick={handleAddMode}
            className="px-6 py-2.5 text-sm font-medium text-gray-700 bg-gray-100 rounded-lg hover:bg-gray-200 transition-colors"
          >
            Cancel
          </button>
          <button
            type="submit"
            disabled={isProcessing}
            className="px-6 py-2.5 text-sm font-medium text-white bg-eleghoBlue rounded-lg hover:bg-eleghoBlue/90 transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
          >
            {isProcessing ? "Adding Occupant..." : "Add Occupant"}
          </button>
        </div>
      </form>
    </div>
  );
}
