import React, { useEffect } from "react";
import UserScreenWindow from "./UserScreenWindow";
import { getAllDocs } from "../../utils/firebase/Firebase.utils";
import NotificationCard from "./NotificationCard";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

function NotificationsUser() {
  const [notifications, setNotifications] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(true);
  const currentUser = useSelector((state) => state.userObject);

  useEffect(() => {
    const fetchNotifications = async () => {
      const data = await getAllDocs("notifications");
      const userNotifications = data.filter(
        (notification) => notification.user === currentUser.email
      );
      setNotifications(userNotifications);
      setIsLoading(false);
    };
    fetchNotifications();
  }, []);

  if (isLoading) {
    return (
      <UserScreenWindow label="Notifications">
        <div className="flex items-center justify-center min-h-[60vh]">
          <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-500"></div>
        </div>
      </UserScreenWindow>
    );
  }

  return (
    <UserScreenWindow label="Notifications">
      <div className="px-4 py-6 max-w-2xl mx-auto">
        {notifications.length === 0 ? (
          <div className="text-center py-12">
            <div className="text-gray-400 mb-4">
              <svg
                className="mx-auto h-12 w-12"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9"
                />
              </svg>
            </div>
            <h3 className="text-lg font-medium text-gray-900 mb-2">
              No notifications yet
            </h3>
            <p className="text-gray-500">
              You'll see your notifications here when they arrive.
            </p>
          </div>
        ) : (
          <div className="space-y-4">
            {notifications.map((notification, index) => (
              <Link
                key={index}
                to={`/notif-details/${notification.id}`}
                className="block transition-transform hover:scale-[1.02] active:scale-[0.98]"
              >
                <NotificationCard notification={notification} />
              </Link>
            ))}
          </div>
        )}
      </div>
    </UserScreenWindow>
  );
}

export default NotificationsUser;
