import React from "react";

export default function DetailsPageHead({
  label,
  editMode,
  setEditMode,
  handleCancelEdit,
}) {
  return (
    <div className="bg-eleghoBlue p-4 text-lg rounded-lg text-white font-semibold flex items-center justify-between mb-4">
      <div className="font-semibold text-white">{label}</div>
      <div className="flex items-center gap-4 text-xs">
        {!editMode && (
          <button
            className="green-button"
            onClick={() => setEditMode(!editMode)}
          >
            {editMode ? "Cancel" : "Edit"}
          </button>
        )}
        {editMode && <button onClick={handleCancelEdit}>Cancel</button>}
      </div>
    </div>
  );
}
