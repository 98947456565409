import React from "react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";

function StatsCard({ title, count, link, color = "blue", delay = 0 }) {
  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ delay }}
      className="p-4 border rounded-lg border-gray-200 text-left w-40 h-24 relative bg-white hover:shadow-md transition-shadow"
    >
      <div className="uppercase text-xs font-semibold text-gray-700">
        {title}
      </div>
      <div className={`text-3xl font-bold mt-2 text-${color}-600`}>{count}</div>
      <div className="absolute text-xs right-3 bottom-3">
        <Link
          to={link}
          className="text-blue-600 hover:text-blue-700 hover:underline transition-colors"
        >
          View All
        </Link>
      </div>
    </motion.div>
  );
}

export default StatsCard;
