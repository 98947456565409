import React, { useEffect } from "react";
import UserScreenWindow from "./UserScreenWindow";
import { useSelector } from "react-redux";
import FacilityCard from "./Facilities/FacilityCard";
import { Link } from "react-router-dom";
import { getAllDocsSnap } from "../../utils/firebase/Firebase.utils";

export default function FacilitiesUser() {
  const currentUser = useSelector((state) => state.userObject);
  const [facilities, setFacilities] = React.useState([]);
  const [loading, setLoading] = React.useState(true);

  useEffect(() => {
    const fetchFacilities = async () => {
      await getAllDocsSnap("facilities", (data) => {
        setFacilities(data);
        setLoading(false);
      });
    };
    fetchFacilities();
  }, []);

  if (loading) {
    return <UserScreenWindow>Loading...</UserScreenWindow>;
  }

  return (
    <UserScreenWindow label="Facilities">
      <div>
        <div className="font-bold text-xl text-eleghoBlue">
          Welcome {currentUser.displayName}!
        </div>

        <div className="mt-4">
          {facilities.map((facility, index) => (
            <Link to={`/user/facilities/${facility.id}`} key={index}>
              <div className="cursor-pointer p-2" key={index}>
                <FacilityCard data={facility} />
              </div>
            </Link>
          ))}
        </div>
      </div>
    </UserScreenWindow>
  );
}
