import React, { useState, useEffect } from "react";
import { getAllDocsSnap } from "../../../utils/firebase/Firebase.utils";
import "./WorkPermitsAdminMobile.css";

function WorkPermitsAdminMobile() {
  const [workPermits, setWorkPermits] = useState([]);
  const [filteredPermits, setFilteredPermits] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchFilter, setSearchFilter] = useState("all");
  const [statusFilter, setStatusFilter] = useState("all");

  const sampleObject = {
    duration: "4",
    history: [
      {
        action: "Status Updated",
        at: "3/25/2025, 2:52:34 PM",
        to: "Returned",
        by: "Admin",
      },
    ],
    createdBy: "atmagat@gmail.com",
    approvedBy: "",
    vehicle: "tsm 845",
    files: {},
    identifier: "Unit 6e - Tower 2",
    assignedTo: [],
    name: "Alvin Magat",
    workers: "john doe, jane doe, ",
    approvedAt: "",
    createdAt: "3/25/2025, 1:43:52 PM",
    workPermitType: "minor work",
    status: "Returned",
    description: "renovation of my veranda",
    user: "atmagat@gmail.com",
    comments: [
      {
        message: "Test comment",
        createdAt: "3/25/2025, 2:26:20 PM",
        name: "Alvin Magat",
      },
      {
        name: "Alvin Magat",
        createdAt: "3/25/2025, 2:55:06 PM",
        message: "another comment",
      },
    ],
    id: "221B9815",
  };

  useEffect(() => {
    const fetchWorkPermits = async () => {
      await getAllDocsSnap("workPermits", (docs) => {
        setWorkPermits(docs);
        setFilteredPermits(docs);
        console.log(docs[0]);
        setIsLoading(false);
      });
    };
    fetchWorkPermits();
  }, []);

  useEffect(() => {
    if (workPermits.length > 0) {
      filterPermits();
    }
  }, [searchTerm, searchFilter, statusFilter, workPermits]);

  const filterPermits = () => {
    let filtered = [...workPermits];

    // Apply status filter first
    if (statusFilter !== "all") {
      filtered = filtered.filter(
        (permit) =>
          (permit.status || "").toLowerCase() === statusFilter.toLowerCase()
      );
    }

    // Then apply search filter (case insensitive)
    if (searchTerm.trim()) {
      const term = searchTerm.toLowerCase().trim();
      filtered = filtered.filter((permit) => {
        // Case insensitive search by converting all values to lowercase
        switch (searchFilter) {
          case "id":
            return (permit.id || "").toLowerCase().includes(term);
          case "name":
            return (permit.name || "").toLowerCase().includes(term);
          case "unit":
            return (permit.identifier || "").toLowerCase().includes(term);
          case "type":
            return (permit.workPermitType || "").toLowerCase().includes(term);
          case "status":
            return (permit.status || "").toLowerCase().includes(term);
          case "all":
          default:
            // Search across all fields (case insensitive)
            return (
              (permit.id || "").toLowerCase().includes(term) ||
              (permit.name || "").toLowerCase().includes(term) ||
              (permit.identifier || "").toLowerCase().includes(term) ||
              (permit.workPermitType || "").toLowerCase().includes(term) ||
              (permit.status || "").toLowerCase().includes(term) ||
              (permit.description || "").toLowerCase().includes(term)
            );
        }
      });
    }

    setFilteredPermits(filtered);
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleFilterChange = (e) => {
    setSearchFilter(e.target.value);
  };

  const handleStatusFilter = (status) => {
    setStatusFilter(status === statusFilter ? "all" : status);
  };

  const getStatusColor = (status) => {
    switch (status.toLowerCase()) {
      case "approved":
        return "#8FCD00"; // eleghoGreen
      case "pending":
        return "#FFC107"; // amber
      case "returned":
        return "#F44336"; // red
      default:
        return "#363062"; // eleghoBlue
    }
  };

  const formatDate = (dateString) => {
    try {
      const date = new Date(dateString);
      return date.toLocaleDateString();
    } catch (error) {
      return dateString;
    }
  };

  if (isLoading) {
    return (
      <div className="loading-container">
        <div className="loading-spinner"></div>
        <p>Loading work permits...</p>
      </div>
    );
  }

  return (
    <div className="work-permits-container">
      <h2 className="page-title">Work Permits</h2>

      <div className="search-container">
        <div className="search-bar">
          <input
            type="text"
            placeholder="Search work permits..."
            value={searchTerm}
            onChange={handleSearchChange}
            className="search-input"
            title="Search is case insensitive"
          />
          <select
            className="search-filter"
            value={searchFilter}
            onChange={handleFilterChange}
          >
            <option value="all">All Fields</option>
            <option value="id">Permit ID</option>
            <option value="name">Name</option>
            <option value="unit">Unit</option>
            <option value="type">Type</option>
            <option value="status">Status</option>
          </select>
        </div>

        <div className="status-filter-buttons">
          <button
            className={`status-btn ${
              statusFilter === "pending" ? "active" : ""
            }`}
            style={{
              borderColor: getStatusColor("pending"),
              backgroundColor:
                statusFilter === "pending"
                  ? getStatusColor("pending")
                  : "transparent",
              color:
                statusFilter === "pending"
                  ? "white"
                  : getStatusColor("pending"),
            }}
            onClick={() => handleStatusFilter("pending")}
          >
            Pending
          </button>
          <button
            className={`status-btn ${
              statusFilter === "approved" ? "active" : ""
            }`}
            style={{
              borderColor: getStatusColor("approved"),
              backgroundColor:
                statusFilter === "approved"
                  ? getStatusColor("approved")
                  : "transparent",
              color:
                statusFilter === "approved"
                  ? "white"
                  : getStatusColor("approved"),
            }}
            onClick={() => handleStatusFilter("approved")}
          >
            Approved
          </button>
          <button
            className={`status-btn ${
              statusFilter === "returned" ? "active" : ""
            }`}
            style={{
              borderColor: getStatusColor("returned"),
              backgroundColor:
                statusFilter === "returned"
                  ? getStatusColor("returned")
                  : "transparent",
              color:
                statusFilter === "returned"
                  ? "white"
                  : getStatusColor("returned"),
            }}
            onClick={() => handleStatusFilter("returned")}
          >
            Returned
          </button>
          {statusFilter !== "all" && (
            <button
              className="status-btn clear-btn"
              onClick={() => setStatusFilter("all")}
            >
              Clear Filter
            </button>
          )}
        </div>

        <div className="filter-info">
          {filteredPermits.length === 0 &&
            (searchTerm || statusFilter !== "all") && (
              <p className="no-results">
                No work permits found matching your filters
              </p>
            )}
          {(searchTerm || statusFilter !== "all") &&
            filteredPermits.length > 0 && (
              <p className="results-count">
                Found {filteredPermits.length} permit(s)
              </p>
            )}
        </div>
      </div>

      <div className="permits-grid">
        {filteredPermits.map((permit) => (
          <div key={permit.id} className="permit-card">
            <div
              className="permit-status"
              style={{ backgroundColor: getStatusColor(permit.status) }}
            >
              {permit.status}
            </div>

            <div className="permit-header">
              <h3 className="permit-id">#{permit.id}</h3>
              <p className="permit-type">{permit.workPermitType}</p>
            </div>

            <div className="permit-info">
              <div className="info-row">
                <span className="info-label">Name:</span>
                <span className="info-value">{permit.name}</span>
              </div>

              <div className="info-row">
                <span className="info-label">Unit:</span>
                <span className="info-value">{permit.identifier}</span>
              </div>

              <div className="info-row">
                <span className="info-label">Description:</span>
                <span className="info-value description">
                  {permit.description}
                </span>
              </div>

              <div className="info-row">
                <span className="info-label">Duration:</span>
                <span className="info-value">{permit.duration} days</span>
              </div>

              <div className="info-row">
                <span className="info-label">Created:</span>
                <span className="info-value">
                  {formatDate(permit.createdAt)}
                </span>
              </div>

              <div className="info-row">
                <span className="info-label">Comments:</span>
                <span className="info-value">
                  {permit.comments?.length || 0}
                </span>
              </div>
            </div>

            <div className="permit-footer">
              <button className="view-details-btn">View Details</button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default WorkPermitsAdminMobile;
