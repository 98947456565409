import React from "react";
import { FiArrowRight } from "react-icons/fi";
import PageModal from "../../Dashboard/Components/PageModal";

function AnnouncementModal({ announcement, onClose, formatDate }) {
  if (!announcement) return null;

  return (
    <PageModal>
      <div className="bg-white p-4 md:p-6 rounded-lg max-w-2xl w-full relative">
        <button
          onClick={onClose}
          className="absolute top-3 right-3 text-gray-500 hover:text-gray-700 text-xl font-bold"
        >
          ×
        </button>

        <div className="mb-4">
          {announcement.isNew && (
            <div className="inline-block bg-[#8FCD00] text-white px-3 py-1 rounded-md text-xs font-bold mb-3">
              NEW
            </div>
          )}

          <h3 className="text-base font-bold text-[#363062]">
            {announcement.title}
          </h3>

          <div className="flex items-center mt-2 space-x-3">
            <div className="text-xs text-gray-500">
              {formatDate(announcement.createdAt)}
            </div>
          </div>
        </div>

        {announcement.poster && (
          <div className="mb-4">
            <img
              src={announcement.poster}
              alt="Poster"
              className="w-full h-[200px] object-contain rounded-md"
            />
          </div>
        )}

        {announcement.imageUrl && (
          <div className="mb-4">
            <img
              src={announcement.imageUrl}
              alt={announcement.title}
              className="w-full max-h-[300px] object-cover rounded-md"
            />
          </div>
        )}

        <div className="text-sm text-gray-700 mb-4">
          {announcement.description && (
            <p className="font-medium mb-2">{announcement.description}</p>
          )}
          <div className="whitespace-pre-wrap">{announcement.content}</div>
        </div>

        {announcement.Details && (
          <div className="text-sm text-left text-gray-700 mb-4 whitespace-pre-wrap">
            {announcement.Details}
          </div>
        )}

        {announcement.link && (
          <div className="mt-4">
            <a
              href={announcement.link}
              className="flex items-center text-sm text-[#363062] hover:text-[#8FCD00] transition"
            >
              Learn More <FiArrowRight className="ml-1" />
            </a>
          </div>
        )}
      </div>
    </PageModal>
  );
}

export default AnnouncementModal;
