import React from "react";
import { useNavigate } from "react-router-dom";
import {
  PiHouseSimpleFill,
  PiShoppingCartSimpleFill,
  PiChatCircleTextFill,
  PiUserCircleFill,
} from "react-icons/pi";

function UserNavBar() {
  const [activeLink, setActiveLink] = React.useState("Home");

  const navItems = [
    {
      icon: <PiHouseSimpleFill />,
      label: "Home",
    },
    {
      icon: <PiShoppingCartSimpleFill />,
      label: "MarketPlace",
    },
    {
      icon: <PiChatCircleTextFill />,
      label: "Chat",
    },
    {
      icon: <PiUserCircleFill />,
      label: "Profile",
    },
  ];

  const activeLinkStyle =
    "text-green-700 bg-green-100 rounded-lg transition-all duration-200 hover:bg-green-50";
  const inactiveLinkStyle =
    "text-gray-500 hover:text-gray-700 hover:bg-gray-100 rounded-lg transition-all duration-200";
  const navigate = useNavigate();

  const handleClick = (link) => {
    setActiveLink(link);
    navigate(`/${link.toLowerCase()}`);
  };

  return (
    <div className="flex items-center justify-center gap-8 bg-white shadow-lg p-2 rounded-t-xl pb-6 mx-auto max-w-[450px] w-screen nav border-t">
      {navItems.map((item, index) => (
        <div
          key={index}
          className={`flex flex-col items-center p-3 cursor-pointer
          ${activeLink === item.label ? activeLinkStyle : inactiveLinkStyle}
            `}
          onClick={() => handleClick(item.label)}
        >
          <div className="text-2xl mb-1">{item.icon}</div>
          <div className="text-xs font-medium">{item.label}</div>
        </div>
      ))}
    </div>
  );
}

export default UserNavBar;
