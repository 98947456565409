import React, { useEffect, useState } from "react";
import { getAllDocsSnap } from "../../utils/firebase/Firebase.utils";
import ConcernsCard from "./ConcernsCard";
import PageModal from "./Components/PageModal";
import ConcernsPageDetails from "./Concerns/ConcernsPageDetails";
import BentoTable from "../../components/BentoTable";
import { slicedData } from "../../utils/EleghoUtils";
import {
  FiAlertCircle,
  FiClock,
  FiCheckCircle,
  FiCalendar,
} from "react-icons/fi";

function JobOrders() {
  const [activeFilter, setActiveFilter] = React.useState("Pending");
  const [concerns, setConcerns] = React.useState([]);

  const [filteredJobOrders, setFilteredJobOrders] = React.useState([]);
  const [searchTerm, setSearchTerm] = React.useState("");
  const [isLoading, setIsLoading] = React.useState(true);
  const [viewMode, setViewMode] = useState(false);
  const [selectedConcern, setSelectedConcern] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [numberOfItemPerPage, setNumberOfItemPerPage] = useState(5);

  useEffect(() => {
    const fetchConcerns = async () => {
      await getAllDocsSnap("concerns", (data) => {
        // arrange data by createdAt
        data.sort((a, b) => {
          return new Date(b.createdAt) - new Date(a.createdAt);
        });
        setConcerns(data);
        setFilteredJobOrders(data);
        setIsLoading(false);
      });
    };
    fetchConcerns();
  }, []);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  const statusLinks = ["Pending", "Scheduled", "Resolved"];

  const tableHeaders = [
    "Unit",
    "Description",
    "Photo",
    "Status",
    "Date",
    "Assigned To",
    "Date Assigned",
    "Priority",
    "Requested By",
    "Actions",
  ];

  const filterLinks = [
    "All",
    "Pending",
    "Processing",
    "Dispatched",
    "Resolved",
  ];

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const activeConcerns = concerns.filter((concern) => {
    if (activeFilter === "All") {
      return concern;
    }
    return concern.status.toLowerCase() === activeFilter.toLowerCase();
  });

  const filteredConcerns = activeConcerns.filter((concern) => {
    return (
      concern.identifier.toLowerCase().includes(searchTerm.toLowerCase()) ||
      concern.concern.toLowerCase().includes(searchTerm.toLowerCase())
    );
  });

  const handleViewMode = (selectedCard) => {
    setViewMode(!viewMode);
    setSelectedConcern(selectedCard);
  };

  const currentItems = slicedData(
    filteredConcerns,
    numberOfItemPerPage,
    currentPage
  );

  // Calculate stats for the summary cards
  const stats = {
    totalConcerns: concerns.length,
    pendingConcerns: concerns.filter((c) => c.status === "Pending").length,
    resolvedConcerns: concerns.filter((c) => c.status === "Resolved").length,
    scheduledConcerns: concerns.filter(
      (c) => c.status === "Scheduled" || c.status === "Dispatched"
    ).length,
  };

  const slidedData = filteredConcerns.slice(
    (currentPage - 1) * numberOfItemPerPage,
    currentPage * numberOfItemPerPage
  );

  return (
    <div className="text-xs">
      <div>
        <div className="mt-4 font-bold text-blue-900 uppercase text-left text-sm">
          Residents' Concerns
        </div>

        {/* Stats Cards */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 my-6">
          <div className="bg-white p-6 rounded-xl border border-gray-100 shadow-sm hover:shadow-md transition-shadow">
            <div className="flex items-center gap-3">
              <div className="p-2 bg-gray-50 rounded-lg">
                <FiAlertCircle className="w-5 h-5 text-gray-600" />
              </div>
              <div>
                <div className="text-sm text-gray-600">Total Concerns</div>
                <div className="text-2xl font-semibold text-gray-900">
                  {stats.totalConcerns}
                </div>
              </div>
            </div>
          </div>
          <div className="bg-white p-6 rounded-xl border border-gray-100 shadow-sm hover:shadow-md transition-shadow">
            <div className="flex items-center gap-3">
              <div className="p-2 bg-eleghoGreen/10 rounded-lg">
                <FiCheckCircle className="w-5 h-5 text-eleghoGreen" />
              </div>
              <div>
                <div className="text-sm text-gray-600">Resolved</div>
                <div className="text-2xl font-semibold text-eleghoGreen">
                  {stats.resolvedConcerns}
                </div>
              </div>
            </div>
          </div>
          <div className="bg-white p-6 rounded-xl border border-gray-100 shadow-sm hover:shadow-md transition-shadow">
            <div className="flex items-center gap-3">
              <div className="p-2 bg-amber-50 rounded-lg">
                <FiClock className="w-5 h-5 text-amber-500" />
              </div>
              <div>
                <div className="text-sm text-gray-600">Pending</div>
                <div className="text-2xl font-semibold text-amber-500">
                  {stats.pendingConcerns}
                </div>
              </div>
            </div>
          </div>
          <div className="bg-white p-6 rounded-xl border border-gray-100 shadow-sm hover:shadow-md transition-shadow">
            <div className="flex items-center gap-3">
              <div className="p-2 bg-eleghoBlue/10 rounded-lg">
                <FiCalendar className="w-5 h-5 text-eleghoBlue" />
              </div>
              <div>
                <div className="text-sm text-gray-600">
                  Scheduled/Dispatched
                </div>
                <div className="text-2xl font-semibold text-eleghoBlue">
                  {stats.scheduledConcerns}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="flex items-center justify-normal gap-4 mt-2">
          {filterLinks.map((link, index) => (
            <div
              key={index}
              className={`cursor-pointer text-sm rounded-full border-2 border-gray-300 px-2 py-1 min-w-20 text-center ${
                activeFilter === link ? "active-pill" : "inactive-pill "
              }
                `}
              onClick={() => setActiveFilter(link)}
            >
              {link}
            </div>
          ))}
        </div>

        <div className="flex items-center justify-between p-2 rounded-lg border-2 border-gray-300 mt-4">
          <div>
            <input
              type="search"
              placeholder="Search by Address or Concern"
              className="border-2 border-gray-300 px-4 py-2 rounded-lg w-60"
              onChange={handleSearch}
            />
          </div>
        </div>

        <BentoTable
          label="Residents' Concerns"
          data={filteredConcerns}
          numberOfItemPerPage={numberOfItemPerPage}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        >
          <div className="divide-y divide-gray-100">
            <div className="grid grid-cols-5 gap-2 p-3 bg-gray-50 text-xs font-medium text-gray-500">
              <div>Unit</div>
              <div>Concern</div>
              <div>Description</div>
              <div>Filed on</div>
              <div>Status</div>
            </div>
            {slidedData.map((concern, index) => (
              <div
                key={index}
                onClick={() => handleViewMode(concern)}
                className="hover:bg-gray-50 transition-colors cursor-pointer"
              >
                <ConcernsCard concern={concern} />
              </div>
            ))}
          </div>
        </BentoTable>
      </div>

      {viewMode && (
        <PageModal>
          <ConcernsPageDetails
            concern={selectedConcern}
            setViewMode={setViewMode}
          />
        </PageModal>
      )}
    </div>
  );
}

export default JobOrders;
