import React, { useState, useEffect } from "react";
import QrScanner from "react-qr-scanner";
import FlipCameraIosIcon from "@mui/icons-material/FlipCameraIos";
import { useNavigate } from "react-router-dom";
import MobileWrapper from "../../components/MobileWrapper";

export default function ScanQR() {
  const [result, setResult] = useState(null);
  const [facingMode, setFacingMode] = useState("environment"); // Default to back camera
  const navigate = useNavigate();

  const handleScan = (data) => {
    if (data) {
      setResult(data);
      // You can now navigate to the scanned URL, e.g.
      navigate(data.text);
    }
  };

  const handleError = (err) => {
    console.error(err);
  };

  const toggleCamera = () => {
    // Toggle between 'environment' and 'user' to switch cameras
    if (facingMode === "environment") {
      setFacingMode("user");
    } else {
      setFacingMode("environment");
    }
  };

  return (
    <MobileWrapper label={"Scan QR Code"}>
      <div>
        <div className="header text-center">Scan QR Code</div>
        <QrScanner
          delay={300}
          onError={handleError}
          onScan={handleScan}
          style={{ width: "100%" }}
          constraints={{
            audio: false,
            video: { facingMode: facingMode },
          }}
          // Pass the facing mode to QR Scanner
        />
        <div>{result && <p>Scanned result: {result}</p>}</div>
        <div className="text-center">
          <button onClick={toggleCamera}>
            <FlipCameraIosIcon />
          </button>
        </div>
      </div>
    </MobileWrapper>
  );
}
