import React, { useEffect } from "react";
import { getAllDocsSnap } from "../utils/firebase/Firebase.utils";
import SelectInput from "../pages/Dashboard/Components/SelectInput";

export default function TaskAssignmentModule({
  assignedToArray,
  setAssignedToArray,
  editMode,
}) {
  const [adminUsers, setAdminUsers] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(true);

  useEffect(() => {
    const fetchAdminUsers = async () => {
      await getAllDocsSnap("users", (data) => {
        const adminUsers = data.filter((user) => !user.roles?.includes("user"));
        setAdminUsers(adminUsers);
        setIsLoading(false);
      });
    };

    fetchAdminUsers();
  }, []);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  const userOptions = adminUsers.map((user) => ({
    value: user.email,
    label: user.displayName,
  }));

  return (
    <div>
      <SelectInput
        label="Assign To"
        name="assignedTo"
        options={userOptions}
        isMulti
        onChange={(selected) => setAssignedToArray(selected)}
        value={assignedToArray}
        disabled={!editMode}
        required
      />
    </div>
  );
}
