import React, { useEffect } from "react";
import { IoIosArrowBack } from "react-icons/io";
import {
  FiUser,
  FiMail,
  FiNavigation2,
  FiCalendar,
  FiClock,
  FiUpload,
  FiX,
  FiPlus,
  FiCheck,
  FiHome,
} from "react-icons/fi";
import InputBox from "../Login/InputBox";
import {
  setDocData,
  getDocDataSnap,
  addNewDoc,
} from "../../utils/firebase/Firebase.utils";
import UserScreenWindow from "./UserScreenWindow";
import { useSelector } from "react-redux";

import { storage } from "../../utils/firebase/Firebase.utils";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { current } from "@reduxjs/toolkit";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import Select from "react-select";
import { addNewNotification } from "../../utils/EleghoUtils";
import { sendNotification } from "../../utils/notifications";
import PageModal from "../Dashboard/Components/PageModal";
import { v4 } from "uuid";
import { motion } from "framer-motion";

function AddGuests() {
  const { id } = useParams();
  const [numberOfGuests, setNumberOfGuests] = React.useState(1);
  const [formData, setFormData] = React.useState([]);
  const [guestsArray, setGuestsArray] = React.useState([{}]); // Initialize with one guest object
  const [inProcess, setInProcess] = React.useState(false);
  const [linkedIdentifiers, setLinkedIdentifiers] = React.useState([]);
  const [uploadingInProgress, setUploadingInProgress] = React.useState(false);
  const [progress, setProgress] = React.useState(0);
  const [activeFileUpload, setActiveFileUpload] = React.useState(null);
  const [selectedId, setSelectedId] = React.useState(null);

  const currentUser = useSelector((state) => state.userObject);
  const navigate = useNavigate();

  useEffect(() => {
    const getIdentifiers = async () => {
      const data = await getDocDataSnap("users", currentUser.uid, (data) => {
        setLinkedIdentifiers(data.linkedIdentifiers);
      });
    };
    getIdentifiers();
  }, []);

  useEffect(() => {
    if (id === undefined) return;

    const fetchDetails = async () => {
      const data = await getDocDataSnap("guests", id, (data) => {
        setFormData(data);
        setGuestsArray(data.guests);
        setNumberOfGuests(data.guests.length);
      });
    };
    fetchDetails();
  }, []);

  const addGuest = () => {
    setNumberOfGuests((prev) => prev + 1);
    setGuestsArray((prev) => [...prev, {}]); // Append a new guest object
  };

  const handleChange = (e, index) => {
    const updatedGuests = guestsArray.map((guest, idx) => {
      if (idx === index) {
        return { ...guest, [e.target.name]: e.target.value };
      }
      return guest;
    });
    setGuestsArray(updatedGuests);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setInProcess(true);

    const { date, time } = formData;
    const dataObject = {
      date,
      time,
      guests: guestsArray,
      linkedIdentifiers: formData.linkedIdentifiers || [],
      requestorName: currentUser.displayName,
      requestedBy: currentUser.email,
      user: currentUser.email,
      status: "pending",
      requestDate: new Date().toISOString(),
    };
    if (!date || !time) {
      toast.error("Please fill all fields");
      setInProcess(false);
      return;
    }

    if (guestsArray.some((guest) => !guest.name || !guest.email)) {
      toast.error("Please fill all guest fields");
      setInProcess(false);
      return;
    }

    if (guestsArray.some((guest) => !guest.email.includes("@"))) {
      toast.error("Please enter a valid email address");
      setInProcess(false);
      return;
    }

    if (guestsArray.length < 1) {
      toast.error("Please add at least one guest");
      setInProcess(false);
      return;
    }

    if (formData.linkedIdentifiers.length < 1) {
      toast.error("Please select a unit to be visited");
      setInProcess(false);
      return;
    }

    if (id !== undefined) {
      await setDocData("guests", id, dataObject);
      toast.success("Guest Details Updated");
      setInProcess(false);
      navigate("/register-guests");
      return;
    }

    await addNewDoc("guests", dataObject);
    toast.success("Guest Registration Successful");

    const notification = {
      user: "admin",
      to: "admin@tali.com",
      from: currentUser.email,
      requestor: currentUser.displayName,
      subject: "New Guest Registration",
      message: `New guest registration request from ${
        currentUser.email
      } for ${currentUser.linkedIdentifiers.join(", ")}`,
      dateTime: new Date(),
    };

    // Store notification in Firestore
    await addNewNotification(notification);

    // Send FCM notification to admin
    const fcmNotification = {
      title: "New Guest Registration",
      body: `New guest registration request from ${
        currentUser.displayName
      } for ${currentUser.linkedIdentifiers.join(", ")}`,
      data: {
        type: "guest_registration",
        url: "/admin/guests",
        priority: "high",
        timestamp: new Date().toISOString(),
      },
    };

    await sendNotification("admin@tali.com", fcmNotification);

    setInProcess(false);
    navigate("/register-guests");
  };

  const handleUpload = async (e, index) => {
    const file = e.target.files[0];
    const storageRef = ref(storage, `guests/${file.name} + ${v4()}`);
    const uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setProgress(progress);
        setUploadingInProgress(true);
        setActiveFileUpload(index);
      },
      (error) => {},
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          const updatedGuests = guestsArray.map((guest, idx) => {
            if (idx === index) {
              return { ...guest, identification: downloadURL };
            }
            return guest;
          });
          setGuestsArray(updatedGuests);
          setUploadingInProgress(false);
          setActiveFileUpload(null);
        });
      }
    );
  };

  const handleViewIdentification = (photo) => {
    setSelectedId(photo);
  };

  return (
    <UserScreenWindow label={"Add Guest"}>
      <div className="min-h-screen bg-gray-50 p-4">
        <div className="max-w-4xl mx-auto">
          {/* Header */}
          <div className="flex items-center gap-4 mb-6">
            <button
              onClick={() => navigate(-1)}
              className="p-2 rounded-full bg-white shadow-sm hover:bg-gray-50 transition-colors"
            >
              <IoIosArrowBack className="w-5 h-5 text-eleghoBlue" />
            </button>
            <h1 className="text-xl font-semibold text-eleghoBlue">
              Guest Registration
            </h1>
          </div>

          <form onSubmit={handleSubmit} className="space-y-6">
            {/* Date and Time Selection */}
            <div className="bg-white rounded-xl shadow-sm p-6 space-y-4">
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <div className="space-y-2">
                  <label className="flex items-center gap-2 text-sm font-medium text-gray-700">
                    <FiCalendar className="w-4 h-4 text-eleghoBlue" />
                    Visit Date
                  </label>
                  <input
                    type="date"
                    required
                    onChange={(e) =>
                      setFormData({ ...formData, date: e.target.value })
                    }
                    value={formData.date}
                    className="w-full px-3 py-2 border border-gray-200 rounded-lg focus:ring-2 focus:ring-eleghoBlue/20 focus:border-eleghoBlue"
                  />
                </div>
                <div className="space-y-2">
                  <label className="flex items-center gap-2 text-sm font-medium text-gray-700">
                    <FiClock className="w-4 h-4 text-eleghoBlue" />
                    Visit Time
                  </label>
                  <input
                    type="time"
                    required
                    onChange={(e) =>
                      setFormData({ ...formData, time: e.target.value })
                    }
                    value={formData.time}
                    className="w-full px-3 py-2 border border-gray-200 rounded-lg focus:ring-2 focus:ring-eleghoBlue/20 focus:border-eleghoBlue"
                  />
                </div>
              </div>

              {/* Unit Selection */}
              <div className="space-y-2">
                <label className="flex items-center gap-2 text-sm font-medium text-gray-700">
                  <FiHome className="w-4 h-4 text-eleghoBlue" />
                  Units to Visit
                </label>
                <Select
                  options={linkedIdentifiers.map((identifier) => ({
                    value: identifier,
                    label: identifier,
                  }))}
                  isMulti
                  placeholder="Select units to visit"
                  required
                  onChange={(value) =>
                    setFormData({
                      ...formData,
                      linkedIdentifiers: value.map((val) => val.value),
                    })
                  }
                  value={
                    formData.linkedIdentifiers &&
                    formData.linkedIdentifiers.map((identifier) => ({
                      value: identifier,
                      label: identifier,
                    }))
                  }
                  className="react-select-container"
                  classNamePrefix="react-select"
                />
              </div>
            </div>

            {/* Guest Information */}
            <div className="space-y-4">
              {Array.from({ length: numberOfGuests }).map((_, index) => (
                <motion.div
                  key={index}
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  className="bg-white rounded-xl shadow-sm p-6 relative"
                >
                  <div className="flex items-center justify-between mb-4">
                    <h3 className="text-lg font-medium text-eleghoBlue">
                      Guest {index + 1}
                    </h3>
                    {index > 0 && (
                      <button
                        type="button"
                        onClick={() => {
                          setNumberOfGuests(numberOfGuests - 1);
                          setGuestsArray(
                            guestsArray.filter((_, idx) => idx !== index)
                          );
                        }}
                        className="p-2 text-gray-400 hover:text-red-500 transition-colors"
                      >
                        <FiX className="w-5 h-5" />
                      </button>
                    )}
                  </div>

                  <div className="space-y-4">
                    <div className="space-y-2">
                      <label className="flex items-center gap-2 text-sm font-medium text-gray-700">
                        <FiUser className="w-4 h-4 text-eleghoBlue" />
                        Full Name
                      </label>
                      <input
                        type="text"
                        name="name"
                        onChange={(e) => handleChange(e, index)}
                        value={guestsArray[index]?.name || ""}
                        required
                        className="w-full px-3 py-2 border border-gray-200 rounded-lg focus:ring-2 focus:ring-eleghoBlue/20 focus:border-eleghoBlue"
                        placeholder="Enter guest's full name"
                      />
                    </div>

                    <div className="space-y-2">
                      <label className="flex items-center gap-2 text-sm font-medium text-gray-700">
                        <FiNavigation2 className="w-4 h-4 text-eleghoBlue" />
                        Vehicle Plate Number
                      </label>
                      <input
                        type="text"
                        name="plateNumber"
                        onChange={(e) => handleChange(e, index)}
                        value={guestsArray[index]?.plateNumber || ""}
                        required
                        className="w-full px-3 py-2 border border-gray-200 rounded-lg focus:ring-2 focus:ring-eleghoBlue/20 focus:border-eleghoBlue"
                        placeholder="Enter vehicle plate number"
                      />
                    </div>

                    <div className="space-y-2">
                      <label className="flex items-center gap-2 text-sm font-medium text-gray-700">
                        <FiMail className="w-4 h-4 text-eleghoBlue" />
                        Email Address
                      </label>
                      <input
                        type="email"
                        name="email"
                        onChange={(e) => handleChange(e, index)}
                        value={guestsArray[index]?.email || ""}
                        required
                        className="w-full px-3 py-2 border border-gray-200 rounded-lg focus:ring-2 focus:ring-eleghoBlue/20 focus:border-eleghoBlue"
                        placeholder="Enter guest's email address"
                      />
                    </div>

                    <div className="space-y-2">
                      <label className="flex items-center gap-2 text-sm font-medium text-gray-700">
                        <FiUpload className="w-4 h-4 text-eleghoBlue" />
                        Upload ID
                      </label>
                      <div className="flex items-center gap-4">
                        <input
                          type="file"
                          onChange={(e) => handleUpload(e, index)}
                          accept="image/*"
                          className="hidden"
                          id={`file-upload-${index}`}
                        />
                        <label
                          htmlFor={`file-upload-${index}`}
                          className="flex-1 px-4 py-2 border border-gray-200 rounded-lg cursor-pointer hover:bg-gray-50 transition-colors text-center text-sm text-gray-600"
                        >
                          {guestsArray[index]?.identification
                            ? "Change ID Photo"
                            : "Upload ID Photo"}
                        </label>
                        {guestsArray[index]?.identification && (
                          <button
                            type="button"
                            onClick={() =>
                              handleViewIdentification(
                                guestsArray[index].identification
                              )
                            }
                            className="px-4 py-2 bg-eleghoBlue/10 text-eleghoBlue rounded-lg hover:bg-eleghoBlue/20 transition-colors"
                          >
                            View
                          </button>
                        )}
                      </div>
                      {uploadingInProgress && activeFileUpload === index && (
                        <div className="w-full bg-gray-200 rounded-full h-2">
                          <div
                            className="bg-eleghoBlue h-2 rounded-full transition-all duration-300"
                            style={{ width: `${progress}%` }}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </motion.div>
              ))}

              {numberOfGuests < 12 && (
                <motion.button
                  type="button"
                  onClick={addGuest}
                  className="w-full py-3 bg-eleghoGreen/10 text-eleghoGreen rounded-lg hover:bg-eleghoGreen/20 transition-colors flex items-center justify-center gap-2"
                  whileHover={{ scale: 1.02 }}
                  whileTap={{ scale: 0.98 }}
                >
                  <FiPlus className="w-5 h-5" />
                  Add Another Guest
                </motion.button>
              )}
            </div>

            {/* Submit Button */}
            <motion.button
              type="submit"
              disabled={inProcess}
              className="w-full py-3 bg-eleghoBlue text-white rounded-lg hover:bg-eleghoBlue/90 transition-colors flex items-center justify-center gap-2 disabled:opacity-50 disabled:cursor-not-allowed"
              whileHover={{ scale: 1.02 }}
              whileTap={{ scale: 0.98 }}
            >
              {inProcess ? (
                <>
                  <div className="w-5 h-5 border-2 border-white border-t-transparent rounded-full animate-spin" />
                  Processing...
                </>
              ) : (
                <>
                  <FiCheck className="w-5 h-5" />
                  {id !== undefined
                    ? "Update Guest Details"
                    : "Register Guests"}
                </>
              )}
            </motion.button>

            <p className="text-sm text-gray-500 text-center">
              Notice: Upon Registration, your guests will receive an email with
              a link where they can upload their valid IDs.
            </p>
          </form>
        </div>
      </div>

      {selectedId && (
        <PageModal>
          <div className="page relative">
            <button
              className="close-button"
              onClick={() => setSelectedId(null)}
            >
              Close
            </button>
            <img
              src={selectedId}
              alt="Guest ID"
              className="max-w-full h-auto rounded-lg"
            />
          </div>
        </PageModal>
      )}
    </UserScreenWindow>
  );
}

export default AddGuests;
