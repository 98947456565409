import React from "react";
import { FiEye } from "react-icons/fi";

export default function ItemCard({ data }) {
  // Determine status based on quantity
  const stockStatus = parseInt(data.quantity) > 0 ? "in-stock" : "out-of-stock";

  const handleViewDetails = (e) => {
    e.stopPropagation(); // This prevents the card's onClick from firing
    // The parent component's click handler will handle the view details functionality
  };

  return (
    <div className="bg-white flex items-center gap-4 p-4 rounded-xl border border-gray-100 hover:border-eleghoBlue/20 hover:shadow-sm text-left transition-all duration-200">
      <div className="w-20">
        <div
          className={`capitalize text-xs font-medium px-3 py-1.5 rounded-full ${
            stockStatus === "in-stock"
              ? "bg-eleghoGreen/10 text-eleghoGreen"
              : "bg-gray-50 text-gray-600"
          }`}
        >
          {stockStatus}
        </div>
      </div>

      <div className="w-1/6">
        <div className="font-semibold text-gray-900 text-sm">
          {data.itemName}
        </div>
      </div>

      <div className="w-1/6">
        <div className="text-gray-600 text-sm">
          {data.location || "Not Set"}
        </div>
      </div>

      <div className="w-1/6">
        <div className="text-gray-600 text-sm capitalize">
          {data.custodian || "Not Assigned"}
        </div>
      </div>

      <div className="w-1/6">
        <div
          className={`text-sm ${
            parseInt(data.quantity) > 0
              ? "text-eleghoGreen font-medium"
              : "text-gray-500"
          }`}
        >
          {data.quantity || "0"}
        </div>
      </div>

      <div className="w-1/6 flex justify-end">
        <button
          onClick={handleViewDetails}
          className="text-eleghoBlue hover:text-eleghoBlue/80 transition-colors"
        >
          <FiEye className="w-4 h-4" />
        </button>
      </div>
    </div>
  );
}
