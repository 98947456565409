import React, { useEffect, useState } from "react";
import {
  addNewDoc,
  getAllDocsSnap,
} from "../../../utils/firebase/Firebase.utils";
import InputBox from "../Components/InputBox";
import SelectInput from "../Components/SelectInput";
import NumberInputText from "../Components/NumberInputText";
import toast from "react-hot-toast";

export default function BillingForm({ setAddMode }) {
  const [residents, setResidents] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({});
  const [isProcessing, setIsProcessing] = useState(false);

  const billingTypes = ["Electricity", "Water", "Association Dues", "Others"];
  const billingTypeOptions = billingTypes.map((type) => {
    return {
      value: type,
      label: type,
    };
  });

  useEffect(() => {
    const fetchResidents = async () => {
      await getAllDocsSnap("residents", (data) => {
        //sort data alphabetically
        data.sort((a, b) => {
          if (a.owner1 < b.owner1) {
            return -1;
          }
          if (a.owner1 > b.owner1) {
            return 1;
          }
          return 0;
        });
        setResidents(data);
        setIsLoading(false);
      });
    };
    fetchResidents();
  }, []);

  const identifierOptions = residents.map((resident) => {
    return {
      value: resident.identifier,
      label: `${resident.owner1} (${resident.identifier})`,
      name: resident.owner1,
    };
  });

  if (isLoading) {
    return <div>Loading...</div>;
  }

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const removeCommas = (num) => {
    return num.replace(/,/g, "");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const amount = removeCommas(formData.amount);
    setIsProcessing(true);

    await addNewDoc("billings", {
      ...formData,
      billingAmount: amount,
      status: "unpaid",
    });
    toast.success("Billing created successfully");
    setIsProcessing(false);
    setAddMode(false);
  };

  return (
    <div className="page relative">
      <button className="close-button" onClick={() => setAddMode(false)}>
        Close
      </button>
      <div className="header">Create new billing</div>

      <div className="w-96 mt-4">
        <form action="" onSubmit={handleSubmit}>
          <div className="space-y-4">
            <SelectInput
              label="Select Resident"
              options={identifierOptions}
              required
              type="text"
              onChange={(e) =>
                setFormData({ ...formData, identifier: e.value, name: e.name })
              }
            />

            <SelectInput
              label="Billing Type"
              options={billingTypeOptions}
              required
              type="text"
              onChange={(e) =>
                setFormData({ ...formData, billingType: e.value })
              }
            />
            <InputBox
              type={"text"}
              label="SOA Number"
              name="soaNumber"
              required
              onChange={handleChange}
            />

            <InputBox
              type={"date"}
              label="Billing Date"
              name="billingDate"
              required
              onChange={handleChange}
            />

            <InputBox
              type={"date"}
              label="Due Date"
              name="dueDate"
              required
              onChange={handleChange}
            />

            <NumberInputText
              type="number"
              name="amount"
              label={"Amount"}
              placeholder={"Enter Amount"}
              formData={formData}
              setFormData={setFormData}
            />

            <InputBox
              label="Description"
              name="description"
              placeholder={"Enter Description/Particulars"}
              required
              type="text"
              onChange={handleChange}
            />
          </div>

          <div className="mt-4">
            <button
              type="submit"
              className="small-form-button"
              disabled={isProcessing}
            >
              {isProcessing ? "Processing..." : "Save Billing"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
