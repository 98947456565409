import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  getAllDocsSnap,
  updateDocById,
} from "../../utils/firebase/Firebase.utils";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";

export default function ParcelLogs() {
  const currentUser = useSelector((state) => state.userObject);
  const [parcels, setParcels] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedImage, setSelectedImage] = useState(null);
  const [showModal, setShowModal] = useState(false);

  // Filter options
  const [filter, setFilter] = useState("all"); // "all", "delivered", "collected"

  useEffect(() => {
    // Subscribe to real-time updates from the parcels collection
    const unsubscribe = getAllDocsSnap("parcels", (parcelData) => {
      // Sort by dateTime (newest first)
      const sortedParcels = parcelData.sort(
        (a, b) => new Date(b.dateTime) - new Date(a.dateTime)
      );
      setParcels(sortedParcels);
      setLoading(false);
    });

    // Cleanup subscription on component unmount
    return () => unsubscribe && unsubscribe();
  }, []);

  const filteredParcels =
    filter === "all"
      ? parcels
      : parcels.filter(
          (parcel) => parcel.status.toLowerCase() === filter.toLowerCase()
        );

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleString();
  };

  const handleStatusChange = async (parcelId, newStatus) => {
    try {
      await updateDocById("parcels", parcelId, {
        status: newStatus,
        collectedAt:
          newStatus === "Collected" ? new Date().toISOString() : null,
      });
      toast.success(`Parcel marked as ${newStatus}`);
    } catch (error) {
      console.error("Error updating parcel status:", error);
      toast.error("Failed to update parcel status");
    }
  };

  const handleOpenImage = (imageUrl) => {
    setSelectedImage(imageUrl);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedImage(null);
  };

  const allowedUsers = ["admin", "reception", "security"];

  return (
    <div className="text-left p-4">
      <div className="flex justify-between items-center mb-4">
        <div className="text-lg font-medium text-eleghoBlue">Parcel Logs</div>
        <Link
          to="/parcels"
          className="text-sm text-eleghoBlue flex items-center"
        >
          <span>Add New</span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-4 w-4 ml-1"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M12 4v16m8-8H4"
            />
          </svg>
        </Link>
      </div>

      {/* Filters */}
      <div className="flex gap-2 mb-4">
        <button
          className={`px-3 py-1.5 text-sm rounded-md ${
            filter === "all"
              ? "bg-eleghoBlue text-white"
              : "bg-gray-100 text-gray-600"
          }`}
          onClick={() => setFilter("all")}
        >
          All
        </button>
        <button
          className={`px-3 py-1.5 text-sm rounded-md ${
            filter === "delivered"
              ? "bg-eleghoBlue text-white"
              : "bg-gray-100 text-gray-600"
          }`}
          onClick={() => setFilter("delivered")}
        >
          Delivered
        </button>
        <button
          className={`px-3 py-1.5 text-sm rounded-md ${
            filter === "collected"
              ? "bg-eleghoBlue text-white"
              : "bg-gray-100 text-gray-600"
          }`}
          onClick={() => setFilter("collected")}
        >
          Collected
        </button>
      </div>

      {/* Parcel list */}
      <div className="space-y-3">
        {loading ? (
          <div className="text-center py-10 bg-gray-50 rounded-lg">
            <div className="text-gray-500">Loading parcels...</div>
          </div>
        ) : filteredParcels.length > 0 ? (
          filteredParcels.map((parcel) => (
            <div
              key={parcel.id}
              className="bg-white rounded-lg p-4 shadow-sm border border-gray-100"
            >
              <div className="flex justify-between mb-2">
                <div className="font-medium text-eleghoBlue">
                  {parcel.unitNumber}
                </div>
                <div
                  className={`text-xs px-2 py-1 rounded-full ${
                    parcel.status === "Delivered"
                      ? "bg-green-100 text-green-700"
                      : "bg-blue-100 text-blue-700"
                  }`}
                >
                  {parcel.status}
                </div>
              </div>

              <div className="grid grid-cols-2 gap-2 text-sm">
                <div>
                  <div className="text-gray-500">Date & Time:</div>
                  <div>{formatDate(parcel.dateTime)}</div>
                </div>
                <div>
                  <div className="text-gray-500">Delivery Company:</div>
                  <div>{parcel.deliveryCompany || "N/A"}</div>
                </div>
                <div>
                  <div className="text-gray-500">Storage Location:</div>
                  <div>{parcel.storageLocation || "N/A"}</div>
                </div>
                {parcel.createdBy && (
                  <div>
                    <div className="text-gray-500">Logged by:</div>
                    <div>{parcel.createdBy}</div>
                  </div>
                )}
              </div>

              <div className="flex justify-between mt-3">
                {currentUser.roles.some((role) =>
                  allowedUsers.includes(role)
                ) && (
                  <div>
                    {parcel.status === "Delivered" ? (
                      <button
                        className="px-3 py-1 text-xs bg-blue-50 text-blue-600 rounded-md"
                        onClick={() =>
                          handleStatusChange(parcel.id, "Collected")
                        }
                      >
                        Mark as Collected
                      </button>
                    ) : (
                      <button
                        className="px-3 py-1 text-xs bg-green-50 text-green-600 rounded-md"
                        onClick={() =>
                          handleStatusChange(parcel.id, "Delivered")
                        }
                      >
                        Mark as Delivered
                      </button>
                    )}
                  </div>
                )}

                {parcel.imageUrl && (
                  <button
                    className="text-eleghoBlue text-sm"
                    onClick={() => handleOpenImage(parcel.imageUrl)}
                  >
                    View Photo
                  </button>
                )}
              </div>
            </div>
          ))
        ) : (
          <div className="text-center py-10 bg-gray-50 rounded-lg">
            <div className="text-gray-500">No parcels found</div>
          </div>
        )}
      </div>

      {/* Image Modal */}
      {showModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4">
          <div className="bg-white rounded-lg max-w-lg w-full max-h-[90vh] overflow-hidden relative">
            <button
              className="absolute top-2 right-2 text-gray-600 hover:text-gray-900 p-2 z-10"
              onClick={handleCloseModal}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
            <div className="p-1 flex items-center justify-center overflow-auto max-h-[80vh]">
              <img
                src={selectedImage}
                alt="Parcel"
                className="max-w-full max-h-full object-contain"
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
