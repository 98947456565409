import React from "react";

export default function IconButton({ icon, label, onClick }) {
  return (
    <div>
      <button
        className="bg-white px-4 py-2 text-xs text-eleghoBlue 
      font-semibold flex items-center gap-4 justify-center border border-gray-200 rounded-lg
      min-w-36
      "
        onClick={onClick}
      >
        {icon}
        {label || "Button"}
      </button>
    </div>
  );
}
