import * as React from "react";
import { TrendingUp } from "lucide-react";
import { Label, Pie, PieChart, Tooltip, Cell } from "recharts";
import { useCompany } from "../context/CompanyContext";

import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "./card.jsx";
import {
  ChartConfig,
  ChartContainer,
  ChartTooltip,
  ChartTooltipContent,
} from "./chart.jsx";

export function PieChartV1({ data }) {
  const { getTerm, getCapitalizedTerm } = useCompany();

  const totalAmount = React.useMemo(() => {
    if (!data?.chartData) return 0;
    return data.chartData.reduce((acc, curr) => acc + curr.data, 0);
  }, [data]);

  if (!data?.chartData) {
    return <div>No data available</div>;
  }

  return (
    <div className="w-full">
      <div className="text-center mb-4">
        <h3 className="text-lg font-semibold">{data.title}</h3>
        <p className="text-sm text-gray-500">{data.cardDescription}</p>
      </div>
      <div className="relative w-full aspect-square max-w-[300px] mx-auto">
        <PieChart width={300} height={300}>
          <Tooltip
            content={({ active, payload }) => {
              if (active && payload && payload.length) {
                return (
                  <div className="bg-white p-2 shadow-lg rounded-lg border">
                    <p className="text-sm font-medium">{payload[0].name}</p>
                    <p className="text-sm text-gray-600">
                      {payload[0].value} {getTerm("units")}
                    </p>
                  </div>
                );
              }
              return null;
            }}
          />
          <Pie
            data={data.chartData}
            dataKey="data"
            nameKey="label"
            cx="50%"
            cy="50%"
            innerRadius={60}
            outerRadius={80}
          >
            {data.chartData.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={entry.fill} />
            ))}
            <Label
              content={({ viewBox }) => {
                const { cx, cy } = viewBox;
                return (
                  <g>
                    <text
                      x={cx}
                      y={cy}
                      textAnchor="middle"
                      dominantBaseline="central"
                      className="text-2xl font-bold fill-current"
                    >
                      {data.total}
                    </text>
                    <text
                      x={cx}
                      y={cy + 20}
                      textAnchor="middle"
                      dominantBaseline="central"
                      className="text-sm fill-gray-500"
                    >
                      Total {getCapitalizedTerm("units")}
                    </text>
                  </g>
                );
              }}
            />
          </Pie>
        </PieChart>
      </div>
      <div className="flex justify-center gap-4 mt-4">
        {data.chartData.map((entry, index) => (
          <div key={index} className="flex items-center gap-2">
            <div
              className="w-3 h-3 rounded-full"
              style={{ backgroundColor: entry.fill }}
            />
            <span className="text-sm">
              {entry.label} ({entry.data})
            </span>
          </div>
        ))}
      </div>
    </div>
  );
}
