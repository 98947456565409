import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  MdOutlineSos,
  MdOutlineConstruction,
  MdOutlinePayments,
} from "react-icons/md";
import { TbBuildingCottage } from "react-icons/tb";
import { FiUsers, FiSettings } from "react-icons/fi";
import { IoMdPerson } from "react-icons/io";
import { TbReport } from "react-icons/tb";
import { IoPeople } from "react-icons/io5";

function AdminQuickAccessMobile() {
  const currentUser = useSelector((state) => state.userObject);

  const apps = [
    {
      appName: "SOS",
      roles: ["super-admin", "admin", "all"],
      icon: <MdOutlineSos size={24} />,
      path: "/admin/mobile/sos",
    },
    {
      appName: "Condo Corp",
      roles: ["super-admin", "admin", "all"],
      icon: <TbBuildingCottage size={24} />,
      path: "/admin/mobile/condo-corp",
    },
    {
      appName: "Resident List",
      roles: ["super-admin", "admin", "all"],
      icon: <IoPeople size={24} />,
      path: "/admin/mobile/residents",
    },
    {
      appName: "Suppliers",
      roles: [
        "super-admin",
        "admin",
        "engineering",
        "maintenance",
        "security",
        "reception",
        "admin",
        "super-admin",
        "finance",
        "board",
      ],
      icon: <MdOutlineConstruction size={24} />,
      path: "/admin/mobile/suppliers",
    },
  ];

  // Filter apps based on user role
  const filteredApps = apps.filter(
    (app) =>
      app.roles.includes("all") ||
      currentUser?.roles?.some((role) => app.roles.includes(role))
  );

  return (
    <div className="p-4">
      <h2
        className="text-lg font-semibold mb-4 text-center"
        style={{ color: "#363062" }}
      >
        Quick Access
      </h2>
      <div className="grid grid-cols-4 gap-3">
        {filteredApps.map((app, index) => (
          <Link
            key={index}
            to={app.path}
            className="flex flex-col items-center justify-start p-2 rounded-lg hover:bg-gray-100"
          >
            <div
              className="w-12 h-12 rounded-full flex items-center justify-center mb-1"
              style={{ backgroundColor: "#363062" }}
            >
              <span className="text-white">{app.icon}</span>
            </div>
            <span className="text-xs text-center" style={{ color: "#363062" }}>
              {app.appName}
            </span>
          </Link>
        ))}
      </div>
    </div>
  );
}

export default AdminQuickAccessMobile;
