import SelectInput from "../Dashboard/Components/SelectInput";
import React, { useEffect } from "react";
import {
  getDocDataSnap,
  setDocData,
} from "../../utils/firebase/Firebase.utils";
import { useSelector } from "react-redux";
import { setDoc } from "firebase/firestore";
import toast from "react-hot-toast";
import CloseButton from "../../components/CloseButton";
import HistoryModule from "../../components/HistoryModule";
import TaskAssignmentModule from "../../components/TaskAssignmentModule";
import DetailsPageHead from "../../components/DetailsPageHead";
import DisplayAvatar from "../../components/DisplayAvatar";
import CommentBlock from "../../components/CommentBlock";
import { sendNotification } from "../../utils/notifications";
import { addNewNotification } from "../../utils/EleghoUtils";

export default function GatePassDetailsPage({ data, setViewMode }) {
  const [newStatus, setNewStatus] = React.useState("");
  const [formData, setFormData] = React.useState(data);
  const [updateHappened, setUpdateHappened] = React.useState(false);
  const [addMode, setAddMode] = React.useState(false);
  const [comments, setComments] = React.useState([]);
  const [newComment, setNewComment] = React.useState("");
  const [editMode, setEditMode] = React.useState(false);
  const [assignedToArray, setAssignedToArray] = React.useState(
    data.assignedTo || []
  );

  const currentUser = useSelector((state) => state.userObject);

  useEffect(() => {
    const fetchData = async () => {
      await getDocDataSnap("gatePass", data.id, (data) => {
        setFormData(data);
        setComments(data.comments || []);
      });
    };
    fetchData();
  }, []);

  const status = ["pending", "approved", "rejected", "completed", "returned"];
  const statusOptions = status.map((item) => ({ value: item, label: item }));

  const handleSelect = (selected) => {
    setUpdateHappened(true);
    setNewStatus(selected.value);
  };

  const handleUpdateStatus = async () => {
    const docObject = {
      status: newStatus,
      updatedBy: currentUser.uid,
      updatedName: currentUser.displayName,
      updatedEmail: currentUser.email,
      updateType: "Status is updated to " + newStatus,
      history: [
        ...(formData.history || ""),
        {
          action: "Status is updated to",
          to: newStatus,
          at: new Date().toLocaleString(),
          by: currentUser.displayName,
        },
      ],
    };

    await setDocData("gatePass", data.id, docObject);

    // Send notification to requestor
    const notification = {
      title: "Gate Pass Status Updated",
      body: `Your gate pass (${data.identifier}) status has been updated to ${newStatus}`,
      url: `/gate-pass/${data.id}`,
      data: {
        type: "gatePass",
        id: data.id,
      },
    };

    // Send notification using the requestor's email
    console.log(data.user);
    await sendNotification(data.user, notification);

    // Add to notifications collection
    await addNewNotification({
      subject: "Gate Pass Status Update",
      message: `Your gate pass (${data.identifier}) status has been updated to ${newStatus}`,
      user: data.requestedBy || data.user,
      dateTime: new Date(),
      type: "gatePass",
      id: data.id,
    });

    toast.success("Status Updated Successfully");
    setUpdateHappened(false);
    setEditMode(false);
  };

  const handleAddMode = () => {
    setAddMode(!addMode);
  };

  const handleChange = (e) => {
    setNewComment(e.target.value);
  };

  const handleSubmit = async () => {
    const docObject = {
      comments: [
        ...comments,
        {
          message: newComment,
          createdAt: new Date().toLocaleString(),
          createdBy: currentUser.displayName,
          email: currentUser.email,
        },
      ],
      history: [
        ...(formData.history || ""),
        {
          action: "Comment added by",
          to: currentUser.displayName,
          at: new Date().toLocaleString(),
          by: currentUser.displayName,
        },
      ],
    };
    await setDocData("gatePass", data.id, docObject);

    // Send notification to requestor
    const notification = {
      title: "New Comment on Gate Pass",
      body: `${currentUser.displayName} added a comment on your gate pass (${data.identifier})`,
      url: `/gate-pass/${data.id}`,
      data: {
        type: "gatePass",
        id: data.id,
      },
    };

    // Send notification using the requestor's email
    await sendNotification(data.requestedBy || data.user, notification);

    // Add to notifications collection
    await addNewNotification({
      subject: "New Gate Pass Comment",
      message: `${currentUser.displayName} added a comment on your gate pass (${data.identifier})`,
      user: data.requestedBy || data.user,
      dateTime: new Date(),
      type: "gatePass",
      id: data.id,
    });

    toast.success("Comment Added Successfully");
    setNewComment("");
  };

  const handleCancelEdit = () => {
    setEditMode(false);
    setFormData(data);
    setAssignedToArray(data.assignedTo || []);
    toast.error("Edit Cancelled");
  };

  const handleAssignment = () => {
    if (editMode) {
      const docObject = {
        ...formData,
        updatedAt: new Date().toLocaleString(),
        updatedBy: currentUser.uid,
        updatedName: currentUser.displayName,
        updatedEmail: currentUser.email,
        updateType: "Gate Pass is assigned",
        assignedTo: assignedToArray,
        history: [
          ...(formData?.history || ""),
          {
            action:
              assignedToArray.length === 0
                ? "Unassigned"
                : "Gate Pass is Assigned to",
            at: new Date().toLocaleString(),
            by: currentUser.displayName,
            to: assignedToArray.map((item) => item.label).join(", "),
          },
        ],
      };

      setDocData("gatePass", data.id, docObject);
      toast.success("Gate Pass is Updated");
      setEditMode(false);
    }
  };

  const handleDeleteComment = async (index) => {
    if (window.confirm("Are you sure you want to delete this comment?")) {
      const newComments = comments.filter((item, i) => i !== index);
      await setDocData("gatePass", data.id, {
        comments: newComments,
        history: [
          ...(formData.history || ""),
          {
            action: "Comment deleted by",
            to: currentUser.displayName,
            at: new Date().toLocaleString(),
            by: currentUser.displayName,
          },
        ],
      });
      toast.success("Comment Successfully Deleted");
    }
  };

  return (
    <div className="page">
      <button className="close-button" onClick={() => setViewMode(false)}>
        <CloseButton />
      </button>

      {/* main header */}
      <DetailsPageHead
        label={"Gate Pass Details"}
        editMode={editMode}
        setEditMode={setEditMode}
        handleCancelEdit={handleCancelEdit}
      />

      {/* main content */}

      <div className="flex flex-col lg:flex-row justify-normal items-start gap-4 text-gray-600">
        {/* left */}
        <div className="min-w-96">
          {/* details of gate pass */}
          <div className="section-box">
            <div className="section-header">Gate Pass Request Details</div>
            <div className="flex items-center justify-between">
              <div className="font-bold">Unit Number</div>
              <div>{data.identifier}</div>
            </div>
            <div className="flex items-center justify-between">
              <div className="font-bold">Provider</div>
              <div className="capitalize">{data.deliveryCompany}</div>
            </div>
            <div className="flex flex-col items-start mb-4 mt-2">
              <div className="font-bold">Items</div>
              <div>{data.items}</div>
            </div>
            <div className="flex items-center justify-between">
              <div className="font-bold">Date of Delivery</div>
              <div>{data.deliveryDate}</div>
            </div>
            <div className="flex items-center justify-between">
              <div className="font-bold">Request Date & Time</div>
              <div>
                {new Date(
                  data.requestDateTime?.seconds * 1000
                ).toLocaleDateString()}{" "}
                {new Date(
                  data.requestDateTime?.seconds * 1000
                ).toLocaleTimeString()}
              </div>
            </div>
            <div className="flex items-center justify-between">
              <div className="font-bold">Requested By</div>
              <div>{data.requestedBy || data.user}</div>
            </div>
            <div className="flex items-center justify-between">
              <div className="font-bold">Status</div>
              <div className={`capitalize ${formData.status}`}>
                {formData.status}
              </div>
            </div>
          </div>

          {/* status update */}
          <div className="mt-4 section-box">
            <div className="section-header">Update Status</div>
            <div>
              <SelectInput
                name={"status"}
                placeholder={"Select Status"}
                options={statusOptions}
                onChange={(selected) => handleSelect(selected)}
                value={
                  statusOptions.find(
                    (option) => option.value === formData.status
                  ) || ""
                }
                disabled={!editMode}
                required
              />
              {editMode && (
                <button
                  className="blue-button mt-4"
                  disabled={!updateHappened}
                  onClick={handleUpdateStatus}
                >
                  Update Status
                </button>
              )}
            </div>
          </div>

          {/* assigned to */}
          <div className="section-box mt-4">
            <div className="section-header">Assign Task</div>
            <TaskAssignmentModule
              assignedToArray={assignedToArray}
              setAssignedToArray={setAssignedToArray}
              editMode={editMode}
            />
            {editMode && (
              <div className="mt-4 flex items-center gap-4 justify-end">
                <button className="blue-button" onClick={handleAssignment}>
                  Save
                </button>
                <button onClick={handleCancelEdit}>Cancel</button>
              </div>
            )}
          </div>
        </div>

        {/* right */}
        <div className="w-full max-w-[1080px]">
          {/* comments */}
          <div className="section-box">
            <div className="section-header">Comments</div>
            <div className="w-full">
              {/* <button className="blue-button" onClick={handleAddMode}>
                Add Comment
              </button> */}
              <textarea
                name="newComment"
                className="mt-4 rounded-lg p-2 border w-full bg-gray-100"
                id=""
                onChange={handleChange}
                placeholder="Add Comment Here"
                value={newComment}
              ></textarea>
              <div className="text-right">
                <button className="blue-button" onClick={handleSubmit}>
                  Submit
                </button>
              </div>
            </div>
            <div>
              <div className="font-bold">
                Comments{" "}
                <span className="text-xs rounded-full bg-orange-500 text-white px-2 p">
                  {comments.length}
                </span>
              </div>
              {comments.map((comment, index) => (
                <CommentBlock
                  key={index}
                  comment={comment}
                  currentUser={currentUser}
                  handleDeleteComment={() => handleDeleteComment(index)}
                />
              ))}
            </div>
          </div>

          {/* history */}
          <HistoryModule data={formData.history} />
        </div>
      </div>
    </div>
  );
}
