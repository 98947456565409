import React from "react";
import UserScreenWindow from "./UserScreenWindow";
import Select from "react-select";
import { useState } from "react";
import { storage } from "../../utils/firebase/Firebase.utils";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { v4 } from "uuid";
import { setDocData } from "../../utils/firebase/Firebase.utils";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import toast from "react-hot-toast";
import { useCompany } from "../../context/CompanyContext";

export default function ConcernsForm() {
  const { getCapitalizedTerm } = useCompany();
  const navigate = useNavigate();
  const currentUser = useSelector((state) => state.userObject);
  const [concern, setConcern] = useState("");
  const [formData, setFormData] = useState({
    identifier: currentUser.linkedIdentifiers[0],
  });
  const [isLoading, setIsLoading] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [progress, setProgress] = useState(0);
  const [isUploading, setIsUploading] = useState(false);
  const [assignedId, setAssignedId] = useState(v4());

  const concernList = [
    "Electricity",
    "Water Supply",
    "Water Leaks",
    "Internet",
    "Violation",
    "Neighbors",
    "Cutting of Trees",
    "Defective Water Meter",
    "Others",
  ];

  const identifiers = currentUser.linkedIdentifiers.map((identifier) => ({
    value: identifier,
    label: identifier,
  }));

  const options = concernList.map((concern) => ({
    value: concern,
    label: concern,
  }));

  const selectStyles = {
    control: (styles) => ({
      ...styles,
      backgroundColor: "white",
      borderColor: "#E2E8F0",
      borderRadius: "0.5rem",
      padding: "2px",
      boxShadow: "none",
      "&:hover": {
        borderColor: "#8FCD00",
      },
    }),
    option: (styles, { isFocused }) => ({
      ...styles,
      backgroundColor: isFocused ? "#F0F9E8" : "white",
      color: "#363062",
      "&:hover": {
        backgroundColor: "#E6F4D7",
      },
    }),
    singleValue: (styles) => ({
      ...styles,
      color: "#363062",
    }),
  };

  const handleSelectConcern = (selectedConcern) => {
    setConcern(selectedConcern.value);
  };

  const handleSelectLot = (selected) => {
    setFormData({ ...formData, identifier: selected.value });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleUpload = (e) => {
    const file = e.target.files[0];
    const storageRef = ref(storage, `concerns/${assignedId}/${file.name}`);
    const uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        setIsUploading(true);
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setProgress(progress);
      },
      (error) => {},
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          setFormData({ ...formData, image: downloadURL });
          setIsUploading(false);
        });
      }
    );
  };

  const handleUploadVideo = (e) => {
    const file = e.target.files[0];
    const storageRef = ref(storage, `concerns/${assignedId}/${file.name}`);
    const uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        setIsUploading(true);
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setProgress(progress);
      },
      (error) => {},
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          setFormData({ ...formData, video: downloadURL });
          setIsUploading(false);
        });
      }
    );
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsProcessing(true);

    //check if concern is selected
    if (!concern) {
      toast.error("Please select a concern");
      setIsProcessing(false);
      return;
    }

    //check if description is entered
    if (!formData.description) {
      toast.error("Please enter a description");
      setIsProcessing(false);
      return;
    }

    //check if either image or video is uploaded
    // if (!formData.image && !formData.video) {
    //   toast.error("Please upload an image or video");
    //   setIsProcessing(false);
    //   return;
    // }

    const concernData = {
      identifier: formData.identifier,
      concern: concern,
      description: formData.description,
      image: formData.image || "",
      video: formData.video || "",
      user: currentUser.email,
      createdBy: currentUser.displayName,
      uid: currentUser.uid,
      status: "Pending",
      createdAt: new Date().toLocaleString(),
    };

    await setDocData("concerns", assignedId, concernData);
    toast.success("Concern filed successfully");
    setIsProcessing(false);
    navigate(`/concern-confirmation/${assignedId}`);
  };

  return (
    <UserScreenWindow label="Concerns">
      <div className="text-[#363062] p-4 bg-white rounded-lg shadow-sm">
        <div className="text-lg font-bold mb-6 border-b-2 border-[#8FCD00] pb-2">
          File a New Concern
        </div>

        <div className="space-y-6">
          <div className="space-y-2">
            <div className="font-medium text-sm">
              {getCapitalizedTerm("identifier")}
            </div>
            <Select
              options={identifiers}
              name="identifier"
              placeholder="Select Unit Number"
              onChange={handleSelectLot}
              defaultValue={identifiers[0]}
              value={
                identifiers.find(
                  (option) => option.value === formData.identifier
                ) || identifiers[0]
              }
              styles={selectStyles}
              className="text-sm"
            />
          </div>

          <div className="space-y-2">
            <div className="font-medium text-sm">Concern Type</div>
            <Select
              options={options}
              placeholder="Select Concern Type"
              onChange={handleSelectConcern}
              styles={selectStyles}
              className="text-sm"
            />
          </div>

          <div className="space-y-2">
            <div className="font-medium text-sm">Describe your concern</div>
            <textarea
              className="w-full rounded-lg border border-gray-200 focus:outline-none focus:border-[#8FCD00] focus:ring-1 focus:ring-[#8FCD00] p-3 text-sm"
              type="text"
              rows={4}
              placeholder="Please provide details about your concern..."
              name="description"
              onChange={handleChange}
            />
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div className="space-y-4">
              <div className="space-y-2">
                <div className="font-medium text-sm">Upload Photo</div>
                <div className="flex items-center justify-center w-full">
                  <label className="flex flex-col items-center justify-center w-full h-32 border-2 border-gray-200 border-dashed rounded-lg cursor-pointer bg-gray-50 hover:bg-gray-100 transition-all">
                    <div className="flex flex-col items-center justify-center pt-5 pb-6">
                      <svg
                        className="w-8 h-8 mb-2 text-[#363062]"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z"
                        ></path>
                      </svg>
                      <p className="text-xs text-gray-500">
                        Click to upload image
                      </p>
                    </div>
                    <input
                      type="file"
                      className="hidden"
                      accept="image/*"
                      onChange={handleUpload}
                    />
                  </label>
                </div>
              </div>

              <div className="space-y-2">
                <div className="font-medium text-sm">Upload Video</div>
                <div className="flex items-center justify-center w-full">
                  <label className="flex flex-col items-center justify-center w-full h-32 border-2 border-gray-200 border-dashed rounded-lg cursor-pointer bg-gray-50 hover:bg-gray-100 transition-all">
                    <div className="flex flex-col items-center justify-center pt-5 pb-6">
                      <svg
                        className="w-8 h-8 mb-2 text-[#363062]"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M15 10l4.553-2.276A1 1 0 0121 8.618v6.764a1 1 0 01-1.447.894L15 14M5 18h8a2 2 0 002-2V8a2 2 0 00-2-2H5a2 2 0 00-2 2v8a2 2 0 002 2z"
                        ></path>
                      </svg>
                      <p className="text-xs text-gray-500">
                        Click to upload video
                      </p>
                    </div>
                    <input
                      type="file"
                      className="hidden"
                      accept="video/*"
                      onChange={handleUploadVideo}
                    />
                  </label>
                </div>
              </div>
            </div>

            <div>
              {formData.image && (
                <div className="space-y-2">
                  <div className="font-medium text-sm">Image Preview</div>
                  <div className="relative h-64 w-full overflow-hidden rounded-lg border border-gray-200">
                    <img
                      src={formData.image}
                      className="h-full w-full object-contain"
                      alt="Uploaded image"
                    />
                  </div>
                </div>
              )}

              {isUploading && (
                <div className="mt-4">
                  <div className="text-xs font-medium mb-1">
                    Uploading... {parseFloat(progress).toLocaleString()}%
                  </div>
                  <div className="w-full bg-gray-200 rounded-full h-2.5">
                    <div
                      className="bg-[#8FCD00] h-2.5 rounded-full transition-all"
                      style={{ width: `${progress}%` }}
                    ></div>
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className="pt-4">
            <button
              className="w-full bg-[#363062] hover:bg-opacity-90 text-white font-medium py-3 px-4 rounded-lg transition-all flex items-center justify-center disabled:opacity-70"
              onClick={handleSubmit}
              disabled={isProcessing}
            >
              {isProcessing ? (
                <>
                  <svg
                    className="animate-spin -ml-1 mr-2 h-4 w-4 text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                  Submitting...
                </>
              ) : (
                "Submit Concern"
              )}
            </button>
          </div>
        </div>

        {/* <div className="mt-8 border-t border-gray-200 pt-6 text-center">
          <div className="font-medium text-sm mb-2">
            For Emergency Assistance
          </div>
          <div className="text-sm">Security Office</div>
          <a
            href="tel:+639123456789"
            className="bg-[#8FCD00] text-[#363062] font-bold p-3 rounded-lg inline-block mt-2 hover:bg-opacity-90 transition-all"
          >
            +639123456789
          </a>
        </div> */}
      </div>
    </UserScreenWindow>
  );
}
