import React from "react";
import { useCompany } from "../context/CompanyContext";
import { replaceCompanyTerms } from "../utils/companyUtils";

/**
 * A component that automatically replaces terminology in its children based on company type
 */
const CompanyTermsWrapper = ({ children, placeholder = null }) => {
  const { companyType } = useCompany();

  if (!children) return placeholder || null;

  // Handle string children directly
  if (typeof children === "string") {
    return replaceCompanyTerms(children, companyType);
  }

  // Handle React elements by cloning them and replacing text in their children
  if (React.isValidElement(children)) {
    // If the child is a text node, just return the replaced text
    if (typeof children.props.children === "string") {
      return React.cloneElement(
        children,
        children.props,
        replaceCompanyTerms(children.props.children, companyType)
      );
    }

    // Otherwise, recursively process its children
    return React.cloneElement(
      children,
      children.props,
      React.Children.map(children.props.children, (child) => (
        <CompanyTermsWrapper>{child}</CompanyTermsWrapper>
      ))
    );
  }

  // For arrays of children, process each one
  if (Array.isArray(children)) {
    return children.map((child, index) => (
      <CompanyTermsWrapper key={index}>{child}</CompanyTermsWrapper>
    ));
  }

  // For anything else, just return it unchanged
  return children;
};

export default CompanyTermsWrapper;
