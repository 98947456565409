import React from "react";
import BlankPhoto from "../../assets/blankphoto.webp";
import { MdAddAPhoto } from "react-icons/md";

export default function ProfilePicture({ data }) {
  return (
    <div className="relative group">
      <div className="relative">
        <div className="w-20 h-20 rounded-full overflow-hidden ring-2 ring-gray-100 shadow-sm">
          <img
            src={data.photo || BlankPhoto}
            className="w-full h-full object-cover"
            alt=""
          />
        </div>
        <div className="absolute inset-0 rounded-full bg-black/30 opacity-0 group-hover:opacity-100 transition-all duration-200 flex items-center justify-center">
          <div className="bg-white/10 backdrop-blur-sm rounded-full p-2">
            <MdAddAPhoto className="w-5 h-5 text-white" />
          </div>
        </div>
      </div>
    </div>
  );
}
