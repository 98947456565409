import React from "react";
import UserScreenWindow from "./UserScreenWindow";
import { useNavigate, useParams } from "react-router-dom";
import {
  getDocDataSnap,
  setDocData,
} from "../../utils/firebase/Firebase.utils";
import { useSelector } from "react-redux";
import {
  FiFileText,
  FiHome,
  FiClock,
  FiUsers,
  FiTruck,
  FiFile,
  FiCheckCircle,
  FiMessageSquare,
  FiEdit2,
  FiSend,
  FiXCircle,
  FiUser,
  FiChevronDown,
  FiChevronUp,
} from "react-icons/fi";

function RequestDetails() {
  const { id } = useParams();
  const [request, setRequest] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(true);
  const [files, setFiles] = React.useState([]);
  const [comments, setComments] = React.useState([]);
  const [newComment, setNewComment] = React.useState("");
  const [displayCount, setDisplayCount] = React.useState(5);
  const [historyDisplayCount, setHistoryDisplayCount] = React.useState(5);

  const currentUser = useSelector((state) => state.userObject);

  React.useEffect(() => {
    const fetchRequest = async () => {
      const data = await getDocDataSnap("workPermits", id, (data) => {
        setComments(data.comments || []);
        setRequest(data);
        setIsLoading(false);
      });
    };
    fetchRequest();
  }, []);

  const navigate = useNavigate();

  const handleChange = (e) => {
    setNewComment(e.target.value);
  };

  const handleNewComment = async () => {
    const newComments = [
      ...comments,
      {
        name: currentUser.displayName,
        createdAt: new Date().toLocaleString(),
        message: newComment,
      },
    ];
    setComments(newComments);
    setNewComment("");
    await setDocData("workPermits", id, {
      comments: newComments,
    });
  };

  const handleEditRequest = () => {
    navigate(`/work-permits?continue=${id}`);
  };

  const handleShowMore = () => {
    setDisplayCount((prev) => prev + 5);
  };

  const handleShowLess = () => {
    setDisplayCount(5);
  };

  const handleShowMoreHistory = () => {
    setHistoryDisplayCount((prev) => prev + 5);
  };

  const handleShowLessHistory = () => {
    setHistoryDisplayCount(5);
  };

  const getStatusColor = (status) => {
    switch (status?.toLowerCase()) {
      case "approved":
        return "bg-eleghoGreen/10 text-eleghoGreen";
      case "pending":
        return "bg-yellow-100 text-yellow-600";
      case "returned":
        return "bg-red-100 text-red-600";
      default:
        return "bg-gray-100 text-gray-600";
    }
  };

  const getStatusIcon = (status) => {
    switch (status?.toLowerCase()) {
      case "approved":
        return <FiCheckCircle className="w-5 h-5" />;
      case "pending":
        return <FiClock className="w-5 h-5" />;
      case "returned":
        return <FiXCircle className="w-5 h-5" />;
      default:
        return null;
    }
  };

  if (isLoading) {
    return (
      <UserScreenWindow label="Request Details">
        <div className="flex items-center justify-center h-64">
          <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-eleghoBlue"></div>
        </div>
      </UserScreenWindow>
    );
  }

  if (!request) {
    return (
      <UserScreenWindow label="Request Details">
        <div className="flex flex-col items-center justify-center h-64">
          <FiXCircle className="text-4xl text-red-500 mb-4" />
          <p className="text-gray-600">Request not found</p>
        </div>
      </UserScreenWindow>
    );
  }

  const filesArray =
    request.files !== undefined
      ? Object.keys(request.files).map((key) => {
          return { name: key, url: request.files[key] };
        })
      : [];

  const displayedComments = comments.slice(0, displayCount);
  const hasMore = displayCount < comments.length;

  const displayedHistory = (request.history || []).slice(
    0,
    historyDisplayCount
  );
  const hasMoreHistory = historyDisplayCount < (request.history || []).length;

  return (
    <UserScreenWindow label="Request Details">
      <div className="max-w-2xl mx-auto space-y-6">
        {/* Header Section */}
        <div className="bg-white rounded-2xl shadow-sm border border-gray-100 p-5">
          <div className="flex items-center justify-between mb-4">
            <div>
              <h1 className="header">Work Permit Request</h1>
              <div className="text-xs text-gray-400">{id}</div>
              <p className="text-xs text-gray-500 mt-0.5">Request Details</p>
            </div>
            <div
              className={`px-3 py-1.5 rounded-full flex items-center space-x-1.5 ${getStatusColor(
                request.status
              )}`}
            >
              {getStatusIcon(request.status)}
              <span className="font-medium capitalize text-xs">
                {request.status}
              </span>
            </div>
          </div>

          <div className="grid grid-cols-2 gap-3">
            <div className="flex items-center space-x-2 p-2.5 bg-gray-50 rounded-lg">
              <FiHome className="w-4 h-4 text-eleghoBlue" />
              <div>
                <p className="text-xs text-gray-500">Work Permit Type</p>
                <p className="text-sm font-medium text-gray-900 capitalize">
                  {request.workPermitType}
                </p>
              </div>
            </div>
            <div className="flex items-center space-x-2 p-2.5 bg-gray-50 rounded-lg">
              <FiClock className="w-4 h-4 text-eleghoBlue" />
              <div>
                <p className="text-xs text-gray-500">Duration</p>
                <p className="text-sm font-medium text-gray-900 capitalize">
                  {request.duration}
                </p>
              </div>
            </div>
          </div>
        </div>

        {/* Details Section */}
        <div className="bg-white rounded-2xl shadow-sm border border-gray-100 p-5">
          <h2 className="text-base font-semibold text-gray-900 mb-3">
            Request Information
          </h2>
          <div className="space-y-3">
            <div className="flex items-center space-x-2 p-2.5 bg-gray-50 rounded-lg">
              <FiHome className="w-4 h-4 text-eleghoBlue" />
              <div>
                <p className="text-xs text-gray-500">Unit Number</p>
                <p className="text-sm font-medium text-gray-900 capitalize">
                  {request.identifier}
                </p>
              </div>
            </div>

            <div className="flex items-center space-x-2 p-2.5 bg-gray-50 rounded-lg">
              <FiUsers className="w-4 h-4 text-eleghoBlue" />
              <div>
                <p className="text-xs text-gray-500">Workers</p>
                <p className="text-sm font-medium text-gray-900">
                  {request.workers}
                </p>
              </div>
            </div>

            <div className="flex items-center space-x-2 p-2.5 bg-gray-50 rounded-lg">
              <FiTruck className="w-4 h-4 text-eleghoBlue" />
              <div>
                <p className="text-xs text-gray-500">Vehicle Information</p>
                <p className="text-sm font-medium text-gray-900">
                  {request.vehicle}
                </p>
              </div>
            </div>
          </div>
        </div>

        {/* Work Description */}
        <div className="bg-white rounded-2xl shadow-sm border border-gray-100 p-5">
          <h2 className="text-base font-semibold text-gray-900 mb-3">
            Work Description
          </h2>
          <div className="bg-gray-50 rounded-lg p-4">
            <p className="text-sm text-gray-600 leading-relaxed">
              {request.description}
            </p>
          </div>
        </div>

        {/* History Timeline */}
        <div className="bg-white rounded-2xl shadow-sm border border-gray-100 p-5">
          <div className="flex items-center justify-between mb-3">
            <h2 className="text-base font-semibold text-gray-900">
              Status History
            </h2>
            <span className="text-xs text-gray-500">
              {(request.history || []).length} updates
            </span>
          </div>

          <div className="space-y-3">
            {displayedHistory.map((entry, index) => (
              <div key={index} className="flex items-start space-x-3">
                <div className="flex-shrink-0">
                  <div className="w-7 h-7 rounded-full bg-gray-100 flex items-center justify-center">
                    <FiUser className="w-3.5 h-3.5 text-gray-600" />
                  </div>
                </div>
                <div className="flex-1">
                  <div className="flex items-center justify-between mb-0.5">
                    <p className="text-xs font-medium text-gray-900">
                      {entry.action}{" "}
                      {entry.to && (
                        <span className="capitalize">{entry.to}</span>
                      )}
                    </p>
                    <span className="text-[10px] text-gray-500">
                      {entry.at}
                    </span>
                  </div>
                  <p className="text-[10px] text-gray-500">by {entry.by}</p>
                </div>
              </div>
            ))}
          </div>

          {hasMoreHistory && (
            <button
              onClick={handleShowMoreHistory}
              className="mt-3 w-full flex items-center justify-center space-x-1.5 text-eleghoBlue hover:text-eleghoBlue/80 transition-colors py-1.5"
            >
              <FiChevronDown className="w-3.5 h-3.5" />
              <span className="text-xs">Show More</span>
            </button>
          )}

          {historyDisplayCount > 5 && (
            <button
              onClick={handleShowLessHistory}
              className="mt-2 w-full flex items-center justify-center space-x-1.5 text-eleghoBlue hover:text-eleghoBlue/80 transition-colors py-1.5"
            >
              <FiChevronUp className="w-3.5 h-3.5" />
              <span className="text-xs">Show Less</span>
            </button>
          )}
        </div>

        {/* Files Section */}
        <div className="bg-white rounded-2xl shadow-sm border border-gray-100 p-5">
          <h2 className="text-base font-semibold text-gray-900 mb-3">
            Attached Files
          </h2>
          <div className="space-y-3">
            {filesArray.map((file, index) => (
              <div
                key={index}
                className="flex items-center justify-between p-3 bg-gray-50 rounded-lg hover:bg-gray-100 transition-colors"
              >
                <div className="flex items-center gap-3">
                  <div className="p-2 bg-white rounded-lg">
                    <FiFile className="w-4 h-4 text-eleghoBlue" />
                  </div>
                  <span className="text-sm font-medium text-gray-700">
                    {file.name}
                  </span>
                </div>
                <a
                  href={file.url}
                  target="_blank"
                  className="flex items-center gap-2 px-3 py-1.5 bg-eleghoGreen text-eleghoBlue rounded-lg hover:bg-eleghoGreen/90 transition-all duration-200 text-xs"
                >
                  View File
                </a>
              </div>
            ))}
            {filesArray.length === 0 && (
              <div className="text-center py-6">
                <div className="text-gray-400 mb-2">
                  <FiFile className="w-8 h-8 mx-auto" />
                </div>
                <div className="text-sm text-gray-500">No files attached</div>
              </div>
            )}
          </div>
        </div>

        {/* Comments Section */}
        <div className="bg-white rounded-2xl shadow-sm border border-gray-100 p-5">
          <div className="flex items-center justify-between mb-3">
            <h2 className="text-base font-semibold text-gray-900">Comments</h2>
            <span className="text-xs text-gray-500">
              {comments.length} comments
            </span>
          </div>

          <div className="space-y-3">
            {displayedComments.map((comment, index) => (
              <div
                key={index}
                className="flex items-start space-x-3 bg-gray-50 rounded-lg p-3"
              >
                <div className="flex-shrink-0">
                  <div className="w-7 h-7 rounded-full bg-white flex items-center justify-center">
                    <FiUser className="w-3.5 h-3.5 text-gray-600" />
                  </div>
                </div>
                <div className="flex-1">
                  <div className="flex items-center justify-between mb-0.5">
                    <p className="text-xs font-medium text-gray-900">
                      {comment.name}
                    </p>
                    <span className="text-[10px] text-gray-500">
                      {comment.createdAt}
                    </span>
                  </div>
                  <p className="text-sm text-gray-600">{comment.message}</p>
                </div>
              </div>
            ))}
          </div>

          {hasMore && (
            <button
              onClick={handleShowMore}
              className="mt-3 w-full flex items-center justify-center space-x-1.5 text-eleghoBlue hover:text-eleghoBlue/80 transition-colors py-1.5"
            >
              <FiChevronDown className="w-3.5 h-3.5" />
              <span className="text-xs">Show More</span>
            </button>
          )}

          {displayCount > 5 && (
            <button
              onClick={handleShowLess}
              className="mt-2 w-full flex items-center justify-center space-x-1.5 text-eleghoBlue hover:text-eleghoBlue/80 transition-colors py-1.5"
            >
              <FiChevronUp className="w-3.5 h-3.5" />
              <span className="text-xs">Show Less</span>
            </button>
          )}

          {request.status !== "Approved" && (
            <div className="mt-4 relative">
              <textarea
                className="w-full h-24 p-3 rounded-lg border border-gray-200 focus:ring-2 focus:ring-eleghoBlue/20 focus:border-eleghoBlue resize-none text-sm"
                placeholder="Add a comment..."
                onChange={handleChange}
                value={newComment}
              />
              <button
                onClick={handleNewComment}
                className="absolute bottom-3 right-3 p-2 bg-eleghoGreen text-eleghoBlue rounded-lg hover:bg-eleghoGreen/90 transition-all duration-200"
              >
                <FiSend className="w-4 h-4" />
              </button>
            </div>
          )}
        </div>
      </div>
    </UserScreenWindow>
  );
}

export default RequestDetails;
