import React, { useEffect } from "react";
import BlueNavHeader from "./BlueNavHeader";
import UserScreenWindow from "./UserScreenWindow";
import {
  getDocDataSnap,
  setDocData,
} from "../../utils/firebase/Firebase.utils";
import { useParams } from "react-router-dom";
import { QRCodeSVG } from "qrcode.react";
import { useSelector } from "react-redux";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import {
  FaCalendarAlt,
  FaClock,
  FaHome,
  FaUsers,
  FaQrcode,
  FaCheckCircle,
  FaHourglass,
  FaIdCard,
} from "react-icons/fa";
import AnimatedModal from "../Dashboard/Components/AnimatedModal";

function GuestCardDetails() {
  const [details, setDetails] = React.useState({});
  const [guests, setGuests] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(true);
  const [inProcess, setInProcess] = React.useState(false);
  const [selectedIdentification, setSelectedIdentification] =
    React.useState(null);
  const [isImageLoading, setIsImageLoading] = React.useState(true);

  const { id } = useParams();
  const navigate = useNavigate();
  const currentUser = useSelector((state) => state.userObject);

  const isApprover =
    currentUser.roles.includes("admin") ||
    currentUser.roles.includes("security");

  useEffect(() => {
    const fetchDetails = async () => {
      const data = await getDocDataSnap("guests", id, (data) => {
        setDetails(data);
        setGuests(data.guests);
        setIsLoading(false);
      });
    };
    fetchDetails();
  }, []);

  const handleChange = (e) => {};

  const timeIn = new Date(details.timeIn);

  if (isLoading) {
    return (
      <div className="flex items-center justify-center min-h-screen bg-gradient-to-br from-blue-50 to-gray-50">
        <div className="flex items-center space-x-2 bg-white p-4 rounded-lg shadow-lg animate-pulse">
          <FaHourglass className="text-blue-900 animate-spin h-5 w-5" />
          <span className="text-base sm:text-lg text-blue-900 font-semibold">
            Loading guest details...
          </span>
        </div>
      </div>
    );
  }

  const handleAdmit = async () => {
    setInProcess(true);
    const data = {
      ...details,
      status: "admitted",
      timeIn: new Date().toISOString(),
    };
    await setDocData("guests", id, data);
    toast.success("Guest Admitted");
    setInProcess(false);
  };

  const handleLogOut = async () => {
    setInProcess(true);
    const data = {
      ...details,
      status: "completed",
      timeOut: new Date().toLocaleString(),
    };
    await setDocData("guests", id, data);
    toast.success("Guest Logged Out");
    setInProcess(false);
  };

  const handleEditDetails = () => {
    if (details.timeIn) {
      toast.error("Guest already admitted");
      return;
    }
    navigate(`/edit-guest-card/${id}`);
  };

  const getStatusBadge = () => {
    const statusConfig = {
      pending: {
        bg: "bg-yellow-100",
        text: "text-yellow-800",
        border: "border-yellow-200",
        icon: <FaHourglass className="w-3 h-3 sm:w-4 sm:h-4 mr-1.5" />,
      },
      admitted: {
        bg: "bg-green-100",
        text: "text-green-800",
        border: "border-green-200",
        icon: <FaCheckCircle className="w-3 h-3 sm:w-4 sm:h-4 mr-1.5" />,
      },
      completed: {
        bg: "bg-gray-100",
        text: "text-gray-800",
        border: "border-gray-200",
        icon: <FaCheckCircle className="w-3 h-3 sm:w-4 sm:h-4 mr-1.5" />,
      },
    };

    const config = statusConfig[details.status] || statusConfig.pending;

    return (
      <span
        className={`inline-flex items-center px-3 py-1.5 text-xs sm:text-sm font-medium ${config.bg} ${config.text} border ${config.border} shadow-sm transition-all duration-200 hover:shadow rounded-full`}
      >
        {config.icon}
        {details.status?.charAt(0).toUpperCase() + details.status?.slice(1)}
      </span>
    );
  };

  return (
    <UserScreenWindow label="Guest Card Details">
      <div className="min-h-screen bg-gradient-to-br from-blue-50 to-gray-50">
        <div className="max-w-3xl mx-auto p-2 sm:p-4 space-y-3 sm:space-y-6">
          {/* Status Badge */}
          <div className="flex justify-end px-2">{getStatusBadge()}</div>

          {/* Date and Time Card */}
          <div className="bg-white rounded-lg sm:rounded-xl shadow-sm p-3 sm:p-6 transition-all duration-200 hover:shadow-md">
            <div className="flex items-center mb-3">
              <FaCalendarAlt className="w-4 h-4 sm:w-5 sm:h-5 text-blue-900 mr-2" />
              <h2 className="text-base sm:text-lg font-semibold text-gray-800">
                Date and Time
              </h2>
            </div>
            <div className="grid grid-cols-2 gap-2 sm:gap-4">
              <div>
                <label className="block text-xs sm:text-sm font-medium text-gray-600 mb-1">
                  Date
                </label>
                <div className="relative">
                  <input
                    type="date"
                    value={details.date}
                    onChange={handleChange}
                    className="w-full text-sm pl-8 sm:pl-10 pr-2 sm:pr-4 py-1.5 sm:py-2 border border-gray-200 rounded-md sm:rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-all duration-200"
                    disabled={details.timeIn}
                  />
                  <FaCalendarAlt className="absolute left-2 sm:left-3 top-1/2 transform -translate-y-1/2 text-gray-400 w-4 h-4" />
                </div>
              </div>
              <div>
                <label className="block text-xs sm:text-sm font-medium text-gray-600 mb-1">
                  Time
                </label>
                <div className="relative">
                  <input
                    type="time"
                    value={details.time}
                    onChange={handleChange}
                    className="w-full text-sm pl-8 sm:pl-10 pr-2 sm:pr-4 py-1.5 sm:py-2 border border-gray-200 rounded-md sm:rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-all duration-200"
                    disabled={details.timeIn}
                  />
                  <FaClock className="absolute left-2 sm:left-3 top-1/2 transform -translate-y-1/2 text-gray-400 w-4 h-4" />
                </div>
              </div>
            </div>
          </div>

          {/* House to Visit Card */}
          {details.linkedIdentifiers && (
            <div className="bg-white rounded-lg sm:rounded-xl shadow-sm p-3 sm:p-6 transition-all duration-200 hover:shadow-md">
              <div className="flex items-center mb-3">
                <FaHome className="w-4 h-4 sm:w-5 sm:h-5 text-blue-900 mr-2" />
                <h2 className="text-base sm:text-lg font-semibold text-gray-800">
                  Unit to be visited
                </h2>
              </div>
              <div className="space-y-1.5 sm:space-y-2">
                {details.linkedIdentifiers.map((identifier, index) => (
                  <div
                    key={index}
                    className="px-3 sm:px-4 py-2 sm:py-3 bg-blue-50 rounded-md sm:rounded-lg text-sm sm:text-base text-gray-700 transition-all duration-200 hover:bg-blue-100"
                  >
                    {identifier}
                  </div>
                ))}
              </div>
            </div>
          )}

          {/* Guest Names Card */}
          <div className="bg-white rounded-lg sm:rounded-xl shadow-sm p-3 sm:p-6 transition-all duration-200 hover:shadow-md">
            <div className="flex items-center mb-3">
              <FaUsers className="w-4 h-4 sm:w-5 sm:h-5 text-blue-900 mr-2" />
              <h2 className="text-base sm:text-lg font-semibold text-gray-800">
                Guest Names
              </h2>
            </div>
            <div className="space-y-2 sm:space-y-3">
              {details.guests.map((guest, index) => (
                <div
                  key={index}
                  className="flex flex-col items-center justify-between px-3 sm:px-4 py-2 sm:py-3 bg-gray-50 rounded-md sm:rounded-lg transition-all duration-200 hover:bg-gray-100"
                >
                  <div>
                    <div className="text-sm sm:text-base font-medium text-gray-700">
                      {guest.name}
                    </div>
                    <div className="text-xs sm:text-sm text-gray-500 bg-white px-2 sm:px-3 py-1 rounded-full shadow-sm">
                      {guest.plateNumber}
                    </div>
                  </div>

                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      setSelectedIdentification(guest?.identification);
                    }}
                    className={`mt-2 inline-flex items-center px-3 py-1.5 text-xs sm:text-sm font-medium rounded-full transition-colors duration-200 ${
                      guest?.identification
                        ? "text-blue-700 bg-blue-100 hover:bg-blue-200"
                        : "text-gray-600 bg-gray-100 hover:bg-gray-200 cursor-not-allowed"
                    }`}
                    disabled={!guest?.identification}
                  >
                    <FaIdCard className="w-3 h-3 sm:w-4 sm:h-4 mr-1.5" />
                    {guest?.identification ? "View ID" : "No ID Uploaded"}
                  </button>
                </div>
              ))}
            </div>
          </div>

          {/* QR Code Card */}
          <div className="bg-white rounded-lg sm:rounded-xl shadow-sm p-3 sm:p-6 transition-all duration-200 hover:shadow-md">
            <div className="flex flex-col items-center">
              <div className="flex items-center mb-3">
                <FaQrcode className="w-4 h-4 sm:w-5 sm:h-5 text-blue-900 mr-2" />
                <h2 className="text-base sm:text-lg font-semibold text-gray-800">
                  Guest Pass QR Code
                </h2>
              </div>
              <div className="p-4 sm:p-6 bg-gradient-to-br from-blue-50 to-gray-50 rounded-lg shadow-inner">
                <div className="bg-white p-2 sm:p-3 rounded-lg shadow-sm">
                  <QRCodeSVG
                    value={`/guest-card-details/${id}`}
                    size={150}
                    level="H"
                    className="transition-all duration-200 hover:scale-105"
                  />
                </div>
              </div>
            </div>
          </div>

          {/* Time In Information */}
          {details.timeIn && (
            <div className="bg-white rounded-lg sm:rounded-xl shadow-sm p-3 sm:p-6 transition-all duration-200 hover:shadow-md">
              <div className="flex items-center mb-2">
                <FaClock className="w-4 h-4 sm:w-5 sm:h-5 text-blue-900 mr-2" />
                <h2 className="text-base sm:text-lg font-semibold text-gray-800">
                  Time In
                </h2>
              </div>
              <div className="text-sm sm:text-base text-gray-600 bg-gray-50 px-3 sm:px-4 py-2 rounded-md sm:rounded-lg">
                {timeIn.toLocaleString()}
              </div>
            </div>
          )}

          {/* Action Buttons */}
          <div className="space-y-2 sm:space-y-4 pt-2 sm:pt-4 px-2">
            {isApprover === false && (
              <button
                className="w-full px-4 sm:px-6 py-2.5 sm:py-3 text-sm sm:text-base text-white font-medium rounded-lg sm:rounded-xl transition-all duration-200
                          disabled:bg-gray-300 disabled:cursor-not-allowed
                          bg-blue-900 hover:bg-blue-800 focus:ring-4 focus:ring-blue-200
                          transform hover:-translate-y-0.5 active:translate-y-0"
                onClick={handleEditDetails}
                disabled={details.timeIn}
              >
                {details.timeIn
                  ? "Admitted"
                  : inProcess
                  ? "Processing..."
                  : "Edit Details"}
              </button>
            )}

            {isApprover === true && (
              <div className="space-y-2 sm:space-y-3">
                <button
                  className="w-full px-4 sm:px-6 py-2.5 sm:py-3 text-sm sm:text-base text-white font-medium rounded-lg sm:rounded-xl transition-all duration-200
                            disabled:bg-gray-300 disabled:cursor-not-allowed
                            bg-green-600 hover:bg-green-700 focus:ring-4 focus:ring-green-200
                            transform hover:-translate-y-0.5 active:translate-y-0
                            flex items-center justify-center"
                  onClick={handleAdmit}
                  disabled={
                    details.timeIn ||
                    details.status === "rejected" ||
                    details.status === "pending"
                  }
                >
                  <FaCheckCircle className="w-4 h-4 sm:w-5 sm:h-5 mr-2" />
                  {details.timeIn
                    ? "Admitted"
                    : inProcess
                    ? "Processing..."
                    : "Admit Guest"}
                </button>

                <button
                  className="w-full px-4 sm:px-6 py-2.5 sm:py-3 text-sm sm:text-base text-white font-medium rounded-lg sm:rounded-xl transition-all duration-200
                            disabled:bg-gray-300 disabled:cursor-not-allowed
                            bg-red-600 hover:bg-red-700 focus:ring-4 focus:ring-red-200
                            transform hover:-translate-y-0.5 active:translate-y-0"
                  onClick={handleLogOut}
                  disabled={
                    details.status === "completed" ||
                    details.status !== "admitted"
                  }
                >
                  Log-Out Guest
                </button>
              </div>
            )}
          </div>
        </div>
      </div>

      {/* Identification Modal */}
      <AnimatedModal
        isOpen={!!selectedIdentification}
        onClose={() => {
          setSelectedIdentification(null);
          setIsImageLoading(true);
        }}
      >
        <div className="p-4 sm:p-6 max-w-2xl mx-auto">
          <div className="flex justify-between items-center mb-4">
            <h3 className="text-lg sm:text-xl font-semibold text-gray-900">
              Guest Identification
            </h3>
            <button
              onClick={() => {
                setSelectedIdentification(null);
                setIsImageLoading(true);
              }}
              className="text-gray-500 hover:text-gray-700 transition-colors"
            >
              <svg
                className="w-6 h-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>
          {selectedIdentification ? (
            <div className="bg-gray-100 rounded-lg overflow-hidden relative min-h-[200px]">
              {isImageLoading && (
                <div className="absolute inset-0 flex items-center justify-center bg-gray-50">
                  <div className="flex items-center space-x-2 bg-white p-4 rounded-lg shadow-lg">
                    <FaHourglass className="text-blue-900 animate-spin h-5 w-5" />
                    <span className="text-sm text-blue-900 font-medium">
                      Loading identification...
                    </span>
                  </div>
                </div>
              )}
              <img
                src={selectedIdentification}
                alt="Guest Identification"
                className={`w-full h-auto object-contain transition-opacity duration-300 ${
                  isImageLoading ? "opacity-0" : "opacity-100"
                }`}
                onLoad={() => setIsImageLoading(false)}
              />
            </div>
          ) : (
            <div className="flex flex-col items-center justify-center py-12 px-4 bg-gray-50 rounded-lg">
              <FaIdCard className="w-12 h-12 text-gray-400 mb-3" />
              <p className="text-gray-600 text-center">
                No identification has been uploaded for this guest.
              </p>
            </div>
          )}
        </div>
      </AnimatedModal>
    </UserScreenWindow>
  );
}

export default GuestCardDetails;
