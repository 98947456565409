import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { FaSearch, FaArrowLeft } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { getAllDocsSnap } from "../../../utils/firebase/Firebase.utils";

export default function GatepassMobile() {
  const navigate = useNavigate();
  const currentUser = useSelector((state) => state.userObject);
  const [gatePasses, setGatePasses] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [statusFilter, setStatusFilter] = useState("all");
  const [isLoading, setIsLoading] = useState(false);

  

  useEffect(() => {
    const fetchGatepass = async () => {
      await getAllDocsSnap("gatePass", (docs) => {
        setGatePasses(docs);
        console.log(docs[0]);
        setIsLoading(false);
      });
    };
    fetchGatepass();
  }, []);

  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-screen text-[#363062]">
        Loading...
      </div>
    );
  }

  const getStatusColor = (status) => {
    switch (status.toLowerCase()) {
      case "approved":
        return "bg-[#8FCD00] text-white";
      case "rejected":
        return "bg-red-400 text-white";
      case "returned":
        return "bg-blue-400 text-white";
      case "pending":
      default:
        return "bg-yellow-100 text-[#363062]";
    }
  };

  const filteredGatePasses = gatePasses.filter((gatePass) => {
    const searchLower = searchTerm.toLowerCase();
    const matchesSearch =
      gatePass.identifier?.toLowerCase().includes(searchLower) ||
      gatePass.deliveryCompany?.toLowerCase().includes(searchLower) ||
      gatePass.items?.toLowerCase().includes(searchLower) ||
      gatePass.requestedBy?.toLowerCase().includes(searchLower);

    const matchesStatus =
      statusFilter === "all" || gatePass.status?.toLowerCase() === statusFilter;

    return matchesSearch && matchesStatus;
  });

  const statusOptions = [
    { value: "all", label: "All" },
    { value: "pending", label: "Pending" },
    { value: "approved", label: "Approved" },
    { value: "rejected", label: "Rejected" },
    { value: "returned", label: "Returned" },
  ];

  return (
    <div className="p-4 bg-gray-50">
      <div className="flex items-center mb-4">
        <button
          onClick={() => navigate(-1)}
          className="mr-2 p-2 rounded-full hover:bg-gray-200 transition-colors"
          aria-label="Go back"
        >
          <FaArrowLeft className="text-[#363062]" />
        </button>
        <h1 className="text-lg font-bold text-[#363062]">Gate Pass Requests</h1>
      </div>

      <div className="mb-4 relative">
        <input
          type="text"
          placeholder="Search by unit, company, items or requester..."
          className="w-full p-2 pr-8 border border-gray-200 rounded-lg focus:outline-none focus:ring-1 focus:ring-[#8FCD00] text-sm"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <div className="absolute right-3 top-2 text-gray-400">
          {searchTerm ? (
            <button onClick={() => setSearchTerm("")}>✕</button>
          ) : (
            <FaSearch className="text-gray-400" />
          )}
        </div>
      </div>

      <div className="flex gap-2 mb-4 overflow-x-auto pb-2">
        {statusOptions.map((option) => (
          <button
            key={option.value}
            onClick={() => setStatusFilter(option.value)}
            className={`px-3 py-1.5 rounded-full text-xs whitespace-nowrap ${
              statusFilter === option.value
                ? "bg-[#363062] text-white"
                : "bg-white text-[#363062] border border-gray-200"
            }`}
          >
            {option.label}
          </button>
        ))}
      </div>

      {filteredGatePasses.length === 0 ? (
        <div className="text-center py-8 text-[#363062]">
          {searchTerm || statusFilter !== "all"
            ? "No matches found"
            : "No gate pass requests found."}
        </div>
      ) : (
        <div className="grid grid-cols-1 gap-4">
          {filteredGatePasses.map((gatePass) => (
            <div
              key={gatePass.id}
              className="bg-white rounded-lg shadow-md overflow-hidden border border-gray-100"
            >
              <div className="p-4">
                <div className="flex justify-between items-start mb-2">
                  <h3 className="text-base font-bold text-[#363062]">
                    {gatePass.identifier}
                  </h3>
                  <span
                    className={`px-3 py-1 rounded-full text-xs font-medium ${getStatusColor(
                      gatePass.status
                    )}`}
                  >
                    {gatePass.status}
                  </span>
                </div>

                <div className="grid grid-cols-2 gap-2 mb-2">
                  <div>
                    <span className="text-xs text-gray-500">
                      Delivery Company:
                    </span>
                    <p className="font-medium text-[#363062]">
                      {gatePass.deliveryCompany}
                    </p>
                  </div>
                  <div>
                    <span className="text-xs text-gray-500">Requested By:</span>
                    <p className="font-medium text-[#363062]">
                      {gatePass.requestedBy}
                    </p>
                  </div>
                  <div>
                    <span className="text-xs text-gray-500">
                      Delivery Date:
                    </span>
                    <p className="font-medium text-[#363062]">
                      {gatePass.deliveryDate}
                    </p>
                  </div>
                  <div>
                    <span className="text-xs text-gray-500">
                      Delivery Time:
                    </span>
                    <p className="font-medium text-[#363062]">
                      {gatePass.deliveryTime}
                    </p>
                  </div>
                </div>

                <div className="mt-2">
                  <span className="text-xs text-gray-500">Items:</span>
                  <p className="text-sm mt-1 text-[#363062]">
                    {gatePass.items}
                  </p>
                </div>

                <div className="mt-3 pt-2 border-t border-gray-100 flex justify-between items-center">
                  <span className="text-xs text-gray-500">
                    Last updated: {gatePass.updatedAt}
                  </span>
                  <button
                    onClick={() => navigate(`/gatepass-mobile/${gatePass.id}`)}
                    className="text-xs text-[#8FCD00] font-medium hover:underline"
                  >
                    View Details
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}
