import React from "react";

function MarketplaceAdmin() {
  return (
    <div className="text-gray-600 text-xs text-left max-w-7xl mx-auto mt-8">
      <div className="page-header">Marketplace</div>
    </div>
  );
}

export default MarketplaceAdmin;
