import React from "react";
import InputBox from "../Dashboard/Components/InputBox";
import SelectInput from "../Dashboard/Components/SelectInput";
import { useSelector } from "react-redux";
import toast from "react-hot-toast";
import { addNewDoc } from "../../utils/firebase/Firebase.utils";
import { motion } from "framer-motion";
import {
  FiX,
  FiCheckCircle,
  FiCalendar,
  FiFlag,
  FiFileText,
  FiLoader,
} from "react-icons/fi";

export default function TodosForm({ setAddTodo }) {
  const currentUser = useSelector((state) => state.userObject);
  const [formData, setFormData] = React.useState({});
  const [isProcessing, setIsProcessing] = React.useState(false);

  const priorityOptions = [
    { value: "high", label: "High Priority", icon: "🔴" },
    { value: "medium", label: "Medium Priority", icon: "🟡" },
    { value: "low", label: "Low Priority", icon: "🟢" },
  ];

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSaveTodo = async (e) => {
    e.preventDefault();
    setIsProcessing(true);

    try {
      const docObject = {
        ...formData,
        createdBy: currentUser.email,
        user: currentUser.uid,
        status: "pending",
        createdAt: new Date().toISOString(),
      };
      await addNewDoc("todos", docObject);
      toast.success("Task added successfully");
      setAddTodo(false);
    } catch (error) {
      toast.error("Failed to add task");
      console.error("Error adding task:", error);
    } finally {
      setIsProcessing(false);
    }
  };

  return (
    <motion.div
      initial={{ opacity: 0, y: 10 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: 10 }}
      className="bg-white rounded-xl shadow-lg overflow-hidden max-w-md w-full mx-auto text-left"
    >
      {/* Header */}
      <div className="bg-gradient-to-r from-eleghoBlue to-eleghoBlue/80 px-6 py-4 flex items-center justify-between">
        <div className="text-white">
          <h2 className="text-lg font-semibold">Add New Task</h2>
          <p className="text-blue-100 text-sm">Create a new task to track</p>
        </div>
        <button
          onClick={() => setAddTodo(false)}
          className="p-2 rounded-full hover:bg-white/10 transition-colors"
        >
          <FiX className="w-5 h-5 text-white" />
        </button>
      </div>

      {/* Form Content */}
      <form onSubmit={handleSaveTodo} className="p-6 space-y-6">
        {/* Task Name */}
        <div className="space-y-2">
          <label className="text-sm font-medium text-gray-700 flex items-center gap-2">
            <FiCheckCircle className="w-4 h-4 text-eleghoBlue" />
            Task Name
          </label>
          <input
            name="name"
            type="text"
            placeholder="Enter task name"
            required
            onChange={handleChange}
            className="w-full px-4 py-2.5 text-sm border border-gray-200 rounded-lg focus:ring-2 focus:ring-eleghoBlue/20 focus:border-eleghoBlue transition-colors"
          />
        </div>

        {/* Description */}
        <div className="space-y-2">
          <label className="text-sm font-medium text-gray-700 flex items-center gap-2">
            <FiFileText className="w-4 h-4 text-eleghoBlue" />
            Description
          </label>
          <textarea
            name="description"
            placeholder="Enter task description"
            onChange={handleChange}
            rows="3"
            className="w-full px-4 py-2.5 text-sm border border-gray-200 rounded-lg focus:ring-2 focus:ring-eleghoBlue/20 focus:border-eleghoBlue transition-colors resize-none"
          />
        </div>

        <div className="grid grid-cols-2 gap-4">
          {/* Due Date */}
          <div className="space-y-2">
            <label className="text-sm font-medium text-gray-700 flex items-center gap-2">
              <FiCalendar className="w-4 h-4 text-eleghoBlue" />
              Due Date
            </label>
            <input
              name="dueDate"
              type="date"
              required
              onChange={handleChange}
              className="w-full px-4 py-2.5 text-sm border border-gray-200 rounded-lg focus:ring-2 focus:ring-eleghoBlue/20 focus:border-eleghoBlue transition-colors"
            />
          </div>

          {/* Priority */}
          <div className="space-y-2">
            <label className="text-sm font-medium text-gray-700 flex items-center gap-2">
              <FiFlag className="w-4 h-4 text-eleghoBlue" />
              Priority
            </label>
            <select
              name="priority"
              onChange={(e) =>
                setFormData({ ...formData, priority: e.target.value })
              }
              required
              className="w-full px-4 py-2.5 text-sm border border-gray-200 rounded-lg focus:ring-2 focus:ring-eleghoBlue/20 focus:border-eleghoBlue transition-colors appearance-none bg-white"
            >
              <option value="">Select Priority</option>
              {priorityOptions.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.icon} {option.label}
                </option>
              ))}
            </select>
          </div>
        </div>

        {/* Submit Button */}
        <div className="pt-4">
          <button
            type="submit"
            disabled={isProcessing}
            className="w-full px-6 py-3 text-sm font-medium text-white bg-eleghoBlue rounded-lg hover:bg-eleghoBlue/90 focus:outline-none focus:ring-2 focus:ring-eleghoBlue focus:ring-offset-2 transition-colors disabled:opacity-70 disabled:cursor-not-allowed flex items-center justify-center gap-2"
          >
            {isProcessing ? (
              <>
                <FiLoader className="w-4 h-4 animate-spin" />
                Creating Task...
              </>
            ) : (
              "Create Task"
            )}
          </button>
        </div>
      </form>
    </motion.div>
  );
}
