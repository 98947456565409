import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Login from "./pages/Login/Login";
import Signup from "./pages/Login/Signup";
import Dashboard from "./pages/Dashboard/Dashboard";
import { useSelector } from "react-redux";
import ResetPassword from "./pages/Login/ForgotPassword";
import AdminDashboard from "./pages/Dashboard/AdminDashboard/AdminDashboard";
import TenantManagement from "./pages/Dashboard/ResidentManagement/ResidentManagement";
import UnderConstruction from "./pages/Dashboard/UnderConstruction";
import PropertyManagement from "./pages/Dashboard/PropertyManagement";
import Facilities from "./pages/Dashboard/Facilities/Facilities";
import Services from "./pages/Dashboard/Services";

import Announcements from "./pages/Dashboard/Announcements/Announcements";
import Billing from "./pages/Dashboard/Billings/Billing";
import UserDashboard from "./pages/User/UserDashboard/UserDashboard";
import Profile from "./pages/User/Profile";
import Contacts from "./pages/Dashboard/Contacts/Contacts";
import Settings from "./pages/Dashboard/Settings";
import UserUnderConstruction from "./pages/User/UnderConstruction";
import MaintenanceRequest from "./pages/User/MaintenanceRequest";
import GuestRegistration from "./pages/User/GuestRegistration";
import AddGuests from "./pages/User/AddGuests";
import GuestCardDetails from "./pages/User/GuestCardDetails";
import Bills from "./pages/User/Bills";
import Paynow from "./pages/User/Paynow";
import RequestSummary from "./pages/User/RequestSummary";
import Tasks from "./pages/User/Tasks";
import WorkPermitUser from "./pages/User/WorkPermit/WorkPermitUser";
import DuesUser from "./pages/User/DuesUser";
import FacilitiesUser from "./pages/User/FacilitiesUser";
import SOS from "./pages/User/SOS";
import LaundryUser from "./pages/User/LaundryUser";
import WaterServiceUser from "./pages/User/WaterServiceUser";
import CleaningServicesUser from "./pages/User/CleaningServicesUser";
import OtherServicesUser from "./pages/User/OtherServicesUser";
import AdminUser from "./pages/User/AdminUser";
import NotificationsUser from "./pages/User/NotificationsUser";
import GuestHistory from "./pages/User/GuestHistory";
import HoaDirectory from "./pages/User/HoaDirectory";
import BillingDetails from "./pages/User/BillingDetails";
import PaymentConfirmed from "./pages/User/PaymentConfirmed";
import WorkPermitConfirmation from "./pages/User/WorkPermitConfirmation";
import RequestDetails from "./pages/User/RequestDetails";
import PickleBallReservation from "./pages/User/PickleBallReservation";
import ReservationConfirmation from "./pages/User/ReservationConfirmation";
import LifeCalendar from "./pages/User/LIfeCalendar";
import GroupChat from "./pages/User/GroupChat";
import NotificationDetails from "./pages/User/NotificationDetails";
import Gatepass from "./pages/User/Gatepass";
import GatepassConfirmation from "./pages/User/GatepassConfirmation";
import MaintenanceRequestConfirmation from "./pages/User/MaintenanceRequestConfirmation";
import Suppliers from "./pages/User/Suppliers";
import GroupChatHome from "./pages/User/GroupChatHome";
import ConcernsForm from "./pages/User/ConcernsForm";
import ConcernConfirmationPage from "./pages/User/ConcernConfirmationPage";
import ConcernDetails from "./pages/User/ConcernDetails";
import Complaints from "./pages/User/Complaints";
import Analytics from "./pages/Dashboard/Analytics";
import WorkPermitDetails from "./pages/Dashboard/WorkPermits/WorkPermitDetails";
import TenantDetailsFullpage from "./pages/Dashboard/TenantManagement/TenantDetailsFullpage";
import SecurityNewPage from "./pages/Security/SecurityNewPage";
import Inventoryv2 from "./pages/Dashboard/Inventory/Inventoryv2";
import ProfileWeb from "./pages/Dashboard/ProfileWeb";
import NotificationWeb from "./pages/Dashboard/Notifications/NotificationWeb";
import Basketball from "./pages/User/ClubHouse";
import UserProfile from "./pages/User/UserProfile";
import InvitationPage from "./pages/User/InvitationPage";
import OtherRequestServices from "./pages/User/OtherRequestServices";
import CarStickerPage from "./pages/User/CarStickerPage";
import Marketplace from "./pages/User/Marketplace/Marketplace";
import NewListingProperty from "./pages/User/Marketplace/NewListingProperty";
import FunctionRoomRequest from "./pages/User/Facilities/FunctionRoomRequest";
import AiDevTool from "./pages/Dashboard/DevTools/AiDevTool";
import ResidentDetailsFullpage from "./pages/Dashboard/ResidentManagement/ResidentDetailsFullpage";
import Todos from "./pages/Dashboard/AdminDashboard/Todos";
import Tickets from "./pages/Dashboard/AdminDashboard/Tickets";
import MarketplaceAdmin from "./pages/Marketplace/MarketplaceAdmin";
import WorkWeekSchedule from "./pages/Dashboard/WorkWeek/WorkWeekSchedule";
import FacilityDetailPage from "./pages/Dashboard/Facilities/FacilityDetailPage";
import SecurityDashboard from "./pages/SecurityMobile/SecurityDashboard";
import Home from "./pages/SecurityMobile/Home";
import ScanQR from "./pages/SecurityMobile/ScanQR";
import UserChat from "./pages/SecurityMobile/UserChat";
import ProfileSecurity from "./pages/SecurityMobile/Profile";
import Guests from "./pages/SecurityMobile/Guests";
import PasswordSecurityUserPage from "./pages/User/UserProfile/PasswordSecurityUserPage";
import AppWrapper from "./components/AppWrapper";
import PaymentPosted from "./pages/User/Billing/PaymentPosted";
import Gatepassdetails from "./pages/User/Gatepass/Gatepassdetails";
import BookingRequestDetails from "./pages/User/Facilities/BookingRequestDetails";
import ConcernConfirmation from "./pages/User/Concerns/ConcernConfirmation";
import CarStickerApplicationDetails from "./pages/User/CarSticker/CarStickerApplicationDetails";
import NewProduct from "./pages/User/Marketplace/NewProduct";
import Newparkingspace from "./pages/User/Marketplace/Newparkingspace";
import AdminDashboardLayout from "./pages/AdminMobileMode/AdminDashboardLayout";
import AdminHomeMobile from "./pages/AdminMobileMode/AdminHomeMobile";
import AdminTicketsPage from "./pages/AdminMobileMode/AdminTicketsPage";
import AdminChatPage from "./pages/AdminMobileMode/AdminChatPage";
import AdminProfilePage from "./pages/AdminMobileMode/AdminProfilePage";
import Parcels from "./pages/SecurityMobile/Parcels";
import ParcelLogs from "./pages/SecurityMobile/ParcelLogs";
import GuestForm from "./pages/SecurityMobile/GuestForm";
import GatepassMobile from "./pages/User/Gatepass/GatepassMobile";
import GatePassMobileAdmin from "./pages/User/Gatepass/GatePassMobileAdmin";
import WorkPermitsAdminMobile from "./pages/Dashboard/AdminDashboard/WorkPermitsAdminMobile";
import AdminResidentListMobile from "./pages/AdminMobileMode/AdminResidentListMobile";
import Overview from "./pages/AdminMobileMode/AdminMobileOverview";
import AdminMobileOverview from "./pages/AdminMobileMode/AdminMobileOverview";
import AdminMobileConcerns from "./pages/AdminMobileMode/AdminMobileConcerns";
import AddConcernMobileAdmin from "./pages/AdminMobileMode/AddConcernMobileAdmin";

function App() {
  const currentUser = useSelector((state) => state.userObject);

  if (
    !currentUser ||
    !currentUser.email ||
    currentUser.email === "" ||
    currentUser.email === null ||
    currentUser.email === undefined
  ) {
    return (
      <div className="App">
        <Router>
          <Routes>
            <Route path="signup" element={<Signup />} />
            <Route path="login" element={<Login />} />
            <Route path="invite/:id" element={<InvitationPage />} />
            <Route path="/resetpassword" element={<ResetPassword />} />
            <Route path="*" element={<Login />} />
          </Routes>
        </Router>
      </div>
    );
  }

  if (currentUser?.roles?.includes("user")) {
    return (
      <div className="App">
        <div className="relative">
          <Router>
            <Routes>
              <Route path="/login" element={<Login />} />
              <Route path="/" element={<Dashboard />}>
                <Route index element={<UserDashboard />} />

                <Route path="dashboard" element={<UserDashboard />} />
                <Route path="car-sticker" element={<CarStickerPage />} />
                <Route
                  path="car-sticker/:id"
                  element={<CarStickerApplicationDetails />}
                />
                <Route path="marketplace" element={<Marketplace />} />

                <Route
                  path="new-listing-property"
                  element={<NewListingProperty />}
                />
                <Route path="new-product-service" element={<NewProduct />} />
                <Route path="new-parking-space" element={<Newparkingspace />} />

                <Route path="life" element={<LifeCalendar />} />
                <Route path="bills" element={<Bills />} />
                <Route
                  path="payment-confirmed/:id"
                  element={<PaymentConfirmed />}
                />
                <Route path="payment-posted/:id" element={<PaymentPosted />} />
                <Route
                  path="billing-details/:id"
                  element={<BillingDetails />}
                />
                <Route path="paynow" element={<Paynow />} />
                <Route path="requests" element={<RequestSummary />} />
                <Route
                  path="request-details/:id"
                  element={<RequestDetails />}
                />
                <Route path="tasks" element={<Tasks />} />
                <Route path="gate-pass" element={<Gatepass />} />
                <Route
                  path="gatepass-confirmation/:id"
                  element={<GatepassConfirmation />}
                />
                <Route
                  path="gatepass-details/:id"
                  element={<Gatepassdetails />}
                />
                <Route path="work-permits" element={<WorkPermitUser />} />
                <Route
                  path="work-permit-confirmation/:id"
                  element={<WorkPermitConfirmation />}
                />
                <Route path="dues" element={<DuesUser />} />
                <Route path="facility-usage" element={<FacilitiesUser />} />
                <Route
                  path="user/facilities/:id"
                  element={<FunctionRoomRequest />}
                />
                <Route path="booking/:id" element={<BookingRequestDetails />} />
                <Route path="basketball" element={<Basketball />} />
                <Route
                  path="reservation-confirmation/:id"
                  element={<ReservationConfirmation />}
                />

                <Route path="pickle-ball" element={<PickleBallReservation />} />
                <Route path="sos" element={<SOS />} />
                <Route path="others" element={<OtherRequestServices />} />
                <Route path="laundry" element={<LaundryUser />} />
                <Route path="water" element={<WaterServiceUser />} />
                <Route path="cleaning" element={<CleaningServicesUser />} />
                <Route path="other-services" element={<OtherServicesUser />} />
                <Route path="suppliers" element={<Suppliers />} />

                <Route path="chat" element={<GroupChatHome />} />
                <Route path="group-chats/:id" element={<GroupChat />} />
                <Route path="admin" element={<AdminUser />} />
                <Route path="notifications" element={<NotificationsUser />} />
                <Route
                  path="notif-details/:id"
                  element={<NotificationDetails />}
                />
                <Route path="condo-corp" element={<HoaDirectory />} />
                <Route path="home" element={<UserDashboard />} />
                <Route path="profile" element={<Profile />} />
                <Route path="userprofile" element={<UserProfile />} />
                <Route path="*" element={<UserUnderConstruction />} />
                <Route
                  path="maintenance-request"
                  element={<MaintenanceRequest />}
                />
                <Route
                  path="maintenance-request-confirmation/:id"
                  element={<MaintenanceRequestConfirmation />}
                />

                <Route path="concerns" element={<ConcernsForm />} />
                <Route
                  path="concern-confirmation/:id"
                  element={<ConcernConfirmation />}
                />
                <Route path="complaints" element={<Complaints />} />
                <Route path="concern/:id" element={<ConcernDetails />} />
                <Route
                  path="/user/concerns"
                  element={<ConcernConfirmationPage />}
                />
                <Route path="register-guests" element={<GuestRegistration />} />
                <Route path="add-guest" element={<AddGuests />} />
                <Route path="edit-guest-card/:id" element={<AddGuests />} />
                <Route path="guest-history" element={<GuestHistory />} />
                <Route path="password" element={<PasswordSecurityUserPage />} />

                <Route
                  path="guest-card-details/:id"
                  element={<GuestCardDetails />}
                />
                <Route path="facilities" element={<Facilities />} />
                <Route path="facilities/:id" element={<FacilityDetailPage />} />
              </Route>
            </Routes>
          </Router>
        </div>
      </div>
    );
  }

  if (
    currentUser?.roles?.includes("security") ||
    currentUser?.roles?.includes("housekeeping") ||
    currentUser?.roles?.includes("reception")
  ) {
    return (
      <AppWrapper>
        <div className="App">
          <div className="">
            <Router>
              <Routes>
                <Route path="/login" element={<Login />} />
                <Route path="/" element={<SecurityDashboard />}>
                  <Route index element={<Home />} />
                  <Route path="dashboard" element={<Home />} />
                  <Route path="admin/mobile/sos" element={<SOS />} />
                  <Route
                    path="work-permits"
                    element={<WorkPermitsAdminMobile />}
                  />
                  <Route
                    path="admin/mobile/suppliers"
                    element={<Suppliers />}
                  />
                  <Route
                    path="admin/mobile/residents"
                    element={<AdminResidentListMobile />}
                  />
                  <Route
                    path="admin/mobile/condo-corp"
                    element={<HoaDirectory />}
                  />
                  <Route path="parcels" element={<Parcels />} />
                  <Route path="parcel-logs" element={<ParcelLogs />} />
                  <Route path="qr" element={<ScanQR />} />
                  <Route path="chat" element={<UserChat />} />
                  <Route path="profile" element={<ProfileSecurity />} />
                  <Route path="gate-pass" element={<GatepassMobile />} />
                  <Route path="gate-pass" element={<GatepassMobile />} />
                  <Route
                    path="gatepass-mobile/:id"
                    element={<Gatepassdetails />}
                  />
                  <Route
                    path="guest-card-details/:id"
                    element={<GuestCardDetails />}
                  />
                  <Route path="*" element={<UnderConstruction />} />
                  <Route path="guests" element={<Guests />} />

                  <Route path="guests/new" element={<GuestForm />} />
                  <Route
                    path="guest-details/:id"
                    element={<GuestCardDetails />}
                  />
                </Route>
              </Routes>
            </Router>
          </div>
        </div>
      </AppWrapper>
    );
  }

  return (
    <AppWrapper>
      <div className="App">
        <Router>
          <Routes>
            <Route path="/" element={<Dashboard />}>
              <Route index element={<AdminDashboard />} />
              <Route path="dashboard" element={<AdminDashboard />} />
              <Route path="admin/mobile" element={<AdminDashboardLayout />}>
                <Route index element={<AdminHomeMobile />} />
                <Route path="home" element={<AdminHomeMobile />} />
                <Route path="overview" element={<AdminMobileOverview />} />
                <Route path="concerns" element={<AdminMobileConcerns />} />
                <Route path="add-concern" element={<AddConcernMobileAdmin />} />
                <Route path="tickets" element={<AdminTicketsPage />} />
                <Route path="chat" element={<AdminChatPage />} />
                <Route path="profile" element={<AdminProfilePage />} />
                <Route path="*" element={<AdminProfilePage />} />
              </Route>
              <Route path="devtool/ai" element={<AiDevTool />} />
              <Route path="tickets" element={<Tickets />} />
              <Route path="marketplace" element={<MarketplaceAdmin />} />
              <Route path="work-week-schedule" element={<WorkWeekSchedule />} />
              <Route
                path="guest-card-details/:id"
                element={<GuestCardDetails />}
              />
              <Route path="overview" element={<AdminDashboard />} />
              <Route
                path="resident-management"
                element={<TenantManagement />}
              />
              <Route
                path="resident/:id"
                element={<ResidentDetailsFullpage />}
              />
              <Route path="tenant/:id" element={<TenantDetailsFullpage />} />
              <Route
                path="property-management"
                element={<PropertyManagement />}
              />
              <Route path="security" element={<SecurityNewPage />} />
              <Route path="inventory" element={<Inventoryv2 />} />
              <Route path="analytics" element={<Analytics />} />
              <Route path="facilities" element={<Facilities />} />
              <Route path="facilities/:id" element={<FacilityDetailPage />} />
              <Route path="todos" element={<Todos />} />
              <Route path="announcements" element={<Announcements />} />
              <Route path="billing" element={<Billing />} />
              <Route path="services" element={<Services />} />
              <Route path="contacts" element={<Contacts />} />
              <Route path="/profile" element={<ProfileWeb />} />
              <Route path="/notifications" element={<NotificationWeb />} />
              <Route path="settings" element={<Settings />} />
              <Route path="*" element={<UnderConstruction />} />
            </Route>
            <Route path="/login" element={<Login />} />
            <Route path="/resetpassword" element={<ResetPassword />} />
            <Route path="/signup" element={<Signup />} />
            <Route path="work-permits/:id" element={<WorkPermitDetails />} />
          </Routes>
        </Router>
      </div>
    </AppWrapper>
  );
}

export default App;

const oldRoutes = {
  // const adminPageRoles = ["super-admin", "admin"];
  // if (currentUser?.roles?.some((role) => adminPageRoles.includes(role))) {
  //   return (
  //     <AppWrapper>
  //       <div className="App">
  //         <Router>
  //           <Routes>
  //             <Route path="/" element={<Dashboard />}>
  //               <Route index element={<AdminDashboard />} />
  //               <Route path="dashboard" element={<AdminDashboard />} />
  //               <Route path="admin/mobile" element={<AdminDashboardLayout />}>
  //                 <Route index element={<AdminHomeMobile />} />
  //                 <Route path="home" element={<AdminHomeMobile />} />
  //                 <Route path="tickets" element={<AdminTicketsPage />} />
  //                 <Route path="chat" element={<AdminChatPage />} />
  //                 <Route path="profile" element={<AdminProfilePage />} />
  //                 <Route path="*" element={<AdminProfilePage />} />
  //               </Route>
  //               <Route path="devtool/ai" element={<AiDevTool />} />
  //               <Route path="tickets" element={<Tickets />} />
  //               <Route path="marketplace" element={<MarketplaceAdmin />} />
  //               <Route
  //                 path="work-week-schedule"
  //                 element={<WorkWeekSchedule />}
  //               />
  //               <Route
  //                 path="guest-card-details/:id"
  //                 element={<GuestCardDetails />}
  //               />
  //               <Route path="overview" element={<AdminDashboard />} />
  //               <Route
  //                 path="resident-management"
  //                 element={<TenantManagement />}
  //               />
  //               <Route
  //                 path="resident/:id"
  //                 element={<ResidentDetailsFullpage />}
  //               />
  //               <Route path="tenant/:id" element={<TenantDetailsFullpage />} />
  //               <Route
  //                 path="property-management"
  //                 element={<PropertyManagement />}
  //               />
  //               <Route path="security" element={<SecurityNewPage />} />
  //               <Route path="inventory" element={<Inventoryv2 />} />
  //               <Route path="analytics" element={<Analytics />} />
  //               <Route path="facilities" element={<Facilities />} />
  //               <Route path="facilities/:id" element={<FacilityDetailPage />} />
  //               <Route path="todos" element={<Todos />} />
  //               <Route path="announcements" element={<Announcements />} />
  //               <Route path="billing" element={<Billing />} />
  //               <Route path="services" element={<Services />} />
  //               <Route path="contacts" element={<Contacts />} />
  //               <Route path="/profile" element={<ProfileWeb />} />
  //               <Route path="/notifications" element={<NotificationWeb />} />
  //               <Route path="settings" element={<Settings />} />
  //               <Route path="*" element={<UnderConstruction />} />
  //             </Route>
  //             <Route path="/login" element={<Login />} />
  //             <Route path="/resetpassword" element={<ResetPassword />} />
  //             <Route path="/signup" element={<Signup />} />
  //             <Route path="work-permits/:id" element={<WorkPermitDetails />} />
  //           </Routes>
  //         </Router>
  //       </div>
  //     </AppWrapper>
  //   );
  // }
};
