import React, { useState, useEffect, useRef } from "react";
import {
  FiMessageSquare,
  FiX,
  FiSend,
  FiMove,
  FiMaximize2,
  FiMinimize2,
  FiUsers,
  FiUser,
  FiSearch,
  FiArrowLeft,
} from "react-icons/fi";
import { motion, AnimatePresence } from "framer-motion";
import { useSelector } from "react-redux";
import {
  getAllDocs,
  queryAllDocsByFieldSnap,
  createDirectChat,
  getDirectChats,
  addMessageToChat,
  getDocDataSnap,
} from "../utils/firebase/Firebase.utils";
import { v4 } from "uuid";
import blankPhoto from "../pages/assets/blankgroupphoto.avif";

const ChatBox = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [messages, setMessages] = useState([]);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [isDragging, setIsDragging] = useState(false);
  const [isMaximized, setIsMaximized] = useState(false);
  const [startPos, setStartPos] = useState({ x: 0, y: 0 });
  const [chatMode, setChatMode] = useState("groups");
  const [groupChats, setGroupChats] = useState([]);
  const [directChats, setDirectChats] = useState([]);
  const [users, setUsers] = useState([]);
  const [selectedChat, setSelectedChat] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const chatBoxRef = useRef(null);
  const messagesEndRef = useRef(null);
  const currentUser = useSelector((state) => state.userObject);

  // Scroll to bottom of messages
  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  // Fetch both group chats and direct chats
  useEffect(() => {
    if (!currentUser?.uid) return;

    // Fetch group chats
    const unsubscribeGroups = queryAllDocsByFieldSnap(
      "groupChats",
      "members",
      "array-contains",
      currentUser.uid,
      (data) => {
        setGroupChats(
          data.sort(
            (a, b) =>
              (b.lastMessageTime?.toDate() ||
                b.createdAt?.toDate() ||
                new Date()) -
              (a.lastMessageTime?.toDate() ||
                a.createdAt?.toDate() ||
                new Date())
          )
        );
      }
    );

    // Fetch direct chats
    const unsubscribeDirect = queryAllDocsByFieldSnap(
      "directChats",
      "members",
      "array-contains",
      currentUser.uid,
      (data) => {
        setDirectChats(
          data.sort(
            (a, b) =>
              (b.lastMessageTime?.toDate() ||
                b.createdAt?.toDate() ||
                new Date()) -
              (a.lastMessageTime?.toDate() ||
                a.createdAt?.toDate() ||
                new Date())
          )
        );
        setIsLoading(false);
      }
    );

    return () => {
      unsubscribeGroups && unsubscribeGroups();
      unsubscribeDirect && unsubscribeDirect();
    };
  }, [currentUser]);

  // Fetch users for direct messaging
  useEffect(() => {
    const fetchUsers = async () => {
      const allUsers = await getAllDocs("users");
      const otherUsers = allUsers.filter(
        (user) => user.id !== currentUser?.uid
      );

      // Add chat information to users
      const usersWithChats = otherUsers.map((user) => {
        const chatId = [currentUser.uid, user.id].sort().join("_");
        const existingChat = directChats.find((chat) => chat.id === chatId);
        return {
          ...user,
          chatId: existingChat?.id || null,
          lastMessage: existingChat?.lastMessage || null,
          lastMessageTime: existingChat?.lastMessageTime || null,
        };
      });

      setUsers(usersWithChats);
    };

    if (currentUser?.uid && directChats.length > 0) {
      fetchUsers();
    }
  }, [currentUser, directChats]);

  const handleMouseDown = (e) => {
    if (isMaximized) return;
    setIsDragging(true);
    setStartPos({
      x: e.clientX - position.x,
      y: e.clientY - position.y,
    });
  };

  const handleMouseMove = (e) => {
    if (!isDragging || isMaximized) return;

    const box = chatBoxRef.current.getBoundingClientRect();
    const maxX = window.innerWidth - box.width;
    const maxY = window.innerHeight - box.height;

    setPosition({
      x: Math.min(Math.max(0, e.clientX - startPos.x), maxX),
      y: Math.min(Math.max(0, e.clientY - startPos.y), maxY),
    });
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  useEffect(() => {
    if (isDragging) {
      window.addEventListener("mousemove", handleMouseMove);
      window.addEventListener("mouseup", handleMouseUp);
    }
    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
      window.removeEventListener("mouseup", handleMouseUp);
    };
  }, [isDragging]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!message.trim() || !selectedChat) return;

    const newMessage = {
      id: v4(),
      message: message.trim(),
      messageStatus: "unread",
      displayName: currentUser.displayName,
      user: currentUser.email,
      userId: currentUser.uid,
      dateTime: new Date(),
    };

    try {
      await addMessageToChat(
        selectedChat.id,
        selectedChat.type === "group",
        newMessage
      );
      setMessage("");
    } catch (error) {
      console.error("Error sending message:", error);
    }
  };

  const toggleMaximize = () => {
    setIsMaximized(!isMaximized);
    if (!isMaximized) {
      setPosition({ x: 0, y: 0 });
    }
  };

  const handleSelectChat = (chat) => {
    setSelectedChat(chat);
    // Subscribe to messages
    const unsubscribe = getDocDataSnap(
      chat.type === "group" ? "groupChats" : "directChats",
      chat.id,
      (data) => {
        if (data) {
          setMessages(data.messages || []);
        }
      }
    );
    return () => unsubscribe && unsubscribe();
  };

  const handleStartDirectChat = async (user) => {
    try {
      const chatId = await createDirectChat(currentUser.uid, user.id);
      handleSelectChat({
        id: chatId,
        name: user.displayName,
        type: "direct",
        members: [currentUser.uid, user.id],
      });
    } catch (error) {
      console.error("Error creating direct chat:", error);
    }
  };

  const getLastMessagePreview = (chat) => {
    if (!chat.lastMessage) return "No messages yet";
    return `${chat.lastMessage.slice(0, 30)}${
      chat.lastMessage.length > 30 ? "..." : ""
    }`;
  };

  const formatLastMessageTime = (timestamp) => {
    if (!timestamp) return "";
    const date = timestamp.toDate();
    const now = new Date();
    const diff = now - date;

    if (diff < 60000) return "Just now";
    if (diff < 3600000) return `${Math.floor(diff / 60000)}m ago`;
    if (diff < 86400000) return `${Math.floor(diff / 3600000)}h ago`;
    return date.toLocaleDateString();
  };

  return (
    <div
      ref={chatBoxRef}
      style={{
        position: "fixed",
        right: isMaximized ? 0 : position.x === 0 ? "16px" : "auto",
        bottom: isMaximized ? 0 : position.y === 0 ? "16px" : "auto",
        left: isMaximized ? 0 : position.x !== 0 ? `${position.x}px` : "auto",
        top: isMaximized ? 0 : position.y !== 0 ? `${position.y}px` : "auto",
        zIndex: 50,
        transition: isMaximized ? "all 0.3s ease-in-out" : "none",
      }}
    >
      <AnimatePresence>
        {isOpen && (
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 20 }}
            className={`bg-white rounded-lg shadow-lg ${
              isMaximized ? "w-full h-screen flex" : "w-80"
            }`}
          >
            {isMaximized && (
              <div className="w-80 border-r border-gray-200 flex flex-col">
                {/* Sidebar Header */}
                <div className="p-4 border-b border-gray-200">
                  <div className="flex items-center justify-between mb-4">
                    <h3 className="font-medium text-lg">Messages</h3>
                    <div className="flex items-center gap-2">
                      <button
                        onClick={() => setChatMode("groups")}
                        className={`text-gray-600 transition-colors ${
                          chatMode === "groups" ? "text-blue-600" : "opacity-50"
                        }`}
                      >
                        <FiUsers className="w-5 h-5" />
                      </button>
                      <button
                        onClick={() => setChatMode("direct")}
                        className={`text-gray-600 transition-colors ${
                          chatMode === "direct" ? "text-blue-600" : "opacity-50"
                        }`}
                      >
                        <FiUser className="w-5 h-5" />
                      </button>
                    </div>
                  </div>
                  <div className="relative">
                    <FiSearch className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
                    <input
                      type="text"
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                      placeholder={`Search ${
                        chatMode === "groups" ? "groups" : "users"
                      }...`}
                      className="w-full pl-10 pr-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                    />
                  </div>
                </div>

                {/* Chat List */}
                <div className="flex-1 overflow-y-auto">
                  {isLoading ? (
                    <div className="flex items-center justify-center py-8">
                      <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-600" />
                    </div>
                  ) : chatMode === "groups" ? (
                    // Group Chats List
                    <div className="space-y-1">
                      {groupChats
                        .filter((group) =>
                          group.name
                            .toLowerCase()
                            .includes(searchTerm.toLowerCase())
                        )
                        .map((group) => (
                          <div
                            key={group.id}
                            onClick={() =>
                              handleSelectChat({ ...group, type: "group" })
                            }
                            className={`flex items-center gap-3 p-3 hover:bg-gray-50 cursor-pointer ${
                              selectedChat?.id === group.id ? "bg-blue-50" : ""
                            }`}
                          >
                            <img
                              src={group.image || blankPhoto}
                              alt={group.name}
                              className="w-12 h-12 rounded-full object-cover"
                            />
                            <div className="flex-1 min-w-0">
                              <div className="font-medium">{group.name}</div>
                              <div className="text-sm text-gray-500 truncate">
                                {getLastMessagePreview(group)}
                              </div>
                            </div>
                            {group.lastMessageTime && (
                              <div className="text-xs text-gray-400">
                                {formatLastMessageTime(group.lastMessageTime)}
                              </div>
                            )}
                          </div>
                        ))}
                    </div>
                  ) : (
                    // Users List
                    <div className="space-y-1">
                      {users
                        .filter((user) =>
                          user.displayName
                            .toLowerCase()
                            .includes(searchTerm.toLowerCase())
                        )
                        .map((user) => (
                          <div
                            key={user.id}
                            onClick={() => handleStartDirectChat(user)}
                            className={`flex items-center gap-3 p-3 hover:bg-gray-50 cursor-pointer ${
                              selectedChat?.id ===
                              [currentUser.uid, user.id].sort().join("_")
                                ? "bg-blue-50"
                                : ""
                            }`}
                          >
                            <div className="w-12 h-12 rounded-full bg-blue-100 flex items-center justify-center">
                              <span className="text-blue-600 font-medium text-lg">
                                {user.displayName?.[0]?.toUpperCase()}
                              </span>
                            </div>
                            <div className="flex-1 min-w-0">
                              <div className="font-medium">
                                {user.displayName}
                              </div>
                              <div className="text-sm text-gray-500 truncate">
                                {user.chatId
                                  ? getLastMessagePreview({
                                      lastMessage: user.lastMessage,
                                    })
                                  : "Start a conversation"}
                              </div>
                            </div>
                            {user.lastMessageTime && (
                              <div className="text-xs text-gray-400">
                                {formatLastMessageTime(user.lastMessageTime)}
                              </div>
                            )}
                          </div>
                        ))}
                    </div>
                  )}
                </div>
              </div>
            )}

            {/* Main Chat Area */}
            <div className={`${isMaximized ? "flex-1" : ""} flex flex-col`}>
              {/* Chat Header */}
              <div
                className={`bg-blue-600 text-white p-4 ${
                  !isMaximized && "rounded-t-lg"
                } flex items-center justify-between cursor-move select-none`}
                onMouseDown={handleMouseDown}
              >
                <div className="flex items-center gap-2">
                  {!isMaximized && selectedChat && (
                    <button
                      onClick={() => setSelectedChat(null)}
                      className="hover:text-gray-200 transition-colors"
                    >
                      <FiArrowLeft className="w-5 h-5" />
                    </button>
                  )}
                  {!isMaximized && !selectedChat && (
                    <FiMove className="w-4 h-4" />
                  )}
                  <h3 className="font-medium">
                    {selectedChat ? selectedChat.name : "Chat"}
                  </h3>
                </div>
                <div className="flex items-center gap-2">
                  {!isMaximized && !selectedChat && (
                    <>
                      <button
                        onClick={() => setChatMode("groups")}
                        className={`text-white transition-colors ${
                          chatMode === "groups" ? "opacity-100" : "opacity-50"
                        }`}
                      >
                        <FiUsers className="w-5 h-5" />
                      </button>
                      <button
                        onClick={() => setChatMode("direct")}
                        className={`text-white transition-colors ${
                          chatMode === "direct" ? "opacity-100" : "opacity-50"
                        }`}
                      >
                        <FiUser className="w-5 h-5" />
                      </button>
                    </>
                  )}
                  <button
                    onClick={toggleMaximize}
                    className="text-white hover:text-gray-200 transition-colors"
                  >
                    {isMaximized ? (
                      <FiMinimize2 className="w-5 h-5" />
                    ) : (
                      <FiMaximize2 className="w-5 h-5" />
                    )}
                  </button>
                  <button
                    onClick={() => {
                      setIsOpen(false);
                      setSelectedChat(null);
                    }}
                    className="text-white hover:text-gray-200 transition-colors"
                  >
                    <FiX className="w-5 h-5" />
                  </button>
                </div>
              </div>

              {!isMaximized && !selectedChat ? (
                // Chat List for non-maximized view
                <div className="p-4">
                  <div className="relative mb-4">
                    <FiSearch className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
                    <input
                      type="text"
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                      placeholder={`Search ${
                        chatMode === "groups" ? "groups" : "users"
                      }...`}
                      className="w-full pl-10 pr-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                    />
                  </div>

                  <div className="space-y-2 max-h-[400px] overflow-y-auto">
                    {isLoading ? (
                      <div className="flex items-center justify-center py-8">
                        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-600" />
                      </div>
                    ) : chatMode === "groups" ? (
                      // Group Chats List
                      groupChats
                        .filter((group) =>
                          group.name
                            .toLowerCase()
                            .includes(searchTerm.toLowerCase())
                        )
                        .map((group) => (
                          <div
                            key={group.id}
                            onClick={() =>
                              handleSelectChat({ ...group, type: "group" })
                            }
                            className="flex items-center gap-3 p-2 hover:bg-gray-50 rounded-lg cursor-pointer"
                          >
                            <img
                              src={group.image || blankPhoto}
                              alt={group.name}
                              className="w-10 h-10 rounded-full object-cover"
                            />
                            <div className="flex-1 min-w-0">
                              <div className="font-medium">{group.name}</div>
                              <div className="text-sm text-gray-500 truncate">
                                {getLastMessagePreview(group)}
                              </div>
                            </div>
                            {group.lastMessageTime && (
                              <div className="text-xs text-gray-400">
                                {formatLastMessageTime(group.lastMessageTime)}
                              </div>
                            )}
                          </div>
                        ))
                    ) : (
                      // Users List
                      users
                        .filter((user) =>
                          user.displayName
                            .toLowerCase()
                            .includes(searchTerm.toLowerCase())
                        )
                        .map((user) => (
                          <div
                            key={user.id}
                            onClick={() => handleStartDirectChat(user)}
                            className="flex items-center gap-3 p-2 hover:bg-gray-50 rounded-lg cursor-pointer"
                          >
                            <div className="w-10 h-10 rounded-full bg-blue-100 flex items-center justify-center">
                              <span className="text-blue-600 font-medium">
                                {user.displayName?.[0]?.toUpperCase()}
                              </span>
                            </div>
                            <div className="flex-1 min-w-0">
                              <div className="font-medium">
                                {user.displayName}
                              </div>
                              <div className="text-sm text-gray-500 truncate">
                                {user.chatId
                                  ? getLastMessagePreview({
                                      lastMessage: user.lastMessage,
                                    })
                                  : "Start a conversation"}
                              </div>
                            </div>
                            {user.lastMessageTime && (
                              <div className="text-xs text-gray-400">
                                {formatLastMessageTime(user.lastMessageTime)}
                              </div>
                            )}
                          </div>
                        ))
                    )}
                  </div>
                </div>
              ) : (
                <>
                  {/* Messages Area */}
                  <div
                    className={`${
                      isMaximized ? "flex-1" : "h-96"
                    } p-4 overflow-y-auto bg-gray-50`}
                  >
                    {selectedChat ? (
                      messages.map((msg, index) => (
                        <div
                          key={msg.id || index}
                          className={`mb-4 flex ${
                            msg.userId === currentUser.uid
                              ? "justify-end"
                              : "justify-start"
                          }`}
                        >
                          <div
                            className={`max-w-[80%] rounded-lg p-3 ${
                              msg.userId === currentUser.uid
                                ? "bg-blue-600 text-white"
                                : "bg-white text-gray-800 border border-gray-200"
                            }`}
                          >
                            <div className="text-xs opacity-75 mb-1">
                              {msg.displayName}
                            </div>
                            <p className="text-sm break-words">{msg.message}</p>
                            <span className="text-xs opacity-75 mt-1 block">
                              {msg.dateTime.toDate().toLocaleTimeString()}
                            </span>
                          </div>
                        </div>
                      ))
                    ) : (
                      <div className="h-full flex items-center justify-center text-gray-500">
                        Select a chat to start messaging
                      </div>
                    )}
                    <div ref={messagesEndRef} />
                  </div>

                  {/* Chat Input */}
                  {selectedChat && (
                    <form
                      onSubmit={handleSubmit}
                      className="border-t p-4 bg-white rounded-b-lg"
                    >
                      <div className="flex items-center gap-2">
                        <input
                          type="text"
                          value={message}
                          onChange={(e) => setMessage(e.target.value)}
                          placeholder="Type your message..."
                          className="flex-1 px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 bg-gray-50"
                        />
                        <button
                          type="submit"
                          className="p-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
                          disabled={!message.trim()}
                        >
                          <FiSend className="w-5 h-5" />
                        </button>
                      </div>
                    </form>
                  )}
                </>
              )}
            </div>
          </motion.div>
        )}
      </AnimatePresence>

      {/* Toggle Button */}
      {!isOpen && (
        <motion.button
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.9 }}
          onClick={() => setIsOpen(true)}
          className="bg-blue-600 text-white p-4 rounded-full shadow-lg hover:bg-blue-700 transition-colors"
        >
          <FiMessageSquare className="w-6 h-6" />
        </motion.button>
      )}
    </div>
  );
};

export default ChatBox;
