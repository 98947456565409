import React, { useEffect, useState } from "react";
import MobileWrapper from "../../components/MobileWrapper";
import { getAllDocsSnap } from "../../utils/firebase/Firebase.utils";

export default function Guests() {
  const [guests, setGuests] = useState([]);
  const [filteredGuests, setFilteredGuests] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [activeFilter, setActiveFilter] = useState("all");
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchGuests = async () => {
      await getAllDocsSnap("guests", (snap) => {
        setGuests(snap);
        setFilteredGuests(snap);
        setIsLoading(false);
        console.log(snap[0]);
      });
    };
    fetchGuests();
  }, []);

  // Apply both search term and status filter
  useEffect(() => {
    let filtered = [...guests];

    // Apply status filter first
    if (activeFilter !== "all") {
      filtered = filtered.filter(
        (guest) => guest.status?.toLowerCase() === activeFilter.toLowerCase()
      );
    }

    // Then apply search term filter
    if (searchTerm.trim()) {
      const term = searchTerm.toLowerCase().trim();
      filtered = filtered.filter((guest) => {
        // Search in requestor name
        if (guest.requestorName?.toLowerCase().includes(term)) return true;

        // Search in unit identifiers
        if (
          guest.linkedIdentifiers?.some((unit) =>
            unit.toLowerCase().includes(term)
          )
        )
          return true;

        // Search in visitor names
        if (
          guest.guests?.some((visitor) =>
            visitor.name?.toLowerCase().includes(term)
          )
        )
          return true;

        // Search in plate numbers
        if (
          guest.guests?.some((visitor) =>
            visitor.plateNumber?.toLowerCase().includes(term)
          )
        )
          return true;

        return false;
      });
    }

    setFilteredGuests(filtered);
  }, [searchTerm, guests, activeFilter]);

  // Color scheme based on colorscheme.md
  const eleghoBlue = "#363062";
  const eleghoGreen = "#8FCD00";

  // Function to format date
  const formatDate = (dateString) => {
    if (!dateString) return "N/A";
    return new Date(dateString).toLocaleDateString();
  };

  const getStatusColor = (status) => {
    switch (status?.toLowerCase()) {
      case "approved":
        return eleghoGreen;
      case "pending":
        return "#FFA500"; // Orange
      case "rejected":
        return "#FF0000"; // Red
      case "admitted":
        return "#4299E1"; // Blue
      case "completed":
        return "#805AD5"; // Purple
      case "returned":
        return "#718096"; // Gray
      default:
        return "#808080"; // Default Gray
    }
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleAddNewRequest = () => {
    // Navigate to the new guest request form
    window.location.href = "/guests/new";
  };

  const handleFilterChange = (filter) => {
    if (filter === activeFilter) {
      // If clicking the active filter, clear it
      setActiveFilter("all");
    } else {
      setActiveFilter(filter);
    }
  };

  // Count for each status type
  const getStatusCount = (status) => {
    return guests.filter(
      (guest) => guest.status?.toLowerCase() === status.toLowerCase()
    ).length;
  };

  const handleCardClick = (id) => {
    window.location.href = `guest-details/${id}`;
  };

  if (isLoading) {
    return (
      <MobileWrapper label="Guests Requests">
        <div className="flex justify-center items-center h-64">
          <div className="text-center">
            <div
              className="spinner-border"
              role="status"
              style={{ color: eleghoBlue }}
            >
              <span className="sr-only">Loading...</span>
            </div>
            <p className="mt-2 text-base" style={{ color: eleghoBlue }}>
              Loading guest requests...
            </p>
          </div>
        </div>
      </MobileWrapper>
    );
  }

  return (
    <MobileWrapper label="Guests Requests">
      <div className="flex justify-between items-center mb-4">
        <div className="text-base font-semibold" style={{ color: eleghoBlue }}>
          Guest Registration Requests
        </div>
        <button
          className="flex items-center px-3 py-2 text-sm font-medium text-white rounded-md"
          style={{ backgroundColor: eleghoGreen }}
          onClick={handleAddNewRequest}
        >
          <svg
            className="w-4 h-4 mr-1"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M12 4v16m8-8H4"
            />
          </svg>
          New Request
        </button>
      </div>

      {/* Search Bar */}
      <div className="mb-4">
        <div className="relative">
          <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
            <svg
              className="w-4 h-4 text-gray-500"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 20 20"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
              />
            </svg>
          </div>
          <input
            type="text"
            className="block w-full p-2 pl-10 text-sm border rounded-lg bg-gray-50 focus:outline-none"
            placeholder="Search by name, unit, status..."
            value={searchTerm}
            onChange={handleSearch}
            style={{ borderColor: eleghoBlue }}
          />
          {searchTerm && (
            <button
              className="absolute inset-y-0 right-0 flex items-center pr-3"
              onClick={() => setSearchTerm("")}
            >
              <svg
                className="w-4 h-4 text-gray-500"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 14 14"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                />
              </svg>
            </button>
          )}
        </div>
      </div>

      {/* Status Filter Buttons */}
      <div className="mb-4 overflow-x-auto">
        <div className="flex space-x-2 pb-2">
          <button
            className={`px-3 py-1.5 text-xs font-medium rounded-full whitespace-nowrap ${
              activeFilter === "all"
                ? "bg-gray-700 text-white"
                : "bg-gray-200 text-gray-800"
            }`}
            onClick={() => handleFilterChange("all")}
          >
            All ({guests.length})
          </button>
          <button
            className={`px-3 py-1.5 text-xs font-medium rounded-full whitespace-nowrap ${
              activeFilter === "pending"
                ? "text-white"
                : "bg-opacity-20 text-gray-800"
            }`}
            style={{
              backgroundColor:
                activeFilter === "pending"
                  ? "#FFA500"
                  : "rgba(255, 165, 0, 0.2)",
            }}
            onClick={() => handleFilterChange("pending")}
          >
            Pending ({getStatusCount("pending")})
          </button>
          <button
            className={`px-3 py-1.5 text-xs font-medium rounded-full whitespace-nowrap ${
              activeFilter === "approved"
                ? "text-white"
                : "bg-opacity-20 text-gray-800"
            }`}
            style={{
              backgroundColor:
                activeFilter === "approved"
                  ? eleghoGreen
                  : `rgba(143, 205, 0, 0.2)`,
            }}
            onClick={() => handleFilterChange("approved")}
          >
            Approved ({getStatusCount("approved")})
          </button>
          <button
            className={`px-3 py-1.5 text-xs font-medium rounded-full whitespace-nowrap ${
              activeFilter === "rejected"
                ? "text-white"
                : "bg-opacity-20 text-gray-800"
            }`}
            style={{
              backgroundColor:
                activeFilter === "rejected"
                  ? "#FF0000"
                  : "rgba(255, 0, 0, 0.2)",
            }}
            onClick={() => handleFilterChange("rejected")}
          >
            Rejected ({getStatusCount("rejected")})
          </button>
          <button
            className={`px-3 py-1.5 text-xs font-medium rounded-full whitespace-nowrap ${
              activeFilter === "admitted"
                ? "text-white"
                : "bg-opacity-20 text-gray-800"
            }`}
            style={{
              backgroundColor:
                activeFilter === "admitted"
                  ? "#4299E1"
                  : "rgba(66, 153, 225, 0.2)",
            }}
            onClick={() => handleFilterChange("admitted")}
          >
            Admitted ({getStatusCount("admitted")})
          </button>
          <button
            className={`px-3 py-1.5 text-xs font-medium rounded-full whitespace-nowrap ${
              activeFilter === "completed"
                ? "text-white"
                : "bg-opacity-20 text-gray-800"
            }`}
            style={{
              backgroundColor:
                activeFilter === "completed"
                  ? "#805AD5"
                  : "rgba(128, 90, 213, 0.2)",
            }}
            onClick={() => handleFilterChange("completed")}
          >
            Completed ({getStatusCount("completed")})
          </button>
          <button
            className={`px-3 py-1.5 text-xs font-medium rounded-full whitespace-nowrap ${
              activeFilter === "returned"
                ? "text-white"
                : "bg-opacity-20 text-gray-800"
            }`}
            style={{
              backgroundColor:
                activeFilter === "returned"
                  ? "#718096"
                  : "rgba(113, 128, 150, 0.2)",
            }}
            onClick={() => handleFilterChange("returned")}
          >
            Returned ({getStatusCount("returned")})
          </button>
        </div>
      </div>

      <div className="mt-4 space-y-4">
        {filteredGuests.length === 0 ? (
          <div className="p-4 text-center bg-white rounded-lg shadow-md">
            <p className="text-base">
              {guests.length === 0
                ? "No guest requests found"
                : "No results match your search criteria"}
            </p>
          </div>
        ) : (
          filteredGuests.map((guest, index) => (
            <div
              key={guest.id}
              className="p-4 bg-white rounded-lg shadow-md hover:shadow-lg transition-all duration-200 cursor-pointer relative"
              onClick={() => handleCardClick(guest.id)}
            >
              <div className="flex justify-between items-start mb-2">
                <div className="text-base font-medium">
                  {guest.requestorName || "Unnamed Requestor"}
                </div>
                <div
                  className="px-2 py-1 rounded-full text-xs font-medium"
                  style={{
                    backgroundColor: getStatusColor(guest.status),
                    color: "white",
                  }}
                >
                  {guest.status || "Unknown Status"}
                </div>
              </div>

              <div className="grid grid-cols-2 gap-2 mb-2 text-sm">
                <div>
                  <span className="font-medium">Date:</span>{" "}
                  {guest.date || formatDate(guest.requestDate) || "N/A"}
                </div>
                <div>
                  <span className="font-medium">Time:</span>{" "}
                  {guest.time || "N/A"}
                </div>
                <div>
                  <span className="font-medium">Unit:</span>{" "}
                  {guest.linkedIdentifiers
                    ? guest.linkedIdentifiers.join(", ")
                    : "N/A"}
                </div>
                <div>
                  <span className="font-medium">Updated:</span>{" "}
                  {guest.updatedAt || "N/A"}
                </div>
              </div>

              {guest.guests && guest.guests.length > 0 && (
                <div
                  className="mt-2 pt-2 border-t"
                  style={{ borderColor: "#eaeaea" }}
                >
                  <div className="font-medium text-sm">Visitors:</div>
                  {guest.guests.map((visitor, i) => (
                    <div key={i} className="pl-2 mt-1 text-sm">
                      <div>{visitor.name || "Unnamed Visitor"}</div>
                      {visitor.plateNumber && (
                        <div className="text-xs">
                          Vehicle: {visitor.plateNumber}
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              )}

              {guest.assignedTo && guest.assignedTo.length > 0 && (
                <div className="mt-2 text-sm">
                  <span className="font-medium">Assigned to:</span>{" "}
                  {guest.assignedTo.map((a) => a.label).join(", ")}
                </div>
              )}

              {/* Right arrow indicator */}
              <div className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-400">
                <svg
                  className="w-5 h-5"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M9 5l7 7-7 7"
                  ></path>
                </svg>
              </div>
            </div>
          ))
        )}
      </div>
    </MobileWrapper>
  );
}
