import React from "react";
import { Link } from "react-router-dom";

export default function GroupChatCard({ groupChat }) {
  const messages = groupChat.messages || [];
  const lastMessageSent = messages[messages.length - 1] || {};

  // Modern placeholder avatar with initials
  const getInitials = (name) => {
    return name
      .split(" ")
      .map((word) => word[0])
      .join("")
      .toUpperCase()
      .slice(0, 2);
  };

  const placeholderAvatar = `data:image/svg+xml;base64,${btoa(`
    <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="56" height="56" rx="28" fill="#E5E7EB"/>
      <text x="50%" y="50%" dominant-baseline="middle" text-anchor="middle" font-family="system-ui" font-size="20" font-weight="600" fill="#6B7280">${getInitials(
        groupChat.name
      )}</text>
    </svg>
  `)}`;

  return (
    <Link
      to={`/group-chats/${groupChat.id}`}
      className="block transition-all duration-200 hover:scale-[1.02]"
    >
      <div className="relative overflow-hidden max-w-sm mx-auto">
        <div className="flex items-center p-2 gap-2">
          <div className="relative">
            <img
              className="w-14 h-14 rounded-full object-cover ring-2 ring-white shadow-sm"
              src={groupChat.image || placeholderAvatar}
              alt={groupChat.name}
            />
            {lastMessageSent?.readStatus === "unread" && (
              <div className="absolute -top-1 -right-1 w-4 h-4 bg-blue-500 rounded-full border-2 border-white" />
            )}
          </div>

          <div className="flex-1 min-w-0">
            <div className="flex items-center justify-between mb-1">
              <h3 className="text-lg font-semibold text-gray-900 truncate">
                {groupChat.name}
              </h3>
              {lastMessageSent.timestamp && (
                <span className="text-xs text-gray-500">
                  {new Date(lastMessageSent.timestamp).toLocaleTimeString([], {
                    hour: "2-digit",
                    minute: "2-digit",
                  })}
                </span>
              )}
            </div>

            <div className="flex items-center gap-2">
              <p
                className={`text-sm text-gray-400 truncate ${
                  lastMessageSent?.readStatus === "unread"
                    ? "font-medium text-gray-900"
                    : ""
                }`}
              >
                {lastMessageSent.message || "No messages yet"}
              </p>
              {lastMessageSent?.readStatus === "unread" && (
                <span className="text-xs font-medium text-blue-500 bg-blue-50 px-2 py-0.5 rounded-full">
                  New
                </span>
              )}
            </div>
          </div>
        </div>
      </div>
    </Link>
  );
}
