import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { getAllDocsSnap } from "../../../utils/firebase/Firebase.utils";
import TicketCard from "../Tickets/TicketCard";
import WorkPermitCards from "../WorkPermits/WorkPermitCards";
import ConcernsCard from "../ConcernsCard";
import GatePassCard from "../../Security/GatePassCard";
import GuestPassCard from "../../Security/GuestPassCard";
import IncidentReportsCard from "../../Security/IncidentReportsCard";
import { slicedData } from "../../../utils/EleghoUtils";
import BentoTable from "../../../components/BentoTable";
import { Bento } from "@mui/icons-material";
import PageModal from "../Components/PageModal";
import WorkPermitDetails from "../WorkPermits/WorkPermitDetails";
import ConcernsPageDetails from "../Concerns/ConcernsPageDetails";
import GatePassDetailsPage from "../../Security/GatePassDetailsPage";
import GuestPassDetailsPage from "../../Security/GuestPassDetailsPage";
import IncidentReportDetails from "../../Security/IncidentReportDetails";

export default function Tickets() {
  const currentUser = useSelector((state) => state.userObject);
  const [activeLink, setActiveLink] = React.useState("Work-Permits");
  const [isLoading, setIsLoading] = React.useState(true);
  const [tickets, setTickets] = React.useState([]);
  const [workPermits, setWorkPermits] = React.useState([]);
  const [concerns, setConcerns] = React.useState([]);
  const [gatePasses, setGatePasses] = React.useState([]);
  const [guestPasses, setGuestPasses] = React.useState([]);
  const [moveInOut, setMoveInOut] = React.useState([]);
  const [incidentReports, setIncidentReports] = React.useState([]);

  const [numberOfItemPerPage, setNumberOfItemPerPage] = React.useState(10);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [viewMode, setViewMode] = React.useState(false);
  const [selectedItem, setSelectedItem] = React.useState(null);

  useEffect(() => {
    const fetchIncidentReports = async () => {
      await getAllDocsSnap("incidentReports", (doc) => {
        setIncidentReports(doc);
      });
    };
    fetchIncidentReports();
  }, []);

  useEffect(() => {
    const fetchMoveInOut = async () => {
      await getAllDocsSnap("moveInOut", (doc) => {
        setMoveInOut(doc);
      });
    };
    fetchMoveInOut();
  }, []);

  useEffect(() => {
    const fetchguestPass = async () => {
      await getAllDocsSnap("guests", (doc) => {
        setGuestPasses(doc);
      });
    };
    fetchguestPass();
  }, []);

  useEffect(() => {
    const fetchgatepass = async () => {
      await getAllDocsSnap("gatePass", (doc) => {
        setGatePasses(doc);
      });
    };
    fetchgatepass();
  }, []);

  useEffect(() => {
    const fetchConcerns = async () => {
      await getAllDocsSnap("concerns", (doc) => {
        setConcerns(doc);
      });
    };
    fetchConcerns();
  }, []);

  useEffect(() => {
    const fetchWorkPermits = async () => {
      await getAllDocsSnap("workPermits", (doc) => {
        setWorkPermits(doc);
        setIsLoading(false);
      });
    };
    fetchWorkPermits();
  }, []);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  const links = [
    // "All",
    "Work-Permits",
    "Move-in/Move-out",
    "Concerns",
    "Gate Pass",
    "Guest Pass",
    "Incident Reports",
  ];

  //use switch case instead of if else
  let currentList = [];
  switch (activeLink) {
    case "All":
      currentList = tickets;
      break;
    case "Move-in/Move-out":
      currentList = moveInOut;
      break;
    case "Work-Permits":
      currentList = workPermits;
      break;
    case "Concerns":
      currentList = concerns;
      break;
    case "Gate Pass":
      currentList = gatePasses;
      break;
    case "Guest Pass":
      currentList = guestPasses;
      break;
    case "Incident Reports":
      currentList = incidentReports;
      break;
    default:
      currentList = tickets;
  }

  const filteredData = currentUser.roles.includes("admin")
    ? currentList
    : currentList.filter((item) =>
        item?.assignedTo?.some((user) => user.value === currentUser.email)
      );

  const dataSliced = slicedData(filteredData, numberOfItemPerPage, currentPage);

  const handleViewMode = (item) => {
    setViewMode(true);
    setSelectedItem(item);
  };

  return (
    <div className="text-left max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
      <div className="bg-gradient-to-r from-eleghoBlue/10 to-transparent p-6 rounded-xl">
        <div className="flex items-center gap-3 mb-2">
          <svg
            className="w-6 h-6 text-eleghoBlue"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M15 5v2m0 4v2m0 4v2M5 5a2 2 0 00-2 2v3a2 2 0 110 4v3a2 2 0 002 2h14a2 2 0 002-2v-3a2 2 0 110-4V7a2 2 0 00-2-2H5z"
            />
          </svg>
          <h1 className="font-bold text-eleghoBlue text-xl">Tickets</h1>
        </div>
        <div className="space-y-1.5">
          <p className="text-gray-600 text-sm leading-relaxed">
            Tickets are requests and tasks - specifically assigned to you.
          </p>
          <p className="text-gray-600 text-sm leading-relaxed flex items-center gap-2">
            <svg
              className="w-4 h-4 text-eleghoBlue"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M13 10V3L4 14h7v7l9-11h-7z"
              />
            </svg>
            Admins can view all tickets
          </p>
        </div>
      </div>

      <div className="mt-4 links-container text-xs text-center select-none">
        {links.map((link, index) => (
          <div
            className={
              activeLink === link ? "activeLinkStyle" : "inactiveLinkStyle"
            }
            key={index}
            onClick={() => setActiveLink(link)}
          >
            {link}
          </div>
        ))}
      </div>

      {activeLink === "Work-Permits" && (
        <div className="mt-4 text-xs">
          <BentoTable
            data={currentList}
            numberOfItemPerPage={numberOfItemPerPage}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            label={activeLink}
          >
            <div className="divide-y divide-gray-100">
              {/* Table Header */}
              <div className="grid grid-cols-1 md:grid-cols-6 gap-2 font-semibold py-3 px-4 bg-gray-50 text-gray-700 text-xs rounded-t-lg">
                <div>Unit Number</div>
                <div>Type of Work</div>
                <div>Applicant</div>
                <div>Date Applied</div>
                <div>Duration</div>
                <div className="text-center">Status</div>
              </div>

              {dataSliced.map((ticket, index) => (
                <div key={index} onClick={() => handleViewMode(ticket)}>
                  <WorkPermitCards application={ticket} />
                </div>
              ))}
            </div>
          </BentoTable>
        </div>
      )}

      {activeLink === "Concerns" && (
        <div className="mt-4 text-xs">
          <BentoTable
            data={currentList}
            numberOfItemPerPage={numberOfItemPerPage}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            label={activeLink}
          >
            {dataSliced.map((ticket, index) => (
              <div key={index} onClick={() => handleViewMode(ticket)}>
                <ConcernsCard concern={ticket} />
              </div>
            ))}
          </BentoTable>
        </div>
      )}

      {activeLink === "Gate Pass" && (
        <div className="mt-4 text-xs">
          <BentoTable
            data={currentList}
            numberOfItemPerPage={numberOfItemPerPage}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            label={activeLink}
          >
            {dataSliced.map((ticket, index) => (
              <div key={index} onClick={() => handleViewMode(ticket)}>
                <GatePassCard data={ticket} />
              </div>
            ))}
          </BentoTable>
        </div>
      )}

      {activeLink === "Guest Pass" && (
        <div className="mt-4 text-xs">
          <BentoTable
            data={currentList}
            numberOfItemPerPage={numberOfItemPerPage}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            label={activeLink}
          >
            {dataSliced.map((ticket, index) => (
              <div key={index} onClick={() => handleViewMode(ticket)}>
                <GuestPassCard data={ticket} />
              </div>
            ))}
          </BentoTable>
        </div>
      )}

      {activeLink === "Incident Reports" && (
        <div className="mt-4 text-xs">
          <BentoTable
            data={currentList}
            numberOfItemPerPage={numberOfItemPerPage}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            label={activeLink}
          >
            {dataSliced.map((ticket, index) => (
              <div key={index} onClick={() => handleViewMode(ticket)}>
                <IncidentReportsCard data={ticket} />
              </div>
            ))}
          </BentoTable>
        </div>
      )}

      {viewMode && (
        <PageModal>
          {activeLink === "Work-Permits" && (
            <WorkPermitDetails
              application={selectedItem}
              setViewMode={setViewMode}
            />
          )}

          {activeLink === "Concerns" && (
            <ConcernsPageDetails
              concern={selectedItem}
              setViewMode={setViewMode}
            />
          )}

          {activeLink === "Gate Pass" && (
            <GatePassDetailsPage
              data={selectedItem}
              setViewMode={setViewMode}
            />
          )}

          {activeLink === "Guest Pass" && (
            <GuestPassDetailsPage
              data={selectedItem}
              setViewMode={setViewMode}
            />
          )}

          {activeLink === "Incident Reports" && (
            <IncidentReportDetails
              data={selectedItem}
              setViewMode={setViewMode}
            />
          )}
        </PageModal>
      )}
    </div>
  );
}
