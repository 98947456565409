import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  FiHome,
  FiAlertCircle,
  FiAlertTriangle,
  FiTool,
  FiKey,
  FiUsers,
  FiFileText,
  FiPackage,
  FiDollarSign,
  FiDroplet,
  FiBriefcase,
  FiCreditCard,
} from "react-icons/fi";

function AdminAppsMobile() {
  const navigate = useNavigate();
  const currentUser = useSelector((state) => state.userObject);
  const userRoles = currentUser?.roles || [];

  const apps = [
    {
      appName: "Overview",
      roles: ["admin", "superadmin"],
      icon: <FiHome className="w-5 h-5" />,
      path: "/admin/mobile/overview",
    },
    {
      appName: "Concerns",
      roles: ["admin", "superadmin", "security"],
      icon: <FiAlertCircle className="w-5 h-5" />,
      path: "/admin/mobile/concerns",
    },
    {
      appName: "Incidents",
      roles: ["admin", "superadmin", "security"],
      icon: <FiAlertTriangle className="w-5 h-5" />,
      path: "/admin/mobile/incidents",
    },
    {
      appName: "Maintenance",
      roles: ["admin", "superadmin", "maintenance"],
      icon: <FiTool className="w-5 h-5" />,
      path: "/admin/mobile/maintenance",
    },
    {
      appName: "Gate Pass",
      roles: ["admin", "superadmin", "security"],
      icon: <FiKey className="w-5 h-5" />,
      path: "/admin/mobile/gate-pass",
    },
    {
      appName: "Guests",
      roles: ["admin", "superadmin", "security"],
      icon: <FiUsers className="w-5 h-5" />,
      path: "/admin/mobile/guests",
    },
    {
      appName: "Work Permits",
      roles: ["admin", "superadmin", "maintenance", "security"],
      icon: <FiFileText className="w-5 h-5" />,
      path: "/admin/mobile/work-permits",
    },
    {
      appName: "Parcels",
      roles: ["reception", "housekeeping", "admin", "super-admin"],
      icon: <FiPackage className="w-5 h-5" />,
      path: "/admin/mobile/parcels",
    },
    {
      appName: "Billing",
      roles: ["admin", "super-admin", "finance"],
      icon: <FiDollarSign className="w-5 h-5" />,
      path: "/admin/mobile/billing",
    },
    {
      appName: "Water Reading",
      roles: ["admin", "super-admin", "engineering", "maintenance"],
      icon: <FiDroplet className="w-5 h-5" />,
      path: "/admin/mobile/water-reading",
    },
    {
      appName: "Board",
      roles: ["admin", "super-admin", "board"],
      icon: <FiBriefcase className="w-5 h-5" />,
      path: "/admin/mobile/board",
    },
    {
      appName: "Finance",
      roles: ["admin", "super-admin", "finance"],
      icon: <FiCreditCard className="w-5 h-5" />,
      path: "/admin/mobile/finance",
    },
  ];

  // Filter apps based on user roles
  const filteredApps = apps.filter((app) =>
    app.roles.some((role) => userRoles.includes(role))
  );

  const handleAppClick = (path) => {
    navigate(path);
  };

  return (
    <div className="p-4">
      <h2 className="text-lg font-semibold mb-4 text-[#363062]">
        Applications
      </h2>
      <div className="grid grid-cols-4 gap-3">
        {filteredApps.map((app, index) => (
          <div
            key={index}
            onClick={() => handleAppClick(app.path)}
            className="flex flex-col items-center justify-center bg-white rounded-lg shadow-sm p-2 cursor-pointer hover:shadow-md transition-all"
          >
            <div className="w-10 h-10 flex items-center justify-center text-[#363062] mb-1">
              {app.icon}
            </div>
            <span className="text-xs text-center font-medium text-[#363062]">
              {app.appName}
            </span>
          </div>
        ))}
      </div>
    </div>
  );
}

export default AdminAppsMobile;
