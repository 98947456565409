import React from "react";

export default function CarStickerCard({ application }) {
  const applicationObject = application;
  return (
    <div className="bg-white rounded-lg shadow-md overflow-hidden border border-gray-200 hover:shadow-lg transition-shadow duration-300">
      <div className="p-4">
        <div className="flex justify-between items-start mb-4">
          <h3 className="text-lg font-semibold text-gray-800">
            {application?.carModel || applicationObject.carModel}
          </h3>
          <span
            className={`px-3 py-1 rounded-full text-xs font-medium ${
              (application?.status || applicationObject.status) === "Approved"
                ? "bg-green-100 text-green-800"
                : (application?.status || applicationObject.status) ===
                  "Rejected"
                ? "bg-red-100 text-red-800"
                : "bg-yellow-100 text-yellow-800"
            }`}
          >
            {application?.status || applicationObject.status}
          </span>
        </div>

        <div className="grid grid-cols-2 gap-3 mb-4">
          <div>
            <p className="text-sm text-gray-500">Plate Number</p>
            <p className="font-medium">
              {application?.plateNumber || applicationObject.plateNumber}
            </p>
          </div>
          <div>
            <p className="text-sm text-gray-500">Car Color</p>
            <p className="font-medium">
              {application?.carColor || applicationObject.carColor}
            </p>
          </div>
          <div>
            <p className="text-sm text-gray-500">Car Owner</p>
            <p className="font-medium">
              {application?.carOwner || applicationObject.carOwner}
            </p>
          </div>
          <div>
            <p className="text-sm text-gray-500">Unit</p>
            <p className="font-medium">
              {application?.identifier || applicationObject.identifier}
            </p>
          </div>
        </div>

        <div className="flex space-x-4 mb-3">
          <div className="flex items-center">
            <div
              className={`w-3 h-3 rounded-full mr-2 ${
                application?.Photo || applicationObject.Photo
                  ? "bg-green-500"
                  : "bg-red-500"
              }`}
            ></div>
            <span className="text-xs text-gray-600">
              {application?.Photo || applicationObject.Photo
                ? "Car Photo Uploaded"
                : "No Car Photo"}
            </span>
          </div>
          <div className="flex items-center">
            <div
              className={`w-3 h-3 rounded-full mr-2 ${
                application?.ORCR || applicationObject.ORCR
                  ? "bg-green-500"
                  : "bg-red-500"
              }`}
            ></div>
            <span className="text-xs text-gray-600">
              {application?.ORCR || applicationObject.ORCR
                ? "ORCR Document Uploaded"
                : "No ORCR Document"}
            </span>
          </div>
        </div>

        <div className="text-xs text-gray-500 mt-2">
          Requested: {application?.requestedAt || applicationObject.requestedAt}
        </div>
      </div>
    </div>
  );
}
