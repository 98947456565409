import React, { useEffect, useState } from "react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import toast from "react-hot-toast";
import DayViewCalendar from "./DayViewCalendar";
import {
  addNewDoc,
  getAllDocsSnap,
} from "../../../utils/firebase/Firebase.utils";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { convertTimeStamptoDate } from "../../../utils/EleghoUtils";
import { Room } from "@mui/icons-material";

export default function FunctionRoomForm({ facility }) {
  const { id } = useParams();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    date: new Date(),
    startTime: "9:00",
    endTime: "12:00",
  });
  const currentUser = useSelector((state) => state.userObject);
  const [isProcessing, setIsProcessing] = useState(false);
  const [dateSelected, setDateSelected] = useState(null);
  const [existingBooking, setExistingBooking] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchBookings = async () => {
      await getAllDocsSnap("bookings", (data) => {
        console.log(data);
        const filtered = data.filter((item) => item.bookingType === facility);
        console.log(filtered);

        setExistingBooking(filtered);
        setLoading(false);
      });
    };
    fetchBookings();
  }, []);

  useEffect(() => {
    if (formData.startTime && formData.endTime) {
      if (isOverlapping()) {
        toast.error("Booking is overlapping with an existing booking");
      }
    }
  }, [formData]);

  if (loading) {
    <div>Loading...</div>;
  }

  const handleChangeCalendar = (date) => {
    setDateSelected(date);
    setFormData({ ...formData, date: date });
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  //   check if booking is overlapping to any existing booking
  const isOverlapping = () => {
    const startTime = new Date(formData.date);
    const endTime = new Date(formData.date);
    startTime.setHours(Number(formData.startTime.split(":")[0]));
    startTime.setMinutes(Number(formData.startTime.split(":")[1]));
    endTime.setHours(Number(formData.endTime.split(":")[0]));
    endTime.setMinutes(Number(formData.endTime.split(":")[1]));

    for (let i = 0; i < existingBooking?.length; i++) {
      const existingStart = new Date(
        convertTimeStamptoDate(existingBooking[i].date)
      );
      const existingEnd = new Date(
        convertTimeStamptoDate(existingBooking[i].date)
      );
      existingStart.setHours(
        Number(existingBooking[i].startTime.split(":")[0])
      );
      existingStart.setMinutes(
        Number(existingBooking[i].startTime.split(":")[1])
      );
      existingEnd.setHours(Number(existingBooking[i].endTime.split(":")[0]));
      existingEnd.setMinutes(Number(existingBooking[i].endTime.split(":")[1]));

      if (
        (startTime >= existingStart && startTime < existingEnd) ||
        (endTime > existingStart && endTime <= existingEnd) ||
        (startTime <= existingStart && endTime >= existingEnd)
      ) {
        return true;
      }
    }
    return false;
  };

  const handleBooking = async () => {
    if (isOverlapping()) {
      toast.error("Selected Date and Time is not available for booking");
      return;
    }

    setIsProcessing(true);

    const docObject = {
      ...formData,
      bookingType: facility,
      createdAt: new Date(),
      createdBy: currentUser.uid,
      createdName: currentUser.displayName,
      createdEmail: currentUser.email,
      user: currentUser.email,
      status: "Pending payment",
    };

    await addNewDoc("bookings", docObject);
    toast.success("Booking Successful");
    setIsProcessing(false);
    navigate("/facility-usage");
  };

  return (
    <div className="pt-8 p-4">
      <div className="font-bold text-eleghoBlue p-2 text-center">
        Check Availability and Book
      </div>
      <div className="flex items-center justify-center">
        {dateSelected ? (
          <div>
            <DayViewCalendar
              date={dateSelected}
              bookings={existingBooking}
              setDateSelected={setDateSelected}
              fac
            />
          </div>
        ) : (
          <div>
            <div className="font-bold text-xs px-2">Select Target Date</div>
            <Calendar onChange={handleChangeCalendar} />
          </div>
        )}
      </div>

      <div className="flex items-center justify-center gap-8">
        <div className="mt-2 text-gray-600 text-sm">
          <div className="font-bold">Start Time</div>
          <input
            className="border border-gray-400 text-eleghoGreen rounded-sm p-2"
            type="time"
            defaultValue={"09:00"}
            name="startTime"
            onChange={handleChange}
          />
        </div>
        <div className="mt-2 text-gray-600 text-sm">
          <div className="font-bold">End Time</div>
          <input
            className="border border-gray-400 text-eleghoGreen rounded-sm p-2"
            type="time"
            defaultValue={"12:00"}
            name="endTime"
            onChange={handleChange}
          />
        </div>
      </div>

      <div className="mt-4 text-xs text-gray-600 p-4">
        <div>Fee</div>
        <div>Security Deposit</div>
        <div className="font-bold text-eleghoBlue text-sm">Total</div>
      </div>

      <button
        className="p-4 rounded-2xl bg-eleghoGreen text-white font-bold w-full"
        onClick={handleBooking}
        disabled={isProcessing}
      >
        {isProcessing ? "Booking..." : "Book Now"}
      </button>
    </div>
  );
}
