import React from "react";

function ButtonBlue({ label, type, onClick, className, disabled }) {
  return (
    <button
      className={`w-full px-4 py-3 bg-[#4f46e5] text-white text-base font-medium rounded-lg
        hover:bg-[#4338ca]
        focus:outline-none focus:ring-1 focus:ring-[#4f46e5]
        disabled:opacity-50 disabled:cursor-not-allowed
        ${className}`}
      onClick={onClick}
      type={type || "submit"}
      disabled={disabled}
    >
      {label}
    </button>
  );
}

export default ButtonBlue;
