import React from "react";
import { Line, Bar, Doughnut } from "react-chartjs-2";
import { Chart, registerables } from "chart.js";
import { motion } from "framer-motion";
import {
  FiTrendingUp,
  FiDollarSign,
  FiShield,
  FiAlertCircle,
  FiUsers,
  FiCreditCard,
  FiActivity,
} from "react-icons/fi";
import { useCompany } from "../../context/CompanyContext";
Chart.register(...registerables);

function Analytics() {
  const { getTerm, getCapitalizedTerm } = useCompany();

  // Data for Electricity Usage (Line Chart)
  const lineChartData = {
    labels: ["January", "February", "March", "April", "May", "June"],
    datasets: [
      {
        label: "Electricity Usage (kWh)",
        data: [500, 600, 700, 800, 750, 850],
        backgroundColor: "rgba(54, 48, 98, 0.1)",
        borderColor: "#363062",
        borderWidth: 2,
        tension: 0.4,
        fill: true,
      },
    ],
  };

  // Data for Maintenance Cost (Bar Chart)
  const barChartData = {
    labels: ["Vehicle", "Genset", "Plumbing", "Painting"],
    datasets: [
      {
        label: "Maintenance Cost (₱)",
        data: [12000, 8000, 4000, 7000],
        backgroundColor: "#8FCD00",
        borderColor: "#8FCD00",
        borderWidth: 1,
      },
    ],
  };

  // Data for Security Cost (Line Chart)
  const securityCostData = {
    labels: ["January", "February", "March", "April", "May", "June"],
    datasets: [
      {
        label: "Security Cost (₱)",
        data: [10000, 12000, 13000, 12500, 14000, 13500],
        backgroundColor: "rgba(54, 48, 98, 0.1)",
        borderColor: "#363062",
        borderWidth: 2,
        tension: 0.4,
        fill: true,
      },
    ],
  };

  // Data for Security Incidents (Bar Chart)
  const securityIncidentsData = {
    labels: ["January", "February", "March", "April", "May", "June"],
    datasets: [
      {
        label: "Security Incidents",
        data: [5, 7, 4, 6, 8, 5],
        backgroundColor: "#8FCD00",
        borderColor: "#8FCD00",
        borderWidth: 1,
      },
    ],
  };

  // Data for Personnel Cost (Doughnut Chart)
  const personnelCostData = {
    labels: [
      "Security Personnel",
      "Maintenance Personnel",
      "Administrative Personnel",
    ],
    datasets: [
      {
        label: "Personnel Cost (₱)",
        data: [50000, 35000, 25000],
        backgroundColor: ["#363062", "#8FCD00", "#363062"],
        borderWidth: 1,
      },
    ],
  };

  // Data for Dues Collection (Bar Chart)
  const duesCollectionData = {
    labels: ["January", "February", "March", "April", "May", "June"],
    datasets: [
      {
        label: `${getCapitalizedTerm("owner")} Dues Collected (₱)`,
        data: [15000, 20000, 18000, 19000, 21000, 22000],
        backgroundColor: "#8FCD00",
        borderColor: "#8FCD00",
        borderWidth: 1,
      },
    ],
  };

  // Data for Total Expenses (Bar Chart)
  const totalExpensesData = {
    labels: ["January", "February", "March", "April", "May", "June"],
    datasets: [
      {
        label: "Total Expenses (₱)",
        data: [12000, 17000, 16000, 15000, 18000, 20000],
        backgroundColor: "#363062",
        borderColor: "#363062",
        borderWidth: 1,
      },
    ],
  };

  // Data for Property Types (Doughnut Chart)
  const propertyTypeData = {
    labels: [
      `1 ${getTerm("unit")}`,
      `2 ${getTerm("unit")}`,
      `3 ${getTerm("unit")}`,
      "Studio",
    ],
    datasets: [
      {
        label: `${getCapitalizedTerm("unit")} Types`,
        data: [25, 35, 30, 10],
        backgroundColor: ["#363062", "#4D4C7D", "#8FCD00", "#4F46E5"],
        borderWidth: 1,
      },
    ],
  };

  // Data for Occupancy Rate (Line Chart)
  const occupancyRateData = {
    labels: ["January", "February", "March", "April", "May", "June"],
    datasets: [
      {
        label: "Occupancy Rate (%)",
        data: [65, 70, 75, 78, 80, 82],
        borderColor: "#8FCD00",
        backgroundColor: "rgba(143, 205, 0, 0.1)",
        fill: true,
        tension: 0.3,
      },
    ],
  };

  // Data for Resident vs Tenant Status (Pie Chart)
  const residentStatusData = {
    labels: [
      `${getCapitalizedTerm("owner")}-Occupied`,
      "Tenant-Occupied",
      "Vacant",
    ],
    datasets: [
      {
        label: `${getCapitalizedTerm("unit")} Status`,
        data: [45, 40, 15],
        backgroundColor: ["#363062", "#8FCD00", "#E2E8F0"],
        borderWidth: 1,
      },
    ],
  };

  return (
    <div className="flex-1 p-8 bg-gray-50 max-w-7xl mx-auto">
      <header className="flex justify-between items-center mb-8">
        <div className="text-left">
          <h1 className="page-header">Analytics Dashboard</h1>
          <p className="text-sm text-gray-500 mt-1">
            Overview of your property management metrics
          </p>
        </div>
      </header>

      {/* Stats Overview */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 mb-8">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.1 }}
          className="bg-white p-6 rounded-xl border border-gray-100 shadow-sm hover:shadow-md transition-all duration-200"
        >
          <div className="flex items-center gap-3">
            <div className="p-2 bg-eleghoBlue/10 rounded-lg">
              <FiTrendingUp className="w-5 h-5 text-eleghoBlue" />
            </div>
            <div>
              <div className="text-sm text-gray-600">Electricity Usage</div>
              <div className="text-2xl font-semibold text-eleghoBlue">
                850 kWh
              </div>
            </div>
          </div>
        </motion.div>

        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.2 }}
          className="bg-white p-6 rounded-xl border border-gray-100 shadow-sm hover:shadow-md transition-all duration-200"
        >
          <div className="flex items-center gap-3">
            <div className="p-2 bg-eleghoGreen/10 rounded-lg">
              <FiDollarSign className="w-5 h-5 text-eleghoGreen" />
            </div>
            <div>
              <div className="text-sm text-gray-600">Total Collections</div>
              <div className="text-2xl font-semibold text-eleghoGreen">
                ₱22,000
              </div>
            </div>
          </div>
        </motion.div>

        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.3 }}
          className="bg-white p-6 rounded-xl border border-gray-100 shadow-sm hover:shadow-md transition-all duration-200"
        >
          <div className="flex items-center gap-3">
            <div className="p-2 bg-eleghoBlue/10 rounded-lg">
              <FiShield className="w-5 h-5 text-eleghoBlue" />
            </div>
            <div>
              <div className="text-sm text-gray-600">Security Incidents</div>
              <div className="text-2xl font-semibold text-eleghoBlue">5</div>
            </div>
          </div>
        </motion.div>

        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.4 }}
          className="bg-white p-6 rounded-xl border border-gray-100 shadow-sm hover:shadow-md transition-all duration-200"
        >
          <div className="flex items-center gap-3">
            <div className="p-2 bg-eleghoGreen/10 rounded-lg">
              <FiUsers className="w-5 h-5 text-eleghoGreen" />
            </div>
            <div>
              <div className="text-sm text-gray-600">Total Personnel</div>
              <div className="text-2xl font-semibold text-eleghoGreen">110</div>
            </div>
          </div>
        </motion.div>
      </div>

      {/* Charts Section */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
        {/* Line Chart: Electricity Usage */}
        <motion.div
          initial={{ opacity: 0, scale: 0.95 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ delay: 0.5 }}
          className="bg-white shadow-sm rounded-xl p-6 border border-gray-100"
        >
          <div className="flex items-center justify-between mb-4">
            <h2 className="text-lg font-semibold text-eleghoBlue">
              Electricity Usage
            </h2>
            <FiActivity className="w-5 h-5 text-eleghoBlue/60" />
          </div>
          <Line data={lineChartData} />
        </motion.div>

        {/* Bar Chart: Maintenance Cost */}
        <motion.div
          initial={{ opacity: 0, scale: 0.95 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ delay: 0.6 }}
          className="bg-white shadow-sm rounded-xl p-6 border border-gray-100"
        >
          <div className="flex items-center justify-between mb-4">
            <h2 className="text-lg font-semibold text-eleghoBlue">
              Maintenance Cost
            </h2>
            <FiCreditCard className="w-5 h-5 text-eleghoBlue/60" />
          </div>
          <Bar data={barChartData} />
        </motion.div>

        {/* Line Chart: Security Cost */}
        <motion.div
          initial={{ opacity: 0, scale: 0.95 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ delay: 0.7 }}
          className="bg-white shadow-sm rounded-xl p-6 border border-gray-100"
        >
          <div className="flex items-center justify-between mb-4">
            <h2 className="text-lg font-semibold text-eleghoBlue">
              Security Cost
            </h2>
            <FiShield className="w-5 h-5 text-eleghoBlue/60" />
          </div>
          <Line data={securityCostData} />
        </motion.div>

        {/* Bar Chart: Security Incidents */}
        <motion.div
          initial={{ opacity: 0, scale: 0.95 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ delay: 0.8 }}
          className="bg-white shadow-sm rounded-xl p-6 border border-gray-100"
        >
          <div className="flex items-center justify-between mb-4">
            <h2 className="text-lg font-semibold text-eleghoBlue">
              Security Incidents
            </h2>
            <FiAlertCircle className="w-5 h-5 text-eleghoBlue/60" />
          </div>
          <Bar data={securityIncidentsData} />
        </motion.div>

        {/* Doughnut Chart: Personnel Cost Distribution */}
        <motion.div
          initial={{ opacity: 0, scale: 0.95 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ delay: 0.9 }}
          className="bg-white shadow-sm rounded-xl p-6 border border-gray-100"
        >
          <div className="flex items-center justify-between mb-4">
            <h2 className="text-lg font-semibold text-eleghoBlue">
              Personnel Cost
            </h2>
            <FiUsers className="w-5 h-5 text-eleghoBlue/60" />
          </div>
          <Doughnut data={personnelCostData} />
        </motion.div>

        {/* Bar Chart: Dues Collection vs Total Expenses */}
        <motion.div
          initial={{ opacity: 0, scale: 0.95 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ delay: 1 }}
          className="bg-white shadow-sm rounded-xl p-6 border border-gray-100"
        >
          <div className="flex items-center justify-between mb-4">
            <h2 className="text-lg font-semibold text-eleghoBlue">
              {getCapitalizedTerm("owner")} Dues vs Expenses
            </h2>
            <FiDollarSign className="w-5 h-5 text-eleghoBlue/60" />
          </div>
          <Bar
            data={{
              labels: ["January", "February", "March", "April", "May", "June"],
              datasets: [
                {
                  label: `${getCapitalizedTerm("owner")} Dues Collected (₱)`,
                  data: duesCollectionData.datasets[0].data,
                  backgroundColor: "#8FCD00",
                  borderColor: "#8FCD00",
                  borderWidth: 1,
                },
                {
                  label: "Total Expenses (₱)",
                  data: totalExpensesData.datasets[0].data,
                  backgroundColor: "#363062",
                  borderColor: "#363062",
                  borderWidth: 1,
                },
              ],
            }}
          />
        </motion.div>
      </div>
    </div>
  );
}

export default Analytics;
