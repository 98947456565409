import React, { useEffect } from "react";
import UserScreenWindow from "./UserScreenWindow";
import {
  queryAllDocsByFieldSnap,
  getDocDataSnap,
} from "../../utils/firebase/Firebase.utils";
import { useSelector } from "react-redux";
import BillingCard from "./Billing/BillingCard";
import { Link } from "react-router-dom";
import { FaReceipt, FaCheckCircle, FaClock } from "react-icons/fa";

function Bills() {
  const currentUser = useSelector((state) => state.userObject);

  const [billings, setBillings] = React.useState([]);
  const [identifiers, setIdentifiers] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(true);
  const [activeLink, setActiveLink] = React.useState("Unpaid");

  useEffect(() => {
    const getIdentifiers = async () => {
      const data = await getDocDataSnap("users", currentUser.uid, (data) => {
        setIdentifiers(data.linkedIdentifiers);
      });
    };
    getIdentifiers();
  }, []);

  useEffect(() => {
    const getBillings = async () => {
      const allBillings = [];
      for (const identifier of identifiers) {
        const data = await queryAllDocsByFieldSnap(
          "billings",
          "identifier",
          "==",
          identifier,
          (data) => {
            allBillings.push(...data);
            setBillings(allBillings);
            setIsLoading(false);
          }
        );
      }
    };

    if (identifiers.length > 0) {
      getBillings();
    }
  }, [identifiers]);

  const links = [
    { name: "Unpaid", icon: <FaClock className="mr-2" /> },
    { name: "Paid", icon: <FaCheckCircle className="mr-2" /> },
  ];

  const currentItems = billings.filter((billing) => {
    if (activeLink === "Unpaid") {
      return billing.status !== "paid";
    } else {
      return billing.status === "paid";
    }
  });

  return (
    <UserScreenWindow label="Bills">
      <div className="max-w-4xl mx-auto px-4">
        <div className="flex items-center justify-center space-x-2 mb-8">
          <FaReceipt className="text-eleghoBlue text-2xl" />
          <h1 className="text-2xl font-bold text-eleghoBlue">
            Billing Details
          </h1>
        </div>

        <div className="flex items-center justify-center gap-4 mb-8">
          {links.map((link) => (
            <button
              key={link.name}
              className={`flex items-center px-6 py-2 rounded-full transition-all duration-300 ${
                activeLink === link.name
                  ? "bg-eleghoBlue text-white shadow-lg"
                  : "bg-gray-100 text-gray-600 hover:bg-gray-200"
              }`}
              onClick={() => setActiveLink(link.name)}
            >
              {link.icon}
              <span className="font-medium">{link.name}</span>
            </button>
          ))}
        </div>

        <div className="space-y-4">
          {currentItems.map((billing, index) => (
            <Link
              to={
                billing.status === "paid"
                  ? `/payment-posted/${billing.id}`
                  : `/billing-details/${billing.id}`
              }
              key={index}
              className="block transition-transform hover:scale-[1.02]"
            >
              <BillingCard billing={billing} />
            </Link>
          ))}

          {currentItems.length === 0 &&
            !isLoading &&
            activeLink === "Unpaid" && (
              <div className="text-center py-12 bg-gray-50 rounded-xl">
                <FaCheckCircle className="text-eleghoGreen text-4xl mx-auto mb-4" />
                <h3 className="text-xl font-semibold text-eleghoBlue mb-2">
                  All Caught Up!
                </h3>
                <p className="text-gray-600">
                  Congratulations! You have no pending bills.
                </p>
              </div>
            )}
        </div>
      </div>
    </UserScreenWindow>
  );
}

export default Bills;
