import React, { useEffect, useState } from "react";
import {
  getAllDocsSnap,
  addNewDoc,
  storage,
  deleteDocById,
  setDocData,
} from "../../utils/firebase/Firebase.utils";
import Modal from "./Components/Modal";
import InputBox from "./Components/InputBox";
import Label from "./Components/Label";
import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";
import toast from "react-hot-toast";
import PageModal from "./Components/PageModal";
import SupplierInformationPage from "./Suppliers/SupplierInformationPage";
import { useSelector } from "react-redux";
import SelectInput from "./Components/SelectInput";
import NoLogo from "../assets/nologo.gif";
import { v4 } from "uuid";
import ModalVariant2 from "./Components/ModalVariant2";
import GridViewIcon from "@mui/icons-material/GridView";
import ViewListIcon from "@mui/icons-material/ViewList";
import { motion, AnimatePresence } from "framer-motion";
import { IoMdAdd } from "react-icons/io";
import { FiEdit2 } from "react-icons/fi";
import { IoCheckmarkDone } from "react-icons/io5";

function Services() {
  const currentUser = useSelector((state) => state.userObject);
  const [activeService, setActiveService] = React.useState("");

  const [suppliers, setSuppliers] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [inProcess, setInProcess] = React.useState(false);
  const [addMode, setAddMode] = React.useState(false);
  const [progress, setProgress] = React.useState(0);
  const [formData, setFormData] = React.useState({});
  const [selectedSupplier, setSelectedSupplier] = useState({});
  const [viewMode, setViewMode] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [uploadingInProgress, setUploadingInProgress] = useState(false);
  const [supplierEdit, setSupplierEdit] = useState(false);
  const [isListView, setIsListView] = useState(true);

  const categoryOptions = [
    { value: "delivery", label: "Delivery" },
    { value: "services", label: "Services / Installation" },
    { value: "grocery", label: "Grocery and Pharmacy" },
    { value: "restaurants", label: "Restaurants" },
    { value: "medical", label: "Hospital and Medical Services" },
  ];

  useEffect(() => {
    const fetchSuppliers = async () => {
      await getAllDocsSnap("suppliers", (data) => {
        //sort suppliers by name
        data.sort((a, b) => a.name.localeCompare(b.name));

        setSuppliers(data);
        setLoading(false);
      });
    };
    fetchSuppliers();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  // const services = ["laundry", "cleaning", "water"];
  const services = [];

  const activeServiceStyle = "bg-green-500  ";

  const handleAddMode = () => {
    setAddMode(!addMode);
    setProgress(0);
    setFormData({});
    setSupplierEdit(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setInProcess(true);

    if (supplierEdit) {
      const docObject = {
        ...formData,
        updatedBy: currentUser.email,
        updatedAt: new Date().toLocaleString(),
      };
      await setDocData("suppliers", formData.id, docObject);
      toast.success("Supplier updated successfully");
      setSupplierEdit(false);
      setInProcess(false);
      setAddMode(false);
      return;
    }

    await addNewDoc("suppliers", formData);
    toast.success("Supplier added successfully");
    setInProcess(false);
    setAddMode(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleUpload = async (e) => {
    const file = e.target.files[0];
    const storageRef = ref(storage, `suppliers/${file.name} + ${v4()}`);
    const uploadTask = uploadBytesResumable(storageRef, file);
    setUploadingInProgress(true);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setProgress(progress);
      },
      (error) => {},
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          setFormData((prev) => ({ ...prev, logo: downloadURL }));
        });

        setUploadingInProgress(false);
      }
    );
  };

  const handleServices = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, services: [value] }));
  };

  const handleViewMode = (selected) => {
    if (editMode) {
      return;
    }

    setSelectedSupplier(selected);
    setViewMode(!viewMode);
  };

  const filteredItems = suppliers.filter(
    (item) =>
      item.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      item.services.some((service) =>
        service.toLowerCase().includes(searchTerm.toLowerCase())
      )
  );

  const handleEditMode = () => {
    setEditMode(!editMode);
  };

  const handleDelete = async (id) => {
    if (currentUser.roles.includes("admin")) {
      if (window.confirm("Are you sure you want to delete this supplier?")) {
        await deleteDocById("suppliers", id);
        toast.success("Supplier deleted successfully");
      }
    } else {
      toast.error("You are not authorized for a delete function");
    }
  };

  const handleEditSupplier = (supplier) => {
    setSupplierEdit(true);
    setFormData(supplier);
    setAddMode(true);
  };

  return (
    <div className="text-blue-900 p-2 max-w-7xl mx-auto mt-8">
      <div className="flex items-center justify-between">
        <div className="page-header ">Accredited Suppliers</div>
        <div className="flex items-center justify-normal gap-4"></div>

        <div className="flex items-center gap-2 mb-6">
          <button
            className="blue-button flex items-center gap-2"
            onClick={handleAddMode}
          >
            <IoMdAdd className="text-xl" />
            <span>Add</span>
          </button>
          <button
            className="blue-button flex items-center gap-2"
            onClick={handleEditMode}
          >
            {editMode ? (
              <>
                <IoCheckmarkDone className="text-xl" />
                <span>Done</span>
              </>
            ) : (
              <>
                <FiEdit2 className="text-xl" />
                <span>Edit</span>
              </>
            )}
          </button>
        </div>
      </div>
      <div className="flex items-center justify-between border border-gray-300 px-4 py-2 text-xs">
        <div className="flex items-center justify-normal rounded-lg border border-gray-300 divide-x">
          {services.map((service, index) => (
            <div
              key={index}
              className={`p-2 cursor-pointer min-w-20 capitalize ${
                activeService === service ? activeServiceStyle : ""
              }`}
              onClick={() => setActiveService(service)}
            >
              {service}
            </div>
          ))}
        </div>
        <div className="flex items-center gap-4">
          <div className="flex items-center gap-2">
            <button
              className={`p-2 rounded-lg ${
                !isListView ? "bg-eleghoBlue text-white" : "bg-gray-100"
              }`}
              onClick={() => setIsListView(false)}
            >
              <GridViewIcon />
            </button>
            <button
              className={`p-2 rounded-lg ${
                isListView ? "bg-eleghoBlue text-white" : "bg-gray-100"
              }`}
              onClick={() => setIsListView(true)}
            >
              <ViewListIcon />
            </button>
          </div>
          <input
            type="search"
            placeholder="Search by Name, or Service"
            className="border border-gray-300 w-96 rounded-lg px-4 py-2"
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <div>Filter</div>
        </div>
      </div>

      {isListView ? (
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -20 }}
          transition={{ duration: 0.3 }}
          className="mt-8 bg-white rounded-xl border border-eleghoBlue/20 overflow-hidden"
        >
          <table className="w-full">
            <thead className="bg-eleghoBlue/5">
              <tr>
                <th className="px-6 py-3 text-left text-xs font-medium text-eleghoBlue uppercase tracking-wider">
                  Supplier
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-eleghoBlue uppercase tracking-wider">
                  Services
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-eleghoBlue uppercase tracking-wider">
                  Contact
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-eleghoBlue uppercase tracking-wider">
                  Address
                </th>
                {editMode && (
                  <th className="px-6 py-3 text-left text-xs font-medium text-eleghoBlue uppercase tracking-wider">
                    Actions
                  </th>
                )}
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200">
              {filteredItems.map((supplier, index) => (
                <motion.tr
                  key={index}
                  initial={{ opacity: 0, x: -20 }}
                  animate={{ opacity: 1, x: 0 }}
                  transition={{ delay: index * 0.05 }}
                  className="hover:bg-eleghoBlue/5 cursor-pointer"
                  onClick={() => handleViewMode(supplier)}
                  whileHover={{ scale: 1.01 }}
                >
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div className="flex items-center">
                      <img
                        src={supplier.logo || NoLogo}
                        className="h-10 w-10 object-contain mr-3"
                        alt={supplier.name}
                      />
                      <div className="text-sm font-medium text-eleghoBlue">
                        {supplier.name}
                      </div>
                    </div>
                  </td>
                  <td className="px-6 py-4">
                    <div className="flex flex-wrap gap-1">
                      {supplier.services.map((service, idx) => (
                        <span
                          key={idx}
                          className="px-2 py-1 bg-eleghoBlue/10 text-eleghoBlue rounded-full text-xs font-medium"
                        >
                          {service}
                        </span>
                      ))}
                    </div>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div className="text-sm text-eleghoBlue/80">
                      {supplier.phone}
                    </div>
                    <div className="text-sm text-gray-500">
                      {supplier.email}
                    </div>
                  </td>
                  <td className="px-6 py-4">
                    <div className="text-sm text-gray-500">
                      {supplier.address || "Not indicated"}
                    </div>
                  </td>
                  {editMode && (
                    <td className="px-6 py-4 whitespace-nowrap text-sm">
                      <div className="flex items-center gap-2">
                        <button
                          className="green-button"
                          onClick={(e) => {
                            e.stopPropagation();
                            handleEditSupplier(supplier);
                          }}
                        >
                          Edit
                        </button>
                        <button
                          className="red-button"
                          onClick={(e) => {
                            e.stopPropagation();
                            handleDelete(supplier.id);
                          }}
                        >
                          Delete
                        </button>
                      </div>
                    </td>
                  )}
                </motion.tr>
              ))}
            </tbody>
          </table>
        </motion.div>
      ) : (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.3 }}
          className="flex items-start lg:justify-center gap-6 mt-8 flex-wrap justify-center"
        >
          {filteredItems.map((supplier, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: index * 0.1 }}
              whileHover={{
                scale: 1.05,
                boxShadow: "0 10px 30px rgba(0,0,0,0.1)",
              }}
              className="relative border border-eleghoBlue/20 w-72 rounded-xl bg-white flex flex-col items-center p-6 text-sm text-gray-600 hover:shadow-lg hover:border-eleghoBlue transition-all duration-300 cursor-pointer group"
              onClick={() => handleViewMode(supplier)}
            >
              {editMode && (
                <div className="absolute inset-0 bg-black/30 rounded-xl z-10" />
              )}
              {editMode && (
                <motion.div
                  initial={{ opacity: 0, y: 10 }}
                  animate={{ opacity: 1, y: 0 }}
                  className="absolute bottom-4 flex items-center gap-4 z-20"
                >
                  <motion.button
                    whileHover={{ scale: 1.05, backgroundColor: "#22c55e" }}
                    whileTap={{ scale: 0.95 }}
                    className="px-4 py-2 bg-green-500 text-white rounded-full font-medium shadow-lg hover:shadow-xl transition-all duration-300 flex items-center gap-2"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleEditSupplier(supplier);
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-4 w-4"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
                      />
                    </svg>
                    Edit
                  </motion.button>
                  <motion.button
                    whileHover={{ scale: 1.05, backgroundColor: "#ef4444" }}
                    whileTap={{ scale: 0.95 }}
                    className="px-4 py-2 bg-red-500 text-white rounded-full font-medium shadow-lg hover:shadow-xl transition-all duration-300 flex items-center gap-2"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleDelete(supplier.id);
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-4 w-4"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                      />
                    </svg>
                    Delete
                  </motion.button>
                </motion.div>
              )}
              <motion.div
                className="mb-4"
                whileHover={{ scale: 1.1 }}
                transition={{ type: "spring", stiffness: 300 }}
              >
                <img
                  src={supplier.logo || NoLogo}
                  className="w-28 h-28 object-contain"
                  alt={supplier.name}
                />
              </motion.div>
              <motion.div
                className="text-lg font-semibold text-eleghoBlue mb-2"
                whileHover={{ scale: 1.05 }}
              >
                {supplier.name}
              </motion.div>
              <motion.div
                className="text-eleghoBlue/80 font-medium mb-3"
                whileHover={{ scale: 1.05 }}
              >
                {supplier.phone}
              </motion.div>
              <motion.div
                className="flex flex-wrap gap-2 justify-center"
                layout
              >
                {supplier.services.map((service, index) => (
                  <motion.span
                    key={index}
                    initial={{ opacity: 0, scale: 0.8 }}
                    animate={{ opacity: 1, scale: 1 }}
                    transition={{ delay: index * 0.1 }}
                    whileHover={{ scale: 1.1 }}
                    className="px-3 py-1 bg-eleghoBlue/10 text-eleghoBlue rounded-full text-xs font-medium"
                  >
                    {service}
                  </motion.span>
                ))}
              </motion.div>
              {supplier.address && supplier.address !== "not indicated" && (
                <motion.div
                  className="mt-3 text-gray-500 text-xs text-center"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ delay: 0.2 }}
                >
                  {supplier.address}
                </motion.div>
              )}
            </motion.div>
          ))}
        </motion.div>
      )}

      <AnimatePresence>
        {viewMode && (
          <PageModal>
            <motion.div
              initial={{ opacity: 0, scale: 0.9 }}
              animate={{ opacity: 1, scale: 1 }}
              exit={{ opacity: 0, scale: 0.9 }}
              transition={{ duration: 0.2 }}
            >
              <SupplierInformationPage
                data={selectedSupplier}
                setViewMode={setViewMode}
              />
            </motion.div>
          </PageModal>
        )}
      </AnimatePresence>

      <AnimatePresence>
        {addMode && (
          <div>
            <ModalVariant2>
              <motion.div
                className="relative max-w-2xl mx-auto bg-white rounded-xl shadow-lg"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -20 }}
                transition={{ duration: 0.3 }}
              >
                <button
                  type="button"
                  className="absolute top-4 right-4 text-gray-400 hover:text-eleghoBlue transition-colors"
                  onClick={handleAddMode}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-5 w-5"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>

                <div className="w-full">
                  <form onSubmit={handleSubmit} className="space-y-6">
                    <div className="blue-gradient">
                      <div className="text-2xl font-bold text-white mb-8">
                        {supplierEdit ? "Update Supplier" : "Add New Supplier"}
                      </div>
                    </div>

                    <div className="grid grid-cols-1 md:grid-cols-2 gap-6 p-4">
                      <InputBox
                        label="Name"
                        type="text"
                        name="name"
                        placeholder="Enter supplier name"
                        onChange={handleChange}
                        required
                        value={formData.name}
                      />

                      <div>
                        <SelectInput
                          label="Category"
                          name="category"
                          placeholder="Select category"
                          className="w-full text-left"
                          required
                          options={categoryOptions}
                          onChange={(e) =>
                            setFormData({ ...formData, category: e.value })
                          }
                          value={categoryOptions.find(
                            (option) => option.value === formData.category
                          )}
                        />
                      </div>

                      <InputBox
                        label="Type of Service"
                        type="text"
                        name="services"
                        placeholder="Enter service type"
                        onChange={handleServices}
                        required
                        value={formData.services}
                      />

                      <InputBox
                        label="Email Address"
                        type="email"
                        name="email"
                        placeholder="Enter email address"
                        onChange={handleChange}
                        value={formData.email}
                      />

                      <InputBox
                        label="Website"
                        type="url"
                        name="website"
                        placeholder="Enter website URL"
                        onChange={handleChange}
                        value={formData.website}
                      />

                      <InputBox
                        label="Phone"
                        type="text"
                        name="phone"
                        placeholder="Enter phone number"
                        onChange={handleChange}
                        required
                        value={formData.phone}
                      />

                      <div className="md:col-span-2">
                        <InputBox
                          label="Address"
                          type="text"
                          name="address"
                          placeholder="Enter complete address"
                          onChange={handleChange}
                          value={formData.address}
                        />
                      </div>

                      <div className="md:col-span-2">
                        <Label label="Company Logo">
                          <div className="flex items-center gap-4">
                            <div className="flex-1">
                              <input
                                type="file"
                                name="logo"
                                accept="image/*"
                                onChange={handleUpload}
                                className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-eleghoBlue/50"
                              />
                              {progress > 0 && progress < 100 && (
                                <div className="mt-2 h-2 bg-gray-200 rounded-full">
                                  <div
                                    className="h-full bg-eleghoBlue rounded-full transition-all duration-300"
                                    style={{ width: `${progress}%` }}
                                  />
                                </div>
                              )}
                            </div>
                            <img
                              src={formData.logo || NoLogo}
                              alt="Logo preview"
                              className="h-20 w-20 object-contain rounded-lg border border-gray-200"
                            />
                          </div>
                        </Label>
                      </div>
                    </div>

                    <div className="flex justify-end p-4">
                      <button
                        type="submit"
                        disabled={uploadingInProgress || inProcess}
                        className="px-6 py-2 bg-eleghoBlue text-white rounded-lg hover:bg-eleghoBlue/90 disabled:opacity-50 disabled:cursor-not-allowed transition duration-200"
                      >
                        {supplierEdit ? "Update Supplier" : "Add Supplier"}
                      </button>
                    </div>
                  </form>
                </div>
              </motion.div>
            </ModalVariant2>
          </div>
        )}
      </AnimatePresence>
    </div>
  );
}

export default Services;
