import React, { useEffect } from "react";
import {
  getDocDataSnap,
  deleteDocById,
  setDocData,
  getAllDocsSnap,
} from "../../utils/firebase/Firebase.utils";
import InputBox from "../Dashboard/Components/InputBox";
import Select from "react-select";
import toast from "react-hot-toast";
import {
  departmentOptions as departments,
  userRoles,
} from "../../utils/EleghoUtils";

function UserDetails({ id, setViewMode }) {
  const [formData, setFormData] = React.useState({});
  const [user, setUser] = React.useState({});
  const [isLoading, setIsLoading] = React.useState(true);
  const [disabled, setDisabled] = React.useState(true);
  const [editMode, setEditMode] = React.useState(false);
  const [isProcessing, setIsProcessing] = React.useState(false);
  const [residentOptions, setResidentOptions] = React.useState([]);

  useEffect(() => {
    const fetchUser = async () => {
      const user = await getDocDataSnap("users", id, (user) => {
        setUser(user);
        setFormData(user);
        setIsLoading(false);
      });
    };
    fetchUser();
  }, []);

  //get all residents
  useEffect(() => {
    const fetchResidents = async () => {
      const residents = getAllDocsSnap("residents", (residents) => {
        const options = residents.map((resident) => {
          return {
            value: resident.identifier,
            label: resident.owner1 + " " + resident.identifier,
          };
        });
        setResidentOptions(options);
      });
    };
    fetchResidents();
  }, []);

  if (isLoading) {
    return (
      <div>
        <div>Loading...</div>
      </div>
    );
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const options = userRoles;

  const departmentOptions = departments;

  const handleEdit = () => {
    setEditMode(!editMode);
    setDisabled(!disabled);
  };

  const handleDelete = async () => {
    if (window.confirm("Are you sure you want to delete this user?")) {
      await deleteDocById("users", id);
      toast.success("User deleted successfully");
    }
    setViewMode(false);
  };

  const handleUpdate = async () => {
    setIsProcessing(true);
    await setDocData("users", id, formData);
    setIsProcessing(false);
    setEditMode(false);
    setDisabled(true);
    toast.success("User updated successfully");
  };

  const handleSelect = (selectedOption, name) => {
    let arrayValues = [];
    selectedOption.map((option) => {
      arrayValues.push(option.value);
    });
    setFormData({ ...formData, [name]: arrayValues });
  };
  return (
    <div className="w-[350px] min-h-[500px] small-form relative">
      <div className="close-button">
        <button onClick={() => setViewMode(false)}>Close</button>
      </div>
      <div className="font-bold uppercase ">User Detailed Information</div>
      <div className="flex items-center gap-4 mb-8">
        <button className="underline" onClick={handleEdit}>
          {editMode ? "Cancel Edit" : "Edit"}
        </button>
        <button className="underline" onClick={handleDelete}>
          Delete
        </button>
      </div>
      <div className="flex flex-col gap-4">
        <InputBox
          name="displayName"
          value={formData.displayName}
          label="Name"
          onChange={handleChange}
          disabled={disabled}
        />
        <InputBox
          name="email"
          value={formData.email}
          label="Email"
          onChange={handleChange}
          disabled={disabled}
        />

        <div className="text-left">
          <label className=" text-blue-900 text-xs uppercase font-bold">
            Department
          </label>
          <Select
            options={departmentOptions}
            value={
              formData.department &&
              departmentOptions.find(
                (option) => option.value === formData.department
              )
            }
            onChange={(value) =>
              setFormData({ ...formData, department: value.value })
            }
            isDisabled={disabled}
          />
        </div>

        <div className="text-left">
          <label className=" text-blue-900 text-xs uppercase font-bold">
            Roles
          </label>
          <Select
            options={options}
            isMulti
            value={
              formData.roles &&
              formData.roles.map((role) => {
                return options.find((option) => option.value === role);
              })
            }
            onChange={(selectedOption) => handleSelect(selectedOption, "roles")}
            isDisabled={disabled}
          />
        </div>

        <div className="text-left">
          <label className=" text-blue-900 text-xs uppercase font-bold">
            Unit Number
          </label>
          <Select
            options={residentOptions}
            isMulti
            value={
              formData.linkedIdentifiers &&
              formData.linkedIdentifiers.map((value) => {
                return residentOptions.find((option) => option.value === value);
              })
            }
            onChange={(selectedOption) =>
              handleSelect(selectedOption, "linkedIdentifiers")
            }
            isDisabled={disabled}
          />
        </div>
        <div>
          {editMode && (
            <button
              className="bg-blue-900 text-white px-4 py-2 rounded-lg"
              onClick={handleUpdate}
            >
              {isProcessing ? "Processing..." : "Save Changes"}
            </button>
          )}
        </div>
      </div>
    </div>
  );
}

export default UserDetails;
