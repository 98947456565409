import React, { useEffect } from "react";
import { queryAllDocsByFieldSnap } from "../utils/firebase/Firebase.utils";

export default function DisplayAvatar({ email, displayName = "User" }) {
  const [userPhoto, setUserPhoto] = React.useState(null);
  const [loading, setLoading] = React.useState(true);

  useEffect(() => {
    const fetchUserPhotos = async () => {
      await queryAllDocsByFieldSnap("users", "email", "==", email, (doc) => {
        setUserPhoto(doc[0].photoUrl);
        console.log(doc.photoUrl);
        setLoading(false);
      });
    };
    fetchUserPhotos();
  }, []);

  const photoURL = userPhoto;

  // Function to get initials from displayName
  const getInitials = (name) => {
    if (!name) return "";
    const names = name.split(" ");
    return names.map((n) => n.charAt(0).toUpperCase()).join("");
  };

  return (
    <div className="flex items-center">
      <div className="w-10 h-10 bg-eleghoGreen rounded-full overflow-hidden flex items-center justify-center">
        {photoURL ? (
          <img
            src={photoURL}
            alt={displayName}
            className="w-full h-full object-cover"
          />
        ) : (
          <span className="text-xs font-bold text-white">
            {getInitials(displayName)}
          </span>
        )}
      </div>
    </div>
  );
}
