import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  setDocData,
  getDocDataSnap,
  storage,
} from "../../../utils/firebase/Firebase.utils";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import NoPhoto from "../../assets/blankphoto.webp";
import InputBox from "../Components/InputBox";
import ResidentOccupantInformationPage from "./ResidentOccupantInformationPage";
import ResidentOccupantDocuments from "./ResidentOccupantDocuments";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import {
  IoClose,
  IoCameraOutline,
  IoPencilOutline,
  IoTrashOutline,
} from "react-icons/io5";
import {
  FaRegUser,
  FaIdCard,
  FaPhone,
  FaEnvelope,
  FaMapMarkerAlt,
  FaBirthdayCake,
  FaFileAlt,
} from "react-icons/fa";
import { AnimatePresence, motion } from "framer-motion";

export default function ResidentOccupantDetails({
  setViewMode,
  occupantId,
  occupant,
  occupants,
  isAccordion = false,
  isExpanded = false,
}) {
  const [editMode, setEditMode] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [isUploading, setIsUploading] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [previewUrl, setPreviewUrl] = useState(occupant?.primaryPhoto || null);
  const [isLoading, setIsLoading] = useState(true);
  const { id } = useParams();
  const currentUser = useSelector((state) => state.userObject);
  const isAdmin = currentUser?.roles?.includes("admin");
  const navigate = useNavigate();

  // Update preview URL when occupant changes
  useEffect(() => {
    setPreviewUrl(occupant?.primaryPhoto || null);
  }, [occupant]);

  useEffect(() => {
    const fetchOccupants = async () => {
      await getDocDataSnap("residents", id, (data) => {
        const currentOccupant = data.occupantsName?.find(
          (o) => o.id === occupantId
        );
        if (currentOccupant?.primaryPhoto) {
          setPreviewUrl(currentOccupant.primaryPhoto);
        }
        setIsLoading(false);
      });
    };
    fetchOccupants();
  }, [id, occupantId]);

  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      // Set preview
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewUrl(reader.result);
      };
      reader.readAsDataURL(file);

      // Upload to Firebase
      setIsUploading(true);
      const storageRef = ref(
        storage,
        `occupants/${id}/${occupantId}/${file.name}`
      );
      const uploadTask = uploadBytesResumable(storageRef, file);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );
          setUploadProgress(progress);
        },
        (error) => {
          setIsUploading(false);
          toast.error("Error uploading photo");
          console.error("Upload error:", error);
        },
        async () => {
          try {
            const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);

            // Update the occupant's document with the new photo URL
            await setDocData("residents", id, {
              occupantsName: occupants.map((o) =>
                o.id === occupantId ? { ...o, primaryPhoto: downloadURL } : o
              ),
            });

            setPreviewUrl(downloadURL); // Update the preview URL with the final URL
            setIsUploading(false);
            toast.success("Photo uploaded successfully");
          } catch (error) {
            setIsUploading(false);
            toast.error("Error saving photo URL");
            console.error("Save error:", error);
          }
        }
      );
    }
  };

  const handleDelete = async () => {
    if (window.confirm("Are you sure you want to delete this occupant?")) {
      await setDocData("residents", id, {
        occupantsName: occupants.filter((o) => o.id !== occupantId),
      });
      setViewMode(false);
      toast.success("Occupant deleted successfully");
    }
  };

  if (isLoading) {
    return (
      <div className="flex items-center justify-center p-8">
        <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-eleghoBlue"></div>
      </div>
    );
  }

  return (
    <div className="bg-white rounded-lg border border-gray-100">
      {/* Header Section - Only show when accordion is closed */}
      {!isExpanded && (
        <div className="relative p-3 border-b border-gray-100">
          <div className="flex items-center gap-3">
            <div className="relative">
              <div className="w-12 h-12 rounded-full overflow-hidden bg-gradient-to-br from-eleghoBlue/20 to-eleghoBlue/10">
                {previewUrl ? (
                  <img
                    src={previewUrl}
                    alt={occupant?.firstName}
                    className="w-full h-full object-cover"
                  />
                ) : (
                  <div className="w-full h-full flex items-center justify-center">
                    <FaRegUser className="w-6 h-6 text-eleghoBlue/40" />
                  </div>
                )}
              </div>
              {editMode && (
                <label className="absolute bottom-0 right-0 p-1 bg-white rounded-full shadow-sm border border-gray-100 cursor-pointer hover:bg-gray-50 transition-colors">
                  <input
                    type="file"
                    accept="image/*"
                    onChange={handleFileChange}
                    className="hidden"
                  />
                  <IoCameraOutline className="w-3.5 h-3.5 text-gray-600" />
                </label>
              )}
            </div>
            <div className="flex-1 min-w-0">
              <div className="flex items-center gap-2">
                <h2 className="text-sm font-medium text-gray-900 truncate">
                  {occupant?.firstName} {occupant?.lastName}
                </h2>
                {isAdmin && (
                  <button
                    onClick={handleDelete}
                    className="p-1 text-red-500 hover:bg-red-50 rounded-lg transition-colors"
                  >
                    <IoTrashOutline className="w-3.5 h-3.5" />
                  </button>
                )}
              </div>
              <p className="text-xs text-gray-500">
                {occupant?.relationship || "No relationship specified"}
              </p>
            </div>
            {!isAccordion && (
              <button
                onClick={setViewMode}
                className="p-1 text-gray-400 hover:text-gray-600 rounded-lg hover:bg-gray-100 transition-colors"
              >
                <IoClose className="w-5 h-5" />
              </button>
            )}
          </div>
        </div>
      )}

      {/* Content */}
      <div className="p-3">
        <div className="flex items-start gap-4">
          {/* Profile Photo Section */}
          <div className="relative">
            {editMode && (
              <div className="w-20 h-20 rounded-full overflow-hidden bg-gradient-to-br from-eleghoBlue/20 to-eleghoBlue/10">
                {previewUrl ? (
                  <img
                    src={previewUrl}
                    alt={occupant?.firstName}
                    className="w-full h-full object-cover"
                  />
                ) : (
                  <div className="w-full h-full flex items-center justify-center">
                    <FaRegUser className="w-8 h-8 text-eleghoBlue/40" />
                  </div>
                )}
              </div>
            )}
            {editMode && (
              <label className="absolute bottom-0 right-0 p-1.5 bg-white rounded-full shadow-sm border border-gray-100 cursor-pointer hover:bg-gray-50 transition-colors">
                <input
                  type="file"
                  accept="image/*"
                  onChange={handleFileChange}
                  className="hidden"
                />
                <IoCameraOutline className="w-4 h-4 text-gray-600" />
              </label>
            )}
            {isUploading && (
              <div className="absolute inset-0 flex items-center justify-center bg-black/50 rounded-full">
                <div className="text-[10px] text-white font-medium">
                  {uploadProgress}%
                </div>
              </div>
            )}
          </div>

          {/* Edit Button */}
          <div className="flex-1 flex justify-end">
            <button
              onClick={() => setEditMode(!editMode)}
              className="flex items-center gap-1.5 px-2 py-1 text-xs font-medium text-eleghoBlue bg-eleghoBlue/10 rounded-lg hover:bg-eleghoBlue/20 transition-colors"
            >
              {editMode ? (
                <>
                  <IoClose className="w-3 h-3" />
                  Cancel Editing
                </>
              ) : (
                <>
                  <IoPencilOutline className="w-3 h-3" />
                  Edit Information
                </>
              )}
            </button>
          </div>
        </div>

        {isLoading ? (
          <div className="flex items-center justify-center py-8">
            <div className="w-6 h-6 border-2 border-eleghoBlue/20 border-t-eleghoBlue rounded-full animate-spin" />
          </div>
        ) : (
          <div className="grid grid-cols-2 gap-4 mt-4">
            {/* Left Side - Information */}
            <div className="space-y-3">
              <div className="flex items-center gap-2 pb-2 border-b border-gray-100">
                <div className="flex items-center gap-2">
                  <div className="p-1 rounded-lg bg-eleghoBlue/10">
                    <FaIdCard className="w-3.5 h-3.5 text-eleghoBlue" />
                  </div>
                  <div>
                    <h3 className="text-xs font-semibold text-gray-900">
                      Personal Information
                    </h3>
                    <p className="text-[10px] text-gray-500">
                      View and edit occupant details
                    </p>
                  </div>
                </div>
              </div>
              <ResidentOccupantInformationPage
                occupantId={occupantId}
                occupant={occupant}
                editMode={editMode}
                onEditModeChange={setEditMode}
              />
            </div>

            {/* Right Side - Documents */}
            <div className="space-y-3">
              <div className="flex items-center gap-2 pb-2 border-b border-gray-100">
                <div className="flex items-center gap-2">
                  <div className="p-1 rounded-lg bg-eleghoBlue/10">
                    <FaFileAlt className="w-3.5 h-3.5 text-eleghoBlue" />
                  </div>
                  <div>
                    <h3 className="text-xs font-semibold text-gray-900">
                      Documents
                    </h3>
                    <p className="text-[10px] text-gray-500">
                      View and manage occupant documents
                    </p>
                  </div>
                </div>
              </div>
              <ResidentOccupantDocuments
                occupantId={occupantId}
                occupant={occupant}
                editMode={editMode}
                onEditModeChange={setEditMode}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
