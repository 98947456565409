import React, { useEffect } from "react";
import UserScreenWindow from "../UserScreenWindow";
import { FaDoorOpen } from "react-icons/fa";
import InputBoxVar2 from "./InputBoxVar2";
import CharacterCounter from "./CharacterCounter";
import {
  convertToNumber,
  handleUploadMultiple,
} from "../../../utils/EleghoUtils";
import { IoIosBed } from "react-icons/io";
import { FaBath } from "react-icons/fa6";
import { MdPhotoSizeSelectSmall } from "react-icons/md";
import { CiSquarePlus } from "react-icons/ci";
import { FaCloudArrowUp } from "react-icons/fa6";
import { useRef } from "react";
import toast from "react-hot-toast";
import { addNewDoc } from "../../../utils/firebase/Firebase.utils";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

function NewListingProperty() {
  const currentUser = useSelector((state) => state.userObject);
  const navigate = useNavigate();
  const categories = ["For Rent", "For Sale"];
  const [activeCategory, setActiveCategory] = React.useState("For Rent");
  const [characterCount, setCharacterCount] = React.useState(0);
  const [formData, setFormData] = React.useState({});
  const [bedRooms, setBedRooms] = React.useState("Studio");
  const [bathRooms, setBathRooms] = React.useState("1");
  const [isProcessing, setIsProcessing] = React.useState(false);

  const photoRef = useRef();

  const roomsArray = ["Studio", "1", "2", "3", "4", "5+"];
  const bathRoomsArray = ["1", "2", "3"];

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const countCharacters = (value) => {
    return value.length;
  };

  const handlePropertyTitle = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setCharacterCount(e.target.value.length);
  };

  const formatNumber = (num) => {
    return num.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const handleNumberChange = (e) => {
    const { name, value } = e.target;
    const numericValue = e.target.value.replace(/[^0-9.]/g, "");
    setFormData({ ...formData, [name]: formatNumber(numericValue) });
  };

  const handleUpload = async (e) => {
    await handleUploadMultiple(
      e,
      formData,
      setFormData,
      "propertyImages",
      "images"
    );
    toast.success("Images Uploaded Successfully");
  };

  const handleRemove = (index) => {
    //remove the photo from the formData with the index number
    const newPhotos = formData.images.filter((photo, i) => i !== index);
    setFormData({ ...formData, images: newPhotos });
  };

  const checkIfAllFieldsAreFilled = () => {
    const { propertyTitle, monthlyRent, unitNumber, size, description } =
      formData;
    if (
      !propertyTitle ||
      !monthlyRent ||
      !unitNumber ||
      !size ||
      !description
    ) {
      return false;
    }
    return true;
  };

  const checkIfImageIsUploaded = () => {
    //check if at least one image is uploaded
    if (formData.images && formData.images.length > 0) {
      return true;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsProcessing(true);
    if (!checkIfAllFieldsAreFilled()) {
      toast.error("Please fill in all fields");
      setIsProcessing(false);
      return;
    }

    if (!checkIfImageIsUploaded()) {
      toast.error("Please upload at least one image");
      setIsProcessing(false);
    }

    const docObject = {
      ...formData,
      bedRooms,
      bathRooms,
      category: "Property",
      type: activeCategory,
      createdAt: new Date().toLocaleString(),
      createdBy: currentUser.email,
      createdName: currentUser.displayName,
    };

    await addNewDoc("properties", docObject);
    toast.success("Property Added Successfully");
    setIsProcessing(false);
    navigate("/marketplace");
  };

  return (
    <UserScreenWindow label="New Property For Sale/Lease">
      <div className="flex items-center gap-4 text-eleghoBlue text-sm font-semibold">
        <div>
          <FaDoorOpen />
        </div>
        <div>Property Details</div>
      </div>

      <div className="category-container text-center mt-4">
        {categories.map((category, index) => (
          <div
            className={
              activeCategory === category
                ? "active-category flex items-center gap-1"
                : "inactive-category flex items-center gap-1"
            }
            onClick={() => setActiveCategory(category)}
          >
            {category}
          </div>
        ))}
      </div>

      <>
        <div className="relative mt-4">
          <InputBoxVar2
            label="Property Title"
            name="propertyTitle"
            placeholder="Property Title"
            onChange={handlePropertyTitle}
            maxLength="30"
          />
          <div className="absolute right-2 bottom-2 text-xs text-gray-400">
            <CharacterCounter count={characterCount} limit={"30"} />
          </div>
        </div>

        <div className="mt-2">
          <InputBoxVar2
            label="Monthly Rent"
            placeholder="Monthly Rent"
            onChange={handleNumberChange}
            name="monthlyRent"
            value={formData.monthlyRent}
          />
        </div>

        <div className="mt-2">
          <InputBoxVar2
            label="Unit Number"
            placeholder="Unit Number"
            onChange={handleChange}
            name="unitNumber"
            value={formData.unitNumber}
          />
        </div>

        <>
          <div className="flex items-center gap-4 text-eleghoBlue text-sm font-semibold mt-4">
            <div>
              <IoIosBed />
            </div>
            <div>Bed Rooms</div>
          </div>
          <div className="flex items-center gap-4 mt-2">
            {roomsArray.map((room, index) => (
              <div
                className={`cursor-pointer text-xs text-gray-600 p-1 min-w-12 text-center font-semibold rounded-md ${
                  bedRooms === room ? "bg-eleghoBlue text-white" : "bg-gray-200"
                } `}
                onClick={() => setBedRooms(room)}
              >
                {room}
              </div>
            ))}
          </div>
        </>

        <>
          <div className="flex items-center gap-4 text-eleghoBlue text-sm font-semibold mt-4">
            <div>
              <FaBath />
            </div>
            <div>Bathrooms</div>
          </div>
          <div className="flex items-center gap-4 mt-2">
            {bathRoomsArray.map((room, index) => (
              <div
                className={`cursor-pointer text-xs text-gray-600 p-1 min-w-12 text-center font-semibold rounded-md ${
                  bathRooms === room
                    ? "bg-eleghoBlue text-white"
                    : "bg-gray-200"
                } `}
                onClick={() => setBathRooms(room)}
              >
                {room}
              </div>
            ))}
          </div>
        </>

        <>
          <div className="flex items-center gap-4 text-eleghoBlue text-sm font-semibold mt-4">
            <div>
              <MdPhotoSizeSelectSmall />
            </div>
            <div>Property Size</div>
          </div>

          <div className="relative ">
            <InputBoxVar2
              placeholder={"Property Size"}
              name="size"
              onChange={handleChange}
              value={formData.size}
            />
            <div className="absolute right-2 bottom-2 text-xs text-gray-400">
              sqms
            </div>
          </div>
        </>

        <>
          <div className="flex items-center gap-4 text-eleghoBlue text-sm font-semibold mt-4">
            Description
          </div>

          <textarea
            name="description"
            onChange={handleChange}
            placeholder="Enter Description of the Property"
            id=""
            value={formData.description}
            className="w-full p-2 border border-gray-200 rounded-md"
            rows={6}
            maxLength={999}
          ></textarea>
          <div className="text-gray-400 text-xs">
            {countCharacters(formData.description || "")} / 999 characters
          </div>
        </>
      </>

      <>
        <div className="flex items-center gap-4 text-eleghoBlue text-sm font-semibold mt-4">
          Upload Sample Photos
        </div>

        <input
          type="file"
          ref={photoRef}
          className="hidden"
          accept="image/*"
          multiple
          onChange={handleUpload}
        />

        <div className="border-2 border-dashed  border-gray-400 p-8 rounded-lg bg-white flex items-center justify-center gap-4 mt-4">
          <div className="flex flex-col items-center">
            <div className="flex items-center gap-4 flex-wrap mb-4">
              {formData.images && formData.images.length > 0
                ? formData.images.map((image, index) => (
                    <div>
                      <img
                        src={image}
                        alt="property"
                        className="w-20 h-20 object-cover"
                      />
                      <button
                        className="underline mt-2 text-gray-400 text-xs text-center"
                        onClick={() => handleRemove(index)}
                      >
                        Remove
                      </button>
                    </div>
                  ))
                : null}
            </div>
            <FaCloudArrowUp />
            <div className="text-sm font-semibold text-eleghoBlue">
              Upload Sample Photos
            </div>
            <div
              className="text-2xl cursor-pointer hover:bg-gray-400 p-2 rounded-lg"
              onClick={() => photoRef.current.click()}
            >
              <CiSquarePlus />
            </div>
          </div>
        </div>

        <button
          className="small-form-button mt-2"
          onClick={handleSubmit}
          disabled={isProcessing}
        >
          {isProcessing ? "Processing..." : "Submit"}
        </button>
      </>
    </UserScreenWindow>
  );
}

export default NewListingProperty;
