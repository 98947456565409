import React, { useEffect, useRef, useState } from "react";
import { motion } from "framer-motion";
import { useMotionValue } from "framer-motion";
import { getAllDocsSnap } from "../../utils/firebase/Firebase.utils";
import AnnouncementCard from "./Announcements/AnnouncementCard";
import AnnouncementModal from "./Announcements/AnnouncementModal";
import PhotoModal from "./Announcements/PhotoModal";

function Announcements() {
  const containerRef = useRef(null);
  const [dragging, setDragging] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [mobileWidth, setMobileWidth] = useState(380);
  const [announcementArray, setAnnouncementArray] = useState([]);
  const [viewPhoto, setViewPhoto] = useState(false);
  const [selectedPhoto, setSelectedPhoto] = useState(null);
  const [selectedAnnouncement, setSelectedAnnouncement] = useState(null);
  const [viewAnnouncement, setViewAnnouncement] = useState(false);
  const dragX = useMotionValue(0);

  useEffect(() => {
    const fetchAnnouncements = async () => {
      await getAllDocsSnap("announcements", (data) => {
        // const res = data.filter((item) => item.type === "announcements");
        const res = data;

        res.sort((a, b) => {
          return a.createdAt > b.createdAt ? -1 : 1;
        });

        const publishedData = res.filter((item) => item.status === "published");
        console.log("publishedData", publishedData);

        setAnnouncementArray(publishedData);
      });
    };
    fetchAnnouncements();

    // Set mobile width based on screen size
    const handleResize = () => {
      setMobileWidth(window.innerWidth < 768 ? window.innerWidth - 40 : 380);
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const openPhotoModal = (e, photoUrl) => {
    e.stopPropagation();
    setSelectedPhoto(photoUrl);
    setViewPhoto(true);
  };

  const openAnnouncementModal = (announcement) => {
    if (!dragging) {
      setSelectedAnnouncement(announcement);
      setViewAnnouncement(true);
    }
  };

  const formatDate = (date) => {
    if (!date) return "";
    const options = {
      year: "numeric",
      month: "numeric",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    };
    return new Date(date).toLocaleString("en-US", options);
  };

  return (
    <div className="w-full py-6 px-4 md:px-6">
      <h2 className="text-base font-bold mb-4 text-[#363062]">Announcements</h2>

      {announcementArray.length > 0 ? (
        <div className="relative">
          {/* Carousel Container */}
          <div ref={containerRef} className="overflow-hidden">
            <motion.div
              className="flex"
              drag="x"
              dragConstraints={{
                left: -(mobileWidth * (announcementArray.length - 1)),
                right: 0,
              }}
              style={{ x: dragX }}
              dragElastic={0.1}
              onDragStart={() => setDragging(true)}
              onDragEnd={(e, { offset, velocity }) => {
                setDragging(false);

                if (Math.abs(offset.x) > mobileWidth / 4) {
                  const direction = offset.x < 0 ? 1 : -1;
                  const newIndex = Math.min(
                    Math.max(0, currentIndex + direction),
                    announcementArray.length - 1
                  );
                  setCurrentIndex(newIndex);
                }
              }}
              animate={{
                x: -currentIndex * mobileWidth,
              }}
              transition={{
                type: "spring",
                damping: 30,
                stiffness: 200,
              }}
            >
              {announcementArray.map((announcement, idx) => (
                <div
                  key={announcement.id}
                  className="min-w-[100%] md:min-w-[380px] px-2"
                  style={{ width: mobileWidth }}
                >
                  <AnnouncementCard
                    announcement={announcement}
                    onCardClick={openAnnouncementModal}
                    onPhotoClick={openPhotoModal}
                    formatDate={formatDate}
                  />
                </div>
              ))}
            </motion.div>
          </div>

          {/* Dots Indicator */}
          <div className="flex justify-center mt-3">
            {announcementArray.map((_, idx) => (
              <button
                key={idx}
                onClick={() => setCurrentIndex(idx)}
                className={`h-1.5 w-1.5 mx-1 rounded-full ${
                  idx === currentIndex ? "bg-[#8FCD00]" : "bg-gray-300"
                }`}
              />
            ))}
          </div>
        </div>
      ) : (
        <div className="text-center py-6 text-gray-500 text-sm">
          No announcements available.
        </div>
      )}

      {/* Photo Modal */}
      {viewPhoto && selectedPhoto && (
        <PhotoModal
          photoUrl={selectedPhoto}
          onClose={() => setViewPhoto(false)}
        />
      )}

      {/* Announcement Detail Modal */}
      {viewAnnouncement && selectedAnnouncement && (
        <AnnouncementModal
          announcement={selectedAnnouncement}
          onClose={() => setViewAnnouncement(false)}
          formatDate={formatDate}
        />
      )}
    </div>
  );
}

export default Announcements;
