import React, { createContext, useContext, useState, useEffect } from "react";
import { getDocDataSnap } from "../utils/firebase/Firebase.utils";

// Create context with default values
export const CompanyContext = createContext({
  companyType: "condo", // Default is condo
  terms: {
    unit: "unit",
    units: "units",
    owner: "unit owner",
    structure: "tower",
    location: "floor",
    identifier: "unit number",
  },
  getTerm: () => {},
  getCapitalizedTerm: () => {},
});

export const CompanyProvider = ({ children }) => {
  const [companyData, setCompanyData] = useState(null);
  const [terms, setTerms] = useState({
    unit: "unit",
    units: "units",
    owner: "unit owner",
    owners: "unit owners",
    structure: "tower",
    location: "floor",
    identifier: "unit number",
    corp: "Condo Corp",
    directory: "Condo Corp Directory",
  });

  useEffect(() => {
    const companyId = "7Dw6sBMQrHLSiHoirHdN"; // This is the fixed ID for the company document

    let unsubscribe;
    try {
      unsubscribe = getDocDataSnap("company", companyId, (data) => {
        console.log("companyId", companyId);
        setCompanyData(data);
        console.log("entityType", data.entityType);

        // Update terminology based on entityType
        if (data && data.entityType === "hoa") {
          setTerms({
            unit: "resident",
            units: "residents",
            owner: "lot owner",
            owners: "lot owners",
            structure: "phase",
            location: "block",
            identifier: "lot number",
            corp: "HOA",
            directory: "HOA Directory",
          });
        }
      });
    } catch (error) {
      console.error("Error subscribing to company data:", error);
    }

    return () => {
      // Check if unsubscribe is a function before calling it
      if (unsubscribe && typeof unsubscribe === "function") {
        unsubscribe();
      }
    };
  }, []);

  // Helper function to get term
  const getTerm = (term) => {
    return terms[term] || term;
  };

  // Helper function to get capitalized term
  const getCapitalizedTerm = (term) => {
    const termText = terms[term] || term;
    return termText.charAt(0).toUpperCase() + termText.slice(1);
  };

  return (
    <CompanyContext.Provider
      value={{
        companyType: companyData?.entityType || "condo",
        terms,
        getTerm,
        getCapitalizedTerm,
      }}
    >
      {children}
    </CompanyContext.Provider>
  );
};

// Custom hook for easier context consumption
export const useCompany = () => useContext(CompanyContext);
