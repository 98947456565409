import React from "react";

export default function PrivacyPage({ setViewMode }) {
  return (
    <div className="bg-white p-4 rounded-lg relative w-full min-h-screen">
      <div className="header"> Privacy Policy and Information </div>
      <button className="close-button" onClick={() => setViewMode(false)}>
        Close
      </button>

      <div>
        <div className="max-w-2xl mx-auto p-6 bg-white shadow-md rounded-lg">
          <h1 className="text-2xl font-bold mb-4">
            Terms and Conditions for Tali-Beach Use of Application
          </h1>
          <p className="mb-4">
            <strong>Effective Date:</strong> October 7, 2024
          </p>

          <h2 className="text-xl font-semibold mt-6">1. Acceptance of Terms</h2>
          <p className="mb-4">
            By accessing and using Tali Connect (the “Service”), you agree to comply
            with and be bound by these Terms and Conditions (the “Terms”). If
            you do not agree with these Terms, you must not use the Service.
          </p>

          <h2 className="text-xl font-semibold mt-6">2. Definitions</h2>
          <ul className="list-disc list-inside mb-4">
            <li>
              “User” refers to any individual or entity that accesses or uses
              the Service.
            </li>
            <li>
              “Content” refers to any data, information, or material that a User
              provides, uploads, or sends through the Service.
            </li>
            <li>
              “Company” refers to Tali Connect, the entity providing the Service.
            </li>
          </ul>

          <h2 className="text-xl font-semibold mt-6">3. Eligibility</h2>
          <p className="mb-4">
            You must be at least 18 years of age to use this Service. By using
            the Service, you represent that you have the authority to enter into
            these Terms.
          </p>

          <h2 className="text-xl font-semibold mt-6">
            4. Account Registration
          </h2>
          <p className="mb-4">
            To use certain features of the Service, you may need to create an
            account. You agree to:
          </p>
          <ul className="list-disc list-inside mb-4">
            <li>
              Provide accurate, current, and complete information during the
              registration process.
            </li>
            <li>
              Maintain the security of your password and account information.
            </li>
            <li>
              Notify the Company immediately of any unauthorized use of your
              account.
            </li>
            <li>
              Be responsible for all activities that occur under your account.
            </li>
          </ul>

          <h2 className="text-xl font-semibold mt-6">5. Use of the Service</h2>
          <p className="mb-4">
            You agree to use the Service for lawful purposes only and in
            accordance with applicable laws and regulations. You must not:
          </p>
          <ul className="list-disc list-inside mb-4">
            <li>
              Send unsolicited commercial emails (spam) or engage in any form of
              illegal email marketing.
            </li>
            <li>Use the Service to harass, abuse, or harm another person.</li>
            <li>
              Impersonate any person or entity or misrepresent your affiliation
              with any person or entity.
            </li>
          </ul>

          <h2 className="text-xl font-semibold mt-6">
            6. Content Responsibility
          </h2>
          <p className="mb-4">
            You are solely responsible for the Content you submit through the
            Service. The Company does not guarantee the accuracy, integrity, or
            quality of any Content. By providing Content, you grant the Company
            a worldwide, non-exclusive, royalty-free license to use, reproduce,
            modify, and distribute such Content in connection with the Service.
          </p>

          <h2 className="text-xl font-semibold mt-6">
            7. Compliance with Anti-Spam Laws
          </h2>
          <p className="mb-4">
            You agree to comply with all applicable laws regarding unsolicited
            commercial emails, including but not limited to the CAN-SPAM Act,
            GDPR, and other relevant regulations.
          </p>

          <h2 className="text-xl font-semibold mt-6">8. Fees and Payment</h2>
          <p className="mb-4">
            The use of the Service may require payment of fees. You agree to pay
            all applicable fees for the Service as outlined in the pricing plan
            you select. All fees are non-refundable unless stated otherwise.
          </p>

          <h2 className="text-xl font-semibold mt-6">9. Termination</h2>
          <p className="mb-4">
            The Company reserves the right to suspend or terminate your access
            to the Service at any time, with or without cause, or with or
            without notice.
          </p>

          <h2 className="text-xl font-semibold mt-6">
            10. Limitation of Liability
          </h2>
          <p className="mb-4">
            To the extent permitted by law, the Company shall not be liable for
            any indirect, incidental, special, consequential or punitive
            damages, including but not limited to loss of profits, data or
            goodwill, arising from or related to your use of the Service.
          </p>

          <h2 className="text-xl font-semibold mt-6">11. Indemnification</h2>
          <p className="mb-4">
            You agree to indemnify, defend, and hold harmless the Company and
            its affiliates from any claims, losses, liabilities, damages, costs,
            or expenses arising from your use of the Service or your violation
            of these Terms.
          </p>

          <h2 className="text-xl font-semibold mt-6">12. Changes to Terms</h2>
          <p className="mb-4">
            The Company reserves the right to modify these Terms at any time.
            Your continued use of the Service after the modifications
            constitutes your acceptance of the new Terms.
          </p>

          <h2 className="text-xl font-semibold mt-6">13. Governing Law</h2>
          <p className="mb-4">
            These Terms shall be governed by and construed in accordance with
            the laws of the Republic of the Philippines, without regard to its
            conflict of law principles.
          </p>

          <h2 className="text-xl font-semibold mt-6">
            14. Contact Information
          </h2>
          <p className="mb-4">
            For any questions regarding these Terms, please contact us at
            admin@emails.tali.com
          </p>
        </div>
      </div>
    </div>
  );
}