import React, { useEffect } from "react";
import UserScreenWindow from "./UserScreenWindow";
import { useNavigate } from "react-router-dom";
import { setDocData, getDocById } from "../../utils/firebase/Firebase.utils";
import { v4 } from "uuid";
import { useSelector } from "react-redux";
import toast from "react-hot-toast";
import {
  FiHome,
  FiCalendar,
  FiClock,
  FiList,
  FiTruck,
  FiSend,
} from "react-icons/fi";
import { useCompany } from "../../context/CompanyContext";

function Gatepass() {
  const { getCapitalizedTerm } = useCompany();
  const [inProcess, setInProcess] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(true);
  const [formData, setFormData] = React.useState({});
  const [assignedId, setAssignedId] = React.useState(v4());
  const [linkedIdentifiers, setLinkedIdentifiers] = React.useState([]);
  const navigate = useNavigate();
  const currentUser = useSelector((state) => state.userObject);

  useEffect(() => {
    const fetchIdentifiers = async () => {
      const user = await getDocById("users", currentUser.uid);
      setLinkedIdentifiers(user.linkedIdentifiers);
      setIsLoading(false);
    };
    fetchIdentifiers();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  if (isLoading) {
    return (
      <UserScreenWindow label="Gate Pass">
        <div className="animate-pulse space-y-4">
          <div className="h-4 bg-gray-200 rounded w-3/4"></div>
          <div className="h-4 bg-gray-200 rounded"></div>
          <div className="h-4 bg-gray-200 rounded w-5/6"></div>
          <div className="h-4 bg-gray-200 rounded w-4/6"></div>
        </div>
      </UserScreenWindow>
    );
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setInProcess(true);

    if (
      !formData.identifier ||
      !formData.deliveryDate ||
      !formData.deliveryTime ||
      !formData.items ||
      !formData.deliveryCompany
    ) {
      toast.error("All fields are required");
      setInProcess(false);
      return;
    }

    const docObject = {
      ...formData,
      user: currentUser.email,
      requestedBy: currentUser.displayName,
      status: "pending",
      requestDateTime: new Date(),
      requestType: "gatePass",
    };

    await setDocData("gatePass", assignedId, docObject);
    toast.success("Request submitted successfully");
    setInProcess(false);
    navigate("/gatepass-confirmation/" + assignedId);
  };

  return (
    <UserScreenWindow label="Gate Pass">
      <div className="max-w-md mx-auto">
        {/* Form */}
        <div className="bg-white rounded-xl shadow-sm p-6">
          <form onSubmit={handleSubmit} className="space-y-6">
            {/* Unit Number */}
            <div className="space-y-2">
              <label className="flex items-center gap-2 text-sm font-medium text-gray-700">
                <FiHome className="w-4 h-4 text-eleghoBlue" />
                {getCapitalizedTerm("identifier")}
              </label>
              <select
                className="w-full px-4 py-2.5 text-sm border border-gray-200 rounded-lg focus:ring-2 focus:ring-eleghoBlue/20 focus:border-eleghoBlue transition-all"
                name="identifier"
                onChange={handleChange}
              >
                <option value="">Select Unit Number</option>
                {linkedIdentifiers.map((identifier, index) => (
                  <option key={index} value={identifier}>
                    {identifier}
                  </option>
                ))}
              </select>
            </div>

            {/* Delivery Date */}
            <div className="space-y-2">
              <label className="flex items-center gap-2 text-sm font-medium text-gray-700">
                <FiCalendar className="w-4 h-4 text-eleghoBlue" />
                Delivery Date
              </label>
              <input
                type="date"
                className="w-full px-4 py-2.5 text-sm border border-gray-200 rounded-lg focus:ring-2 focus:ring-eleghoBlue/20 focus:border-eleghoBlue transition-all"
                name="deliveryDate"
                onChange={handleChange}
              />
            </div>

            {/* Delivery Time */}
            <div className="space-y-2">
              <label className="flex items-center gap-2 text-sm font-medium text-gray-700">
                <FiClock className="w-4 h-4 text-eleghoBlue" />
                Estimated Time of Delivery
              </label>
              <input
                type="time"
                className="w-full px-4 py-2.5 text-sm border border-gray-200 rounded-lg focus:ring-2 focus:ring-eleghoBlue/20 focus:border-eleghoBlue transition-all"
                name="deliveryTime"
                onChange={handleChange}
              />
            </div>

            {/* Items List */}
            <div className="space-y-2">
              <label className="flex items-center gap-2 text-sm font-medium text-gray-700">
                <FiList className="w-4 h-4 text-eleghoBlue" />
                Items to be Delivered
              </label>
              <textarea
                className="w-full px-4 py-2.5 text-sm border border-gray-200 rounded-lg focus:ring-2 focus:ring-eleghoBlue/20 focus:border-eleghoBlue transition-all"
                rows="4"
                placeholder="e.g. 1. Refrigerator - 1 Unit, 2. 2 Sofa Beds - 2 Units"
                name="items"
                onChange={handleChange}
              />
            </div>

            {/* Delivery Company */}
            <div className="space-y-2">
              <label className="flex items-center gap-2 text-sm font-medium text-gray-700">
                <FiTruck className="w-4 h-4 text-eleghoBlue" />
                Delivery Company
              </label>
              <input
                type="text"
                className="w-full px-4 py-2.5 text-sm border border-gray-200 rounded-lg focus:ring-2 focus:ring-eleghoBlue/20 focus:border-eleghoBlue transition-all"
                placeholder="e.g. Crate and Barrel"
                name="deliveryCompany"
                onChange={handleChange}
              />
            </div>

            {/* Submit Button */}
            <button
              className="w-full bg-eleghoGreen text-eleghoBlue px-4 py-3 rounded-lg font-medium flex items-center justify-center gap-2 hover:bg-eleghoGreen/90 transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
              type="submit"
              disabled={inProcess}
            >
              {inProcess ? (
                "Processing..."
              ) : (
                <>
                  <FiSend className="w-4 h-4" />
                  Submit Request
                </>
              )}
            </button>
          </form>
        </div>
      </div>
    </UserScreenWindow>
  );
}

export default Gatepass;
