import { getMessaging, getToken } from "firebase/messaging";
import { db, functions } from "./firebase/Firebase.utils";
import {
  doc,
  getDoc,
  collection,
  addDoc,
  query,
  where,
  getDocs,
} from "firebase/firestore";
import { httpsCallable } from "firebase/functions";

export const sendNotification = async (userEmail, notification) => {
  try {
    // Find user by email
    const usersRef = collection(db, "users");
    const q = await query(usersRef, where("email", "==", userEmail));
    const querySnapshot = await getDocs(q);

    if (querySnapshot.empty) {
      console.log("User not found with email:", userEmail);
      return false;
    }

    const userDoc = querySnapshot.docs[0];
    const userData = userDoc.data();
    const fcmToken = userData.fcmToken;
    console.log("token", fcmToken);

    if (!userData.fcmToken) {
      console.log("No FCM token available for user:", userEmail);
      return false;
    }

    // Send web push notification using Cloud Function
    const sendNotificationFunction = httpsCallable(
      functions,
      "sendNotification"
    );

    const result = await sendNotificationFunction(
      {
        token: fcmToken,
        notification: notification,
      },
      "us-central1"
    );

    console.log("payload", fcmToken, notification);

    // Store notification in Firestore notifications collection
    const notificationsRef = collection(db, "notifications");
    await addDoc(notificationsRef, {
      ...notification,
      timestamp: new Date(),
      read: false,
      userId: userDoc.id,
      userEmail: userEmail,
    });

    if (!result.data.success) {
      console.error("Failed to send push notification:", result.data.error);
      return false;
    }

    console.log("Successfully sent push notification - notification.js");
    return true;
  } catch (error) {
    console.error("Error sending notification:", error);
    return false;
  }
};

export const sendBulkNotifications = async (userEmails, notification) => {
  try {
    const results = await Promise.all(
      userEmails.map((userEmail) => sendNotification(userEmail, notification))
    );

    console.log("results", results);

    const successCount = results.filter((result) => result).length;
    console.log(
      `Successfully sent ${successCount} out of ${userEmails.length} notifications`
    );
    return successCount;
  } catch (error) {
    console.error("Error sending bulk notifications:", error);
    return 0;
  }
};
