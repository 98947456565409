import React from "react";
import { useCompany } from "../../../context/CompanyContext";

export default function ResidentTableHeaders() {
  const { getCapitalizedTerm } = useCompany();

  return (
    <div className="bg-gray-50 flex items-center gap-4 p-4 rounded-xl border-b border-gray-100 text-left">
      <div className="w-24">
        <div className="text-xs font-medium text-gray-500 uppercase tracking-wider">
          Status
        </div>
      </div>
      <div className="w-28">
        <div className="text-xs font-medium text-gray-500 uppercase tracking-wider">
          {getCapitalizedTerm("owner")}
        </div>
      </div>
      <div className="w-24">
        <div className="text-xs font-medium text-gray-500 uppercase tracking-wider">
          Nationality
        </div>
      </div>
      <div className="w-36">
        <div className="text-xs font-medium text-gray-500 uppercase tracking-wider">
          {getCapitalizedTerm("identifier")}
        </div>
      </div>
      <div className="w-60">
        <div className="text-xs font-medium text-gray-500 uppercase tracking-wider">
          Address
        </div>
      </div>
      <div className="w-28">
        <div className="text-xs font-medium text-gray-500 uppercase tracking-wider">
          Current Tenant
        </div>
      </div>
      <div className="w-24">
        <div className="text-xs font-medium text-gray-500 uppercase tracking-wider">
          Occupants
        </div>
      </div>
    </div>
  );
}
