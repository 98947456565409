import React, { useState } from "react";
import {
  getDocDataSnap,
  setDocData,
  storage,
} from "../../../utils/firebase/Firebase.utils";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { useParams } from "react-router-dom";
import toast from "react-hot-toast";
import { FaFileAlt, FaDownload, FaIdCard, FaPlus } from "react-icons/fa";
import { IoTrashOutline, IoCheckmarkCircle } from "react-icons/io5";
import { useSelector } from "react-redux";
import InputBox from "../Components/InputBox";

export default function ResidentOccupantDocuments({
  occupantId,
  occupant,
  editMode,
  onEditModeChange,
}) {
  const [documents, setDocuments] = useState([]);
  const [formData, setFormData] = useState({
    documentType: "",
    documentNumber: "",
  });
  const [selectedFile, setSelectedFile] = useState(null);
  const [uploadType, setUploadType] = useState(null); // 'government' or 'additional'
  const { id } = useParams();
  const currentUser = useSelector((state) => state.userObject);
  const isAdmin = currentUser?.roles?.includes("admin");

  const hasGovernmentId = documents.some((doc) => doc.type === "government");

  React.useEffect(() => {
    const fetchOccupant = async () => {
      await getDocDataSnap("residents", id, (data) => {
        const occupantItem = data.occupantsName.find(
          (o) => o.id === occupantId
        );
        setDocuments(occupantItem.documents || []);
        setFormData(occupantItem);
      });
    };
    fetchOccupant();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setSelectedFile(file);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!selectedFile) {
      toast.error("Please select a file to upload");
      return;
    }

    const storageRef = ref(
      storage,
      `residents/occupants/${id}/documents/${formData.documentType}`
    );
    const uploadTask = uploadBytesResumable(storageRef, selectedFile);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log(progress);
      },
      (error) => {
        toast.error("Error uploading file");
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then(async (downloadURL) => {
          const newDoc = {
            id: Date.now().toString(),
            type: uploadType,
            documentType: formData.documentType,
            documentNumber: formData.documentNumber,
            url: downloadURL,
            uploadedAt: new Date().toISOString(),
          };

          const updatedDocs = [...documents, newDoc];
          setDocuments(updatedDocs);

          await setDocData("residents", id, {
            occupantsName: occupant.map((occ) => {
              if (occ.id === occupantId) {
                return {
                  ...occ,
                  documents: updatedDocs,
                };
              }
              return occ;
            }),
          });

          toast.success(`${formData.documentType} uploaded successfully`);
          setSelectedFile(null);
          setFormData({
            documentType: "",
            documentNumber: "",
          });
          setUploadType(null);
        });
      }
    );
  };

  const handleDownload = (doc) => {
    // Implement download logic
  };

  const handleDelete = (id) => {
    // Implement delete logic
  };

  return (
    <div className="space-y-4">
      {/* Required Government ID Section */}
      <div className="bg-white rounded-lg border border-gray-100 p-3">
        <div className="flex items-center justify-between mb-3">
          <div className="flex items-center gap-2">
            <div className="p-1.5 rounded-lg bg-blue-50">
              <FaIdCard className="w-4 h-4 text-blue-500" />
            </div>
            <div>
              <h4 className="text-sm font-medium text-gray-900">
                Government ID
              </h4>
              <p className="text-xs text-gray-500">Required for verification</p>
            </div>
          </div>
          {hasGovernmentId && (
            <div className="flex items-center gap-1 text-green-500 bg-green-50 px-2 py-1 rounded-full">
              <IoCheckmarkCircle className="w-3.5 h-3.5" />
              <span className="text-xs font-medium">Verified</span>
            </div>
          )}
        </div>

        {editMode && !hasGovernmentId && (
          <div className="border-2 border-dashed border-gray-200 rounded-lg p-4 text-center">
            <div className="mb-3">
              <div className="inline-flex items-center justify-center w-12 h-12 rounded-full bg-blue-50 mb-2">
                <FaIdCard className="w-6 h-6 text-blue-500" />
              </div>
              <h5 className="text-sm font-medium text-gray-900">
                Upload Government ID
              </h5>
              <p className="text-xs text-gray-500 mt-1">
                Supported formats: JPG, PNG, PDF
              </p>
            </div>
            <div className="space-y-3">
              <input
                type="text"
                placeholder="ID Number"
                value={formData.documentNumber}
                onChange={(e) =>
                  setFormData((prev) => ({
                    ...prev,
                    documentNumber: e.target.value,
                  }))
                }
                className="w-full px-3 py-1.5 text-xs border border-gray-200 rounded-lg focus:outline-none focus:border-blue-500"
              />
              <div className="flex items-center gap-2">
                <input
                  type="file"
                  onChange={(e) => {
                    setSelectedFile(e.target.files[0]);
                    setUploadType("government");
                    setFormData((prev) => ({
                      ...prev,
                      documentType: "Government ID",
                    }));
                  }}
                  className="flex-1 text-xs text-gray-500 file:mr-4 file:py-1.5 file:px-3 file:rounded-lg file:border-0 file:text-xs file:font-medium file:bg-blue-50 file:text-blue-500 hover:file:bg-blue-100"
                />
                <button
                  onClick={handleSubmit}
                  className="px-3 py-1.5 text-xs font-medium text-white bg-blue-500 rounded-lg hover:bg-blue-600 transition-colors"
                >
                  Upload
                </button>
              </div>
            </div>
          </div>
        )}

        {hasGovernmentId &&
          documents
            .filter((doc) => doc.type === "government")
            .map((doc) => (
              <div
                key={doc.id}
                className="flex items-center justify-between p-2 bg-gray-50 rounded-lg"
              >
                <div className="flex items-center gap-2">
                  <FaIdCard className="w-4 h-4 text-blue-500" />
                  <div>
                    <h6 className="text-xs font-medium text-gray-900">
                      {doc.documentType}
                    </h6>
                    <p className="text-[10px] text-gray-500">
                      {doc.documentNumber}
                    </p>
                  </div>
                </div>
                <div className="flex items-center gap-1">
                  <button
                    onClick={() => handleDownload(doc)}
                    className="p-1 text-gray-400 hover:text-gray-600 rounded-lg hover:bg-white transition-colors"
                  >
                    <FaDownload className="w-3.5 h-3.5" />
                  </button>
                  {isAdmin && editMode && (
                    <button
                      onClick={() => handleDelete(doc.id)}
                      className="p-1 text-red-500 hover:bg-red-50 rounded-lg transition-colors"
                    >
                      <IoTrashOutline className="w-3.5 h-3.5" />
                    </button>
                  )}
                </div>
              </div>
            ))}
      </div>

      {/* Additional Documents Section */}
      <div className="bg-white rounded-lg border border-gray-100 p-3">
        <div className="flex items-center justify-between mb-3">
          <div className="flex items-center gap-2">
            <div className="p-1.5 rounded-lg bg-purple-50">
              <FaFileAlt className="w-4 h-4 text-purple-500" />
            </div>
            <div>
              <h4 className="text-sm font-medium text-gray-900">
                Additional Documents
              </h4>
              <p className="text-xs text-gray-500">
                Optional supporting documents
              </p>
            </div>
          </div>
        </div>

        <div className="space-y-2">
          {documents
            .filter((doc) => doc.type === "additional")
            .map((doc) => (
              <div
                key={doc.id}
                className="flex items-center justify-between p-2 bg-gray-50 rounded-lg"
              >
                <div className="flex items-center gap-2">
                  <FaFileAlt className="w-4 h-4 text-purple-500" />
                  <div>
                    <h6 className="text-xs font-medium text-gray-900">
                      {doc.documentType}
                    </h6>
                    <p className="text-[10px] text-gray-500">
                      {doc.documentNumber}
                    </p>
                  </div>
                </div>
                <div className="flex items-center gap-1">
                  <button
                    onClick={() => handleDownload(doc)}
                    className="p-1 text-gray-400 hover:text-gray-600 rounded-lg hover:bg-white transition-colors"
                  >
                    <FaDownload className="w-3.5 h-3.5" />
                  </button>
                  {isAdmin && editMode && (
                    <button
                      onClick={() => handleDelete(doc.id)}
                      className="p-1 text-red-500 hover:bg-red-50 rounded-lg transition-colors"
                    >
                      <IoTrashOutline className="w-3.5 h-3.5" />
                    </button>
                  )}
                </div>
              </div>
            ))}

          {editMode && (
            <button
              onClick={() => {
                setUploadType("additional");
                setFormData({
                  documentType: "",
                  documentNumber: "",
                });
              }}
              className="w-full flex items-center justify-center gap-2 p-3 border-2 border-dashed border-gray-200 rounded-lg text-gray-500 hover:text-purple-500 hover:border-purple-200 hover:bg-purple-50 transition-colors"
            >
              <FaPlus className="w-3.5 h-3.5" />
              <span className="text-xs font-medium">Add Document</span>
            </button>
          )}

          {editMode && uploadType === "additional" && (
            <div className="border-2 border-dashed border-gray-200 rounded-lg p-4">
              <div className="space-y-3">
                <input
                  type="text"
                  placeholder="Document Type"
                  value={formData.documentType}
                  onChange={(e) =>
                    setFormData((prev) => ({
                      ...prev,
                      documentType: e.target.value,
                    }))
                  }
                  className="w-full px-3 py-1.5 text-xs border border-gray-200 rounded-lg focus:outline-none focus:border-purple-500"
                />
                <input
                  type="text"
                  placeholder="Document Number (optional)"
                  value={formData.documentNumber}
                  onChange={(e) =>
                    setFormData((prev) => ({
                      ...prev,
                      documentNumber: e.target.value,
                    }))
                  }
                  className="w-full px-3 py-1.5 text-xs border border-gray-200 rounded-lg focus:outline-none focus:border-purple-500"
                />
                <div className="flex items-center gap-2">
                  <input
                    type="file"
                    onChange={(e) => setSelectedFile(e.target.files[0])}
                    className="flex-1 text-xs text-gray-500 file:mr-4 file:py-1.5 file:px-3 file:rounded-lg file:border-0 file:text-xs file:font-medium file:bg-purple-50 file:text-purple-500 hover:file:bg-purple-100"
                  />
                  <button
                    onClick={handleSubmit}
                    className="px-3 py-1.5 text-xs font-medium text-white bg-purple-500 rounded-lg hover:bg-purple-600 transition-colors"
                  >
                    Upload
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
