import React from "react";

export default function GuestPassCard({ data }) {
  const getStatusColor = (status) => {
    switch (status.toLowerCase()) {
      case "approved":
        return "text-eleghoGreen";
      case "pending":
        return "text-yellow-500";
      case "rejected":
        return "text-red-500";
      case "admitted":
        return "text-blue-500";
      case "completed":
        return "text-purple-500";
      case "returned":
        return "text-gray-600";
      default:
        return "text-gray-600";
    }
  };

  const getStatusBg = (status) => {
    switch (status.toLowerCase()) {
      case "approved":
        return "bg-eleghoGreen/10";
      case "pending":
        return "bg-yellow-50";
      case "rejected":
        return "bg-red-50";
      case "admitted":
        return "bg-blue-50";
      case "completed":
        return "bg-purple-50";
      case "returned":
        return "bg-gray-50";
      default:
        return "bg-gray-50";
    }
  };

  return (
    <div className="grid grid-cols-6 py-3 px-4 items-center">
      <div className="font-medium text-gray-800">
        {data.guests && data.guests.length > 0
          ? data.guests[0].name
          : "Not Available"}
      </div>

      <div className="text-gray-700">
        {data.requestorName || "Not Available"}
      </div>

      <div className="text-gray-700">
        Unit {data.linkedIdentifiers[0] || "Not Set"}
      </div>

      <div className="text-gray-700">
        {data.date || "Not Set"}
      </div>

      <div>
        <span
          className={`capitalize px-2 py-1 rounded-full text-xs font-medium ${getStatusBg(
            data.status
          )} ${getStatusColor(data.status)}`}
        >
          {data.status}
        </span>
      </div>

      <div>
        <button className="px-3 py-1 bg-eleghoBlue/10 text-eleghoBlue rounded-md text-xs hover:bg-eleghoBlue/20 transition-colors">
          View Details
        </button>
      </div>
    </div>
  );
}
