import React from "react";

function InputBox({
  label,
  placeholder,
  type,
  onChange,
  name,
  className,
  ...otherprops
}) {
  return (
    <div className="flex flex-col space-y-1">
      <label className="text-[#374151] text-base">{label}</label>
      <input
        className={`w-full px-4 py-3 bg-[#f3f4f6] rounded-lg text-[#374151] text-base
          placeholder:text-[#9ca3af]
          focus:outline-none focus:ring-1 focus:ring-[#4f46e5]
          ${className}`}
        type={type}
        placeholder={placeholder}
        onChange={onChange}
        name={name}
        {...otherprops}
      />
    </div>
  );
}

export default InputBox;
