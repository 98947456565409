import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  getDocDataSnap,
  setDocData,
} from "../../../utils/firebase/Firebase.utils";
import InputBox from "../Components/InputBox";
import toast from "react-hot-toast";
import {
  FaIdCard,
  FaPhone,
  FaEnvelope,
  FaMapMarkerAlt,
  FaBirthdayCake,
  FaUser,
} from "react-icons/fa";
import { IoPencilOutline, IoClose } from "react-icons/io5";

export default function ResidentOccupantInformationPage({
  occupantId,
  occupant,
  editMode = false,
  onEditModeChange,
}) {
  const { id } = useParams();
  const [formData, setFormData] = useState({
    firstName: "",
    middleName: "",
    lastName: "",
    relationship: "",
    dateOfBirth: "",
    gender: "",
    civilStatus: "",
    contactNumber: "",
    email: "",
    address: "",
    occupation: "",
    employer: "",
    emergencyContact: "",
    emergencyContactNumber: "",
    emergencyContactRelationship: "",
  });

  useEffect(() => {
    const fetchOccupantData = async () => {
      await getDocDataSnap("residents", id, (data) => {
        const occupantData = data.occupantsName.find(
          (o) => o.id === occupantId
        );
        if (occupantData) {
          setFormData(occupantData);
        }
      });
    };
    fetchOccupantData();
  }, [id, occupantId]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await getDocDataSnap("residents", id, async (data) => {
        const updatedOccupants = data.occupantsName.map((o) =>
          o.id === occupantId ? { ...o, ...formData } : o
        );
        await setDocData("residents", id, {
          occupantsName: updatedOccupants,
        });
        toast.success("Occupant information updated successfully");
        onEditModeChange(false);
      });
    } catch (error) {
      toast.error("Error updating occupant information");
    }
  };

  const InfoItem = ({ icon: Icon, label, value }) => (
    <div className="flex items-start gap-3 py-2">
      <div className="mt-1 text-gray-400">
        <Icon className="w-5 h-5" />
      </div>
      <div>
        <div className="text-xs font-medium text-gray-500">{label}</div>
        <div className="text-sm text-gray-900">{value || "Not specified"}</div>
      </div>
    </div>
  );

  if (editMode) {
    return (
      <form onSubmit={handleSubmit} className="space-y-3">
        <div className="flex items-center justify-between pb-2 border-b border-gray-100">
          <div className="flex items-center gap-2">
            <div className="p-1 rounded-lg bg-eleghoBlue/10">
              <FaUser className="w-3.5 h-3.5 text-eleghoBlue" />
            </div>
            <div>
              <h3 className="text-xs font-semibold text-gray-900">
                Edit Information
              </h3>
              <p className="text-[10px] text-gray-500">
                Update occupant details
              </p>
            </div>
          </div>
          <button
            type="button"
            onClick={() => onEditModeChange(false)}
            className="p-1 rounded-lg hover:bg-gray-100 transition-colors"
          >
            <IoClose className="w-3.5 h-3.5 text-gray-500" />
          </button>
        </div>

        <div className="space-y-3">
          <div className="bg-white rounded-lg border border-gray-100 p-2">
            <div className="flex items-center gap-2 mb-2">
              <FaUser className="w-3 h-3 text-eleghoBlue" />
              <h4 className="text-xs font-medium text-gray-900">
                Personal Information
              </h4>
            </div>
            <div className="grid grid-cols-2 gap-2">
              <InputBox
                label="First Name"
                name="firstName"
                value={formData.firstName}
                onChange={handleInputChange}
                required
              />
              <InputBox
                label="Middle Name"
                name="middleName"
                value={formData.middleName}
                onChange={handleInputChange}
              />
              <InputBox
                label="Last Name"
                name="lastName"
                value={formData.lastName}
                onChange={handleInputChange}
                required
              />
              <InputBox
                label="Relationship"
                name="relationship"
                value={formData.relationship}
                onChange={handleInputChange}
                required
              />
              <InputBox
                label="Date of Birth"
                name="dateOfBirth"
                type="date"
                value={formData.dateOfBirth}
                onChange={handleInputChange}
                required
              />
              <InputBox
                label="Gender"
                name="gender"
                value={formData.gender}
                onChange={handleInputChange}
                required
              />
              <InputBox
                label="Civil Status"
                name="civilStatus"
                value={formData.civilStatus}
                onChange={handleInputChange}
                required
              />
            </div>
          </div>

          <div className="bg-white rounded-lg border border-gray-100 p-2">
            <div className="flex items-center gap-2 mb-2">
              <FaPhone className="w-3 h-3 text-eleghoBlue" />
              <h4 className="text-xs font-medium text-gray-900">
                Contact Information
              </h4>
            </div>
            <div className="grid grid-cols-2 gap-2">
              <InputBox
                label="Contact Number"
                name="contactNumber"
                value={formData.contactNumber}
                onChange={handleInputChange}
                required
              />
              <InputBox
                label="Email"
                name="email"
                type="email"
                value={formData.email}
                onChange={handleInputChange}
                required
              />
              <InputBox
                label="Address"
                name="address"
                value={formData.address}
                onChange={handleInputChange}
                required
                className="col-span-2"
              />
            </div>
          </div>

          <div className="bg-white rounded-lg border border-gray-100 p-2">
            <div className="flex items-center gap-2 mb-2">
              <FaIdCard className="w-3 h-3 text-eleghoBlue" />
              <h4 className="text-xs font-medium text-gray-900">
                Employment Details
              </h4>
            </div>
            <div className="grid grid-cols-2 gap-2">
              <InputBox
                label="Occupation"
                name="occupation"
                value={formData.occupation}
                onChange={handleInputChange}
                required
              />
              <InputBox
                label="Employer"
                name="employer"
                value={formData.employer}
                onChange={handleInputChange}
                required
              />
            </div>
          </div>

          <div className="bg-white rounded-lg border border-gray-100 p-2">
            <div className="flex items-center gap-2 mb-2">
              <FaPhone className="w-3 h-3 text-eleghoBlue" />
              <h4 className="text-xs font-medium text-gray-900">
                Emergency Contact
              </h4>
            </div>
            <div className="grid grid-cols-2 gap-2">
              <InputBox
                label="Emergency Contact"
                name="emergencyContact"
                value={formData.emergencyContact}
                onChange={handleInputChange}
                required
              />
              <InputBox
                label="Emergency Contact Number"
                name="emergencyContactNumber"
                value={formData.emergencyContactNumber}
                onChange={handleInputChange}
                required
              />
              <InputBox
                label="Emergency Contact Relationship"
                name="emergencyContactRelationship"
                value={formData.emergencyContactRelationship}
                onChange={handleInputChange}
                required
              />
            </div>
          </div>
        </div>

        <div className="flex justify-end gap-2 pt-2 border-t border-gray-100">
          <button
            type="button"
            onClick={() => onEditModeChange(false)}
            className="px-2 py-1 text-xs font-medium text-gray-700 bg-gray-100 rounded-lg hover:bg-gray-200 transition-colors"
          >
            Cancel
          </button>
          <button
            type="submit"
            className="px-2 py-1 text-xs font-medium text-white bg-eleghoBlue rounded-lg hover:bg-eleghoBlue/90 transition-colors"
          >
            Save Changes
          </button>
        </div>
      </form>
    );
  }

  return (
    <div className="space-y-4">
      <div className="grid grid-cols-2 gap-4">
        <div className="space-y-3">
          <div className="space-y-2">
            <h3 className="text-xs font-medium text-gray-900">
              Personal Information
            </h3>
            <div className="space-y-1.5">
              <InfoItem
                icon={FaUser}
                label="Full Name"
                value={`${formData.firstName} ${formData.middleName} ${formData.lastName}`}
              />
              <InfoItem
                icon={FaIdCard}
                label="Relationship"
                value={formData.relationship}
              />
              <InfoItem
                icon={FaBirthdayCake}
                label="Date of Birth"
                value={formData.dateOfBirth}
              />
              <InfoItem icon={FaUser} label="Gender" value={formData.gender} />
              <InfoItem
                icon={FaIdCard}
                label="Civil Status"
                value={formData.civilStatus}
              />
            </div>
          </div>

          <div className="space-y-2">
            <h3 className="text-xs font-medium text-gray-900">
              Contact Information
            </h3>
            <div className="space-y-1.5">
              <InfoItem
                icon={FaPhone}
                label="Contact Number"
                value={formData.contactNumber}
              />
              <InfoItem
                icon={FaEnvelope}
                label="Email"
                value={formData.email}
              />
              <InfoItem
                icon={FaMapMarkerAlt}
                label="Address"
                value={formData.address}
              />
            </div>
          </div>
        </div>

        <div className="space-y-3">
          <div className="space-y-2">
            <h3 className="text-xs font-medium text-gray-900">
              Employment Details
            </h3>
            <div className="space-y-1.5">
              <InfoItem
                icon={FaUser}
                label="Occupation"
                value={formData.occupation}
              />
              <InfoItem
                icon={FaUser}
                label="Employer"
                value={formData.employer}
              />
            </div>
          </div>

          <div className="space-y-2">
            <h3 className="text-xs font-medium text-gray-900">
              Emergency Contact
            </h3>
            <div className="space-y-1.5">
              <InfoItem
                icon={FaUser}
                label="Emergency Contact"
                value={formData.emergencyContact}
              />
              <InfoItem
                icon={FaPhone}
                label="Emergency Contact Number"
                value={formData.emergencyContactNumber}
              />
              <InfoItem
                icon={FaIdCard}
                label="Emergency Contact Relationship"
                value={formData.emergencyContactRelationship}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
