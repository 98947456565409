import React from "react";
import {
  FaCalendarAlt,
  FaFileInvoiceDollar,
  FaInfoCircle,
  FaTag,
} from "react-icons/fa";

function BillingCard({ billing }) {
  const getStatusColor = (status) => {
    switch (status.toLowerCase()) {
      case "paid":
        return "bg-eleghoGreen bg-opacity-10 text-eleghoGreen";
      case "pending":
        return "bg-yellow-100 text-yellow-600";
      case "overdue":
        return "bg-red-100 text-red-600";
      default:
        return "bg-gray-100 text-gray-600";
    }
  };

  return (
    <div className="bg-white rounded-xl p-6 shadow-sm hover:shadow-md transition-all duration-300 border border-gray-100">
      <div className="flex justify-between items-start mb-4">
        <div className="flex items-center space-x-2">
          <FaFileInvoiceDollar className="text-eleghoBlue text-xl" />
          <div className="font-semibold text-eleghoBlue">
            {billing.identifier}
          </div>
        </div>
        <div
          className={`px-3 py-1 rounded-full text-xs font-medium ${getStatusColor(
            billing.status
          )}`}
        >
          {billing.status}
        </div>
      </div>

      <div className="grid grid-cols-2 gap-4 mb-4">
        <div className="flex items-center space-x-2">
          <FaCalendarAlt className="text-gray-400" />
          <div>
            <div className="text-xs text-gray-500">Due Date</div>
            <div className="font-medium">{billing.dueDate}</div>
          </div>
        </div>
        <div className="flex items-center space-x-2">
          <FaTag className="text-gray-400" />
          <div>
            <div className="text-xs text-gray-500">Type</div>
            <div className="font-medium capitalize">{billing.billingType}</div>
          </div>
        </div>
      </div>

      <div className="flex items-center space-x-2 mb-4">
        <FaInfoCircle className="text-gray-400" />
        <div className="text-sm text-gray-600 capitalize">
          {billing.description}
        </div>
      </div>

      <div className="flex justify-between items-center pt-4 border-t border-gray-100">
        <div className="text-sm text-gray-500">Amount</div>
        <div className="text-xl font-bold text-eleghoBlue">
          ₱{billing.amount}
        </div>
      </div>
    </div>
  );
}

export default BillingCard;
