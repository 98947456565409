import React, { useEffect } from "react";
import { getAllDocsSnap } from "../../utils/firebase/Firebase.utils";
import GuestPassCard from "./GuestPassCard";
import PageModal from "../Dashboard/Components/PageModal";
import GuestPassDetailsPage from "./GuestPassDetailsPage";
import { slicedData } from "../../utils/EleghoUtils";
import BentoTable from "../../components/BentoTable";
import { FiClock, FiCheck, FiX, FiUsers, FiUserPlus } from "react-icons/fi";
import { Link } from "react-router-dom";

export default function GuestPass() {
  const [passes, setPasses] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [viewMode, setViewMode] = React.useState(false);
  const [selectedData, setSelectedData] = React.useState(null);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [numberOfItemsPerPage, setNumberOfItemsPerPage] = React.useState(5);
  const [searchTerm, setSearchTerm] = React.useState("");

  const links = [
    "All",
    "Approved",
    "Pending",
    "Rejected",
    "Admitted",
    "Completed",
    "Returned",
  ];
  const [activeLink, setActiveLink] = React.useState("Pending");

  useEffect(() => {
    const fetchAllPasses = async () => {
      await getAllDocsSnap("guests", (data) => {
        setPasses(data);
        setLoading(false);
      });
    };
    fetchAllPasses();
  }, []);

  if (loading) {
    return <div>Loading..</div>;
  }

  const handleViewMode = (pass) => {
    setViewMode(true);
    setSelectedData(pass);
  };

  const filteredItems = passes.filter(
    (pass) => activeLink === "All" || pass.status === activeLink.toLowerCase()
  );

  const filteredItemsSliced = filteredItems.slice(
    (currentPage - 1) * numberOfItemsPerPage,
    currentPage * numberOfItemsPerPage
  );

  // Calculate stats
  const stats = {
    totalRequests: passes.length,
    pending: passes.filter((pass) => pass.status === "pending").length,
    approved: passes.filter((pass) => pass.status === "approved").length,
    rejected: passes.filter((pass) => pass.status === "rejected").length,
  };

  return (
    <div className="text-xs">
      <div className="mt-4">
        <div className="font-bold text-blue-900 uppercase text-left text-sm">
          Guest Pass Requests
        </div>

        {/* Stats Cards */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 my-6">
          <div className="bg-white p-6 rounded-xl border border-gray-100 shadow-sm hover:shadow-md transition-shadow">
            <div className="flex items-center gap-3">
              <div className="p-2 bg-gray-50 rounded-lg">
                <FiUsers className="w-5 h-5 text-gray-600" />
              </div>
              <div>
                <div className="text-sm text-gray-600">Total Requests</div>
                <div className="text-2xl font-semibold text-gray-900">
                  {stats.totalRequests}
                </div>
              </div>
            </div>
          </div>
          <div className="bg-white p-6 rounded-xl border border-gray-100 shadow-sm hover:shadow-md transition-shadow">
            <div className="flex items-center gap-3">
              <div className="p-2 bg-yellow-50 rounded-lg">
                <FiClock className="w-5 h-5 text-yellow-500" />
              </div>
              <div>
                <div className="text-sm text-gray-600">Pending</div>
                <div className="text-2xl font-semibold text-yellow-500">
                  {stats.pending}
                </div>
              </div>
            </div>
          </div>
          <div className="bg-white p-6 rounded-xl border border-gray-100 shadow-sm hover:shadow-md transition-shadow">
            <div className="flex items-center gap-3">
              <div className="p-2 bg-eleghoGreen/10 rounded-lg">
                <FiCheck className="w-5 h-5 text-eleghoGreen" />
              </div>
              <div>
                <div className="text-sm text-gray-600">Approved</div>
                <div className="text-2xl font-semibold text-eleghoGreen">
                  {stats.approved}
                </div>
              </div>
            </div>
          </div>
          <div className="bg-white p-6 rounded-xl border border-gray-100 shadow-sm hover:shadow-md transition-shadow">
            <div className="flex items-center gap-3">
              <div className="p-2 bg-red-50 rounded-lg">
                <FiX className="w-5 h-5 text-red-500" />
              </div>
              <div>
                <div className="text-sm text-gray-600">Rejected</div>
                <div className="text-2xl font-semibold text-red-500">
                  {stats.rejected}
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Search and Filter Area */}
        <div className="flex items-center justify-between mb-6">
          <div className="border rounded-lg border-gray-300 w-64 p-2 bg-white">
            <input
              className="w-full border-none outline-none"
              placeholder="Search guest name..."
              type="search"
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
          <button className="flex items-center gap-2 px-4 py-2 bg-eleghoBlue text-white rounded-lg hover:bg-eleghoBlue/90 transition-colors">
            <FiUserPlus className="w-4 h-4" />
            Add New Guest Pass
          </button>
        </div>

        {/* Filter Tabs */}
        <div className="links-container mb-6">
          {links.map((link, index) => (
            <div
              key={index}
              className={`${
                activeLink === link ? "activeLinkStyle" : "inactiveLinkStyle"
              } cursor-pointer`}
              onClick={() => setActiveLink(link)}
            >
              {link}
            </div>
          ))}
        </div>

        <BentoTable
          label="Guest Pass Requests"
          data={filteredItems}
          numberOfItemPerPage={numberOfItemsPerPage}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        >
          <div className="divide-y divide-gray-100">
            {/* Table Headers */}
            <div className="grid grid-cols-6 py-2 px-4 bg-gray-50 text-gray-700 font-medium rounded-t-lg">
              <div>Guest Name</div>
              <div>Requestor</div>
              <div>Unit</div>
              <div>Visit Date</div>
              <div>Status</div>
              <div>Actions</div>
            </div>

            {/* Table Content */}
            {filteredItemsSliced.map((pass, index) => (
              <div
                key={index}
                className="hover:bg-gray-50 transition-colors cursor-pointer"
                onClick={() => handleViewMode(pass)}
              >
                <GuestPassCard data={pass} />
              </div>
            ))}
          </div>
        </BentoTable>
      </div>

      {viewMode && (
        <PageModal>
          <GuestPassDetailsPage data={selectedData} setViewMode={setViewMode} />
        </PageModal>
      )}
    </div>
  );
}
