import UserScreenWindow from "../UserScreenWindow";
import Select from "react-select";
import { useEffect, useState } from "react";
import {
  getDocDataSnap,
  storage,
  setDocData,
} from "../../../utils/firebase/Firebase.utils";
import { useSelector } from "react-redux";
import { v4 } from "uuid";
import { useNavigate } from "react-router-dom";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import toast from "react-hot-toast";
import { useSearchParams } from "react-router-dom";
import FileUploadAndView from "../FileUploadAndView";
import {
  FaHardHat,
  FaBuilding,
  FaTools,
  FaCalendarAlt,
  FaUsers,
  FaTruck,
  FaFileAlt,
  FaMoneyBillWave,
} from "react-icons/fa";
import { useCompany } from "../../../context/CompanyContext";

function WorkPermitUser({}) {
  const { getCapitalizedTerm } = useCompany();
  const [searchParams, setSearchParams] = useSearchParams();
  const [isLoading, setIsLoading] = useState(true);

  const id = searchParams.get("continue");

  const [formData, setFormData] = useState({});
  const [isProcessing, setIsProcessing] = useState(false);
  const [identifiers, setIdentifiers] = useState([]);
  const [files, setFiles] = useState({});
  const [assignedId, setAssignedId] = useState(
    id ? id : v4().slice(0, 8).toUpperCase()
  );
  const [progress, setProgress] = useState(0);
  const [url, setUrl] = useState("");
  const [activeUpload, setActiveUpload] = useState(false);
  const navigate = useNavigate();

  const currentUser = useSelector((state) => state.userObject);

  useEffect(() => {
    const getUser = async () => {
      const userSnap = getDocDataSnap("users", currentUser.uid, (data) => {
        setIdentifiers(data.linkedIdentifiers);
      });
    };
    getUser();
  }, []);

  useEffect(() => {
    const fetchRequest = async () => {
      const data = await getDocDataSnap("workPermits", assignedId, (data) => {
        setFormData(data);
        setFiles(data.files || {});
        setIsLoading(false);
      });
    };

    if (id) {
      fetchRequest();
    } else {
      setIsLoading(false);
    }
  }, []);

  if (isLoading) {
    return (
      <UserScreenWindow label="Work Permit Request">
        <div>Loading...</div>
      </UserScreenWindow>
    );
  }

  const options = [
    { value: "minor work", label: "Minor Work and Repairs " },
    { value: "major work", label: "Major Renovation" },
    { value: "construction", label: "Construction" },
  ];

  const identifierOptions = identifiers.map((id) => ({ value: id, label: id }));

  const handleSelect = (e, name) => {
    setFormData({ ...formData, [name]: e.value });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSettingFileList = (e, fileName) => {
    setFiles([...files, { name: fileName, file: e.target.files[0] }]);
  };

  const handleUpload = (e, fileName) => {
    const file = e.target.files[0];
    const storageRef = ref(storage, `workPermits/${assignedId}/${fileName}`);
    const uploadTask = uploadBytesResumable(storageRef, file);
    setActiveUpload(fileName);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setProgress(progress);
      },
      (error) => {},
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          setUrl(downloadURL);
          setFiles({
            ...files,
            [fileName]: downloadURL,
          });
          setActiveUpload(false);
        });
      }
    );
  };

  const handleSubmit = async () => {
    setIsProcessing(true);
    const docObject = {
      ...formData,
      status: "Pending",
      name: currentUser.displayName,
      files: files,
      createdAt: new Date().toLocaleString(),
      createdBy: currentUser.email,
      userId: currentUser.uid,
      user: currentUser.email,
    };

    await setDocData("workPermits", assignedId, docObject);
    navigate("/work-permit-confirmation/" + assignedId);
  };

  return (
    <UserScreenWindow label="Work Permit Request">
      <div className="max-w-3xl mx-auto p-6">
        <div className="text-center mb-8">
          <h1 className="text-3xl font-bold text-eleghoBlue mb-2">
            Work Permit Application
          </h1>
          <p className="text-gray-600">
            Please fill out the form below to submit your work permit request
          </p>
        </div>

        <div className="space-y-6">
          <div className="bg-white rounded-xl shadow-sm p-6">
            <label className="block font-semibold text-eleghoBlue mb-2">
              <FaHardHat className="inline-block mr-2" />
              Type of Work Permit
            </label>
            <Select
              options={options}
              className="text-sm"
              onChange={(e) => handleSelect(e, "workPermitType")}
              value={options.find(
                (option) => option.value === formData.workPermitType
              )}
              styles={{
                control: (base) => ({
                  ...base,
                  borderColor: "#e5e7eb",
                  "&:hover": {
                    borderColor: "#3b82f6",
                  },
                }),
              }}
            />
          </div>

          {formData.workPermitType === "major work" && (
            <div className="bg-eleghoGreen/10 rounded-lg p-4 text-sm text-eleghoGreen">
              <FaBuilding className="inline-block mr-2" />
              Major Renovation: Additional new structures, major repairs,
              changes in current structure of the unit.
            </div>
          )}

          <div className="bg-white rounded-xl shadow-sm p-6">
            <label className="block font-semibold text-eleghoBlue mb-2">
              <FaBuilding className="inline-block mr-2" />
              {getCapitalizedTerm("identifier")}
            </label>
            <Select
              options={identifierOptions}
              className="text-sm"
              onChange={(e) => handleSelect(e, "identifier")}
              value={identifierOptions.find(
                (option) => option.value === formData.identifier
              )}
              styles={{
                control: (base) => ({
                  ...base,
                  borderColor: "#e5e7eb",
                  "&:hover": {
                    borderColor: "#3b82f6",
                  },
                }),
              }}
            />
          </div>

          <div className="bg-white rounded-xl shadow-sm p-6">
            <label className="block font-semibold text-eleghoBlue mb-2">
              <FaCalendarAlt className="inline-block mr-2" />
              Duration of Activities
            </label>
            <input
              type="number"
              className="w-full rounded-lg border border-gray-200 p-3 text-sm focus:ring-2 focus:ring-eleghoBlue focus:border-transparent"
              placeholder="Number of Days"
              onChange={handleChange}
              name="duration"
              value={formData.duration}
            />
          </div>

          <div className="bg-white rounded-xl shadow-sm p-6">
            <label className="block font-semibold text-eleghoBlue mb-2">
              <FaTools className="inline-block mr-2" />
              Work Description
            </label>
            <textarea
              className="w-full rounded-lg border border-gray-200 p-3 text-sm focus:ring-2 focus:ring-eleghoBlue focus:border-transparent"
              rows={5}
              onChange={handleChange}
              placeholder="Describe the work to be done"
              name="description"
              value={formData.description}
            />
          </div>

          <div className="bg-white rounded-xl shadow-sm p-6">
            <label className="block font-semibold text-eleghoBlue mb-2">
              <FaUsers className="inline-block mr-2" />
              Workers Information
            </label>
            <textarea
              className="w-full rounded-lg border border-gray-200 p-3 text-sm focus:ring-2 focus:ring-eleghoBlue focus:border-transparent"
              rows={5}
              onChange={handleChange}
              placeholder="List all workers involved"
              name="workers"
              value={formData.workers}
            />
          </div>

          <div className="bg-white rounded-xl shadow-sm p-6">
            <label className="block font-semibold text-eleghoBlue mb-2">
              <FaTruck className="inline-block mr-2" />
              Vehicle Information
            </label>
            <input
              type="text"
              name="vehicle"
              className="w-full rounded-lg border border-gray-200 p-3 text-sm focus:ring-2 focus:ring-eleghoBlue focus:border-transparent"
              placeholder="Vehicle type and plate number"
              onChange={handleChange}
              value={formData.vehicle}
            />
          </div>

          {formData.workPermitType === "major work" && (
            <div className="space-y-4">
              <FileUploadAndView
                doc="Renovation Plan"
                files={files}
                handleUpload={handleUpload}
                progress={progress}
                activeUpload={activeUpload}
              />
              <FileUploadAndView
                doc="Renovation Permit"
                files={files}
                handleUpload={handleUpload}
                progress={progress}
                activeUpload={activeUpload}
              />
            </div>
          )}

          {formData.workPermitType === "construction" && (
            <div className="space-y-4">
              <FileUploadAndView
                doc="Construction Plans"
                files={files}
                handleUpload={handleUpload}
                progress={progress}
                activeUpload={activeUpload}
              />
              <FileUploadAndView
                doc="Construction Permit"
                files={files}
                handleUpload={handleUpload}
                progress={progress}
                activeUpload={activeUpload}
              />
              <FileUploadAndView
                doc="Building Permit"
                files={files}
                handleUpload={handleUpload}
                progress={progress}
                activeUpload={activeUpload}
              />
            </div>
          )}

          {formData.duration > 5 &&
            formData.workPermitType === "minor work" && (
              <div className="bg-eleghoGreen/10 rounded-lg p-4">
                <FaMoneyBillWave className="inline-block mr-2 text-eleghoGreen" />
                <span className="text-eleghoGreen font-semibold">
                  Required Bonds for Minor Works Exceeding 5 Days:
                </span>
                <ul className="mt-2 space-y-1 text-sm">
                  <li className="flex items-center">
                    <span className="w-2 h-2 bg-eleghoGreen rounded-full mr-2"></span>
                    Repair Bond: Php 15,000
                  </li>
                  <li className="flex items-center">
                    <span className="w-2 h-2 bg-eleghoGreen rounded-full mr-2"></span>
                    Sanitation Bond: Php 10,000
                  </li>
                </ul>
              </div>
            )}

          {formData.workPermitType === "major work" && (
            <div className="bg-eleghoGreen/10 rounded-lg p-4">
              <FaMoneyBillWave className="inline-block mr-2 text-eleghoGreen" />
              <span className="text-eleghoGreen font-semibold">
                Required Bonds for Major Works:
              </span>
              <ul className="mt-2 space-y-1 text-sm">
                <li className="flex items-center">
                  <span className="w-2 h-2 bg-eleghoGreen rounded-full mr-2"></span>
                  Renovation Bond: Php 75,000
                </li>
                <li className="flex items-center">
                  <span className="w-2 h-2 bg-eleghoGreen rounded-full mr-2"></span>
                  Sanitation Bond: Php 10,000
                </li>
              </ul>
            </div>
          )}

          {formData.workPermitType === "construction" && (
            <div className="bg-eleghoGreen/10 rounded-lg p-4">
              <FaMoneyBillWave className="inline-block mr-2 text-eleghoGreen" />
              <span className="text-eleghoGreen font-semibold">
                Required Bonds and Fees for Construction:
              </span>
              <ul className="mt-2 space-y-1 text-sm">
                <li className="flex items-center">
                  <span className="w-2 h-2 bg-eleghoGreen rounded-full mr-2"></span>
                  Construction Bond: Php 100,000
                </li>
                <li className="flex items-center">
                  <span className="w-2 h-2 bg-eleghoGreen rounded-full mr-2"></span>
                  Sanitation Bond: Php 10,000
                </li>
                <li className="flex items-center">
                  <span className="w-2 h-2 bg-eleghoGreen rounded-full mr-2"></span>
                  Electrical Distribution Tapping Fee: Php 20,000
                </li>
                <li className="flex items-center">
                  <span className="w-2 h-2 bg-eleghoGreen rounded-full mr-2"></span>
                  Water System Connection Fee: Php 100,000
                </li>
              </ul>
            </div>
          )}

          {(formData.duration > 5 &&
            formData.workPermitType === "minor work") ||
          formData.workPermitType !== "minor work" ? (
            <FileUploadAndView
              doc="Bond Payment"
              files={files}
              handleUpload={handleUpload}
              progress={progress}
              activeUpload={activeUpload}
            />
          ) : null}

          <div className="mt-8">
            <button
              className="w-full bg-eleghoBlue text-white py-3 px-6 rounded-lg font-semibold hover:bg-eleghoBlue/90 transition-colors duration-200 flex items-center justify-center"
              onClick={handleSubmit}
            >
              {isProcessing ? (
                <>
                  <svg
                    className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                  Processing...
                </>
              ) : (
                "Submit Application"
              )}
            </button>
          </div>
        </div>
      </div>
    </UserScreenWindow>
  );
}

export default WorkPermitUser;
